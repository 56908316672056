import React, { useState, useEffect, Fragment, useContext} from "react"
import {Link, Route, Redirect } from "react-router-dom"
import useStyle from "../../../../hooks/useStyle"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../PreLoadSpinner"
import {NavIcon} from '../../../Common/MenuItem'
import Pagination from '../../../Pagination'
import PlannerService from './PlannerService'
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle"
import TrainingModuleService from "../TrainingModules/TrainingModuleService"
import CoursePlannerTable from "./CoursePlannerTable"
import CourseFilterList from "./CourseFilterList"
import SideMenuBar from "../../../Common/SideMenuBar"
import TrainingModuleForm from "../TrainingModules/Form/TrainingModuleForm"

let requestParams = {}

const CoursePlanner = (props) => {
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const {domain_info: {data_source: profileDs}} = currentUser;

  let params = props.match.params;
  let journey_category = props.location.state;

  const [courseModules, setCourseModules] = useState([]);
  const [showTrainingModule, setShowTrainingModule] = useState(false);
  const [loading, setLoading] = useState(false);

  /*if(profileDs.fj_your_role.sid != 'school_counselor'){
    props.history.push('/profile?go_back=true')
  }*/

  const toggleTrainingModule = () => setShowTrainingModule(!showTrainingModule);

  let updateSortOrder = (id, prior_item_id, next_item_id, success_callback) =>{
    setLoading(true)
    let req = {
      'journey_category_id':params.journey_category_id,
      'update_type': 'sort_order',
      'prior_item_id':prior_item_id,
      'next_item_id':next_item_id,
      'id':id
    }
    PlannerService.update(req).then((res)=>{
      setLoading(false)
      if(res.status == 204){
        success_callback()
      }
    })  
  }

  return (
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4">
          <JourneyTitle title={"Course Planner"} project={journey_category} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/career_training/project/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>

          <NavIcon id="select_training_modules" dataTip="Select Training Modules" 
            onSelect={toggleTrainingModule}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fa fa-table"/>

          <NavIcon dataTip="Share" 
            link={{pathname:`/journey/user_role/${params.journey_category_id}/${params.journey_profile_id}/carrier_training.planner/${journey_category.id}`, search:`?title=planner`, state:{journey:journey_category, activity:journey_category} }}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="far fa-eye-slash"/>

          <NavIcon id="back_icon" dataTip="Back to Planner List" 
            onSelect={e=>props.history.goBack()}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-arrow-left"/>
          
        </div> 
        <div className="clear"></div>
      </div>

      <div className="content row">
        {showTrainingModule && <Fragment>
            <CoursePlannerTable modules={courseModules} loading={loading}
            setModules={setCourseModules} requestParams={requestParams} 
            updateSortOrder={updateSortOrder} params={params}
            setLoading={setLoading} searchString={props.searchString}/>
          </Fragment>
        }

        {!showTrainingModule && <CourseFilterList {...props}/>}
        <SideMenuBar helpId="2"/>
      </div>  
      <div className="m-b-60"/>
    </Fragment>
  )
}

export default CoursePlanner;