import env from '../env';
import axios from 'axios';

export default class BaseService {
  
  constructor(base, api_name) {
    this.url = env[base]+`${api_name}`;
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(this.url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(id, req){
  	//const id = (typeof req === 'object') ? req.id : req;
    return new Promise((resolve, reject) => {
      axios.get(`${this.url}/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(this.url, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${this.url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id, req) {
  	//const id = (typeof req === 'object') ? req.id : req;
    return new Promise((resolve, reject) => {
      axios.delete(`${this.url}/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}