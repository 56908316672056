import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DynamicForm from '../../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../../constants/FormTemplateList'
import ActivityService from "./ActivityService"
import querystringify from "querystringify"
import FormContainer from '../../../FormBuilder/FormContainer'
import FormWizard from '../../../../components/FormBuilder/FormWizard';

let formMode = null 
let activity = {} 
let data = {} 
let formFn = {}  

function ActivityForm(props) {
  const { state:{ user:currentUser } } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const get = () => {
    ActivityService.get(params.id).then((res) => {
      if(res.status==200){
        activity = res.data.activity || {}
        data = activity.data || {}
        setFormRendered(true)
      }
    }).catch((res)=>{
       activity = null
    })
  }
  
  useEffect(() => { 
    activity = {} 
    data = {} 
    formFn = {
      'form_type':'career_journey.activity', 
      'journey_category_id':params.journey_category_id
    } 
    
    formMode = 'create-form-submissions'
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (activity) => {
    activity.data.career = queryParam.career
    activity.data.occupation_group = queryParam.occupation_group
    activity.data.career_ds_fk_id = queryParam.career_ds_fk_id
    activity.journey_profile_id = params.journey_profile_id
    activity.journey_category_id = params.journey_category_id
    activity.activity_form_id = params.form_id
    activity.activity_type = params.type
    ActivityService.create(activity).then((res) => {
      if(res.status == 201){
        onSubmitPageRedirect()
      }else if(res.status == 422){
        alert(res.data.error)
      }
    }).catch((res)=>{
       
    })
  }

  const update = (activity) => {
    ActivityService.update(activity).then((res) => {
      if(res.status == 204){
        onSubmitPageRedirect()
      }
    }).catch((res)=>{
       
    })
  }

  const onSubmitPageRedirect = () => {
    if(queryParam.goBack){
      props.history.goBack()
    }else{
      props.history.push(`/career/path/${params.journey_category_id}/${params.journey_profile_id}`)
    }
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id || queryParam.unfilled_client_id){
    return(<FormWizard form_wizard_id={queryParam.form_wizard_id} form={activity} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}/>)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={params.form_id} 
          form={activity} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default ActivityForm;