import env from '../../../../env';
import axios from 'axios';

class CollegeActivityService {
  
  getJourneyPath(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_college_journey}college_path`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_college_journey}activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getActivityByType(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_college_journey}activity_types`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_college_journey}activities/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_college_journey+'activities',profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getCatId(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.edu_college_journey}activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.edu_college_journey}activities/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.edu_college_journey}activities/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  reports(req){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_college_journey+'activities/report', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  copyToPlanner(id){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_college_journey+'activities/clone/'+id).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }
}

export default new CollegeActivityService();