import env from '../../env';
import axios from 'axios';

class SubscriptionService {

  getSubscriptions(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+'subscription/plans',{params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  createSubscriptionUser(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+`subscriptions/${req.id}/users`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  stripeCreateCustomer(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/create_customer', req).then((res) => {
        // handle success
        resolve(res);
      })
    }) 
  }

  createStripeSubscription(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/create_subscription', req).then((res) => {
        // handle success
        resolve(res.data);
      })
    }) 
  }

  cancelStripeSubscription(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/cancel_subscription', req).then((res) => {
        // handle success
        resolve(res.data);
      })
    }) 
  }

  retryInvoiceWithNewPaymentMethod(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/retry_invoice', req).then((res) => {
        // handle success
        resolve(res.data);
      })
    }) 
  }

  retrieveCustomerPaymentMethod(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/retrieve_customer_payment_method', req).then((res) => {
        // handle success
        resolve(res.data);
      })
    })  
  }

  retrieveUpcomingInvoice(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/retrieve_upcoming_invoice', req).then((res) => {
        // handle success
        resolve(res.data);
      })
    })  
  }

  updateSubscription(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/update_subscription', req).then((res) => {
        // handle success
        resolve(res.data);
      })
    })  
  }

  createCheckoutSession(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'payments/create_checkout_session', req).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  getCustomerPortal(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+'payments/customer_portal',{params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new SubscriptionService();