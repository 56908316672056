import BaseService from "../../services/BaseService";
import env from "../../env"
import axios from 'axios';

class DailyDabbleService extends BaseService{

  getDailyDabble(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}get_latest_dabble`).then((res) => {
        // handle success
        resolve(res)
        
      })
    })
  }

  getPreviousDabble(req){
  	return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}get_previous_dabble_responses`, {params: req}).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }
  
}

export default new DailyDabbleService('family_tree_backend', 'daily_dabbles');