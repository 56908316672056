  import React, { Fragment, useState, useEffect, useContext } from "react"
import ReactDOM from "react-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import ChecklistService from "../../services/ChecklistService"
import useStyle from "../../hooks/useStyle"
import useEscape from "../../hooks/useEscape"

let checklist = {}

const ChecklistsModal = ({ item, type, isOpen, toggleModal }) => {
  useStyle("add_role_dialog")
  useEscape(toggleModal)

  const { state: currentUser, dispatch } = useContext(AuthContext)
  const [checkListItem, setCheckListItem] = useState([])
  const [checklistId, setChecklistId] = useState()
  const [text, setText] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isOpen) getCheckList()
  }, [item.id, checklist])
  
  const getCheckList = () => {
    let req = {
      activity_id: item.id,
      journey_profile_id: item.education_journey_profile_id || item.journey_profile_id,
      page: 1,
      paginate: 1,
      per_page: 30,
      sort_column: "updated_at",
      sort_direction: "asc",
    }

    if(type == 'data_source'){
      req.activity_int_id = item.pk_id
      delete req.activity_id
    }

    ChecklistService.getAll(req).then((res) => {
      if (res.status == 200) {
        setLoading(false)
        setCheckListItem(res.data.check_lists)
      }
    })
  }

  if (!isOpen) return null;

  const addCheckList = (e) => {
    setLoading(true)
    e.preventDefault()
    checklist.data = {'term': text}
    if (!text) return

    checklist.journey_category_id = item.journey_category_id
    checklist.journey_profile_id = item.education_journey_profile_id || item.journey_profile_id;
    checklist.activity_id = item.id

    checklist.item_type = type
    checklist.updated_by = currentUser.user.id

    if(type == 'data_source'){
      checklist.activity_int_id = item.pk_id
      delete checklist.activity_id
    }
    
    if (checklist.id) {
      ChecklistService.update(checklist).then((res) => {
        if (res.status == 204) {
          setLoading(false)
          setCheckListItem([...checkListItem])
          checklist = {}
          setText("")
        }
      })
    } else {
      ChecklistService.create(checklist).then((res) => {
        if (res.status == 201) {
          setLoading(false)
          checklist.id = res.data.id
          setCheckListItem([...checkListItem, checklist])
          checklist = {}
          setText("")
        }
      })
    }
  }

  const deleteCheckList = (e, checklist) => {
    e.preventDefault()
    setLoading(true)
    ChecklistService.delete(checklist.id).then((res) => {
      setLoading(false)
      setCheckListItem(checkListItem.filter((i) => i.id !== checklist.id))
    })
  }

  const toggleStatus = (e, checklist) => {
    e.preventDefault()
    checklist.completion_status = !checklist.completion_status
    ChecklistService.status(checklist).then((res) => {
      setCheckListItem(
        checkListItem.map((c) =>
          c.id === checklist.id
            ? { ...c, completion_status: checklist.completion_status }
            : c
        )
      )
    })
  }

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Checklist</h4>
            <button className="btn-close"
              title="Close Modal" onClick={(e) => toggleModal(e)}>×
            </button>
          </div>

          <form className="ml-container" onSubmit={(e) => addCheckList(e)}>
            <div className="ml-section">
              <input onChange={(e) => setText(e.target.value)}
                className="ml-input ml-border ml-margin-bottom"
                type="text"
                placeholder="Add Checklist"
                name="checklist"
                required
                value={text}/>

              <div className="ml-button ml-block ml-section ml-padding bg-safforn white" onClick={(e) => addCheckList(e)}>
                <b>{checklist.id ? "Update" : "Create"}</b>
              </div>
            </div>
          </form>

          <div className="ml-container">
            <div className="note-ul note-card-4" style={{ marginBottom: "20px" }}>
              {loading ? (
                <div className="spinner"></div>
              ) : (
                checkListItem && checkListItem.map((c, key) => (
                  <div className={c.completion_status ? "note-bar bg-ltint" : "note-bar"} key={key}>
                    <span className="checkbox-container" onClick={(e) => toggleStatus(e, c)}>
                      <span>{c.data.term}</span>
                      <input type="checkbox" checked={c.completion_status} readOnly/>
                      <span className="checkbox-checkmark"></span>
                      <div className="nbt-white font-16 pull-right m-r-5"
                        onClick={(e) => {checklist = c;setChecklistId(c.id);setText(c.data.term);}}>
                        <i className="far fa-edit" aria-hidden="true"></i>
                      </div>
                      <div className="nbt-white font-16 pull-right m-r-25"
                        onClick={(e) => deleteCheckList(e, c)}>
                        <i className="far fa-trash-alt" aria-hidden="true"></i>
                      </div>
                    </span>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>, document.body
  )
};

export default ChecklistsModal;