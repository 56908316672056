import React, { Fragment, useState } from 'react'
import FavouritesService from "../../services/FavouritesService"

const AddToFavourite = ({favouriteId, favorableType, favorableId, journeyCategoryId, journeyProfileId, classNameActive, classNameDisable}) => {
  
  const [favId, setFavId] = useState(favouriteId)

  const remove = () => {
    FavouritesService.delete(favId).then((res) => {
      if (res.status == 204) {
        setFavId(null)        
      }
    })
  }

  const add = () => {
    let req = {
      favorable_id: favorableId,
      favorable_type: favorableType,
      education_journey_profile_id: journeyProfileId,
      journey_category_id: journeyCategoryId,
    }
    FavouritesService.create(req).then(function (res) {
      if (res.status == 201) {
        setFavId(res.data.favourite.id)
      }
    })
  }

  return (
    <Fragment>
      {favId ? (
        <i onClick={(e) => remove(e)} data-tip="Favourite"
          className={`fas fa-thumbtack pull-right top-3 pin font-16 ${classNameActive} `}/>
      ) : (
        <i onClick={(e) => add(e)} data-tip="Favourite"
          className={`fa fa-thumbtack pull-right top-3 pin font-16  ${classNameDisable} `}/>
      )}
    </Fragment>
  )
}

export default AddToFavourite;