import React,{Fragment} from 'react'

const VimeoPlayer = ({url, width, height}) => {
  if(url && url.includes('vimeo.com')){
		return(
			<iframe src={url} width={width} height={height}
				frameBorder="0" allow="fullscreen" webkitallowfullscreen mozallowfullscreen 
				allowFullScreen>
			</iframe>
		)  		
  }

  return null	
}

export default VimeoPlayer;