import React, {Fragment} from "react"

const Stages = (props) => {
  const {stages=[], favourite, updateStage, index, onSelect} = props
  const d = favourite.data || {}

  const checkExp = (s, d) =>{
	  let visibility = true
    try{
      visibility = eval(s)
    }catch(e){
      return false
    }
    return visibility
	}    
  	
  const setStage = (s) =>{
    d[s.label.toLowerCase()] = true
    updateStage(favourite.id, d, index)
  }

  const findPreviousCue = (sid) =>{
  	return favourite.college_cues?.findIndex((c)=> c.form_type == sid)
  }

  const checkStatus = (idx, status) =>{
		if(idx>-1){
  		const cue = favourite.college_cues[idx]
			return cue?.data_source?.app_outcome?.label == status
		}
		
		return false		
  }

  const checkStage = (s, cue) =>{
  	let idx = null
	  switch (s.stage) {
		  case 1:
		    return true
		    break
		  case 2:
		    return findPreviousCue('research')>-1
		    break
		  case 3:
		    return findPreviousCue('key_info')>-1 
		    break
		  case 4:
		    return findPreviousCue('plan_recruiting')>-1 
		    break
		  case 5:
		    return findPreviousCue('manage_application')>-1 
		    break
		  case 6:
		  	return findPreviousCue('manage_application')>-1
		    break
		  case 7:
		    idx = findPreviousCue('update_admission_status')
	    	return checkStatus(idx, 'Waitlisted')
		    break
		  case 8:
		    idx = findPreviousCue('update_admission_status') 	
		    return checkStatus(idx, 'Deferred')
		    break
		  case 9:
		    idx = findPreviousCue('update_admission_status') 	
		    return checkStatus(idx, 'Admitted') 
		    break
		  case 10:
		  	idx = findPreviousCue('update_admission_status') 	
		    return checkStatus(idx, 'Admitted') && findPreviousCue('visit_admitted_campus')>-1
		    break
		  case 11:
		  	idx = findPreviousCue('update_admission_status') 	
		    return checkStatus(idx, 'Admitted') && findPreviousCue('accept_admission_offer')>-1
		  case 12:
		  	idx = findPreviousCue('update_admission_status') 	
		    return checkStatus(idx, 'Admitted') && findPreviousCue('move_in')>-1 
		    break
		}
	}    

  return(
    <StagePanels stages={stages} checkExp={checkExp} 
    	data={d} setStage={setStage} onSelect={onSelect}
    	collegeCues={favourite.college_cues || []}
    	favourite={favourite} checkStage={checkStage}
    />
  )
}

const StagePanels = ({favourite, checkStage, stages, onSelect, checkExp, updateStage, data, setStage, collegeCues}) => stages.map((s, i) => {
		const cueFound = collegeCues.find((c)=> c.form_id == s.child_form)
		const stagePass = checkStage(s, cueFound)
		const style = (i==0 || stagePass || cueFound)?{}:{color: '#999', cursor: 'not-allowed', pointerEvents: 'none'}
		
		return(
			<div className="col-xs-12 br-btm-grey p10 m-b-5" style={style} key={i}>
				<div className="col-xs-1 font-20 text-center">
					<span className={cueFound + " gold fas fa-star m-t-18"}/>
				</div>
				<div className="col-xs-10" onClick={()=>onSelect(s, favourite, cueFound?.id)}>
					<div className="p-0-10 m-b-5">	
	    			<label>{s.label}-{s.stage}</label>
		    		<div>{s.message}</div>
		    		<div className="pg-sm-1">
		    			<div style={{width: '0%'}}>0%</div>
		    		</div>
	    		</div>
				</div>
				<div className="col-xs-1 font-20 d-in-grid text-center" onClick={()=>onSelect(s, favourite, cueFound?.id)}>
					{cueFound ? 
						<span className="far fa-file-alt m-t-10"/>
						:
						<span className="fas fa-arrow-right m-t-10"/>
					}
					<span className={cueFound ? "fas fa-lock-open m-t-10":"fas fa-lock m-t-10"}/>
				</div>
    	</div>
    )	
})

export {Stages}