import env from '../../env'
import axios from 'axios'

class JourneyProfileService {

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}education_journey_profiles/${req}`)
      .then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        reject(e)
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.family_tree_backend+'education_journey_profiles', profile)
      .then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        reject(e)
      })
    })
  }

  updateJourneyProfile(profile){
    return new Promise((resolve, reject) => {
      axios.put(env.family_tree_backend+'education_journey_profiles/'+profile.id, profile)
      .then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        reject(e)
      })
    })
  }

  updateActivityReminder(activity, req){
    return new Promise((resolve, reject) => {
      axios.put(env.family_tree_backend+'empty_fields/'+activity.id, req)
      .then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        reject(e)
      })
    })
  }
  
}

export default new JourneyProfileService();