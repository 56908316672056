import React, { useState, Fragment, useEffect, useContext} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import JourneyCategoryService from "../../JourneyCategoryService";
import {NavIcon} from '../../../Common/MenuItem'

let requestParams = {};
let data_source = [];

const CareerList = (props) => {
  useStyle("card");
  useStyle("card_table");

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  let params = props.match.params;
  let project = props.location.state;

  let queryParam = querystringify.parse(props.location.search);

  useEffect(() => {
    getJourneyById();
  }, []);

  let getJourneyById = () => {
    setLoading(true);
    let req = {
      journey_category_id: params.journey_category_id,
      show_journey_templates: false,
      data_source_params: {
        data_source_id: [3443] 
      }
    }
    JourneyCategoryService.getJourneyById(req).then((res) => {
      if (res.status == 200) {
        res = res.data;
        data_source = res.data_source_list.occupation_groups.options;
        setLoading(false);
      }
    })
  }

  const careerList = data_source.map((c, i) => {
    let journey_category_ds_id = project.education_journey_profiles.data.fj_type_edu_jou
    if(c.read_only == 'yes')return null;
    return( 
    <Link to={{
      pathname:`/college/interview/questions/${params.journey_category_id}/${params.journey_profile_id}/${c.child_datasource}/${c.pk_id}`,
      search:`?graduate_program_ds_id=${c.value}&journey_category_ds_id=${journey_category_ds_id}`, 
      state:project, 
      label:c.label}} 
      key={i} className="col-xs-12 carrier ver-icons">
      <i className={`${c.icon} +' pull-left p-5`} style={{color:c.icon_color}}></i>
        {c.label}
      <i className="fas fa-chevron-right lgrey pull-right p-5"></i>
    </Link>)
  })

  return (
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title="Interview Preparation" project={project} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="back_home" dataTip="Back to Journey Home" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={e=>{props.history.goBack();e.preventDefault();}} icon="fas fa-home"/>
        </div>
        <div className="clear"></div>
      </div>

      <div className="page-content">
        <div className="col-xs-12 col-sm-6 col-md-4"> 
          <div className="card-container bg-white p-12 ht-200 scroll-auto">
            <div className="bg-highlight white">
              <label className="font-16 p-7">Please select a profession</label>
            </div>
            {careerList}
          </div>
        </div>
      </div>  
      <SideMenuBar helpId="15" />
    </Fragment>
  )
}

export default CareerList;