import React, { useEffect, Fragment, useRef ,useState, useContext} from "react"
import { Link } from "react-router-dom"
import useStyle from "../../hooks/useStyle"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommonService from "../../services/CommonService"
import AuthenticationService from "../../services/AuthService"

let requestParams = {}

const HamBar = (props) => {
  useStyle("nav");
  
  const { state:{screen, user:currentUser}, dispatch, isAuthenticated: isAuthenticated } = useContext(AuthContext)
  const [menuItems, setMenuItems] = useState()
  const BurgerNavEl = useRef(null)
  const SideBurgerMenuEl = useRef(null)

  useEffect(() => {
  	requestParams = {
  		data_source_id: [3368],
  	}
  	getMenuItems()

  	document.addEventListener("keydown", handleEscape)
  	document.addEventListener("click", handleClick, true)
	
  	return () => {
  		document.removeEventListener("keydown", handleEscape)
  		document.removeEventListener("click", handleClick, true)
  	}
  }, [isAuthenticated]);

  let getMenuItems = () => {
    CommonService.getDataSource(requestParams).then((res) => {
		res[Object.keys(res)[0]].options.sort((a, b) => (a.order_list > b.order_list) ? 1 : -1)
      setMenuItems(res[Object.keys(res)[0]].options);
    })
  }

  const handleEscape = (e) => {
	  if (e.keyCode === 27)document.getElementById("hamtoggle").checked = false;
  }

  const handleClick = (e) => {
	  if (BurgerNavEl.current && (!BurgerNavEl.current.contains(e.target) || SideBurgerMenuEl.current.contains(e.target))){
		  document.getElementById("hamtoggle").checked = false;
	  }  
  }

  const MenuList = menuItems && menuItems.map((k, i) => {
	  return (
	    <Link to={`/${k.url}?i=${i}`} className="black" key={i}>
		    <li>{k.label}</li>
	    </Link>
	  )
  })

  const signout = (e) =>{
    e.stopPropagation()
    e.preventDefault()
    AuthenticationService.signout().then(()=>{
      dispatch({type: "LOGOUT"});
    })
  }

  /*try{
    if(!currentUser?.current_admin?.role_master)return null;
  }catch(e){
    return null
  } */

  return (
    <Fragment>
	    <nav className={props.className} role="navigation" ref={BurgerNavEl} >
        <div id="menuToggle" className="zIndex-9">
          <input id="hamtoggle" type="checkbox" />

          <span></span>
          <span></span>
          <span></span>

          <ul id="menu" ref={SideBurgerMenuEl}>
		  	    {MenuList}
			      {!currentUser &&
              <Fragment>
                <Link to="/signin" className="black">
                  <li>Sign in</li>
                </Link>
                <Link to="/signup" className="black">
                  <li>Sign up</li>
                </Link>
              </Fragment>
            }
            { currentUser &&
              <Link to="/" onClick={(e) => signout(e)} className="black">
                <li>Sign out</li>
              </Link>
            }
          </ul>
        </div>
      </nav>
    </Fragment>
  )
}

export default HamBar;