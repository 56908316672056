import React from "react"

const Badge = ({text}) => {
  const defaultCss = 'badge badge-success fw900 f12'
  return (
    <div className={defaultCss}>{text}</div>
  )
}

const NotificationBadge = ({count, css}) =>{
  const defaultCss = css?css:'notification_count border-rounded notification_white fw900'
  return(
    <span className={defaultCss}>{count}</span>
  )
}

export {Badge, NotificationBadge};