import React, {useState, Fragment, useContext, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import Pagination from '../../../../Pagination'
import useStyle from '../../../../../hooks/useStyle';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CollegeService from "../CollegeServices";
import College from "./College"
import useSort from '../../../../../hooks/useSorting';
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import FavouritesService from '../../../../../services/FavouritesService';
import JourneyTitle from '../../../JourneyTitle/JourneyTitle'
import SideMenuBar from "../../../../Common/SideMenuBar";
import NotificationPopup from "../../../../Common/NotificationPopup"
import GenericModal from "../../../../Modals/GenericModal";
import { SwiperContainer, SwiperSlide } from "../../../../Common/Swiper";
import CommonService from "../../../../../services/CommonService";
import querystringify from "querystringify";
import {NavIcon} from '../../../../Common/MenuItem'
import ReactTooltip from "react-tooltip";
import CollegeProfileForm from "../CollegeProfileForm/CollegeProfileForm"
import SortDirection from '../../../../../components/Common/SortDirection'

let requestParams = {};
let data_source = {};
let selectedCollege = null;
let collegeSearchFilter = {};
let favCollegeIds = [];
let cardViewCount = 1;
let timer = null;

const CollegeTableList = (props) => {
  useStyle('table');  

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collegeSearch, setCollegeSearch] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [searchString, setSearchString] = useState();

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal();
  const { isOpen:isSuccessMessageOpen, toggleModal:toggleSuccessMessage } = useModal();
  const { isOpen:isSearchFilterOpen, toggleModal:toggleSearchFilterModal } = useModal();

  let params = props.match.params;
  let journey = props.location;
  let queryParam = querystringify.parse(props.location.search);
  const indexEle = useRef(null);

  let collegeSearchProps = {
    match:{
      params:{
        journey_category_id: params.journey_category_id,
        journey_profile_id: params.journey_profile_id,
        go_back:true,
        id:collegeSearch?.id
      }
    },
    location:{}
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'page': currentpage, 
      'per_page' : 15,
      'search': (props.searchString || searchString || null),  
      'sort_column': 'user_rating', 
      'sort_direction': 'asc',
      'include_favourites_count': true,
      'journey_category_id':params.journey_category_id,
      'favourites_params':{
        'education_journey_profile_id':params.journey_profile_id,
        'field':'favorable_id',
        'sort_column':'updated_at',
        'sort_direction':'asc'
      }
    }
    
    try{
      collegeSearchFilter = JSON.parse(sessionStorage.activeCollegeProfile)
      CollegeService.buildCollegeSearchParam(collegeSearchFilter.data, requestParams)
    }catch(e){
      console.log(e)
    }
    searchColleges()
  }, [props.searchString, searchString, currentpage, searchFilter])

  let searchColleges = () => { 
    setLoading(true);
    CollegeService.search(requestParams).then((res)=>{
     if(res.status == 200){
        favCollegeIds = res.data.favourites;
        requestParams.totalItems = res.data.meta.total 
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount);
        if(requestParams.search || searchFilter){
          setColleges(res.data.colleges); 
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setColleges(res.data.colleges); 
        }else{
          setColleges([...colleges, ...res.data.colleges]); 
        }
        setCollegeSearch(res.data.college_admission_profile) 
        setLoading(false);
      }
    })
  }

  const {sort} = useSort(requestParams, searchColleges);

  const addToFavourite = (college, e) =>{
    e.preventDefault()
    setLoading(true)
    let req = {
        'favorable_id':college.id,
        'favorable_type':'colleges',
        'education_journey_profile_id':params.journey_profile_id, 
        'college_admission_profile_id':collegeSearchFilter.id,
        'journey_category_id':params.journey_category_id
    }
    FavouritesService.create(req).then(function(res){
      if(res.status==201){
        setLoading(false)
        favCollegeIds.push(res.data.favourite.id)
        toggleSuccessMessage(e)
        props.addFavCollegeNode && props.addFavCollegeNode(college)
      }
    })
  }

  const search = ({target}) =>{
    clearTimeout(timer)
    timer = setTimeout(()=>{
      setSearchString(target.value)
    }, 1000)
  }

  const favListView = (favourite) =>{

    let spanTag = []

    favCollegeIds.length > 0 && favCollegeIds.map((f,k)=>{
      if(f === favourite.id){
        spanTag.push(<span className="green">Added</span>)
      }
    })

    if(spanTag.length === 0){
      spanTag.push(<span className="" id={`check_list_${favourite.id}`}
      onClick={(e) => addToFavourite(favourite,e)}>
      <i className="far fa-heart font-18 text-muted m-t-10" data-tip="Likes"></i>
      <span className="badge up bg-lgrey">{favourite.favourites_count}</span>
    </span>)
    }

    return spanTag
  }

  const tableData = colleges && colleges.map((o, k) =>
    <tr key={k}>
      <td data-title="Name" className="text-cap">{o.name}</td>
      <td data-title="State" className="text-cap">
        {o.state}
      </td>
      <td data-title="City" className="text-cap">
        {o.city}
      </td>
      <td data-title="Url" className="">
      <a className="link" target="_blank" href={o.school_url}> {o.school_url}
           </a>
        
      </td>
      <td data-title="Options">
        {favListView(o)}
      </td>
    </tr>
  )

  return (
    <Fragment>
      {loading && <div className="spinner" />}
      <div className="content">
        <div className="col-xs-12">
          {props.isPopupView &&  
            <a className="input-group m-t-10">
              <input type="text" placeholder="Search" onChange={e=>search(e)} className="form-control input-sm"/>
              <span className="input-group-btn">
                <p type="button" className="bg-highlight input-sm">
                  <i className="fa fa-search white"></i>
                </p>
              </span>
            </a>
          }

          {!props.isPopupView &&<JourneyTitle title={"Nearby Colleges Search"} project={journey} />}
        </div>
        
        <div className="col-xs-6">
          <Link data-tip="Filter Favorite Colleges" className="bg-highlight round-btn m-t-10 p-5" onClick={e=>toggleSearchFilterModal(e)}>
            Advanced Search
          </Link>
        </div>
        <div className="col-xs-6 pull-right ht-50">
          <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
        </div>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        <div className="col-xs-12 table-responsive">
          <div className="scrollable">
          <table className="shadow-small">
            <thead>
              <tr className="white bg-black">
                <th onClick={e=>sort('data.name')}>Name 
                  <SortDirection sort_column={requestParams.sort_column} column="data.fj_edu_goal_name" reverse={requestParams.reverse}/>
                </th>
                <th onClick={e=>sort('data.state')}>State 
                  <SortDirection sort_column={requestParams.sort_column} column="data.subject" reverse={requestParams.reverse}/>
                </th>
                <th onClick={e=>sort('data.city')}>City 
                  <SortDirection sort_column={requestParams.sort_column} column="data.list_of_states" reverse={requestParams.reverse}/>
                </th>
                <th onClick={e=>sort('data.school_link')}>Url
                  <SortDirection sort_column={requestParams.sort_column} column="data.college_preferred_type" reverse={requestParams.reverse}/>
                </th>
                <th>Favorites</th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </table>
          <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
          </div>
        </div>
      </div>

      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedCollege} type="college"/>}
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedCollege} type="college"/>}
      {isSuccessMessageOpen && (
        <GenericModal
          component={NotificationPopup}
          header="Success"
          message="Added Successfully"
          onSuccess={toggleSuccessMessage}
          isOpen={isSuccessMessageOpen}
          toggleModal={toggleSuccessMessage}/>
      )}

      <SideMenuBar helpId="4"/>

      {isSearchFilterOpen &&  <GenericModal component={CollegeProfileForm} title="Filter"
        setSearchFilter={setSearchFilter} {...collegeSearchProps} 
        toggleModal={toggleSearchFilterModal}/>
      }
    </Fragment>
  )
};

export default CollegeTableList;