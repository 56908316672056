import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";
class DigitalContentService extends BaseService{
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.backend}digital_contents`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.backend}digital_contents/${req}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'digital_contents',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }



  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}digital_contents/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.backend}digital_contents/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }*/

}

export default new DigitalContentService('backend', 'digital_contents');