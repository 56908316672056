import React ,{useState, Fragment, useContext} from "react"
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import UserFormService from "../UserFormService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import Cs from '../../../services/CommonService'
import CheckAccess from "../../Journey/Roles/CheckAccess"
import UserFormFieldService from "../UserFormFieldService"

let selectedField = null
const FormFields = (props) => {

  let history = useHistory()    
  const { state:{screen, user:currentUser} } = useContext(AuthContext)
  const [activeIndex, setActiveIndex] = useState()
  const [visible, setVisible] = useState(false)
  const [formFields, setFormFields] = useState(props.formFields)
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()

  const toggleMenu =(i) =>{
    setActiveIndex(i==activeIndex?null:i)
  }

  const deleteField = (e) => {
    e.preventDefault()
    UserFormFieldService.delete(selectedField.id).then((res)=>{
      if(res.status == 204){
        setFormFields(formFields.filter(i => i.id !== selectedField.id))
      }
    })
    toggleDeleteModal(e)
  }

  const confirmDelete = (e, form_field) =>{
    e.preventDefault()
    selectedField = form_field
    toggleDeleteModal(e)
  }

  const Menu = ({form_field}) => ( 
    <span className="dropup pull-right">
      {CheckAccess.hasStepActivityAccess(currentUser, props.journeyCategory, props.form) &&
        <Fragment>
          <a>
            {currentUser.id == form_field.created_by &&
              <i className='fas fa-bars'/>
            }
          </a>
          {currentUser.id == form_field.created_by &&
            <div className="dropup-content w-100p">
              <a href="#" onClick={e => props.openFormFieldModal(e, form_field)}>
                <i className="far fa-edit"/> Edit
              </a>
      
              <a href="#" onClick={e => confirmDelete(e, form_field) }>
                <i className="far fa-trash-alt"/> Delete
              </a>
            </div>
          }
        </Fragment>
      }
    </span>
  )

  const Card = ({form_field}) => {
    return(
      <div className="p-5 font-14">
        <p className="pull-right font-10" style={{border: "1px solid", padding: "2px"}}>
          {form_field.created_user.name}-{Cs.formatUpdateDate(form_field.created_at)}
        </p>
        <div className="m-t-5">{form_field.input_type}</div>
        <div className="m-t-5">{form_field.description}</div>
      </div>
    )
  }

  const RenderCard = ({form_field}) =>{
    return <Card form_field={form_field} />
  }

  return (
    <Fragment>
      <div className="p-5 ht-350 scroll-auto">
        <strong>Fields</strong>
        {formFields && formFields.map((s, i) =>
          <Fragment key={i}>
            <div className="card-activity" onClick={e=>toggleMenu(i)}>
              <Menu form_field={s}/>
              <div className="w-90p">{s.label}</div>
            </div>
            {activeIndex == i && <RenderCard form_field={s} /> }
          </Fragment>
        )}
      </div>
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteField}/>
    </Fragment> 
  )

}

export default FormFields;