import React, {Fragment, useState, useEffect, useContext} from "react"
import { Link, Redirect } from 'react-router-dom'
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import SubscriptionService from "./SubscriptionService"
import ProductList from "./ProductList"
import Cs from "../../services/CommonService"
import AccountConfirmation from '../Common/AccountConfirmation'
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"
import CheckAccess from "../Journey/Roles/CheckAccess"

const PricingTable = (props) => {
  useStyle('pricing')
  useStyle("add_role_dialog")
  useStyle("filter_dialog")
  
  const params = props.match.params
  const state = props.location.state
  const queryParam = querystringify.parse(props.location.search)

  const { state: { screen, user: currentUser, isAuthenticated: isAuthenticated }, dispatch} = useContext(AuthContext)
  const [productSelected, setProduct] = useState(null)
  const [customer, setCustomer] = useState()
  const [subscriptionUser, setSubscriptionUser] = useState()
  const [loading, setLoading] = useState(true)

  const {isOpen: isSuccessMsgOpen, toggleModal: toggleSuccessMsg } = useModal()

  useEffect(() => {  
    if(isAuthenticated){
      stripeCreateCustomer()
    }
  }, [])

  const stripeCreateCustomer = (subscription) => {
    let req = {
      'email': currentUser.email
    }
    SubscriptionService.stripeCreateCustomer(req).then((res)=>{
      setCustomer(res.data?.customer)
    })
  }

  /*const selectSubscription = (subscription) =>{
    let req = {
      priceId: subscription.stripe_price_id,
      subscriptionId: subscription.id,
      customerId: customer.customer_id
    }
    
    SubscriptionService.createCheckoutSession(req).then((res)=>{*/
      /*Add this info to user subscritpion table and webhook*/
      /*if(res.status == 200){
        window.location.replace(res.data.session.url);
      }else{

      }
    }) 
  }*/

  const selectSubscription = (subscription) =>{
    const req = {
      id: subscription.id,
      journey_member_id:params.journey_member_id,
      journey_category_id:params.journey_category_id
    }
    setLoading(true)
    SubscriptionService.createSubscriptionUser(req).then(({status, data})=>{
      if(status == 201){
        setSubscriptionUser(data)
        currentUser.current_subscription = {
          name: subscription.data.name,
          journey_types: subscription.data.journey_types,
          subscription_id: data.subscription_id,
          subscription_user_id: data.id,
          journey_member_id:params.journey_member_id,
          created_at: Cs.formatUpdateDate(data.created_at)
        }
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser },
        })
        setLoading(false)
        toggleSuccessMsg()
      }
    })
    //setProduct(subscription)  
    //stripeCreateCustomer(subscription)  
  }

  const onPlanSuccessMsgDismiss = () =>{
    toggleSuccessMsg()
    const isParentOrTutor = CheckAccess.hasJourneyMemberAccess(currentUser)
    if(currentUser.setting.welcome_tour == null){
      props.history.push("/journeytour", state)
    }else if(state?.journey){
      state.journey.subscription_user_id = subscriptionUser.id
      Cs.routeJourney(state.journey, props)
    }else if(isParentOrTutor){
      props.history.push('/journey/members')
    }else{
      props.history.push('/journey')
    }
  }

  /*const stripeCreateCustomer = (subscription) => {
    let req = {
      'email': currentUser.email
    }
    SubscriptionService.stripeCreateCustomer(req).then((res)=>{
      setCustomer(res.data.customer)
      props.history.push({pathname: '/checkouts', state: {productSelected:subscription, customer:res.data.customer}})
    })
  }*/

  return(
    <Fragment>
      {loading && <div className="spinner"/>}
  	  <ProductList selectSubscription={selectSubscription} 
        accountInformation={currentUser?.current_subscription}
        currentUser={currentUser}
        isAuthenticated={isAuthenticated} 
        loading={loading} setLoading={setLoading}
        queryParam={queryParam}/>
        
      {isSuccessMsgOpen && 
        <GenericModal component={AccountConfirmation} title="Subscription Confirmed"
        isOpen={isSuccessMsgOpen} toggleModal={onPlanSuccessMsgDismiss}
        productSelected={productSelected} isPopupMode="true"/>
      }
  	</Fragment>
  )
}

export default PricingTable;