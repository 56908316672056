import React from "react"
import {Link} from "react-router-dom"
import Cs from '../../../../../services/CommonService'
import RatingView from "../../../../Common/RatingView"
import {FormFieldTable, RenderData} from "../../../UserRoles/NotificationPill"

const Resource = (props) => {
  let activity = props.activity;
  activity.updated_at = Cs.formatUpdateDate(activity.updated_at, 'MMM DD YYYY');
  let data = activity.data;
  let data_source = activity.data_source || {};
  let ds = props.dataSource;
  
  const cardStyle = {
    borderLeft : (activity.created_by == props.currentUser.id) ?'10px solid #27a1fb':''
  }

  if(!data)return null;

  /*const list =  (key) => data_source[key] && data_source[key].map((l, i) => (
    <div key={i}>
      {l.data && l.data.label}
    </div>
  ))

  const pillList =  (key) => data_source[key] && data_source[key].map((l, i) => (
    <span key={i} className="pill-xs m-5" style={{'backgroundColor': l.data.header_color}}>
      {l.data && l.data.label}
    </span>
  ))*/

  if(data){
    return(
      <div className="bg-white border-rounded-10 m-b-10">
        <div className="card-title-gray flex aligncenter p10" style={cardStyle}>
          <div className="w-100">
            <h4 className="fw700 card-heading">{data.name}</h4>
            <p className="f12">{activity.event_tracking_count} actions</p>
          </div>
          <div className="menu-drop-icon">
            { data.url_array ?
              <span id="portal_menu" className="portlet-dropdown">
                <a className="white link p-dropbtn" target="_blank" href={data.url}>
                  <i className="fas fa-share"/>
                </a>
                <div className="p-dropdown-content">
                  {data.url_array.map((link, i)=>
                    <a href={link} target="_blank" key={i}>
                      <i className="fas fa-share m-r-5"/>{Cs.extractHostName(link)}
                    </a>
                    )   
                  }
                </div>
              </span>
              :
              <a className="pull-right white link" target="_blank" href={data.url}> 
                <i className="fas fa-share"></i>
              </a>
            }
          </div>
        </div>
        <div className="card-content p10-20 ht-330 scroll-x">
          <div className="b-tab-table active m-t-5">
            <FormFieldTable 
              formFields={props.formFields} 
              data={data} 
              dataSource={data_source}/>
            <div className="border-dashed-sm p-b-10 m-b-5">
              <strong>Ratings</strong>
              <div>Experience</div>
              {activity.rating_avg.experience ? <RatingView noOfRating="1" sumOfRating={activity.rating_avg.experience /5}/> :'-'}
              <div>Residential</div>
              {activity.rating_avg.residential ? <RatingView noOfRating="1" sumOfRating={activity.rating_avg.residential /5}/>:'-' }
              <div>Online</div>
              {activity.rating_avg.online ? <RatingView noOfRating="1" sumOfRating={activity.rating_avg.online /5}/>:'-' }
            </div> 
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          <ul className="flex aligncenter">
            <li className="m-r-25">
              {activity.favourite_id ? <i className="far fa-heart font-18 text-muted"></i>:''}
            </li>
          </ul>
          <props.menu activity={activity}/>
        </div>
      </div>    
    )
  }
}

export default Resource;