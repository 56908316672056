import React, {useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CheckAccess from "../../../Roles/CheckAccess";
import Cs from "../../../../../services/CommonService";
import AssessmentService from "../../Assessments/AssessmentService";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import StepAssessments from "./StepAssessments"
import ReactTooltip from "react-tooltip";

const Assessment = (props) => {
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);

  let {assessment, assessmentList, share} = props;
  assessment.updated_at = Cs.formatUpdateDate(assessment.updated_at);
  let data = assessment.data;
  let ds = props.dataSource;

  const cardStyle = {backgroundColor: (assessment.course?.color || '#607D8B')}

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!data) return null;

  const handleClick =(id)=> {
      console.log('scroll')
      //document.getElementById('info').scrollTop -= 10;
      document.getElementById('info_'+id).scrollTop += 10;

  }

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16">
        <i className="fas fa-expand pull-right white expand" onClick={(e) => props.routeAssessment(assessment)}></i>
        {data.subject_name}
      </div>
      <div className="font-16">
        {Cs.formatUpdateDate(assessment.updated_at)} <span className="font-14">by {assessment.created_user.name}</span>
      </div>
    </div>
  )

  const routeAnswer = (assessment) => {
    props.history.push(`/career_training/answers/${assessment.journey_category_id}/${assessment.journey_profile_id}/${assessment.id}`)
  }

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left">
        <span id={`comment_${props.index}`} className="m-l-25"
          onClick={(e) => props.addNotes(e, assessment)}>
            <i className="far fa-comment font-18 text-muted" data-tip="Post/View messages"></i>
            <span className="badge up bg-lgrey">{assessment.comments_count || 0}</span>
        </span>
        <span id={`check_list_${props.index}`} className="m-l-25"
          onClick={(e) => {props.addChecklist(e, assessment);}}>
            <i className="far fa-check-circle font-18 text-muted" data-tip="Create/manage checklist"></i>
            <span className="badge up bg-lgrey">{assessment.check_list_count || 0}</span>
        </span>
        <span onClick={e => routeAnswer(assessment)} className="m-l-25">
          <i className="far fa-sticky-note font-18 text-muted" data-tip="View test responses"></i>
          <span className="badge up bg-lgrey">{assessment.answers_count}</span>
        </span>
      </div>
      <div className="pull-right">
        <props.menu assessment={assessment} />
      </div>
    </div>
  )

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header />
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table center">
            <tbody>
              <tr>
                <td className="text-center">
                  <b>Start </b><br/>{Cs.formatUpdateDate(data.date_time, 'MMM DD, YYYY hh:mm a') || '-'}
                </td>
                <td className="text-center">
                  <b>Submit by</b><br/>{Cs.formatUpdateDate(data.submission_deadline, 'MMM DD, YYYY hh:mm a') || '-'}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                {data.details.length > 100 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(props.index)}></p>}
                <div id={`info_${props.index}`} className="ht-50 scroll-auto" dangerouslySetInnerHTML={{ __html: data.details}}></div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepAssessments assessment={assessment} stepAssessments={assessment.step_assessments} journeyCategory={props.journeyCategory} 
          openQuestionModal={props.openQuestionModal} typeOfQuestion={props.typeOfQuestion} share={share}/>
        </div>
        <Footer/>
      </div>
    )
  }

  return(<Card/>)
};

export default Assessment;