import React, { useEffect, useState } from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import TrackRelationshipService from "../../../components/UserContacts/TrackRelationship/TrackRelationshipService";
import FormContainer from '../../../components/FormBuilder/FormContainer'

let formMode = null 
let track = {}
let data = {} 
let formFn = {}  

function TrackRelationshipForm(props) {

  const { state:currentUser, dispatch } = React.useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  let params = props.match.params

  const get = () => {
    TrackRelationshipService.get(params).then((res) => {
      if(res.status==200){
        track = res.data.relationship_tracking
        data = track.data || {}

        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function(res){
      track = null;
    })
  }
  
  useEffect(() => { 
    track = {} 
    data = {} 
    formFn = {} 
    formMode = 'create-form-submissions'
    if(params.id && params.id != 'null'){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [params.id])

  const create = (track) => {
    track.form_id = FormTemplateId.TrackRelationship
    track.member_id = params.member_id
    TrackRelationshipService.create(track).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (track) => {
    track.form_id = FormTemplateId.TrackRelationship
    TrackRelationshipService.update(track).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack()
  }


  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.TrackRelationship} 
          form={track} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default TrackRelationshipForm;