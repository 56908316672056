import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import JourneyPriceModelService from "./JourneyPriceModelService"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'

let formMode = null 
let priceModel = {} 
let data = {} 
let formFn = {}  

function JourneyPriceModelForm(props) {
  const { state:{ user:currentUser } } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  const formId = params.item_type == 'journey_profile'?FormTemplateId.JourneyPriceModal:FormTemplateId.CoursePriceModal

  const get = () => {
    JourneyPriceModelService.get(params.id || 0, {item_type:params.item_type, item_id:params.item_id}).then((res) => {
      if(res.status==200){
        priceModel = res.data.journey_price_model || {}
        data = priceModel.data || {}
        formMode = priceModel.id?'edit-form-submissions':'create-form-submissions';
        setFormRendered(true)
      }
    }).catch((res)=>{
       priceModel = null;
    })
  }
  
  useEffect(() => { 
    get()
    priceModel = {} 
    data = {} 
    formFn = {
      'form_type':'journey_price_model', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    } 
  }, [])

  const create = (price_model) => {
    price_model.journey_profile_id = params.journey_profile_id
    price_model.journey_category_id = params.journey_category_id
    price_model.item_type = params.item_type
    price_model.item_id = params.item_id 
    price_model.form_id = formId
    JourneyPriceModelService.create(price_model).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }else if(res.status == 422){
        alert(res.data.error)
      }
    }).catch((res)=>{
       
    })
  }

  const update = (price_model) => {
    JourneyPriceModelService.update(price_model).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect()
      }
    }).catch((res)=>{
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/career_training/project/portal/${params.journey_category_id}`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={formId} 
          form={priceModel} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default JourneyPriceModelForm;