import React, { useState, Fragment} from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from "react-tooltip";

const AddUserRole = ({ role, isOpen, toggleModal, addNewRole, dataSources, roles, invited_journey_members}) => {

  if(role){
    role.permissions = role.permissions || {};
    role.notification_comment = role.notification_comment || [];
    role.notification_step_activity = role.notification_step_activity || [];
  }
  
  if(role.id == null && roles.length == 0){
    role.filter_type = 'user' 
  }

  const [newRole, setNewRole] = useState(role);
  const [loading, setLoading] = useState(false);

  /* 
   * To check an item exist in checkbox options.
   */
  const setAccess = (access_type, key) => {
    let obj = newRole[key];
    if (obj[access_type]) {
      delete obj[access_type]
    }else {
      obj[access_type] = true; 
    }
    setNewRole({...newRole})
    if(!newRole.permissions.activity_admin)delete newRole.permissions.is_activity_admin_removeable;
  }

  const setNotification = (notification_type, key) => {
    newRole[key] = [notification_type];
    setNewRole({...newRole})
  }

  const exists = (notification_type, key) => {
    let list = newRole[key];
    return list.indexOf(notification_type) > -1
  }

  const setRoleType = (e) =>{
    role.filter_type = e.target.value;
    setNewRole({...newRole})
  }

  const setRole = (e) => {
    let r = roles[e.target.selectedIndex-1];
    role.role_record_id = parseInt(e.target.value);
    role.role_id = r.id;
  }

  const setInvitedUser = (e) => {
    let u = invited_journey_members[e.target.selectedIndex-1];
    role.email = u.email;
  }

  const OptionsObjList = ({list, id_key, label_key}) => list.map((o, k) =>
    <option key={k} value={o[id_key]}>{o[label_key]}</option>
  );

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      <div className="flex justspacebetween modal-title">
        <h4 className="f18 text-white fw500 p12-10-15">{role.id?'Update':'Add'} User</h4>
        <button className="btn-close"
          title="Close Modal" onClick={e=>toggleModal(e)}>×
        </button>
      </div>
    
    {loading ? <div className="spinner"></div> :
    <form className="ml-container">
      <div className="ml-section">
        
        <label><b>User Email</b></label>
        <input className="form-control ml-margin-bottom" type="text" defaultValue={role.email} disabled/>

        <label data-tip="Notification of new step activity"><b>Notification for Step Activity</b></label>
          {Object.keys(dataSources.notificationsTypes).map((p)=>
            <Fragment key={p}>
              <span className="radio-container" onClick={e => setNotification(p, 'notification_step_activity')}>
                <span>{dataSources.notificationsTypes[p]}</span>
                <input type="radio" checked={exists(p, 'notification_step_activity')} readOnly/>
                <span className="radio-checkmark"></span>
              </span>
            </Fragment>
          )}

        <label data-tip="Notification for new chat message"><b>Notification for Comments</b></label>
          {Object.keys(dataSources.notificationsTypes).map((p)=>
            <Fragment key={p}>
              <span className="radio-container" onClick={e => setNotification(p, 'notification_comment')}>
                <span>{dataSources.notificationsTypes[p]}</span>
                <input type="radio" checked={exists(p, 'notification_comment')} readOnly/>
                <span className="radio-checkmark"></span>
              </span>
            </Fragment>
          )}

        <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addNewRole(e, newRole);setLoading(true)}}>
          <b>{role.id?'Update':'Create'}</b>
        </div>
        
      </div>
    </form>}
    <ReactTooltip place="bottom" />
  </div>
</div>
    </React.Fragment>, document.body))
};

export default AddUserRole;

/*
{role.filter_type=='role' && roles.length > 0 && 
          <Fragment>
          <label><b>Role</b></label>
          <select onChange={e => setRole(e)} value={role.role_record_id} className="form-control ml-margin-bottom">
            <option value="null"></option>
            <OptionsObjList list={roles} id_key="record_id" label_key="role"/>
          </select>
          </Fragment>
        }

        {role.filter_type=='user' && <Fragment>
          <label><b>Email</b></label>
          <select onChange={e => setInvitedUser(e)} value={role.user_id} className="form-control ml-margin-bottom">
            <option value="null"></option>
            <OptionsObjList list={invited_journey_members} id_key="user_registration_id" label_key="email"/>
          </select>
          </Fragment>
        }

        {role.id==null && <Fragment>
          <label><b>Role</b></label>
          <select onChange={e => setRoleType(e)} value={role.filter_type} className="form-control ml-margin-bottom">
            <option value="null"></option>
            <option value="user">Add User</option>
            {roles.length>0 && <option value="role">Add Roles</option>}
          </select>
          </Fragment>
        }

  const setNotification = (notification_type, key) => {
    let list = newRole[key];
    let idx = list.indexOf(notification_type);
     if(idx > -1) {
       list = list.filter(i => i !== notification_type)
       newRole[key] = list;
     }else {
       list.push(notification_type)
     }
     setNewRole({...newRole})
  }


  const exists = (notification_type, key) => {
    let list = newRole[key];
    return list.indexOf(notification_type) > -1
  }

   <label><b>Permissions</b></label>
        <div className="m-b-5">Check the box to share your rights</div>

          <span className="checkbox-container" onClick={e => setAccess('activity_admin', 'permissions')}>
            <span data-tip="Assign permission to create a new step activity, post messages, post checklist items, and send emails to this Tile">
              Authorize user for this tile.
            </span>
            <input type="checkbox" checked={!role.permissions.activity_admin} readOnly/>
            <span className="checkbox-checkmark"></span>
          </span>

        */