import React ,{useState, Fragment} from "react";
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import StepActivitiesService from "./StepActivitiesService";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import useModal from "../../../../hooks/useModal";
import CommonService from '../../../../services/CommonService';

let selectedStepActivity = null;

const CareerStepActivity = (props) => {
  let history = useHistory()  	
  //let stepActivities = props.stepActivities;
  const [activeIndex, setActiveIndex] = useState();
  const [visible, setVisible] = useState(false);
  const [stepActivities, setStepActivities] = useState(props.stepActivities);
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();

  const toggleMenu =(i) =>{
    setActiveIndex(i)
    setVisible(!visible)
  }

  const deleteStepActivity = (e) => {
    e.preventDefault();
    StepActivitiesService.delete(selectedStepActivity).then((res)=>{
      if(res.status==204){
        setStepActivities(stepActivities.filter(i => i.id !== selectedStepActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const confirmDelete = (e, step_activity) =>{
    e.preventDefault();
    selectedStepActivity = step_activity;
    toggleDeleteModal(e);
  }

  const updateStatus = (e, step_activity) =>{
    e.preventDefault();
    step_activity.data.completion_status = step_activity.data.completion_status=='1'?'0':'1';
    StepActivitiesService.update(step_activity).then((res)=>{
      if(res.status==204){
        setStepActivities([...stepActivities]);
      }
    })
  }

  const FileList = ({files}) => {
    return(<Fragment>
      {files && files.map((file, k) =>  (
        <a key={k} className="m-t-5 inline-files-list" target="_blank" href={CommonService.getFileUrl(file)}>
          <img src={CommonService.getIconByType(file)} width="35" height="35"/>
          <span className="m-l-5">{CommonService.getFileName(file)}</span>
        </a>
      ))}
      </Fragment>)
  }

  const Menu = ({step_activity}) => (
    <span className="dropup pull-right">
      <a><i className='fas fa-bars'></i></a>
      <div className="dropup-content">
        <Link to={`/highschool/step_activity/form/${step_activity.journey_category_id}/${step_activity.education_journey_profile_id}/${step_activity.activity_id}/${step_activity.activity_form_id}/${step_activity.activity_type}/${step_activity.id}`}>
          <i className="far fa-edit"/> Edit
        </Link>
        <a href="#" onClick={e => {confirmDelete(e, step_activity)} }>
          <i className="far fa-trash-alt"/> Delete
        </a>
        <a href="#" onClick={e=>updateStatus(e, step_activity)}>
          <i className={step_activity.data.completion_status=='1'?'far fa-check-circle':'far fa-circle'}/> Status
        </a>
      </div>
    </span>
  )

  const SponsorCard = ({step_activity, data}) => {
    return(<div className="p-5">
      <div className="vcard-field">
        <strong>Quantity</strong>
        <a href="#">{data.quantity_requested}</a>
      </div>
      <div className="vcard-field">
        <strong>Drop Off</strong>
        <a href="#">{data.drop_off}</a>
      </div>
      <div className="vcard-field">
        <strong>Notes</strong>
        <div dangerouslySetInnerHTML={{ __html: data.notes }}  />
      </div>
    </div>)
  }

  const TeamBookCard = ({step_activity, data}) => {
    return(<div className="p-5 font-14">
    <p className="pull-right" style={{    border: "1px solid",
  padding: "2px"}}>{step_activity.author_name} <br/>{CommonService.formatUpdateDate(step_activity.created_at)}</p>
    <FileList files={data.upload_files}/>
    <a href={data.reference_url} target="_blank">{CommonService.extractHostName(data.reference_url)}</a>
    <div className="m-t-5" dangerouslySetInnerHTML={{ __html: data.notes }}  />
  </div>)
  }

  const TeamCard = ({step_activity, data}) => {
    return(<div className="p-5 font-14">
    <p className="pull-right" style={{    border: "1px solid",
padding: "2px"}}>{step_activity.author_name}<br/>{CommonService.formatUpdateDate(step_activity.created_at)}</p>
<a href={data.reference_url} target="_blank">{data.reference_url}</a>
<FileList files={data.upload_files}/>
<a className="m-t-5" href="#">{data.reminder}</a>
<div className="m-t-5" dangerouslySetInnerHTML={{ __html: data.notes }}  />
</div>)
  }

  const FileLink = ({step_activity, data}) =>{
    if(step_activity.activity_type == 'step_activity') {
      return (<Fragment> 
        {data.file_upload && data.file_upload.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
       </Fragment>  
      )
    }else if(step_activity.activity_type == 'team_step_activity'){
      return (<Fragment> 
        {data.upload_files && data.upload_files.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
       </Fragment>  
      )
    }else{
      return null
    }
  }

  const RenderCard = ({step_activity, data}) =>{
    if(step_activity.activity_type == 'sponsor_step_activity'){
      return <SponsorCard step_activity={step_activity} data={data} />
    }else if(step_activity.activity_type == 'step_activity') {
      return <TeamBookCard step_activity={step_activity} data={data} />
    }else{
      return <TeamCard step_activity={step_activity} data={data} />
    }
  }
  
  return (
  	<Fragment>
  		<div>
        {stepActivities && stepActivities.map((s, i) => {
          let step_ribbon_color
         
         if(s.updated_at){
           let duration
           var a = moment(s.updated_at);
           var b = moment();

           duration = b.diff(a, 'days')+1;
          
           if(duration < 7){
             step_ribbon_color= 'green'
           }else if(duration > 7  && duration < 14){
             step_ribbon_color	= 'orange'
           }else {
             step_ribbon_color	= 'red'
           }

         }
         else{
           let duration
           var a = moment(s.created_at);
           var b = moment();
           duration = b.diff(a, 'days')+1;

           if(duration < 7){
             step_ribbon_color= 'green'
           }else if(duration > 7  && duration < 14){
             step_ribbon_color	= 'orange'
           }else {
             step_ribbon_color	= 'red'
           }
         }
          return (
          <Fragment key={i}>
            <div className="step-activity" style={{borderLeft: '5px solid '+step_ribbon_color}} onClick={e=>toggleMenu(i)}>
           <div className="pull-left">{s.data.name}</div>
               <Menu step_activity={s}/>
               <FileLink step_activity={s} data={s.data} /> 
              <span className="pull-right m-r-15 font-12">{s.data.reminder}</span>
            </div>
            {activeIndex == i && visible && <RenderCard step_activity={s} data={s.data} /> }
          </Fragment>
        )})}
        </div>
        <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteStepActivity}/>
  	</Fragment>
  )

};

export default CareerStepActivity;