import React, { useState, Fragment, useEffect, useContext,useRef} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Activity from "../../Activities/List/Activity";
import CollegeActivityService from "../../Activities/CollegeActivityService";
import FavouritesService from "../../../../../services/FavouritesService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import CardMenu from "../../../../Modals/CardMenu";
import { Link } from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import useStyle from "../../../../../hooks/useStyle";
import CheckAccess from "../../../Roles/CheckAccess";
import ActivityTypeMenu from "../../Activities/List/ActivityTypeMenu";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import CalendarModal from "../../../../Modals/CalendarModal";
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import {NavIcon,CardMenuInfo,MenuItem} from '../../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import HelpMessageList from '../../../../../constants/HelpMessageList'
import CommonService from "../../../../../services/CommonService";
import ReactTooltip from "react-tooltip";

let requestParams = {}
let selectedActivity = {}
let dataSource = {}
let journeyCategory = null;
let cardViewCount = 1;
let share = null;

let step_activity_form = {
  team_book: { form_id: 260, type: "step_activity" },
  team: { form_id: 232, type: "team_step_activity" },
  sponsor: { form_id: 305, type: "sponsor_step_activity" },
}

const JourneyActivityList = (props) => {
  /*useStyle("card")
  useStyle("card_table")
  useStyle("add_role_dialog")*/

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "col-xs-12 col-sm-6 col-md-8";
  let cardHeightCss = "ht-400";
  const showIcons = params.journey_category_id != null;
  if (queryParam.id || props.id) {
    cardGridCss = "col-xs-12 p-0";
    cardHeightCss = "vh-80";
    queryParam.id = queryParam.id || props.id;
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [journeyActivityList, setJourneyActivityList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [isFavourite, setIsFavourite] = useState(false) 
  const [pagemeta, setPagemeta] = useState([])
  const [formFields, setFormFields] = useState([]);

  const {isOpen: isActivityTypeOpen, toggleModal: toggleActivityType,} = useModal()
  const {isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const {isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal,} = useModal()
  const {isOpen: isCalendarOpen, toggleModal: toggleCalendarModal,} = useModal()
  const {isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal,} = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const indexEle = useRef(null)

  useEffect(() => {
    journeyCategory = null
    requestParams = {
      'is_favourites':isFavourite,
      'data_source_params': { 
        'data_source_id': [3196, 2214, 3354], 
        'response_type': "object" 
      },
      'journey_profile_id': params.journey_profile_id,
      'filter_by_role': false,
      'journey_category_id': params.journey_category_id,
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': "updated_at",
      'sort_direction': "desc",
      'totalItems': 0,
      'search': props.searchString,
      'id': queryParam.id,
      'load_form_fields_in_result_set':true,
    }

    getJourneyActivityList()
    ReactTooltip.rebuild()

    return () => {
     requestParams = {} 
    }
  }, [props.searchString, currentpage, isFavourite,props.location])

  let getJourneyActivityList = () => {
    setLoading(true)
    CollegeActivityService.getAll(requestParams).then((res) => {
      if (res.status === 200) {
        dataSource = res.data.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        journeyCategory = res.data.journeyCategory;
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
        share = res.data.invited_journey_member
        CheckAccess.userAccess(currentUser, journeyCategory, share)
        if(requestParams.search){
          setJourneyActivityList(res.data.activities)
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setJourneyActivityList(res.data.activities)
        }else{
          setJourneyActivityList([...journeyActivityList, ...res.data.activities])  
        }
         setFormFields(res.data.form_fields)
        setLoading(false)
        res.data.activities.length == 0 && !requestParams.search && toggleHelpMessage()
      }
    })
  }

  const pinFavourite = (e) =>{
    e.preventDefault()
    setIsFavourite(!isFavourite)
  }

  const openFileLinks = (e) => {
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const addNotes = (e, a) => {
    selectedActivity = a
    toggleCommentModal(e)
  }

  const addChecklist = (e, a) => {
    selectedActivity = a
    toggleChecklistModal(e)
  }

  const addReminderdate = (e, a) => {
    selectedActivity = a
    toggleCalendarModal(e)
  }

  const deleteActivity = (e) => {
    e.preventDefault()
    CollegeActivityService.delete(selectedActivity.id).then((res) => {
      if (res.status == 204) {
        setJourneyActivityList(
          journeyActivityList.filter((i) => i.id !== selectedActivity.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedActivity = a
    toggleMenuModal(e)
  }

  const routeActivity = (activity) => {
    props.history.push(
      `/college/activity/${params.journey_category_id}/${params.journey_profile_id}?id=${activity.id}`
    )
    setJourneyActivityList(
      journeyActivityList.filter((i) => i.id == activity.id)
    )
  }

  const copyToPlanner = (e, activity) => {
    e.preventDefault()
    CollegeActivityService.copyToPlanner(activity.id).then((res) => {
      if (res.status == 200) {
      }
    })
  }

  const menuItems = ({ item }) => {
    const title = item.data && item.data.record_title.replace(/\s/g, "_")

    return (
      <Fragment>
        <CardMenuInfo formId={item.activity_form_id} recordId={item.record_id} 
          createdAt={item.created_at} />

        {CheckAccess.hasEditAccess(currentUser, journeyCategory, item) && (
          <MenuItem id="edit_icon" 
            link={`/college/activity/form/${item.journey_category_id}/${item.journey_profile_id}/${item.activity_form_id}/${item.activity_type}/${item.id}`}
            icon="far fa-edit"
            action="Edit"
            />
        )}

        {CheckAccess.hasDeleteAccess(currentUser, journeyCategory, item) && (
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDeleteModal(e)}}
          />
        )}

        {CheckAccess.hasStepActivityAccess(currentUser, journeyCategory, item) && (
          <MenuItem id="activity_icon" 
            link={`/college/step_activity/form/${item.journey_category_id}/${item.journey_profile_id}/${item.id}/${FormTemplateId.EducationActivity}/step_activity`}
            icon="far fa-sticky-note"
            action="Add Activity"
          />
        )}

        {CheckAccess.hasRoleAccess(currentUser, journeyCategory, item) && (
          <Link to={{pathname: `/journey/user_role/${item.journey_category_id}/${item.journey_profile_id}/edu_college_journey.activity/${item.id}`,
            state: { journey: props.location.state, activity: item },
          }}>
            <i className="far fa-eye-slash text-muted font-15" aria-hidden="true"></i>
            <span className="font-13">
              Permissions
              <i className={`fas ${item.user_roles == null ? "fa-times m-l-120 m-t-n2 lred" : "fa-check m-l-120 m-t-n2 green"} `}></i>
            </span>
            <i className="fa fa-angle-right"></i>
          </Link>
        )}

        {CheckAccess.hasEditAccess(currentUser, journeyCategory, item) && (
          <a href="#" onClick={(e) => copyToPlanner(e, item)}>
            <i className="far fa-clone font-15" aria-hidden="true"></i>
            <span className="font-13">Copy to Planner</span>
            <i className="fa fa-angle-right"></i>
          </a>
        )}

        <a href={`mailto:${title}-CA-${item.record_id}@cuetree.com`} target="_blank">
          <i className="far fa-envelope font-15" aria-hidden="true"></i>
          <span className="font-13">{`${title}-CA-${item.record_id}@cuetree.com`}</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ activity }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, activity)} className="fas fa-bars m-r-15"/>
  )

  const journeyActivityCard = journeyActivityList && journeyActivityList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <Activity activity={a} addNotes={addNotes} 
        addChecklist={addChecklist} dataSource={dataSource} 
        menu={Menu} journeyCategory={journeyCategory} 
        cardHeightCss={cardHeightCss} routeActivity={routeActivity} 
        addReminderdate={addReminderdate} index={i} 
        formFields={formFields}/>
      
      {selectedActivity.id === a.id && 
        <ProjectMenu isOpen={isMenuOpen} 
        toggleModal={toggleMenuModal} 
        item={selectedActivity} type="activity" 
        MenuItems={menuItems} menuHeight="365px" />      
      }
    </div>
  ))

  if (queryParam.id && !loading && journeyActivityList.length === 0) {
    return (
      <NotificationPopup
        iconClass="fa fa-times-circle lred font-24"
        header="Not Found"
        message="Sorry, this message was deleted by the author"
      />
    )
  }

  return (
    <div id="ct">
      <div className="app_content">
      {!props.id &&
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex aligncenter">
            <JourneyTitle title={"Activities"} project={journeyCategory} />
          </div>

          <div className="flex aligncenter">        
            <NavIcon id="add_new_activity" dataTip="Create New Activity" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              onSelect={toggleActivityType} icon="fas fa-plus"/>

            {journeyCategory && 
              <NavIcon id="tile_view" dataTip="Tile View" 
              link={{
                pathname:`/college/activity/type/${params.journey_category_id}/${journeyCategory.education_journey_profiles.id}`,
                state:journeyCategory
              }}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-file"/>
            }

            <NavIcon id="home_icon" dataTip="Back to Journey Home" 
              link={`/college/portal/${params.journey_category_id}`}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-home"/>

            <NavIcon id="file_links" dataTip="Journey Files and Links" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              onSelect={openFileLinks} icon="fas fa-link"/>
            
            {!queryParam.id && 
              <NavIcon id="pin" dataTip="Filter Favourites" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon={"fas fa-thumbtack " + (isFavourite ? 'black' : 'white')}
              onSelect={pinFavourite} />
            }

            {queryParam.id  && 
              <NavIcon id="back_icon" dataTip="Back to Activity" 
                onSelect={e=>{props.history.goBack()}}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-arrow-left"/>
            }
          </div>
          <div className="clear"/>
        </div>
      }
      
      <div className="app-content-section">
        <div className="row">
          {journeyActivityCard}
        </div>
      </div>  
      
      {isCommentOpen && (
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedActivity} type="edu_college_journey.activity"/>
      )}
      {isChecklistOpen && (
        <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} 
        item={selectedActivity} type="edu_college_journey"/>
      )}
      {isActivityTypeOpen && (
        <ActivityTypeMenu journeyCategoryId={params.journey_category_id} 
        journeyProfileId={params.journey_profile_id} params={dataSource} 
        isOpen={isActivityTypeOpen} toggleModal={toggleActivityType}/>
      )}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity}/>
      {isCalendarOpen && (
        <CalendarModal toggleModal={toggleCalendarModal} item={selectedActivity}
         type="edu_college_journey.activity"/>
      )}
      {isFileLinkOpen && (
        <JourneyFilesLinksModal toggleModal={toggleFileLinkModal} 
        journeyCategory={journeyCategory}/>
      )}
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      )}
      <SideMenuBar helpId="15" />
    </div></div>
  )
}

export default JourneyActivityList;