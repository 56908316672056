import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import Cs from "../../services/CommonService"
import useModal from '../../hooks/useModal'
import UserAccessComponents from "../Subscriptions/UserAccessComponents"
import { ChatGrayIcon, CheckListGrayIcon, HamburgerMenuIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import {CTTooltip} from "../Common/CueTooltip"

const Member = (props) => {
  const {member, membersType, openJourneyType, history} = props
  let data = member.data
  let ds = member.data_source

  let bodyStyle = { height: "200px", overflowY: "auto" }
  let profileImage = 'images/avatars/2s.png'

  const cardStyle = {
    borderTop: "5px solid " + ("#27a1fb"),
    height: "320px"
  }

  const {isOpen:isUserAccessListOpen, toggleModal:toggleUserAccessListModal} = useModal()

  const routeMember = (member) =>{
    openJourneyType(member)
    //Cs.routeJourney(member.journey, props)
      //history.push('/plans/'+member.id)
  }

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 m-t-10">
        <div className="card-title card-title-gray p10">
          <span className="badge m-r-5 pull-right bg-highlight white cursor-pointer" onClick={()=>routeMember(member)}>
            Add Journey
          </span>

          <div className="flex aligncenter">
            <img src={profileImage} className="rounded-circle bg-highlight" width="50"/>
            
            <Link to={`/journey?journey_member_id=${member.id}`} id="head" className="content">
              <div className="w-100">
                <h4 className="fw700 card-heading truncate">{member.data.name}</h4>
                <p className="f12">since {Cs.formatUpdateDate(member.created_at)}</p>
              </div>
            </Link>
          </div>

          {/* 
          <div className="menu-drop-icon flex justifycenter aligncenter">
            <a href="#">
              <img src="./images/menu-drop.png" alt="" />
            </a> 
          </div>*/}
        </div>
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
            <table className="table card-table">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <strong>Email</strong>
                      <a href="mailto:{data.email}"> &nbsp; {data.email}</a>
                    </div>
                    <div>
                      <strong>Parent Email</strong>
                      <a href="mailto:{data.email_parent}"> &nbsp; {data.email_parent}</a>
                    </div>
                  </td>
                </tr>
                {member.subscription &&
                  <tr>
                    <td>
                      <strong>Subscription</strong>
                      <div>{member.subscription?.name}</div>
                    </td>
                  </tr>
                }
                {member.journeys &&
                  <tr>
                    <td>
                      <strong>Journey</strong>
                      {member.journeys.map((j, i)=>
                        <div key={i} onClick={()=>Cs.routeJourney(j, props)} className="badge bg-highlight white">
                          {Cs.journeyTitle(j, j?.education_journey_profiles)}
                        </div>
                      )}
                    </td>
                  </tr>
                }
              </tbody>
            </table>       
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          <ul className="flex aligncenter">
            <li className="m-r-25" data-tip="Add Comments">
              <LinkFontAwesomeIcon css="far fa-comment text-white f16" 
              onSelect={(e) => props.addNotes(e, member)}/>
            </li>
            <li className="m-r-25" data-tip="Add Checklist">
              <CheckListGrayIcon onSelect={(e) => props.addChecklist(e, member)}/>
            </li>
            <li className="m-r-25" data-tip="Mails">
              <LinkFontAwesomeIcon css="far fa-envelope text-white f16" count={member.checklists_count}/>
            </li>
            {member.subscription &&
              <li className="m-r-25" data-tip="User Access List">
                <LinkFontAwesomeIcon css="fas fa-user-lock text-white f16" 
                  onSelect={(e) => toggleUserAccessListModal()}
                  count={member.checklists_count}/>
              </li>
            }
          </ul>
          <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, member)}/>
        </div>
      </div>
    
      <CTTooltip/>

    {isUserAccessListOpen && 
      <UserAccessComponents toggleModal={toggleUserAccessListModal} 
      isOpen={isUserAccessListOpen} journeyMemberId={member.id}
      subscription={member.subscription}/>
    }
    </Fragment>
  )
}

export default Member;