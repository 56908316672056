import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import HSReel from "./HSReel";
import { Link } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import useStyle from "../../../../hooks/useStyle";
import PreLoadSpinner from "../../../PreLoadSpinner";
import SideMenuBar from "../../../Common/SideMenuBar";
import Cs from "../../../../services/CommonService";

let data_source = {}
let journey_category = null;

const HSReelList = (props) => {
  useStyle("card")
  useStyle("card_table")
  useStyle("add_role_dialog")
  
  let params = props.match.params;
  let {project, child} = props.location.state || {};
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "pull-left";
  let cardHeightCss = "";
  if (props.id) {
    cardGridCss = "col-sm-12";
    cardHeightCss = "mih-380";
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)



  useEffect(() => {
   

  }, [props.searchString])


  /*if(counselingList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const ReelCard = ({counselingList}) => {return (
    <div className={cardGridCss} >
      <HSReel />
    </div>
  )}

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
        </div>
        <div className="col-xs-4">
          
        </div>
      </div>
        
      <div className="page-content row">
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Suggestion</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
        </div>
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Recent</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
        </div>
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Trending</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
        </div>
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Academic</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
        </div>
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Extracurricular</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
        </div>
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Special Experience</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
        </div>
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Scheduling</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
          <ReelCard />
        </div>
      </div>

    
      <SideMenuBar/>
    </Fragment>
  )
}

export default HSReelList;