import React from "react";
import PrivateRoute from "../../../routes/PrivateRoute";
import CoursePreparationList from "../../Journey/CourseJourney/CoursePreparations/List/CoursePreparationList";
import CoursePreparationForm from "../../Journey/CourseJourney/CoursePreparations/Form/CoursePreparationForm";
import PortalDetails from "../../Journey/CourseJourney/CoursePortalPage/PortalDetails";
import PreparationStepForm from "../../Journey/CourseJourney/PreparationSteps/PreparationStepForm";
import CourseSchedules from "../../Journey/CourseJourney/Calendars/CourseSchedules";
import JourneyPlannerList from '../../Journey/HighSchoolJourney/Planners/List/JourneyPlannerList'

const CourseRouter = (props) => (
  <React.Fragment>
    <PrivateRoute
      path="/course/preparation/:journey_category_id?/:journey_profile_id?"
      setHelpId={props.setHelpId}
      searchString={props.searchString}
      component={CoursePreparationList}
      exact
    />
    <PrivateRoute
      path="/course/preparation/form/:journey_category_id/:journey_profile_id/:id?"
      component={CoursePreparationForm}
      exact
    />
    <PrivateRoute
      path="/course/planners/:journey_category_id/:journey_proile_id?"
      searchString={props.searchString}
      component={JourneyPlannerList}
      exact
    />
    <PrivateRoute
      path="/course/portal/:journey_category_id?"
      app={props.app}
      component={PortalDetails}
      exact
    />
    <PrivateRoute
      path="/course/step/form/:journey_category_id/:journey_profile_id/:course_preparation_id/:id?"
      component={PreparationStepForm}
      exact
    />
    <PrivateRoute
      path="/course/schedules/:journey_category_id/:journey_profile_id/:journey_type"
      component={CourseSchedules}
      exact
    />
  </React.Fragment>
);

export default CourseRouter;
