import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../../../Common/arrayMove';

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt m-t-5 pull-left"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const PlannerGroupItems = ({plannerList, updateSortOrder, groupList, setGroupList, group, index}) => {
  
  let [planners, setPlanners] = useState(plannerList || []);

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      console.log(planners[newIndex].data.name)
      nextItemId = planners[newIndex].group_item_id
    }else if(newIndex+1 == planners.length){
      console.log(planners[newIndex].data.name)
      priorItemId = planners[newIndex].group_item_id
    }else if(oldIndex > newIndex){
      console.log(planners[newIndex-1].data.name)
      console.log(planners[newIndex].data.name)

      priorItemId = planners[newIndex-1].group_item_id
      nextItemId = planners[newIndex].group_item_id
    }else if(oldIndex < newIndex){
      console.log(planners[newIndex].data.name)
      console.log(planners[newIndex+1].data.name)

      priorItemId = planners[newIndex].group_item_id
      nextItemId = planners[newIndex+1].group_item_id
    }

    setGroupList([])
    
    function onSuccess(){
      /* planners = arrayMove(planners, oldIndex, newIndex)
       setPlanners([...planners])*/

      groupList[index].planners = arrayMove(planners, oldIndex, newIndex)
      setGroupList([...groupList])
    }

    updateSortOrder(planners[oldIndex].group_item_id, priorItemId, nextItemId, group.id, onSuccess)
  }

  const SortableItem = sortableElement(({o}) => 
    <div className="pill bg-green white d-block">
      <DragHandle/>
      {o.data.name || o.data.planner_heading}
    </div>
  )

  const plannerData = planners.map((o, k) =>
    <SortableItem key={`item-${k}`} index={k} o={o} />
  )

  return (
    <SortableContainer onSortEnd={onSortEnd} distance={1} useDragHandle>
      {plannerData}
    </SortableContainer>
  )

}

export default PlannerGroupItems;