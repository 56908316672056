import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../../hooks/useStyle';
import useSort from '../../../../../hooks/useSorting';
import SortDirection from '../../../../Common/SortDirection'
import PlannerGroupItems from "./PlannerGroupItems";

const PlannerGroupTable = ({groupList, setGroupList, getGroupList, requestParams, updateSortOrder, loading}) => {
  useStyle('table');  
  const {sort} = useSort(requestParams, getGroupList);

  const tableData = groupList.map((o, k) =>
    <tr key={k}>
      <td data-title="Id">{o.record_id}</td>
      <td data-title="Group">{o.title}</td>
      <td data-title="Planners">
        <PlannerGroupItems plannerList={o.planners} updateSortOrder={updateSortOrder} loading={loading}
        group={o} groupList={groupList} setGroupList={setGroupList} index={k}/>      
      </td>
    </tr>
  )

  if(loading)
    return(<div className="spinner"></div>)

  return (
    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')}>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('title')}>Group 
              <SortDirection sort_column={requestParams.sort_column} column="title" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('title')}>Planners
              <SortDirection sort_column={requestParams.sort_column} column="title" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

}

export default PlannerGroupTable;