import env from '../../../../env';
import axios from 'axios';

class TeamActivityService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_team_backend}activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_team_backend}activities/${req}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_team_backend+'activities',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getCatId(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.edu_team_backend}activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.edu_team_backend}activities/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(activity) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.edu_team_backend}activities/${activity.id}?journey_category_id=${activity.journey_category_id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  reports(req){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_team_backend+'activities/report', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  copyToPlanner(id){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_team_backend+'activities/clone/'+id).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })  
  }
}

export default new TeamActivityService();