import React, { useState, Fragment, useContext, useEffect} from 'react'
import ReactDOM from 'react-dom'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import ReactTooltip from "react-tooltip"
import {OptionsObjList, InputTags, Radio} from "../../Common/FormInput"
import UserFormService from "../UserFormService"
import {Link, useParams} from "react-router-dom"
import FormHelper from '../../../components/FormBuilder/FormHelpers'

let searchKeyword = null
let timer = null
const submissionTypes = [
  {sid:'single', label:'Single Submission'},
  {sid:'multiple', label:'Multipe Submission'}
]
const editableTypes = [
  {sid:true, label:'Yes'},
  {sid:false, label:'No'}
]

const AddForm = ({toggleModal, existingForm, formList, setFormList}) => {

  const { state:{user:currentUser} } = useContext(AuthContext)
  const [form, setForm] = useState(existingForm || {})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const params = useParams()

  /*useEffect(() => {
    if(form.id){
      setLoading(true)
      UserFormService.get({id: form.id}).then((res)=>{
        if(res.status == 200){
          setForm(res.data.user_form);
          setLoading(false)
        }
      })  
    }
  }, []);*/

  const handleInputChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    if(!loading)FormHelper.validate('ct_form', form, error, setError);
  }, [form])

  const submit = () =>{
    setLoading(true)
    if(form.id){
      UserFormService.update(form).then((res)=>{
        setLoading(false)
        toggleModal()
        const list = formList.map(existingForm => {
          return existingForm.id == form.id ? form : existingForm
        })
        setFormList([...list])
      })
    }else{
      form.journey_profile_id = params.journey_profile_id
      form.journey_category_id = params.journey_category_id
      form.item_id = params.item_id
      form.item_type = params.item_type

      UserFormService.create(form).then((res)=>{
        setLoading(false)
        toggleModal()
        res.data.user_form.created_user = {name:currentUser.name}
        setFormList([res.data.user_form, ...formList])
      })
    }
  }

  return(
    <Fragment>
      {loading ? <div className="spinner"></div> : <form className="ml-container" id="ct_form">
      <label className="pull-right clear"><span className="lred font-16">* </span>Required field</label>
        <div className="ml-section">
          
          <label className="req-field"><b>Title</b></label>
          <input onChange={e=>handleInputChange(e)} name="title"
            className="form-control ml-margin-bottom"  placeholder="Title" 
            defaultValue={form.title} required/>

          <label className="req-field"><b>Description</b></label>
          <textarea onChange={e=>handleInputChange(e)} name="description"
            className="form-control ml-margin-bottom"  placeholder="Description" 
            defaultValue={form.description} required/>   
          
          <label className="req-field"><b>Submission Type</b></label>
          <select onChange={e=>handleInputChange(e)} name="submission_type" value={form.submission_type} className="form-control ml-margin-bottom" required>
            <option value="" disabled selected>Select an item from list below</option>
            <OptionsObjList list={submissionTypes} id_key="sid" label_key="label"/>
          </select>

          <label className="req-field"><b>Editable</b></label>
          <select onChange={e=>handleInputChange(e)} name="is_editable" value={form.is_editable} className="form-control ml-margin-bottom" required>
            <option value="" disabled selected>Select an item from list below</option>
            <OptionsObjList list={editableTypes} id_key="sid" label_key="label"/>
          </select>

          <label className="req-field"><b>Public Access</b></label>
          <select onChange={e=>handleInputChange(e)} name="is_public" value={form.is_public} className="form-control ml-margin-bottom" required>
            <option value="" disabled selected>Select an item from list below</option>
            <OptionsObjList list={editableTypes} id_key="sid" label_key="label"/>
          </select>

          {error.is_valid &&   
            <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>submit(e)}>
              <b>{form.id?'Update':'Add'}</b>
            </div>
          }

        </div>
      </form> }
    </Fragment>
  )

};

export default AddForm;