import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../components/PreLoadSpinner"
import DynamicForm from '../../components/FormBuilder/DynamicForm'
import FormContainer from '../../components/FormBuilder/FormContainer'
import FormTemplateId from '../../constants/FormTemplateList'
import InterventionProtocolService from "./InterventionProtocolService"
import JourneyPlannerService from "../Journey/HighSchoolJourney/Planners/JourneyPlannerService"
import ResourceService from "../Journey/HighSchoolJourney/Resources/ResourceService"
import NewsService from "../News/NewsService"

let formMode = null 
let formId = null 
let interventionProtocol = null
let data = {}
let formFn = {}  

function Form(props) {
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  
  formFn.planners_path = 'name'
  formFn.planners_pagenate = {
    page:1,
    per_page:30
  }

  formFn.enrichments_path = 'name'
  formFn.enrichments_pagenate = {
    page:1,
    per_page:30
  }

  formFn.cue_news_path = 'name'
  formFn.cue_news_pagenate = {
    page:1,
    per_page:30
  }

  const getById = (id) => {
    InterventionProtocolService.get(id).then((res) => {
      if(res.status==200){
        interventionProtocol = res.data.intervention_protocol
        data = interventionProtocol.data || {}
        setFormRendered(true)
      }
    }).catch(function(res){
      interventionProtocol = null
    })
  }
  
  useEffect(() => { 
    data = {}
    interventionProtocol = {} 
    formFn = {'form_type':'intervention_protocol', 'editPermission':true}
    
    if(params.id){
      formMode = 'edit-form-submissions'
      getById(params.id)
    }else{
      formMode = 'create-form-submissions'
      setFormRendered(true)
    }
  }, [])

  const create = (interventionProtocol) => {
    interventionProtocol.created_by = currentUser.id
    interventionProtocol.form_type = 'intervention_protocol'
    interventionProtocol.form_id = params.form_id
    
    InterventionProtocolService.create(interventionProtocol).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (interventionProtocol) => {
    InterventionProtocolService.update(interventionProtocol).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  formFn.get_planners = (search, on_success, on_failure) => {
    let req = {
      search: search,
      is_form_search: true,
      page: formFn.planners_pagenate.page,
      per_page: formFn.planners_pagenate.per_page
    }
    
    JourneyPlannerService.getAll(req).then(({status, data})=>{
      formFn.planners_pagenate.pageSize = Math.ceil(data.meta.total/formFn.planners_pagenate.per_page)
      on_success(data.planner_lists)
    })
  }

  formFn.toggle_planners = (item) =>{
    const listKey = 'planners_array'
    const idx = data[listKey].findIndex(o => o.id === item.id);
    if(idx > -1) {
      data[listKey].splice(idx, 1);
    }else{
      data[listKey].push({name:item.name, id:item.id});
    }
    data.videos_suggested = data[listKey].map((o)=> o.id).join(",");
    return data[listKey]
  }

  formFn.exist_planners = (item, list_field) =>{
    return data.planners_array.findIndex(o => o.id === item.id) > -1;
  }

  formFn.get_enrichments = (search, on_success, on_failure) => {
    const req = {
      search: search,
      is_form_search: true,
      page: formFn.enrichments_pagenate.page,
      per_page: formFn.enrichments_pagenate.per_page
    }
    
    ResourceService.getAll(req).then(({status, data})=>{
      formFn.enrichments_pagenate.pageSize = Math.ceil(data.meta.total/formFn.enrichments_pagenate.per_page)
      on_success(data.education_resources)
    })
  }

  formFn.toggle_enrichments = (item) =>{
    const listKey = 'enrichments_array'
    const idx = data[listKey].findIndex(o => o.edu_resource_id === (item.id || item.edu_resource_id));
    if(idx > -1) {
      data[listKey].splice(idx, 1);
    }else{
      data[listKey].push({name:item.name, edu_resource_id:item.id});
    }
    data.videos_suggested = data[listKey].map((o)=> o.edu_resource_id).join(",");
    return data[listKey]
  }

  formFn.exist_enrichments = (item, list_field) =>{
    return data.enrichments_array.findIndex(o => o.edu_resource_id === item.id) > -1;
  }

  formFn.get_cue_news = (search, on_success, on_failure) => {
    const req = {
      search: search,
      is_form_search: true,
      page: formFn.cue_news_pagenate.page,
      per_page: formFn.cue_news_pagenate.per_page
    }
    
    NewsService.getAll(req).then(({status, data})=>{
      formFn.cue_news_pagenate.pageSize = Math.ceil(
        data.meta.total/formFn.cue_news_pagenate.per_page
      )
      on_success(data.cue_newses)
    })
  }

  formFn.toggle_cue_news = (item) =>{
    const listKey = 'cue_news_array'
    const idx = data[listKey].findIndex(o => o.id === item.id);
    if(idx > -1) {
      data[listKey].splice(idx, 1);
    }else{
      data[listKey].push({name:item.name, id:item.id});
    }
    data.cue_news = data[listKey].map((o)=> o.id).join(",");
    return data[listKey]
  }

  formFn.exist_cue_news = (item, list_field) =>{
    return data.cue_news_array.findIndex(o => o.id === item.id) > -1;
  }

  const onSubmitPageRedirect = (id) => {
    if(props.isPopupMode){
      props.toggleModal()
      props.onSuccess()
    }else{
      props.history.push("/intervention_protocols")  
    }
  }

  const onCancel = (id) => {
    if(props.isPopupMode){
      props.toggleModal()
    }else{
      props.history.push("/intervention_protocols")  
    }
  }

  if(isFormRendered){
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode} formId={params.form_id} 
          form={interventionProtocol} data={data} formFn={formFn}  
          onUpdate={update} post={post} onCancel={onCancel} 
          onCreate={create} setPost={setPost} />
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default Form;