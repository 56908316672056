import React, {Fragment, useState, useContext, useEffect} from "react"
import moment from 'moment/min/moment.min'
import {Link} from "react-router-dom"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import CommonService from '../../../services/CommonService'
import env from "../../../env"
import LottieLight from "../../Common/LottieLight"
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService"

let requestParams = {}
let selectedDigitalContent = {}
let data_source = {}

const DigitalContentDetails = (props) => {
  let socialContent =[]
  let params = props.match.params

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [activeIndex, setActiveIndex] = useState()
  const [activeView, setActiveView] = useState()
  const [digitalContentDetail, setDigitalContentDetail] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
 
  let data = digitalContentDetail.data
  const cardStyle = {
    borderTop: '5px solid '
  }

  const toggleView = (id) => {
    if(activeView == id){
      setActiveView(null)    
    }else{
      setActiveView(id)
    }
  }

  useEffect(() => {
    getJourneyDetails(params)
  }, [currentUser.id, currentpage])

  let getJourneyDetails = (params) => {
    DigitalContentService.get(params.id).then((res)=>{
      if(res.status == 200){
        data_source = res.data.data_source_list
        //setPagemeta(res.data.meta)
        setDigitalContentDetail(res.data.digital_content)
      }
    })
  }

  const RenderCard = ({label, details, id}) =>{
    return(
      <Fragment>
        <div className="step-activity" onClick={e=>toggleView(id)}>
          {label}
        </div>
        {activeView == id && 
          <div className="vcard-field p-5">
            <div dangerouslySetInnerHTML={{ __html: details }}/>
          </div>
        }
      </Fragment>
    )
  }

  const WelcomeTour = () =>{
    let data = digitalContentDetail.data
    
    return(
      <div className="content-boxed shadow-small bg-rose" style={cardStyle}>
        <div className="content">
          <h3 className="vcard-title color-highlight bold">{data.title || data.heading}</h3>
          <div className="scroll-auto">
            { data.lottie && <LottieLight id={digitalContentDetail.id} {...data.lottie}/>}
            { data.image && <img className="responsive-image w-50p pull-left p-12" src={env.file_url+ data.image.image_url } />}
            { data.illustration && <img className="responsive-image w-50p pull-left p-12" src={env.file_url+ data.illustration } />}
            <div className="w-50p pull-left p-12">
              {data.notes && <li>{data.notes}</li>}
              {data.box_1_note && <li>{data.box_1_note}</li>}
              {data.box_2_note && <li>{data.box_2_note}</li>}
              {data.box_3_note && <li>{data.box_3_note}</li>}
            </div> 
          </div>
        </div> 
      </div>
    )
  }

  const SocialContent = () =>{
    return(
      <div className="content-boxed shadow-small bg-rose" style={cardStyle}>
        <div className="content">
          <h3 className="vcard-title color-highlight bold">{data_source.category && data_source.category.label }</h3>
          <div className="vcard">
            <div>{data.journey}</div>
            <a href={data.url}>{data.url}</a>
          </div> 

          <div className="vcard scroll-x ht-200">
            {data.twitter_content &&  
              <RenderCard label="Twitter/In-App" details={data.twitter_content} id="twitter" />
            } 
            {data.facebook_content && 
              <RenderCard label="Facebook" details={data.facebook_content} id="facebook"/>
            } 
            {data.instagram_content && 
              <RenderCard label="Instagram" details={data.instagram_content} id="instagram"/>
            } 
            {data.linkedin_content &&  
              <RenderCard label="LinkedIn" details={data.linkedin_content} id="linkedin"/> }
            {data.website_content && 
              <RenderCard label="Website" details={data.website_content} id="website"/>
            }
          </div>
        </div> 
      </div>
    )
  }

  const MarketingContent = () =>{
    let data = digitalContentDetail.data
    
    return(
      <div className="content-boxed shadow-small bg-rose" style={cardStyle}>
        <div className="content">
          <h3 className="vcard-title color-highlight bold">{data.title || data.heading}</h3>
          <div className="scroll-auto">
            { data.lottie && <LottieLight id={digitalContentDetail.id} {...data.lottie}/>}
            { data.image && <img className="responsive-image w-50p pull-left p-12" src={env.file_url+ data.image.image_url } />}
            { data.illustration && <img className="responsive-image w-50p pull-left p-12" src={env.file_url+ data.illustration } />}
            <div className="w-50p pull-left p-12">
              {data.notes && <li dangerouslySetInnerHTML={{ __html: data.notes }}></li>}
              {data.box_1_note && <li>{data.box_1_note}</li>}
              {data.box_2_note && <li>{data.box_2_note}</li>}
              {data.box_3_note && <li>{data.box_3_note}</li>}
            </div> 
          </div> 
        </div> 
      </div>
    )
  }

  if(digitalContentDetail.length == 0)return null;

  if(digitalContentDetail.content_form_id == 945){
    return <SocialContent />
  }else if(digitalContentDetail.content_form_id == 976 || digitalContentDetail.content_form_id == 951){
    return <MarketingContent/>
  }else if(digitalContentDetail.content_form_id == 980){
    return <WelcomeTour/>
  }else{
    return <SocialContent/>
  }

}

export default DigitalContentDetails;