import React, { Fragment,useEffect, useState } from 'react';
import AuthService from "../../services/AuthService";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../hooks/useStyle';
import { Link } from 'react-router-dom';
import DigitalContentService from "../DigitalContent/DigitalContentService";
import HamBar from '../Authentication/HamBar';
import Logo from '../Authentication/Logo';

let requestParams = {}
const SubscriptionTerms = (props) => {
  useStyle("spmarketing");
  
  let params = props.match.params
  const { state: currentUser, dispatch,isAuthenticated: isAuthenticated } = React.useContext(AuthContext);
  const [list, setList] = useState([])
  const [content, setContent] = useState()

  useEffect(() => {
    requestParams = {
      content_type: "subscription_terms",//about_us/terms_of_service,privacy_policy
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null,
    };

    getPrivacyList();
  }, []);

  let getPrivacyList = () => {
    DigitalContentService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setList(res.data.digital_contents);
        setContent(res.data.digital_contents[0])
      }
    })
  }

  return (
    <Fragment>
      <header>
        <Logo />
		    <HamBar />
      </header>
      {content &&
      <div id="main-doc" className="main">
        <section id="link1" className="main-section">
          <header>{content.data.id_20211191613712085989}</header>
          <p dangerouslySetInnerHTML={{ __html: content.data.id_20211191613712001166 }}></p>
        </section>
      </div>}
    </Fragment>
  )
}

export default SubscriptionTerms;