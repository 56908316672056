/*Component used in planner for assigning form to planner*/
import React, {useState, Fragment, useEffect} from "react"
import GenericModal from "../Modals/GenericModal"
import UserFormService from "./UserFormService"
import {FilterMenuItem} from "../Common/MenuItem"
import CourseReviewService from "../Journey/CareerTrainingJourney/CourseReviewAssignments/CourseReviewService"

let requestParams = null
const WAIT_INTERVAL = 1000
let timer = null

const UserFormListModal = (props) => {
  
  const [formList, setFormList] = useState([])
  const [selectedForm, setSelectedForm] = useState(props.item.survey || {})

  useEffect(() => {
    requestParams = {
      'journey_profile_id':props.journeyProfileId,
      'journey_category_id':props.journeyCategoryId,
      'page':1,
      'per_page':100,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'search':props.searchString,
    }
    //'id':selectedForm.review_id
    getUserFormList()

    return () => {
      requestParams = {}
      clearTimeout(timer)
    }
  }, [])

  let getUserFormList = () => { 
    UserFormService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setFormList(res.data.user_forms)
        requestParams.id = null
      }
    })
  }

  let handleChange = (e) =>{
  	clearTimeout(timer)
  	requestParams.search = e.target.value
    timer = setTimeout(getUserFormList, WAIT_INTERVAL)
  }

  const assignSurvey = (form) => 
    assignReview({
      id: selectedForm.id,
      journey_category_id:props.journeyCategoryId,
      journey_profile_id:props.journeyProfileId,
      item_type:'carrier_training.content',
      item_id:props.item.id,
      review_type:'survey_form',
      review_id:form.id,
      course_id:props.courseId,
      data:{
        title:form.title, 
        details:form.details
      }
    }, 'survey')

  const assignReview = (req, field) =>{
    if(req.id){
      CourseReviewService.update(req).then((res)=>{
        if(res.status == 204){
          setSelectedForm(req)
        }
      })
    }else{
      CourseReviewService.create(req).then((res)=>{
        if(res.status == 201){
          props.item[field] = res.data
          setSelectedForm(res.data)
        }
      })
    } 
  }

  let FormsView = () => formList.map((a, i)=>
  	<div key={i} className={`p-5 font-15 font-500 ${a.id == selectedForm.review_id?'bg-green white':'bg-ltint'} `}
      onClick={e => assignSurvey(a)}>{a.title}</div>
  )

  return(
  	<div className="p-5 scroll-x">
      <div className="input-group m-b-5">
        <input type="text" onChange={e=>handleChange(e)} placeholder="Search" className="form-control input-sm"/>
        <span className="input-group-btn">
    	  <p type="button" className="bg-highlight input-sm">
        	<i className="fa fa-search white"/>
          </p>
        </span>
      </div>  

      <FormsView/>
    </div>
  )

}

export default UserFormListModal;