import React, {Fragment, useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import PortalHeader from "../../../../components/Journey/TeamJourney/TeamPortalPage/PortalHeader";
import PortalPath from "../../../../components/Journey/TeamJourney/TeamPortalPage/PortalPath";
import JourneyCategoryService from "../../JourneyCategoryService";
import useStyle from '../../../../hooks/useStyle';
import Teamnodes from '../../../../constants/Teamnodes';
import CheckAccess from '../../Roles/CheckAccess';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import ActivityInfoList from "../Activities/List/ActivityInfoList";
import UpcomingActivityInfoList from "../Activities/List/UpcomingActivityInfoList";

let portalPath = null;
let share = null;

const PortalDetails = (props) => {
  useStyle('css/tile-components.css')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [project, setProject] = useState({});
  const [statics, setStatics] = useState({});

  const params = props.match.params;

  useEffect(() => {  
    const req = {
      'journey_category_id':params.project_id,
      'show_journey_templates': false, 
      'data_source_params':{
        "data_source_id":[3326],
        "response_type":"object",
        "group_by":"sid"
      }
    }

    JourneyCategoryService.getJourneyById(req).then((res)=>{
      if(res.status == 200){
        res = res.data;
        res.journey_category = res.journey_category[0];
        portalPath = res.data_source_list.ct_life_team_nodes;
        share = res.invited_journey_member;
        /* if(res.journey_category.education_journey_profiles.data.fj_type_edu_jou == 14){
         }else{
          PortalPath = Teamnodes.indi_path_nodes;
        }*/
        sessionStorage.activeJourney = JSON.stringify(res);
        CheckAccess.userAccess(currentUser ,res.journey_category, share);
        setStatics(res.statics)
        checkPathAccess(res.journey_category);
        setProject(res.journey_category)
      }
    })

  }, []);

  const checkPathAccess = (journey_category) =>{
    if(!journey_category.is_admin_user){
      Object.values(portalPath.options).map((path, k) =>  {
        if(path.sid == 'planners' || path.sid == 'roles'){
          delete portalPath.options[path.sid];
        }
      })
    }
  }

  const routePath = (e, path) => {
    e.preventDefault();
    if(path.sid == 'planners'){
      props.history.push(`/team/planners/${params.project_id}/${project.education_journey_profiles.id}`,project);
    }else if(path.sid == 'roles'){
      props.history.push(`/journey/role/${params.project_id}/${project.education_journey_profiles.id}`,project)
    /*}else if(path.sid == 'contract'){
      props.history.push(`/list/project/contracts/${project.id}`)*/
    }else if(path.sid == 'activities'){
      props.history.push(`/team/activity/${params.project_id}/${project.education_journey_profiles.id}`,project)
    }else if(path.sid == 'calendars'){
      props.history.push(`/team/schedules/${params.project_id}/${project.education_journey_profiles.id}/team`,project)
    }else if(path.sid == 'invite_users'){
      props.history.push(`/invite/user/${project.id}/${project.education_journey_profiles.id}`, {'journey': project, 'share':share})
    }else if (path.sid == "meetings") {
      props.history.push(
        `/zoom/meetings/${project.id}`, project
      )
    }
  }

  if(!project.id)return( <PreLoadSpinner/>);

  return (
    <>
      <div id="ct">
        <div className="app_content">
          <PortalHeader project={project} share={share} statics={statics} app={props.app}/>

          <div className="app-content-section">
            <PortalPath portalPath={portalPath} routePath={routePath} statics={statics}/>    
          </div>
        </div>
      </div>
      <small className="font-12 p-5 lh-16">3326</small>
      <SideMenuBar helpId="14"/>
    </>
  )

  return (
    <Fragment>
    <div className="p-5">
      <div className="portlet">
        <PortalHeader project={project} share={share} statics={statics} app={props.app}/>
        <div id="bg-info" className="panel-collapse collapse in" style={{minHeight:'250px'}}>
          <div className="portlet-body">
            <PortalPath portalPath={portalPath} routePath={routePath} statics={statics}/>

            <div className="row">  
              {project.last_activities &&
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="edu-path">
                    <div className="head">Recent Activities</div> 
                    <div className="p-5" style={{height: '163px'}}>
                      <ActivityInfoList activities={project.last_activities}/>
                    </div>
                  </div>
                </div>
              }
                
              {project.upcoming_activity &&
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="edu-path">
                    <div className="head">Upcoming Items</div>   
                    <div className="p-5" style={{height: '163px'}}>
                      <UpcomingActivityInfoList activities={project.upcoming_activity}/>
                    </div>
                  </div>
                </div>
              }
            </div>

            <small className="font-12 p-5 lh-16">3326</small>
          </div>
        </div>
      </div>
    </div>
    <SideMenuBar helpId="14"/>
    </Fragment>
  )
}

export default PortalDetails;