import React, { Fragment, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../services/CommonService';
import InvitedUserService from '../Journey/InvitedUsers/InvitedUserService';
import ChatService from './ChatService';
import useStyle from '../../hooks/useStyle';
import useModal from "../../hooks/useModal";
import JourneyProfileForm from "../Journey/Form/JourneyProfileForm";

let profileImage = 'images/avatars/2s.png'

const ChatBot = (props) => {
  useStyle('chat');
  useStyle('dashboard');
  useStyle("filter_dialog");
  useStyle('add_role_dialog');

  const history = useHistory();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatList, setChatList] = useState([]);

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const { isOpen: showChatBot, toggleModal: toggleChatBot } = useModal();
  const {openChatBot, setOpenChatBot} = props
  let journeyCategory = [
    {
      child_form: 190,
      label: "Customize a new college journey",
      wizard_id: 24,
      value: 3,
      icon: "fa fa-graduation-cap",
      icon_color: "#0076BA",
      ribbon_edge_color: "#0bbcc7",
    },{
      label: "Launch a new team journey",
      value: 14,
      wizard_id: 41,
      child_form: 973,
      icon: "fa fa-users",
      icon_color: "#FF42A1",
      ribbon_edge_color: "#666666",
    },{
      child_form: 972,
      icon: "fas fa-school",
      icon_color: "#F27200",
      label: "Start a new carrier Journey",
      value: 4,
      wizard_id: 40,
      ribbon_edge_color: "#28edd6",
    }
  ]

  const addChat = (e, text) => {
    setLoading(true)
    e.preventDefault()
    let req = {
      "sender": currentUser.email,
      "message": text,
      "token": "None"
    }
    if(text=='')return
    ChatService.chatbot(req).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        setText('')
        req.created_by = currentUser.id
        req.text = req.message
        setChatList([...chatList, req, (res.data.length>0 && res.data[0])])
        scrollTo(chatList)
      }
    })
  }

  const routeJourney = (e, journey_type) => {
    e.preventDefault();
    toggleChatBot(e)
    history.push(
      `/journey_profile?show_form_wizard=1&form_id=${journey_type.child_form}&form_wizard_id=${journey_type.wizard_id}&fj_type_edu_jou=${journey_type.value}`
    )
  }

  function scrollTo(chats){
    if(chats.length>0){
      try{
        let ele = document.getElementById('last_comment');
        document.getElementById('chat-main').scrollTop = ele.offsetTop;
      }catch(e){
        console.log(e)
      }
    }
  }

  const JourneySuggestion = () =>(
    <div className="col-xs-12">
      <div className="card card-style">
        <div className="content">
          <div className="head-boder black">
            <h3>
              <i className="fas fa-comments m-r-15 font-24 color-highlight"/> 
              Hello, how can we help?
            </h3>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal" 
            onClick={e=>toggleChatBot(e)}>×</span>
          </div>
          <p className="m-b-10">
            Tap to open.
          </p>
          <div className="bottom-0 chat-main" id="chat-main">
            <div className="popup-menu-grid">
              {PersonalTypeList()}  
            </div>

            {loading ? <div className="spinner"></div> : chatsItem}
            <div id="last_comment" className="m-t-5"></div>
          </div>
          <CommentForm text={text} addChat={addChat}/>
        </div>
      </div>
    </div>
  )

  const MenuItem = ({ k }) => (
    <div className="ver-icons" onClick={(e) => routeJourney(e, k)}>
      <a href="/">
        <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
      </a>
      <div className="popup-menu-title">{k.label}</div>
      <i className="fas fa-chevron-right lgrey pull-right" />
    </div>
  )

  const PersonalTypeList = () => journeyCategory.map((k) => (
    <Fragment key={k.value}>
      <MenuItem k={k} />
    </Fragment>
  ))

  const CommentType = ({chat}) =>{
    return(
      <Fragment>
        <span dangerouslySetInnerHTML={{ __html: chat.text}}></span>
      </Fragment>
    )
  }

  const FormWizard = (props) =>{
    let p = {
      location:{
        search:"show_form_wizard=1&&form_wizard_id=24"
      },
      match:{
        params:{}
      }
    }

    return(
      <Fragment>
        <JourneyProfileForm match={p.match} location={p.location} isInsideChatBot="true"/>
      </Fragment>
    )
  }

  const chatsItem = chatList.map((c, k) =>
    <Fragment key={k}>
      {c.text && 
        <div id={`chat_${c.id}`} className={`speech-bubble ${c.created_by == currentUser.id?'speech-left':'speech-right'}`}>
          <CommentType chat={c}/>
        </div>
      }
      {c.action &&
        <FormWizard/>
      }  
      <div className="clear"/>
    </Fragment>
  )

  const CommentForm = (props) =>{
    const [text, setText] = useState(props.text || '');
    return(
      <form className="clearfix speech-footer" onSubmit={e=> props.addChat(e, text)}>
        <div className="form-group col-xs-10 speach-input center-text">
          <input className="m-l-5" type="text" value={text} placeholder="Enter your message here" onChange={e => setText(e.target.value)} autoFocus/>
        </div>
        <div className="col-xs-2 p-t-3 center-text">
          <div onClick={e=> props.addChat(e, text)} className="round-btn brd-17 bg-lgreen"><i className="fas fa-arrow-up"></i></div>
        </div>
      </form>
    )
  }

  if(showChatBot){
    return(ReactDOM.createPortal(
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom bg-white">
          <JourneySuggestion/>
        </div>
      </div>, document.body)
    )
  }

  return (
  	<Fragment>
      {!showChatBot &&
        <Fragment>
          <span className="chat-close clearfix fa fa-times notification-close" onClick={(e)=>{setOpenChatBot(!openChatBot);}}></span>
          <button className="chat-btn" onClick={e=>toggleChatBot(e)}>
            <img src="images/chat.svg"/>
          </button>
        </Fragment>
      }
      
      {showChatBot &&  
        <button className="chat-btn" onClick={e=>toggleChatBot(e)}>
          <img src="images/chat_close.svg"/>
        </button>
      }
    </Fragment>
  )

}

export default ChatBot;