import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import StudentProfileServices from "./StudentProfileServices";
import CommonService from "../../../../services/CommonService"
import env from '../../../../env';
import FormContainer from '../../../FormBuilder/FormContainer';

let formMode = null; 
let profile = {}; 
let data = {}; 
let formFn = {};  

function StudentProfileForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  let params = props.match.params
  let path = props.match.path

  formFn.college_pagenate = {page:1, per_page:10}
  formFn.college_path = "name"

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    StudentProfileServices.get(params).then((res) => {
      if(res.status==200){
        profile = res.data.student_profile;
        data = profile.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       profile = null;
    })
  }
  
  useEffect(() => { 
    profile = {}; 
    data = {}; 
    formMode = 'create-form-submissions';
    data = {}; 
    formFn = {'form_type':'student_profile', 'journey_category_id':params.journey_category_id}; 
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (profile) => {
    profile.created_by = authState.user.id;
    profile.education_journey_profile_id = params.journey_profile_id;
    profile.journey_category_id = params.journey_category_id;
    profile.profile_type = (path == "/college/admission/profile/:id?" ? 'college_admission_profile':'highschool_profile');
    StudentProfileServices.create(profile).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (profile) => {
    profile.updated_by = authState.user.id;
    StudentProfileServices.update(profile).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  formFn.get_college = function(search, on_success, on_failure){
    formFn.college_pagenate.college_name = search;
    let req = {
      method: 'post',
      url: `${env.family_tree_backend}colleges/search`,
      data: formFn.college_pagenate
    }

    CommonService.useAxios(req).then(({data, status})=>{
      formFn.college_list = data.colleges;
      if(data.meta.total && formFn.college_pagenate.per_page){
        formFn.college_pagenate.pageSize = Math.ceil(data.meta.total/formFn.college_pagenate.per_page);
      } 
      on_success(formFn.college_list)
    })
  }

  formFn.set_college = (item) =>{
    data.college = item.name;
    data.fk_college_id = item.id;
  }

  const onSubmitPageRedirect = () => {
    if(path == "/college/admission/profile/:id?"){
      props.history.push('/college/admissions')
    }else{
      props.history.push(`/highschool/student/profiles/${params.journey_category_id}/${params.journey_profile_id}`)      
    }
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.StudentProfileForm} form={profile} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    )
  }else{
    return null;
  }
  
}

export default StudentProfileForm;