import React, { Fragment } from "react";
import {Link} from "react-router-dom";
import {PermissionList, NotificationList} from './NotificationPill'

export const UserRole = (props) => {
  let {user_role, addUser, roles, dataSources:{permissions, notificationsTypes}} = props;

  return (
    <Fragment>
      <div className="bg-white p-12">
      <div className="user-role-list-left">
        <h4 className="vcard-title color-highlight bold m-t-10">{user_role.email}</h4>
        <img src="images/avatars/2s.png" alt="img" className="shadow-small bg-blue2-dark" />
      </div> 

      <div className="text-cap role scroll-auto m-t-5" style={{height: '130px'}}>
        {user_role.id && <Fragment> 
          <div className="float-left w-100p">
            <div><strong>Permission</strong></div>
            {user_role.role_ids && user_role.role_ids.map((role_id, i) => 
              <span key={i} className="round-tab bg-yellow-white" data-tip={roles[role_id] && roles[role_id].role_description}>
                {roles[role_id] && roles[role_id].role}
              </span>
            )}
          </div>
          <div className="float-left w-100p">
            <div><strong>Notification for Step Activity</strong></div>
            <NotificationList list={user_role.notification_step_activity} obj={notificationsTypes}/>
          </div>
          <div className="float-left w-100p">
            <div><strong>Notification for Comment Posting</strong></div>
            <NotificationList list={user_role.notification_comment} obj={notificationsTypes}/>
          </div>
          </Fragment>
        }
      </div>
      <div className="m-t-5 clearfix">
        {user_role.id && <div className="action-btn bg-highlight" onClick={e=>{props.selectUserRole(e, user_role)}}>Remove</div>}
        {!user_role.id && <div className="action-btn bg-highlight" onClick={e=>{addUser(e, user_role)}}>Add</div>}
        {user_role.id && <div className="action-btn bg-highlight" onClick={e=>{props.setEdit(e, user_role)}}>Edit</div>}
      </div>
      </div>
    </Fragment>
  )

}

export default UserRole;

/* 
<PermissionList list={user_role.permissions} obj={permissions}/>
<span >
      <strong>Users</strong>
          {user_role.role_users && user_role.role_users.map((role, i) => ( <div key={i} >{role.email}</div> ))}
      </span>*/