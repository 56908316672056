import React, { Fragment, useState, useEffect, useContext, useRef} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from "../../env"
import {Locales} from "../../constants/DataSourceList"

const LocaleList = (props) => {
  useStyle('dashboard')

  const { state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext)
  const localeEl = useRef(null)
  
  const updateLocale = (l) => {
    let req = {
      user: { current_locale: l.sid},
    }
    AuthenticationService.updateUserName(req).then((res) => {
      if (res.status == 200) {
        dispatch({
          type: "UPDATE",
          payload: { user: res.data.user },
        })
      } 
    })
  }

  useEffect(() => {
    document.addEventListener("click", handleClick, true)
    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [])

  const handleClick = (e) => {
    if (!localeEl.current.contains(e.target)){
       props.toggleModal(e)
    }
  }

  const LocaleMenu = () => Locales.map((o, i) => (
	  <Fragment key={i}>
  	  <div className={currentUser.current_locale == o.sid?'d-flex org-active':'d-flex'} onClick={e=>updateLocale(o)}>
  	    <div className='op-06 m-l-10'>  
  	      <h5 className="d-title ellipsis">{o.label}</h5>
        </div>
  	  </div>
  	  <div className="divider"/>           
  	</Fragment>
  )) 
  
  return(
    <div className="ml-container" ref={localeEl}>
      <div className="ml-section">
        <div className="content">
          <LocaleMenu/>
        </div>
      </div>
    </div>
  )
}

export default LocaleList;