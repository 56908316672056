import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import Cs from "../../../../services/CommonService";
import ResourceService from "../Resources/ResourceService";
import SideMenuPlannerList from "../Planners/List/SideMenuPlannerList";
import {EmptyFormFieldList} from "../../Common/FormFieldSuggestions"; 

let requestParams = null;

const Suggestions = ({openActivityForm, cueSuggestions, share, cueTipType, journeyProfiles, journey, history, suggestions, dataSource, currentUser, activity, style}) => {

  const {cuePrompt_items} = dataSource
  const [cueTips, setCueTips] = useState([])
  const [emptyFields, setEmptyFields] = useState(cueSuggestions?.empty_fields || [])
  const [suggestionList, setSuggestionList] = useState(suggestions || [])

  useEffect(()=>{
    if(!journeyProfiles.data.school_zipcode){
      setSuggestionList(suggestions?.filter((s) => s.sid !== 'colleges_nearby'))
    }
    if(journey.journal_entry_count === 0){
      setSuggestionList(suggestions?.filter((s) => s.sid !== 'activity_prompts'))  
    }
  }, [])

  useEffect(() => {
    if(cueTipType == 'edu_path'){
      requestParams = {
        'journey_value':`${journeyProfiles.data?.fj_type_edu_jou}`,
        'high_school_grade_value':`${journeyProfiles.data?.fj_edu_current_grade}`,
        //'subjects_most_interest_values':journeyProfiles.data?.subjects_most_interest_array,
        'career_interest_values':journeyProfiles.data?.interest_array,
        'journey_category_id':journey?.id,
        'edu_journey_type': 'high_school_journey'
      }
    }else if(cueTipType == 'edu_activity'){
      requestParams = {
        'journey_value':`${journeyProfiles.data?.fj_type_edu_jou}`,
        'classification_value':`${activity?.data?.fj_edu_entry_type}`,
      }
    }else if(cueTipType == 'college'){
      requestParams = {
        'journey_value':`${journeyProfiles.data?.fj_type_edu_jou}`,
      }
    }
    /*if(cueTipType == 'edu_path')getHighSchoolCueTips()*/

    if(!journey.is_admin_user)removeUnwantedSuggestion()
  }, [])

  /*const getHighSchoolCueTips = () =>{
    Cs.getCueTips(requestParams).then((res)=>{
      if(res.status == 200){
        //setCueTips(res.data.cue_tips)
        setEmptyFields(res.data.empty_fields || emptyFields)
      }
    })
  }*/

  const removeUnwantedSuggestion = () =>{
    setSuggestionList(suggestions.filter((s)=> s.item_type != 'invite_users'))
  }

  let route = (s) =>{
    let u = null
    switch (s.sid_code) {
      case 'journey':
        u = `/journey_profile/${journeyProfiles.id}?form_id=${journeyProfiles.form_id}`
        break;
      case 'matched_planner':
        u = `/highschool/planners/${journey.id}/${journeyProfiles.id}`
        break;
      case 'invite_users':
        u = `/invite/user/${journey.id}/${journeyProfiles.id}`
        history.push(u, {'journey': journey, 'share':share})
        return
        break;
      case 'premade_activities':
        u = `/highschool/resources/${journey.id}/${journeyProfiles.id}`
        break;
      case 'activity_prompts':
        u = `/highschool/suggestions/${journey.id}/${journeyProfiles.id}`
        break;
      case 'colleges_nearby':
        //school_zipcode
        let activeCollegeProfile = {}
        activeCollegeProfile = {'filter_nearby_college':true}
        sessionStorage.activeCollegeProfile = JSON.stringify(activeCollegeProfile)
        u = `/highschool/college/search/${journey.id}/${journeyProfiles.id}/`
        break;
    }
    history.push(u, journey)
  }

  let Suggestion = ({}) => suggestionList.map((s, i) => {
    let d = s.data;
    let obj = cuePrompt_items && cuePrompt_items.options.find(o => o.value === d?.value);
    return(
      <div key={i} className="next-action-text br-btm-grey clearfix" onClick={e=>route(d)}>
        <div className="col-xs-12 p-5 d-flex">
          <div className="font-24 w-55">
            <i className={obj?.icon} style={{color:obj?.icon_color}}/>
          </div>
          <div className="w-100p">
            <h5 className="darkblue lh-20 font-14">
              {obj?.label}
            </h5>
            <div className="next-action-hover lh-18">{obj?.prompt_text}</div>
          </div>
          <div className="font-18 pull-right">
            <i className="fas fa-chevron-right lgrey m-l-10"/>
          </div>
        </div>
      </div>  
    ) 
  })

  let CueTips = ({}) => cueTips.map((c, i) => {
    let d = c.data;
    return(
      <div key={i} className="next-action-text br-btm-grey clearfix">
        <div className="col-xs-12 d-flex p-5">
          <div className="font-24">
            <i className="far fa-dot-circle grey"/>
          </div>
          <div className="m-l-5">
            <h5><a href={d.url} target="_blank">{d.name}</a></h5>
            <div className="next-action-hover lh-16" dangerouslySetInnerHTML={{ __html: d.notes}}></div>
          </div>
        </div>
      </div>  
    ) 
  })

  return(
    <div className="clearfix">
      <div className="col-xs-12 next-action-container scroll-x" style={style}>
        <EmptyFormFieldList fields={emptyFields} openForm={openActivityForm}/>
        <Suggestion/> 
        <CueTips/>
        <div className="col-xs-12">
          <small className="font-9 lgrey lh-16 p-5">{cuePrompt_items.id}</small>
        </div>
      </div>    
    </div>
  ) 
} 

export default Suggestions;