import React, { useState, Fragment, useEffect, useContext} from "react";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import useEscape from '../../../../hooks/useEscape';
import InterviewService from "./InterviewService";

const TagModal = (props) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');
  useEscape(props.toggleModal);

  let answerTxt = props.userResponse && props.userResponse.company_name;
  const [answer, setAnswer] = useState(answerTxt);
  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    getCompanyList();
  }, []);

  const handleChange = (e) =>{
    setAnswer(e.target.value)
  }
  
  const getCompanyList = () =>{
    let req = {
      'search': answer
    }
    InterviewService.getCompanyList(req).then((res)=>{
      setCompanyList(res.data.interview_preparations)
    })
  }

  const submitAnswer = (vote) =>{
    let req = {
      'question_id':props.question.id,
      'response':vote,
      'company_name':answer,
      'response_type':'tag_interview',
      'journey_category_id':props.journeyCategoryId,
      'journey_profile_id':props.journeyProfileId,
      'carrier_ds_id': props.carrierDataDourceId,
      'question_category_ds_id':props.question.question_category_ds_id,
      'journey_type_ds_id':props.question.journey_category_ds_id,
      'graduate_program_ds_id':props.question.graduate_program_ds_id
    }
    if(props.userResponse){
      req.id = props.userResponse.id
      InterviewService.update(req).then((res)=>{
        if(res.status == 204){
          props.userResponse.company_name = answer
          props.toggleModal()
        }
      })
    }else{
      InterviewService.create(req).then((res)=>{
        if(res.status == 201){
          props.answerList[props.question.index].tag_interview = res.data.interview_preparation
          props.setAnswerList([...props.answerList])
          props.toggleModal()
        }
      })
    }
  }

  return(
    <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
        {props.title &&
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">{props.title}</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>props.toggleModal(e)}>×
            </button>
          </div>
        }
      
        <div className="row">
          <div className="form-group col-xs-12">
            <label>{props.label}</label>
            <input placeholder="name of interviewing organization" onChange={e => handleChange(e)} value={answer}
            className="form-control plainTxt2"/>

            <ul className="bold-300 p-7">
              {companyList.map((c, i) => (
                <li className={'font-15 '+(c.response=='up_vote'?'green':'lred')} onClick={e=>setAnswer(c.company_name)} key={i}>{c.company_name}</li>
              ))}
            </ul>

            {answer &&
              <div className="col-xs-12 font-40 center-text">
                <i className="fa fa-smile p-7 green" onClick={e=>submitAnswer('up_vote')}></i>
                <i className="fa fa-frown p-7 lred" onClick={e=>submitAnswer('down_vote')}></i>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TagModal;