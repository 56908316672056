import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import List from './List'
import Form from './Form'

const InterventionProtocolRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/intervention_protocols" component={List} searchString={props.searchString} permissionType='current_admin.role_content' exact />
    <PrivateRoute path="/intervention_protocol/:form_id/:id?" component={Form} exact />
  </Fragment>
)	

export default InterventionProtocolRouter;