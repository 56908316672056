import React ,{useContext} from "react"
import moment from '../../../../node_modules/moment/min/moment.min.js'
import {Link} from "react-router-dom"
import Cs from '../../../services/CommonService'

const Help = (props) => {
  
  let helpList = props.helpList
  let data = helpList.data

  const cardStyle = {
    borderTop: '3px solid #00a2ff',
    height: '60px'
  }

  if(data !== null){
    return (
      <div className="card-container shadow-small" >
        <div className="card-header p-5 bg-white" style={cardStyle}>
          <div className="font-16 black-light-1 ellipsis">
            {data.title}
          </div>
          <div className="lred">
            {Cs.formatUpdateDate(helpList.created_at)}
          </div> 
        </div>
        
        <div className="br-grey"></div>

        <div className="ht-200 scroll-auto p-5 bg-white">
          <a href={Cs.getIconByType(data.image)} target="_blank">
            <img src={Cs.getIconByType(data.image)} width={100} className="center-block"/>
          </a>
          <h5>Guide Information</h5>
          <div>{data.guide_information}</div>
        </div>

        <div className="card-footer p-5">
          <div className="pull-right">
            <props.menu help={helpList} />
          </div>
        </div>
      </div>
    )
  }else{
   return null
  }
  
}

export default Help;