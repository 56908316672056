import React, { useState, Fragment, useEffect, useContext} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import querystringify from "querystringify";
import Journey from "../../../components/Journey/List/Journey";
import JourneyCategoryService from "../JourneyCategoryService";
import Pagination from "../../Pagination";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import FormTemplateId from "../../../constants/FormTemplateList";
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu";
import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import NotesModal from "../../Modals/NotesModal";
import MenuModal from "../../Modals/Menu";
import ChecklistsModal from "../../Modals/ChecklistsModal";
import useStyle from "../../../hooks/useStyle";
import CheckAccess from "../Roles/CheckAccess";
import SideMenuBar from "../../../components/Common/SideMenuBar";
import {JourneyTypeMenu} from "./JourneyTypeMenu";
import NotificationPopup from '../../Common/NotificationPopup'
import HelpMessageList from '../../../constants/HelpMessageList'
//import FilterModal from "../../../components/Organizations/List/OrganizationsFilterModal";
import {NavIcon,CardMenuInfo,MenuItem} from '../../Common/MenuItem'
import ReactTooltip from "react-tooltip";

let requestParams = {};
let selectedJourney = {};
let data_source = {};
let users = {};

const JourneyList = (props) => {
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  useStyle('/css/tile-components.css', true)
  useStyle('/css/journey-list.css', true)


  let queryParam = querystringify.parse(props.location.search);

  const {state: { user: currentUser }} = useContext(AuthContext)

  const [journeyList, setJourneyList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [journeyMember, setJourneyMember] = useState()

  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal();
  const {isOpen: isJourneyMenuOpen, toggleModal: toggleJourneyMenu,} = useModal();

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      data_source_params: {
        response_type: "object",
        data_source_id: [2214, 3170, 3171, 2860, 3231],
        group_by: "value",
      },
      page: currentpage,
      paginate: 1,
      per_page: 15,
      show_journey_templates: false,
      sort_column: "updated_at",
      sort_direction: "desc",
      search: props.searchString || null,
      is_favourites: queryParam.pinned,
      journey_member_id: queryParam.journey_member_id
    }

    getJourneyList();
  }, [props.searchString, currentUser, currentpage, queryParam.journey_member_id]);

  let getJourneyList = () => {
    JourneyCategoryService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        users = res.data.users;
        data_source = res.data.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        setJourneyList(res.data.journey_categories);
        setJourneyMember(res.data.journey_member)
        res.data.journey_categories.length == 0 && toggleHelpMessage()
      }
    })
  }

  const addDelete = (e, ja) => {
    selectedJourney = ja;
    toggleDeleteModal(e);
  }

  const deleteJourney = (e) => {
    e.preventDefault();
    JourneyCategoryService.delete(selectedJourney).then((res) => {
      if (res.status == 204) {
        setJourneyList(journeyList.filter((i) => i.id !== selectedJourney.id));
      }
    })
    toggleDeleteModal(e);
  }

  const addMenu = (e, ja) => {
    selectedJourney = ja;
    toggleMenuModal(e);
  }

  const addNewJourney = (e) => {
    e.preventDefault();
    let profile = currentUser.domain_info;
    /*if(profile.data.type_of_use == 1){
      //Personal
    }else if(profile.data.type_of_use == 2){
      //Organization Use
    }*/
    toggleJourneyMenu(e);
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <span className="m-l-15">
          <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
              createdAt={item.created_at} />
        </span>
        {CheckAccess.isJourneyAuthor(currentUser, item) && (
          <Fragment>
            <span className="m-l-15">
              <MenuItem id="delete_icon" 
                icon="far fa-trash-alt"
                action="Delete"
                onSelect={(e) => {addDelete(e, item);toggleMenuModal(e);}}/>
            </span>
            <span className="m-l-15">
              <MenuItem id="edit_icon" 
                link={`/journey_profile/${item.education_journey_profiles.id}?form_id=${item.education_journey_profiles.form_id}`}
                icon="far fa-edit"
                action="Edit"/>
            </span>
            <span className="m-l-15">
              <MenuItem id="invite_icon" 
                link={`/invite/user/${item.id}/${item.education_journey_profiles.id}`}
                icon="fa fa-user"
                action="Invite User"/>
            </span>
          </Fragment>
        )}
        <span className="m-l-15">
          <a href="#">
            <i className="fas fa-info-circle"></i>
            <span className="font-13">Help</span>
            <i className="fa fa-angle-right"></i>
          </a>
        </span>
        <span className="m-l-15">
          <a href="#">
            <i className="far fa-check-circle"></i>
            <span className="font-13">Share team list for chat</span>
            <i className="fa fa-angle-right"></i>
          </a>
        </span>
      </Fragment>
    )
  }

  const Menu = ({ ja, fn }) => (
    <Fragment>
      <i data-tip="Open Tile Menu" id="journey_menu" 
        onClick={(e) => addMenu(e, ja)}
        className="fas fa-bars font-16"/>
    </Fragment>
  )

  const journeyListCard = journeyList.map((ja, i) => (
    <div className="col-md-4" key={i}>
      <Journey journey={ja} menu={Menu} currentUser={currentUser}
        users={users} toggleModal={toggleDeleteModal} addMenu={addMenu}
        dataSources={data_source}/>
      {selectedJourney.record_id == ja.record_id && (
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
          item={selectedJourney} addDelete={addDelete}
          type="journey" MenuItems={menuItems}
          menuHeight="275px"/>
      )}
    </div>
  ))
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">Journey List</div>
              <div className="font-14">{journeyMember &&  journeyMember?.data?.name}</div>
            </div>
            <div className="flex aligncenter">
              {!CheckAccess.hasJourneyMemberAccess(currentUser) &&
                <NavIcon id="add_new_journey" dataTip="Launch New Journey" 
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  onSelect={addNewJourney} icon="fas fa-plus"/>
              }
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs">
            <div className="row m-t-30">
              {journeyListCard}
            </div>
            <Pagination totalItems={requestParams.totalItems}
              currentpage={currentpage} setCurrentpage={setCurrentpage}
              itemsPerPage={requestParams.per_page}/>
          </div>
        </div>
      </div>
      
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
      {isJourneyMenuOpen && (
        <JourneyTypeMenu isOpen={isJourneyMenuOpen} 
          toggleModal={toggleJourneyMenu}
          journeyMember={journeyMember}/>
      )}
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_journey} 
          onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} 
          toggleModal={toggleHelpMessage}/>
      )} 
      <SideMenuBar helpId="2" />
    </Fragment>
  )
}

export default JourneyList;