let FormHelper = {}  
  
  FormHelper.setInputTypeValidation = (field, inputAttributes, validationAttributes, position) => {
  	
    /*if(position == 0){
      inputAttributes.autoFocus = true;
    }*/

    let vo = field.field_validation_options;
  	if(vo){
  	/*	if(vo.display_format && vo.display_format!=''){
			inputAttributes['ui-mask'] = vo.display_format;
			if(vo.preserve_display_format){
				inputAttributes['model-view-value'] = true;
			}
		}*/

		/*if(vo.display_filter && vo.display_filter.filter){
			inputAttributes['view-filter'] = null;
			inputAttributes['filter_format'] = vo.display_filter.filter;
			if(vo.display_filter.currency_symbol){
				inputAttributes['currency_symbol'] = vo.display_filter.currency_symbol;
			}else if(vo.display_filter.fraction_size){
				inputAttributes['fraction_size'] = vo.display_filter.fraction_size;
			}
		}*/

		if(vo.validation_type){
			  switch (vo.validation_type) {
    			case 'email':
    				inputAttributes['type'] = "email";
    				validationAttributes.pattern = {
            			value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            			message: "invalid email address"
          			}
        			break;
    			case 'number':
    				inputAttributes['pattern'] = vo.regex;
    				validationAttributes.pattern = {
            			value: vo.regex,
            			message: "invalid input pattern"
          			}
        			break;
        	case 'url':
    				inputAttributes['type'] = "url";
        	  break;
        	case 'alpha_numeric':
    				inputAttributes['pattern'] = vo.regex;
    				validationAttributes.pattern = {
            			value: vo.regex,
            			message: "invalid input"
          	}
        		break;
        	case 'phone_no':
            field.component_type = "phone_no"
            inputAttributes.format = vo.display_format
            
            //display_format
    				/*inputAttributes['pattern'] = vo.regex;
    				validationAttributes.pattern = {
            			value: vo.regex,
            			message: "invalid phone no"
          	}*/
        	  break;
			  }
		  }
  	}
  }

  FormHelper.setMinMaxValidation = (field, inputAttributes, validationAttributes) => {
  	let vo = field.field_validation_options;
  	if(vo){
  	  if(vo.min_length){
		    inputAttributes['minLength'] = vo.min_length;
	    }
	
	    if(vo.max_length){
		    inputAttributes['maxLength'] = vo.max_length;
	    }
  	}
  }

  FormHelper.checkAccessControl = (field, parentAttributes) => {
	  if(field && field.expression){
		  
  	}
  }

  FormHelper.checkFieldConditionality = (field, modal_val) => {
	  let visibility = true;
    if(field?.visibility_logic){
		  var f = field.visibility_logic;
		  var e ='';
		  if(f.exp_show && f.exp_show!=''){
			   e = f.exp_show;
		  }

		  if(f.exp_hide && f.exp_hide!=''){
			  if(e!=''){
				  e += ' || '+f.exp_hide;
			  }else{
				  e = f.exp_hide;
			  }
		  }
      
      try{
        visibility = eval(e);
	    }catch(e){
        return false;
      }
    }

    return visibility;
  }

  FormHelper.check_isNaN = (field) => {
	  if(!isNaN(field.client_id)){
		  field.client_id = 'field_'+field.client_id;
	  }
  }

  FormHelper.setDisabled = (field, inputAttributes) => {
  	if(field.read_only == 'yes'){
		  inputAttributes['disabled'] = true;
  	}	
  }

  FormHelper.initializeValue = (field, modal_val, form_mode) => {
	  if(form_mode == 'create-form-submissions' && field.default_value!=null){
		  if('radio select checkbox'.includes(field.component)){
			  modal_val[field.client_id] = field.default_value;
		  }
	  }
  }

  FormHelper.setTouched = (e, form) => {
  	form[e.target.name].touched = true;
  }

  FormHelper.setDatePicker = (field, inputAttributes) => {
    let vo = field.field_validation_options;
    field.dateFormat = "YYYY-MM-DD";
    if(vo){
      if(vo.is_future_date)inputAttributes.minDate = new Date();
      field.dateFormat = vo.display_format || field.dateFormat;
    }
    if(field.component == 'timepicker'){
      inputAttributes.showTimeSelect = true
    }
  }

  FormHelper.onChangeExp = (field, value) => {
    try{
      if(field.on_change_expression){
        value[field.client_id] = eval(field.on_change_expression);
      }
    }catch(e){

    }
  }

  FormHelper.isNumeric = (value) => {
    if(isNaN(value)){
      return value
    }

    return parseInt(value);
  }

  FormHelper.checkFieldValidity = (field, data, errors, pkey) => {
    let name = field.client_id;
    errors[name] = errors[name] || {};
    errors[name].required = field.required;
    errors[name].label = field.label;
    errors[name].id = field.client_id;
    errors[name].tab_index = pkey;
    if(data[name]==null || data[name]==''){
      errors.invalid[name] = true;
      errors[name].invalid = true;
    }
  }

  FormHelper.setLabelStyle = (field, isFormWizard, parentAttributes) =>{
    let labelAttributes = {
      'id':'label_'+field.client_id
    }

    if(isFormWizard){
      labelAttributes.className=`f18 fw500 m-b-10 ${field.required && 'req-field'}`;
      parentAttributes.className="form-group col-xs-12"
    }else{
      labelAttributes.className=`form-label m-b-10 ${field.required && 'req-field'}`;
      parentAttributes.className=`${field.no_of_column == '1'?'col-xs-12':'col-md-6'}`
    }

    return labelAttributes;
  }

  FormHelper.setFormTitle = function(component, modal_val, value, data_source){
    try{  
      if(component.is_form_title){
        if(component.component == 'multi_select' || component.component == 'checkbox'){
          let o = data_source.options.reduce((obj, item) => (obj[item.value] = item, obj) ,{});
          let t = [];
          value.map((i)=>{
            t.unshift(o[i])
          })
          modal_val.record_title = t.join(' ');
        }else if(data_source){
          let o = data_source.options.reduce((obj, item) => (obj[item.value] = item, obj) ,{});
          modal_val.record_title = o[value].label;
        }else{
          modal_val.record_title = value;  
        }
      }
    }catch(e){
        
    } 
  }

  FormHelper.validate = (form_id, data, error, setError) =>{
    let ele = document.getElementById(form_id)
    if(!ele)return;
    error = {is_valid:false}
    ele.querySelectorAll("[required]").forEach((i)=> {
      error[i.name] = error[i.name] || {}  
      if(Array.isArray(data[i.name]) && data[i.name].length == 0){
        error[i.name].invalid = true
      }else if(data[i.name] ?? false){
        delete error[i.name]
      }else{
        error[i.name].invalid = true
      } 

      /*if (!allAreFilled) return
        if (!i.value) allAreFilled = false
        if (i.type === "radio") {
          let radioValueCheck = false
          document.getElementById(form_id).querySelectorAll(`[name=${i.name}]`).forEach((r) => {
            if (r.checked) radioValueCheck = true
          })
          allAreFilled = radioValueCheck
      }*/

    })
    error.is_valid = Object.keys(error).length == 1;
    setError({...error})
  }

  FormHelper.validateEmails = (mails) =>{
    let emails = mails.replace(/\s/g,'').split(",");
    let invalidEmails = []
    for (let i = 0; i < emails.length; i++) {
      if( emails[i] == "" || ! FormHelper.validateEmail(emails[i])){
        invalidEmails.push(emails[i])
      }
    }
    console.log(invalidEmails)
    return invalidEmails
  }

  FormHelper.validateEmail = (email) =>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  FormHelper.toggleListString = (item, list = [], setter) => {
    let idx = list.indexOf(item);
    if(idx > -1) {
      list = list.filter(i => i !== item)
    }else {
      list.push(item)
    }
    setter([...list])
  }

  FormHelper.existsListString = (item, list = []) => {
    return list.indexOf(item) > -1
  }
  
export default FormHelper;