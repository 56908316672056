import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Dabble from './Dabble'
import querystringify from "querystringify"
import DailyDabbleService from "./DailyDabbleService"
import Pagination from '../Pagination'
import ConfirmMenu from "../Modals/ConfirmMenu"
import FormTemplateId from '../../constants/FormTemplateList'
import {Link, useHistory} from "react-router-dom"
import useModal from "../../hooks/useModal"
import useStyle from '../../hooks/useStyle'
import Cs from '../../services/CommonService'
import {PillDsList} from "../Journey/UserRoles/NotificationPill" 
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons";

let requestParams = {}
let dataSource = {}

const DailyDabbleUserView = (props) => {
  useStyle('/css/common.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [dabbleList, setDabbleList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])

  let history = useHistory()
  let queryParam = querystringify.parse(props.location.search)
  
  useEffect(() => {
    requestParams = {
      'page': currentpage,
      'paginate': 1,
      'per_page': 20,
      'sort_column': 'latest_dabble_date',
      'sort_direction': 'desc',
      'search': props.searchString,
    }

    getDailyDabbleList()
  }, [props.searchString, currentUser.id, currentpage])

  let getDailyDabbleList = () => { 
    DailyDabbleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totaltems = res.data.meta.total
        setDabbleList(res.data.daily_dabbles)
      }
    })
  }

  const TableData = ({dabbleList}) => dabbleList && dabbleList.map((o, k) =>
    <TR key={k}>
      <TD className="text-gray" data-title="Id">{o.record_id}</TD>
      <TD data-title="Title">{o.data.title || o.data.record_title}</TD>
      <TD data-title="Updated Date" >{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD data-title="Content Type" >
        <PillDsList list={o.data_source.classification} dataKey='label' style="pill white bg-lred m-r-5"/>
      </TD>
      <TD data-title="Preview Answers" >
        <Fragment>
          <Link id="question_form_icon"  to={`/dailyCueQuote/${o.id}`} data-tip="Preview Answers">
            <i className="far fa-file"/>
          </Link>
        </Fragment>
      </TD>
    </TR>
  )

  return (
    <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                Dabble List
              </div>
            </div>

            <div className="flex aligncenter">
              
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              <div className="col-xs-12 m-t-10">
                <Table className="shadow-small">
                  <THead>
                    <TR>
                      <TH>Id
                      </TH>
                      <TH>Title
                      </TH>
                      <TH>Updated Date 
                      </TH>
                      <TH>Content Type
                      </TH>
                      <TH>View Responses
                      </TH>
                    </TR>
                  </THead>
                  <TBody>
                    <TableData dabbleList={dabbleList} />
                  </TBody>
                </Table>
                <Pagination totalItems={requestParams.totalItems} 
                  currentpage={currentpage} 
                  setCurrentpage={setCurrentpage} 
                  itemsPerPage={requestParams.per_page}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  )

}

export default DailyDabbleUserView;