import React, { useEffect, useState, useContext} from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import ResourceService from "../../../../../components/Journey/HighSchoolJourney/Resources/ResourceService";
import FormContainer from '../../../../FormBuilder/FormContainer';
import {FormApi} from "../../../../Common/FormApi"

let formMode = null; 
let resource = {}; 
let data = {}; 
let formFn = {};  
let form_template_id = null;

function ResourceForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ResourceService.get(params.id).then((res) => {
      if(res.status==200){
        resource = res.data.education_resource;
        data = resource.data || {};
        form_template_id = resource.activity_form_id
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       resource = null;
    })
  }
  
  useEffect(() => { 
    resource = {}; 
    data = {}; 
    formFn = {'form_type':'education_resource', editPermission:true}; 
    formMode = 'create-form-submissions';

    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (resource) => {
    resource.created_by = authState.user.id;
    resource.journey_category_id = queryParam.journey_category_id; 
    resource.journey_profile_id = queryParam.journey_profile_id; 
    resource.privacy_type = data.share_selection == 1 ? 'shared':'private'
    resource.partnership_id = authState.user.domain_info.partnership_org_id
    resource.organization_id = authState.user.current_organization_id

    ResourceService.create(resource).then((res) => {
      if(res.status == 201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (resource) => {
    resource.updated_by = authState.user.id;
    resource.created_by = authState.user.id;

    ResourceService.update(resource).then((res) => {
      if(res.status == 204){
       onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    if(queryParam.journey_category_id){
      props.history.push(`/highschool/resources/${queryParam.journey_category_id}/${queryParam.journey_profile_id}`)
    }else{
      props.history.push('/admin/resources')
    }
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <FormApi formFn={formFn} data={data}/>
        <DynamicForm formMode={formMode} formId={FormTemplateId.EducationResourceForm} 
          form={resource} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect}  
          setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default ResourceForm;