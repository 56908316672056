import React, { Fragment, useEffect, useState, useContext } from "react"
import useStyle from "../../hooks/useStyle"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import DigitalContentService from "../DigitalContent/DigitalContentService"
import env from "../../env"
import HamBar from "../Authentication/HamBar"
import Logo from "../Authentication/Logo"
import LottieLight from "../Common/LottieLight"
import { Link } from "react-router-dom"
import Footer from "../Footer/Footer"
import NewsletterSnippet from "../Authentication/NewsletterSnippet"
import SocialShareBtn from "../Common/SocialShareBtn"
import SideMenuBar from "../Common/SideMenuBar"

let requestParams = {}

const FAQList = (props) => {
  useStyle("spmarketing")
  useStyle("faq")
  useStyle("daily_dabble")

  const { state: currentUser, dispatch,isAuthenticated: isAuthenticated } = useContext(AuthContext)
  const [faqList, setFaqList] = useState([])
  const [index, setIndex] = useState()

  window.addEventListener("scroll", () => {
    console.log(document.documentElement.scrollTop)
  })

  useEffect(() => {
    requestParams = {
      content_type: "faq",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null,
      data_filter:[{key:'web_page' , operator:'@>' , value: props.web_page || ''}]
    }
   
    getFaqContent()
  }, [currentUser.id])

  let getFaqContent = () => {
    DigitalContentService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setFaqList(res.data.digital_contents)
      }
    })
  }

  const faqView = faqList.map((n, k) => {

    const onToggle=(a)=>{
      setIndex(a)
      /*if((document.getElementById('accordion-button-'+k).getAttribute('aria-expanded') === null)){
        document.getElementById('accordion-button-'+k).setAttribute('aria-expanded','true')
        document.getElementById('accordion-i-'+k).className= 'fa fa-chevron-up pull-right'
        document.getElementById('accordion-item-'+k).className='accordion-item p-10 shadow-large'
      }else{
        document.getElementById('accordion-button-'+k).removeAttribute('aria-expanded')
        document.getElementById('accordion-i-'+k).className='fa fa-chevron-down pull-right'
        document.getElementById('accordion-item-'+k).className='accordion-item p-10'
      }*/
    }

      return(
        <Fragment key={k}>
          <div id={`accordion-item-`+k} className={index === k ? 'accordion-item p-10 shadow-large':'accordion-item p-10'}>
            <button id={`accordion-button-`+k} onClick={(e)=>onToggle(k)} aria-expanded={index === k ? 'true':'false'}>
              <span className="accordion-title">{n.data.faq}</span>
              <i id={`accordion-i-`+k} className={index === k ? 'fa fa-chevron-up pull-right':'fa fa-chevron-down pull-right'} aria-hidden="true"></i>
            </button>
            <div className="accordion-content scroll-auto">
              <p className="rich-text m-l-10" dangerouslySetInnerHTML={{ __html: n.data.response}}></p>
              {n.data.url && 
                <a className="pill white p-7 font-16 bg-rgb1" href={n.data.url} target="_blank">
                  <b>{n.data.button_text || 'Read More'}</b>
                </a>
              }
            </div>
          </div>
      </Fragment>
    )
  })

  return (
    <Fragment>
      <div className="page-content row m-b-30 m-t-10">      
        <main className="p-7 bg-lwhite">
          <div className="col-xs-12">
            <div className="">
              <h1 className="bold text-left m-l-20 m-r-20 mobile-text-center"> FAQ</h1>
            </div>
          </div>
  	  	  <section className="intro">
      		  <div className="w-100p">
              <div className="accordion">
                {faqView}
              </div>
  		      </div>
  		    </section>
        </main>
      </div>
    </Fragment>
  )
}

export default FAQList;