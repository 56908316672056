/*Component used in planner for assigning assessment to planner*/
import React, {useState, Fragment, useEffect} from "react"
import GenericModal from "../../../../Modals/GenericModal"
import AssessmentService from "../../Assessments/AssessmentService"
import {FilterMenuItem} from "../../../../Common/MenuItem"
import CourseReviewService from "../../CourseReviewAssignments/CourseReviewService"

let requestParams = null
const WAIT_INTERVAL = 1000;
let timer = null

const AssessmentListModal = (props) => {
  
  const [assessmentList, setAssessmentList] = useState([])
  const [selectedAssessment, setSelectedAssessment] = useState(props.item.assessment || {})

  useEffect(() => {
    requestParams = {
      'journey_profile_id':props.journeyProfileId,
      'journey_category_id':props.journeyCategoryId,
      'page':1,
      'per_page':100,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'course_id':props.courseId,
      'search':props.searchString,
    }
    //'id':selectedAssessment.review_id
    getAssessmentList()

    return () => {
      requestParams = {}
      clearTimeout(timer)
    }
  }, []);

  let getAssessmentList = () => { 
    AssessmentService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setAssessmentList(res.data.assessments)
        requestParams.id = null
      }
    })
  }

  let handleChange = (e) =>{
    clearTimeout(timer)
    requestParams.search = e.target.value
    timer = setTimeout(getAssessmentList, WAIT_INTERVAL)
  }

  const assignAssessment = (assessment) => 
    assignReview({
      id: selectedAssessment.id,
      journey_category_id:props.journeyCategoryId,
      journey_profile_id:props.journeyProfileId,
      item_type:'carrier_training.content',
      item_id:props.item.id,
      review_type:'assessment',
      review_id:assessment.id,
      course_id:props.courseId,
      data:{
        title:assessment.data.record_title, 
        details:assessment.data.details
      }
    }, 'assessment')

  const assignReview = (req, field) =>{
    if(req.id){
      CourseReviewService.update(req).then((res)=>{
        if(res.status == 204){
          setSelectedAssessment(req)
          props.toggleModal()
        }
      })
    }else{
      CourseReviewService.create(req).then((res)=>{
        if(res.status == 201){
          props.item[field] = res.data
          setSelectedAssessment(res.data)
          props.toggleModal()
        }
      })
    } 
  }

  let AssessmentsView = () => assessmentList.map((a, i)=>
    <div key={i} className={`p-5 font-15 font-500 ${a.id == selectedAssessment.review_id?'bg-green white':'bg-white'}`}
      onClick={e => assignAssessment(a)}>{a.data.record_title}</div>
  )

  return(
    <div className="p-5 scroll-x">
      <div className="input-group m-b-5">
        <input type="text" onChange={e=>handleChange(e)} placeholder="Search" className="form-control input-sm"/>
        <span className="input-group-btn">
          <p type="button" className="bg-highlight input-sm">
            <i className="fa fa-search white"/>
          </p>
        </span>
      </div>  

      <AssessmentsView/>
    </div>
  )

}

export default AssessmentListModal;