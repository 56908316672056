import React, {useState, Fragment, useEffect, useContext} from "react"
import Pagination from '../Pagination'
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommonService from "../../services/CommonService"
import NotificationPopup from '../Common/NotificationPopup'

let requestParams = {}

export const DataSourceList = (props) => {
  useStyle('filter_dialog')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [dataSourceList, setDataSourceList] = useState([])
  const [dataSources, setDataSources] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  let params = props.match.params

  useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
    }

    getDataSource();
  }, [props.searchString, currentpage])

  let getDataSource = () => { 
    CommonService.getDataSources(requestParams).then((res)=>{
     if(res.status == 200){
       //setPagemeta(res.data.meta)
       requestParams.totalItems = res.data.meta.total
       setDataSources(res.data.data_sources)
      }
    })
  }

  let routeDataSourceList = (data_source_id) => { 
    props.history.push(`/data_source_list/filters/${data_source_id}`)
  }

  const OptionsObjList = ({list}) => list && list.map((o, k) =>
    <span className="checkbox-container" key={k}>
      <span>{o.label}</span>
      <input type="checkbox" checked={true} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  const DataSources = dataSources.map((o, k) => 
    <Fragment key={k}>
      <div className="filter-label" onClick={e=>routeDataSourceList(o.id)}>{o.title}</div>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="top-10 col-xs-12">
        <h5 className="float-left font-500">Data Source</h5>
        <div className="clear"></div>
      </div>

      <div className="col-xs-12">
        {DataSources}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
        setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
    </Fragment>
  )
};

export default DataSourceList;