import React, {useState, useRef, Fragment, useContext, useEffect} from "react"
import {Link} from "react-router-dom"
import ReactTooltip from 'react-tooltip'
import querystringify from "querystringify"
import useStyle from '../../../hooks/useStyle'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import JourneyTitle from "../../Journey/JourneyTitle/JourneyTitle"
import BarChart from "../../Charts/BarChart"
import UserFormService from "../UserFormService"
import {CollegeColor} from '../../../constants/DataSourceList'
import {NavIcon} from '../../Common/MenuItem'

let requestParams = {}

const FormReport = (props) => {
  useStyle("panel")
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [currentpage, setCurrentpage] = useState(1)
  const [loading, setLoading] = useState(true)
 
  const [reports, setReports] = useState([])

  let params = props.match.params
  let {project} = props.location.state
  let queryParam = querystringify.parse(props.location.search)

  useEffect(() => {
    requestParams = {
      user_form_id:params.id,
    }
    getFormReport()
    ReactTooltip.rebuild()
  }, [currentpage])

  let getFormReport = () => {
    setLoading(true)
    UserFormService.report(requestParams).then((res) => {
      if(res.status==200){
        setReports(res.data)
        setLoading(false)
      }
    })
  }

  const GraphContainer = () => {
    let colors = CollegeColor
    let height = 350
    let markers = null
    let chart = {}
    
    chart.college = {
      series: [{
        data: reports,
      }],
      title: {
        text: 'User Form Reports',
        align: 'center',
        floating: true
      },
      x_axis: reports,
      chart_type:'bar'
    }

    return(
      <Fragment>
        <div className="row bg-grey p-7">
          <div className="pull-right bg-white">
          </div>
        </div>
        <div className="m-t-10">
          <BarChart series={chart.series} x_axis={chart.x_axis} 
            title={chart.title} chart_type={chart.chart_type}
            y_axis_label="" colors={colors}
            x_axis_label="" markers={markers}
            className="clearfix" 
            id="report" height={height}/>
        </div>
      </Fragment>
    )
  }

  if(loading)
    return(<div className="spinner"/>);

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-12">
          <div className="col-md-4 pull-left">
            <JourneyTitle title={"Field Reports"} project={project} />
          </div>
          <div className="col-md-8 pull-right">     
            <NavIcon id="back_icon" dataTip="Back to User Forms" 
              onSelect={e=>props.history.goBack()}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-arrow-left"/>
          </div>
          <div className="clear"></div>
        </div>
      </div>

      <div className="page-content bg-white row">
             
          <div className="col-xs-12 col-sm-6 p-0-20 m-b-10">
            <div className="m-3 bg-white br-grey"> 
              <GraphContainer/>
            </div>
          </div>   
      </div>

      <ReactTooltip place="bottom" />
    </Fragment>
  )
}

export default FormReport;