import React, { Fragment, useState, useEffect, useContext} from "react"
import { useHistory } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import env from "../../env"
import { Link } from "react-router-dom"
import Socket from "../../components/Common/Socket"
import { usePageTour } from "../../components/Common/usePageTour"
import NotificationList from "../Notifications/NotificationList"
import EventTrackingService from "../../services/EventTrackingService"
import UserAccessComponents from "../Subscriptions/UserAccessComponents"
import useModal from "../../hooks/useModal"
import FormTemplateList from "../../constants/FormTemplateList"
import useStyle from "../../hooks/useStyle"

const WAIT_INTERVAL = 1000
let timer = null
let searchKeyword = null
let searchEle = null
let profileImage = "images/avatar-2.jpg"
let orgImage = "images/org.png"
let orgName = ""
let currentPageUrl = null

const Header = (props) => {
  useStyle('/css/header.css', true);
  useStyle('/css/responsive.css', true);

  const { state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext)
  
  const history = useHistory()
  let tour = usePageTour()
    
  const [searchButton, setSearchButton] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [instantNotification, setInstantNotification] = useState(null)

  const {isOpen:isUserAccessListOpen, toggleModal:toggleUserAccessListModal} = useModal()

  /*const [scroll, setScroll] = useState(0)      
  const [isTop, setIsTop] = useState(false) */

  const setNotification = (data) =>{
    setInstantNotification(data)
    setShowNotification(true)
  }

  Socket(currentUser, setNotification, props.setSocketData)

  if (currentUser.profile_image_url) {
    profileImage = env.file_url + currentUser.profile_image_url
  }

  if (currentUser.current_organization) {
    orgImage = env.file_url + currentUser.current_organization.logo_url
  }
  if (currentUser.current_organization) {
    orgName = currentUser.current_organization.name
  }
  
  useEffect(() => {
    timer = null
    searchKeyword = null
    searchEle = null
    currentPageUrl = null

    let unlisten = history.listen((location, action) => {
      if(currentPageUrl != location.pathname){
        props.setSearchString(null)
        setSearchButton(false)
        /*EventTrackingService.create({
          url:location.pathname,
          event_action:'page_load'
        })*/
      }
      currentPageUrl = location.pathname
    })
    
    setTimeout(function () {
      tour && tour.init()
    }, 1000)
    
    return () => {
      unlisten()
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (props.searchString) {
      params.append("search", props.searchString)
    } else {
      params.delete("search")
    }
    history.push({search: params.toString()})
  }, [props.searchString, history])

  const searchClick = () => {
    if (searchButton === true) {
      setSearchButton(false)
      clearChange()
    } else {
      setSearchButton(true)
    }
  }

  const handleChange = (event) => {
    clearTimeout(timer)
    searchKeyword = event.target.value;
    searchEle = event.target
    timer = setTimeout(triggerChange, WAIT_INTERVAL)
  }

  const clearChange = (event) => {
    clearTimeout(timer)
    searchEle.value = searchKeyword = null
    props.setSearchString(null)
    timer = setTimeout(triggerChange, WAIT_INTERVAL)
  }

  const triggerChange = () => {
    props.setSearchString(searchKeyword)
  }

  const openFeedback = (e) => {
    props.history.push(`/feedback?url=${props.location.pathname}`)
  }

  const toggleNotification = (e) =>{
    e.preventDefault()
    setInstantNotification(null)
    setShowNotification(!showNotification)
  }

  /*useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setIsTop(scrollCheck)
        setScroll(scrollCheck)
      }
    })
  },[])*/

  return (
    <Fragment>
       <div id="ct" className="header-container">
        <header className="top_bar">
          <div className="flex wrap justspacebetween aligncenter top_bar_content">
            <div className="flex aligncenter gap-5">
              <span className="logo_bg flex justifycenter aligncenter">
                <Link to="/home" data-tip="Go to home screen">
                  <img id="header_home" src="images/logo-small.png" alt="cueTree Logo" />
                </Link>
              </span>
              <span className="flex justifycenter aligncenter m-l-10 text-white f18"
                data-tip="Back to previous page"
                onClick={(e) => {e.preventDefault();history.goBack()}}>
                  <i className="fa fa-arrow-left" />
              </span>
            </div>
            <div className="flex aligncenter">
              <ul className="flex aligncenter menu-xs">
                <li data-tip="Create Journey">
                  <Link id="journey_btn" to="/journey/members"
                    className="flex m-r-30 m-r-15-xs pos_relative">
                      <i className="fas far fa-plus white font-14" />
                  </Link>
                </li>
                <li data-tip="Search">
                  <a id="search_btn" onClick={e => searchClick()} 
                    className="flex m-r-30 m-r-15-xs pos_relative">
                    <i className="fas far fa-search white font-14"/>
                  </a>
                </li>
                {currentUser?.current_subscription &&
                  <li data-tip={'Account Plan '+ (currentUser?.current_subscription?.name || '')}>
                    <a onClick={(e) => toggleUserAccessListModal()} className="flex m-r-30 m-r-15-xs">
                      <img src="images/web-icon.png" alt="" />
                    </a> 
                  </li>
                }
                <li data-tip="View notifications">
                  <Link to="/notifications" className="flex m-r-30 m-r-15-xs pos_relative">
                    <img src="images/notification-icon.png" alt="" />
                    <span className="notification_count_header tcenter f10 border-rounded flex aligncenter justifycenter fw900">
                      {currentUser.notification_count > 99 ? "99+" : (currentUser.notification_count)}
                    </span>
                  </Link>
                </li>
                <li data-tip="Set account preferences">
                  <Link to="/user/profile" 
                    className="flex aligncenter m-r-30 m-r-15-xs user_dropdown-xs">
                      <img src={profileImage} style={{height:"25px"}}/>
                  </Link>
                </li>
                <li data-tip="View main menu" onClick={e=> {props.setOpenSideNav(!props.openSideNav)}}>
                  <div className="show-xs">
                    <div
                      id="menu"
                      className="m-r-30 m-r-15-xs flex aligncenter justifycenter coldir"
                    >
                      <div className="menu-line1" />
                      <div className="menu-line2" />
                    </div>
                  </div>
                  <div className="flex m-r-30 m-r-15-xs hide-xs">
                    <img src="images/menu-icon.png" alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
      
      {searchButton && 
        <div className="search-box search-color bg-white shadow-tiny bottom-10" >
          <i className="fa fa-search"></i>
          <input type="text" placeholder="Search here.." autoFocus onChange={(e)=>handleChange(e)} />
        </div>
      }

      
   


      {showNotification && 
        <NotificationList showAsPopup="true" toggleNotification={toggleNotification} 
        instantNotification={instantNotification} history={props.history}/>
      }

      {isUserAccessListOpen && 
        <UserAccessComponents toggleModal={toggleUserAccessListModal} 
        isOpen={isUserAccessListOpen}
        subscription={currentUser.current_subscription}
        journeyMemberId={currentUser.domain_info?.member_id}/>
      }
    </Fragment>
  )
}

export default Header;