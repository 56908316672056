import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";
class DataGroupService extends BaseService {
 /* 
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(group){
    return new Promise((resolve, reject) => {
      axios.post(url, group).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(group) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${group.id}`, group).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }*/

}

export default new DataGroupService('backend', 'data_groups');