import React, { useState, Fragment, useContext} from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";

const AddRole = ({ role, isOpen, toggleModal, addNewRole, dataSources, componentList, journeyType}) => {

  if(role){
    role.permissions = role.permissions || {};
    role.access_list = role.access_list || [];
  }

  let typeOfUser = null;
  try{
    typeOfUser = currentUser.domain_info.data_source.type_of_use.label
  }catch(e){

  }

  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);
  const [newRole, setNewRole] = useState(role);

  /* 
   * To check an item exist in checkbox options.
   */
  const setAccess = (access_type) => {
    let obj = newRole.permissions;
    if (obj[access_type]) {
      delete obj[access_type]
    }else {
      obj[access_type] = true; 
    }
    setNewRole({...newRole})
  }

  const toggle = (access_type) => {
    newRole.access_type = access_type
    setNewRole({...newRole});
  }

  const setRole = (item, key) => {
    let list = newRole[key];
    let idx = list.indexOf(item);
    if(idx > -1) {
      list = list.filter(i => i !== item)
      newRole[key] = list;
    }else {
      list.push(item)
    }
    setNewRole({...newRole})
  }

  const exists = (item, key) => {
    let list = newRole[key];
    return list.indexOf(item) > -1
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">

        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p12-10-15">{role.id?'Update':'Add'} Role</h4>
          <button className="btn-close"
            title="Close Modal" onClick={e=>toggleModal(e)}>×
          </button>
        </div>
    
        <form className="ml-container">
          <div className="ml-section">

            <label><b>Role</b></label>
            <input onChange={e=>{newRole.role = e.target.value}} className="ml-input ml-border"
              type="text" placeholder="Role name" name="role_name" required defaultValue={newRole.role}/>
            <div className="ml-margin-bottom">Customize your team roles names</div>

            <label><b>Description</b></label>
            <textarea onChange={e=>{newRole.role_description = e.target.value}}
              className="ml-input ml-border ml-margin-bottom" placeholder="Description" 
              defaultValue={newRole.role_description} required>
            </textarea>
            
            <label className="req-field"><b>Access Permissions</b></label>
            <span className="checkbox-container" onClick={e => toggle('full_access')}>
              <span>Full Access (Admin level permission)</span>
              <input type="checkbox" checked={newRole.access_type == 'full_access'} readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>

            {newRole.access_type == 'full_access' && <Fragment>
              <span className="checkbox-container m-l-15" onClick={e => setAccess('is_primary_admin_removeable')}>
                <span>Invited Admins can remove primary Admin</span>
                <input type="checkbox" checked={newRole.permissions.is_primary_admin_removeable} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>

              <span className="checkbox-container m-l-15" onClick={e => setAccess('is_primary_admin_removeable')}>
                <span>Invited Admins cannot remove primary Admin </span>
                <input type="checkbox" checked={!newRole.permissions.is_primary_admin_removeable} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>
              </Fragment>
            }
            
            <span className="checkbox-container" onClick={e => toggle('partial_access')}>
              <span>Specific Journey Sections (select from list)</span>
              <input type="checkbox" checked={newRole.access_type == 'partial_access'} readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>

            <span className="checkbox-container" onClick={e => toggle(null)}>
              <span>Only when invited to specific journey tiles</span>
              <input type="checkbox" checked={!newRole.access_type} readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>

            {newRole.access_type == 'partial_access' && componentList && 
              <Fragment>
                <label><b>Select component to access</b></label>
                {componentList.map((r, k) => (
                  <span key={k} className="checkbox-container" onClick={e => setRole(r.sid, 'access_list')}>
                    <span>{r.label}</span>
                    <input type="checkbox" checked={exists(r.sid, 'access_list')} readOnly/>
                    <span className="checkbox-checkmark"></span>
                  </span>
                ))}
              </Fragment>
            }

            <label><b>Create Permissions</b></label>
            <div className="m-b-5">
              Checking this box will allow users in this role to create Team activity tiles
            </div>
              
            {Object.keys(dataSources.permissions).map((p)=>
              <Fragment key={p}>
                <span className="checkbox-container" onClick={e => setAccess(p)}>
                  <span>{dataSources.permissions[p]}</span>
                  <input type="checkbox" checked={newRole.permissions[p]!=null} readOnly/>
                  <span className="checkbox-checkmark"></span>
                </span>
              </Fragment>
            )}

            <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>addNewRole(e, newRole)}>
              <b>{role.id?'Update':'Create'}</b>
            </div>
            
          </div>
        </form>
    
      </div>
    </div>
  </Fragment>, document.body))
}

export default AddRole;