import React, { useEffect, useState } from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import CollegeActivityService from "../../../../../components/Journey/CollegeJourney/Activities/CollegeActivityService";
import FormContainer from '../../../../FormBuilder/FormContainer';

let formMode = null; 
let activity = {}; 
let data = {}; 
let formFn = {};  

function ActivityForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    let req = {'id':params.id, 'journey_category_id': params.journey_category_id}
    CollegeActivityService.get(req).then((res) => {
      if(res.status==200){
        activity = res.data.activity;
        data = activity.data || {};
        params.form_template_id = activity.activity_form_id;

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       activity = null;
    })
  }
  
  useEffect(() => { 
    activity = {}; 
    data = {}; 
    formFn = {'form_type':'edu_college_journey.activity', 'journey_category_id':params.journey_category_id}; 
    formMode = 'create-form-submissions';
    data = {}; 

    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (activity) => {
    activity.created_by = authState.user.id;
    activity.journey_profile_id = params.journey_profile_id;
    activity.journey_category_id = params.journey_category_id;
    activity.activity_type = params.type;
    activity.activity_form_id = params.form_template_id;  
    activity.privacy_type = 'private';
    activity.data.activity_type = parseInt(queryParam.activity_type);
    activity.data.grade_dsl_id = parseInt(queryParam.grade_dsl_id);
    activity.data.grade_array = [parseInt(queryParam.grade)];
    activity.data.grade = parseInt(queryParam.grade);
    CollegeActivityService.create(activity).then((res) => {
      if(res.status === 201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id;
    CollegeActivityService.update(activity).then((res) => {
      if(res.status === 204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    if(props.history.goBack){
      props.history.goBack()
    }else{
      props.history.push(`/college/activity/${params.journey_category_id}/${params.journey_profile_id}`)
    }
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={params.form_template_id} form={activity} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default ActivityForm;