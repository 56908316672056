import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import TeamActivityService from "../../../../../components/Journey/TeamJourney/Activities/TeamActivityService";
import querystringify from "querystringify";
import FormContainer from '../../../../FormBuilder/FormContainer';

let formMode = null; 
let activity = {}; 
let data = {}; 
let formFn = {};  

function JourneyActivityForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  
  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    TeamActivityService.get(params.id).then((res) => {
      if(res.status==200){
        activity = res.data.activity;
        data = activity.data || {};
        params.form_template_id = activity.activity_form_id;

        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       activity = null;
    })
  }
  
  useEffect(() => { 
    activity = {}; 
    data = {}; 
    formFn = {
      'form_type':'edu_team_journey.activity', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    } 
    formMode = 'create-form-submissions';
    data = {}; 
    
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (activity) => {
    activity.created_by = authState.user.id;
    activity.education_journey_profile_id = params.education_journey_profile_id;
    activity.journey_category_id = params.journey_category_id;
    activity.activity_type = params.type;
    activity.activity_form_id = params.form_template_id;  
    TeamActivityService.create(activity).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id;
    TeamActivityService.update(activity).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/team/activity/${params.journey_category_id}/${params.education_journey_profile_id}`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
       <DynamicForm formMode={formMode} formId={params.form_template_id} form={activity} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default JourneyActivityForm;