import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../../hooks/useStyle';
import useSort from '../../../hooks/useSorting';
import SortDirection from '../../../components/Common/SortDirection'
import CheckAccess from "../Roles/CheckAccess";

const InvitedUsersTable = ({invitedUserList, getInvitedUserList, requestParams, setEdit, selectInviteUser, rolesObj, currentUser, journey}) => {
  useStyle('table');  
  const {sort} = useSort(requestParams, getInvitedUserList);
  
  const tableData = invitedUserList.map((o, k) =>
    <tr key={k}>
      <td data-title="Id">{k}</td>
      <td data-title="Email">{o.email}</td>
      <td data-title="Full Access">
        <span className="m-r-5">
          <span className={o.access_type == 'full_access' ? "far fa-check-circle" : "far fa-circle"}></span>
        </span>
      </td>
      <td>
        <span>{o.activity_count} Activities</span>
        <br/>
        <span>{o.comments_count} Messages</span>
      </td>
      <td data-title="Role">
        {o.role_ids && o.role_ids.length>0 && (
          <select className="form-control">
            <option value="" disabled selected>Select an item from list below</option>
            {o.role_ids.map((role_id, i) => (
              <option key={i}>{rolesObj[role_id] && rolesObj[role_id].role}</option>
            ))}
          </select>
        )}
      </td>
      <td>
        {CheckAccess.hasEditAccess(currentUser, journey, o) &&   <Fragment>
        <div className="action-btn bg-highlight"
          onClick={(e) => {setEdit(e, o)}}>
          Edit
        </div>
        <div className="action-btn bg-highlight"
          onClick={(e) => {selectInviteUser(e, o)}}>
          Delete
        </div>
        </Fragment>}
      </td>
    </tr>
  )

  return (
    <div id="no-more-tables" className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')}>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('email')}>Email 
              <SortDirection sort_column={requestParams.sort_column} column="email" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('access_type')}>Full Access 
              <SortDirection sort_column={requestParams.sort_column} column="access_type" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('is_email_sent')}>Activities
              <SortDirection sort_column={requestParams.sort_column} column="is_email_sent" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('roles')}>Roles 
              <SortDirection sort_column={requestParams.sort_column} column="roles" reverse={requestParams.reverse}/>
            </th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

};

export default InvitedUsersTable;