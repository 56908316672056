import env from '../../env'
import axios from 'axios'
import BaseService from "../../services/BaseService"

class InterventionProtocolService extends BaseService{

  getNotifications(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+'intervention_protocol/notifications', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new InterventionProtocolService('backend', 'intervention_protocols');