import React, { useState, useEffect, Fragment, useContext } from "react"
import { Link } from "react-router-dom"
import {PortalHeader} from "./PortalHeader"
import PortalPath from "./PortalPath"
import JourneyCategoryService from "../../JourneyCategoryService"
import useStyle from "../../../../hooks/useStyle"
import Teamnodes from "../../../../constants/Teamnodes"
import CheckAccess from "../../Roles/CheckAccess"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import SideMenuBar from "../../../../components/Common/SideMenuBar"
import UpcomingActivities from "../SuggestionPortal/UpcomingActivities"
import {ActivityInfoList, CommentInfoList, StepActivityInfoList} from "../Activities/List/ActivitiesInfo"
import Suggestions from "./Suggestions"
import useModal from "../../../../hooks/useModal"
import GenericModal from '../../../../components/Modals/GenericModal'
import {HighSchoolLink} from "../../../Common/RouteLinks"

let portalPath = null;
let userTypeList = null;
let activeJourney = null;
let share = null;
let dataSource = {};

const PortalDetails = (props) => {
  /*useStyle("panel");*/
  useStyle('css/tile-components.css')

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  
  let params = props.match.params;
  
  const [project, setProject] = useState({})
  const [statics, setStatics] = useState({})
  const [suggestions, setSuggestions] = useState([])
  const [upcomingActivities, setUpcomingActivities] = useState()
  const [recentActivities, setRecentActivities] = useState()

  const { isOpen:isSuggestionOpen, toggleModal:toggleSuggestionModal } = useModal()

  useEffect(() => {
    let req = {
      journey_category_id: params.journey_category_id,
      show_journey_templates: false,
      data_source_params: {
        data_source_id: [3327, 2541, 3410], //Individual journey data_source
        /*"response_type":"object",
        "group_by":"sid"*/
      }
    }

    JourneyCategoryService.getJourneyById(req).then((res) => {
      if(res.status == 200){
        res = res.data
        dataSource = res.data_source_list
        portalPath = res.portal_nodes
        res.journey_category = res.journey_category[0]
        userTypeList = res.data_source_list.fj_i_am
        //portalPath = Teamnodes.indi_path_nodes;
        share = res.invited_journey_member
        CheckAccess.userAccess(currentUser, res.journey_category, share)
        setSuggestions(res.suggestions)
        setProject(res.journey_category)
        setStatics(res.statics)
        setUpcomingActivities(res.upcoming_activity)
        setRecentActivities(res.recent_activities)
        activeJourney = res
      }
    })
  }, [])

  const routePath = (e, path) => {
    e.preventDefault();
    if (path.sid === "planners") {
      props.history.push(
        `/highschool/planners/${params.journey_category_id}/${project.education_journey_profiles.id}`,
        project
      )
    } else if(path.sid === 'roles'){
      props.history.push(`/journey/role/${params.journey_category_id}/${project.education_journey_profiles.id}`, project)
    } else if (path.sid === "edu_resources") {
      props.history.push(
        `/highschool/resources/${params.journey_category_id}/${project.education_journey_profiles.id}`,
        project
      )
    } else if (path.sid === "activities") {
      props.history.push(HighSchoolLink.hs_activity_type({
        journey_category_id:params.journey_category_id, 
        journey_profile_id:project.education_journey_profiles.id
      }, project))
      
    } else if (path.sid === "favorite_colleges") {
      props.history.push(
        `/highschool/favourite/colleges/${project.id}/${project.education_journey_profiles.id}`,
        project
      )
    } else if (path.sid === "suggestions") {
      toggleSuggestionModal()
      /*props.history.push(
        `/highschool/suggestions/${project.id}/${project.education_journey_profiles.id}`,
        project
      )*/
    } else if (path.sid === "cue_me_profiles") {
      if (!currentUser.current_admin.role_master) {
        props.history.push(
          `/highschool/profile/${project.id}/${project.education_journey_profiles.id}`,
          project
        )
      }
    } else if (path.sid === "invite_users") {
      props.history.push(
        `/invite/user/${project.id}/${project.education_journey_profiles.id}`,
        {'journey': project, 'share':share}
      )
    } else if (path.sid === "schedules") {
      props.history.push(
        `/highschool/schedules/${project.id}/${project.education_journey_profiles.id}/education`,
        project
      )
    } else if (path.value == 7) {

      /*{env.admin_emails.includes(currentUser.email) && (
          <Link to="/highschool/path">
            <i className="fas fa-map-signs"></i> Path
          </Link>
        )}

        props.history.push(
          `/highschool/grade_path/${project.id}/${project.education_journey_profiles.id}`,
          project
        );*/

        const currentGrade = project.education_journey_profiles?.data?.fj_edu_current_grade
        const carrierInterests = project.education_journey_profiles?.data?.interest 
        props.history.push(`/highschool/path/${project.id}/${project.education_journey_profiles.id}?current_grade=${currentGrade}&carrier_interests=${carrierInterests}`, project)
        window.location.reload();
    }else if (path.sid === "interviews") {
      props.history.push(
        `/highschool/interviews/${project.id}/${project.education_journey_profiles.id}`,
        project
      )
    }
  };

  if (!project.id) return <PreLoadSpinner />;

  return (
    <>
      <div id="ct">
        <div className="app_content">
          <PortalHeader project={project} createdUser={project.created_user} 
          share={share} statics={statics} userTypeList={userTypeList} app={props.app}/>

          <div className="app-content-section">
            <PortalPath project={project} currentUser={currentUser} 
              invitedUser={share} portalPath={portalPath} 
              routePath={routePath} statics={statics}/>    
          </div>
        </div>
      </div>

      <small className="font-12 p-5 lh-16">3327</small>
      
      <SideMenuBar helpId="3" app={props.app} />

      {isSuggestionOpen  && (
        <GenericModal title="Check out these suggestions" component={Suggestions} 
          toggleModal={toggleSuggestionModal} history={props.history} journey={project} 
          journeyProfiles={project.education_journey_profiles} suggestions={suggestions} 
          dataSource={dataSource} currentUser={currentUser} share={share}/>
      )}
    </>
  )
  
  return (
    <Fragment>
      <div className="p-5">
        <div className="portlet">
          <PortalHeader project={project} createdUser={project.created_user} 
          share={share} statics={statics} userTypeList={userTypeList} app={props.app}/>
          
          <div id="bg-info" className="panel-collapse collapse in" style={{ minHeight: "260px" }}>
            <div className="portlet-body">
              <h5>Transform your journey to college.</h5>
              
              <PortalPath project={project} currentUser={currentUser} invitedUser={share}
                portalPath={portalPath} routePath={routePath} statics={statics}/>

              <div className="row"> 
                {recentActivities && 
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <div className="edu-path">
                      <div className="head">Recent Activities</div> 
                      <div className="p-5 ht-175 scroll-auto">
                        <ActivityInfoList activities={recentActivities.activities}/>
                        <CommentInfoList comments={recentActivities.comments}/>
                        <StepActivityInfoList stepActivities={recentActivities.step_activity}/>
                      </div>
                    </div>
                  </div>
                }
                
                {upcomingActivities  &&
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <div className="edu-path">
                      <div className="head">Items to update on the activities</div>   
                      <div className="p-5 ht-175 scroll-auto">
                        <UpcomingActivities page_name="journey_list" upcomingActivities={upcomingActivities}/>
                      </div>
                    </div>
                  </div>
                }
              </div>
                
              <small className="font-12 p-5 lh-16">3327</small>
            </div>
          </div>
        </div>
      </div>
      
      <SideMenuBar helpId="3" app={props.app} />

      {isSuggestionOpen  && (
        <GenericModal title="Check out these suggestions" component={Suggestions} 
          toggleModal={toggleSuggestionModal} history={props.history} journey={project} 
          journeyProfiles={project.education_journey_profiles} suggestions={suggestions} 
          dataSource={dataSource} currentUser={currentUser} share={share}/>
      )}
    </Fragment>
  )
}

export default PortalDetails;