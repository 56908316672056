import React from "react";
import {Link} from "react-router-dom";
import CommonService from '../../../../../services/CommonService';

export const JourneyPlanner = (props) => {
  let journeyPlanner = props.journeyPlanner;
  let data = journeyPlanner.data;
  let ds = props.dataSources;

  const cardStyle = {
    backgroundColor: '#009688d1'
  };

  if(data == null)return null;

  const PlannerCard = () => {
    return (
      <div className="card-container bg-white ht-325 shadow-small" >
      <div className="white card-header p-5" style={cardStyle}>
        <div className="font-16 ellipsis">
               {data.name}
        </div>
        <div>
        {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
        </div>
      </div>
      <div className="ht-227 scroll-auto p-5">
      <div className="content">
        <div className="vcard-field">
          <strong>Topic</strong>
          <p>{ds.team_planner_topics.options[data.topic_area].label}</p>
          <i className="fas fa-city color-green1-dark" />
        </div>

        <div className="vcard-field">
          <strong>Competition</strong>
          <p>{ds.premade_team_tile_events.options[data.competition_activity].label}</p>
          <i className="fa fa-map-marker color-blue2-dark" />
        </div>

        <div className="vcard-field no-border">
          <strong>Link</strong>
          <a href={data.reference_url}>{CommonService.extractHostName(data.reference_url)}</a>
          <i className="fas fa-share color-red2-light" />
        </div>

        <div className="vcard-field">
          <strong>Date</strong>
          <p>{data.reminder_formatted_date}</p>
          <i className="fas fa-calendar-alt color-green1-dark" />
        </div>
        
        <div className="vcard-field">
          <strong>Intro</strong>
          <p>{data.intro}</p>
        </div>

      </div>
      </div>
        <div className="card-footer p-5">
      <div className="pull-right">
        <div className="m-t-5">
         <props.menu planner={journeyPlanner}/>

        </div>
      </div>
      </div>
      </div>
      );
  }

  const TeamCard = () => {
    return (
    <div className="card-container bg-white ht-325 shadow-small">
    <div className="white card-header  p-5" style={cardStyle}>
        <div className="font-16 ellipsis">
          {data.name}
        </div>
        <div>
        {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
        </div>
      </div>
      <div className="ht-227 scroll-auto p-5">
      <div className="content">
        <div className="vcard-field">
          <strong>Topic</strong>
          <p>{ds.team_planner_topics.options[data.topic_area].label}</p>
          <i className="fas fa-city color-green1-dark" />
        </div>

        <div className="vcard-field">
          <strong>Competition</strong>
          <p>{ds.premade_team_tile_events.options[data.competition_activity].label}</p>
          <i className="fa fa-map-marker color-blue2-dark" />
        </div>

        <div className="vcard-field no-border">
          <strong>Link</strong>
          <a href={data.reference_url}>{CommonService.extractHostName(data.reference_url)}</a>
          <i className="fas fa-share color-red2-light" />
        </div>

        <div className="vcard-field">
          <strong>Date</strong>
          <p>{data.reminder_formatted_date}</p>
          <i className="fas fa-calendar-alt color-green1-dark" />
        </div>
        
        <div className="vcard-field">
          <strong>Intro</strong>
          <p >{data.intro}</p>
        </div>

      </div>
      </div>
      <div className="card-footer p-5">
      <div className="pull-right">
        <div className="m-t-5">
         <props.menu planner={journeyPlanner}/>

        </div>
      </div>
      </div>
      </div>);
  }

  const Card = () => {
    return (
      <div className="card-container bg-white ht-325 shadow-small" >
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.name}
          </div>
          <div>
            {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
          </div>
        </div>
        <div className="ht-227 scroll-auto p-5">
          <div className="content">

          </div>
        </div>
        <div className="card-footer p-5">
          <div className="pull-right">
          <div className="m-t-5">
            <props.menu planner={journeyPlanner}/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(data.is_teambook==1){
    return (<PlannerCard/>)
  }else if(data.is_teambook==2){
    return (<TeamCard/>)
  }else if(journeyPlanner.planner_type == 'sponsor'){
    return (<Card/>)
  }else{
    return null
  }
  
};

export default JourneyPlanner;