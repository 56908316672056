import React, {useState, Fragment} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Role from '../../Journey/Roles/Role'
import RoleService from "../../Journey/Roles/RoleService";
import InvitedUserService from "../../Journey/InvitedUsers/InvitedUserService";
import ConfirmMenu from '../../Modals/ConfirmMenu'
import AddRole from '../../Journey/Roles/AddRole'
import {Link} from "react-router-dom";
import useModal from '../../../hooks/useModal';
import useStyle from '../../../hooks/useStyle';
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import JourneyTitle from "../../Journey/JourneyTitle/JourneyTitle";
import InvitedUserModal from '../../Journey/InvitedUsers/InvitedUserModal'
import RoleListTable from './RoleListTable'
import {NavIcon} from '../../Common/MenuItem'
import querystringify from "querystringify"
import CommonService from "../../../services/CommonService";
import Pagination from '../../Pagination'
import SideMenuBar from "../../../components/Common/SideMenuBar";
import {Portal} from '../../../constants/DataSourceList';

let requestParams = {}
let newRole = {};
let dataSources = {};
let rolesUser = [];
let newInviteUser = {};
let selectedInviteUser = {};
let selectedRole = {};
let componentList = null

const RoleList = (props) => {
  useStyle('user_lists');
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let project = props.location.state; 
  let queryParam = querystringify.parse(props.location.search);

  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [roles, setRoles] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [currentpage,setCurrentpage] = useState(1); 
  const [showTableView, setShowTableView] = useState(false);

  let dataSources = {
    'notificationsList':{
      'new_journal_entry':'New Tile',
      'new_activity':'New Activity Entry',
      'new_comment':'New Comment'
    },
    'permissions':{
      'add_activity':'Create/Edit Tile',
      'add_step_activity':'Create/Edit step activities'
    }
  }

  React.useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'totalItems':0,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id':params.journey_category_id,
      'data_source_params':{
        'data_source_id':[Portal[project.edu_journey_type]]
      }
    }

    getRoleList();
  }, [props.searchString]);

  let getRoleList = () => { 
    RoleService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       rolesUser = res.data.roles_list;
       dataSources = res.data.data_source_list;
       requestParams.totalItems = res.data.meta.total;
       componentList = CommonService.findById(dataSources, Portal[project.edu_journey_type])
       setRoles(res.data.roles)
      }
    })
  }

  const deleteRole = (e) => {
    let req = {id:selectedRole.id, journey_category_id:params.journey_category_id}
    RoleService.delete(req).then((res) => {
      if(res.status==204){
        setRoles(roles.filter(r => r.record_id !== selectedRole.record_id));
        selectedRole = null
      }
    })    
    toggleDeletePopup(e)
  }

  const deleteInvitedUser = (e) => {
    e.preventDefault();
    InvitedUserService.delete(selectedInviteUser.id).then((res) => {
      if(res.status==204){
        let filteredUsers = selectedRole.role_users.filter(r => r.id !== selectedInviteUser.id)
        let filteredRoles = roles.filter(r => r.record_id !== selectedRole.record_id)
        selectedRole.role_users = filteredUsers;
        setRoles([selectedRole].concat(filteredRoles))
      }
    })  
    toggleInviteUserDeletePopup(e);  
  }

  const setEdit = (e, role) => {
    newRole = role;
    toggleNewRolePopup(e);
  }
  
  const addNewRole = (e) => {
    e.preventDefault();
    if(newRole.id){
      RoleService.update(newRole).then((res) => {
        if(res.status==204){
          restModal(e);
          setRoles([...roles])
        }
      })  
    }else{
      newRole.created_by = currentUser.user.id;
      newRole.journey_category_id = params.journey_category_id;
      newRole.journey_proile_id = params.journey_proile_id;

      RoleService.create(newRole).then((res) => {
        if(res.status==201){
          newRole.id = res.data.id;
          res.data.user = {name:currentUser.user.name, id:currentUser.user.id};
          setRoles([...roles, res.data])
          restModal(e);
        }
      })  
    }
  }

  const restModal = (e) =>{
    newRole = {};
    toggleNewRolePopup(e);
    //getRoleList();
  }

  const addInviteUser = (e, new_invite_user) => {
    e.preventDefault();
    if(new_invite_user.id){
      InvitedUserService.update(new_invite_user).then((res) => {
        if(res.status==204){
          getRoleList()
          restModalUser(e);
        }
      })  
    }else{
      new_invite_user.created_by = currentUser.user.id;
      new_invite_user.journey_category_id = params.journey_category_id;
      new_invite_user.journey_proile_id = params.journey_proile_id;
      new_invite_user.family_id = currentUser.user.domain_info.id;
      
      InvitedUserService.create(new_invite_user).then((res) => {
        if(res.status==201){
          getRoleList()
          restModalUser(e)
        }
      })  
    }
  }

  const restModalUser = (e, role_id) =>{
    e.stopPropagation();
    newInviteUser = {role_ids: (role_id?[role_id]:[]) };
    toggleInviteUserPopup(e);
  }

  const deleteInviteUser = (e, invited_user, role) =>{
    selectedInviteUser = invited_user;
    selectedRole = role;
    toggleInviteUserDeletePopup(e)
  }

  const selectRole = (role) => selectedRole = role;

  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal(deleteRole);
  const {isOpen:isNewRolePopupOpen, toggleModal:toggleNewRolePopup, success:newRoleSubmit} = useModal(addNewRole);
  const {isOpen:isInviteUserRolePopupOpen, toggleModal:toggleInviteUserPopup} = useModal();
  const {isOpen:isInviteUserDeletePopupOpen, toggleModal:toggleInviteUserDeletePopup} = useModal();

  const Menu = ({role}) => (
    <div className="dropup pull-right p-r-15">
      <button className="dropbtn">Menu</button>      
      <div className="dropup-content">
        <a href="#" onClick={e => {setEdit(e, role)}}>Edit</a>
        <a href="#" onClick={e => {toggleDeletePopup(e, role)} }>Delete</a>
      </div>
    </div>
  )
    
  
  /*if(roles.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Role Lists"} project={project} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="add_role" dataTip="Add New Role" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={restModal} icon="fas fa-plus"/>

          <NavIcon id="toggle_role" dataTip="Toggle Table" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={e=>setShowTableView(!showTableView)} icon="fas fa-table"/>

          <NavIcon id="back_home" dataTip="Back to Journey Home" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={e=>{props.history.goBack();e.preventDefault();}}
            icon="fas fa-home"/>  
        </div>
      </div>

      {!showTableView &&
        <div className="page-content row">
          {roles.map((role, i) => (
            <div className="col-xs-12 col-sm-6 col-md-4 role-list-container" key={i}>
              <Role role={role} setEdit={setEdit} setDelete={toggleDeletePopup} menu={Menu} permissions={dataSources.permissions} restModalUser={restModalUser} 
              deleteInviteUser={deleteInviteUser} selectRole={selectRole}
              currentUser={currentUser.user} journey={project}/>
            </div>
          ))}
          <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
        </div>
      }
      
      {showTableView &&
      <div className="page-content row">
        <RoleListTable setEdit={setEdit} setDelete={toggleDeletePopup} menu={Menu} 
        permissions={dataSources.permissions} restModalUser={restModalUser} 
        deleteInviteUser={deleteInviteUser} selectRole={selectRole} 
        requestParams={requestParams} roles={roles} getRoleList={getRoleList}
        currentUser={currentUser.user} journey={project}/>
      </div>}
        
      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteRole}/>
      {isNewRolePopupOpen && <AddRole role={newRole} isOpen={isNewRolePopupOpen} toggleModal={toggleNewRolePopup} addNewRole={addNewRole} dataSources={dataSources} 
      journeyType={project.edu_journey_type} componentList={componentList}/>}
      {isInviteUserRolePopupOpen && <InvitedUserModal inviteUser={newInviteUser} isOpen={isInviteUserRolePopupOpen} toggleModal={toggleInviteUserPopup} addInviteUser={addInviteUser} 
      componentList={componentList} roles={rolesUser} flag={true}/>}
      <ConfirmMenu isOpen={isInviteUserDeletePopupOpen} toggleModal={toggleInviteUserDeletePopup} success={deleteInvitedUser}/>
      <SideMenuBar helpId="15"/>
    </Fragment>
  )

};

export default RoleList;