import React, {useState, useContext, useEffect, Fragment} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Meeting from './Meeting'
import ZoomMeetingService from "./ZoomMeetingService"
import ConfirmMenu from '../Modals/ConfirmMenu'
import {Link} from "react-router-dom"
import useModal from '../../hooks/useModal'
import useStyle from '../../hooks/useStyle'
import PreLoadSpinner from "../PreLoadSpinner"
import JourneyTitle from "../Journey/JourneyTitle/JourneyTitle"
import SideMenuBar from "../Common/SideMenuBar"
import NotificationPopup from '../Common/NotificationPopup'
import HelpMessageList from '../../constants/HelpMessageList'
import GenericModal from "../Modals/GenericModal"
import ProjectMenu from "../Modals/ProjectMenu"
import ZoomCredentialModal from "./ZoomCredentialModal"
import MeetingForm from "./MeetingForm"
import {NavIcon, MenuItem, CardMenuInfo,CardMainMenu} from '../Common/MenuItem'
import CheckAccess from '../Journey/Roles/CheckAccess'

let requestParams = {}
let selectedMeeting = {}
let credential = {}

const ZoomMeetingList = (props) => {
  useStyle('card')  
  useStyle('card_table')
  useStyle('add_role_dialog')
  
  let params = props.match.params
  let journey_category = props.location.state

  const {state:currentUser, dispatch} = useContext(AuthContext)
  const [meetingList, setMeetingList] = useState([])

  const {isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage} = useModal()
  const {isOpen:isDeleteMeetingOpen, toggleModal:toggleDeletePopup} = useModal()
  const {isOpen:isNewMeetingPopupOpen, toggleModal:toggleNewMeetingPopup} = useModal()
  const {isOpen:isCredentialPopupOpen, toggleModal:toggleCredentialPopup} = useModal()
  const {isOpen:isMenuOpen, toggleModal: toggleMenu} = useModal()

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id':params.journey_category_id
      //profile_id
    }

    getMeetingList()
  }, [props.searchString])

  let getMeetingList = () => { 
    ZoomMeetingService.get_meetings(requestParams).then((res)=>{
     if(res.status == 200){
       setMeetingList(res.data.meetings) 
       credential = res.data.credential
       res.data.meetings.length == 0 && toggleHelpMessage()
      }
    })
  }

  const deleteMeeting = (e) => {
    ZoomMeetingService.delete_meeting(selectedMeeting.id).then((res) => {
      if(res.status==204){
        setMeetingList(meetingList.filter(r => r.record_id !== selectedMeeting.record_id))
      }
    })    
    toggleDeletePopup(e)
  }

  const restModal = (e, meeting) =>{
    selectedMeeting = meeting || {}
    toggleNewMeetingPopup(e)
  }

  const showMenu = (e, a) => {
    selectedMeeting = a
    toggleMenu(e)
  }

  const onDeleteMeeting = (e, a) => {
    selectedMeeting = a
    toggleDeletePopup(e)
  }

  const onMeetingUpdate = (meeting, form_event) =>{
    if(form_event == 'create'){
      meeting.user_name =  currentUser.name
      setMeetingList([meeting, ...meetingList])
    }else if(form_event == 'update'){
      setMeetingList(meetingList.map(r => {
        return r.record_id == meeting.record_id
          ? {...r, ...meeting }
          : r
      }))
    } 
    toggleNewMeetingPopup()
  }

  const onCredentialUpdate = (cred) =>{
    credential = cred
    toggleCredentialPopup()
  }

  const menuItems = ({ item }) => {
    const editAccess = CheckAccess.hasEditAccess(currentUser, journey_category, item)
    return (
      <Fragment>

        <CardMenuInfo formId={item.form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.user_name}/>

        {editAccess &&  
          <MenuItem id="edit_icon" onSelect={(e) => restModal(e, item)}
          icon="far fa-edit" action="Edit"/>
        }

        {CheckAccess.hasDeleteAccess(currentUser, journey_category, item) &&
          <MenuItem id="delete_icon" icon="far fa-trash-alt"
            action="Delete" onSelect={(e) => onDeleteMeeting(e, item)} />
        }

        <MenuItem id="report_icon" 
          link={''}
          icon="fas fa-home"
          action="Reports"/>
      </Fragment>
    )
  }

  const Menu = ({ item }) => (
    <CardMainMenu activity= {item} showMenu={showMenu} />
  )

  return (
    <Fragment>
      <div className="content m-t-15 clearfix">
        <div className="col-md-4 pull-left">
          <JourneyTitle title="Zoom Meeting" project={journey_category} />
        </div>
        <div className="col-md-8 pull-right">
          {credential && 
            <NavIcon id="add_meeting" dataTip="Create New Meeting" 
              className="bg-highlight round-btn pull-right m-r-5 m-t-4" 
              onSelect={restModal}
              icon="fas fa-plus"/>
          }
          <NavIcon id="add_role" dataTip="Create New Meeting" 
            className="bg-highlight round-btn pull-right m-r-5 m-t-4" 
            onSelect={toggleCredentialPopup}  
            icon="fas fa-users"/>

          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            onSelect={props.history.goBack}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
        </div>
        <div className="clear"/>
      </div>

      <div className="page-content row">
        <Fragment>          
          {meetingList.map((s, i) => (
            <div className="col-xs-12 col-sm-6 col-md-4 user-list-container" key={i}>
              <Meeting meeting={s} menu={Menu} journeyCategory={journey_category}/>

              {selectedMeeting.id == s.id && 
                <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenu}
                item={selectedMeeting} type="zoom_meeting"
                MenuItems={menuItems} menuHeight="200px"/>
              }
            </div>  
          ))}
        </Fragment>  
      </div>

      <ConfirmMenu isOpen={isDeleteMeetingOpen} toggleModal={toggleDeletePopup} 
        success={deleteMeeting}/>

      {isNewMeetingPopupOpen && <GenericModal component={MeetingForm} 
        title="Schedule Meeting" 
        isOpen={isNewMeetingPopupOpen} 
        toggleModal={onMeetingUpdate}
        journey_category_id={params.journey_category_id}
        id={selectedMeeting.id}/>
      }

      {isCredentialPopupOpen && (
        <GenericModal component={ZoomCredentialModal} title="Zoom Credentials"
          credential={credential} isOpen={isCredentialPopupOpen} 
          toggleModal={toggleCredentialPopup}
          onUpdate={onCredentialUpdate}/>
      )}    
      <SideMenuBar helpId="2"/>
    </Fragment>    
  )
}

export default ZoomMeetingList;