import React, {Fragment, useState, useEffect} from "react"
import {Link, useParams} from "react-router-dom"
import useModal from '../../../../../hooks/useModal'
import CommonService from '../../../../../services/CommonService'
import CardMenuIcon from "../../../../../components/Common/CardMenuIcon"
import ProjectMenu from "../../../../Modals/ProjectMenu"
import CollegeInfoBar from "../SearchList/CollegeInfoBar"
import {MenuItem} from '../../../../Common/MenuItem'
import {Timeline} from '../TimeLineComponent/Timeline'
import CheckAccess from '../../../Roles/CheckAccess'
import Tabs, { TabPane } from 'rc-tabs'
import ConfirmMenu from "../../../../Modals/ConfirmMenu"
import CollegeReviewService from "./../Reviews/ReviewService"
import ReactTooltip from "react-tooltip"
import GenericModal from "../../../../Modals/GenericModal"
import EssayTileForm from "../Favourites/EssayTileForm"
import BarChart from "../../../../Charts/BarChart"
import CollegeService from "../CollegeServices"
import {OptionsObjList} from "../../../../Common/FormInput"
import FavouritesService from "../../../../../services/FavouritesService"
import {Stages} from "./Stages"
import { HamburgerMenuIcon } from "../../../../Common/ImageIcons"

let selectedEssay = null
let selectedPrompt = null

const Favourite = (props) => {
  
  const params = useParams()
  
  const [activeIndex, setActiveIndex] = useState()
  
  const {isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const {isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const {isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const {isOpen:isPromptOpen, toggleModal:togglePromptModal } = useModal()

  let {
    openCollegeCueForm, favourite, addComments, addChecklist, 
    onDelete, index, journeyCategory, currentUser, dataSource,
    updateApplicationStatus
  } = props

  //favourite.updated_at = CommonService.formatUpdateDate(favourite.updated_at)

  //const showTrends = favourite.college_cue?.application_notes && favourite.college_cue?.research

  useEffect(() => {
    ReactTooltip.rebuild()
  })
  
  const toggleMenu =(f,e) =>{
    selectedPrompt = f
    togglePromptModal(e, true)
    //setActiveIndex(i)
    /*setVisible(!visible)*/
  }

  const callback = (e) => {
    console.log(e)
  }

  const deleteStepActivity = (e) => {
    e.preventDefault()
    CollegeReviewService.delete(selectedEssay.id).then((res)=>{
      if(res.status == 204){
        favourite.essay_prompts = favourite.essay_prompts.filter(
          i => i.id !== selectedEssay.id
        )
        setActiveIndex(null)
      }
    })
    toggleDeleteModal(e)
  }

  const confirmDelete = (e, essay) =>{
    e.preventDefault()
    selectedEssay = essay
    toggleDeleteModal(e)
  }

  const MenuItems = () =>{
    return(
      <Fragment>
        {CheckAccess.hasEditAccess(currentUser, journeyCategory, favourite) &&
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {onDelete(favourite, e);toggleMenuModal(e)}}/>
        }
        
        <MenuItem id="comment_icon" 
          icon="far fa-comment"
          action="Comment"
          onSelect={(e) => {addComments(favourite, e);toggleMenuModal(e)}}/>
        
        <MenuItem id="checklist_icon" 
          icon="far fa-check-circle"
          action="Add Checklist"
          onSelect={(e) => {addChecklist(favourite, e);toggleMenuModal(e)}}/>

        {CheckAccess.hasEditAccess(currentUser, journeyCategory, favourite) &&  
          <MenuItem id="add_user_icon" 
            link={{pathname:`/journey/user_role/${favourite.journey_category_id}/${favourite.education_journey_profile_id}/college/${favourite.college_id}?title=${favourite.name}`, state:{journey:journeyCategory, activity:favourite} }}
            icon="fa fa-users"
            action="Add User"/>
        }

        <MenuItem link={`/highschool/college/review/essay_prompts/1000/${params.journey_category_id}/${params.journey_profile_id}/${favourite.college_id}/${favourite.id}`} id="checklist_icon" 
          icon="far fa-check-circle"
          action="Add Essay Prompt"/>
      </Fragment>
    )
  }

  const EmptyFieldCard = ({fieldName}) =>(
    <div className="col-xs-6">
      <div className="font-16 lgrey">{fieldName} not available for display</div>        
    </div>
  )

  const SpanField = ({name, label, styleName}) =>{
    return(
      <Fragment>
        <div className="lgrey pull-left m-t-5">{label}</div>
        <span className={styleName}>{favourite[name] ? favourite[name]:'-'}</span>
      </Fragment>
    )
  }

  const Menu = ({essay}) => {
    return( 
      <span className="dropup pull-right">
        <a>{currentUser.id == essay.created_by && <i className='fas fa-bars'/>}</a>
        <div className="dropup-content w-100p">
          <Link to={`/highschool/college/review/essay_prompts/1000/${params.journey_category_id}/${params.journey_profile_id}/${essay.college_id}/${essay.favourite_id}/${essay.id}`}>
            <i className="far fa-edit"/> Edit
          </Link>
          <a href="#" onClick={e => confirmDelete(e, essay)}>
            <i className="far fa-trash-alt"/> Delete
          </a>
        </div>
      </span>
    )
  }
  
  const StatusMenu = () => {
    return( 
      <span className="dropup m-t-5">
        <div className="p-5 bg-ltint underline font-16">
          {favourite.data?.application_status || 'Set Current Status'}
        </div>
        <div className="dropup-content w-100p">
          {dataSource.college_app_status?.map((o, k)=>
            <span onClick={e => updateApplicationStatus(favourite.id, o.label, index)} key={k}>
              <i className={`fas ${o.label == favourite.data?.application_status?'fa-check-circle':'fa-circle'} lgrey m-r-15`}/>
              {o.label}
            </span>
          )}
        </div>
      </span>
    )
  }

  const InfoCard = () =>{
    return(
      <div className="ht-200 scroll-auto">
        <table className="table card-table">
          <tbody>
            <tr>
              <td className="p-0">
                <SpanField name="ownership" label="Ownership" styleName="pill black" />
              </td>
              <td className="p-0">
                <SpanField name="predominant_degrees_awarded" label="Degree Awards" styleName="pill black" />
              </td>
            </tr>
            <tr>
              <td className="p-0">
                <SpanField name="student_size" label="Student Size" styleName="pill black" />
              </td>
              <td className="p-0">
                <SpanField name="completion_overall" label="Completion" styleName="pill black" />
              </td>
            </tr>
            <tr>
              <td className="p-0">
                <SpanField name="earnings_10_yrs_after_entry" label="Earnings after 10 yrs" styleName="pill black" />
              </td>
              <td className="p-0">
                <SpanField name="earnings_6_yrs_after_entry" label="Earnings after 6 yrs" styleName="pill black" />
              </td>
            </tr>
            <tr>
              <td className="p-0">
              <SpanField name="user_rating" label="User Rating" styleName="pill black" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const EssayCard = favourite.essay_prompts && favourite.essay_prompts.map((f, i) => {
    return(
      <Fragment key={i}>
        <div className="card-activity" onClick={e=>toggleMenu(f,e)}>
          <Menu essay={f}/>
          <div className="w-90p" dangerouslySetInnerHTML={{ __html: f.data.prompt}}></div>
        </div>
      </Fragment>
    )
  })

  const GraphContainer = () => {
    const [chartType, setChartType] = useState()
    const [chartData, setChartData] = useState({})

    let colors = ['#FEAAC4', '#4CABEE', '#EEB3A1', '#00AB8E', '#9A62EA', '#EA5F44', '#0069EE', '#FEAE00']
    let height = 200
    let markers = null
    let reportChartType = {
      no_of_college_view:'line',
      topic_interest:'pie',
      application_type:'pie',
      campus_visits:'pie',
      user_rating:'bar',
      sat_act_gpa:'line'
    }

    useEffect(() => {
      getCollegeReport('no_of_college_view')
    }, [])

    const getCollegeReport = (filter) =>{
      CollegeService.report({college_ids:[favourite.college_id], filter:filter}).then((res)=>{
        if(res.status == 200){
          let d = res.data.reports
          let chart = {
            series: [{
              data: d.count || [],
            }],
            title: {
              text: 'Accomplishment',
              align: 'center',
              floating: true
            },
            x_axis: d.label || [],
            chart_type: reportChartType[filter]
          } 
          setChartData({...chartData, [filter]:chart})
          setChartType(filter)
        }
      })
    }

    if(!chartType)return null;

    return(
      <Fragment>
        <div className="row bg-grey p-7">
          <div className="pull-right bg-white">
            <select className="select input-sm" onChange={e=>getCollegeReport(e.target.value)}>
              <option value="no_of_college_view">
                # of Actions
              </option>
              <option value="user_rating">
                User ratings
              </option>
              <option value="campus_visits">
                Campus Visits
              </option>
              <option value="application_type">
                Application Type
              </option>
              <option value="topic_interest">
                 Topics of Interest
              </option>
              <option value="sat_act_gpa">
                GPA vs Score
              </option>
            </select>
          </div>
        </div>
        <div className="m-t-10">
          <BarChart series={chartData[chartType].series} x_axis={chartData[chartType].x_axis} 
            title={chartData[chartType].title} chart_type={chartData[chartType].chart_type}
            y_axis_label="College" colors={colors} x_axis_label="Admissions" markers={markers}
            className="clearfix" id={chartType} height={height}/>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 m-t-10">
        <div className="card-title card-title-gray flex aligncenter justspacebetween p10 p-b-15">
          <div className="flex aligncenter w-100p">
            <a id="head" className="content">
              <div className="w-100p">
                <h4 className="fw700 card-heading truncate">{favourite.name}</h4>
                <p className="f12">
                  {favourite.state},{favourite.city},{favourite.locale} 
                </p>
              </div>
            </a>
          </div>
          <div>
            <div className="menu-drop-icon flex justifycenter aligncenter m-7">
              <a className="pull-right white link" target="_blank"  href={favourite.school_url}>
                <i className="fas fa-share"/>  
              </a>
            </div>
            <div className="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15"> 
              {props.isPopupView && 
                <i className="fas fa-expand pull-right white expand" onClick={(e) => props.routeFavourite(favourite)}/>
              } 
            </div>
          </div>
        </div>
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-400 m-t-5">
            <Tabs defaultActiveKey={'5'} onChange={callback}>
              <TabPane tab="Stages" key="5">
                <div className="col-xs-12 p-10-20">
                  <Stages stages={dataSource.college_app_status} 
                  updateStage={updateApplicationStatus}
                  favourite={favourite} index={index}
                  onSelect={openCollegeCueForm} />
                </div>
              </TabPane>

              <TabPane tab="Trends" key="1">
                <div className="col-xs-12 br-btm-grey">
                  <GraphContainer/> 
                </div>
              </TabPane>
              
              <TabPane tab="Scores" key="2">
                <div className="row br-btm-grey">
                  {favourite.act_scores ? 
                    <div className="col-xs-6">
                      <CollegeInfoBar leftLabel="ACT" rightLabelString="36" index={index} 
                        name="act" maxScoreString="36" earnedScore={favourite.act_scores} 
                        barClass="btn-success">
                      </CollegeInfoBar>
                      <div className="clearfix"/>
                    </div>
                    : 
                    <EmptyFieldCard fieldName="ACT Score"/>
                  }
                      
                  {favourite.sat_scores ? 
                    <div className="col-xs-6">
                      <CollegeInfoBar leftLabel="SAT" rightLabelString="1600" maxScoreString="1600"
                        earnedScore={favourite.sat_scores} index={index} name="sat"
                        barClass="btn-info">
                      </CollegeInfoBar>
                      <div className="clearfix"/>
                    </div>
                    : 
                    <EmptyFieldCard fieldName="SAT Score"/>
                  }
                </div>

                <div className="col-xs-12 br-btm-grey">     
                  {favourite.tution_cost_in_state ? 
                    <div className="col-xs-6">
                      <CollegeInfoBar leftLabel="In-State" index={index}
                        rightLabelString="$73,132" maxScoreString="73132" name="in_state" 
                        earnedScore={favourite.tution_cost_in_state} barClass="btn-purple" 
                        filterName="currency">
                      </CollegeInfoBar>
                      <div className="clearfix"/>
                    </div>
                    : <EmptyFieldCard fieldName="In-State Score"/>
                  }
                      
                  {favourite.tution_cost_out_of_state ? 
                    <div className="col-xs-6">
                      <CollegeInfoBar leftLabel="Out-of-State" index={index}
                        rightLabelString="$73,132" maxScoreString="73132" name="out_state"
                        earnedScore={favourite.tution_cost_out_of_state} barClass="btn-pink" 
                        filterName="currency">
                      </CollegeInfoBar>
                    </div>
                    : <EmptyFieldCard fieldName="Out-of-State Score"/>
                  }
                </div>
              </TabPane>
              
              <TabPane tab="Info" key="3">
                <div className="col-xs-12 br-btm-grey">
                  <InfoCard />
                </div>
              </TabPane>
              
              <TabPane tab="Essay Prompt" key="4">
                <div className="col-xs-12 br-btm-grey">
                  {EssayCard}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          <ul className="flex aligncenter">
            <li className="m-r-25">
              <span className="m-l-5" id={`comment_${props.index}`}
                onClick={(e) => addComments(favourite,e)}>
                <i className="far fa-comment font-18 text-muted" data-tip="Chat with users you invite"></i>
                <span className="badge up bg-lgrey">{favourite.comments_count || 0}</span>
              </span>
            </li>
            <li className="m-r-25">
              <span className="m-l-25" id={`check_list_${props.index}`}
                onClick={(e) => addChecklist(favourite,e)}>
                <i className="far fa-check-circle font-18 text-muted" data-tip="Manage checklists with users you invite"></i>
                <span className="badge up bg-lgrey">{favourite.check_list_count || 0}</span>
              </span>
            </li>
            <li className="m-r-25">
              {CheckAccess.hasEditAccess(currentUser, journeyCategory, favourite) &&
                <span className="m-l-25" id={`mail_list_${props.index}`}>
                  <i className="far fa-envelope font-18 text-muted" data-tip="View saved emails about this college"></i>
                  <span className="badge up bg-lgrey">{0}</span>
                </span>
              }
            </li>
            <li className="m-r-25">
              {CheckAccess.hasEditAccess(currentUser, journeyCategory, favourite) &&
                <span className="m-l-25">
                  <i className="far fa-lightbulb font-18 text-muted" data-tip="View cueTree suggestions"></i>
                  <span className="badge up bg-lgrey">{favourite.unfilled_field_count}</span>
                </span>
              }
            </li>
            <li className="m-r-25">
              {CheckAccess.hasEditAccess(currentUser, journeyCategory, favourite) &&
                <span className="m-l-25" id={`favourites_count_${props.index}`}>
                  <i className="far fa-heart font-18 text-muted" data-tip="Likes"></i>
                  <span className="badge up bg-lgrey">{favourite.favourites_count}</span>
                </span>
              }
            </li>
          </ul>
          <div style={props.disableClickEvent}>
            <HamburgerMenuIcon onSelect={e=>toggleMenuModal(e)}/>
          </div>
        </div>
      </div>
      {isPromptOpen && (
        <GenericModal component={EssayTileForm} title={selectedPrompt.data.prompt} 
          selectedPrompt={selectedPrompt} isOpen={isPromptOpen} 
          toggleModal={togglePromptModal} widthCss="w-99p"/>
      )}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteStepActivity}/>
      <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} MenuItems={MenuItems}/>
    </Fragment>
  )

}

export default Favourite;

/*
<h5 className="text-center black m-t-10">To view the Trends charts, please first fill and submit the <br/>
  <Link className="underline" to={`/highschool/college/review/research/1001/${journeyCategory.id}/${params.journey_profile_id}/${favourite.college_id}/${favourite.id}/${favourite.college_cue['research'] || ''}?form_wizard_fk_id=${45}&edit=true`}>Research and Rate
            this College</Link> and <Link className="underline" to={`/highschool/college/review/application_notes/1003/${journeyCategory.id}/${params.journey_profile_id}/${favourite.college_id}/${favourite.id}/${favourite.college_cue['application_notes'] || ''}?form_wizard_fk_id=${47}&edit=true`}>
    Organize College Application</Link> items.
</h5>
*/