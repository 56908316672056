import React, { useState, useEffect } from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import querystringify from "querystringify"
import PriceChangeForm from "./PriceChangeForm"
import SubscriptionService from "./SubscriptionService"
import useStyle from '../../hooks/useStyle'
import ProductList from "./ProductList"

function PricingChange(props) {
  useStyle('pricing')
  
  const params = props.match.params
  const queryParam = querystringify.parse(props.location.search)

  const [newProductSelected, setNewProductSelected] = useState(null)
  const [accountInformation] = useState((props.location.state && props.location.state.accountInformation) || {})
  let [selectedProducted, setSelectedProduct] = useState(accountInformation.priceId)
  
  if(newProductSelected && queryParam.change_plan){
    return(
      <PriceChangeForm customerId={accountInformation.subscription.customer}
        subscriptionId={accountInformation.subscription.id}
        currentProductSelected={selectedProducted}
        newProductSelected={newProductSelected}
        setSelectedProduct={setSelectedProduct}/>
    )
  }

  return(
    <div id="prices-form" className="w-full">
      <div className="text-center">
         <h4>Change pricing plan</h4>
      </div>
      <div className="flex justify-between mt-8 mb-8">
         <ProductList selectSubscription={setNewProductSelected} accountInformation={accountInformation}/>
      </div>
    </div>
  )
}

export default PricingChange;