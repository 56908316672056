import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import moment from '../../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../../components/Common/SortDirection'
import Cs from '../../../services/CommonService'
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { EditGrayIcon, LinkDeleteIcon } from "../../Common/ImageIcons.js"

const NewsListTable = (props) => {

  const {currentUser, newsList, roleCreateNews, getNewsList, requestParams, updateStatus, setEdit, setDelete} = props
  const {sort} = useSort(requestParams, getNewsList)
  
  const tableData = newsList.map((news, k) =>
    <TR key={k}>
      <TD data-title="Id">{k}</TD>

      <TD data-title="Title">
        {(news.data.title_news_item || news.data.short_description)}
      </TD>
     
      <TD data-title="Url">
       {Cs.extractHostName(news.data.news_url || news.data.public_url)}
      </TD>
      
      <TD data-title="Publish Date">
        {news.data.date_published_article}
      </TD>

      <TD data-title="Active" onClick={e=>updateStatus(e, news)}>
        <i className={news.is_active ? "far fa-check-circle": "far fa-circle"}></i>
      </TD>

      <TD data-title="User Suggested">
        <i className={news.is_user_suggested ? "far fa-check-circle": "far fa-circle"}></i>
      </TD>

      <TD data-title="Status">
        {news.status}
      </TD>

      <TD>
        <div className="flex aligncenter">
          {(currentUser == news.updated_by || roleCreateNews) && <> 
            <EditGrayIcon onSelect={e=>setEdit(e, news.id)} className="action-btn bg-highlight">Edit</EditGrayIcon>
            <LinkDeleteIcon id="delete" data-tip="Delete" onSelect={e=>setDelete(e, news)}>
            </LinkDeleteIcon></>
          }
        </div>
      </TD>
    </TR>
  )

  return (
    <div className="col-xs-12 m-t-10">
      <Table className="shadow-small">
        <THead>
          <TR>
            <TH onClick={e=>sort('updated_at')}>Id 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/> 
            </TH>
            <TH onClick={e=>sort('title_news_item')}>Title
              <SortDirection sort_column={requestParams.sort_column} column="title_news_item" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={e=>sort('news_url')}>Url
              <SortDirection sort_column={requestParams.sort_column} column="news_url" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={e=>sort('date_published_article_formatted_date')}>Publish Date
              <SortDirection sort_column={requestParams.sort_column} column="date_published_article_formatted_date" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={e=>sort('is_active')}>Active
              <SortDirection sort_column={requestParams.sort_column} column="is_active" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={e=>sort('is_user_suggested')}>User Suggested
              <SortDirection sort_column={requestParams.sort_column} column="is_user_suggested" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={e=>sort('status')}>Status
              <SortDirection sort_column={requestParams.sort_column} column="status" reverse={requestParams.reverse}/>
            </TH>
            <TH>Options</TH>
          </TR>
        </THead>
        <TBody>
          {tableData}
        </TBody>
      </Table>
    </div>
  )

};

export default NewsListTable;