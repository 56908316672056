import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js/pure'
import {CardElement, Elements, useStripe, useElements,} from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import SubscriptionService from "./SubscriptionService"
import useStyle from '../../hooks/useStyle'
import Cs from '../../services/CommonService'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
loadStripe.setLoadParameters({advancedFraudSignals: false})
const stripePromise = loadStripe('pk_test_3Qo4PpwmCENXnpSZ7ddISnTD')

const CheckoutForm = ({ productSelected, customer }) => {
  useStyle('pricing')
  
  const stripe = useStripe()
  const elements = useElements()

  const [subscribing, setSubscribing] = useState(false)
  const [accountInformation, setAccountInformation] = useState(null)
  const [errorToDisplay, setErrorToDisplay] = useState('')
  const [isCountryUs, setIsCountryUs] = useState(false)
  
  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && (subscription.status === 'active' || subscription.status === 'trialing')) {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              }
            }
          }
        })
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId }
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active' || subscription.status === 'trialing') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      )
      throw new Error('Your card was declined.')
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    const priceId = productSelected.stripe_price_id
    let req = {
          customerId: customer.customer_id,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
    }

      SubscriptionService.retryInvoiceWithNewPaymentMethod(req)
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.log(error)
          // An error has happened. Display the failure to the user here.
          setSubscribing(false)
          setErrorToDisplay(error && error.error && error.error.decline_code)
        })
  }

  function onSubscriptionComplete(result) {
    console.log(result)
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription }
      result.subscription = subscription
      localStorage.clear()
    }

    setAccountInformation(result)
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function createSubscription({ paymentMethodId }) {
    const priceId = productSelected.stripe_price_id
    let req = {
          customerId: customer.customer_id,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscriptionId:productSelected.id
        }
        SubscriptionService.createStripeSubscription(req)
        // If the card is declined, display an error to the user.
        .then((result) => {
          console.log(result)
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result
          }
          return result
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result.subscription,
            paymentMethodId: paymentMethodId,
            priceId: productSelected.stripe_price_id,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false)
          setErrorToDisplay(error.message || error.error.decline_code)
        })
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault()

    setSubscribing(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    )

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      console.log('[createPaymentMethod error]', error)
      setSubscribing(false)
      setErrorToDisplay(error && error.message)
      return
    }
    console.log('[PaymentMethod]', paymentMethod)
    const paymentMethodId = paymentMethod.id
    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem('latestInvoiceId')
      retryInvoiceWithNewPaymentMethod({
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      })
      return
    }

    // Create the subscription
    createSubscription({
      paymentMethodId: paymentMethodId,
    })
    
  }

  const cardStyle = {
    style: {
      base: {
        fontSize: '16px',
        color: '#32325d',
        fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
        '::placeholder': {color: '#a0aec0',},
      },
      invalid: {color: '#9e2146',},
    },
  }

  if (accountInformation) {
    return (
      <Redirect to={{pathname: '/account', state: { accountInformation: accountInformation },}}/>
    )
  } else {
    let bgColor = {'backgroundColor': productSelected.body_bg_color}
    let fee = productSelected.subscription_rates[0].fee

    return (
      <div id="payment-form">
        <div className="col-xs-12 p-7">
          <div className="round-medium shadow-small bg-white">
            
            <div className="col-xs-12 bg-green-3 white lh1p6" style={bgColor}>
              <h4 className="m-t-15">
                Your subscription selection: 
              </h4>
            
              <p className="font-16 m-t-5">
                <b>Plan:</b> {productSelected.name}.
              </p>
              <p className="font-16">
                <b>Amount:</b> $ {fee}.
              </p>
              <p className="font-16">
                <b>Billing Period:</b> 
                {productSelected.payment_type == 'month'?'Monthly':'Yearly'}
              </p>
              <p className="font-16">
                <b>Start Date:</b> {Cs.formatUpdateDate(new Date())}. 
              </p>
            </div>

            <div className="col-xs-12 m-t-15">

            <p className="font-16">Thank you for your subscription. Please confirm the
              subscription details shown above and then provide
              your payment details below. Your payment will be processed via Stripe and is
              subject to Stripe’s Checkout Terms of Service and Privacy Policy.  </p>
            
            <form id="payment-form" onSubmit={handleSubmit}>
                <div className="m-t-15">
                  <label className="regTitles2">
                    FULL NAME(as appears on your credit card)
                  </label>
                  <input className="form-control" id="name" type="text"
                    placeholder="First and last name" required/>
                </div>
                <div className="m-t-10">
                  <label className="regTitles2">
                    CREDIT CARD NUMBER
                  </label>
                  <div className="form-control" id="card-element">
                    <CardElement options={cardStyle}/>
                  </div>
                </div>
                <div className="m-t-15">
                  <span className="checkbox-container" onClick={e=>setIsCountryUs(!isCountryUs)}>
                    <label className="regTitles2">Click this box if based in the United States</label>
                    <input type="checkbox" checked={isCountryUs} readOnly/>
                    <span className="checkbox-checkmark"></span>
                  </span>
                </div>
                {isCountryUs &&
                  <div className="m-t-10">
                    <label className="regTitles2">
                      ZIP CODE (for credit card billing address)
                    </label>
                    <input className="form-control" id="zipcode" type="number" 
                      placeholder="Zipcode" minlength="5" maxlength="6" required/>
                  </div>
                }
                <div className="red font-15 bold-600" role="alert">
                  {errorToDisplay ? errorToDisplay : null}
                </div>
              <button id="submit-premium" className="price-btn" type="submit" style={bgColor}>
                  <div>
                    <div>{subscribing ? 'Subscribing...' : 'Subscribe'}</div>
                  </div>
              </button>
            </form>

            <div className="col-xs-12">
              <div className="col-xs-4 text-center">
                <a className="Link Link--primary" href="https://stripe.com" target="_blank" rel="noopener">
                  <span className="Text-color--gray400 Text-fontWeight--400">Powered by &nbsp; 
                    <svg className="InlineSVG Icon Footer-PoweredBy-Icon Icon--md" focusable="false" width="33" height="15">
                      <g fillRule="evenodd">
                        <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z">
                        </path>
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
              <div className="col-xs-4 text-center">
                <a className="Link Link--primary" href="https://stripe.com/checkout/terms" target="_blank" rel="noopener">
                  <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Terms</span>
                </a>
              </div>
              <div className="col-xs-4 text-center">
                <a className="Link Link--primary" href="https://stripe.com/privacy" target="_blank" rel="noopener">
                  <span className="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Privacy</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    )
  }
}

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} {...props.location.state}/>
  </Elements>
)

export default PaymentForm;