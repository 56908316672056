import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import JourneyNotificationService from "./JourneyNotificationService"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'

let formMode = null 
let notification = {} 
let data = {} 
let formFn = {}  

function NotificationForm(props) {
  const { state:{ user:currentUser } } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  const formId = FormTemplateId.JourneyNotification

  const get = () => {
    JourneyNotificationService.get(params.id).then((res) => {
      if(res.status==200){
        notification = res.data.admin_notification || {}
        data = notification.data || {}
        setFormRendered(true)
      }
    }).catch((res)=>{
       notification = null;
    })
  }
  
  useEffect(() => { 
    notification = {} 
    data = {} 
    formFn = {
      'form_type':'admin_notification', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    } 
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else{
      formMode = 'create-form-submissions';
      setFormRendered(true)
    }
  }, [])

  const create = (notification) => {
    notification.journey_category_id = params.journey_category_id
    notification.item_type = params.item_type
    notification.item_id = params.item_id 
    notification.form_id = formId
    JourneyNotificationService.create(notification).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }else if(res.status == 422){
        alert(res.data.error)
      }
    }).catch((res)=>{
       
    })
  }

  const update = (notification) => {
    JourneyNotificationService.update(notification).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect()
      }
    }).catch((res)=>{
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/career_training/project/portal/${params.journey_category_id}`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={formId} 
          form={notification} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default NotificationForm;