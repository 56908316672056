import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import CommonService from '../../services/CommonService'

const Invite = (props) => {

  let {invitedUser} = props

  return (
    <Fragment>
      <div className="user-list-container bg-white">
        <div className="user-list-left">
          <img src="images/avatars/2s.png" alt="img" className="shadow-small bg-blue2-dark" />
          <h4>{invitedUser.email}</h4>
          <div className="text-cap role">
            <a className="black">{ invitedUser.message && invitedUser.message.substring(0,110)}</a>
            <span>
              <strong>Email</strong>
              <a><span className={invitedUser.is_email_sent?'far fa-check-circle':'far fa-circle'}></span></a>
            </span>
            <span>
              <strong>Visit</strong>
              <a><span className={invitedUser.is_invite_link_visited?'far fa-check-circle':'far fa-circle'}></span></a>
            </span>
            <span>
              <strong>Join</strong>
              <a><span className={invitedUser.joined_from_invite?'far fa-check-circle':'far fa-circle'}></span></a>
            </span>
          </div>
        </div>  
      </div>
    </Fragment>
  )

}

export default Invite;