import React, {useState, Fragment, useContext, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import Pagination from '../../../../Pagination'
import useStyle from '../../../../../hooks/useStyle';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CollegeService from "../../Colleges/CollegeServices";
import College from "./College"
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import FavouritesService from '../../../../../services/FavouritesService';
import JourneyTitle from '../../../JourneyTitle/JourneyTitle'
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../Common/NotificationPopup"
import GenericModal from "../../../../Modals/GenericModal";
import { SwiperContainer, SwiperSlide } from "../../../../../components/Common/Swiper";
import CommonService from "../../../../../services/CommonService";
import querystringify from "querystringify";
import {NavIcon} from '../../../../Common/MenuItem'
import ReactTooltip from "react-tooltip";
import CollegeProfileForm from "../CollegeProfileForm/CollegeProfileForm"
import {HighSchoolLink} from "../../../../Common/RouteLinks"

let requestParams = {};
let data_source = {};
let selectedCollege = null;
let collegeSearchFilter = {};
let favCollegeIds = [];
let cardViewCount = 1;

const CollegeList = (props) => {
  useStyle('card');
  useStyle('card_table');

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collegeSearch, setCollegeSearch] = useState();
  const [searchFilter, setSearchFilter] = useState();

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal();
  const { isOpen:isSuccessMessageOpen, toggleModal:toggleSuccessMessage } = useModal();
  const { isOpen:isSearchFilterOpen, toggleModal:toggleSearchFilterModal } = useModal();

  let params = props.match.params;
  let journey = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
  const indexEle = useRef(null);

  let collegeSearchProps = {
    match:{
      params:{
        journey_category_id: params.journey_category_id,
        journey_profile_id: params.journey_profile_id,
        go_back:true,
        id:collegeSearch?.id
      }
    },
    location:{}
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'user_rating', 
      'sort_direction': 'asc',
      'include_favourites_count': true,
      'journey_category_id':params.journey_category_id,
      'favourites_params':{
        'education_journey_profile_id':params.journey_profile_id,
        'field':'favorable_id',
        'sort_column':'updated_at',
        'sort_direction':'asc'
      }
    }
    
    try{
      collegeSearchFilter = JSON.parse(sessionStorage.activeCollegeProfile)
      CollegeService.buildCollegeSearchParam(collegeSearchFilter.data, requestParams)
    }catch(e){
      console.log(e)
    }
    searchColleges()
  }, [props.searchString, currentpage, searchFilter])

  let searchColleges = () => { 
    setLoading(true);
    CollegeService.search(requestParams).then((res)=>{
     if(res.status == 200){
        favCollegeIds = res.data.favourites;
        requestParams.totalItems = res.data.meta.total 
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount);
        if(requestParams.search || searchFilter){
          setColleges(res.data.colleges); 
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setColleges(res.data.colleges); 
        }else{
          setColleges([...colleges, ...res.data.colleges]); 
        }
        setCollegeSearch(res.data.college_admission_profile) 
        setLoading(false);
      }
    })
  }

  const addComments = (college, e) =>{
    selectedCollege = college;
    toggleCommentModal(e);
  }

  const addChecklist = (college, e) =>{
    selectedCollege = college;
    selectedCollege.journey_category_id = params.journey_category_id;
    selectedCollege.journey_profile_id = params.journey_profile_id;
    toggleChecklistModal(e);
  }

  const addToFavourite = (college, e) =>{
    e.preventDefault()
    setLoading(true)
    let req = {
        'favorable_id':college.id,
        'favorable_type':'colleges',
        'education_journey_profile_id':params.journey_profile_id, 
        'college_admission_profile_id':collegeSearchFilter.id,
        'journey_category_id':params.journey_category_id
    }
    FavouritesService.create(req).then(function(res){
      if(res.status==201){
        setLoading(false)
        favCollegeIds.push(res.data.favourite.id)
        toggleSuccessMessage(e)
        props.addFavCollegeNode && props.addFavCollegeNode(college)
      }
    })
  }

  const collegeList =  colleges && colleges.map((c, i) => (
    <SwiperSlide key={i}>
      <div className="col-xs-12">
      <College college={c} addComments={addComments} addChecklist={addChecklist} addToFavourite={addToFavourite} favCollegeIds={favCollegeIds} index={i}/>
      </div>
    </SwiperSlide>
  ))

  return (
    <Fragment>
      {loading && <div className="spinner" />}
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Nearby Colleges Search"} project={journey} />
        </div>
        
        <div className="col-md-8 pull-right">
          <Link data-tip="Filter Favorite Colleges" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>toggleSearchFilterModal(e)}>
            <i className="fas fa-sliders-h"></i>
          </Link>

          <Link to={HighSchoolLink.hs_portal(params)} data-tip="Back to Journey Home"  className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-home"></i>
          </Link>
          <NavIcon id="back_icon" dataTip="Back to Favourite College" 
            onSelect={e=>props.history.goBack()}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-arrow-left"/>
        </div>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
          requestParams={requestParams} indexEle={indexEle} cardViewCount={cardViewCount}>
          {collegeList}
        </SwiperContainer>
        <div className="col-md-12 col-xs-12 text-center">
          <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
        </div>
      </div>

      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedCollege} type="college"/>}
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedCollege} type="college"/>}
      {isSuccessMessageOpen && (
        <GenericModal
          component={NotificationPopup}
          header="Success"
          message="Added Successfully"
          onSuccess={toggleSuccessMessage}
          isOpen={isSuccessMessageOpen}
          toggleModal={toggleSuccessMessage}/>
      )}

      <SideMenuBar helpId="4"/>

      {isSearchFilterOpen &&  <GenericModal component={CollegeProfileForm} title="Filter"
        setSearchFilter={setSearchFilter} {...collegeSearchProps} 
        toggleModal={toggleSearchFilterModal}/>
      }
    </Fragment>
  )
};

export default CollegeList;