import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../../../../hooks/useStyle';
import useEscape from '../../../../../hooks/useEscape';

const CoursePreparationMenu = ({ params, isOpen, toggleModal }) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');
  useEscape(toggleModal);

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);

  let activity_types = {
    'activity_types':[
      {'label':'New Administrator Tile', form_template_id:257, type:'team'},
      {'label':'New Teambook Tile', form_template_id:259, type:'team_book'},
      {'label':'New Contribution Tile', form_template_id:304, 'type':'sponsor'}
  ]}
  
  const Filters = activity_types.activity_types.map(k => 
    <Fragment key={k.type}>
      <div className="filter-label">
      <Link to={`/team/activity/form/${params.journey_category_id}/${params.education_journey_profile_id}/${k.form_template_id}/${k.type}`} >
        <span>{k.label}</span> 
      </Link>
    </div>
  </Fragment>  )

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select an item</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          <div className="p-5 scroll-x" style={{height:screen.height-120}}>
            {Filters}
          </div>
            
        </div>
      </div>
    </React.Fragment>, document.body)
  )

}

export default CoursePreparationMenu;