import React, { useEffect, useState, useContext} from 'react'
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import ProfileService from "../../../services/ProfileService"
import FormWizard from '../../../components/FormBuilder/FormWizard'
import env from '../../../env'
import CommonService from "../../../services/CommonService"
import {JourneyTypeMenu} from "../List/JourneyTypeMenu"
import NotificationPopup from '../../Common/NotificationPopup'
import AuthenticationService from "../../../services/AuthService"
import FormContainer from '../../FormBuilder/FormContainer'
import {FormApi} from '../../Common/FormApi'
import CheckAccess from '../Roles/CheckAccess'

let formMode = null
let formId = null
let profile = {} 
let data = {}
let formFn = {}  
let domainInfoId = {}

function ProfileForm(props) {
  const { state: { user: currentUser, isAuthenticated: isAuthenticated }, dispatch} = useContext(AuthContext)

  const [isFormRendered, setFormRendered] = useState(false)
  const [showJourneyMenuType, setShowJourneyMenuType] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  useEffect(() => { 
    if (!isAuthenticated) {
      props.history.push('/signin')
      return
    }

    profile = currentUser.domain_info 
    data = profile.data || {}
    formFn = {'form_type':'family'} 
    formMode = 'edit-form-submissions'
    setFormRendered(true)
    formFn.successMessage = `Next, you can launch your cueTree education journey. Click OK to begin.`
  }, [currentUser, params.id])

  const update = (profile) => {
    formFn.setLoading(true)
    domainInfoId = currentUser.domain_info.id
    profile.created_by = currentUser.id
    profile.updated_by = currentUser.id
    profile.id = currentUser.domain_info.id

    ProfileService.update(profile, domainInfoId).then((res) => {
      if(res.status === 200){
        currentUser.domain_info = res.data.family
        currentUser.user_type = CheckAccess.getUserType(currentUser)
        AuthenticationService.setPartnership(currentUser)

        dispatch({
          type: "UPDATE",
          payload: { user: currentUser }
        })

        formFn.setLoading(false)

        if(queryParam.show_form_wizard){
          formFn.setIsFormSubmitted()
          //setShowJourneyMenuType(true);
        }else{
          onSubmitPageRedirect()
        }
      }
    })
  }

  const onSubmitPageRedirect = () => {
    if(queryParam.go_back){
      props.history.goBack()
    }else{
      CheckAccess.routeUser(currentUser, props.history)
    }
  }

  if(queryParam.show_form_wizard){
    return(
      <>
        <FormWizard form_wizard_id={FormTemplateId.UserProfileWizard} 
          form={profile} data={data} formFn={formFn} onUpdate={update} 
          onCancel={onSubmitPageRedirect}/>
        <FormApi formFn={formFn} data={data}/>
      </>
    )
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.ProfileRegistration} 
          form={profile} data={data} formFn={formFn} onUpdate={update} 
          post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
        <FormApi formFn={formFn} data={data}/>
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default ProfileForm;