import React, { Fragment, useState, useEffect, useContext } from "react"
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from "../../hooks/useStyle"
import env from "../../env"
import useModal from "../../hooks/useModal"
import AuthenticationService from "../../services/AuthService"
import DigitalContentService from "../DigitalContent/DigitalContentService"
import CommonService from "../../services/CommonService"
import ImageEditor from "../ImageEditor/ImageEditor"
import GenericModal from "../Modals/GenericModal"
import Tabs, { TabPane } from "rc-tabs"
import SecretQuestions from "./SecretQuestions"
import NotificationPopup from "../Common/NotificationPopup"
import HelpMessageList from "../../constants/HelpMessageList"
import UserSubscription from "../SubscriptionPayments/UserSubscription"
import PricingTable from "../SubscriptionPayments/PricingTable"
import { RenderData } from "../Journey/UserRoles/NotificationPill"
import ProfileImageUpload from "./ProfileImageUpload"
import { Link } from "react-router-dom"

let profileImage = "images/avatar-2.jpg"
let error = {}
let digiRequestParams = {}
let msgRequestParams = {}

const DemoUserProfile = (props) => {
  useStyle("/css/sidebar-theme.css", true);
  useStyle("/css/user-preference-page.css", true);
  useStyle("/css/responsive.css", true);

  let queryParam = querystringify.parse(props.location.search);

  const {
    state: { screen, user: currentUser },
    dispatch,
  } = useContext(AuthContext);
  const { state: authState } = useContext(AuthContext);

  const [user, setUser] = useState({});
  const [msgs, setMsgs] = useState();
  const [content, setContent] = useState();
  const [settings, setSetting] = useState(currentUser.setting);

  const { isOpen: isHelpMessageOpen, toggleModal: toggleHelpMessage } =
    useModal();

  if (currentUser.profile_image_url) {
    profileImage = env.file_url + currentUser.profile_image_url;
  }

  useEffect(() => {
    digiRequestParams = {
      content_type: "what_new",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null,
    };

    msgRequestParams = {
      content_type: "in_app_notif",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null,
    };

    getWhatsNew();
    getMessages();
  }, []);

  let getWhatsNew = () => {
    DigitalContentService.getAll(digiRequestParams).then((res) => {
      if (res.status == 200) {
        digiRequestParams.totalItems = res.data.meta.total;
        setContent(res.data.digital_contents);
      }
    });
  };

  let getMessages = () => {
    DigitalContentService.getAll(msgRequestParams).then((res) => {
      if (res.status == 200) {
        digiRequestParams.totalItems = res.data.meta.total;
        setMsgs(res.data.digital_contents);
      }
    });
  };

  const handleChange = (e) => {
    user[e.target.name] = e.target.value;
    setUser({ ...user });
  };

  const handlePasswordChange = (e) => {
    user[e.target.name] = e.target.value;
    setUser({ ...user });
    checkPasswordValid();
  };

  const updatePassword = () => {
    let data = {
      user: user,
    };
    AuthenticationService.updatePassword(data).then((res) => {
      if (res.status == 200) {
        error.pwsuccess = res.data.message;
      } else if (res.status == 400) {
        error.pwerror = res.data.message;
      } else {
        error.pwerror = "Password Reset Failed";
      }
      setUser({ ...user });
    });
  };

  const checkPasswordValid = () => {
    error.is_password_valid = false;
    if (user.password && user.new_password && user.password_confirmation) {
      if (user.new_password === user.password_confirmation) {
        error.is_password_valid = true;
      }
    }
  };

  const updateUserName = () => {
    let req = {
      user: { name: user.name },
    };
    error = {};
    AuthenticationService.updateUserName(req).then((res) => {
      if (res.status == 200) {
        error.username = "Updated Successfully";
      } else {
        error.username = "Updated Failed";
      }
      setUser({ ...user });
    });
  };

  const callback = (e) => {
    console.log(e);
  };

  const updateNotificationSetting = (field) => {
    settings[field] = !settings[field];
    updateSetting(settings);
  };

  const updateMobile = (e) => {
    settings[e.target.name] = e.target.value;
    if (
      settings.country_code &&
      settings.mobile_no &&
      settings.mobile_no.length == 10
    ) {
      updateSetting(settings);
    }
  };

  const updateSetting = (settings) => {
    CommonService.updateSetting(settings).then((res) => {
      if (res.status == 200) {
        currentUser.settings = res.data.setting;
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser },
        });
      }
    });
  };

  const profileForm = [
    { label: "Your Name", client_id: "name" },
    { label: "Your Telephone Number", client_id: "telephone" },
    { label: "Age", client_id: "fj_age" },
    {
      label: "Enter Email ID for Parent or Guardian",
      client_id: "parent_guardian_email",
    },
    { label: "Type of Use", client_id: "type_of_use" },
    { label: "Your Role in Personal Use", client_id: "fj_your_role" },
    {
      label: "Type of Organizational Use",
      client_id: "type_organizational_use",
    },
    { label: "Name of Organization", client_id: "org_name" },
    { label: "URL", client_id: "org_url" },
    { label: "Country", client_id: "country" },
    { label: "Brief Professional Bio", client_id: "bio" },
  ];

  const infoCard =
    content &&
    content.map((c, i) => (
      <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
        <div className="profile-card profile-card-style ht-250">
          <div className="content">
            <div
              className="d-flex font-16 bg-black white p-5"
              style={{ alignItems: "center", justifyContent: "left" }}
            >
              <div>{c.data.title || c.data.heading}</div>
            </div>
            <div className="d-flex">
              <div className="p-12 w-50p">
                <p dangerouslySetInnerHTML={{ __html: c.data.details }}></p>
              </div>
              <div className="p-12 w-50p">
                <p
                  className="pull-right font-10"
                  style={{ border: "1px solid", padding: "2px" }}
                >
                  {CommonService.formatUpdateDate(c.created_at)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  const msgCard =
    msgs &&
    msgs.map((m, i) => (
      <div className="col-xs-12 col-sm-6 col-md-4">
        <div className="profile-card profile-card-style ht-250">
          <div className="content">
            <div
              className="d-flex font-16 white p-5"
              style={{
                alignItems: "center",
                justifyContent: "left",
                backgroundColor: m.data.color,
              }}
            >
              <div>{m.data.title || m.data.heading}</div>
            </div>
            <div className="d-flex">
              <div className="p-12 w-50p">
                <p dangerouslySetInnerHTML={{ __html: m.data.details }}></p>
              </div>
              <div className="p-12 w-50p">
                <p
                  className="pull-right font-10"
                  style={{ border: "1px solid", padding: "2px" }}
                >
                  {CommonService.formatUpdateDate(m.created_at)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  let ds = currentUser.domain_info.data_source;

  const SpanField = ({ name, label, styleName }) => (
    <span className={styleName}>{ds[name] ? ds[name][label] : "-"}</span>
  );

  return (
    <Fragment>
      <section id="ct" className="app-main">
        <div className="app-main__outer" id="appMain">
          <div className="app_content">
            <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20">
              <div className="flex coldir fulwid p20">
                <div className="flex alignstart justspacebetween">
                  <div>
                    <div className="app-title m-r-20">User preference page</div>
                    <p className="m-t-10">Org Master</p>
                  </div>
                  <div className="flex aligncenter">
                    <Link
                      id="nav-welcome"
                      to="#"
                      className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter m-l-10"
                    >
                      <img
                        src="./images/menu-bar.png"
                        className="w-16px"
                        alt=""
                      />
                    </Link>
                    <Link
                      id="nav-welcome"
                      to="#"
                      className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter m-l-10"
                    >
                      <img src="./images/home-icon-black.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="app-content-section m-t-30 m-t-0-xs">
              <div className="row m-t-30 m-t-0-xs">
                <div className="col-md-3">
                  <div className="bg-white border-rounded-10 p20 card-min-ht">
                    <ul className="flex coldir bordered-tab">
                      <li>
                        <a
                          href="true"
                          data-tab="a"
                          className="b-nav-tab active"
                        >
                          About me
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="b" className="b-nav-tab">
                          Password Change
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="c" className="b-nav-tab">
                          Secret Questions
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="d" className="b-nav-tab">
                          Account Preferences
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="e" className="b-nav-tab">
                          Plans
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="f" className="b-nav-tab">
                          Payments
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="g" className="b-nav-tab">
                          Deactivate
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="h" className="b-nav-tab">
                          Whats New
                        </a>
                      </li>
                      <li>
                        <a href="true" data-tab="i" className="b-nav-tab">
                          Messages
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-9">
                  <div id="a" className="b-tab active">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <div className="flex aligncenter tab-card-style justspacebetween hide-sm">
                        <a
                          href="#"
                          className="show-xs hide-sm slider-arrow arrow-left m-r-10"
                        >
                          <img src="./images/slider-arrow.png" alt="" />
                        </a>
                        <ul className="flex aligncenter">
                          <li>
                            <a
                              href="#"
                              data-tab-table="a"
                              className="b-nav-tab-table active"
                            >
                              About me
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-tab-table="b"
                              className="b-nav-tab"
                            >
                              Password Change
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-tab-table="c"
                              className="b-nav-tab-table"
                            >
                              Notification
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-tab-table="d"
                              className="b-nav-tab-table"
                            >
                              Whats New
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-tab-table="e"
                              className="b-nav-tab-table"
                            >
                              Message
                            </a>
                          </li>
                        </ul>
                        <a
                          href="#"
                          className="show-xs hide-sm slider-arrow m-r-10"
                        >
                          <img src="./images/slider-arrow.png" alt="" />
                        </a>
                        <button
                          type="button"
                          className="btn btn-icon-add btn-responsive text-white hide-xs"
                        >
                          Add
                        </button>
                      </div>
                      <h2 className="m-b-20 m-t-20-xs">About Me</h2>
                      <div className="flex aligncenter justspacebetween">
                        <div className="flex coldir m-b-20">
                          <ProfileImageUpload />
                            <div className="form-group">
                              <h3 className="m-b-5">
                                {currentUser.domain_info.data.name}
                              </h3>
                              <h4>Age:</h4>
                              <SpanField
                                name="fj_age"
                                label="label"
                                styleName="pill text-left m-t-1"
                              />
                              <h4>Role: </h4>
                              <SpanField
                                name="fj_your_role"
                                label="label"
                                styleName="pill text-left m-t-1"
                              />
                              
                            </div>
                          </div>
                          <a href="#" className="btn btn-stroke-gray">
                            {" "}
                            Edit{" "}
                          </a>
                      </div>
                      <h3 className="m-t-30 m-b-30">Basic Information</h3>
                      <div className="flex coldir m-b-20">
                        <div className="form-group">
                          <img
                            alt="profile_pic"
                            style={{ width: "64px" }}
                            src={profileImage}
                          /><br/>
                          <input
                            placeholder="Email"
                            defaultValue={currentUser.email}
                            className="form-input form-input-gray m-t-5"
                            readOnly
                          />
                          <label className="m-t-10">Username</label>
                          <input
                            placeholder="User Name"
                            name="name"
                            onChange={(e) => handleChange(e)}
                            defaultValue={currentUser.name}
                            className="form-input form-input-gray m-t-5" 
                          />
                          {error.username && (
                            <div className="form-group error m-t-5">
                              <label>{error.username}</label>
                            </div>
                          )}

                          {user.name && (
                            <button
                              type="button"
                              onClick={(e) => updateUserName(e)}
                              className="btn btn-inverse m-t-5"
                            >
                              Update
                            </button>
                          )}
                        </div>
                        
                        <div className="profile-card profile-card-style ht-280 m-t-5">
                          <div className="flex coldir">
                            {profileForm.map(
                              (n, i) =>
                                currentUser.domain_info.data[n.client_id] && (
                                  <div className="d-flex" key={i}>
                                    <h5 className="text-cap">{n.label}:</h5>
                                    <span className="m-t-5">
                                      <RenderData
                                        data={currentUser.domain_info.data}
                                        ds={currentUser.domain_info.data_source}
                                        dataKey={n.client_id}
                                      />
                                    </span>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="b" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Password Change</h2>
                      <div className="flex coldir m-b-20">
                        <label className="form-label m-b-10">
                          Old Password
                        </label>
                        <input
                          placeholder="Old Password"
                          name="password"
                          onChange={(e) => handlePasswordChange(e)}
                          className="form-input form-input-gray"
                        />
                      </div>

                      <div className="flex coldir m-b-20">
                        <label className="form-label m-b-10">
                          New Password
                        </label>
                        <input
                          placeholder="New Password"
                          name="new_password"
                          onChange={(e) => handlePasswordChange(e)}
                          className="form-input form-input-gray"
                        />
                      </div>

                      <div className="flex coldir m-b-20">
                        <label className="form-label m-b-10">
                          Re-New Password
                        </label>
                        <input
                          placeholder="Retype New Password"
                          name="password_confirmation"
                          onChange={(e) => handlePasswordChange(e)}
                          className="form-input form-input-gray"
                        />
                      </div>

                      {(error.pwsuccess || error.pwerror) && (
                        <div className="flex coldir m-b-20 error">
                          <label>{error.pwsuccess}</label>
                          <label>{error.pwerror}</label>
                        </div>
                      )}

                      {error.is_password_valid && (
                        <button
                          type="button"
                          onClick={(e) => updatePassword()}
                          className="butn btn-inverse"
                        >
                          Update
                        </button>
                      )}

                      {error.is_password_valid == false && (
                        <label className="error">Password Mismatch</label>
                      )}
                    </div>
                  </div>
                  <div id="c" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Secret Questions</h2>
                    </div>
                    <SecretQuestions history={props.history}/>               
                  </div>
                  <div id="d" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">
                        Set your preferences to receive cueTree notifications
                      </h2>

                      <div className="flex coldir m-b-20">
                        <span className="checkbox-container">
                          <span
                            data-toggle="tooltip"
                            title="Notification sent soon after specified interactions (e.g., a comment, an invitation)."
                          >
                            Journey Interactions
                          </span>
                          <input
                            type="checkbox"
                            checked={settings.notify_journey_interactions}
                          />
                          <span className="checkbox-checkmark"></span>
                          <span className="help-block">
                            Notification set up for journeys that you are
                            participating in.
                          </span>
                        </span>

                        <span
                          className="checkbox-container"
                          onClick={(e) =>
                            updateNotificationSetting(
                              "notify_daily_interactions"
                            )
                          }
                        >
                          <span
                            data-toggle="tooltip"
                            title="Notification sent once a day, summarizing action items applicable to a user."
                          >
                            Daily Digest
                          </span>
                          <input
                            type="checkbox"
                            checked={settings.notify_daily_interactions}
                          />
                          <span className="checkbox-checkmark"></span>
                          <span className="help-block">
                            A daily compilation of journey notifications and
                            general news items from cueTree.
                          </span>
                        </span>

                        <span
                          className="checkbox-container event-none"
                          onClick={(e) =>
                            updateNotificationSetting("notify_weekly_report")
                          }
                        >
                          <span
                            data-toggle="tooltip"
                            title="Notification sent once a week, summarizing action items applicable to a user."
                          >
                            Weekly Report
                          </span>
                          <input
                            type="checkbox"
                            checked={settings.notify_weekly_report}
                          />
                          <span className="checkbox-checkmark"></span>
                          <span className="help-block">
                            A weekly compilation of journey notifications and
                            general news items from cueTree.
                          </span>
                        </span>

                        <span className="checkbox-container event-none">
                          <span
                            data-toggle="tooltip"
                            title="Notification about the cueTree system, sent for administrative purposes."
                          >
                            Administrative
                          </span>
                          <input
                            type="checkbox"
                            checked={settings.notify_system_alert}
                          />
                          <span className="checkbox-checkmark"></span>
                          <span className="help-block">
                            Notifications from cueTree about system status and
                            rules.
                          </span>
                        </span>

                        <span
                          className="checkbox-container event-none"
                          onClick={(e) =>
                            updateNotificationSetting("notify_marketing")
                          }
                        >
                          <span
                            data-toggle="tooltip"
                            title="Notification concerning products and services that may be of interest to users."
                          >
                            Marketing
                          </span>
                          <input
                            type="checkbox"
                            checked={settings.notify_marketing}
                          />
                          <span className="checkbox-checkmark"></span>
                          <span className="help-block">
                            Notifications from cueTree about new system features
                            and user experiences.
                          </span>
                        </span>
                      </div>

                      {/* <div className="flex coldir m-b-20">
                      <span className="checkbox-container" 
                          onClick={e=>updateSetting('notify_journey_interactions')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification sent soon after specified interactions (e.g., a comment, an invitation).">Journey Interactions</span>
                          <input type="checkbox" checked={settings.notify_journey_interactions}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container"
                          onClick={e=>updateSetting('notify_daily_interactions')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification sent once a day, summarizing action items applicable to a user.">Daily Digest</span>
                          <input type="checkbox" checked={settings.notify_daily_interactions}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container event-none"
                          onClick={e=>updateSetting('notify_weekly_report')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification sent once a week, summarizing action items applicable to a user.">Weekly Report</span>
                          <input type="checkbox" checked={settings.notify_weekly_report}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container event-none"
                          onClick={e=>updateSetting('notify_system_alert')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification about the cueTree system, sent for administrative purposes.">Administrative</span>
                          <input type="checkbox" checked={settings.notify_system_alert}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container event-none"
                          onClick={e=>updateSetting('notify_marketing')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification concerning products and services that may be of interest to users.">Marketing</span>
                          <input type="checkbox" checked={settings.notify_marketing}/>
                          <span className="checkbox-checkmark"></span>
                      </span>
                    </div> */}
                    </div>
                  </div>
                  <div id="e" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Plans</h2>
                    </div>
                    <PricingTable {...props}/>
                  </div>
                  <div id="f" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Payments</h2>
                    </div>
                    <UserSubscription/>      
                  </div>
                  <div id="g" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Deactivate</h2>
                    </div>

                    <div
                      className="d-flex"
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger bg-lred white"
                        >
                          Click to Deactivate Account
                        </button>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="p-12">
                        <p>
                          Note:Once Deactivated, you cannot access this
                          account.You will need to register again to use cueTree
                          Journey Services
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="h" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Whats New</h2>
                    </div>
                    {infoCard}
                  </div>
                  <div id="i" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Messages</h2>
                    </div>
                    {msgCard}                   
                  </div>
                </div>
                <div className="tright">
                  <button
                    href="#"
                    className="btn-primary green text-white m-t-20"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isHelpMessageOpen && (
          <GenericModal
            component={NotificationPopup}
            header="Note"
            message={HelpMessageList.user_setting}
            onSuccess={toggleHelpMessage}
            isOpen={isHelpMessageOpen}
            toggleModal={toggleHelpMessage}
          />
        )}
      </section>
    </Fragment>
  );
};

export default DemoUserProfile;
/*
<p className = "lred">If you would like to receive notifications via text messages, enter
                your cell number below. Additional charges may be applicable.</p>


                    <div className="form-group col-xs-4">
                      <label>Country Code</label>
                      <input
                        onChange={(e) => updateMobile(e)}
                        name="country_code"
                        className="form-control"
                        placeholder="Country Code"
                        defaultValue={settings.country_code}/>
                    </div>
                    <div className="form-group col-xs-8">
                      <label>Mobile No</label>
                      <input
                        onChange={(e) => updateMobile(e)}
                        name="mobile_no"
                        className="form-control"
                        placeholder="Mobile no"
                        defaultValue={settings.mobile_no}/>
                    </div>
                  </div>
*/
/*onClick={(e) =>
                      updateNotificationSetting("notify_journey_interactions")
                  }

onClick={(e) =>
                      updateNotificationSetting("notify_system_alert")
                    }
*/
