import React, {Fragment, useState, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'

let profileImage = "images/cuetree_logo.png"

const WelcomeJourney = (props) => {
  let params = props.match.params
  useStyle('signup')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  
  return (
    <Fragment>
      <div className="content top-10">
        <div className="clear"></div>
      </div>
      <div className="page-content row">
        <div className="content-boxed content-boxed-full left-20 right-20 shadow-large">
          <div className="content bottom-0">
            <div className="row">
              <div id='account-card' className="animated fadeIn">
                <div id='upper-side' className="black">
                  <img className="user-logo ma w-50p" src={profileImage}/>
                  <h3 id='status'>Welcome to cueTree HighSchool Journey</h3>
                </div>
                <div id='lower-side'>
                  <div className="p-7">
                    <i className="fa fa-address-book font-18" aria-hidden="true"></i><span className="m-l-10 font-16">Easy to build Journey</span>
                    <p className="p-12 lgrey font-13">Build the high school journey the way you want it,with templates to help</p>
                  </div>
                  <div className="p-7">
                    <i className="fa fa-address-book font-18" aria-hidden="true"></i><span className="m-l-10 font-16">Simple to use</span>
                    <p className="p-12 lgrey font-13">Access your journey map from any computer, tablet or smartphone</p>
                  </div>
                  <div className="p-7">
                    <i className="fa fa-address-book font-18" aria-hidden="true"></i><span className="m-l-10 font-16">Convenient to work with others</span>
                    <p className="p-12 lgrey font-13">Invite others to help with the journey, collaborating on journey steps</p>
                  </div>
                  <div className="p-7">
                    <i className="fa fa-address-book font-18" aria-hidden="true"></i><span className="m-l-10 font-16">Helpful suggestions and tools</span>
                    <p className="p-12 lgrey font-13">Get Support with continuous feedback to help move ahead on the journey</p>
                  </div>
                  <div className="p-7">
                    <button type="button" className="dropbtn bg-black white p-12 w-100p"> Sign up now!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )

}

export default WelcomeJourney;