import React, {useState, Fragment, useEffect, useContext} from "react"
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from '../../services/CommonService'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import DailyDabbleResponseService from "./DailyDabbleResponseService"
import JourneyTitle from "../Journey/JourneyTitle/JourneyTitle"
import {NavIcon, CardMenuInfo, MenuItem, CardMainMenu} from '../Common/MenuItem'
import Pagination from '../Pagination'
import useModal from "../../hooks/useModal"
import ConfirmMenu from "../Modals/ConfirmMenu"
import EditResponseModal from "./EditResponseModal"

let requestParams = {}
let selectedResponse = null

const DailyDabbleResponsesTable = (props) => {
  useStyle('table')  
  useStyle('add_role_dialog')  
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [responses, setResponses] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 
  const [loading, setLoading] = useState(true) 
  const [pagemeta, setPagemeta] = useState([])

  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isEditOpen, toggleModal:toggleEditModal } = useModal()

  let params = props.match.params
  let dailyDabble = props.location.state

  useEffect(() => {
    requestParams = {
      'daily_dabble_id':params.daily_dabble_id,
      'page':currentpage,
      'per_page':20,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
    }

    getDailyDabbleResponses()

    return () => {
     requestParams = {} 
    }
  }, [props.searchString, currentpage])

  let getDailyDabbleResponses = () => { 
    setLoading(true)
    DailyDabbleResponseService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setResponses([getDefaultResponse(), ...res.data.daily_dabble_responses])
        setLoading(false)
      }
    })
  }
  
  const {sort} = useSort(requestParams, getDailyDabbleResponses)

  const getDefaultResponse = () =>{
    return {
      id:'',
      data:{response:dailyDabble?.data?.notes},
      created_at:dailyDabble?.created_at,
    }
  }

  const onDelete = (e, response) => {
    selectedResponse = response
    toggleDeleteModal(e)
  }

  const deleteResponse = (e) =>{
    e.preventDefault();
    DailyDabbleResponseService.delete(selectedResponse.id).then((res) => {
      if (res.status == 204) {
        setResponses(responses.filter((i) => i.id !== selectedResponse.id)) 
      }
    })  
    toggleDeleteModal(e)
  }

  const update = (item, field) =>{
    if(field == 'is_reviewed') item.is_reviewed = !item.is_reviewed; 
    DailyDabbleResponseService.update(item).then((res) => {
      if(res.status == 204){
        setResponses([...responses])
      }
    })
  }

  const setEdit = (e, item)=>{
    selectedResponse = item
    toggleEditModal(e)
  }

  const tableData = responses.map((q, k) =>{
    return(
      <tr key={k} className="bold-100">
        <td data-title="Id">{q.record_id}</td>

        <td className="font-16 miw-100 maw-120">
          <div dangerouslySetInnerHTML={{ __html: q.data.response }}/>
        </td>
     
        <td className="font-16 maw-50">
          {Cs.formatUpdateDate(q.updated_at, 'MMM DD, YYYY hh:mm a')}
        </td>

        <td className="font-16 maw-50">
          {q.id && <>
              <span className="m-r-15" onClick={e=>setEdit(e, q)}>
                <i className="fa fa-edit"/>
              </span>
              <span className="m-r-15" onClick={e=>onDelete(e, q)}>
                <i className="far fa-trash-alt"/>
              </span>
              <span onClick={e=>update(q, 'is_reviewed')}>
                <i className={q.is_reviewed ? "far fa-check-circle": "far fa-circle"}></i>
              </span>
            </>
          }
        </td>
      </tr>
    )
  })

  if(loading){
    return(<div className="spinner"/>)
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4">
          <JourneyTitle title={dailyDabble?.data?.record_title}/>
        </div>

        <div className="col-md-8 float-right">         
          <NavIcon id="home_icon" dataTip="Back to Daily Dabble" 
            onSelect={e=>props.history.goBack()}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
        </div>
      </div>

      <div className="table-responsive">
        <table className="shadow-small">
          <thead>
            <tr className="white bg-black">
              <th onClick={e=>sort('record_id')}>Id 
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </th>
              <th onClick={e=>sort('response')} className="miw-100 maw-120">Response
                <SortDirection sort_column={requestParams.sort_column} column="response" reverse={requestParams.reverse}/>
              </th>
              <th onClick={e=>sort('updated_at')} className="maw-50">Date 
                <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
              </th>
              <th className="maw-50">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData}
          </tbody>
        </table>
      </div>

      <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
      setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteResponse}/>

      {isEditOpen && <EditResponseModal isOpen={isEditOpen} toggleModal={toggleEditModal} response={selectedResponse}/>}
    </Fragment>
  )

};

export default DailyDabbleResponsesTable;