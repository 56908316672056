import React, {useState} from 'react'
import {CueTooltip} from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const InputMultiRating = ({field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  let [showError, setShowError] = useState(false)
  let defaultModel = field.rating_type == 'general_rating'?0:{};
  let defaultSumOfRating = field.rating_type == 'general_rating'?formData[field.client_id]:formData['sum_of_'+field.client_id];
  let [sumOfRating, setSumOfRating] = useState(defaultSumOfRating || 0)
  let [model, setModel] = useState(formData[field.client_id] || defaultModel)
  const [index, setIndex] = useState(isFormWizard?field.client_id:0)

  let totalRating = 0

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let validation = {
    required: field.required
  }

  let op_kv = field.options_key_value;
  if(field.rating_type == 'general_rating'){
    totalRating = 0;
  }else{
    if(op_kv.options && op_kv.options.length>0){
      totalRating = op_kv.options.length*5;
    }
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const bindWindowClickEvent = (e, field) =>{
    if(isFormWizard)return null;
    setIndex(index == field.client_id?null:field.client_id)
    window.addEventListener('click', eventFunction)
  }

  const eventFunction = (event) => {
    try{
      if(document.getElementById(field.client_id+'_container').contains(event.target)){
        console.log(field, event)
      }else{
        let el = document.getElementById(field.client_id+'_result_set')
        el.classList.add("hidden")
        window.removeEventListener("click",eventFunction)
      }
    }catch(e){
    
    }
  }

  const toggle = (value, item) => {
    if(field.rating_type == 'general_rating'){
      if(model == value){
        model = 0
        setModel(0)
      }else{
        model = value
        setModel(value)
      }
      //setSumOfRating(model);
      formData[field.client_id] = model
    }else{
      model = model || {}
      if(model[item.value] == value){
        delete model[item.value]
      }else{
        model[item.value] = value
      }
      setModel(model)
      setSumOfRating(sum(model))
      formData[field.client_id] = model
      formData['sum_of_'+field.client_id] = sumOfRating
    }
    setError()
  }

  const sum = (obj) => {
    let sum = 0
    for( let el in obj ) {
      if( obj.hasOwnProperty( el ) ) {
        sum += parseFloat( obj[el] )
      }
    }
    return sum
  }
  
  const setError = (event) => {
    if(field.required){
      const name = field.client_id
      if(field.rating_type == 'general_rating'){
         errors[name].invalid = model==null?true:false
      }else{
        errors[name].invalid = Object.keys(formData[name]).length === 0?true:false
      }
      errors[name].touched = true
      if(errors[name].invalid){
        errors.invalid[name] = true
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError)
      formFn.refreshFormSubmit()
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null
  }
  
  const multiRatings = op_kv.options && op_kv.options.map((o, k) =>  
    <div key={k} title={o.hover_text}>
      {o.label}
      {field.rating_type != 'general_rating' &&
        <div className="pull-right font-20">
          <i className={`${1<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(1, o)}></i>
          <i className={`${2<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(2, o)}></i>
          <i className={`${3<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(3, o)}></i>
          <i className={`${4<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(4, o)}></i>
          <i className={`${5<=model[o.value]?'fas green':'far'} fa-star`} onClick={e => toggle(5, o)}></i>
        </div>
      }
    </div>
  )

  const Rating = () =>(
    <span className="m-l-20 font-20">
      <i className={`${1<=model?'fas green':'far'} fa-star`} onClick={e => toggle(1)}></i>
      <i className={`${2<=model?'fas green':'far'} fa-star`} onClick={e => toggle(2)}></i>
      <i className={`${3<=model?'fas green':'far'} fa-star`} onClick={e => toggle(3)}></i>
      <i className={`${4<=model?'fas green':'far'} fa-star`} onClick={e => toggle(4)}></i>
      <i className={`${5<=model?'fas green':'far'} fa-star`} onClick={e => toggle(5)}></i>
    </span>
  )

  return (
  	<div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
    	<div className="form-input-gray p10  flex coldir m-b-20">
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        
        {field.rating_type == 'general_rating' && <Rating/>}
   	    
        <div className="m-t-10 m-l-25 autocomplete" id={`${field.client_id}_container`} onClick={e => bindWindowClickEvent(e,field)} >
          {field.rating_type == 'general_rating' && 
            <div className="text-muted">
              <i className="fa fa-info-circle"></i> Features to consider when rating
            </div>
          }
          {field.rating_type != 'general_rating' &&
            <input {...inputAttributes} className="form-input form-input-gray" 
            placeholder="Enter Search txt"
            value={`${sumOfRating} out of ${totalRating}`} readOnly />
          }
          {index == field.client_id && 
            <div id={`${field.client_id}_result_set`} className="autocomplete-items shadow-small">
              {multiRatings}
            </div>  
          }
         </div>
    	  <span className="fts-13">{field.description}</span>
        <Error/>
        <CueTooltip id={field.client_id} description={field.tooltip}/>
 	    </div>
    </div> 
  )

}

export default InputMultiRating;