import React, {Fragment} from "react"
import { Route, Switch } from "react-router-dom"
import PrivateRoute from "../../routes/PrivateRoute"
import JourneyList from "../../components/Journey/List/JourneyList"
import RoleList from "../../components/Journey/Roles/RoleList"
import UserRoleList from "../../components/Journey/UserRoles/UserRoleList"
import UsersList from "../../components/Journey/UserRoles/UsersList"
import JourneyDashboard from "../../components/Journey/JourneyDashboard"
import JourneyProfileForm from "../../components/Journey/Form/JourneyProfileForm"
import InvitedUserList from "../Journey/InvitedUsers/InvitedUserList"
import TeamRouter from "./TeamJourney/TeamRouter"
import CourseRouter from "./CourseJourney/CourseRouter"
import HighSchoolRouter from "./HighSchoolJourney/HighSchoolRouter"
import CareerTrainingRouter from "./CareerTrainingJourney/CareerTrainingRouter"
import CollegeRouter from "./CollegeJourney/CollegeRouter"
import QuickUpdateForm from "./Form/QuickUpdateForm"
import IncomingMails from "./IncomingMails/IncomingMails"
import JourneyPriceModelForm from "./JourneyPriceModels/JourneyPriceModelForm"
import CareerJourneyRouter from "./CareerJourney/CareerJourneyRouter"
import NotificationForm from "./JourneyNotification/Form"
import {JourneyTypeMenu} from "./List/JourneyTypeMenu"
import ScholarshipRouter from "./Scholarships/ScholarshipRouter"

const JourneyRouter = (props) => (
  <Fragment>
    <Switch>
      <PrivateRoute
        path="/journey/dashboard"
        component={JourneyDashboard}
        exact
      />
      <PrivateRoute
        path="/journey"
        component={JourneyList}
        searchString={props.searchString}
        exact
      />
      <PrivateRoute
        path="/journey_profile/:id?"
        component={JourneyProfileForm}
        exact
      />
      <PrivateRoute
        path="/journey/role/:journey_category_id/:journey_proile_id?"
        component={RoleList}
        exact
      />
      <PrivateRoute
        path="/journey/user_role/:journey_category_id/:journey_proile_id/:roleable_type/:roleable_id"
        component={UserRoleList}
        exact
      />
      <PrivateRoute
        path="/journey/users/:journey_category_id/:journey_proile_id/:roleable_type/:roleable_id"
        component={UsersList}
        exact
      />
      <PrivateRoute
        path="/invite/user/:journey_category_id/:journey_profile_id"
        component={InvitedUserList}
        searchString={props.searchString}
        exact
      />
      <PrivateRoute
        path="/journey/activity/update"
        component={QuickUpdateForm}
        exact
      />
      <PrivateRoute
        path="/journey/incoming/emails/:type/:id"
        component={IncomingMails}
        exact
      />
      <PrivateRoute
        path="/journey/price/form/:journey_category_id/:journey_profile_id/:item_type/:item_id/:id?"
        component={JourneyPriceModelForm}
        exact
      />
      <PrivateRoute
        path="/journey/notification/form/:journey_category_id/:journey_profile_id/:item_type/:item_id/:id?"
        component={NotificationForm}
        exact
      />
      <PrivateRoute
        path="/journey_types/:journey_member_id?"
        component={JourneyTypeMenu}
        exact
      />
    </Switch>
    <Switch>
      <CourseRouter
        app={props.app}
        searchString={props.searchString}
        setHelpId={props.setHelpId}
      />
    </Switch>
    <Switch>
      <TeamRouter
        app={props.app}
        searchString={props.searchString}
        setHelpId={props.setHelpId}
      />
    </Switch>
    <Switch>
      <HighSchoolRouter app={props.app} searchString={props.searchString} />
    </Switch>
    <Switch>
      <CollegeRouter app={props.app} searchString={props.searchString} />
    </Switch>
    <Switch>
      <CareerTrainingRouter app={props.app} searchString={props.searchString} />
    </Switch>
    <Switch>
      <CareerJourneyRouter app={props.app} searchString={props.searchString} />
    </Switch>
    <Switch>
      <ScholarshipRouter app={props.app} searchString={props.searchString} />
    </Switch>
  </Fragment>
)

export default JourneyRouter;