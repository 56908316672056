import env from '../../../env';
import axios from 'axios';
const url = `${env.family_tree_backend}invited_journey_members`;

class InvitedUserService {
  
  getFamilyMembers(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}/family_members`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(role){
    return new Promise((resolve, reject) => {
      axios.post(url,role).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(role) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${role.id}`, role).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new InvitedUserService();