import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../../../hooks/useStyle";
import CommonService from "../../../../../services/CommonService";
import ActivityService from "../../Activities/ActivityService";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import useModal from "../../../../../hooks/useModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import CommentsModal from "../../../../Modals/CommentsModal";
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal";
import {NavIcon} from '../../../../Common/MenuItem'
import {DataList, DataColumn, DataRow} from '../../../Common/ActivityLayout'
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import CheckAccess from '../../../Roles/CheckAccess';
import ListActivity from "./ListActivity";
import ReactTooltip from "react-tooltip";
import {HighSchoolLink} from "../../../../Common/RouteLinks"

let requestParams = {};
let journey_category = null;
let selectedActivity = null;
let dataPosition = null;

const ActivityTypeList = (props) => {
  useStyle("filter_dialog");
  useStyle('add_role_dialog');

  let params = props.match.params;
  let journey_category = props.location.state;

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [activityCategory, setActivityCategory] = useState();
  const [activityReport, setActivityReport] = useState({});
  const [tabIndex, setTabIndex] = useState();
  const [activities, setActivities] = useState([]);
  
  const {isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal();
  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal,} = useModal();
  const {isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal,} = useModal();

  useEffect(() => {
    requestParams = {
      data_position:{
        data_source_id: 2214,
        key:'child_form'
      },
      data_source_params: {
        data_source_id: 2214,
      }
    }
    //getActivityCategory();
    getActivityByType()
    ReactTooltip.rebuild()
  }, [])

  let getActivityCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      let d = CommonService.groupBy(res.options.fj_edu_entry_type.options, "actions")
      dataPosition = res.data_position
      setActivityCategory(d)
    })
  }

  let getActivityByType = (form_id) => {
    let req = {
      'journey_category_id':params.journey_category_id,
      'journey_profile_id':params.journey_profile_id, 
      'activity_form_id':form_id,
      'data_position':{
        data_source_id: 2214,
        key:'child_form'
      },
      'data_source_params': {
        data_source_id: 2214,
      }
    }
    ActivityService.getActivityByType(req).then((res) => {
      setActivities(res.data.activities)
      if(!form_id){
        let d = CommonService.groupBy(res.data.activity_type?.options, "actions")
        dataPosition = res.data.data_position
        setActivityCategory(d)
        setActivityReport(res.data.activity_report.report || {})
      }
    })
  }

  const openTab = (ds) =>{
    if(ds.child_form != tabIndex){
      getActivityByType(ds.child_form)
      setActivities([])
      setTabIndex(ds.child_form)
    }else{
      setTabIndex(null)
    }
  }

  const addComments = (e, activity) => {
    e.stopPropagation();
    selectedActivity = activity;
    //selectedDataSource.journey_category_id = params.journey_category_id
    toggleCommentModal();
  }

  const addChecklist = (e, activity) => {
    e.stopPropagation();
    selectedActivity = activity;
    //selectedDataSource.education_journey_profile_id = params.journey_profile_id
    //selectedDataSource.journey_category_id = params.journey_category_id
    toggleChecklistModal();
  }

  return(
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Activities"} project={journey_category} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={HighSchoolLink.hs_portal(params)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
          {journey_category && 
            <NavIcon id="tile_view" dataTip="Toggle View" 
              link={{pathname:`/highschool/activity/${params.journey_category_id}/${journey_category.education_journey_profiles.id}`,
                state:journey_category}}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-file"/>
          }
          <NavIcon id="file_links" dataTip="Journey Files and Links" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={toggleFileLinkModal} icon="fas fa-link"/>
        </div>
        <div className="clear"></div>
      </div>

      <ListActivity activityReport={activityReport} journey_category={journey_category} activityCategory={activityCategory}  
        journey_category_id={params.journey_category_id} journey_profile_id={params.journey_profile_id} 
        tabIndex={tabIndex} activities={activities} dataPosition={dataPosition}
        addComments={addComments} addChecklist={addChecklist} openTab={openTab} 
        pageName="activities"/>

      {isCommentOpen && (
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal}
          item={selectedActivity} type="education_journal_entry"/>
      )}
      
      {isChecklistOpen && (
        <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal}
          item={selectedActivity} type="education_journal_entry"/>
      )}

      {isFileLinkOpen && (
        <JourneyFilesLinksModal toggleModal={toggleFileLinkModal}
          journeyCategory={journey_category}/>
      )}
      <SideMenuBar helpId="8"/>
    </Fragment>
  )
}

export default ActivityTypeList;