import React,{useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import CommonService from "../services/CommonService";
import OrgAccountInactiveNotice from '../components/Authentication/OrgAccountInactiveNotice'
import {IsAuthorized} from './CheckPermission'
import UnauthorizedNotice from '../components/Authentication/UnauthorizedNotice'

const PrivateRoute = ({component: Component, permissionType, ...rest}) => {
	const { state:authState, dispatch } = useContext(AuthContext);
   
   	/* CommonService.loadCss('forms');
    CommonService.removeUnusedCss();*/
    
    // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page

    if(!authState.isAuthenticated){
      return(
    	<Route {...rest} render={props => <Redirect to="/signin" />} />
      )
    }else if(authState.user.domain_info.data == null){
  	  return(
  		<Route {...rest} render={props => <Redirect to="/profile?show_form_wizard=1"/>}/>
      )
    }else if(!authState.user.current_organization.is_active){
      return(
        <Route {...rest} render={props => <OrgAccountInactiveNotice />} />
      )
    }else if(permissionType && !IsAuthorized(authState.user, permissionType)){
       /* console.log(permissionType)
        console.log(IsAuthorized(authState.user, permissionType))*/
      return(
        <Route {...rest} render={props => <UnauthorizedNotice />} />
      )
    }else{
      return(
    	<Route {...rest} render={(props) => <Component {...rest} {...props}/>}/>
      )
    }
  
}

export default PrivateRoute;
