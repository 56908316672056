const Portal = {	
  training_journey:3386,
  college_journey:3354,
  academic_course:2214,
  high_school_journey:2214,
  edu_team_journey:3326	
}

const Locales = [
  {label:"English (default)", sid:"english"},
  {label:'Gujarati (India)', sid:'gujarati', iso_code:'gu'},
  {label:'Tamil (India)', sid:'tamil', iso_code:'ta'}
]

const CollegeStatusList = [
  {label:"Applied", sid:"applied"},
  {label:'Rejected', sid:'rejected'},
  {label:'Waitlisted', sid:'waitlisted'},
  {label:'Admitted', sid:'admitted'}
]

const CollegeColor = ['#FF42A1', '#60D937', '#00A1FF', '#ED220D', '#FEAE00', '#973CFE', '#3BE7AE', '#000000']

const NotificationTypeList = [
  {label:'All students in this course', sid:"course_level"},
  {label:'Only students in this group', sid:'group_level'},
]

const ActiveStatusList = [
  {label:'ALL', value:null},
  {label:'Active', value:'true'},
  {label:'InActive', value:'false'},
]

const DefaultFilter = {label:'ALL', value:null}

const setDefaultLabel = (options_obj, options_list) =>{
  if(options_obj){
    options_obj[0] = DefaultFilter
  }else if(options_list){
    options_list.unshift(DefaultFilter)
  }
}

const collegeCueList = [
  { label: 'Research', tip:'Research and rate the college', form_type: 'research', form_id: 1001 , icon:'fa-search', 'form_wizard_id':45,},
  { label: 'Reference', tip:'Save helpful URLs for this college', form_type: 'reference_info', form_id: 1002, icon:'fa-list', 'form_wizard_id':46},
  { label: 'Application', tip:'Plan and manage your application to this college', form_type: 'application_notes', form_id: 1003, icon:'fa-school', 'form_wizard_id':47, showStatus:['Waitlisted', 'Deferred']},
  { label: 'Checklist', tip:'Organize your acceptance and move-in to this college', form_type: 'checklist', form_id: 1004, icon:'fa-people-carry', 'form_wizard_id':48, showStatus:['Applied', 'Enrolled', 'Admitted']},
]

export {collegeCueList, DefaultFilter, setDefaultLabel, Portal, Locales, CollegeStatusList, CollegeColor, NotificationTypeList, ActiveStatusList}