import env from '../env';
import axios from 'axios';
import BaseService from "../services/BaseService";

class ProfileService extends BaseService{
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}families`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}families/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(profile,id){
    return new Promise((resolve, reject) => {
      axios.put(`${env.family_tree_backend}families/${id}`, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.family_tree_backend}families/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.family_tree_backend}families/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }*/

}

export default new ProfileService('family_tree_backend', 'families');