import env from "../../env"
import axios from "axios"

class JourneyCategoryService {
  getAll(req) {
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}journey_categories`, { params: req })
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  getJourneyById(req) {
    return new Promise((resolve, reject) => {
      axios.get(env.family_tree_backend +"journey_categories/"+req.journey_category_id, { params: req })
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  getCatId(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.family_tree_backend}journey_categories`, { params: req })
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  create(journey_category) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.family_tree_backend}journey_categories`, journey_category)
        .then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  update(journey_category) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.family_tree_backend}journey_categories/${journey_category.id}`, journey_category)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.family_tree_backend}journey_categories/${req.id}`)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  getSchedules(req) {
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}education_schedules`, { params: req })
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  getSchedulesById(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}education_schedules/${req}`)
      .then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  createSchedule(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.family_tree_backend}education_schedules`, req)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  updateSchedule(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.family_tree_backend}education_schedules/${req.id}`, req)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  updateScheduleDate(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.family_tree_backend}update_schedule_date`, req)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  deleteSchedule(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.family_tree_backend}education_schedules/${req.id}`)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  cloneJourney(id) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.family_tree_backend}/journey_categories/clone/${id}`)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  getJourneyDashboard(){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}dashboard/journey_categories`)
        .then((res) => {
          // handle success
          resolve(res)
      })
    })
  }

  createDataSource(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}data_source_lists`, req)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  updateDataSource(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}data_source_lists/${req.id}`, req)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

  deleteDataSource(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.backend}data_source_lists/${id}`)
        .then((res) => {
          // handle success
          resolve(res)
        })
    })
  }

}

export default new JourneyCategoryService();