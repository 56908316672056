import React, { Fragment,useContext } from "react";
import env from "../../../env";
import useModal from "../../../hooks/useModal";
import Cs from "../../../services/CommonService";
import JourneyCategoryService from "../JourneyCategoryService";
import PortalPath from "../CareerTrainingJourney/CareerTrainingPortalPage/PortalPath";
import CheckAccess from "../Roles/CheckAccess";
import GenericModal from "../../Modals/GenericModal";
import { Link, useHistory } from "react-router-dom";
import AddToFavourite from "../../Common/AddToFavourite";
import {PillList} from '../UserRoles/NotificationPill'
import { HamburgerMenuIcon } from "../../Common/ImageIcons";

const PreCollegeJourney = (props) => {
  let { journey, users, currentUser, cardStyle, share, subPlan } = props;
  let data = journey.education_journey_profiles.data;
  let data_source = journey.education_journey_profiles.data_source;
  let bodyStyle = { height: "238px", overflowY: "auto" };
  let dsource = props.dataSources.fj_type_edu_jou.options
  const history = useHistory();
  
  const route = () =>{
    history.push({
        pathname:`/college/admissions/${journey.id}/${journey.education_journey_profiles.id}`,
        state:{project:journey}
      })
    /*if(!CheckAccess.isDevUser(currentUser.email, env) && !journey.is_demo_journey && !journey.subscription_user_id){
      history.push({
        pathname:`/plans/${journey.id}`+(journey.journey_member_id?`/${journey.journey_member_id}`:''),
        search:'?journey_type='+(data?.fj_type_edu_jou)
      })
    }else{
      history.push({
        pathname:`/college/admissions/${journey.id}/${journey.education_journey_profiles.id}`,
        state:{project:journey}
      })
    }*/
  }

  const category = (key) => {
    return (
      <div className="card-title flex justspacebetween m-t-20 p-b-15">
        <div className="flex aligncenter">
          <div className="card-icon-small border-rounded card-icon-blue-bg flex aligncenter justifycenter m-r-15">
            <i className={dsource[key].icon} style={{color: dsource[key].icon_color ? dsource[key].icon_color :'black'}}/>
          </div>
          <div>
            <h4 className="fw700 f14 card-heading-blue">
              {dsource[key].label}
            </h4>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 p20" style={cardStyle}>
        <div onClick={e=>Cs.routeJourney(journey, {history:history}, false, currentUser)}
          className="card-title flex aligncenter justspacebetween border-dashed-sm p-b-15">
          <div className="flex aligncenter justifycenter">
            <div className="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
              <img src={Cs.getIconByType(users[journey.created_by].profile_image, 'profile_image')} className="icon-gray" alt="" width="50"/>
            </div>
            <div>
              <h4 className="fw700 card-heading">Pre College - {data.state}</h4>
              <p className="text-gray f12">since {journey.updated_at}</p>
              <p className="text-gray f12"> by {users[journey.created_by].name}</p>
            </div>
          </div>
          <div>
            <div className="menu-drop-icon flex justifycenter aligncenter m-7">
              <img src="./images/arrow-right.png" alt=""/>
            </div>
            <div className="menu-drop-icon flex justifycenter aligncenter m-7"> 
              <AddToFavourite favouriteId={journey.favourite_id} favorableType="journey_category" 
                favorableId={journey.id} journeyCategoryId={journey.id} classNameActive="pin-0 green" 
                classNameDisable="pin-0 lgrey" journeyProfileId={journey.education_journey_profiles.id}/>
            </div>
          </div>
        </div>
        {category(data.fj_type_edu_jou)}
        <div className="card-content">
          <div className="flex coldir">
            {subPlan &&
              <div className="m-b-15 fw500">
                {subPlan}
              </div>
            }
            <div className="m-b-15 fw500">
              <b>College of Interest: </b>
              <div>
                <PillList list={data.colleges_array} dataKey="name" style="pill white bg-lgrey"/>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer flex justspacebetween aligncenter">
          <ul className="flex aligncenter">
          </ul>
          <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, journey)}/>
        </div>
      </div>

    </Fragment>
  )
}

export default PreCollegeJourney;