import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import StepActivityResponseService from "./StepActivityResponseService";
import {FilterMenuItem} from "../../../Common/MenuItem"
import useModal from "../../../../hooks/useModal";
import CommonService from "../../../../services/CommonService";

function TrackProgress({stepActivity, toggleModal}) {
  const [list, setList] = useState();
  const [loading, setLoading] = useState(true);

  let {progress_status, activity_type} = stepActivity

  useEffect(() => {
    if(progress_status)getActionList()
  }, [])

  let getActionList = () => {
    let requestParams = {
      data_source_id:[progress_status == 'progress'?activity_type.step_prog:activity_type.step_prob],
    }
    CommonService.getDataSource(requestParams).then((res) => {
      setList(res[Object.keys(res)[0]].options)  
      setLoading(false)
    })
  }

  const onSelect = (e, ds) =>{
    e.preventDefault()
    setLoading(true)
    let req = {
      education_journey_profile_id : stepActivity.education_journey_profile_id,
      journey_category_id : stepActivity.journey_category_id,
      education_journal_entry_id : stepActivity.education_journal_entry_id,
      education_activity_id: stepActivity.id,
      form_type : 'progress_tracking',
      form_id : 238,
      data:{
        status:stepActivity.progress_status,
        action:ds.label,
        action_ds_id:ds.pk_id
      }  
    }
  
    StepActivityResponseService.create(req).then((res) => {
      if(res.status==201){
        setLoading(false)
        toggleModal(e)
      }
    })
  }

  if(!list) return null;

  return (
    <>
      {loading ? <div className="spinner"/> : <FilterMenuItem list={list} onSelect={onSelect}/>}
    </>
  )
}

export default TrackProgress;