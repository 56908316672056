import React from "react";
import CommonService from '../../../../../services/CommonService';

const CollegeInfoBar = (props) => {
  let {maxScoreValue, maxScoreString, minScore, 
    earnedScore, leftLabel, rightLabelString, 
    rightLabelValue, barClass, filterName, index, name} = props

  let maxScore = maxScoreString || maxScoreValue;
  let rightLabel = rightLabelString || rightLabelValue;
  
  setTimeout(function () { 
      var elem = document.getElementById(`${name}_${index}`); 
      var width = 24;
      var max_width = 0;
      var id = null;

      if(isNaN(maxScore) || isNaN(earnedScore)){
        max_width = 0;
      }else{
        max_width = ((earnedScore/maxScore) * 100).toFixed(2);
        if(max_width < 25) max_width =33;
        id = setInterval(frame, 0);
      }

        function frame() {
          try{
            if (width >= max_width) {
              let v = parseInt(earnedScore);
              /*if(filterName){
                v = $filter(filterName)(earnedScore, '$', 0);
              }*/
              v = CommonService.numberWithCommas(v)
              elem.innerHTML = v;
              clearInterval(id);
            } else {
              width++; 
              elem.style.width = width + '%'; 
            }
          }catch(e){
            clearInterval(id);
          }
        }
  },0)

  return(
    <div className="c-info-bar">
      <div className="col-xs-12">
        {leftLabel}
      </div>
      <div className="col-xs-12 text-center m-l-30">
        {rightLabel}
      </div>
      <div className="col-xs-12">
        <div className="btn-rounded" id="myProgress">
          <div id={`${name}_${index}`} className={`ct-bar ${barClass} btn-rounded`}>NO DATA</div>
        </div>
      </div>
    </div>
  )
}
  
export default CollegeInfoBar;

  /*angular.module('app').directive('progressBar', function() {
    var uniqueId = 1;
    return {
      restrict: 'E',
      scope: {
        maxScoreValue: '=',
        maxScoreString: '@',
        minScore: '=',
        earnedScore: '=',
        index: '=',
        leftLabel:'@',
        rightLabelString:'@',
        rightLabelValue:'=',
        barClass:'@',
        d:'@',
        filterName:'@'
      },
      replace:true,
      templateUrl: 'app/directives/template/progress_bar.template.html',
      controller: ['$scope','$timeout','$filter', function($scope, $timeout, $filter){
       
       id = 'progress_'+uniqueId++;     
       maxScore = maxScoreString || maxScoreValue;
       rightLabel = rightLabelString || rightLabelValue;

       create = function() {
         $timeout(function () { 
            var elem = document.getElementById(id); 
            var width = 24;
            var max_width = 0;
            var id = null;

            if(isNaN(maxScore) || isNaN(earnedScore)){
                max_width = 0;
            }else{
                max_width = ((earnedScore/maxScore) * 100).toFixed(2);
                if(max_width < 25) max_width =33;
                id = setInterval(frame, 10);
            }

            function frame() {
              try{
                if (width >= max_width) {
                  let v = $filter('number')(earnedScore);
                  if(filterName){
                    v = $filter(filterName)(earnedScore, '$', 0);
                  }
                  elem.innerHTML = v;
                  clearInterval(id);
                } else {
                    width++; 
                    elem.style.width = width + '%'; 
                }
              }catch(e){
                clearInterval(id);
              }
            }
          },0)
        }

      }]
    }
  })*/
