import React, { useState, Fragment, useContext, useEffect} from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'
import DailyDabbleResponseService from "./DailyDabbleResponseService"

const EditResponseModal = ({isOpen, toggleModal, response}) => {

  const [responseText, setResponseText] = useState(response.data.response)
  const [loading, setLoading] = useState(false)

  const validateForm = () =>{
    if(responseText.length > 0){
      return true
    }

    return false
  }

  const updateResponse = () =>{
    response.data.response = responseText
    DailyDabbleResponseService.update(response).then((res)=>{
      if(res.status == 204){
        toggleModal()
      }
    })
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
        
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p12-10-15">Edit</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
        </div>
      
        {loading ? <div className="spinner"></div> : <form id="ct_form" className="ml-container">
          <div className="ml-section">

            <label><b>Description</b></label>
            <textarea maxLength="100" onChange={e=>setResponseText(e.target.value)}
              className="ml-input ml-border" placeholder="Response" 
              defaultValue={responseText} name="message">
            </textarea>

            {validateForm() && 
              <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>updateResponse()}>
                <b>Update</b>
              </div>
            }
        
          </div>
        </form> 
      }
      <ReactTooltip place="bottom" />
    </div>
  </div>
  </Fragment>, document.body))
};

export default EditResponseModal;