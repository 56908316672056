import React, {useEffect} from "react"
import TeamJourney from "./TeamJourney"
import KidsSchoolJourney from "./KidsSchoolJourney"
import MidSchoolJourney from "./KidsSchoolJourney"
import HighSchoolJourney from "./HighSchoolJourney"
import CareerTrainingJourney from "./CareerTrainingJourney"
import CourseJourney from "./CourseJourney"
import CollegeJourney from "./CollegeJourney"
import CommonService from "../../../services/CommonService"
import moment from "../../../../node_modules/moment/min/moment.min.js"
import ReactTooltip from "react-tooltip"
import PreCollegeJourney from "./PreCollegeJourney"
import CareerJourney from "./CareerJourney"

const Journey = (props) => {
  let {journey, currentUser, dataSources} = props
  let eduJourneyType = dataSources.fj_type_edu_jou.options
  let data = journey.education_journey_profiles.data

  journey.updated_at = CommonService.formatUpdateDate(journey.updated_at)
  let subPlan = currentUser?.current_subscription?.name
  if(journey.journey_member_id){
    subPlan = currentUser.journey_members[journey.journey_member_id]?.subscription?.name
  }
  let borderColor = eduJourneyType[parseInt(data?.fj_type_edu_jou)]?.ribbon_edge_color

  const cardStyle = {
    borderTop: "6px solid " + (borderColor || "#27a1fb"),
    marginTop: "10px",
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const listView = (list, label_key) =>
    list && list.map((o, k) => <span className="pill white" style={{backgroundColor: (o.data.icon_color || '#ee6565')}} key={k}>{o.data[label_key]} </span>);

  const fieldView = (list, label_key) =>{
    return (<span className="pill white">{list?.[label_key]} </span>)
  }

  if (!journey.education_journey_profiles) return null;

  if (data) {
    if (data.fj_type_edu_jou == 14) {
      /*Team journey template*/
      journey.journey_type = "edu_team_journey"
      return <TeamJourney cardStyle={cardStyle} subPlan={subPlan} {...props} />
    } else if (data.fj_type_edu_jou == 1) {
      /*Kids journey*/
      journey.journey_type = "edu_kids_journey"
      return <KidsSchoolJourney cardStyle={cardStyle} subPlan={subPlan} {...props} />
    } else if (data.fj_type_edu_jou == 2) {
      /*Mid School journey*/
      journey.journey_type = "mid_school_journey"
      return <MidSchoolJourney cardStyle={cardStyle} subPlan={subPlan} {...props} />
    } else if (data.fj_type_edu_jou == 3) {
      /*High School journey*/
      journey.journey_type = "high_school_journey"
      return <HighSchoolJourney listView={listView} cardStyle={cardStyle} subPlan={subPlan} {...props} />
    } else if (data.fj_type_edu_jou == 4) {
      /*College journey*/
      journey.journey_type = "edu_college_journey"
      return <CollegeJourney listView={listView} cardStyle={cardStyle} subPlan={subPlan} {...props} />
    } else if (data.fj_type_edu_jou == 6) {
      /*Academic Course journey*/
      journey.journey_type = "academic_course_journey"
      return <CourseJourney listView={listView} cardStyle={cardStyle} subPlan={subPlan} {...props} />
    } else if (data.fj_type_edu_jou == 20) {
      /*Training journey*/
      journey.journey_type = "training_journey"
      return <CareerTrainingJourney listView={listView} cardStyle={cardStyle} subPlan={subPlan} {...props}/>
    }else if (data.fj_type_edu_jou == 30) {
      /*Pre College journey*/
      journey.journey_type = "pre_college_journey"
      return <PreCollegeJourney listView={listView} cardStyle={cardStyle} subPlan={subPlan} {...props}/>
    }else if (data.fj_type_edu_jou == 35) {
      journey.journey_type = "career_journey"
      return <CareerJourney listView={listView} fieldView={fieldView} cardStyle={cardStyle} subPlan={subPlan} {...props}/>
    }
  }

  return null;
}

export default Journey;