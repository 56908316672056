import React, {Fragment} from 'react'
import PrivateRoute from "../../../routes/PrivateRoute"
import LayoutFlow from "./TreePath/LayoutFlow"
import ActivityForm from "./Activities/ActivityForm"
import ActivityList from "./Activities/List/ActivityList"
import PlannerForm from "./Planners/PlannerForm"
import PlannerList from "./Planners/PlannerList"
import CareerList from "../CollegeJourney/InterViews/CareerList"

const CareerJourneyRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/career/path/:journey_category_id/:journey_profile_id" component={LayoutFlow} exact />
  	<PrivateRoute path={["/career/activity/form/:journey_category_id/:journey_profile_id/:type/:form_id/:id?", "/career/activity/edit/:id"]} component={ActivityForm} exact />
  	<PrivateRoute path="/career/activities/:journey_category_id/:journey_profile_id" component={ActivityList} exact />
  	<PrivateRoute path="/career/planner/form/:type/:form_id/:id?" component={PlannerForm} exact />
  	<PrivateRoute path="/career/admin/planners" component={PlannerList} permissionType='role_manager.planner' exact />
  	<PrivateRoute path="/career/planners" component={PlannerList} exact />
  	<PrivateRoute path="/career/interviews/:journey_category_id/:journey_profile_id" component={CareerList} exact />
  </Fragment>
)

export default CareerJourneyRouter;