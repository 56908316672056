export default {
  frontend: 'https://edu.beta.cuetree.com/',
  backend: "https://life.admin.cuetree.com/",
  family_tree_backend: "https://life.admin.cuetree.com/family_tree/",
  sports_backend: "https://life.admin.cuetree.com/sports_journey/",
  edu_team_backend: "https://life.admin.cuetree.com/edu_team_journey/",
  edu_kids_backend: "https://life.admin.cuetree.com/edu_kids_journey/",
  mid_school_backend: "https://life.admin.cuetree.com/mid_school_journey/",
  academic_courses: "https://life.admin.cuetree.com/academic_courses/",
  edu_college_journey: "https://life.admin.cuetree.com/edu_college_journey/",
  carrier_training: "https://life.admin.cuetree.com/carrier_training/",
  career_journey: "https://life.admin.cuetree.com/career_journey/",
  socket: "https://www.cuetree.com/faye",
  profile_image_url:"https://selftree-images.s3.amazonaws.com/uploads/user/fj_profile_image/",
  file_url: "https://life.admin.cuetree.com/files?url=",
  chat_bot_backend: "http://54.188.42.159:5005/",
  admin_emails: [
    "info@cuetree.com",
    "author@ipipal.com",
    "skasi@ipipal.com",
    "jaibharathi1991@gmail.com",
  ],
  terms_backend:"https://beta.cuetree.com/"
};

/*export default {
  frontend: 'http://127.0.0.1:3000/',
  backend: 'http://127.0.0.1:8081/',
  family_tree_backend: 'http://127.0.0.1:8081/family_tree/',
  sports_backend: 'http://127.0.0.1:8081/sports_journey/',
  edu_backend: 'http://34.209.108.106:3000/v1/schools/',
  edu_team_backend: 'http://127.0.0.1:8081/edu_team_journey/',
  edu_kids_backend: 'http://127.0.0.1:8081/edu_kids_journey/', 
  mid_school_backend: 'http://127.0.0.1:8081/mid_school_journey/', 
  academic_courses: 'http://127.0.0.1:8081/academic_courses/',
  edu_college_journey: 'http://127.0.0.1:8081/edu_college_journey/',
  carrier_training:"http://127.0.0.1:8081/carrier_training/",
  career_journey: "http://127.0.0.1:8081/career_journey/",
  payment_gateway:'http://127.0.0.1:8081/payment_gateway/',   
  socket:'http://127.0.0.1:9292/faye',
  socket:'https://www.cuetree.com/faye',
  profile_image_url:'https://selftree-images.s3.amazonaws.com/uploads/user/fj_profile_image/',
  file_url:'https://life.admin.cuetree.com/files?url=',
  chat_bot: "http://54.188.42.159:5005",
  admin_emails:['info@cuetree.com', 'author@ipipal.com', 'skasi@ipipal.com', 'jaibharathi1991@gmail.com', 'jaibharathi1992@gmail.com']
}*/