import React from "react"
import moment from 'moment/min/moment.min'
//import Skills from '../../../components/ServiceProvider/List/Skills';
import {Link} from "react-router-dom"
import CommonService from '../../../services/CommonService'

export const Question = (props) => {
  let questionsList = props.questionsList
  let data = questionsList.data
  let newsTags = props.dataSource.news_tags
  let tagColor = props.dataSource

  const cardStyle = {
    borderTop: '3px solid ',
    height: '60px'
  }

  const infoLabel = data.news_tags_array && data.news_tags_array.map((la,i) => {
    let bgColor = tagColor.options[parseInt(la)] && tagColor.options[parseInt(la)].bg_color || 'black'
    return( 
      <div className="pill m-r-15 white" style={{backgroundColor:bgColor}} key={i}>
        {newsTags.options[parseInt(la)].label}
      </div>
    )
  })

  if(data !== null){
    return (
      <div className="card-container shadow-small" >
        <div className="card-header p-5" style={cardStyle}>
          <div className="font-16 black-light-1 ellipsis">
            {data.title_news_item}
          </div>
          <div className="lred">
            {CommonService.formatUpdateDate(data.date_published_article_formatted_date)}
          </div> 
        </div>

        <div className="ht-200 scroll-auto p-5 bg-white">
          <div className="font-12">
            <a className="" href={`${data.news_url}`} target="_blank">
              {CommonService.extractHostName(data.news_url)}
            </a>
          </div>
          <div className="font-15 p-7">{data.prompt_user}</div>
          {infoLabel}
        </div>

        <div className="card-footer p-5">
          <div className="m-r-5 pull-left font-bold">
            <span id={`comment_${questionsList.record_id}`} 
              onClick={(e) => props.addNotes(e, questionsList)}>
              <i className="far fa-comment font-18 text-muted"></i>
            </span>
          </div>
        </div>
      </div>
    )
  }else{
   return null
  }
  
}

export default Question;