import env from '../../env'
import axios from "axios"
import BaseService from "../../services/BaseService"

class SubscriptionAccessesService extends BaseService {
  getUserAccesses(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.backend}user/subscription_accesses`, {params: req}).then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        resolve(e.response)
      })
    })
  }

}

export default new SubscriptionAccessesService('backend', 'subscription_accesses');