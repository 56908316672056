import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import useModal from '../../../../hooks/useModal';
import ConfirmMenu from '../../../Modals/ConfirmMenu'
import JourneyCategoryService from "../../JourneyCategoryService";
import CoursePreparationsService from "../CoursePreparations/CoursePreparationsService";
import useNotification from "../../../../hooks/useNotification"

const PortalHeader = (props) => {
  const {project} = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal();
  const { showNotification } = useNotification()

  const deleteProject = (e) => {
    e.preventDefault();
    setLoading(true)
    JourneyCategoryService.delete(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
        history.push('/journey');
      }
    })
    toggleDeleteModal(e);
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault();
    setLoading(true)
    project.is_chatlist_enabled = !project.is_chatlist_enabled;
    JourneyCategoryService.update(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
      }
    })
  }

  const getActiveUserReport = (e, journey_category_id) =>{
    e.preventDefault();
    setLoading(true)
    let req = {
      'journey_category_id':journey_category_id,
      'type':'active_users'
    }
    CoursePreparationsService.reports(req).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        let activeUsers = res.data.report;
      }
    })
  }

  const cloneJourney = (e, journey_category_id) =>{
    e.preventDefault();
    setLoading(true)
    JourneyCategoryService.cloneJourney(journey_category_id).then((res)=>{
      if(res.status == 200) {
        showNotification('Success', 'Journey Cloned Successfully')
        setLoading(false)
        history.push('/journey');
      }
    })
  }

  const updateStatus = (e, journey_category) =>{
    e.preventDefault();
    setLoading(true)
    let req = {
      'is_journey_completed': !journey_category.is_journey_completed,
      'id': journey_category.id
    }
    JourneyCategoryService.update(req).then((res)=>{
      if (res.status == 204) {
        journey_category.is_journey_completed = !journey_category.is_journey_completed;
        setLoading(false)
      }
    })
  }

  const Menu = () => (
    <span id="portal_menu" className="portlet-dropdown">
      <a data-tip="Adjust Journey Settings" className="p-dropbtn">        <i className="fas fa-bars m-r-15"></i>
</a>
      <div className="p-dropdown-content">
        {project.is_admin_user && 
          <Link to={`/journey_profile/${project.education_journey_profiles.id}?form_id=${project.education_journey_profiles.form_id}`}>
            <i className="far fa-edit"/> Edit
          </Link>
        }

        {project.is_admin_user && 
          <a href="#" onClick={e => {toggleDeleteModal(e, project)} }>
            <i className="far fa-trash-alt"/> Delete
          </a>
        }

        {project.is_admin_user &&
          <Link to={{pathname:`/invite/user/${project.id}/${project.education_journey_profiles.id}`, state:{journey:project} }}>
            <i className="fa fa-user"></i> Invite User
          </Link>
        }

        {project.is_admin_user &&
          <a href="#" onClick={e=>cloneJourney(e, project.id)}>
            <i className="far fa-clone"></i> Create a copy
          </a>
        }
        
        {project.is_admin_user &&
          <a href="#" onClick={e=>updateStatus(e, project)}>
            <i className={`far ${project.is_journey_completed?'fa-check-circle':'fa-circle'}`}></i> Completed
          </a>
        }
        
        {project.is_admin_user && 
          <a href="#" onClick={e=>toggleChatlistStatus(e)}>
            <i className={project.is_chatlist_enabled?'far fa-circle':'far fa-check-circle'}></i> Share chat
          </a>
        }

        <a href="#" onClick={e=>props.app.openHelp(e)}>
          <i className="fas fa-question-circle"></i> Help
        </a>
      </div>
    </span>
  )

  return (
    <div className="portlet-heading clearfix">
      {loading && <div className="spinner"/>}
      <div className="col-xs-12 col-sm-10">
        <h3 className="portlet-title">
          <span>{project.education_journey_profiles.data_source.subject.label } <i className="fas fa-angle-right"/> </span>
          <span>{project.education_journey_profiles.data.record_title}</span>
          <span dangerouslySetInnerHTML={{ __html: project.education_journey_profiles.data.brief_13_brief_profile_of_team_profile }}></span>
        </h3>
      </div>

      <div className="col-xs-12 col-sm-2">
      <span className="text-cap">
        { project.edu_journey_type !== 'null' ? (project.edu_journey_type).replace(/_/g, ' ') :''}
        </span>
        <div className="portlet-widgets">
          <Menu/>
		      <Link data-tip="Back to Journey List" to={{pathname: `/journey`,}}>
            <i className="fas fa-home"></i>
          </Link>
        </div>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
    </div>
  )
}

export default PortalHeader;