import React, {Fragment, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../../hooks/useStyle';
import useSort from '../../../../../hooks/useSorting';
import useModal from '../../../../../hooks/useModal';
import SortDirection from '../../../../../components/Common/SortDirection'
import CollegeProfileService from './CollegeProfileService'
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import {NavIcon} from '../../../../Common/MenuItem'
import {HighSchoolLink} from "../../../../Common/RouteLinks"

let requestParams = {}
let selectedProfile = null;

export const CollegeProfileList = (props) => {
  useStyle('table');  

  let params = props.match.params;
  let journey = props.location.state;

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [collegeProfileList, setCollegeProfileList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState([]);
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();

  useEffect(() => {
    requestParams = {
      'education_journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'paginate':1,
      'per_page':10,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString
    }

    getCollegeProfileList();
  }, [props.searchString, currentpage]);

  let getCollegeProfileList = () => { 
    CollegeProfileService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
        setPagemeta(res.data.meta)
        setCollegeProfileList(res.data)
      }
    })
  }
  const {sort} = useSort(requestParams, getCollegeProfileList);

  const onDelete = (e, profile)=>{
    selectedProfile = profile;
    toggleDeleteModal(e);
  }

  const deleteCollegeProfile = (e) => {
    e.preventDefault();
    CollegeProfileService.delete(selectedProfile.id).then((res)=>{
      if(res.status==204){
        setCollegeProfileList(collegeProfileList.filter(i => i.id !== selectedProfile.id))
      }
    })
    toggleDeleteModal(e);
  }

  const onSearch = (e, profile)=>{
    sessionStorage.activeCollegeProfile = JSON.stringify(profile); 
    props.history.push(`/highschool/college/search/${params.journey_category_id}/${params.journey_profile_id}/${profile.id}`)
  }

  const tableData = collegeProfileList && collegeProfileList.map((o, k) =>
    <tr key={k}>
      <td data-title="Id" className="text-cap">{o.record_id}</td>
      <td data-title="Name" className="text-cap">{o.data.fj_edu_goal_name}</td>
      <td data-title="Subject" className="text-cap">{o.data.subject}</td>
      <td data-title="State" className="text-cap">
        {o.data.list_of_states}
      </td>
      <td data-title="Size" className="text-cap">
        {o.data.college_size}
      </td>
      <td data-title="Type" className="text-cap">
        {o.data.college_preferred_type}
      </td>
      <td data-title="Options">
        <span onClick={e=>onDelete(e, o)}>
          <i className="far fa-trash-alt font-15 lred" aria-hidden="true"></i>
        </span>
        <span onClick={e=>onSearch(e, o)} className="m-l-15">
          <i className="fa fa-search font-15 green" aria-hidden="true"></i>
        </span>
        <span className="m-l-15">
          <Link to={`/highschool/college/profile/${params.journey_category_id}/${params.journey_profile_id}/${o.id}`}>
            <i className="far fa-edit font-15 green" aria-hidden="true"></i>
          </Link>
        </span>
      </td>
    </tr>
  )

  return (
    <Fragment>

    <div className="content top-10">
      <div className="col-md-4 pull-left">
        <JourneyTitle title={"College Admission Profile"} project={journey} />
      </div>
      <div className="col-md-8 pull-right">
        <Link to={HighSchoolLink.hs_portal(params)} className="bg-highlight round-btn float-right m-r-5 m-t-4">
          <i className="fas fa-home"></i>
        </Link>

        <NavIcon id="back_icon" dataTip="Back to Favourite College" 
          onSelect={e=>{props.history.goBack()}}
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          icon="fas fa-arrow-left"/>
      </div>
        <div className="clear"></div>
    </div>

    <div id="no-more-tables" className="col-xs-12 table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')}>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('data.fj_edu_goal_name')}>Name 
              <SortDirection sort_column={requestParams.sort_column} column="data.fj_edu_goal_name" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.subject')}>Subject 
              <SortDirection sort_column={requestParams.sort_column} column="data.subject" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.list_of_states')}>State 
              <SortDirection sort_column={requestParams.sort_column} column="data.list_of_states" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.college_size')}>Size 
              <SortDirection sort_column={requestParams.sort_column} column="data.college_size" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.college_preferred_type')}>Type 
              <SortDirection sort_column={requestParams.sort_column} column="data.college_preferred_type" reverse={requestParams.reverse}/>
            </th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
     <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteCollegeProfile}/>
    </Fragment>
  )

};

export default CollegeProfileList;