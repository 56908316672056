import React, {Fragment} from 'react';
import PrivateRoute from "../../../routes/PrivateRoute";
import PublicRoute from "../../../routes/PublicRoute";
import TrainingModuleList from '../CareerTrainingJourney/TrainingModules/List/TrainingModuleList'
import TrainingModuleForm from '../CareerTrainingJourney/TrainingModules/Form/TrainingModuleForm'
//import TrainingScheduleForm from '../CareerTrainingJourney/Calendars/Form/TrainingScheduleForm'
import PortalDetails from '../CareerTrainingJourney/CareerTrainingPortalPage/PortalDetails'
import StepActivityForm from '../CareerTrainingJourney/StepActivities/StepActivityForm'
//import TrainingSchedules from './Calendars/TrainingSchedules'
import TrainingModuleTypeList from "./TrainingModules/List/TrainingModuleTypeList"
import TrainingTopicForm from "./TrainingTopics/Form/TrainingTopicForm"
import TrainingContentForm from "./TrainingContents/Form/TrainingContentForm"
import CoursePlanner from "./Planners/CoursePlanner"
import Topic from "./TrainingTopics/Topic"
import CourseList from "./TrainingTopics/List/CourseList"
import AssessmentList from "./Assessments/List/AssessmentList"
import AssessmentForm from "./Assessments/Form/AssessmentForm"
import StepAssessmentForm from "./Assessments/Form/StepAssessmentForm"
import QuestionForm from "./Assessments/Form/QuestionForm"
import AssessmentAnswers from "./Assessments/List/AssessmentAnswers"
import ExamList from "./Exams/List"
import CoursePage from "./TrainingTopics/List/CoursePage"
import PlannerCourseList from "./Planners/PlannerCourseList"
import HighSchoolSchedules from '../HighSchoolJourney/Calendars/HighSchoolSchedules'
import EnrollmentForm from './CourseEnrollments/EnrollmentForm'
import EnrollmentList from './CourseEnrollments/EnrollmentList'

const CareerTrainingRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/career_training/schedules/:journey_category_id/:journey_profile_id/:journey_type" component={HighSchoolSchedules} exact/>
    <PrivateRoute path="/admin/course/modules" setHelpId={props.setHelpId} searchString={props.searchString} component={TrainingModuleTypeList} permissionType='role_manager.training_content' exact />    
    <PrivateRoute path="/career_training/modules/:journey_category_id?/:journey_profile_id?/:course_id?" component={Topic} exact/>
    <PrivateRoute path="/career_training/modules/list/:journey_category_id?/:education_journey_profile_id?" searchString={props.searchString} component={TrainingModuleList} exact />
    <PrivateRoute path="/career_training/module/:type/:form_template_id?/:id?" component={TrainingModuleForm} exact />
    <PrivateRoute path="/career_training/topic/:type/:form_template_id?/:id?" component={TrainingTopicForm} exact />
    <PrivateRoute path="/career_training/step_activity/form/:journey_category_id/:journey_profile_id/:activity_id/:form_template_id/:activity_type/:id?" component={StepActivityForm} exact />
    <PrivateRoute path="/career_training/project/portal/:journey_category_id?" app={props.app} component={PortalDetails} exact style="org_details"/>
    <PrivateRoute path="/career_training/content/:type/:form_template_id?/:id?" component={TrainingContentForm} exact/>
    <PrivateRoute path="/career_training/planner/:journey_category_id/:journey_profile_id/:course_id?" searchString={props.searchString} component={CoursePlanner} exact/>
    <PrivateRoute path="/career_training/topic_page/:journey_category_id/:journey_profile_id/:topic_id" component={Topic} exact/>
    <PrivateRoute path="/career_training/assessments/:journey_category_id/:journey_profile_id/:course_id?" component={AssessmentList} exact/>
    <PrivateRoute path="/career_training/assessment/form/:journey_category_id/:journey_profile_id/:course_id?/:id?" component={AssessmentForm} exact/>
    <PrivateRoute path="/career_training/step_assessment/form/:journey_category_id/:journey_profile_id/:assessment_id/:id?" component={StepAssessmentForm} exact/>
    <PrivateRoute path="/career_training/question/:journey_category_id/:journey_profile_id/:assessment_id/:id?" component={QuestionForm} exact/>
    <PrivateRoute path="/career_training/answers/:journey_category_id/:journey_profile_id/:assessment_id" component={AssessmentAnswers} exact/>
    <PrivateRoute path="/career_training/exams/:journey_category_id/:journey_profile_id" component={ExamList} exact/>
    <PrivateRoute path="/career_training/courses/:journey_category_id?/:journey_profile_id?" app={props.app} component={CourseList} exact/>
    <PrivateRoute path="/career_training/course_page/:id" component={CoursePage} exact/>
    <PublicRoute path="/public/career_training/course_page/:id" component={CoursePage} exact/>
    <PrivateRoute path="/career_training/planners/courses/:journey_category_id/:journey_profile_id" searchString={props.searchString} component={PlannerCourseList} exact/>
    <PrivateRoute path={["/career_training/enrollment/:journey_category_id/:journey_profile_id/:course_id/:user_form_id", "/career_training/enrollment/:user_form_id/:id"]} component={EnrollmentForm} exact />
    <PrivateRoute path="/career_training/list/enrollment/:journey_category_id/:journey_profile_id/:course_id" component={EnrollmentList} exact />
  </Fragment>
)

export default CareerTrainingRouter;

/*
<PrivateRoute path="/career_training/activity/:journey_category_id/:journey_profile_id/:journey_type/:id?" component={TrainingScheduleForm} exact />
<PrivateRoute path="/career_training/schedules/:journey_category_id/:journey_profile_id/:journey_type" component={TrainingSchedules} exact/>
*/