import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import HelpList from '../../components/Help/List/HelpList'
import HelpForm from '../../components/Help/Form/HelpForm'

const HelpRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/help" exact >
      <HelpList searchString={props.searchString}/>
    </PrivateRoute>
    <PrivateRoute path="/help/create/:id?" component={HelpForm} exact />
  </Fragment>
)

export default HelpRouter;