import React, {Fragment} from 'react'
import PrivateRoute from '../../routes/PrivateRoute'
import SubscriptionsTable from './SubscriptionsTable'
import SubscriptionForm from './SubscriptionForm'
import SubscriptionUsersTable from './SubscriptionUsersTable'

const Router = (props) => (
  <Fragment>	
    <PrivateRoute path="/list/subscriptions" component={SubscriptionsTable} searchString={props.searchString} exact />
    <PrivateRoute path="/subscription/create/:id?" component={SubscriptionForm} exact />
    <PrivateRoute path="/subscriptions/:marketing/:id?" component={SubscriptionForm} exact />
    <PrivateRoute path="/subscription/users/:subscription_id" component={SubscriptionUsersTable} exact />
  </Fragment>
)

export default Router;