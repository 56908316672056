import React, { Fragment,useContext } from "react"
import env from "../../../env"
import useModal from "../../../hooks/useModal"
import Cs from "../../../services/CommonService"
import ActivityTypeMenu from "../HighSchoolJourney/Activities/List/ActivityTypeMenu"
import JourneyCategoryService from "../JourneyCategoryService"
import PortalPath from "../HighSchoolJourney/HighSchoolPortalPage/PortalPath"
import CheckAccess from "../Roles/CheckAccess"
import GenericModal from "../../Modals/GenericModal"
import { Link, useHistory } from "react-router-dom"
import AddToFavourite from "../../Common/AddToFavourite"
import { HamburgerMenuIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons"

let portalPath = null
let journeyCategory = null
let statics = { report: {} }

const HighSchoolJourney = (props) => {
  const history = useHistory()

  let {journey, users, currentUser, cardStyle, subPlan} = props
  let {data, data_source} = journey.education_journey_profiles
  let dsource = props.dataSources.fj_type_edu_jou.options
  const currentGrade = data?.fj_edu_current_grade
  const carrierInterestId = data?.goals?.career_interest?.single_goal_id
  const subjectInterests = data.goals?.subject_interest?.multi_goals?.split(',')

  const {isOpen: isMenuOpen, toggleModal: toggleMenu} = useModal()
  const {isOpen: isEduActivityMenuOpen, toggleModal: toggleEduActivityMenu} = useModal()
/*
<div>
        <i className={dsource[key].icon + ' m-r-10'} style={{color: dsource[key].icon_color ? dsource[key].icon_color :'black'}}></i>
        {dsource[key].label}
      </div>
*/
  /*
<span className="pull-right m-r-15" data-tip="Add Activity" 
              onClick={(e) => toggleEduActivityMenu(e)}>
              <i className="fas fa-plus" />
            </span>
  */
  const category = (key) => {
    return (
      <div className="card-title flex justspacebetween m-t-20 p-b-15">
        <div className="flex aligncenter">
          <div className="card-icon-small border-rounded card-icon-blue-bg flex aligncenter justifycenter m-r-15">
            <i className={dsource[key].icon} style={{color: dsource[key].icon_color ? dsource[key].icon_color :'black'}}/>
          </div>
          <div>
            <h4 className="fw700 f14 card-heading-blue">
              {dsource[key].label}
            </h4>
          </div>
        </div>
      </div>
    )
  }

  const route = () =>{
    /*if(!CheckAccess.isDevUser(currentUser.email, env) && !journey.is_demo_journey && !journey.subscription_id){
      history.push({
        pathname:`/plans/${journey.id}`+(journey.journey_member_id?`/${journey.journey_member_id}`:''),
        search: '?journey_type='+(data?.fj_type_edu_jou),
        state:{journey:journey}
      })
    }else{
      history.push(`/highschool/path/${journey.id}/${journey.education_journey_profiles.id}?current_grade=${currentGrade}&carrier_interests=${carrierInterestId}`, journey)
    }*/
    history.push(`/highschool/path/${journey.id}/${journey.education_journey_profiles.id}?current_grade=${currentGrade}&carrier_interests=${carrierInterestId}`, journey)
  }

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 p20" style={cardStyle}>
        <div onClick={()=>Cs.routeJourney(journey, {history:history}, false, currentUser)}
          className="card-title flex aligncenter justspacebetween border-dashed-sm p-b-15">
          <div className="flex aligncenter justifycenter">
            <div className="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
              <img src={Cs.getIconByType(users[journey.created_by].profile_image, 'profile_image')} className="icon-gray" alt="" width="50"/>
            </div>
            <div>
              <h4 className="fw700 card-heading">{data.fj_name_of_family_member}</h4>
              <p className="text-gray f12">since {journey.updated_at}</p>
              <p className="text-gray f12">by {users[journey.created_by].name}</p>
            </div>
          </div>
          <div>
            <div id="route_portal_page" className="menu-drop-icon flex justifycenter aligncenter m-7">
              <img src="./images/arrow-right.png" alt=""/>
            </div>
            <div id="add_favorite" className="menu-drop-icon flex justifycenter aligncenter m-7"> 
              <AddToFavourite favouriteId={journey.favourite_id} 
                favorableType="journey_category" 
                favorableId={journey.id} journeyCategoryId={journey.id} 
                classNameActive="pin-0 green" 
                classNameDisable="pin-0 lgrey"
                journeyProfileId={journey.education_journey_profiles.id}/>
            </div>
          </div>
        </div>
        {category(data.fj_type_edu_jou)}
        <div className="card-content">
          <div className="flex coldir">
            {data_source &&
              <div className="m-b-15">
                <span className="fw500">Subject of Most Interest: </span> 
                {data.goals?.career_interest?.single_goal}
              </div>
            }
            <div className="m-b-15">
              <span className="fw500">Grade: </span> 
              {data.fj_edu_current_grade}
            </div>
            {data_source && 
              <div className="m-b-15">
                <span className="fw500">Interest: </span>  
                {subjectInterests?.map((s, i)=>
                  <span key={i} className="badge badge-lite-red">{s}</span>
                )}
              </div>
            }
            {journey.journey_member &&
              <div className="m-b-15">
                <span className="fw500">Member: </span>  
                <span className="font-14">{journey.journey_member?.member_name}</span>
              </div>
            }
            {subPlan &&
              <div className="m-b-15">
                <span className="fw500">Plan: </span>  
                <span className="font-14">{subPlan}</span>
              </div>
            }
            {journey.last_activity_updated_at && 
              <div className="m-b-15 fw500">
                <span className="fw500">Last updated: </span>  
                <span className="font-14">{Cs.formatUpdateDate(journey.last_activity_updated_at)}</span>
              </div>
            }
          </div>
        </div>
        <div className="card-footer flex justspacebetween aligncenter">
          <ul className="flex aligncenter">
            <li id="add_activity" className="m-r-25" data-tip="Add Activity">
              <LinkFontAwesomeIcon css="fas fa-plus white" onSelect={() => toggleEduActivityMenu()}/>
            </li>
            <li className="m-r-25 red f14 bold-600">
              {journey.parent_journey_category_id ?
                <div>This is a copy. Click the menu and edit/update the name now.</div>
                :
                null
              }
            </li>
          </ul>
          <HamburgerMenuIcon id="card_menu" onSelect={(e)=>props.addMenu(e, journey)}/>
        </div>
      </div>
 
      {isEduActivityMenuOpen && 
        <ActivityTypeMenu journey_category_id={journey.id}
          education_journey_profile_id={journey.education_journey_profiles.id}
          isOpen={isEduActivityMenuOpen} toggleModal={toggleEduActivityMenu}/>
      }

      {isMenuOpen && 
        <GenericModal component={PortalPath} title="Menu" isOpen={isMenuOpen}
          portalPath={portalPath} routePath={Cs.routeJourney} statics={statics}
          toggleModal={toggleMenu} currentUser={currentUser}/>
      }

    </Fragment>
  )
}

export default HighSchoolJourney;