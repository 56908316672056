import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormContainer from '../../../components/FormBuilder/FormContainer'
import FormTemplateId from '../../../constants/FormTemplateList'
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService"

let formMode = null 
let formId = null 
let digitalContent = {}
let digitalContentType = null 
let data = {}
let formFn = {}  
let DigitalContentFormTemplateId = {}
let requestParams = {}

function DigitalContentForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let currentUser = authState.user
  
  /*TODO: Move API to Router to load data before view*/
   const getById = (id) => {
    DigitalContentService.get(id).then((res) => {
      if(res.status==200){
        digitalContent = res.data.digital_content
        data = digitalContent.data || {}
        DigitalContentFormTemplateId = digitalContent.content_form_id
        setFormRendered(true)
      }
    }).catch(function(res){
      digitalContent = null
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions'
    data = {}
    digitalContent = {} 
    //DigitalContentFormTemplateId = {};
    digitalContentType = null
    formFn = {'form_type':'digital_content', 'editPermission':true}
    requestParams = {}
    DigitalContentFormTemplateId = parseInt(params.formId)
    digitalContentType = params.type
   
    if(params.id){
      formMode = 'edit-form-submissions'
      getById(params.id)
    }else{
      setFormRendered(true)
    }
  }, [])

  const create = (digitalContent) => {
    digitalContent.created_by = currentUser.id
    digitalContent.content_type = digitalContentType
    digitalContent.content_form_id = DigitalContentFormTemplateId
    
    DigitalContentService.create(digitalContent).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (digitalContent) => {
    DigitalContentService.update(digitalContent).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect(digitalContent.journey_category_id)
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = (id) => {
    if(props.isPopupMode){
      props.toggleModal()
      props.onSuccess()
    }else{
      props.history.push("/digital_contents")  
    }
  }

  const onCancel = (id) => {
    if(props.isPopupMode){
      props.toggleModal()
    }else{
      props.history.push("/digital_contents")  
    }
  }

  if(isFormRendered){
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode} formId={DigitalContentFormTemplateId} 
          form={digitalContent} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onCancel} 
          setPost={setPost} />
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default DigitalContentForm;