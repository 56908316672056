import React, { useEffect, Fragment, useRef ,useState, useContext} from "react";
import { Link } from "react-router-dom";
import useStyle from "../../hooks/useStyle";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../services/CommonService";
import PreLoadSpinner from "../../components/PreLoadSpinner";

let requestParams = {};

const NewsletterSnippet = (props) => {
  let isSubscribedCookie = CommonService.getCookie('ct_subscribed')

  const initialState = {
    email: "",
    isSubmitted: false,
    message: null,
  }

  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleNewsletterSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setData({
      ...data,
      message: null,
    });
    let req = { email: data.email };
    CommonService.postNewsletter(req).then((res) => {
      if (res.status == 201) {
        setLoading(false);
        setData({
          ...data,
          isSubmitted: true,
          message: 'Thank you for registering with cueTree Education. When registration is effective, you should receive periodic email notifications.',
        })
        CommonService.setCookie('ct_subscribed', true, (365 * 10))
      } else {
        setLoading(false);
        setData({
          ...data,
          isSubmitted: true,
          message: res.data.error,
        })
        CommonService.setCookie('ct_subscribed', true, (365 * 10))
      }
    }).catch((error) => {
      setLoading(false);
      setData({ ...data, message: error.response.data.error });
    })
  }

  if(loading)
    return(<PreLoadSpinner/>)
  

  if(isSubscribedCookie)
    return null

  return (
    <Fragment>
      <section className="cta">
        <div className="cta-item">
          <div className="cta-text text-left">
            {data.isSubmitted && <p>{data.message}</p>}
            {!data.isSubmitted && 
              <Fragment>
                <h2>Register today for more access </h2>
                <p>
                  Register your email today to get access to a collection of Daily Cues, news links, and be notified of cueTree Education features and benefits.
                </p>
              </Fragment>
            }
          </div>
          {!data.isSubmitted && 
            <Fragment>
              <form className="cta-form mt-2">
                <input
                  className="input-block"
                  type="email"
                  placeholder="example@email.com"
                  name="email"
                  onChange={handleInputChange}/>
                <button className="btn-block btn-2" onClick={handleNewsletterSubmit}>Register now</button>
              </form>
            </Fragment>
          }
        </div>
      </section>
      <section className="contact-info"/>
    </Fragment>
  )
}

export default NewsletterSnippet;