import React, { Fragment, useState, useEffect, useContext} from 'react'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import AuthenticationService from '../../services/AuthService'
import SubscriptionAccessesService from './SubscriptionAccessesService'
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from '../../env'
import SubscriptionService from "./SubscriptionService"
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"

let selectedItem = null
const SubscriptionAccesses = (props) => {
  useStyle('table')

  const {subscription} = props

  const { state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  let [selectedComponents, setSelectedComponents] = useState({})
  let [components, setComponents] = useState({})

  const {isOpen:isLevelInfoModalOpen, toggleModal:toggleLevelInfoModal} = useModal()

  useEffect(() => {
    selectedItem = null
    getAccessComponents()
  }, [])

  const getAccessComponents = () =>{
	  setLoading(true)
  	const req = {
  	  subscription_id: subscription.id,
  	}
    SubscriptionAccessesService.getAll(req).then(({status, data})=>{
      if(status == 200){
        setSelectedComponents(data.subscription_accesses)
		    setComponents(data.journey_components)
        setLoading(false)
      }
    })
  }

  const createOrRemove = (selected_component, component, component_data_source_id, level, level_data_source_id) =>{
  	if(selected_component){
  	  SubscriptionAccessesService.delete(selected_component.id).then((res)=>{
	      if(res.status == 204){
	        setSelectedComponents(
            selectedComponents.filter(i => i.id !== selected_component.id)
          )
	      }
	    })	
  	}else{
  		const req = {
	  	  'subscription_id':subscription.id,
	  	  'component_sid':component.sid,
	      'data_source_id':component_data_source_id,
	      'data_source_list_id':component.pk_id,
        'level_data_source_id':level_data_source_id,
        'level_data_source_list_id':level.pk_id,
        'level_sid':level.sid,  
	  	}
	    SubscriptionAccessesService.create(req).then((res)=>{
	      if(res.status == 201){
	        setSelectedComponents([...selectedComponents, res.data.access])
	      }
	    })
  	}
  } 

  const enableMulipleComponents = (components, level, level_data_source_id) =>{
    const requests = []
    components.options.forEach((c)=>{
      requests.push({
        'subscription_id':subscription.id,
        'component_sid':c.sid,
        'data_source_id':components.id,
        'data_source_list_id':c.pk_id,
        'level_data_source_id':level_data_source_id,
        'level_data_source_list_id':level.pk_id,
        'level_sid':level.sid,  
      })
    })

    SubscriptionAccessesService.create({
      bulk_insert:true,
      records:requests
    }).then((res)=>{
      if(res.status == 201){
        setSelectedComponents([...selectedComponents, ...res.data.access_list])
      }
    })
  }

  const exists = (component_pk_id, component_data_source_id, level_pk_id, level_data_source_id) =>{
    const i = selectedComponents.find(s => (
      s.data_source_id == component_data_source_id 
      && s.data_source_list_id == component_pk_id
      && s.level_data_source_id == level_data_source_id
      && s.level_data_source_list_id == level_pk_id
    ))

    return i
  }

  const openLevelInfoModal = (c) =>{
    selectedItem = c
    toggleLevelInfoModal()
  }

  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return(
    <Fragment>
  	<div className="p-15">
      {subscription?.data_source?.journey_types?.map((s, i) =>
        <div className="table-responsive table-fix-head p-0 ht-600" key={i}>
          <table className="shadow-small">
            <thead>
              <tr>
                <th className="p-2 bold-400 text-center w-95">
                  Features
                </th>
                <Tablehead components={components[subscription.data.stage_data_source_id]?.options}/>
                <th className="text-center w-95">
                  Stage Info
                </th>
              </tr>
            </thead>
            <tbody className="sub-cmp">
              <TableBody journeyType={s} 
                components={components[3546]} 
                createOrRemove={createOrRemove}
                openLevelInfoModal={openLevelInfoModal}
                selectedComponents={selectedComponents}
                levelComponents={components[subscription.data.stage_data_source_id]}
                enableMulipleComponents={enableMulipleComponents}
                exists={exists}/>

              <TableBody journeyType={s} 
                components={components[s.data.path_nodes]} 
                createOrRemove={createOrRemove}
                openLevelInfoModal={openLevelInfoModal}
                selectedComponents={selectedComponents}
                levelComponents={components[subscription.data.stage_data_source_id]}
                enableMulipleComponents={enableMulipleComponents}
                exists={exists}/>

              <TableBody journeyType={s} 
                components={components[s.data.portal_nodes]} 
                createOrRemove={createOrRemove}
                openLevelInfoModal={openLevelInfoModal}
                selectedComponents={selectedComponents}
                levelComponents={components[subscription.data.stage_data_source_id]}
                enableMulipleComponents={enableMulipleComponents}
                exists={exists}/>

              <TableBody journeyType={s} 
                components={components[s.data.activity_cat]} 
                createOrRemove={createOrRemove}
                openLevelInfoModal={openLevelInfoModal}
                selectedComponents={selectedComponents}
                levelComponents={components[subscription.data.stage_data_source_id]}
                enableMulipleComponents={enableMulipleComponents}
                exists={exists}/>
            </tbody>
          </table>
        </div>
      )}
    </div>
    {isLevelInfoModalOpen &&  
      <GenericModal component={LevelInfo} title={selectedItem.label}
        isOpen={isLevelInfoModalOpen} toggleModal={toggleLevelInfoModal}  
        subscription={subscription} 
        dataId={selectedItem.pk_id}/>
    }
    </Fragment>
  )
}

const Tablehead = ({components=[]}) => components.map((s, k) =>{
  return(
    <Fragment key={k}>
      <th className="text-center w-95">
        <div className="bold-400 p-2">{s.label}</div>
      </th>
    </Fragment>
  )
})

const TableBody = (props) => {
  const {components, levelComponents, enableMulipleComponents} = props
  if(!components)return null;

  return(
    <Fragment>
      <tr>
        <td className="text-left font-15 bold-600">
          {components.label}
        </td>
        {levelComponents?.options?.map((l, k) =>
          <td>
            <span onClick={()=>enableMulipleComponents(components, l, levelComponents.id)} 
              className="fas fa-tasks lgrey m-r-15"/>          
          </td>
        )}
      </tr>       
      <TableBodyRows {...props}/> 
      <tr className="br-btm-grey"></tr>
    </Fragment>
  )
}

const TableBodyRows = (props) => props.components?.options?.map((o, k) =>{
  return(
    <tr key={k}>
      <td className="text-left p-l-30">
        <div className="font-16">{o.label}</div>
      </td>
      <TableBodyCol component={o} componentDataSourceId={props.components.id} {...props}/>
      <td className="text-center">
        <span className="fas fa-sticky-note" onClick={()=>props.openLevelInfoModal(o)}/>
      </td>
    </tr>
  )
})

const TableBodyCol = ({component, selectedComponents, levelComponents, createOrRemove, componentDataSourceId, exists}) => levelComponents?.options?.length>0 ? levelComponents.options.map((l, k) =>{
  const checkedItem = exists(component.pk_id, componentDataSourceId, l.pk_id, levelComponents.id)

  return(
    <Fragment key={k}>
      <td className="text-center" onClick={e=>createOrRemove(
          checkedItem, component, componentDataSourceId, l, levelComponents.id
        )}>
        <span className={`fas ${checkedItem?'fa-check-circle':'fa-circle'} lgrey m-r-15`}/>
      </td>
    </Fragment>
  )
}):null

const JourneyType = ({journeyType, journeyComponent, createOrRemove, selectedComponents}) => {
  if(!journeyComponent) return null;

  return (
    <div className="m-t-5 br-btm-grey">
      <h5>{journeyComponent.label}</h5>
      <AccessList journeyType={journeyType} 
        dataSourceId={journeyComponent.id}
        componentList={journeyComponent.options}
        createOrRemove={createOrRemove}
        selectedComponents={selectedComponents}
      />
      <span className="font-9">{journeyComponent.id}</span>
    </div>
  )
}

const AccessList = ({journeyType, componentList, createOrRemove, selectedComponents, dataSourceId}) => componentList?.map((c, i) => {
  const component = selectedComponents[c.pk_id]
  return(
    <div key={i} className="m-b-5" onClick={e=>createOrRemove(component, c, dataSourceId)}>
      <span className={component?'font-20 far fa-check-square text-muted':'font-20 far fa-square text-muted'}/>
      <span className="m-l-15 font-14">{c.label}</span>
    </div>
  )
})

const LevelInfo = ({subscription, dataId, toggleModal}) =>{
  let [data, setData] = useState(subscription.level_data || {})

  const onInputChange = (value) =>{
    setData({...data, [dataId]:value})
  }

  const onSubmit = () =>{
    SubscriptionService.update({
      id:subscription.id,
      level_data:data
    }).then(({status})=>{
      if(status==200){
        toggleModal()
      }
    })
  }

  return(
    <div className="p-5">
      <textarea className="form-control input-sm" rows="4" cols="50"
        onChange={e=>onInputChange(e.target.value)}
        defaultValue={data[dataId]}/>  
      
      <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={()=>onSubmit()}>
        <b>Update</b>
      </div>
    </div>
  )

}

export default SubscriptionAccesses;