import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'

const ConfirmMenu = ({ isOpen, toggleModal, success, message, title }) => isOpen ? ReactDOM.createPortal(
  <Fragment>
    <div className="menu-hider menu-active d-block trf-0"/>  
    <div id="menu-confirm" className="menu menu-box-modal round-medium menu-active modal-xs nonbold br-grey br-lft2">
        <h1 className="center-text uppercase top-30 font-16">{title ? 'Are you sure?':''}</h1>
        <p className="boxed-text-large font-18">
            {message || 'Want to delete this item?'}
        </p>
        <div className="confirm_content left-20 right-20 m-t-10">
            <div className="one-half">
                <a href="#" className="close-menu button button-center-large button-s button-round-small black nonbold underline"
                onClick={ e => {success(e)} }>
                    YES
                </a>
            </div>
            <div className="one-half last-column">
                <a href="#" className="close-menu button button-center-large button-s button-round-small black nonbold underline"
                onClick={ e => {toggleModal(e)} }>
                    NO
                </a>
            </div>
            <div className="clear" />
        </div>
    </div>
  </Fragment>, document.body
) : null;

export default ConfirmMenu;