import React, {Fragment, useState, useEffect} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import useModal from '../../hooks/useModal'
import {Link} from "react-router-dom"
import CommonService from '../../services/CommonService'
import moment from '../../../node_modules/moment/min/moment.min.js'
import NotificationPopup from "../../components/Common/NotificationPopup"
import WelcomeProfileSetupModal from "./WelcomeProfileSetupModal"

let userTypeList = null
let modalHeaderColor = null

const Welcome = (props) => {
  let params = props.match.params
  useStyle('card')

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext)
  const {isOpen:isUserTypeOpen, toggleModal:toggleUserTypeModal} = useModal()
  
  useEffect(() => {
    try{getDemoUserType()}catch(e){}  
  }, [])
   
  if(!currentUser.invite_user)return null
  
  let expireDate = moment(currentUser.invite_user.updated_at).add(7, 'days').format('MMM DD, YYYY')
  let currentDate =  moment()
  let accountActiveDays = moment(currentUser.invite_user.updated_at).diff(currentDate, 'days')  // =1

  if(accountActiveDays == -6){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Demo Account Expired" message="Demo Account will be active for 7 Days..."/>)
  }

  const getDemoUserType = () => { 
    let requestParams = {
      "data_source_id":[2541, 3351, 3350],
    }
    CommonService.getDataSource(requestParams).then((res)=>{
      userTypeList = res.fj_i_am
    })
  }

  const updateDemoJourneyUserType = (e) =>{
    e.preventDefault()
    let req = {
      'user_type': currentUser.domain_info.data_source.fj_your_role.sid,
      'invite_id': currentUser.invite_user.id
    }
    CommonService.createDemoJourney(req).then((res)=>{
      if(res.status == 201){
        props.history.push('/journey')
      }
    })
  }

  const routeDemoUser = (e) =>{
    if(currentUser.invite_user.is_demo_journey_created){
      e.preventDefault()
      props.history.push('/journey')
    }else{
      modalHeaderColor = 'rgb(254, 174, 0)'
      toggleUserTypeModal(e)  
    }
  }

  const routeRegularUser = (e) =>{
    e.preventDefault()
    let req = {'admin_role_name': 'member'}
    CommonService.updateRoleName(req, currentUser.current_admin.id).then((res)=>{
      if(res.status == 201){
        props.history.push('/journey')
      }
    })
  }

  const InvitationCard = () =>{
    const cardStyle = {
      borderTop: '5px solid red',
      minHeight: '240px'
    }

    return(
    <Fragment>
      <div className="content-boxed shadow-small" style={cardStyle}>
        <div className="content vcard-header">
          <ul className="d-flex">
            <li>
            
            </li>
            <li className="m-l-15">
              <Link className="black-light-1" to="/welcome">
                <div className="vcard-title black-light-1">This Invitation</div>
                <span> From {currentUser.invite_user.invited_user.name}, {CommonService.formatUpdateDate(currentUser.invite_user.updated_at)}</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="content font-14 clearfix">
          Hi, I am using cueTree, a new collaboration and guided instruction
          application for students and educators.
          Check it out and connect with me.
          <div className="m-t-5 m-b-5"/>
          You are using a temporary cueTree Education account, which gives access
          to some demo features. This account will expire on {expireDate}. 
        </div> 
      </div>
    </Fragment>
    )
  }

  const RegularAccountCard = () =>{
    const cardStyle = {
      borderTop: '5px solid #05b172',
      minHeight: '240px'
    }

    const btnStyle = {
      backgroundColor: '#05b172',
      color:'white',
      fontWeight: 'bold'
    }

    return(
      <Fragment>
        <div className="content-boxed shadow-small" style={cardStyle}>
          <div className="content text-center font-16 font-500">
            <div>Use as a Regular Account </div>
          </div>
          <div className="content clearfix p-t-28">
            <div className="font-14">
              Click the button below to make this a regular account that you can use as long as you want. You will be asked to
              update account details and create your own password, You will then be able to access the features of cueTree
              Education to create and join individual and team journeys,.
            </div>
            <a href="/" onClick={e=>routeRegularUser(e)} className="ml-button pull-right" style={btnStyle}>Regular Account</a>
          </div> 
        </div>
    </Fragment>
    )
  }
  
  const DemoAccountCard = () =>{
    const cardStyle = {
      borderTop: '5px solid #FEAE00',
      minHeight: '240px'
    }

    const btnStyle = {
      backgroundColor: '#FEAE00',
      color:'white',
      fontWeight: 'bold'
    }

    return(
      <Fragment>
        <div className="content-boxed shadow-small" style={cardStyle}>
          <div className="content text-center font-16 font-500">
            <div>Check out these Demo Journeys</div>
          </div>
          <div className="content clearfix m-t-5 p-t-28">
            <div className="font-14">
              Click the button below to visit a Journey dashboard where you can 
              view available Demo Journeys and interact with them. You can invite
              others to join your demo journey. You can delete your demo journey when
              you are done exploring the trial features.
            </div>
            <a href="#" onClick={e=>updateDemoJourneyUserType(e)} className="ml-button pull-right" style={btnStyle}>Demo Journeys</a>
          </div> 
        </div>
      </Fragment>
    )
  }
  
  return (
    <Fragment>
      <div className="content top-10">
        <h4 className="float-left font-500 m-t-5">Welcome to cueTree Education</h4>
        <div className="clear"></div>
      </div>
      <div className="page-content row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <InvitationCard/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <RegularAccountCard/>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
          <DemoAccountCard/>
        </div>
      </div>
    </Fragment>
  )

}

export default Welcome;

/*
{isUserTypeOpen && <WelcomeProfileSetupModal isOpen={isUserTypeOpen} toggleModal={toggleUserTypeModal} headerColor={modalHeaderColor}
  title="Please Select your high school role" onUpdate={updateDemoJourneyUserType} />}

<img src={CommonService.getIconByType(data.image_for_ribbon, 'team_journey')} 
className="rounded-circle bg-highlight" width="50"/>
*/