import React, { Fragment, useState, useEffect, useRef, useContext} from "react"
import ReactDOM from "react-dom"
import { useHistory, useLocation, Link, useRouteMatch } from "react-router-dom"
import useStyle from "../../hooks/useStyle"
import HelpSideNav from "../../components/Help/HelpSideNav"
import NotesModal from "../../components/Modals/NotesModal"
import useModal from "../../hooks/useModal"
import {usePageTour, setPageTour} from "../../components/Common/usePageTour"
import InviteModal from "../../components/Modals/InviteModal"
import env from "../../env"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"

const SideMenuBar = (props) => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch();

  let tour = usePageTour(true)

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  const [isSideMenuOpen, setSideMenuOpen] = useState(false)
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pageTours, setPageTours] = useState([])
  const [pageTourData, setPageTourData] = useState([])

  let {isOpen: isPageNoteOpen, toggleModal: togglePageNoteModal } = useModal()
  let {isOpen: isInviteUserPopupOpen, toggleModal: toggleInviteUserPopup,} = useModal()

  useEffect(() => {
    if (isSideMenuOpen) {
      setIsHelpOpen(false)
      if(pageTours.length === 0){
        getDataSource(3578, true)
      }
    }
  }, [isSideMenuOpen])

  const getDataSource = (ds, is_tour_list) =>{
    setLoading(true)
    let req = {
        'data_source_id':[ds], 
        'per_page' : 30
    }
    Cs.getDataSource(req).then((data)=>{
      if(data){
        if(is_tour_list){
          setPageTours(data.pages_screen_guide)          
        }else{
          setPageTourData(data)
          openTour(data, Object.keys(data)[0])
        }
      }
      setLoading(false)
    })
  }

  const openFeedback = (e) => {
    history.push(`/feedback?url=${location.pathname}`)
  }

  const findPageTourData = () =>{
    const pageId = match.path.split("/:")[0];
    const dataId = pageTours.options.find((o) => o.url === pageId)
    if(dataId?.child_ds)
      getDataSource(dataId.child_ds)
  }

  const openTour = (tours, key) => {
    tours[key].options.forEach((t)=>{
      setPageTour(key, {
        id:t.sid,
        title:t.label,
        description:t.popover
      })
    })
    setTimeout(function () {
      tour && tour.init(true, key)
    }, 1000)
    setSideMenuOpen(!isSideMenuOpen)
  }

  if (props.app) {
    props.app.openHelp = (e) => {
      e.preventDefault()
      setIsHelpOpen(!isHelpOpen)
    }
  }

  if(loading)
    return <div className="spinner"/>;

  if (isSideMenuOpen) {
    const style = { padding: "12px", borderRadius: "10px" }
    return ReactDOM.createPortal(
      <Fragment>
        <div className="side-menu-bar" style={style}>
          <div onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
            <i className="fas fa-arrow-right"/>
          </div>
          
          {!currentUser && 
            <Fragment>
              <div className="element"
                onClick={(e) => {toggleInviteUserPopup(e);setSideMenuOpen(!isSideMenuOpen);}}>
                Share With Others
              </div>

              <Link className="element white" to="/signup"
                onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
                  Sign Up
              </Link>

              <Link className="element white" to="/signin"
                onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
                  Sign In
              </Link>
            </Fragment>
          }

          {currentUser && <Fragment>
              <div className="element"
                onClick={(e) => {setIsHelpOpen(!isHelpOpen);setSideMenuOpen(!isSideMenuOpen);}}>
                Help
              </div>
              <div className="element"
                onClick={(e) => {openFeedback(e);setSideMenuOpen(!isSideMenuOpen);}}>
                Feedback
              </div>
              <div className="element" onClick={(e) => {togglePageNoteModal(e);setSideMenuOpen(!isSideMenuOpen);}}>
                My Notes
              </div>
              <div className="element-dotted"
                onClick={(e) => {findPageTourData();setSideMenuOpen(!isSideMenuOpen);}}>
                Screen Guide
              </div>
              {env.admin_emails.includes(currentUser?.email) && (
                <div className="element"
                  onClick={(e) => {toggleInviteUserPopup(e);setSideMenuOpen(!isSideMenuOpen);}}>
                    Invite Others
                </div>
              )}
            </Fragment>
          }
        </div>
      </Fragment>, document.body
    )
  } else {
    const style = {padding: "5px"}
    return ReactDOM.createPortal(
      <Fragment>
        <div className="side-menu-bar" style={style} onClick={(e) => setSideMenuOpen(!isSideMenuOpen)}>
          <i className="fas fa-arrow-left"/>
        </div>
        {isPageNoteOpen && <NotesModal toggleModal={togglePageNoteModal} />}
        {isHelpOpen && (
          <HelpSideNav helpId={props.helpId} setIsHelpOpen={setIsHelpOpen} />
        )}
        {isInviteUserPopupOpen && (
          <InviteModal isOpen={isInviteUserPopupOpen} toggleModal={toggleInviteUserPopup}/>
        )}
      </Fragment>, document.body
    )
  }
};

export default SideMenuBar;