import React ,{useState, Fragment, useContext} from "react"
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import TrackRelationshipService from "./TrackRelationshipService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import Cs from '../../../services/CommonService'

let selectedTracking = null

const TrackRelationship = (props) => {
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [activeIndex, setActiveIndex] = useState()
  const [tracking, setTracking] = useState(props.tracking)
  
  const {isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal} = useModal()

  const toggleMenu =(i) =>{
    setActiveIndex(i==activeIndex?null:i)
  }

  const deleteTracking = (e) => {
    TrackRelationshipService.delete(selectedTracking).then((res)=>{
      if(res.status==204){
        setTracking(tracking.filter(i => i.id !== selectedTracking.id))
      }
    })
    toggleDeleteModal(e)
  }

  const confirmDelete = (e, tracking_relation) =>{
    selectedTracking = tracking_relation
    toggleDeleteModal(e)
  }

  const Menu = ({tracking_relation}) => {
    return( 
      <span className="dropup pull-right">
        <a><i className='fas fa-bars'></i></a>
        <div className="dropup-content w-100p btm-unset">
          <Link to={`/list/relationship/tracking/${tracking_relation.member_id}/${tracking_relation.id}`}>
            <i className="far fa-edit"/> Edit
          </Link>
          <a href="#" onClick={e => {confirmDelete(e, tracking_relation)} }>
            <i className="far fa-trash-alt"/> Delete
          </a>
        </div>
      </span>
    )
  }

  const FileList = ({files}) => {
    return(
      <Fragment>
        {files && files.map((file, k) =>  (
          <a key={k} className="m-t-5 inline-files-list" target="_blank" href={Cs.getFileUrl(file)}>
            <img src={Cs.getIconByType(file)} width="35" height="35"/>
            <span className="m-l-5">{Cs.getFileName(file)}</span>
          </a>
        ))}
      </Fragment>
    )
  }

  const Card = ({tracking_relation, data}) => {
    let ds = tracking_relation.data_source
    
    const SpanField = ({name, label, styleName}) =>(
      <span className={styleName}>{ds[name] && ds[name][label]}</span>
    )

    const SpanList = ({name, label, styleName}) => ds[name] && ds[name].map((d, i) => (
      <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
    ))

    return(
      <div className="p-5 font-14">
        <p className="pull-right font-10" style={{border:"1px solid", padding:"2px"}}>{Cs.formatUpdateDate(tracking_relation.created_at)}</p>
        <div className="m-t-5">{data.next_steps}</div>
        <div>{data.action_date_formatted_date}</div>
        <table>
          <tbody>
            <tr>
              <td><SpanField name="strength" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanField name="source" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td><SpanField name="purpose" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanField name="outcome" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td><SpanField name="helpful" label="label" styleName="pill bg-highlight pull-left"/></td>
              <td><SpanList name="channel" label="label" styleName="pill bg-highlight pull-right"/></td>
            </tr>
            <tr>
              <td>
              <FileList files={data.reference_upload}/>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const RenderCard = ({tracking_relation, data}) =>{
    if (typeof data.reference_upload === 'string') {
      data.reference_upload = [data.reference_upload]
    }
    return <Card tracking_relation={tracking_relation} data={data} />
  }

  const FileLink = ({tracking_relation, data}) =>{
    return (
      <Fragment> 
        {data.upload && data.upload.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
      </Fragment>  
    )
  }

  const StepActivityCard = () => tracking && tracking.map((s, i) => {
    let cardStyle = {borderLeft:'5px solid rgb(163, 163, 255)'}
    return(
      <Fragment key={i}>
        <div className="step-activity" style={cardStyle} onClick={e=>toggleMenu(i)}>
          <div className="pull-left">{s.data.title} - {s.data.name}</div>
          <Menu tracking_relation={s}/> 
        </div>
        {activeIndex == i && <RenderCard tracking_relation={s} data={s.data} /> }
      </Fragment>
    )
  })

  return (
    <Fragment>
      {tracking && 
        <div className="p-5">
          <strong>Tracks</strong>
          <StepActivityCard/>
        </div>
      }
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteTracking}/>
    </Fragment>
  )

}

export default TrackRelationship;