import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import moment from 'moment/min/moment.min'
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../Common/SortDirection'
import CommonService from '../../../services/CommonService'
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { EditGrayIcon, LinkDeleteIcon } from "../../Common/ImageIcons.js"

const QuestionListTable = (props) => {
  const {sort} = useSort(props.requestParams, props.getQuestionsList)
  
  const tableData = props.questionsList.map((question, k) =>{
    let ds = question.data_source
  
    const SpanField = ({name, label, styleName}) =>(
      <span className={styleName}>{ds[name] && ds[name][label]}</span>
    )
  
    const SpanList = ({name, label, styleName}) => ds[name] && ds[name].map((d, i) => (
      <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
    ))

    return(
      <TR key={k}>
        <TD data-title="Id">{question.record_id}</TD>

        <TD data-title="Question">
          {question.data.interview_question}
        </TD>
       
        <TD data-title="Category">
          <SpanList name="question_category" label="label" styleName="pill text-left"/>
        </TD>
        
        <TD data-title="Last updated date">
          {CommonService.formatUpdateDate(question.updated_at)}
        </TD>

        <TD data-title="Journey">
          <SpanList name="journey_type" label="label" styleName="pill text-left" />
        </TD>

        {/*<TD data-title="Profession">
          <SpanList name="course_type" label="label" styleName="pill text-left" />
        </TD>*/}

        <TD data-title="Last UpdatedAt">
          {CommonService.formatUpdateDate(question.updated_at)}
        </TD>

        <TD data-title="Privacy">
          <span onClick={e=>props.updatePrivacy(e, question)}>
            <i className={question.privacy_type == 'public' ? "far fa-check-circle": "far fa-circle"}></i>
          </span>
        </TD>

        <TD data-title="User">
          {question.user_name}
        </TD>
        
        <TD>
          <div className="flex aligncenter">
              <EditGrayIcon onSelect={e=>props.setEdit(e, question.id)} className="action-btn bg-highlight">Edit</EditGrayIcon>
              <LinkDeleteIcon id="delete" data-tip="Delete" onSelect={e=>props.setDelete(e, question)}>
              </LinkDeleteIcon>
              <div className="m-l-5 action-btn bg-highlight white p-5" onClick={e=>props.cloneQuestion(e, question)}>Copy</div>
          </div>
        </TD>
      </TR>
    )
  })

  return (
    <Table className="shadow-small">
      <THead>
        <TR>
          <TH onClick={e=>sort('record_id')}>Id 
            <SortDirection sort_column={props.requestParams.sort_column} column="record_id" reverse={props.requestParams.reverse}/> 
          </TH>
          <TH onClick={e=>sort('interview_question')}>Question
            <SortDirection sort_column={props.requestParams.sort_column} column="interview_question" reverse={props.requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('question_category')}>Category
            <SortDirection sort_column={props.requestParams.sort_column} column="question_category" reverse={props.requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('updated_at')}>Last updated date
            <SortDirection sort_column={props.requestParams.sort_column} column="updated_at" reverse={props.requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('journey_type')}>Journey
            <SortDirection sort_column={props.requestParams.sort_column} column="journey_type" reverse={props.requestParams.reverse}/>
          </TH>
          {/*<TH onClick={e=>sort('course_type')}>Profession
            <SortDirection sort_column={props.requestParams.sort_column} column="course_type" reverse={props.requestParams.reverse}/>
          </TH>*/}
          <TH onClick={e=>sort('updated_at')}>Last Updated
            <SortDirection sort_column={props.requestParams.sort_column} column="updated_at" reverse={props.requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('privacy_type')}>Approved
            <SortDirection sort_column={props.requestParams.sort_column} column="privacy_type" reverse={props.requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('user_name')}>User
            <SortDirection sort_column={props.requestParams.sort_column} column="user_name" reverse={props.requestParams.reverse}/>
          </TH>
          <TH>Options</TH>
        </TR>
      </THead>
      <TBody>
        {tableData}
      </TBody>
    </Table>
  )

}

export default QuestionListTable;