import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormContainer from '../../FormBuilder/FormContainer'
import FormTemplateId from '../../../constants/FormTemplateList'
import ForumService from "../../../components/Forums/ForumService"

let formMode = null 
let formId = null 
let forum = {} 
let data = {} 
let formFn = {}  

function ForumForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let currentUser = authState.user

  /*TODO: Move API to Router to load data before view*/
 
  const get = () => {
    ForumService.get(params.id).then((res) => {
      if(res.status==200){
        forum = res.data.discussion_request
        data = forum.data || {}
        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function(res){
       forum = null
    })
  }
  
  useEffect(() => { 
    forum = {} 
    data = {} 
    formFn = {} 
    formMode = 'create-form-submissions'
    if(params.id){
      get()
    }else if(!isFormRendered){
      setFormRendered(true)
    }
   
  }, [params.id])

  const create = (forum) => {
    forum.discussion_request_id = currentUser.id
    ForumService.create(forum).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/forums")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.ForumNewDiscussion} 
          form={forum} data={data} formFn={formFn} onCreate={create} post={post} 
          onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return('')
  }
  
}

export default ForumForm;