const GetUserPermission = (currentUser, type) =>{
  let role_manager;
  try{
    role_manager = currentUser.current_admin.permissions[type]
  }catch(e){
    
  }
  return role_manager || {}
}

const IsAuthorized = (currentUser, type) =>{
	if(type == 'current_admin.role_content'){
		return currentUser.current_admin.role_content
	}else if(type == 'current_admin.role_manager'){
		return currentUser.current_admin.role_manager
	}else if(type == 'role_manager.training_content'){
	    let role_manager = GetUserPermission(currentUser, 'role_manager')
		return role_manager.training_content
	}else if(type == 'role_manager.news'){
		let role_manager = GetUserPermission(currentUser, 'role_manager')
		return role_manager.news
	}else if(type == 'role_manager.interview'){
		let role_manager = GetUserPermission(currentUser, 'role_manager')
		return role_manager.interview
	}else if(type == 'role_manager.enrichment'){
		let role_manager = GetUserPermission(currentUser, 'role_manager')
		return role_manager.enrichment
	}else if(type == 'current_admin.role_admin'){
		return currentUser.current_admin.role_admin
	}else if(type == 'role_manager.planner'){
		let role_manager = GetUserPermission(currentUser, 'role_manager')
		return role_manager.planner
	}
}

export {IsAuthorized}