import React, { useState, Fragment, useContext, useEffect} from 'react'
import ReactDOM from 'react-dom'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import ReactTooltip from "react-tooltip"
import {OptionsObjList, InputTags, Radio} from "../../Common/FormInput"
import UserFormFieldService from "../UserFormFieldService"
import {Link, useParams} from "react-router-dom"
import Cs from "../../../services/CommonService"
import FormHelper from '../../../components/FormBuilder/FormHelpers'

let searchKeyword = null
let timer = null

const AddFormField = ({toggleModal, form, formList, setFormList, typeOfInput, existingFormField}) => {

  const { state:{user:currentUser}, dispatch } = useContext(AuthContext)
  const [formField, setFormField] = useState(existingFormField || {})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})

  const params = useParams()

  useEffect(() => {
    form.form_fields = form.form_fields || []
    if(formField.id){
      setLoading(true)
      UserFormFieldService.get(formField.id).then((res)=>{
        if(res.status == 200){
          setFormField(res.data.user_form_field);
          setLoading(false)
        }
      })  
    }
  }, []);

  useEffect(() => {
    if(!loading)FormHelper.validate('ct_form', formField, error, setError)
  }, [formField])

  const handleInputChange = (event) => {
    setFormField({
      ...formField,
      [event.target.name]: event.target.value,
    })
  }

  const onTagChange = (list) => {
    formField.options = list
    setFormField({...formField})   
  }

  const submit = (e)=>{
    setLoading(true)
    if(formField.id){
      UserFormFieldService.update(formField).then((res)=>{
        setLoading(false)
        toggleModal(e)
      })
    }else{
      formField.journey_profile_id = params.journey_profile_id
      formField.journey_category_id = params.journey_category_id
      formField.user_form_id = form.id
      UserFormFieldService.create(formField).then((res)=>{
        setLoading(false)
        toggleModal(e)
        res.data.user_form_field.created_user = {name:currentUser.name}
        form.form_fields.unshift(res.data.user_form_field)
        setFormList([...formList])
      })
    }
  }

  const deleteOption = (o) =>{
    Cs.deleteDataSourceList(o.id)
  }

  /*const updateOption = (option) =>{
    let req = {id: option.id, is_answer: !option.is_answer}
    UserFormFieldService.updateOption(req).then((res)=>{
      if(res.status == 204){  
        option.is_answer = !option.is_answer
        setFormList([...formList])                                         
      }
    })
  }*/

  return(
    <Fragment>
      {loading ? <div className="spinner"></div> : <form id="ct_form" className="ml-container">
        <label className="pull-right clear"><span className="lred font-16">* </span>Required field</label>
        <div className="ml-section">
          
          <label className="req-field"><b>Field Type</b></label>
          <select onChange={e=>handleInputChange(e)} name="input_type" value={formField.input_type} className="form-control ml-margin-bottom" required>
            <option value="" disabled selected>Select an item from list below</option>
            <OptionsObjList list={typeOfInput} id_key="sid" label_key="label"/>
          </select>

          <label className="req-field"><b>Label</b></label>
          <input onChange={e=>handleInputChange(e)} name="label"
            className="form-control ml-margin-bottom"  placeholder="label" 
            defaultValue={formField.label} required/>
          
          <label className="req-field"><b>Required</b></label>
          <Radio list={[{label:'Yes', value:'true'}, {label:'No', value:'false'}]} model={formField.required} name="required" required="true" handleChange={handleInputChange}/>

          <label className="req-field"><b>Placeholder</b></label>
          <input onChange={e=>handleInputChange(e)} name="placeholder"
            className="form-control ml-margin-bottom"  placeholder="placeholder" 
            defaultValue={formField.placeholder} required/>

          <label><b>Tooltip</b></label>
          <input onChange={e=>handleInputChange(e)} name="tooltip"
            className="form-control ml-margin-bottom"  placeholder="tooltip" 
            defaultValue={formField.tooltip}/>

          {'checkbox radio multi_select select'.includes(formField.input_type) && <Fragment>          
            <label className="req-field"><b>Add Choices for Multiple Type Questions</b></label>
              <InputTags tagList={formField.options} name="options" onDelete={deleteOption} 
              onTagChange={onTagChange} required="true"/>
            </Fragment>
          }
          
          {error.is_valid &&     
            <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>submit(e)}>
              <b>{formField.id ? 'Update' : 'Add'}</b>
            </div>
          }

        </div>
      </form> }
    </Fragment>
  )

}

export default AddFormField;