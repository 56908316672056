import React, {useState, useRef, Fragment, useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import Tabs, { TabPane } from 'rc-tabs';
import ReactTooltip from 'react-tooltip';
import querystringify from "querystringify";
import useStyle from '../../../../../hooks/useStyle';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import StudentProfileServices from "../../StudentProfiles/StudentProfileServices";
import CommonService from "../../../../../services/CommonService";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import CtTicker from "../../../../Common/CtTicker";
import {OptionsObjList} from "../../../../Common/FormInput";
import {CollegeStatusList, CollegeColor} from '../../../../../constants/DataSourceList'
import BarChart from "../../../../Charts/BarChart"
import {SwiperInfiniteLoopContainer, SwiperSlide, SwiperContainer} from "../../../../Common/Swiper"
import FH from "../../../../FormBuilder/FormHelpers"
import {PillDsList, TablePillDsList} from '../../../../Journey/UserRoles/NotificationPill'
import GenericModal from "../../../../Modals/GenericModal"
import CollegeCompareList from "../../Colleges/SearchList/CollegeCompareList"
import CollegeDetails from "../../Colleges/SearchList/CollegeDetails"

let requestParams = {};
let dataSource = {};
let dataPosition = null;
let selectedCollege = null
let cardViewCount = 0

const MyAdmissionProfile = (props) => {
  /*useStyle('card_table');  
  useStyle("panel");
  useStyle("profile");
  useStyle('card');
  useStyle('table');*/
  useStyle('css/tile-components.css')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  
  const [currentpage, setCurrentpage] = useState(1); 
  const [loading, setLoading] = useState(true);
  const [admissionList, setAdmissionList] = useState([]);
  const [admissionProfile, setAdmissionProfile] = useState();
  const [collegeStatics, setCollegeStatics] = useState([]);
  const [collegeCompareList, setCollegeCompareList] = useState([]);
  const [stateStatics, setStateStatics] = useState([])
  const [satStatics, setSatStatics] = useState({})
  const [actStatics, setActStatics] = useState({})
  const [scoreSubmissions, setScoreSubmissions] = useState({})
  const [engagementStatics, setEngagementStatics] = useState({})
  const [accomplishmentStatics, setAccomplishmentStatics] = useState({})
  const [collegeRatings, setCollegeRatings] = useState({})
  
  const { isOpen: isCollegeOpen, toggleModal: toggleCollege} = useModal()
  const { isOpen: isCollegeCompareOpen, toggleModal: toggleCollegeCompare} = useModal()

  let params = props.match?.params;
  let {project} = props.location?.state || {};
  let queryParam = querystringify.parse(props.location?.search);

  useEffect(() => {
    requestParams = {
      page:currentpage,
      per_page:30,
      profile_type: 'college_admission_profile',
      student_journey_category_id:params.journey_category_id,
      data_source_params: {
        data_source_id:[3426],
        response_type:'object'
      },
    }
    getAdmissionList()
    ReactTooltip.rebuild()
  }, [currentpage]);

  let getAdmissionList = () => {
    setLoading(true)
    StudentProfileServices.getPublicProfiles(requestParams).then((res)=>{
      setAdmissionList(res.data.student_profiles)
      requestParams.totalItems = res.data.student_profiles.college_ids?.length
      setAdmissionProfile(res.data.student_profile)
      setCollegeStatics(res.data.college_statics)
      setStateStatics(res.data.state_statics)
      setSatStatics(res.data.sat_score_statics)
      setActStatics(res.data.act_score_statics)
      setScoreSubmissions(res.data.score_submission_statics)
      setEngagementStatics(res.data.engagement_statics)
      setAccomplishmentStatics(res.data.accomplishment_statics)
      setCollegeRatings(res.data.college_rating_submissions || {})
      cardViewCount = CommonService.getCardCount(screen)
      requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
      dataSource = res.data.data_source_list 
      dataSource.college_app_status = Object.values(dataSource.college_app_status?.options)
      setLoading(false)
    })
  }

  const updateStatus = (status, college) =>{
    let req = {
      id:admissionProfile.id,
      application_status: admissionProfile.application_status || {}
    }
    req.application_status[college.id] = status

    StudentProfileServices.update(req).then((res)=>{
     if(res.status == 204){
        setAdmissionList([...admissionList])
      }
    })  
  }

  const collegeCardView = (profile, college, index) =>{
    let data = profile.data || {}
    let ds = profile.data_source || {}
    const SpanField = ({name, label, styleName}) =>{
      return(
        <>
          <div className="badge badge-xs badge-blue f14 text-white">
            {label}
          </div>
          <p className="m-t-5">{ds[name] ? ds[name].label:'-'}</p>
        </>
      )
    }

    return(
        <div className="bg-white border-rounded-10 p20">
          <div className="card-title flex justspacebetween border-dashed-sm p-b-15">
            <h4 className="fw700 f14 card-heading card-heading flex aligncenter">
              <span
                style={{backgroundColor:CollegeColor[index]}} 
                className="badge empty-badge m-r-10"></span>
              {college.name} 
            </h4>
            {profile.application_status?.[college.id]}
          </div>
          <div className="card-content m-t-15">
            <h5 className="f16">{profile.updated_at.split('-')[0]} - public , {ds.state?.label}</h5>
            <p className="m-t-5">{data.major}</p>
            <div className="flex aligncenter border-dashed-sm p-b-15">
              <div className="flex coldir m-t-10">
                <SpanField name="sat_score" label="SAT " styleName="pill white bg-grey"/> 
              </div>
              <div className="flex coldir m-t-10 m-l-20">
                <SpanField name="act_score" label="ACT " styleName="pill white bg-grey"/>     
              </div>
            </div>
            <ul className="m-t-15 card-bullets border-dashed-sm p-b-15">
              {ds.notable_accomplishments?.map((d, k)=>
                <li className="m-b-10 f14" key={k}>{d.data.label}</li>
              )}
            </ul>
            <ul className="m-t-15 card-bullets p-b-15">
              {ds.engagement?.map((d, k)=>
                <li className="m-b-10 f14" key={k}>{d.data.label}</li>
              )}
            </ul>
          </div>
        </div>
    )
    return(
      <Fragment>
          <div className="">
          <table className="shadow-small">
            <thead>
              <tr className="white bg-black">
                <th className="font-14 white bold-600 bg-grey text-left w-70p">
                <span className="fa fa-circle m-r-5" style={{color:CollegeColor[index]}} /> 
                {college.name} 
                </th>
                <th className="font-14 white bold-600 bg-grey text-right text-cap">
                  {profile.application_status?.[college.id]}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="font-14 w-50p text-left">{profile.updated_at.split('-')[0]} - public , {ds.state?.label}<br/>
                  <span className="" data-tip={data.major} >
                    <i className="fas fa-flask m-r-5" style={{color: "rgb(217, 209, 21)"}}></i>
                    {data.major}
                  </span>
                </td>
                <td rowSpan="2" className="pull-right">
                  <SpanField name="sat_score" label="SAT " styleName="pill white bg-grey"/> <br/> 
                  <SpanField name="act_score" label="ACT " styleName="pill white bg-grey"/>                              
                </td>
              </tr>
              <tr>
              <td colSpan="2">
                <table>
                  <tr>
                    <TablePillDsList list={ds.notable_accomplishments} dataKey="label" style="pill black br-grey font-12 lh-16"/>
                  </tr>
                </table>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table>
                    <tr>
                      <TablePillDsList list={ds.engagement} dataKey="label" style="pill black br-grey font-12 lh-16"/>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
      </Fragment>
    )
  }

  const collegeListView = (profile, colleges) => colleges && colleges.map((c, k) => 
    <SwiperSlide key={c.id}>
      <div className="m-3">
        {collegeCardView(profile, c, k)}
      </div>
    </SwiperSlide>
  )

  const admissionListView = admissionList.length > 0 && admissionList.map((a, k) => {
    return(
      <Fragment key={a.id}>
        {collegeListView(a, (a.data.colleges_array || []))}
      </Fragment>
    )
  })

  const compare = () =>{
    //props.history.push('/college/compare', collegeCompareList)
    toggleCollegeCompare()
  }

  const openCollegeModal = (college,e) =>{
    selectedCollege = college
    toggleCollege()
  }

  const CollegeData = ({colleges}) => colleges && colleges.map((c, k) =>
    <tr key={k}>
      <td className="text-left">
        <div className="black m-l-10" onClick={(e)=>openCollegeModal(c.id,e)}>
          <span className="fa fa-circle m-r-5" style={{color:CollegeColor[k]}} /> 
          {c.name}
        </div>
      </td>
      <td>
        <select className="select" onChange={e=>updateStatus(e.target.value, c)} defaultValue={admissionProfile.application_status?.[c.id]}>
          <option value="">Select</option>
          <OptionsObjList list={dataSource.college_app_status} id_key="label" label_key="label"/>
        </select>
      </td>
      <td className="text-center">
        {collegeRatings[c.id] ? 
          <span className={FH.existsListString(c.id, collegeCompareList)?'fas fa-check-square':'far fa-square'} 
            onClick={e=>FH.toggleListString(c.id, collegeCompareList, setCollegeCompareList)}/>
          :
          <Link className="underline" to={`/highschool/college/review/research/1001/${params.journey_category_id}/${params.journey_profile_id}/${c.id}?goBack=1`}>
            Research and Rate this College
          </Link>
        }
      </td>
    </tr>
  )

  const MyCollegeList = () =>{
    const colleges = admissionProfile?.data?.colleges_array || []
    return(
      <div className="bg-white border-rounded-10 p20">
        <div className="card-title card-title-min-ht flex justspacebetween border-dashed-sm p-b-15">
          <h4 className="fw700 f14 card-heading card-heading flex aligncenter">
            Status &amp; Compare
          </h4>
        </div>
        <div className="card-content m-t-30 card-content-min-ht-270">
          {colleges && colleges.map((c, k) =>
            <div key={k} className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 coldir-xs alignstart-xs">
              <div onClick={(e)=>openCollegeModal(c.id, e)}>
                <div className="fw700 f14 card-heading card-heading flex aligncenter">
                  <span
                    style={{backgroundColor:CollegeColor[k]}} 
                    className="badge empty-badge m-r-10"></span>
                  University of Chicago
                </div>
                {collegeRatings[c.id] ? 
                  <div className="d-flex aligncenter m-l-30 m-t-10">
                    <input type="checkbox" id={`check${k}`} name={`check${k}`} className="m-r-5" readOnly
                    onClick={()=>FH.toggleListString(c.id, collegeCompareList, setCollegeCompareList)} 
                    checked={FH.existsListString(c.id, collegeCompareList)}/>
                    <label htmlFor={`check${k}`}>Select to compare.</label>
                  </div>
                  :
                  <Link className="m-l-30 m-t-10 text-link f14" to={`/highschool/college/review/research/1001/${params.journey_category_id}/${params.journey_profile_id}/${c.id}?goBack=1`}>
                    Research &amp; Rate this College
                  </Link>
                }
              </div>
              <select className="form-select-dropdown form-select-dropdown-sm form-select-w-100-xs m-b-10-xs m-t-10-xs"
                onChange={e=>updateStatus(e.target.value, c)} defaultValue={admissionProfile.application_status?.[c.id]}>
                <option value="">Select</option>
                <OptionsObjList list={dataSource.college_app_status} id_key="label" label_key="label"/>
              </select>
            </div>
          )}
          <div className="flex">
            <button className="btn-primary green text-white wid_100 text-center m-t-15"
              onClick={e=>compare()} disabled={collegeCompareList.length < 2}>
              <img src="./images/compare-icon.png" alt=""/> Start
              Compare
            </button>
          </div>
        </div>
      </div>      
    )
    
    return(
      <table className="table card-table br-grey">
        <thead>
          <tr className="white bg-grey">
            <th className="text-center m-l-10">College</th>
            <th>Status</th>
            <th>Compare</th>
          </tr>
        </thead>
        <tbody>
          <CollegeData colleges={admissionProfile?.data?.colleges_array || []}/>
          <tr colSpan="2">
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>
    )
  }

  const GraphContainer = () => {
    const [chartType, setChartType] = useState('college')

    let colors = CollegeColor
    let height = 350
    let markers = null
    let chart = {}
    
    chart.college = {
      series: [{
        data: collegeStatics.map((i)=>i.count),
      }],
      title: {
        text: 'Application by College',
        align: 'center',
        floating: true
      },
      x_axis: collegeStatics.map((i)=>i.name),
      chart_type:'bar'
    }  

    chart.state = {
      series: [{
        data: stateStatics,
      }],
      title: {
        text: 'College Application By State',
        align: 'center',
        floating: true
      },
      chart_type:'treemap'
    }  

    chart.satScore = {
      series: [{
        data: satStatics.count,
      }],
      title: {
        text: 'SAT Score',
        align: 'center',
        floating: true
      },
      x_axis: satStatics.sat_score,
      chart_type:'pie'
    } 

    chart.actScore = {
      series: [{
        data: actStatics.count,
      }],
      title: {
        text: 'ACT Score',
        align: 'center',
        floating: true
      },
      x_axis: actStatics.act_score,
      chart_type:'pie'
    } 

    chart.scoreSubmission = {
      series: [{
        data: scoreSubmissions.count,
      }],
      title: {
        text: 'ACT Score',
        align: 'center',
        floating: true
      },
      x_axis: scoreSubmissions.score_submission,
      chart_type:'pie'
    }

    chart.engagementStatics = {
      series: [{
        data: engagementStatics.count,
      }],
      title: {
        text: 'Engagement',
        align: 'center',
        floating: true
      },
      x_axis: engagementStatics.engagement,
      chart_type:'pie'
    } 

    chart.accomplishmentStatics = {
      series: [{
        data: accomplishmentStatics.count,
      }],
      title: {
        text: 'Accomplishment',
        align: 'center',
        floating: true
      },
      x_axis: accomplishmentStatics.accomplishments,
      chart_type:'pie'
    } 

    let changeGraph = (t) =>{
      setChartType(t)
    }

    return(
      <div className="bg-white border-rounded-10 p20">
        <div className="card-title card-title-min-ht flex justspacebetween border-dashed-sm p-b-15 coldir-xs">
          <h4 className="fw700 f14 card-heading card-heading flex aligncenter">
            <span className="badge empty-badge empty-badge-yellow m-r-10"></span>
            Application by College
          </h4>
          <select onChange={e=>changeGraph(e.target.value)}
            className="form-select-dropdown form-select-dropdown-sm form-select-w-100-xs m-b-10-xs m-t-10-xs">
              <option value="college">
                College
              </option>
              <option value="state">
                State
              </option>
              <option value="satScore">
                SAT Score
              </option>
              <option value="actScore">
                ACT Score
              </option>
              <option value="scoreSubmission">
                Score Submission
              </option>
              <option value="engagementStatics">
                Engagement
              </option>
              <option value="accomplishmentStatics">
                Accomplishment
              </option>
            </select>
        </div>
        <div className="card-content m-t-30 card-content-min-ht-270">
          <div className="border-dashed-sm p-b-30">
            <BarChart series={chart[chartType].series} x_axis={chart[chartType].x_axis} 
              title={chart[chartType].title} chart_type={chart[chartType].chart_type}
              y_axis_label="College" colors={colors}
              x_axis_label="Admissions" markers={markers}
              className="clearfix" 
              id={chartType} height={height}/>
          </div>
        </div>
      </div>
    )
    return(
      <Fragment>
        <div className="row bg-grey p-7">
          <div className="pull-right bg-white">
            <select className="select" onChange={e=>changeGraph(e.target.value)}>
              <option value="college">
                College
              </option>
              <option value="state">
                State
              </option>
              <option value="satScore">
                SAT Score
              </option>
              <option value="actScore">
                ACT Score
              </option>
              <option value="scoreSubmission">
                Score Submission
              </option>
              <option value="engagementStatics">
                Engagement
              </option>
              <option value="accomplishmentStatics">
                Accomplishment
              </option>
            </select>
          </div>
        </div>
        <div className="m-t-10">
          <BarChart series={chart[chartType].series} x_axis={chart[chartType].x_axis} 
            title={chart[chartType].title} chart_type={chart[chartType].chart_type}
            y_axis_label="College" colors={colors}
            x_axis_label="Admissions" markers={markers}
            className="clearfix" 
            id={chartType} height={height}/>
        </div>
      </Fragment>
    )
  }

  if(loading)
    return <div className="spinner"/>

  return (
    <>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
            <div className="flex coldir fulwid p20">
              <div className="flex justspacebetween">
                <div>
                  <JourneyTitle title={"Activities"} project={project} />
                </div>
                <div className="flex aligncenter">
                  <Link data-tip="Back to Journey Home" to="/journey" className="bg-highlight round-btn m-r-5">
                    <i className="fas fa-home"/>
                  </Link>

                  <span data-tip="Search public profile" className="bg-highlight round-btn m-r-5">
                    <i className="fas fa-search"/>
                  </span>

                  <Link data-tip="Create New Profile" to={`/journey_profile/${params.journey_profile_id}?form_id=301`} className="bg-highlight round-btn m-r-5">
                    <i className="fas fa-plus"/>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="app-content-section m-t-30">
            <div className="row">
                <SwiperContainer cardViewCount={cardViewCount} currentpage={currentpage}
                 setCurrentpage={setCurrentpage} requestParams={requestParams}>
                  {admissionListView}
                </SwiperContainer>
            </div>
            <div className="row">
              <div className="col-md-6 m-t-30">
                <GraphContainer/>
              </div>
              <div className="col-md-6 m-t-30">
                <MyCollegeList/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  
  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-12">
          <div className="col-md-4 pull-left">
            <JourneyTitle title={"Activities"} project={project} />
          </div>
          <div className="col-md-8 pull-right">     
            <Link data-tip="Back to Journey Home" to="/journey" className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-home"></i>
            </Link>

            <span data-tip="Search public profile" className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-search"></i>
            </span>

            <Link data-tip="Create New Profile" to={`/journey_profile/${params.journey_profile_id}?form_id=301`} className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-plus"></i>
            </Link>
          </div>
          <div className="clear"></div>
        </div>
      </div>

      <div className="page-content bg-white row">
          <div className="col-xs-12 m-3">
            <SwiperContainer cardViewCount={cardViewCount} currentpage={currentpage}
             setCurrentpage={setCurrentpage} requestParams={requestParams}>
              {admissionListView}
            </SwiperContainer> 
          </div>        
          <div className="col-xs-12 col-sm-6 p-0-20 m-b-10">
            <div className="m-3 bg-white br-grey"> 
              <GraphContainer/>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 p-0-20 m-b-10">
            <div className="m-3 bg-white"> 
              <MyCollegeList/>
            </div>
          </div>
      </div>

      {isCollegeOpen && <GenericModal title="College" component={CollegeDetails} journey_category_id={params.journey_category_id} 
         widthCss="w-80p" education_journey_profile_id={params.journey_profile_id} isOpen={isCollegeOpen} isPopupView={true} toggleModal={toggleCollege} journey={project} id={selectedCollege} />}
      
      {isCollegeCompareOpen && <GenericModal title="College Compare" component={CollegeCompareList} collegeCompareList={collegeCompareList}
         widthCss="w-80p" isOpen={isCollegeCompareOpen} isPopupView={true} toggleModal={toggleCollegeCompare} />}
      
      <ReactTooltip place="bottom" />
    </Fragment>
  )
}

export default MyAdmissionProfile;