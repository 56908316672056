import React, {Fragment,useState} from "react"
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../../components/Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc'
import arrayMove from '../Common/arrayMove'
import Cs from "../../services/CommonService"
import {PillDsList} from "../Journey/UserRoles/NotificationPill" 
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { EditGrayIcon, LinkDeleteIcon } from "../Common/ImageIcons.js"

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>
})

const DailyDabbleTableList = ({addDelete, routeForm ,dabbleList, requestParams, selectedDabble, updateStatus, getDailyDabbleList}) => {
  useStyle('table')  
  
  const [currentAssessment, setCurrentAssessment] = useState()

  const {sort} = useSort(requestParams, getDailyDabbleList)

  const TableData = ({dabbleList}) => dabbleList && dabbleList.map((o, k) =>
    <TR key={k}>
      <TD data-title="Id">{o.record_id}</TD>
      <TD data-title="Title">{o.data.title || o.data.record_title}</TD>
      <TD data-title="Created Date" >{Cs.formatUpdateDate(o.created_at)}</TD>
      <TD data-title="Content Type" >
        <PillDsList list={o.data_source.classification} dataKey='label' style="pill white bg-lred m-r-5"/>
      </TD>
      <TD data-title="Created By" >{o?.created_user?.name || 'Public'}</TD>
      <TD data-title="Preview Answers" >
        <Link id="question_form_icon" 
          to={{pathname:`/list/daily_dabble_responses/${o.id}`, state:o}}
          data-tip="Preview Answers">
          <i className="far fa-file"/>
        </Link>
      </TD>
      <TD data-title="Options" >
        <div className="flex aligncenter">
          <span id="publish_icon" className="m-r-15"
            onClick={(e) => {selectedDabble = o;updateStatus(e, selectedDabble)}}>
              <i className={o.is_active?'far fa-check-circle':'far fa-circle'} />
          </span>
          <EditGrayIcon onSelect={e=>routeForm(e, o.id)} className="action-btn bg-highlight">Edit</EditGrayIcon>
          <LinkDeleteIcon id="delete" data-tip="Delete" onSelect={e=>addDelete(e, o)}>
          </LinkDeleteIcon>
        </div>
      </TD>
    </TR>
  )

  return (
    <div className="col-xs-12 m-t-10">
      <SortableContainer distance={1} lockAxis="y" useDragHandle>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>Id
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('title')}>Title
                <SortDirection sort_column={requestParams.sort_column} column="title" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('updated_at')}>Updated Date 
                <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('classification')}>Content Type
                <SortDirection sort_column={requestParams.sort_column} column="classification" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_by')}>Created By
                <SortDirection sort_column={requestParams.sort_column} column="created_by" reverse={requestParams.reverse}/> 
              </TH>
              <TH>Preview Answers
              </TH>
              <TH onClick={e=>sort('is_active')}>Actions
                <SortDirection sort_column={requestParams.sort_column} column="is_active" reverse={requestParams.reverse}/> 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData dabbleList={dabbleList} />
          </TBody>
        </Table>
      </SortableContainer>
    </div>
  )

}

export default DailyDabbleTableList;