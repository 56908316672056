import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import JourneyCategoryService from "../../JourneyCategoryService";
import TrainingModuleService from "../TrainingModules/TrainingModuleService";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useNotification from "../../../../hooks/useNotification";
import MultiSelectModal from "../../../Modals/MultiSelectModal";
import env from '../../../../env';
import Cs from '../../../../services/CommonService'

const PortalHeader = (props) => {
  const { project, share } = props;
  const journey_profile = project.education_journey_profiles;
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal()
  const {isOpen: isUserTypeOpen, toggleModal: toggleUserTypeModal,} = useModal()
  const { showNotification } = useNotification()

  const deleteProject = (e) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.delete(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
        history.push("/journey")
      }
    })
    toggleDeleteModal(e)
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault()
    setLoading(true)
    project.is_chatlist_enabled = !project.is_chatlist_enabled;
    JourneyCategoryService.update(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
      }
    })
  }

  const getActiveUserReport = (e, journey_category_id) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      journey_category_id: journey_category_id,
      type: "active_users",
    }
    TrainingModuleService.reports(req).then((res) => {
      if (res.status == 200) {
        setLoading(false)
        let activeUsers = res.data.report;
      }
    })
  }

  const cloneJourney = (e, journey_category_id) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.cloneJourney(journey_category_id).then((res) => {
      if (res.status == 200) {
        showNotification("Success", "Journey Cloned Successfully")
        setLoading(false)
        history.push("/journey")
      }
    })
  }

  const updateStatus = (e, journey_category, field) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      id: journey_category.id,
    }
    req[field] = !journey_category[field];
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        journey_category[field] = !journey_category[field];
        setLoading(false)
        if (field == "is_demo_template" && req.is_demo_template) {
          toggleUserTypeModal(e)
        }
      }
    })
  }

  const updateDemoJourneyUserType = (list) => {
    let req = {
      id: project.id,
      demo_journey_user_type: list,
    }
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        setLoading(false)
      }
    })
  }

  const Menu = () => (
    <span id="portal_menu" className="portlet-dropdown">
      <a data-tip="Adjust Journey Settings" className="p-dropbtn">
        <i className="fas fa-cog m-r-15 font-20"/>
      </a>
      <div className="p-dropdown-content font-16">
        {project.is_admin_user && 
          <>
            <Link to={`/journey_profile/${journey_profile.id}?form_id=${journey_profile.form_id}`}>
              <i className="far fa-edit"/> Edit
            </Link>

            <a href="#" onClick={(e) => toggleDeleteModal(e, project)}>
              <i className="far fa-trash-alt"/> Delete
            </a>
          </>
        }

        {(project.is_admin_user || share?.role_sid?.includes('teacher')) && <>
          <Link to="/admin/course/modules">
            <i className="fa fa-globe"/> Training Manager
          </Link>

          <Link to={`/journey/price/form/${project.id}/${journey_profile.id}/journey_profile/${journey_profile.id}`}>
            <i className="fas fa-dollar-sign"/> Price
          </Link>

          <Link to={`/journey/notification/form/${project.id}/${journey_profile.id}/journey_profile/${journey_profile.id}`}>
            <i className="fas far fa-bell"/> Notification
          </Link>

          <Link to={{pathname: `/invite/user/${project.id}/${journey_profile.id}`, state: {'journey':project}}}>
            <i className="fa fa-user"/> Invite User
          </Link>
        </>}

        {project.is_admin_user &&
          <a href="#" onClick={(e) => cloneJourney(e, project.id)}>
            <i className="far fa-clone"/> Create a copy
          </a>
        }
        
        {project.is_admin_user &&
          <a href="#" onClick={(e) => updateStatus(e, project, "is_journey_completed")}>
            <i className={`far ${project.is_journey_completed ? "fa-check-circle" : "fa-circle"}`}/> Completed
          </a>
        }
        
        {project.is_admin_user &&
          <a href="#" onClick={(e) => toggleChatlistStatus(e)}>
            <i className={project.is_chatlist_enabled ? "far fa-check-circle": "far fa-circle"}/> Share chat
          </a>
        }

        {project.is_admin_user && 
          <a href="#" onClick={(e) => updateStatus(e, project, "is_demo_template")}>
            <i className={`far ${project.is_demo_template ? "fa-check-circle" : "fa-circle"}`}/> Demo Journey
          </a>
        }
        
        <a href="#" onClick={(e) => props.app.openHelp(e)}>
          <i className="fas fa-question-circle"/> Help
        </a>
      </div>
    </span>
  )

  const DemoMenu = () => (
    <span id="portal_menu" className="portlet-dropdown">
      <a data-tip="Adjust Journey Settings" className="p-dropbtn">
        <i className="fas fa-cog m-r-15 font-20"/>
      </a>
      <div className="p-dropdown-content">
        <Link to={{
          pathname: `/invite/user/${project.id}/${journey_profile.id}`,
          state: {journey: project},
        }}>
          <i className="fa fa-user"/> Invite User
        </Link>
      </div>
    </span>
  )
  
  return(
    <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
      <div className="flex coldir fulwid p20">
        <div className="flex justspacebetween">
          <div>
            <div className="app-title m-r-20">{journey_profile.data.journey_name}</div>
            <p className="m-t-10">
              Training Journey &gt; &nbsp; 
              <span className="text-blue">{Cs.formatUpdateDate(project.education_journey_profiles.updated_at)}</span>
            </p>
            {share?.role_sid?.map((role, i) => 
              <span key={i} className="text-cap round-tab bg-black white p-3 pill">
                {role}
              </span>
            )}
          </div>
          <div className="flex aligncenter">
            {project.is_demo_journey ? <DemoMenu /> : <Menu />}
            <Link data-tip="Back to Journey List" to="/journey">
              <i className="fas fa-home font-20"/>
            </Link>
          </div>
        </div>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
      {isUserTypeOpen && 
        <MultiSelectModal isOpen={isUserTypeOpen} toggleModal={toggleUserTypeModal}
          title="User Type" options={props.userTypeList.options}
          onUpdate={updateDemoJourneyUserType} selectedList={project.demo_journey_user_type}/>
      }
    </div>
  )

  return (
    <div className="portlet-heading clearfix">
      {loading && <div className="spinner" />}
      <div className="col-md-4">
        <h3 className="portlet-title">
          <span>
            {journey_profile.data.journey_name}
          </span>
          <p>
            {share?.role_sid?.map((role, i) => 
              <span key={i} className="text-cap round-tab bg-black white p-3 pill text-cap">
                {role}
              </span>
            )}          
          </p>
        </h3>
      </div>

      <div className="col-md-8 pull-right">
        <div className="portlet-widgets">
          <small className="text-cap m-t-10 m-r-15">Training Journey</small>
          {project.is_demo_journey ? <DemoMenu /> : <Menu />}
          <Link data-tip="Back to Journey List" to="/journey">
            <i className="fas fa-home font-20"></i>
          </Link>
        </div>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
      {isUserTypeOpen && (
        <MultiSelectModal isOpen={isUserTypeOpen} toggleModal={toggleUserTypeModal}
          title="User Type" options={props.userTypeList.options}
          onUpdate={updateDemoJourneyUserType} selectedList={project.demo_journey_user_type}/>
      )}
    </div>
  )
}

export default PortalHeader;