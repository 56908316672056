import React from "react";
import NotificationPopup from '../Common/NotificationPopup'

const UnauthorizedNotice = (props) => {
  let message = `Sorry, You don't have access to this page`

  const onSuccess = () =>{
   
  }

  return (
    <NotificationPopup header="Unauthorized" message={message} onSuccess={onSuccess}/>
  )
}

export default UnauthorizedNotice;