import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Member from '../../components/Members/Member'
import MemberService from "../../components/Members/MemberService"
import ConfirmMenu from '../Modals/ConfirmMenu'
import {Link} from "react-router-dom"
import useModal from '../../hooks/useModal'
import useStyle from '../../hooks/useStyle'
import {NavIcon,CardMenuInfo,MenuItem} from '../../components/Common/MenuItem'
import ProjectMenu from "../../components/Modals/ProjectMenu"
import Cs from "../../services/CommonService"
import CommentsModal from "../Modals/CommentsModal"
import ChecklistsModal from "../Modals/ChecklistsModal"
import Pagination from "../Pagination"
import SideMenuBar from "../../components/Common/SideMenuBar"
import {JourneyTypeMenu} from "../Journey/List/JourneyTypeMenu"

let requestParams = {}
let selectedMember = {}
let dataSource = {}

const MemberList = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)

  const { state:{screen, user:currentUser}} = useContext(AuthContext)

  const [members, setMembers] = useState([])
  const [currentpage, setCurrentpage] = useState(1)

  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isJourneyMenuOpen, toggleModal:toggleJourneyMenu } = useModal()
  
  useEffect(() => {  
    requestParams = {
      'page': currentpage,
      'per_page': 30,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'data_source_params': {"data_source_id":[3400]}
    //  'accelerator_id':currentUser.user.accelerator.id
    }

    getMembersList()
  }, [currentUser, props.searchString, currentpage])

  let getMembersList = () => { 
    MemberService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       dataSource = res.data.dataSource_list
       requestParams.totalItems = res.data.meta.total
       setMembers(res.data.members)
      }
    })
  }

  const addDelete = (e, member) => {
    selectedMember = member
    toggleMenuModal(e)
    toggleDeleteModal()
  }

  const deleteMember = (e) => {
    e.preventDefault()
    MemberService.delete(selectedMember.id).then((res) => {
      if (res.status == 204) {
        setMembers(members.filter(r => r.id !== selectedMember.id))
      }
    })
    toggleDeleteModal(e)
  }

  const addMenu = (e, member) => {
    selectedMember = member
    toggleMenuModal(e)
  }

  const addChecklist = (e, member) => {
    selectedMember = member
    toggleChecklistModal(e)
  }

  const addNotes = (e, member) => {
    selectedMember = member
    toggleCommentModal(e)
  }

  const openJourneyType = (member) =>{
    selectedMember = member
    toggleJourneyMenu()
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => addDelete(e, item)}/>
      
        <MenuItem id="edit_icon" 
          link={`/member/form/${item.form_type_id}/${item.form_id}/${item.id}`}
          icon="far fa-edit"
          action="Edit"/>
      </Fragment>
    )
  }

  const Menu = ({ item}) => (
    <i data-tip="Open Tile Menu" id="journey_menu" onClick={(e) => addMenu(e, item)}
      className="fas fa-bars font-18 "/>
  )
    
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20 m-b-10">
                User Lists
              </div>
              <div className="w-90">
                <p>
                  1) To launch a journey for a new student, first create a student tile
                  by clicking the + button above and then add the journey from the new tile.
                </p>
                <p>
                  2) To launch a journey for an existing student, simply click the "Add Journey" 
                  button on that student's tile.
                </p>
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="add_new_member" dataTip="Add New Member" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                link="/member/form/1/1037" icon="fas fa-plus"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              { members && members.map((member, i) => (
                  <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
                    <Member member={member} menu={Menu} 
                      toggleModal={toggleDeleteModal} 
                      addNotes={addNotes} 
                      addMenu={addMenu}
                      addChecklist={addChecklist}
                      openJourneyType={openJourneyType}
                      history={props.history}/>
                    
                    {selectedMember.id == member.id && (
                      <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
                        item={selectedMember} addDelete={addDelete}
                        type="member" MenuItems={menuItems}
                        menuHeight="275px"/>
                    )}
                  </div>
                ))}
            </div>
            <Pagination totalItems={requestParams.totalItems}
              currentpage={currentpage} setCurrentpage={setCurrentpage}
              itemsPerPage={requestParams.per_page}/>
          </div>
        </div>
      </div>
      
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteMember}/>
      
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} 
        toggleModal={toggleChecklistModal} 
        item={selectedMember} type="member"/>
      }
      
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={selectedMember} type="member"/>
      }
      
      {isJourneyMenuOpen && 
        <JourneyTypeMenu isOpen={isJourneyMenuOpen} 
        toggleModal={toggleJourneyMenu}
        journeyMember={selectedMember}
        openJourneyType={openJourneyType}/>
      }

      <SideMenuBar helpId="2" />
    </Fragment>
  )

}

export default MemberList;