import React, {Fragment, useEffect, useState, useContext} from "react"
import { Link } from "react-router-dom"
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import PreLoadSpinner from "../../components/PreLoadSpinner"
import CommonService from '../../services/CommonService'

const Signup = (props) => {
  useStyle('/css/login.css', true) 
  useStyle('/css/common.css', true)
    
  const params = props.match.params
  const queryParam = querystringify.parse(props.location.search)

  const { state: authState, dispatch } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(Math.random())

  const initialState = {
    email: "",
    password: "",
    cpassword: "",
    isSubmitting: false,
    isChecked:false,
    errorMessage: {},
    serverErrorMessage: {},
    errorSign: {
      email: false,
      password: false,
      cpassword: false,
    }
  }

  const [data, setData] = useState(initialState)
  const [isRegistered, setIsRegistered] = useState(false)

  const handleTerms = (event) => {
    setData({
      ...data,
      isChecked: event.target.checked
    })
  }

  const handleInputChange = (event) => {
    if (event.target.value === "") {
      let errorMessage = data.errorMessage
      errorMessage[event.target.name] = [event.target.name] + " required"
      let errorSign = data.errorSign
      errorSign[event.target.name] = false
      setData({
        ...data,
        errorMessage: errorMessage,
        errorSign: errorSign,
      })
    } else {
      if (data.errorMessage[event.target.name]) {
        delete data.errorMessage[event.target.name]
      }
      
      let errorSign = data.errorSign
      errorSign[event.target.name] = true
      data[event.target.name] = event.target.value

      if('password' == event.target.name){
        checkRegex(event.target.name, event.target.value, data)
      }else if('cpassword' == event.target.name){
        checkCpassword(data)
      }
      
      setData({
        ...data,
        errorMessage: data.errorMessage,
        errorSign: errorSign,
      })
    }
  }

  const checkError = () => {
    for (const property in data.errorSign) {
      if (data.errorSign[property] === false) return false
    }
    if(data.isChecked === false){
      return false
    }
    return true
  }

  useEffect(() => {
    if(authState.isAuthenticated){
      props.history.push('/')
    }
    invitationLinkVisited()
  }, [authState.isAuthenticated])

  const handleFormSubmitSignup = (event) => {
    event.preventDefault()
    setLoading(true)
    setData({...data, isSubmitting: true})
    let req = {
      user: {
        email: data.email,
        password: data.password,
        organization_use_case: 'family_journey',
        password_confirmation: data.cpassword,
        invitation_id: queryParam.invite_id || null,
      }
    }
    AuthenticationService.signup(req).then((res) => {
      if(res.status == 201) {
          CommonService.setCookie('ct_subscribed', true, (365 * 10))
          dispatch({
            type: "Signup",
            payload: res.data.data,
          })
          setLoading(false)
          setIsRegistered(true)
          //props.history.push('/signin');
      } else {
          setLoading(false)
          setData({
            ...data,
            isSubmitting: false,
            serverErrorMessage: res.data.errors || res.statusText,
          })
      }
    })
  }
  
  const invitationLinkVisited = () => {
    if(queryParam.share_type == 'ribbon'){
      if(queryParam.invite_id && queryParam.invite_id.length>0){
        AuthenticationService.inviteRibbonLinkVisited(queryParam.invite_id).then(function(response) {
          if (response.status == 204) {

          }
        })
      }
    }
  }

  const checkRegex = (name, val, data) =>{
    const pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d\W_]{6,19}$/)
    const patternMatch = pattern.test(val)
    data.errorSign[name] = patternMatch
    if(!patternMatch){
      data.errorMessage[name] = 'password should have atleast one numeric and should be greater than 6 chars'
    }
  }

  const checkCpassword = (data) =>{
    if(data.password!="" && data.cpassword!="" && data.password !== data.cpassword){
      data.errorSign['cpassword'] = false
      data.errorMessage['cpassword'] = 'password not match'
    }
  }

  const Error = () =>{
    if(typeof data.serverErrorMessage === "string"){
      return(
        <div className="error">{data.serverErrorMessage}</div>
      )
    }else{
      return(
        <Fragment>
          {Object.keys(data.serverErrorMessage).map((o, k) =>
            <div className="error" key={k}>{o} {data.serverErrorMessage[o]}</div>
          )}
        </Fragment>
      )
    }
  }

  if(loading){
    return(<PreLoadSpinner/>)
  }
  
  return (
    <Fragment>
      <div id="ct">
        <section className="login-page flex justspacebetween">
          <form className="login-left wid_50 pos_relative flex aligncenter">
            <div className="login-form">
              <h2 className="title m-b-10">Sign Up</h2>
              {isRegistered &&
                <div className="ht-600">
                  <div id='card' className="animated fadeIn">
                    <div id='upper-side'>
                      <i className="fa fa-check bg-green1-dark success-ico" />
                      <h3 id='status'>Success</h3>
                    </div>
                    <div id='lower-side'>
                      <p className="p-7">Thank you for registering to use cueTree Education. </p>
                      <p className="p-7">Check for a verification email in the
                          inbox of the email account used to
                          register for your account. If you don’t
                          find the email, check the spam
                          folder. 
                      </p>
                      <p className="p-7">
                        Click the verification link in the email to sign into cueTree the first time.
                      </p>
                    </div>
                  </div>
                </div>
              }
              {!isRegistered && 
              <Fragment>
              <p className="text-lite-gray m-b-30">
                Enter your sign up details below
              </p>
              <div className="form-fields flex coldir m-b-20">
                <label className="form-label m-b-10">Email</label>
                <input
                  className="form-input fulwid"
                  type="name"
                  placeholder="Email"
                  name="email"
                  onChange={handleInputChange}
                  required
                />
              </div>
              {data.errorMessage["email"] && (
                <div className="error m-b-20">
                  {data.errorMessage["email"]}
                </div>
              )}

              <>
              <div className="form-fields flex coldir m-b-20">
                <label className="form-label m-b-10">Password</label>
                <input className="form-input"
                  type="password"
                  placeholder="Choose a Password"
                  name="password"
                  onChange={handleInputChange}
                  required />
              </div>
              {data.errorMessage["password"] && (
                <div className="error m-b-20">
                  {data.errorMessage["password"]}
                </div>
              )}
              </>
              <>
              <div className="form-fields flex coldir m-b-20">
                <label className="form-label m-b-10">Confirm Password</label>
                <input className="form-input"
                  type="password"
                  placeholder="Confirm your Password"
                  name="cpassword"
                  onChange={handleInputChange}
                  required />
              </div>
              {data.errorMessage["cpassword"] && (
                <div className="error m-b-20">
                  {data.errorMessage["cpassword"]}
                </div>
              )}
              </>
              
              <div className="d-flex m-t-25 aligncenter">
                <input type="checkbox" id="terms" name="terms" className="wh-25 fs14" onChange={handleTerms} required />
                <label className="m-l-5 f14" htmlFor="terms">I Accept the Terms of Use and Privacy Policy.</label>
              </div>
              {data.errorMessage["tc"] && (
                <div className="error m-b-10">
                  {data.errorMessage["tc"]}
                </div>
              )}

              <Error/>
              
              <button
                onClick={handleFormSubmitSignup} disabled={checkError() ? false : true}
                className="btn btn-primary fulwid f16 fw500 tcenter text-white m-t-10">
                {data.isSubmitting ? "Loading..." : "Create Account"}
              </button>
              
              </Fragment>
            }
              <div className="m-t-50">
                <p>
                Already Registered? {" "}
                  <Link to="/signin" className="text-link">
                    <strong>Sign In Here.</strong>
                  </Link>
                </p>
              </div>
            </div>
          </form>
          <div className="login-right tcenter justifycenter wid_50 pos_relative flex aligncenter">
            <div className="login-content">
              <a href="#" className="m-b-20">
                <img src="images/cueTree-logo.png" alt="" />
              </a>
              <h3 className="fw500 m-b-15">Transform education journeys</h3>
              <p className="text-lite-gray m-b-20 wid_80 w-80 wid_100_xs mx-auto">
                 {CommonService.displayRandomPhrase(Math.floor(4*count))}
              </p>
              <div className="badge badge-md badge-content m-b-20 mx-auto fw500">
               Improving learning experiences by augmenting and enriching connections
              </div>
              <div className="m-t-10 mx-auto">
                <ul className="flex gap-5 justifycenter">
                  <li>
                    <a href="#">
                      <img src="images/fb-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/instagram-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/twitter-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/linked-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/whatsapp-icon.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex justspacebetween m-t-50">
                <p className="text-lite-gray f12">{new Date().getFullYear()} IPIPAL INC. All Rights Reserved.</p>
                <p className="f12">
                  <Link to="/about_us/terms_of_service" className="text-link">
                    <strong>Terms of Service</strong>
                  </Link>
                  {" "}|{" "}
                  <Link to="/about_us/privacy_policy" className="text-link">
                    <strong>Privacy policy </strong>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
};

export default Signup;