import React, { Fragment, useState, useEffect, useContext} from 'react'
import ReactDOM from 'react-dom'
import {Link} from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import useEscape from '../../hooks/useEscape'

const ContactTypeMenu = ({ dataSource, params, isOpen, toggleModal }) => {
  useStyle('add_role_dialog')
  useStyle('filter_dialog')
  useEscape(toggleModal)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  let dataSourceId = dataSource.edu_stakeholder_type.id
  let activity_types = dataSource.edu_stakeholder_type.options
  
  const FormFilters = () =>(
    <Fragment>
      <div className="popup-menu-grid">
        {activity_types.map((o, k) =>(
          <Link className="ver-icons" key={k} to={`/add/contact/${o.value}/${o.child_form}`}>
            <span>
              <i className={o.icon || "fas fa-plus"} style={{ color: o.icon_color }}/>
            </span>
            <div className="popup-menu-title">{o.label}</div>
            <i className="fas fa-chevron-right lgrey pull-right" />
          </Link>
        ))}
      </div>
      <div className="row">
        <p className="font-9 lgrey lh-16 p-5">{dataSourceId}</p>
      </div>
    </Fragment>  
  )

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom" style={{width: '400px'}}>
          
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select a category</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          <div className="p-5 scroll-x" style={{height:screen.height-120}}>
            <FormFilters />
          </div>
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default ContactTypeMenu;