import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import PublicRoute from "../../routes/PublicRoute"
import NewsList from '../../components/News/List/NewsList'
import NewsForm from '../../components/News/Form/NewsForm'

const NewsRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/news" component={NewsList} searchString={props.searchString} exact />
    <PrivateRoute path="/admin/news" component={NewsList} searchString={props.searchString} permissionType='role_manager.news' exact />
    <PublicRoute path="/news/create/:form_id/:id?" component={NewsForm} exact />
  </Fragment>
)

export default NewsRouter;