import React, {useState, Fragment, useEffect,useContext,useRef} from "react"
import querystringify from "querystringify"
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Assessment from '../../Assessments/List/Assessment';
import AssessmentService from "../../Assessments/AssessmentService"
import Pagination from '../../../../Pagination'
import ConfirmMenu from "../../../../Modals/ConfirmMenu"
import ProjectMenu from "../../../../Modals/ProjectMenu"
import FormTemplateId from '../../../../../constants/FormTemplateList';
import CommentsModal from "../../../../Modals/CommentsModal"
import CardMenu from "../../../../Modals/CardMenu"
import {Link} from "react-router-dom"
import useModal from "../../../../../hooks/useModal"
import NotesModal from "../../../../Modals/NotesModal"
import MenuModal from "../../../../Modals/Menu"
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import useStyle from '../../../../../hooks/useStyle';
import CheckAccess from '../../../Roles/CheckAccess';
import PreLoadSpinner from "../../../../../components/PreLoadSpinner"
import JourneyTitle from "../../../JourneyTitle/JourneyTitle"
import CalendarModal from "../../../../Modals/CalendarModal"
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal"
import SideMenuBar from "../../../../../components/Common/SideMenuBar"
import NotificationPopup from "../../../../../components/Common/NotificationPopup"
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from '../../../../Common/arrayMove';
import HelpMessageList from '../../../../../constants/HelpMessageList'
import {NavIcon,CardMenuInfo,MenuItem,CardMainMenu} from '../../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import CommonService from "../../../../../services/CommonService"
import AddQuestion from "./AddQuestion"
import GenericModal from "../../../../Modals/GenericModal"
import StepAssessmentService from "../../Assessments/StepAssessmentService"
import AssessmentTable from "./AssessmentTable"
import JourneyNotificationType from "../../../JourneyUserGroup/JourneyNotificationType"
import JourneyUserGroupEmailTable from "../../../JourneyUserGroup/JourneyUserGroupEmailTable"

let requestParams = {}
let searchParams = {}
let selectedAssessment = {}
let selectedStepAssessment = {}
let data_source = {}
let journey_category = null
let cardViewCount = 1
let publishType = null
let timer = null

const typeOfQuestion = [
  {'label':'Select single item from multiple choices', 'sid':'radio'},
  {'label':'Select several items from multiple choices', 'sid':'checkbox'},
  {'label':'Short Answer Response', 'sid':'textArea'},
  {'label':'Long Essay Answer Response', 'sid':'richtext'},
  {'label':'Submit URL response', 'sid':'textInput'}
]

const AssessmentList = (props) => {
  useStyle('card')
  useStyle('card_table')
  useStyle('add_role_dialog')

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "col-xs-12"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8"
    cardHeightCss = "mih-380"
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [assessmentList, setAssessmentList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showAssessmentTable, setShowAssessmentTable] = useState(false)
  const [pagemeta, setPagemeta] = useState([])
  const [share, setShare] = useState({})
  const [userGroupEmailNotifications, setUserGroupEmailNotifications] = useState([])
  const [course, setCourse] = useState({})

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const { isOpen:isQuestionModalOpen, toggleModal:toggleQuestionModal } = useModal()
  const { isOpen:isPublishModalOpen, toggleModal:togglePublishModal } = useModal()
  const { isOpen:isResultModalOpen, toggleModal:toggleResultModal } = useModal()
  const { isOpen:isNotificationTypeOpen, toggleModal:toggleNotificationType } = useModal()
  const { isOpen:isUserGroupEmailNotificationOpen, toggleModal:toggleUserGroupEmailNotification } = useModal()

  const indexEle = useRef(null)

  useEffect(() => {
    ReactTooltip.rebuild()
    journey_category = null
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'course_id':params.course_id,
      'page':currentpage,
      'per_page':10,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'id':queryParam.id,
      'data_source_params':{
        'data_source_id':[3478]
      }
    }

    getAssessmentList()

    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage, props.location])

  let getAssessmentList = () => { 
    setLoading(true)
    AssessmentService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        data_source = res.data.data_source_list
        requestParams.totalItems = res.data.meta.total
        journey_category = res.data.journey_category
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
        CheckAccess.userAccess(currentUser, journey_category, res.data.invited_journey_member)
        setShare(res.data.invited_journey_member)
        if(requestParams.search){
          setAssessmentList(res.data.assessments)
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setAssessmentList(res.data.assessments)
        }else{
          setAssessmentList([...assessmentList, ...res.data.assessments])  
        }
        setCourse(res.data.course || {})
        res.data.assessments.length == 0 && !requestParams.search && toggleHelpMessage()
      }
    })
  }

  let searchAssessment = () =>{
    AssessmentService.search(searchParams).then((res)=>{
      setAssessmentList(res.data.assessments)  
    })
  }

  let handleSearch = (e) =>{
    clearTimeout(timer)
    searchParams = {
      'page':currentpage,
      'per_page':20,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':e.target.value
    }
    timer = setTimeout(searchAssessment, 1000)
  }

  let getEmailNotificationList = (e, item) => { 
    e.preventDefault()
    setLoading(true)
    let req = {
      notifiable_id:item.id,
      notifiable_type:'carrier_training.assessment'
    }
    AssessmentService.getEmailNotificationList(item.id, req).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        setUserGroupEmailNotifications(res.data.sent_email_notifications)
        toggleUserGroupEmailNotification()
      }
    })
  }
    
  const addNotes = (e, a) => {
    selectedAssessment = a
    toggleCommentModal(e)
  }

  const addChecklist = (e, a) => {
    selectedAssessment = a
    toggleChecklistModal(e)
  }

  const deleteAssessment = (e) => {
    e.preventDefault()
    AssessmentService.delete(selectedAssessment.id).then((res)=>{
      if(res.status==204){
        setAssessmentList(assessmentList.filter(i => i.id !== selectedAssessment.id))
      }
    })
    toggleDeleteModal(e)
  }

  const toggleAssessmentTable = () => setShowAssessmentTable(!showAssessmentTable)

  const update = (e, item, key) => {
    e && e.preventDefault()
    let req = null
    if(key){
      req = {[key]: !item[key], id: item.id}
    }

    AssessmentService.update(req).then((res)=>{
      if(res.status==204){
        if(key)item[key] = !item[key];
        setAssessmentList([...assessmentList])
      }
    })
  }
  
  const publishAssessment = (e) => {
    e.preventDefault()
    toggleResultModal(e)
    toggleNotificationType(e)
  }

  const openNotificationType = (e, item, type) =>{
    publishType = type
    selectedAssessment = item
    toggleResultModal(e)
  }

  const openAnswerWindow = (e, item) => {
    e.preventDefault()
    update(e, item, 'is_answer_window_open')
  }

  const updateNotificationItem = (req) =>{
    req[publishType] = true
    
    AssessmentService.update(req).then((res)=>{
      if(res.status==204){
        if(req.email_notification_type)
          selectedAssessment.email_notification_type = req.email_notification_type

        if(req.journey_group_ids) 
          selectedAssessment.journey_group_ids = req.journey_group_ids

        selectedAssessment[publishType] = true
        setAssessmentList([...assessmentList])
      }
    })
  }

  /*
  const updateResult = (e) =>{
    update(e, selectedAssessment, 'is_result_published')
  }*/

  const updatePreview = (e, item) =>{
    update(e, item, 'is_previewed')
    props.history.push(`/career_training/question/${item.journey_category_id}/${item.journey_profile_id}/${item.id}`)
  }

  const showMenu = (e, a) => {
    selectedAssessment = a
    toggleMenuModal(e)
  }

  const routeAssessment = (assessment) =>{
    props.history.push(`/career_training/assessments/${params.journey_category_id}/${params.journey_profile_id}?id=${assessment.id}`)
    /*setAssessmentList(assessmentList.filter(i => i.id == assessment.id))*/
  }

  const openQuestionModal = (e, question) =>{
    selectedStepAssessment = question
    toggleQuestionModal(e)
    toggleMenuModal(e)
  }

  const clone = (e, item) =>{
    e.preventDefault()
    let req = {
      'id':item.id,
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
    }

    AssessmentService.clone(req).then((res)=>{
      if(res.status == 200){
        setAssessmentList([res.data.assessment, ...assessmentList])  
      }
    })
  }

  const menuItems = ({item}) =>{
    const form_template_id = item.assessment_form_id;
    const title = item.data && item.data.record_title.replace(/\s/g, '_')

    const editAccess = CheckAccess.isPublishedItemEditable(currentUser, share, item)
    
    return(
      <Fragment>
        <CardMenuInfo formId={item.assessment_form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name} />

        {editAccess && <MenuItem id="assessment_icon" onSelect={e => openQuestionModal(e, {})}
          icon="far fas fa-question-circle" action="Add Question"/>
        }

        <MenuItem id="question_form_icon" 
          onSelect={e => updatePreview(e, item)}
          icon="far fas fa-eye" action="Preview Question Form"/> 

        {item.is_previewed &&   
          <MenuItem id="publish_icon" 
            onSelect={(e) => openNotificationType(e, item, 'is_published')}
            icon={item.is_published?'far fas fa-check-circle':'far fas fa-newspaper'} 
            action="Publish Assessment to Students"/>
        }

        {item.is_published && 
          <MenuItem id="answer_window_open" 
            onSelect={(e) => openAnswerWindow(e, item)}
            icon={item.is_answer_window_open?'far fas fa-check-circle':'far fas fa-circle'}  
            action="Open Answer Window"/> 
        }

        {item.is_published && 
          <MenuItem id="publish_answer_icon" 
            onSelect={(e) => openNotificationType(e, item, 'is_result_published')}
            icon="far fas fa-newspaper" action="Publish Result"/> 
        }

        {item.is_published && 
          <MenuItem link={`/career_training/answers/${item.journey_category_id}/${item.journey_profile_id}/${item.id}`}
            icon="far fa-eye" action="Student Interaction Status"/> 
        }

        {editAccess &&
          <MenuItem id="clone_icon" icon="fas fa-clone"
            action="Copy" onSelect={(e) => clone(e, item)}/>
        }

        {editAccess &&  <MenuItem id="edit_icon" 
          link={`/career_training/assessment/form/${item.journey_category_id}/${item.journey_profile_id}/${item.course_id}/${item.id}?edit=${editAccess}`}
          icon="far fas fa-edit" action="Edit"/>
        }
        
        {editAccess &&
          <MenuItem id="delete_icon" icon="far fas fa-trash-alt"
            action="Delete" onSelect={(e) => toggleDeleteModal(e)}/>
        }
   
        {!editAccess && 
          <p className="m-t-10 p-5 lred">
            This item can no longer be edited or deleted since it has been published. Contact the journey administrator for assistance with this item.
          </p>
        }    
      </Fragment>
    )
  }

  const Menu = ({ assessment}) => (
    <CardMainMenu activity= {assessment} showMenu={showMenu} />
  )

  const assessmentCard = assessmentList && assessmentList.map((a, i) => (
    <SwiperSlide key={i}>
      <div className={cardGridCss}>
        <Assessment assessment={a} addNotes={addNotes} addChecklist={addChecklist} 
        dataSource={data_source} menu={Menu} journeyCategory={journey_category} 
        cardHeightCss={cardHeightCss} openQuestionModal={openQuestionModal} index={i}
        typeOfQuestion={typeOfQuestion} history={props.history} share={share}
        routeAssessment={routeAssessment}/>
        {selectedAssessment.id == a.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedAssessment} 
          type="carrier_training.assessments" MenuItems={menuItems} menuHeight="365px"/>
        }
      </div>
    </SwiperSlide>
  ))

  if(queryParam.id && !loading && assessmentList.length == 0){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Not Found" message="Sorry, this message was deleted by the author"/>)
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4">
          <JourneyTitle title={course?.data?.course_name || "Assessment List"} project={journey_category}/>
        </div>
          
        <div className="col-md-8 float-right">     
          {params.course_id &&    
            <NavIcon id="add_new_assessment" dataTip="Create New Assessment" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              link={`/career_training/assessment/form/${params.journey_category_id}/${params.journey_profile_id}/${params.course_id}`}
              icon="fas fa-plus"/>
          }
              
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/career_training/project/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
          
          <NavIcon id="toggle_assessment_table" dataTip="Toggle Assessment Table" 
            onSelect={toggleAssessmentTable}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fa fa-table"/>

          <NavIcon id="toggle_search" dataTip="Toggle Search" 
            onSelect={e=>setShowSearch(!showSearch)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas far fa-search"/>

          {queryParam.id  && 
            <NavIcon id="back_icon" dataTip="Back to Assessment" 
              onSelect={e=>props.history.goBack()}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-arrow-left"/>
          }
        </div>
      </div>

      <div className="page-content row">
        {showSearch && 
          <div className="row">
          <div className="col-md-12 col-xs-12">
            <a className="input-group m-t-10" id="search_box">
              <span className="input-group-btn" style={{zIndex:"9",left:'44px'}}>
                <p type="button" className="input-sm" onClick={e=>handleSearch(e)} style={{height:'40px'}}>
                  <i className="fa fa-search lgrey" style={{fontSize:'26px'}}></i>
                </p>
              </span>
              <input type="text" onChange={e=>handleSearch(e)} placeholder="Search" className="w-98p form-control input-sm" style={{fontSize:'16px',height:'40px',textIndent:"30px"}} />
              <span className="input-group-btn" style={{zIndex:"9",right:"48px"}}>
                <p type="button" className="input-sm" onClick={e=>setShowSearch(!showSearch)} style={{height:'40px'}}>
                  <i className="fa fa-times lgrey" style={{fontSize:'26px'}}></i>
                </p>
              </span>
            </a>
          </div>
          </div>
         
        }
          
        {!showAssessmentTable && !queryParam.id && <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
          requestParams={requestParams} indexEle={indexEle} cardViewCount={cardViewCount}>
            {assessmentCard}
            {!queryParam.id && <div className="col-md-12 col-sm-12 text-center">
                <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
              </div>
            } 
          </SwiperContainer> 
        }

        {!showAssessmentTable && queryParam.id &&
          <Fragment>
            {assessmentCard}
          </Fragment>           
        }

        {showAssessmentTable && <Fragment>
          <AssessmentTable assessment={assessmentList} requestParams={requestParams} openQuestionModal={openQuestionModal}
            typeOfQuestion={typeOfQuestion} journeyCategory={journey_category} selectedAssessment={selectedAssessment} 
            togglePublishModal={togglePublishModal} share={share}/>
          </Fragment>
        }
      </div>

      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedAssessment} type="carrier_training.assessment"/>}
     
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} 
        item={selectedAssessment} type="carrier_training.assessment"/>}
     
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteAssessment}/>
      <ConfirmMenu isOpen={isResultModalOpen} toggleModal={toggleResultModal} success={publishAssessment} message="Are you sure that this Assessment is complete and ready to be published?"/>

      {isHelpMessageOpen && 
        <NotificationPopup header="Note" message={HelpMessageList.new_assessment} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      }
     
      {isQuestionModalOpen &&
        <GenericModal component={AddQuestion} questionObj={selectedStepAssessment} title="Add Question"
          isOpen={isQuestionModalOpen} toggleModal={toggleQuestionModal} assessment={selectedAssessment}
          assessmentList={assessmentList} setAssessmentList={setAssessmentList} 
          typeOfQuestion={typeOfQuestion} course={course} difficultyLevel={data_source.difficulty_level}/>
      }

      {isNotificationTypeOpen && 
        <GenericModal component={JourneyNotificationType} title="Publish" 
        isOpen={isNotificationTypeOpen} toggleModal={toggleNotificationType}
        journeyCategoryId={params.journey_category_id}
        journeyProileId={params.journey_profile_id}
        updateNotificationItem={updateNotificationItem}
        item={selectedAssessment} groupableId={params.course_id} 
        groupableType="carrier_training.course"/>
      }
      
      {isUserGroupEmailNotificationOpen &&
        <GenericModal component={JourneyUserGroupEmailTable} title="User Email Status"
          isOpen={isUserGroupEmailNotificationOpen} toggleModal={toggleUserGroupEmailNotification} 
          userList={userGroupEmailNotifications}/>
      }

      <SideMenuBar helpId="15"/>
      <ReactTooltip place="bottom" />
    </Fragment>
  )

}

export default AssessmentList;