import React, {Fragment} from "react";
import PrivateRoute from "../../../routes/PrivateRoute";
import ActivityList from "../../Journey/CollegeJourney/Activities/List/ActivityList";
import ActivityForm from "../../Journey/CollegeJourney/Activities/Form/ActivityForm";
import PortalDetails from "../../Journey/CollegeJourney/PortalPage/PortalDetails";
import StepActivityForm from "../../Journey/CollegeJourney/StepActivities/StepActivityForm";
import CareerList from "./InterViews/CareerList"
import QuestionsList from "./InterViews/QuestionsList"
import ActivityTypeList from "./Activities/List/ActivityTypeList"
import HighSchoolSchedules from '../../Journey/HighSchoolJourney/Calendars/HighSchoolSchedules'
import JourneyPlannerList from '../../Journey/HighSchoolJourney/Planners/List/JourneyPlannerList'
import PlannerFilter from '../../Journey/HighSchoolJourney/Planners/List/PlannerFilter'
import ResourceList from '../../Journey/HighSchoolJourney/Resources/List/ResourceList'
import CollegeFavouritesList from '../HighSchoolJourney/Colleges/Favourites/FavouritesList'
import CollegeProfileForm from '../HighSchoolJourney/Colleges/CollegeProfileForm/CollegeProfileForm'
import CollegeList from '../HighSchoolJourney/Colleges/SearchList/CollegeList'
import CollegeProfileList from '../HighSchoolJourney/Colleges/CollegeProfileForm/CollegeProfileList'
import CollegeReviewForm from '../HighSchoolJourney/Colleges/Reviews/ReviewForm'
import LayoutFlow from "./TreePath/LayoutFlow"

const CollegeRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/college/portal/:journey_category_id" app={props.app} component={PortalDetails} exact />
  	<PrivateRoute path="/college/activity/type/:journey_category_id?/:journey_profile_id?" setHelpId={props.setHelpId} searchString={props.searchString} component={ActivityTypeList} exact />
    <PrivateRoute path="/college/activity/:journey_category_id?/:journey_profile_id?" setHelpId={props.setHelpId} searchString={props.searchString} component={ActivityList} exact />
    <PrivateRoute path="/college/activity/form/:journey_category_id/:journey_profile_id/:form_template_id/:type/:id?" component={ActivityForm} exact />
    <PrivateRoute path="/college/step_activity/form/:journey_category_id/:journey_profile_id/:activity_id/:form_template_id/:activity_type/:id?" component={StepActivityForm} exact />
    <PrivateRoute path={["/college/interviews/:journey_category_id/:journey_profile_id","/college/interviews/:journey_category_id/:journey_profile_id"]} component={CareerList} exact />
    <PrivateRoute path={["/college/interview/questions/:journey_category_id/:journey_profile_id/:data_source_ids/:carrier_data_source_id","/college/interview/questions/:journey_category_id/:journey_profile_id/:data_source_ids/:carrier_data_source_id"]} 
      component={QuestionsList} searchString={props.searchString} exact />
    <PrivateRoute path="/college/schedules/:journey_category_id/:journey_profile_id/:journey_type" component={HighSchoolSchedules} exact />
    <PrivateRoute path="/college/resources/:journey_category_id/:journey_profile_id" searchString={props.searchString} component={ResourceList} exact />
    <PrivateRoute path={["/college/planners/:journey_category_id/:journey_profile_id"]} searchString={props.searchString} component={JourneyPlannerList} exact />
    <PrivateRoute path="/college/favourite/colleges/:journey_category_id/:journey_profile_id" component={CollegeFavouritesList} exact />
    <PrivateRoute path="/college/favourite/colleges/:id" component={CollegeFavouritesList} exact /> 
    <PrivateRoute path="/college/college/profile/:journey_category_id/:journey_profile_id/:id?" component={CollegeProfileForm} exact />
    <PrivateRoute path="/college/college/search/:journey_category_id/:journey_profile_id/:college_profile_id?" component={CollegeList} exact />
    <PrivateRoute path="/college/college/profiles/:journey_category_id/:journey_profile_id" component={CollegeProfileList} exact />
    <PrivateRoute path="/college/college/review/:type/:form_template_id/:journey_category_id/:journey_profile_id/:college_id/:favourite_id/:id?" component={CollegeReviewForm} exact />
    <PrivateRoute path="/college/path/:journey_category_id/:journey_profile_id?" component={LayoutFlow} exact/>
  </Fragment>
);

export default CollegeRouter;
