import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import Cs from "../../services/CommonService"
import { ChatGrayIcon, CheckListGrayIcon, HamburgerMenuIcon } from "../Common/ImageIcons"
import TrackRelationship from './TrackRelationship/TrackRelationship'

const Contact = (props) => {
  const {member, membersType} = props
  let data = member.data
  let ds = member.data_source
  let borderColor = null
  let category = null
  let bodyStyle = { height: "238px", overflowY: "auto" }
  let profileImage = 'images/avatars/2s.png'

  const SpanField = ({name, label, styleName}) =>(
    <span className={styleName}>{ds[name] && ds[name][label]}</span>
  )

  const SpanList = ({name, label, styleName}) => ds[name] && ds[name].map((d, i) => (
    <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
  ))

  try {
    borderColor = membersType[member.form_type_id].bg_color
    category = membersType[member.form_type_id].label
  } catch (e) {

  }

  const cardStyle = {
    borderTop: "5px solid " + ( borderColor || "#27a1fb"),
    height: "360px",
  }

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 m-t-10">
        <div className="card-title card-title-gray flex aligncenter p10 p-b-15">
          <div className="flex aligncenter">
            <img src={profileImage} className="rounded-circle bg-highlight" width="50"/>
          </div>
          <div className="flex aligncenter">
            <a id="head" className="content" href="#">
              <div className="w-100">
                <h4 className="fw700 card-heading truncate">{member.data.name}</h4>
                <p className="f12">{category} since {Cs.formatUpdateDate(member.created_at)}</p>
              </div>
            </a>
          </div>
          <div className="menu-drop-icon flex justifycenter aligncenter">
            {/* <a href="#">
              <img src="./images/menu-drop.png" alt="" />
            </a> */}
          </div>
        </div>
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
            <table className="table card-table">
              <tbody>
                <tr>
                  <TrackRelationship  tracking={member.relationship_trackings} member={member} />
                </tr>
                <tr>
                  <td>
                    <SpanField name="status" label="label" styleName="pill bg-highlight white pull-right" />
                    <strong>Address</strong>
                    <div>&nbsp;{data.street_1}<br/>{data.street_2}<br/>{data.city}<br/> {data.state}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Personal</strong>
                    <a href="tel:+{data.mobile}">&nbsp;{data.tele}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Mobile</strong>
                    <a href="tel:+{data.telephone}">&nbsp;+{data.cell}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    Marketing: <SpanList name="marketing" label="label" styleName="pill bg-highlight white" />
                  </td>
                </tr>
                <tr>
                  <td>
                    Level: <SpanList name="level" label="label" styleName="pill bg-highlight white" />
                  </td>
                </tr>
              </tbody>
            </table>              
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          <ul className="flex aligncenter">
            <li className="m-r-25">
              <ChatGrayIcon onSelect={(e) => props.addNotes(e, member)}/>
            </li>
            <li className="m-r-25">
              <CheckListGrayIcon onSelect={(e) => props.addChecklist(e, member)}/>
            </li>
            <li className="m-r-25">
              <i className="far fa-envelope font-18 text-muted"></i>
              <span className="badge up bg-lgrey">{member.checklists_count || 0}</span>
            </li>
          </ul>
          <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, member)}/>
        </div>
      </div>
    </Fragment>
  )
}

export default Contact;