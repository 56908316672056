import React,{Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import ForumList from '../../components/Forums/List/ForumList'
import ForumNewForm from '../../components/Forums/Form/ForumNewForm'
import ForumReplyForm from '../../components/Forums/Form/ForumReplyForm'

const ForumRouter =  (props) => (
  <Fragment>  
    <PrivateRoute path="/forums" component={ForumList} searchString={props.searchString} permissionType='role_manager.training_content' exact />
    <PrivateRoute path="/forums/create/:id?" component={ForumNewForm} exact/>
    <PrivateRoute path="/forums/reply/:id?" component={ForumReplyForm} exact/>
  </Fragment>
)

export default ForumRouter;