import React, {useEffect, Fragment, useRef, useState, useContext} from "react"
import HamBar from "./../../../components/Authentication/HamBar"
import Logo from "./../../../components/Authentication/Logo"
import SocialShareBtn from "../../../components/Common/SocialShareBtn"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"

const HeaderSnippet = (props) => {
  const {state: currentUser, dispatch, isAuthenticated: isAuthenticated} = useContext(AuthContext)

  return (
    <div className="col-sm-12">
      <div className="col-xs-6">
        <Logo/>
      </div>
      <div className="col-xs-6">
        <div className="d-contents">
          <div className="hidden d-block m-t-55">
            <div className="bottom-10 social-sharing pull-right m-t-5 m-r-60">
              <SocialShareBtn />
            </div>
            {!currentUser.isAuthenticated && (
              <div className="pull-right m-r-40 m-t-5">
                <a href="/signup"
                  className="p-5 pull-left white p-10 font-16 button button-full shadow-large button-round-small"
                  style={{backgroundColor: "rgb(255,91,23)"}}>
                  Sign Up
                </a>
                <a href="/signin"
                  className="p-5 pull-right m-l-10 white p-10 font-16 button button-full shadow-large button-round-small"
                  style={{backgroundColor: "rgb(255,91,23)"}}>
                  Sign In
                </a>
              </div>
            )}
          </div>
          <HamBar/>
        </div>
      </div>
    </div>
  )
}

export default HeaderSnippet;