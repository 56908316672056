import React, {Fragment,useState} from "react"
import {Link} from "react-router-dom"
import useStyle from '../../../../../hooks/useStyle'
import useSort from '../../../../../hooks/useSorting'
import SortDirection from '../../../../../components/Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc'
import arrayMove from '../../../../Common/arrayMove'
import Cs from "../../../../../services/CommonService"
import {PillDsList,PillDsField} from '../../../../Journey/UserRoles/NotificationPill'
import { Table, THead, TH, TR, TD, TBody } from "../../../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../../../Common/ImageIcons";

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>
})

const PlannerContentTableList = ({plannerList, requestParams, selectedPlanner, updatePrivacy, toggleDeleteModal, getPlannerList}) => {

  const {sort} = useSort(requestParams, getPlannerList)
  
  const privacyType = (key) => {
    if(key == 'public'){
      return (<div className="font-16">C</div>);
    }else if(key == 'private'){
      return (<div className="font-16">P</div>);
    }else if(key == 'shared'){
      return (<div className="font-16">PC</div>);
    }
  }

  const TableData = ({plannerList}) => plannerList && plannerList.map((o, k) =>{
    return(
      <TR key={k}>
        <TD className="text-gray" data-title="Id">{o.record_id}</TD>
        <TD data-title="Title">{o.data.planner_heading || o.data.name}</TD>
        <TD data-title="Created Date">{Cs.formatUpdateDate(o.updated_at)}</TD>
        <TD data-title="Content Type">{privacyType(o.privacy_type)}</TD>
        <TD data-title="Created By">{o.author.name}</TD>
        <TD data-title="Edit">
          <Fragment>
            <Link id="edit_form_icon" to={`/highschool/planner/edit/${o.planner_type}/${o.planner_form_id}/${o.id}`}
              data-tip="Edit Form">
                <i className="far fa-file"/>
            </Link>
          </Fragment>
        </TD>
        <TD data-title="Privacy" >
          <a href="#" onClick={(e) => updatePrivacy(e, o)}>
            <i className={(o.privacy_type == "community"? "far fa-check-circle": "far fa-circle") + " font-15"}></i>
            <span className="font-13 text-cap">{o.privacy_type}</span>
          </a>
        </TD>
        <TD data-title="Options" >
          <div className="flex aligncenter">
            <LinkDeleteIcon id="delete_planner" data-tip="Delete planner" onSelect={(e)=>toggleDeleteModal(e, o)}>
            </LinkDeleteIcon>
          </div>
        </TD>
      </TR>
    )
  })

  return (
      <SortableContainer distance={1} lockAxis="y" useDragHandle>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>Id
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('record_title')}>Title
                <SortDirection sort_column={requestParams.sort_column} column="record_title" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created')}>Created Date 
                <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('content_type')}>Privacy Type
                <SortDirection sort_column={requestParams.sort_column} column="content_type" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_by')}>Created By
                <SortDirection sort_column={requestParams.sort_column} column="created_by" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('record_id')}>Edit
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('is_active')}>Activate Privacy
                <SortDirection sort_column={requestParams.sort_column} column="is_active" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_at')}>Options
                <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/> 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData plannerList={plannerList} />
          </TBody>
        </Table>
      </SortableContainer>
  )

}

export default PlannerContentTableList;