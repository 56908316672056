import React, { useEffect, useState, useContext} from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormWizard from '../../../../../components/FormBuilder/FormWizard';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import TrainingContentService from "../TrainingContentService";
import FormContainer from '../../../../FormBuilder/FormContainer';

let formMode = null; 
let content = {}; 
let data = {}; 
let formFn = {};  
let form_template_id = null;

function TrainingContentForm(props) {
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  
  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    let req = {
      'id': params.id,
      'journey_category_id': params.journey_category_id
    }
    TrainingContentService.get(req).then((res) => {
      if(res.status==200){
        content = res.data.content;
        data = content.data || {};
        form_template_id = content.content_form_id
        setFormRendered(true);
      }
    })
  }
  
  useEffect(() => { 
    content = {}; 
    data = {}; 
    formFn = {
      'form_type':'carrier_training.content', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    }
    formMode = 'create-form-submissions';
    data = {}; 
    form_template_id = params.form_template_id

    if(params.id){
      get(params)
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (req) => {
    req.created_by = currentUser.id;
    req.content_type = params.type;
    req.content_form_id = params.form_template_id; 
    req.form_wizard_id = queryParam.form_wizard_id;
    req.module_id = queryParam.module_id;
    req.topic_id = queryParam.topic_id;

    TrainingContentService.create(req).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    })
  }

  const update = (req) => {
    req.updated_by = currentUser.id;
    req.form_wizard_id = queryParam.form_wizard_id;
    
    TrainingContentService.update(req).then((res) => {
      if(res.status == 204){
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/admin/course/modules`)
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id){
    return(<FormWizard form_wizard_id={queryParam.form_wizard_id} form={content} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}/>)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={form_template_id} form={content} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default TrainingContentForm;