import React, { Fragment, useState } from "react";
import useStyle from "../../hooks/useStyle";

const team = [
  {
    rank: 1,
    name: "Lewis Hamilton",
    handle: "lewishamilton",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/L/LEWHAM01_Lewis_Hamilton/lewham01.png.transform/2col-retina/image.png",
    kudos: 36,
    sent: 31,
  },
  {
    rank: 2,
    name: "Kimi Raikkonen",
    handle: "kimimatiasraikkonen",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/K/KIMRAI01_Kimi_R%C3%A4ikk%C3%B6nen/kimrai01.png.transform/2col-retina/image.png",
    kudos: 31,
    sent: 21,
  },
  {
    rank: 3,
    name: "Sebastian Vettel",
    handle: "vettelofficial",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/S/SEBVET01_Sebastian_Vettel/sebvet01.png.transform/2col-retina/image.png",
    kudos: 24,
    sent: 7,
  },
  {
    rank: 4,
    name: "Max Verstappen",
    handle: "maxverstappen1",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/M/MAXVER01_Max_Verstappen/maxver01.png.transform/2col-retina/image.png",
    kudos: 22,
    sent: 4,
  },
  {
    rank: 5,
    name: "Lando Norris",
    handle: "landonorris",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/L/LANNOR01_Lando_Norris/lannor01.png.transform/2col-retina/image.png",
    kudos: 18,
    sent: 16,
  },
  {
    rank: 6,
    name: "Charles Leclerc",
    handle: "charles_leclerc",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/C/CHALEC01_Charles_Leclerc/chalec01.png.transform/2col-retina/image.png",
    kudos: 16,
    sent: 6,
  },
  {
    rank: 7,
    name: "George Russell",
    handle: "georgerussell63",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/G/GEORUS01_George_Russell/georus01.png.transform/2col-retina/image.png",
    kudos: 10,
    sent: 21,
  },
  {
    rank: 8,
    name: "Daniel Ricciardo",
    handle: "danielricciardo",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/D/DANRIC01_Daniel_Ricciardo/danric01.png.transform/2col-retina/image.png",
    kudos: 7,
    sent: 46,
  },
  {
    rank: 9,
    name: "Alexander Albon",
    handle: "alex_albon",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/A/ALEALB01_Alexander_Albon/alealb01.png.transform/2col-retina/image.png",
    kudos: 4,
    sent: 2,
  },
  {
    rank: 10,
    name: "Carlos Sainz Jr.",
    handle: "carlossainz55",
    img: "https://www.formula1.com/content/dam/fom-website/drivers/C/CARSAI01_Carlos_Sainz/carsai01.png.transform/2col-retina/image.png",
    kudos: 1,
    sent: 24,
  },
];

const LeaderShipDashboard = (props) => {
  useStyle("sample");
  const tlist =
    team &&
    team.map((a, i) => (
      <div className="c-list__grid">
        <div className="c-flag c-place u-bg--transparent u-text--dark u-bg--yellow">
          {a.rank}
        </div>
        <div className="c-media">
          <img className="c-avatar c-media__img" src={a.img} />
          <div className="c-media__content">
            <div className="c-media__title">{a.name}</div>
            <a
              className="c-media__link u-text--small"
              href="https://instagram.com/lewishamilton"
              target="_blank"
            >
              {a.handle}
            </a>
          </div>
        </div>
        <div className="u-text--right c-kudos u-text--yellow">
          <div className="u-mt--8">
            <strong>{a.sent}</strong> 👍
          </div>
        </div>
      </div>
    ));

  return (
    <div className="l-wrapper">
      <div className="l-grid">
        <div className="l-grid__item l-grid__item--sticky">
          <div className="c-card u-bg--light-gradient u-text--dark">
            <div className="c-card__body">
              <div className="u-display--flex u-justify--space-between">
                <div className="u-text--left">
                  <div className="u-text--small">My Rank</div>
                  <h2>3rd Place</h2>
                </div>
                <div className="u-text--right">
                  <div className="u-text--small">My Score</div>
                  <h2>24</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="c-card">
            <div className="c-card__body">
              <div className="u-text--center" id="winner">
                <div className="u-text-small u-text--medium u-mb--16">
                  Top Sender Last Week
                </div>
                <img
                  className="c-avatar c-avatar--lg"
                  src="https://www.formula1.com/content/dam/fom-website/drivers/D/DANRIC01_Daniel_Ricciardo/danric01.png.transform/2col-retina/image.png"
                />
                <h3 className="u-mt--16">Daniel Ricciardo</h3>
                <span className="u-text--teal u-text--small">Daniel Ricciardo</span>
              </div>
            </div>
          </div>
          <div className="c-card">
            <div className="c-card__body">
              <div className="c-list__grid">
                <div className="c-flag c-place u-bg--transparent u-text--dark u-bg--yellow">
                  1
                </div>
                <div className="c-media">
                  <div className="c-media__content">
                    <div className="c-media__title">Activities</div>
                  </div>
                </div>
                <div className="u-text--right c-kudos u-text--yellow">
                  <div className="u-mt--8">
                    <strong>10</strong>
                  </div>
                </div>
              </div>
              <div className="c-list__grid">
                <div className="c-flag c-place u-bg--transparent u-text--dark u-bg--yellow">
                  5
                </div>
                <div className="c-media">
                  <div className="c-media__content">
                    <div className="c-media__title">Planners</div>
                  </div>
                </div>
                <div className="u-text--right c-kudos u-text--yellow">
                  <div className="u-mt--8">
                    <strong>5</strong>
                  </div>
                </div>
              </div>
              <div className="c-list__grid">
                <div className="c-flag c-place u-bg--transparent u-text--dark u-bg--yellow">
                 2
                </div>
                <div className="c-media">
                  <div className="c-media__content">
                    <div className="c-media__title">Meetings</div>
                  </div>
                </div>
                <div className="u-text--right c-kudos u-text--yellow">
                  <div className="u-mt--8">
                    <strong>7</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="l-grid__item">
          <div className="c-card">
            <div className="c-card__header">
              <h3>Received Kudos</h3>
              <select className="c-select">
                <option selected="selected">
                  Sunday, Feb. 23 - Sunday, Feb. 30
                </option>
              </select>
            </div>
            <div className="c-card__body">
              <ul className="c-list" id="list">
                <li className="c-list__item">
                  <div className="c-list__grid">
                    <div className="u-text--left u-text--small u-text--medium">
                      Rank
                    </div>
                    <div className="u-text--left u-text--small u-text--medium">
                      Team Member
                    </div>
                    <div className="u-text--right u-text--small u-text--medium">
                      # of Kudos
                    </div>
                  </div>
                  {tlist}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaderShipDashboard;
