import React, { Fragment, useEffect, useState, useContext } from "react"
import useStyle from "../../../hooks/useStyle"
import { Link } from "react-router-dom"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import DigitalContentService from "../../DigitalContent/DigitalContentService"
import HamBar from "../../Authentication/HamBar"
import { OptionsObjList } from "../../Common/FormInput"
import Logo from "../../Authentication/Logo"
import env from "../../../env"
import LottieLight from "../../Common/LottieLight"
import Footer from "../../Footer/Footer"
import NewsletterSnippet from "../../Authentication/NewsletterSnippet"
import SocialShareBtn from "../../Common/SocialShareBtn"
import SideMenuBar from "../../Common/SideMenuBar"
import FAQList from "../../MarketingSnippets/FAQList"
import MarketingList from "../../MarketingSnippets/MarketingList"
import querystringify from "querystringify"
import TestimonialList from "../../MarketingSnippets/TestimonialList"
import HeaderSnippet from "./HeaderSnippet"
import {Button} from "../Common"

let requestParams = {}
let pageTitle = null
let dataSource = {}

const BlogMarketing = (props) => {
  
  useStyle("spmarketing")
  useStyle("daily_dabble")
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const { state: currentUser, dispatch,isAuthenticated: isAuthenticated } = useContext(AuthContext)
  
  const [blogList, setblogList] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  /*window.addEventListener("scroll", () => {
    console.log(document.documentElement.scrollTop);
  });*/

  useEffect(() => {
    pageTitle = null
    requestParams = {
      content_type: "blog",
      page: 1,
      paginate: 1,
      per_page: 100,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: null,
      is_active:true,
      data_filter:[
        {key:'type_of_blog_item' , operator:'=' , value: currentUser.isAuthenticated ? 1: 2}            
      ],
      data_source_filter:[
        {key:'web_page.url', operator:'=', value: `1`}
      ],
      data_source_params: {
        data_source_id:[3325, 3511]
      }
    }

    getMarketingContent()
    return () => {
      requestParams = {}
      dataSource = {}
    }
  }, [currentUser.id])

  let getMarketingContent = () => {
    setIsLoading(true)
    DigitalContentService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total
        dataSource = res.data.data_source_list
        setblogList(res.data.digital_contents)
        setIsLoading(false)
      }
    })
  }

  const ImageSvgRender = ({ma}) => {
    if (ma.data.lottie) {
      return <LottieLight id={ma.id} {...ma.data.lottie} isPublic="true"/>
    } else {
      return <img src={ma.data.illustration}/>
    }
  }

  const SocialShare = ({ ma }) => {
    return (
      <div className="center-text">
        <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fa fa-share-alt" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fab fa-facebook-f" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
          <i className="fab fa-twitter" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
          <i className="fab fa-instagram" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
          <i className="fab fa-linkedin-in" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
          <i className="fab fa-whatsapp" />
        </a>
      </div>
    )
  }

  const SectionWithButton = ({blog, i}) => {
    let {data} = blog
    let color = {color:data.text_color}
    return(
      <div className="row m-b-30 p-20 section" style={{backgroundColor:data.color}}>
        {i%2 == 0 && 
          <div className="intro-img col-xs-12 col-sm-4 wk-center p-20-0">
            <ImageSvgRender ma={blog} />
          </div>
        }
        <div className="intro-content col-xs-12 col-sm-8 text-left p-20 ">
          <h3 className="bold-600 font-24" style={color}>{data.title}</h3>
          <p className="rich-text lg-p mt-2" dangerouslySetInnerHTML={{ __html: data.notes }} style={color}></p>
          <Button item={blog} eleType='button'/>
        </div>
        {i%2 == 1 && 
          <div className="col-xs-12 col-sm-4 wk-center">
            <ImageSvgRender ma={blog} />
          </div>
        }
      </div>
    )
  }

  const blogListCard = blogList.map((blog, i) => {
     return <SectionWithButton blog={blog} i={i}/>
  })

  if(isLoading)return null;

  return (
    <Fragment>
      <div className="page-content row m-b-30 m-t-10">
        <div className="header bg-highlight ht-30"></div>
        <div className="col-sm-12">
          <div className="p-20 ht-150">
            <HeaderSnippet />
          </div>
        </div>

        <main>
          <div className="col-xs-12">
            <div className="bg-black white p-7">
              <h1 className="text-left m-l-20 m-r-20 mobile-text-center"> 
              <select name="journey" onChange={e => getMarketingContent(e.target)} 
                className="form-control font-18 webkit-appearance-none select-background" defaultValue="-">
                  <option value="">Select a journey</option>
                  <OptionsObjList list={dataSource.blog_pages.options} label_key="label" id_key="pk_id"/>
                </select>
              </h1>
            </div>
          </div>
          <section className="intro">
            <div className="w-100p">
              {blogListCard}
            </div>
          </section>
          {!currentUser.isAuthenticated && <NewsletterSnippet />}
        </main>
      
        <section className="row">
          <div className="col-xs-12"
            style={{
              width: "100%",
              display: "flex",
              whiteSpace: "nowrap",
              overflowX: "scroll",
            }}
          >
            <MarketingList web_page="40" screen="col-md-3" />
          </div>
        </section>

        <section className="row">
          <FAQList web_page="40" />
        </section>
        
        <footer></footer>
      </div>
      
      <SideMenuBar helpId="2" />
      
      <Footer />
    </Fragment>
  )
}

export default BlogMarketing;