import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Prompt } from 'react-router-dom'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import InputTextField from '../../components/FormBuilder/InputComponents/InputTextField'
import InputTextArea from '../../components/FormBuilder/InputComponents/InputTextArea'
import InputSelect from '../../components/FormBuilder/InputComponents/InputSelect'
import InputRadio from '../../components/FormBuilder/InputComponents/InputRadio'
import InputCheckbox from '../../components/FormBuilder/InputComponents/InputCheckbox'
import InputDataTimePicker from '../../components/FormBuilder/InputComponents/InputDataTimePicker'
import InputColorPicker from '../../components/FormBuilder/InputComponents/InputColorPicker'
import InputMultiSelect from '../../components/FormBuilder/InputComponents/InputMultiSelect'
import InputMultiSelectSearch from '../../components/FormBuilder/InputComponents/InputMultiSelectSearch'
import InputMultiFileUpload from '../../components/FormBuilder/InputComponents/InputMultiFileUpload'
import InputFileUpload from '../../components/FormBuilder/InputComponents/InputFileUpload'
import InputMultiRating from '../../components/FormBuilder/InputComponents/InputMultiRating'
import InputRating from '../../components/FormBuilder/InputComponents/InputRating'
import InputTags from '../../components/FormBuilder/InputComponents/InputTags'
import InputExplanatoryText from '../../components/FormBuilder/InputComponents/InputExplanatoryText'
//import InputMapLocation from '../../components/FormBuilder/InputComponents/InputMapLocation'
import InputSlider from '../../components/FormBuilder/InputComponents/InputSlider'
import InputAutoComplete from '../../components/FormBuilder/InputComponents/InputAutoComplete'
import InputSignaturePad from '../../components/FormBuilder/InputComponents/InputSignaturePad'
import InputRichText from '../../components/FormBuilder/InputComponents/InputRichText'
import InputRichImageMap from '../../components/FormBuilder/InputComponents/InputRichImageMap'
import InputLottieSvg from '../../components/FormBuilder/InputComponents/InputLottieSvg'
import FormHelper from '../../components/FormBuilder/FormHelpers'
import CommonService from '../../services/CommonService'
//import { useForm } from "react-hook-form";
import Tabs, { TabPane } from 'rc-tabs'
import FormSubmit from '../../components/FormBuilder/FormSubmit'
import ErrorsList from '../../components/FormBuilder/ErrorsList'
import ExitForm from '../../components/Modals/ExitForm'
import PreLoadSpinner from '../../components/PreLoadSpinner'
import DataSourceListModal from '../DataSources/DataSourceListModal'
import useModal from '../../hooks/useModal'
import AddLocale from '../../components/Locale/AddLocale'
import GenericModal from '../../components/Modals/GenericModal'
import InputTableView from './InputComponents/InputTableView'
import useStyle from "../../hooks/useStyle"

//let template = {}
let readOnly = true
let errors = {invalid:{}}
let noOfTabs = 0
let template = {}
let isFormSubmitted = false
let selectedFormField = {}

const DynamicForm = (props) => {
  
  const { state:currentUser, dispatch } = useContext(AuthContext)
  currentUser.user = currentUser.user || {}
  const screen = currentUser.screen
  
  //const { handleSubmit, register, errors, setValue} = useForm()
  const [activeKey, setActiveKey] = useState('0')
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const {isOpen: isDataSourceModalOpen, toggleModal: toggleDataSourceModal} = useModal()
  const {isOpen: isFieldLabelOpen, toggleModal: toggleFieldLabelModal} = useModal()

  let params = useParams()
  const history = useHistory()

  const forceUpdate = () =>{
    setCount(value => ++value)
  }

  props.formFn.refreshForm = () => forceUpdate()

  const validationProps = {
    'forceUpdate':forceUpdate,
    'errors':errors
  }

  const onSubmit = (draft, event) => {
    setLoading(true)
    //ct_form.$valid = false;
    event.stopPropagation()
    isFormSubmitted = true

    props.form.updated_by = currentUser.user.id
    props.form.draft = draft?true:false
    props.form.data = props.data

    if(props.formMode == 'create-form-submissions'){
      props.form.created_by = currentUser.user.id
      props.onCreate(props.form)
     // setLoading(false)
    }else{
      props.onUpdate(props.form)
      //setLoading(false)
    }
  }

  useEffect(() => {
    getFormTemplate()
    return () => {
      template = {}
      noOfTabs = 0
      readOnly = true
	    errors = {invalid:{}}
	    isFormSubmitted = false
    }
  }, [props.formId])

  const getFormTemplate = () => {
    if(props.formId) {
      CommonService.getFormTemplateById(props.formId).then((tf) => {
        setForm(tf)
      })
    }else if(props.user_form_id){
      let req = { id: props.user_form_id }
      CommonService.getUserForm(req).then((tf) => {
        setForm(tf)
      })
    }
    setLoading(false)
  }

  const setForm = (tf) =>{
    //template = tf;
    noOfTabs = tf.template_fields.length;
    tf.fill_info = tf.fill_info || 'Please fill out the following fields';
    if(props.formMode == 'create-form-submissions'){
      readOnly = false;
    }else if(props.formMode == 'edit-form-submissions'){
      readOnly = !(props.form.created_by == currentUser.user.id || props.formFn.editPermission)
    }else if(props.formMode == 'view-form-submissions'){
      tf.fill_info = 'View out the following fields';
      tf.post.details ='View Details'
      readOnly = true;
    }
    template = tf;
    props.setPost(tf.post)  
  }

  var callback = function(key) {
    setActiveKey(key)
  }

  let nextTab = () =>{
    let i = (parseInt(activeKey) +1).toString()
    setActiveKey(i)
  }

  let previousTab = () =>{
    let i = (parseInt(activeKey)-1).toString()
    setActiveKey(i)
  }

  let openDataSourceModal = (field) =>{
    selectedFormField = field
    toggleDataSourceModal()
  }

  let openFieldLabelModal = (field) =>{
    selectedFormField = field
    toggleFieldLabelModal()
  }

  props.formFn.setLoading = (value) => {
    setLoading(value)
  }

  const ChildElementList = ({childField, pkey}) => childField.map( (field, key )=> {

    let result = FormHelper.checkFieldConditionality(field, props.data)
    if(!result){
      delete errors[field.client_id]
      delete errors.invalid[field.client_id]
      return;
    }

    if(field.required)FormHelper.checkFieldValidity(field, props.data, errors, pkey)

    if(activeKey!=pkey)return; 
    
    field.formTemplateId = props.formId

    let fieldProps = {
      'field':field, 
      'formData':props.data, 
      'formFn':props.formFn,
      'readOnly':readOnly,
      'position': key,
      'openDataSourceModal':openDataSourceModal,
      'openFieldLabelModal':openFieldLabelModal,
      'currentLocale':currentUser.user.current_locale
    }

    switch (field.component) {
      case 'textInput':
        FormHelper.onChangeExp(field, props.data)
        return <InputTextField key={key} {...fieldProps} {...validationProps} />
        break;
      case 'textArea':
        return <InputTextArea key={key} {...fieldProps} {...validationProps} />
        break;
      case 'timepicker':
        return <InputDataTimePicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'datepicker':
        return <InputDataTimePicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'checkbox':
        return <InputCheckbox key={key} {...fieldProps} {...validationProps} />
        break;
      case 'radio':
        return  <InputRadio key={key} {...fieldProps} {...validationProps} />
        break;
      case 'select':
        return <InputSelect key={key} screen={screen} {...fieldProps} {...validationProps} />
        break;
      case 'multi_select':
        if(field.is_dynamic_data){
          return <InputMultiSelectSearch key={key} {...fieldProps} {...validationProps} />  
        }else{
          return <InputMultiSelect key={key} {...fieldProps} {...validationProps} />
        }
        break;
      case 'slider':
        return <InputSlider key={key} {...fieldProps} {...validationProps} />
        break;
      case 'autocomplete':
        return <InputAutoComplete key={key} {...fieldProps} {...validationProps} />
        break;
      case 'imageupload':
        return <InputFileUpload key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'multi_file_upload':
        return <InputMultiFileUpload key={key} formId={props.form.id} {...fieldProps} {...validationProps} />
        break;
      case 'richtext':
        return <InputRichText key={key} formId={props.form.id} user={currentUser.user} {...fieldProps} {...validationProps} />
        break;
      case 'explanatory_text':
        return <InputExplanatoryText key={key} {...fieldProps} {...validationProps} />
        break;
      case 'color_picker':
        return <InputColorPicker key={key} {...fieldProps} {...validationProps} />
        break;
      case 'rating':
        if(field.data_source_id){
          return <InputMultiRating key={key} {...fieldProps} {...validationProps} />
        }else{
          return <InputRating key={key} {...fieldProps} {...validationProps} />
        }
        break;
      case 'input_tags':
        return <InputTags key={key} {...fieldProps} {...validationProps} />
        break;
      case 'signature':
        return <InputSignaturePad key={key} {...fieldProps} {...validationProps} />
        break;
      case 'rich_image_map':
        return <InputRichImageMap key={key} user={currentUser.user} {...fieldProps} {...validationProps} />
        break;
      case 'lottie_svg':
         return <InputLottieSvg key={key} user={currentUser.user} {...fieldProps} {...validationProps} />
        break;
      case 'table_view':
        return <InputTableView key={key} screen={screen} user={currentUser.user} {...fieldProps} {...validationProps} />
        break;
      default:
        return <Fragment key={key}>Not Implemented</Fragment>
      }
    }
  )

  /*const FormTabs = () => template.template_fields.map((parentField, key) =>{
    if(!FormHelper.checkFieldConditionality(parentField, props.data)){
      return null
    }

    return(
      <Fragment key={key}>
        <TabPane tab={parentField.label} forceRender="true">
          <ChildElementList pkey={key} childField={parentField.child_template_fields}/>
        </TabPane>
      </Fragment>
    )
  })*/

  return (
    <Fragment>
      <div className="bg-white p20 card-min-ht">
        {readOnly && 
          <div className='p-7 lgrey'>This form is active and cannot be edited</div>
        }
        
        <form>
          <ErrorsList errors={errors} formFn={props.formFn} setActiveKey={setActiveKey}/>
          {!readOnly && !isFormSubmitted && <Prompt message="Are you sure you want to leave?"/>}
          <Tabs activeKey={activeKey} onChange={callback} moreIcon="+">
            {template.id && template.template_fields.map((parentField, key) =>
              <TabPane key={key} tab={parentField.label} forceRender="true">
                <div className="form-ht">
                  <div className="row">
                    {FormHelper.checkFieldConditionality(parentField, props.data) &&  
                      <ChildElementList pkey={key} childField={parentField.child_template_fields}/>
                    }
                  </div>
                </div>
              </TabPane>
            )}
          </Tabs>
          <div className="clerfix"/>
          { loading ? 
            <div className="spinner"/> : 
            <FormSubmit errors={errors} form={props.form} readOnly={readOnly} 
              onCancel={props.onCancel} onSubmit={onSubmit} activeKey={activeKey} 
              previousTab={previousTab} nextTab={nextTab} noOfTabs={noOfTabs} 
              formFn={props.formFn} forceUpdate={forceUpdate}>
            </FormSubmit>
          }
          <small className="m-l-5">cT{template.updated_at}-{template.id}</small><br/>
          <small className="m-l-5 lgrey">
            <i className="m-r-5 far fa-copyright" aria-hidden="true"></i>
            {new Date().getFullYear()} IPIPAL Inc. All rights reserved.
          </small>
        </form>
      </div>

      {isDataSourceModalOpen && 
        <DataSourceListModal form_template_id={template.id} 
        data_source_id={selectedFormField.data_source_id} 
        journey_profile_id={params.journey_profile_id}
        toggleModal={toggleDataSourceModal}/>
      }

      {isFieldLabelOpen && 
        <GenericModal component={AddLocale} defaultLabel={selectedFormField.label} 
        localeType="form_field" title={selectedFormField.label} 
        toggleModal={toggleFieldLabelModal} 
        onCancel={toggleFieldLabelModal} labelObj={selectedFormField}/>
      }
      
    </Fragment>
  )
    
}

export default DynamicForm;