import React, {Fragment, useState} from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'

const ConfirmSubscription = ({ isOpen, toggleModal, onSuccess, message, title, plan, isActivePlan }) =>{ 
    
    const [isTermsPrivacyAgreed, setTermsPrivacyAgreed] = useState(false)

    return(ReactDOM.createPortal(
      <Fragment>
        <div className="menu-hider menu-active d-block trf-0"/>  
        <div id="menu-confirm" className="menu menu-box-modal round-medium menu-active modal-xs nonbold br-grey br-lft2">
            <h1 className="center-text uppercase top-30 font-16">Are you sure?</h1>
            <p className="boxed-text-large font-18">
                Do you wish to select this subscription plan and agree with the terms and conditions?
            </p>
            
            <div className="center-text" onClick={()=>setTermsPrivacyAgreed(!isTermsPrivacyAgreed)}>
              <input type="checkbox" className="wh-25 t-6" id="confirm_check" readOnly checked={isTermsPrivacyAgreed}/>
              <span htmlFor="confirm_check" className="m-l-5 font-16 font-600">
                I acknowledge and agree to the Subscription<br/>  
                <Link to="/subscription_terms"><u>Terms and Conditions</u></Link>
              </span>
            </div>
            
            <div className="confirm_content left-20 right-20 m-t-10">
                <div className="one-half">
                    <div className={"close-menu button button-center-large button-s button-round-small nonbold underline " + (isTermsPrivacyAgreed ? 'green':'red')}
                    onClick={e => isTermsPrivacyAgreed ? onSuccess() :''}>
                        YES
                    </div>
                </div>
                <div className="one-half last-column">
                    <div className={"close-menu button button-center-large button-s button-round-small nonbold underline "+(isTermsPrivacyAgreed ? 'red':'green')}
                    onClick={e => toggleModal(e)}>
                        NO
                    </div>
                </div>
                <div className="clear"/>
            </div>
        </div>
      </Fragment>, document.body)
    )
}

export default ConfirmSubscription;