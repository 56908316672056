import React, {Fragment} from 'react';
import PrivateRoute from "../../../routes/PrivateRoute";
import PlannerFilter from '../../Journey/HighSchoolJourney/Planners/List/PlannerFilter'
import JourneyPlannerList from '../../Journey/HighSchoolJourney/Planners/List/JourneyPlannerList'
import ActivityList from '../../Journey/HighSchoolJourney/Activities/List/ActivityList'
import ResourceList from '../../Journey/HighSchoolJourney/Resources/List/ResourceList'
import ActivityForm from '../../Journey/HighSchoolJourney/Activities/Form/ActivityForm'
import PlannerForm from '../../Journey/HighSchoolJourney/Planners/Form/PlannerForm'
import ResourceForm from '../../Journey/HighSchoolJourney/Resources/Form/ResourceForm'
import ReviewForm from '../../Journey/HighSchoolJourney/Resources/Form/ReviewForm'
import PortalDetails from '../../Journey/HighSchoolJourney/HighSchoolPortalPage/PortalDetails'
import StepActivityForm from '../../Journey/HighSchoolJourney/StepActivities/StepActivityForm'
import HighSchoolSchedules from '../../Journey/HighSchoolJourney/Calendars/HighSchoolSchedules'
import CollegeFavouritesList from './Colleges/Favourites/FavouritesList'
import CollegeProfileForm from './Colleges/CollegeProfileForm/CollegeProfileForm'
import CollegeList from './Colleges/SearchList/CollegeList'
import CollegeProfileList from './Colleges/CollegeProfileForm/CollegeProfileList'
import Suggestions from './SuggestionPortal/Suggestions'
import CollegeReviewForm from './Colleges/Reviews/ReviewForm'
import StudentProfileList from './StudentProfiles/List/StudentProfileList'
import StudentProfileForm from './StudentProfiles/StudentProfileForm'
import GradePathPortal from "./GradePath/GradePathPortal"
import MyEduProfile from "./MyProfile/MyEduProfile"
import EduPublicProfile from "./MyProfile/EduPublicProfile"
import TreePath from "./TreePath/TreePath"
import LayoutFlow from "./TreePath/LayoutFlow"
import ActivityTypeList from "./Activities/List/ActivityTypeList"
import CollegeSuggestionList from "./Colleges/CollegeSuggestionList"
import MyAdmissionProfile from "../../Journey/HighSchoolJourney/Admissions/List/MyAdmissionProfile"
import CollegeDetails from './Colleges/SearchList/CollegeDetails'
import CollegeCompareList from './Colleges/SearchList/CollegeCompareList'
import StepActivityResponseForm from './StepActivityResponses/StepActivityResponseForm'
import HSReelList from './Reels/HSReelList';

const HighSchoolRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/highschool/activity/type/:journey_category_id?/:journey_profile_id?" setHelpId={props.setHelpId} searchString={props.searchString} component={ActivityTypeList} exact />	
    <PrivateRoute path="/highschool/resource/create/" searchString={props.searchString} component={ResourceForm} exact />
    <PrivateRoute path="/highschool/resource/edit/:id?" searchString={props.searchString} component={ResourceForm} exact />
    <PrivateRoute path="/highschool/resource/review/form/:journey_category_id/:journey_profile_id/:education_resource_id?/:id?" searchString={props.searchString} component={ReviewForm} exact />
    <PrivateRoute path="/highschool/resources/:journey_category_id/:journey_profile_id" searchString={props.searchString} component={ResourceList} exact />
    <PrivateRoute path="/admin/resources" searchString={props.searchString} component={ResourceList} permissionType='role_manager.enrichment' exact />
    <PrivateRoute path="/highschool/activity/:journey_category_id?/:journey_profile_id?" searchString={props.searchString} component={ActivityList} exact />
    <PrivateRoute path="/highschool/activity/edit/:journey_category_id?/:journey_profile_id/:id?" component={ActivityForm} exact />
    <PrivateRoute path="/highschool/activity/form/:journey_category_id/:journey_profile_id/:type/:form_template_id?" component={ActivityForm} exact />
    <PrivateRoute path="/highschool/step_activity/form/:journey_category_id/:journey_profile_id/:activity_id/:form_template_id/:activity_type/:id?" component={StepActivityForm} exact />
    <PrivateRoute path="/highschool/planner/edit/:type/:form_template_id/:id?" component={PlannerForm} exact />
    <PrivateRoute path="/highschool/planner/form/:type/:form_template_id?/:activity_id?" component={PlannerForm} exact />
    <PrivateRoute path="/highschool/planners/:journey_category_id/:journey_profile_id" searchString={props.searchString} component={JourneyPlannerList} exact />
    <PrivateRoute path="/admin/highschool/planners" searchString={props.searchString} component={JourneyPlannerList} permissionType='role_manager.planner' exact />
    <PrivateRoute path="/highschool/planners/filter/:journey_category_id/:journey_profile_id" component={PlannerFilter} exact/>
    <PrivateRoute path="/highschool/project/portal/:journey_category_id?" app={props.app} component={PortalDetails} exact style="org_details"/>
  	<PrivateRoute path="/highschool/favourite/colleges/:journey_category_id/:journey_profile_id" component={CollegeFavouritesList} exact />
  	<PrivateRoute path="/highschool/favourite/colleges/:id" component={CollegeFavouritesList} exact /> 
    <PrivateRoute path="/highschool/college/profile/:journey_category_id/:journey_profile_id/:id?" component={CollegeProfileForm} exact />
    <PrivateRoute path="/highschool/college/search/:journey_category_id/:journey_profile_id/:college_profile_id?" component={CollegeList} exact />
    <PrivateRoute path="/highschool/college/profiles/:journey_category_id/:journey_profile_id" component={CollegeProfileList} exact />
    <PrivateRoute path="/highschool/suggestions/:journey_category_id/:journey_profile_id" component={Suggestions} exact />
    <PrivateRoute path={["/highschool/college/review/:type/:form_template_id/:journey_category_id/:journey_profile_id/:college_id/:favourite_id?/:id?", "/college/review/:type/:form_template_id/:college_id/:id?"]} component={CollegeReviewForm} exact />
    <PrivateRoute path="/highschool/student/profiles/:journey_category_id/:journey_profile_id" component={StudentProfileList} exact />
    <PrivateRoute path={["/highschool/student/profile/form/:journey_category_id/:journey_profile_id/:id?", "/college/admission/profile/:id?"]} component={StudentProfileForm} exact />
    <PrivateRoute path="/highschool/schedules/:journey_category_id/:journey_profile_id/:journey_type" component={HighSchoolSchedules} exact/>
    <PrivateRoute path="/highschool/grade_path/:journey_category_id/:journey_profile_id" component={GradePathPortal} exact/>
    <PrivateRoute path="/highschool/profile/:journey_category_id/:journey_profile_id/:id?" component={MyEduProfile} exact/>
    <PrivateRoute path="/search/student/profiles/:journey_category_id/:journey_profile_id" component={EduPublicProfile} exact/>
    <PrivateRoute path="/highschool/path/:journey_category_id/:journey_profile_id?" component={LayoutFlow} exact/>
    <PrivateRoute path="/highschool/suggestion/colleges/:journey_category_id/:journey_profile_id" component={CollegeSuggestionList} exact/>
    <PrivateRoute path="/college/admissions/:journey_category_id/:journey_profile_id" component={MyAdmissionProfile} exact/>
    <PrivateRoute path="/college/details/:id" component={CollegeDetails} exact/>
    <PrivateRoute path="/college/compare" component={CollegeCompareList} exact/>
    <PrivateRoute path="/highschool/step_response/form/:journey_category_id/:journey_profile_id/:activity_id/:form_type/:form_id/:step_activity_id?" component={StepActivityResponseForm} exact/>
    <PrivateRoute path="/highschool/reel" setHelpId={props.setHelpId} searchString={props.searchString} component={HSReelList} exact />	
    <PrivateRoute path="/shared/highschool/activity" searchString={props.searchString} component={ActivityList} exact/>
  </Fragment>
)

export default HighSchoolRouter;