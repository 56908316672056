import React, { useState, Fragment, useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import JourneyCategoryService from "../../JourneyCategoryService";
import ActivityService from "../Activities/ActivityService";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useNotification from "../../../../hooks/useNotification";
import MultiSelectModal from "../../../Modals/MultiSelectModal";
import env from '../../../../env';
import Cs from '../../../../services/CommonService'
import {PillDsList} from "../../UserRoles/NotificationPill"

const PortalHeader = (props) => {

  const { project, share } = props;
  let data_source = project.education_journey_profiles.data_source;

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  const getActiveUserReport = (e, journey_category_id) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      journey_category_id: journey_category_id,
      type: "active_users",
    }
    ActivityService.reports(req).then((res) => {
      if (res.status == 200) {
        setLoading(false)
        let activeUsers = res.data.report;
      }
    })
  }

  let styleFn = (o) => ({backgroundColor: (o.data.icon_color)})

  const DemoMenu = () => (
    <span id="portal_menu" className="portlet-dropdown">
      <a data-tip="Journey Menu" className="p-dropbtn">
        <i className="fas fa-cog m-r-15 font-20"></i>
      </a>
      <div className="p-dropdown-content">
        <Link to={{
            pathname: `/invite/user/${project.id}/${project.education_journey_profiles.id}`,
            state: {journey:project},
          }}>
          <i className="fa fa-user"></i> Invite User
        </Link>
      </div>
    </span>
  )

  return(
    <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
      <div className="flex coldir fulwid p20">
        <div className="flex justspacebetween">
          <div>
            <div className="app-title m-r-20">{project.education_journey_profiles.data.fj_name_of_family_member}</div>
            <p className="m-t-10">
              Grade {project.education_journey_profiles.data.fj_edu_current_grade} &gt;
              <span className="text-blue">{Cs.formatUpdateDate(project.education_journey_profiles.data.grad_date)}</span>
            </p>
          </div>
          <div className="flex aligncenter">
            {project.is_demo_journey ? <DemoMenu/> : <Menu {...{project, setLoading, history}} app={props.app} userTypeList={props.userTypeList} />}
            <Link data-tip="Back to Journey List" to={{pathname: `/journey`,}}>
              <i className="fas fa-home font-20"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="portlet-heading clearfix">
      {loading && <div className="spinner" />}
      <div className="col-xs-12">
        <h3 className="portlet-title">
          <span>
            {project.education_journey_profiles.data.fj_name_of_family_member}
            <i className="fas fa-angle-right" />
          </span>
          <span>
            Grade {project.education_journey_profiles.data.fj_edu_current_grade}
            <i className="fas fa-angle-right" />
          </span>
          <span>{Cs.formatUpdateDate(project.education_journey_profiles.data.grad_date)}</span>
          <span dangerouslySetInnerHTML={{__html:project.education_journey_profiles.data.brief_13_brief_profile_of_team_profile,}}></span>
        </h3>
      </div>

      <div className="col-xs-12">
        <span className="text-cap">
          {(project.edu_journey_type).replace(/_/g, ' ')}
        </span> by {project.created_user.name}
      </div>

      <div className="col-xs-12">
        <div className="pull-left">
          <PillDsList list={data_source.subjects_most_interest} dataKey="label" 
          style="pill white d-unset" styleFn={styleFn}/>
        </div>
        <div className="portlet-widgets">
          {project.is_demo_journey ? <DemoMenu/> : <Menu {...{project, setLoading, history}} app={props.app} userTypeList={props.userTypeList} />}
          <Link data-tip="Back to Journey List" to={{pathname: `/journey`,}}>
            <i className="fas fa-home font-20"></i>
          </Link>
        </div>
      </div>
    </div>
  )
}

const Menu = ({project, app, setLoading, history, userTypeList, menuCss, iconCss}) => {

  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal()
  const { isOpen: isUserTypeOpen, toggleModal: toggleUserTypeModal} = useModal()

  const { showNotification } = useNotification()

  const deleteProject = (e) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.delete(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
        history.push("/journey")
      }
    })
    toggleDeleteModal(e)
  }

  const cloneJourney = (e, journey_category_id) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.cloneJourney(journey_category_id).then((res) => {
      if (res.status == 200) {
        showNotification("Success", "Journey Cloned Successfully")
        setLoading(false)
        history.push("/journey")
      }
    })
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault()
    setLoading(true)
    project.is_chatlist_enabled = !project.is_chatlist_enabled;
    JourneyCategoryService.update(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
      }
    })
  }

  const updateStatus = (e, journey_category, field) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      id: journey_category.id
    }
    req[field] = !journey_category[field];
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        journey_category[field] = !journey_category[field]
        setLoading(false)
        if (field === "is_demo_template" && req.is_demo_template) {
          toggleUserTypeModal(e)
        }
      }
    })
  }

  const updateDemoJourneyUserType = (list) => {
    let req = {
      id: project.id,
      demo_journey_user_type: list,
    }
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        setLoading(false)
      }
    })
  }

  return(
    <>
      <span id="portal_menu" className={`m-r-15 portlet-dropdown ${menuCss}`}>
        <a data-tip="Adjust Journey Settings" className="p-dropbtn">
          <i className={`fas fa-cog font-20 ${iconCss}`}/>
        </a>

        <div className="p-dropdown-content">
          {project.is_admin_user &&
            <Fragment>
              <Link to={`/journey_profile/${project.education_journey_profiles.id}?form_id=${project.education_journey_profiles.form_id}`}>
                <i className="far fa-edit"/> Edit
              </Link>
              <a href="#" onClick={(e) => toggleDeleteModal(e, project)}>
                <i className="far fa-trash-alt"/> Delete
              </a>
              <Link to={{pathname: `/invite/user/${project.id}/${project.education_journey_profiles.id}`, state: {journey:project}, }}>
                <i className="fa fa-user"/> Invite User
              </Link>
              <a href="#" onClick={(e) => cloneJourney(e, project.id)}>
                <i className="far fa-clone"/> Create a copy
              </a>
              <a href="#" onClick={(e) => updateStatus(e, project, "is_journey_completed")}>
                <i className={`far ${project.is_journey_completed ? "fa-check-circle" : "fa-circle"}`}/> Completed
              </a>
            </Fragment>
          }
          {project.is_admin_user && 
            <a href="#" onClick={(e) =>updateStatus(e, project, "is_demo_template")}>
              <i className={`far ${project.is_demo_template? "fa-check-circle": "fa-circle"}`}/>
              &nbsp;Demo Journey  
            </a>
          }
          <a href="#" onClick={(e) => toggleChatlistStatus(e)}>
            <i className={project.is_chatlist_enabled ? "far fa-check-circle": "far fa-circle"}/> Share chat
          </a>
          <a href="#" onClick={(e) => app.openHelp(e)}>
            <i className="fas fa-question-circle"/> Help
          </a>
          <a href="#" onClick={(e) => updateStatus(e, project, "is_disabled")}>
            <i className={project.is_disabled ? "fas fa-toggle-off": "fas fa-toggle-on"}/> { project.is_disabled ? "Inactive":"Active"} 
          </a>
        </div>
      </span>
      
      {isUserTypeOpen && 
        <MultiSelectModal isOpen={isUserTypeOpen} 
          toggleModal={toggleUserTypeModal}
          title="User Type" options={userTypeList.options}
          onUpdate={updateDemoJourneyUserType}
          selectedList={project.demo_journey_user_type}/>
      }

      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
    </>
  )  
}

export {PortalHeader, Menu};