import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import useStyle from '../../../../hooks/useStyle';

const PortalPath = (props) => {
  useStyle('path');
  let report = (props.statics && props.statics.report) || {};
  report.calendars = report.schedules;

  let p = { ...props.portalPath.options };

  if (report) {
    return (
      <div className="row">
        <div className="col-md-4 m-t-30">
          <div className="bg-white border-rounded-10 p20 card-min-ht">
            <div className="card-title flex justspacebetween border-dashed-sm p-b-15">
              <h4 className="fw700 f15 card-heading card-heading flex aligncenter text-cap">
                PLAN
              </h4>
            </div>
            <div className="card-content m-t-15">
              {p.planners && 
                <div onClick={(e) => props.routePath(e, p.planners)}
                  className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 p-l-15 p-r-15">
                  <div className="flex">
                    <i className={'fas fa-clipboard-check red' + ' m-r-10 f22'}/>
                    <h5 className="f16 text-cap">Planners</h5>
                  </div>
                  <span className="f16">{report[p.planners.sid] || 0}</span>
                </div>       
              }    
              {p.roles && 
                <div onClick={(e) => props.routePath(e, p.roles)}
                  className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 p-l-15 p-r-15">
                  <div className="flex">
                    <i className={'fas fa-users gold' + ' m-r-10 f22'}/>
                    <h5 className="f16 text-cap">Roles</h5>
                  </div>
                  <span className="f16">{report[p.roles.sid] || 0}</span>
                </div>
              }
              {p.invite_users &&
                <div onClick={(e) => props.routePath(e, p.invite_users)}
                  className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 p-l-15 p-r-15">
                  <div className="flex">
                    <i className={'fas fa-user grey' + ' m-r-10 f22'}/>
                    <h5 className="f16 text-cap">Users</h5>
                  </div>
                  <span className="f16">{report.invited_journey_members || 0}</span>
                </div>
              }
            </div>           
          </div>  
        </div>

        <div className="col-md-4 m-t-30">
          <div className="bg-white border-rounded-10 p20 card-min-ht">
            <div className="card-title flex justspacebetween border-dashed-sm p-b-15">
              <h4 className="fw700 f15 card-heading card-heading flex aligncenter text-cap">
                TRACK
              </h4>
            </div>
            <div className="card-content m-t-15">
              {p.activities && 
                <div onClick={(e) => props.routePath(e, p.activities)}
                  className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 p-l-15 p-r-15">
                  <div className="flex">
                    <i className={'far fa-sticky-note l-green' + ' m-r-10 f22'}/>
                    <h5 className="f16 text-cap">Activities</h5>
                  </div>
                  <span className="f16">{report[p.activities.sid] || 0}</span>
                </div>       
              }
              {p.calendars && 
                <div onClick={(e) => props.routePath(e, p.calendars)}
                  className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 p-l-15 p-r-15">
                  <div className="flex">
                    <i className={'far fa-calendar-check grey' + ' m-r-10 f22'}/>
                    <h5 className="f16 text-cap">Calendars</h5>
                  </div>
                  <span className="f16">{report[p.calendars.sid] || 0}</span>
                </div>       
              }  
              <div onClick={e=>props.routePath(e, {sid:'meetings'})}
                className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 p-l-15 p-r-15">
                <div className="flex">
                  <i className={'fas fa-video' + ' m-r-10 f22'} style={{color:'rgb(34, 165, 225)'}}/>
                  <h5 className="f16 text-cap">Meetings</h5>
                </div>
                <span className="f16">{0}</span>
              </div>       
            </div>
          </div>
        </div>    
      </div>
    )

    return (
      <Fragment>
        <div className="row margin-unset">
        {(p.planners || p.roles) &&   
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="edu-path">
              <div className="head">PLAN</div>
              <div className="icons">
              {p.planners && (
                <div className="three-half" onClick={(e) => props.routePath(e, p.planners)}>
                  <i className="fas fa-clipboard-check red" />
                  <div>Planners</div>
                  <span className="notification">{report[p.planners.sid]}</span>
                </div>
              )}
              {p.roles && (
                <div className="three-half" onClick={(e) => props.routePath(e, p.roles)}>
                  <i className="fas fa-users gold" />
                  <div>Roles</div>
                  <span className="notification">{report[p.roles.sid]}</span>
                </div>
              )}
              {p.invite_users && (
                <div className="three-half" onClick={(e) => props.routePath(e, p.invite_users)}>
                  <i className="fas fa-user grey"/>
                  <div>Users</div>
                  <span className="notification">{report.invited_journey_members}</span>
                </div>
              )}
            </div>
          </div>
          </div>
        }

        {(p.calendars || p.activities) &&
        <div className="col-xs-12 col-sm-6 col-md-4">
          <div className="edu-path">
            <div className="head">TRACK</div>
            <div className="icons">
              {p.activities && (
                <div className="three-half" onClick={(e) => props.routePath(e, p.activities)}>
                  <i className="far fa-sticky-note l-green" />
                  <div>Activities</div>
                  <span className="notification">{report[p.activities.sid]}</span>
                </div>
              )}
              {p.calendars && (
                <div className="three-half" onClick={(e) => props.routePath(e, p.calendars)}>
                  <i className="far fa-calendar-check grey" />
                  <div>Calendars</div>
                  <span className="notification">{report[p.calendars.sid]}</span>
                </div>
              )}
              <div className="three-half" onClick={e=>props.routePath(e, {sid:'meetings'})}>
                  <i className="fas fa-video" style={{color:'rgb(34, 165, 225)'}}/>
                  <div>Meetings</div>
                  <span className="notification">{0}</span>
                </div>
            </div>
          </div>
        </div>}
      </div>
    </Fragment>
    );
  } else {
    return null;
  }
};

export default PortalPath;
