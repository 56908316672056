import env from '../../env'
import axios from 'axios'
import BaseService from "../../services/BaseService"
class QuestionService extends BaseService {
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_college_journey}interview_questions`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_college_journey}interview_questions/${req}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_college_journey+'interview_questions',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }



  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.edu_college_journey}interview_questions/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.edu_college_journey}interview_questions/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }*/

}

export default new QuestionService('edu_college_journey', 'interview_questions');