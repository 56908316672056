import React, { useState, Fragment, useEffect, useContext} from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import JourneyCategoryService from "../../JourneyCategoryService";
import GenericModal from "../../../Modals/GenericModal";
import FormTemplateId from '../../../../constants/FormTemplateList';
import AnswerModal from "./AnswerModal"
import TagModal from "./TagModal"
import useModal from "../../../../hooks/useModal"; 
import InterviewService from "./InterviewService";
import QuestionService from "../../../Questions/QuestionService";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import CheckAccess from "../../Roles/CheckAccess";

let requestParams = {};
let journey_category = null;
let selectedQuestion = null;
let answer = null;
let selectedQuestionTypeId = null;

const QuestionsList = (props) => {
  useStyle("card");
  useStyle("card_table");

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState()
  const [answerList, setAnswerList] = useState([])
  const [questionType, setQuestionType] = useState({})
  const [statics, setStatics] = useState({})

  const { isOpen:isAnswerModalOpen, toggleModal:toggleAnswerModal } = useModal();
  const { isOpen:isTagModalOpen, toggleModal:toggleTagModal } = useModal();
  const { isOpen, toggleModal:toggleDeleteModal } = useModal();

  let params = props.match.params;  
  let project = props.location.state;
  let category = props.location.label
  let queryParam = querystringify.parse(props.location.search);

  useEffect(() => {
    selectedQuestionTypeId = null;
    getAllAnswers(selectedQuestionTypeId);
  }, [props.searchString, params]);

  let getAllAnswers = (question_category_ds_id) => {
    let req = {
      'data_source_params': { 
        'data_source_id': 3382, 
        'response_type': "object" 
      },
      'journey_category_id':params.journey_category_id,
      'journey_profile_id':params.journey_profile_id,
      'graduate_program_id':queryParam.graduate_program_ds_id,
      'question_category_id':question_category_ds_id,
      'search': props.searchString,
    }
    req.include_statics = (question_category_ds_id && !props.searchString)?null:true;
    
    InterviewService.getAll(req).then((res) => {
      if (res.status == 200) {
        res = res.data;
        CheckAccess.userAccess(currentUser, project, res.invited_journey_member);
        setAnswerList(res.interview_preparations)
        setQuestionType(res.data_source_list.categories_interview_q.options)
        if(res.statics)setStatics(res.statics || {})
      }
    })
  }

  const vote = (vote) =>{
    let res = vote ? 'fa-thumbs-up' :'fa-thumbs-down'
    let resType = vote ? 'up_vote' :'down_vote'
    let response = selectedQuestion;
    let req = {
      'question_id':selectedQuestion.id,
      'response':res,
      'response_type':resType,
      'journey_category_id':params.journey_category_id,
      'journey_profile_id':params.journey_profile_id,
      'carrier_ds_id': params.carrier_data_source_id,
      'question_category_ds_id':selectedQuestion.question_category_ds_id,
      'journey_type_ds_id':selectedQuestion.journey_category_ds_id,
      'graduate_program_ds_id':selectedQuestion.graduate_program_ds_id
    }

    if(response && response.vote){
      req.id = response.vote.id
      InterviewService.update(req).then((res)=>{
        if(res.status == 204){
          response.vote.response = req.response;
        }
      })
    }else{
      InterviewService.create(req).then((res)=>{
        if(res.status == 201){
          answerList[selectedQuestion.index].vote = res.data.interview_preparation
          setAnswerList([...answerList])
        }
      })  
    }
  }

  const openAnswerModal = (question) =>{
    selectedQuestion = question;
    answer = question.answer
    toggleAnswerModal()
  }

  const openTagModal = (question) =>{
    selectedQuestion = question;
    answer = question.tag_interview
    toggleTagModal()
  }

  const upVote = (question) =>{
    selectedQuestion = question;
    vote(1)
  }

  const downVote = (question) =>{
    selectedQuestion = question;
    vote(0)
  }

  const handleClick =(i, question_type)=> {
    if(index != i)getAllAnswers(question_type.value);
    selectedQuestionTypeId = question_type.value
    setAnswerList([])
    setIndex(index == i?null:i)
    if(index != i){
      const tabScroll = document.getElementById('tab_'+i);
      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': tabScroll.offsetTop - 200
      });
    }
  }

  const createQuestion = (ds) =>{
    let d = {
      'graduate_program': parseInt(queryParam.graduate_program_ds_id),
      'graduate_program_array': [parseInt(queryParam.graduate_program_ds_id)],
      'question_category': ds.value,
      'journey_type': parseInt(queryParam.journey_category_ds_id),
      'journey_type_array': [parseInt(queryParam.journey_category_ds_id)]
    }  

    let data = JSON.stringify(d)
    let query = '?journey_category_id='+params.journey_category_id+'&journey_profile_id='+params.journey_profile_id+'&form_wizard_id=43&data='+data
    props.history.push('/questions/create/'+FormTemplateId.QuestionBankForm+query)
  }

  const editUserQuestion = (id) =>{
    let query = '?journey_category_id='+params.journey_category_id+'&journey_profile_id='+params.journey_profile_id+'&form_wizard_id=43'
    props.history.push('/questions/create/'+FormTemplateId.QuestionBankForm+'/'+id+query)
  }

  const deleteUserQuestion = (e) => {
    QuestionService.delete(selectedQuestion.id).then((res) => {
      if (res.status == 204) {
        setAnswerList(answerList.filter(i => i.id !== selectedQuestion.id))
        selectedQuestion = null;
      }
    })
    toggleDeleteModal(e);
  }

  const questionListView = (question_type_id) => answerList.map((c, i) => {
    c.index = i
    c.question_category_ds_id = question_type_id
    c.journey_category_ds_id = queryParam.journey_category_ds_id
    c.graduate_program_ds_id = queryParam.graduate_program_ds_id
    const styleBorder = c.journey_profile_id ? {'borderLeft': '3px solid #27a1fb'}:{}
    const hasEditAccess = CheckAccess.hasInterviewAccess(currentUser, project, c)

    return(
      <div key={i} className="col-xs-12 carrier" style={styleBorder}>
        <div className="row p-5">
          {c.data.interview_question}
        </div>
        <div className="pull-left">
          <span className={'p-7 font-14 '+ (c.answer ? 'green':'lred')} onClick={e=>openAnswerModal(c)}>{c.answer ? 'View Answer':'Add Answer'}</span>
          <span className={'p-7 font-14 '+ (c.tag_interview ? 'green':'highlight')} onClick={e=>openTagModal(c)}>Tag an Interview</span>
          <i data-tip="This question is useful"  onClick={e=>upVote(c)}  
            className={'fa fa-thumbs-up p-7 m-l-15 center-text '+ (c.vote && c.vote.response_type == 'up_vote'?'green':'text-muted')}></i>
          <i data-tip="This question is not useful" onClick={e=>downVote(c)} 
            className={'fa fa-thumbs-down p-7 m-l-15 center-text '+ (c.vote && c.vote.response_type == 'down_vote'?'lred':'text-muted')}></i>
          {hasEditAccess && <Fragment>
            <i data-tip="Edit" onClick={e=>editUserQuestion(c.id)} 
              className='far fa-edit p-7 m-l-15 center-text text-muted'></i>
            <i data-tip="Delete" onClick={e=>{selectedQuestion=c;toggleDeleteModal(e);}} 
              className='far fa-trash-alt p-7 m-l-15 center-text text-muted'></i>
          </Fragment>}
        </div>
        <div className="col-xs-4">
          <ReactTooltip place="left" />
        </div>
      </div>
    )
  })

  const questionTypeList = questionType && Object.values(questionType).map((c, i) => {
    const iconColor = {'color': c.icon_color, 'fontSize': '20px', 'marginRight':'10px'}
    const boxShadow =  index == i ? {border: "1px solid lightgrey", boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} : {}
    
    let s = {question_count:0, answer_count:0}
    if(statics[c.value] && statics[c.value][0]){
      s = statics[c.value][0]
    }

    return(
      <div className="col-xs-12 col-sm-6 col-md-6 font-16 m-b-15" key={i} 
        style={boxShadow} id={'tab_'+i}>
        <div className="black bg-white row">
          <div className="row d-flex" onClick={e=>handleClick(i, c)}>
            <div className="font-16 w-100p p-7">
             <i className={c.icon} style={iconColor}/> {c.label}
            </div>
            <i className = {'m-r-10 p-7 font-24 ' + (index == i ? "fa fa-caret-up" : "fa fa-caret-down")} ></i>
          </div>
          <div className="row font-12 p-7 pull-left">
            <div className="pill lred">Questions : {s.question_count}</div>
            <div className="pill green">Answered : {s.answer_count}</div>
          </div>
          <div className="row font-12 p-7 pull-right" onClick={e=>createQuestion(c)}>
            <i className="fa fa-plus m-r-10 highlight"></i>
          </div>
        </div>
            
        {index == i && <div key={i} className="ht-250 bg-white p-7 scroll-auto">
          {questionListView(c.value)}
         </div>
        }
      </div>
    )
  })

  return (
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={queryParam.title || "Interview Preparation"} project={project} />
        </div>
        <div className="col-md-8 pull-right">
          <span id="home_icon" data-tip="Back to Journey Home"
            onClick={e=>props.history.goBack()}
            className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-home"></i>
          </span>
        </div>
        <div className="clear"></div>
      </div>
   
      <div className="page-content m-t-20">
        <div className="container p-20">
          <label>{category}</label>
          {questionTypeList}
          <div className="col-xs-12 ht-250"></div>
        </div>
      </div>  

      {isAnswerModalOpen && (
        <AnswerModal
          title={selectedQuestion.data.interview_question}
          question={selectedQuestion}
          isOpen={isAnswerModalOpen}
          toggleModal={toggleAnswerModal}
          journeyCategoryId={params.journey_category_id}
          journeyProfileId={params.journey_profile_id}
          userResponse={answer}
          answerList={answerList}
          setAnswerList={setAnswerList}
          carrierDataDourceId={params.carrier_data_source_id}/>
      )}
      {isTagModalOpen && (
        <TagModal
          title={selectedQuestion.data.interview_question}
          question={selectedQuestion}
          isOpen={isTagModalOpen}
          toggleModal={toggleTagModal}
          journeyCategoryId={params.journey_category_id}
          journeyProfileId={params.journey_profile_id}
          userResponse={answer}
          answerList={answerList}
          setAnswerList={setAnswerList}
          carrierDataDourceId={params.carrier_data_source_id}/>
      )}
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteUserQuestion}/>
      <SideMenuBar helpId="15" />
    </Fragment>
  )
}

export default QuestionsList;