//const position = { x: 0, y: 0 };
const edgeType = 'smoothstep';

let initialElements = []

const resetElement = () => initialElements = [];

const position = [
  { x: 400, y: 0 },
  { x: 0, y: 50 },
  { x: 900, y: 50 },
  { x: 50, y: 160 },
  { x: 100, y: 280 },
  { x: 150, y: 160 }
]

const edgePosition = [
  { source:'bottom', target:'right' },
  { source:'bottom', target:'right' },
  { source:'bottom', target:'left' },
]

const gradeElement = (grade_data_sources = [], addActivity, journey_profile, routeInterview) => {
  grade_data_sources.forEach((d, i) => {
    let data = { 
      label: d.label,
      value:d.value, 
      //subTitle:journey_profile.data?.current_job,
      sourcePosition: edgePosition[i].source,
      targetPosition: edgePosition[i].target,
      interview: routeInterview,
      //addActivity 
    }

    /*if(i+1 == grade_data_sources.length)
    console.log('d,v',d,i,journey_profile.data)
      data.subTitle = journey_profile.data?.future_careers;*/
      
    if(d.label == 'Future Career 1'){
      data.subTitle = journey_profile.data_source?.future_occup_groups?.label
    }else if(d.label == 'Future Career 2'){
      data.subTitle = journey_profile.data_source?.future_occup_groups_2?.label
    }else if(d.label == 'Current Position'){
      data.subTitle = journey_profile.data_source?.occupation_group?.label
    }

    initialElements.push({
      id: `ds_${d.pk_id}`,
      type: 'AddDataSource',
      data,
      position:position[i]
    })

    if(i!=0){
      initialElements.push({ 
        id: `edge_${d.pk_id}`, 
        source: `ds_${grade_data_sources[0]['pk_id']}`, 
        target: `ds_${d.pk_id}`, 
        type: edgeType, 
        animated: true 
      })  
    }
    
  })

  /*initialElements.push({
      id: `ds_1111`,
      type: 'AddDataSource',
      data: { label: 'Add Activity', sourcePosition: 'left',targetPosition: 'top', addActivity },
      position:position[2]
  })*/

 /* initialElements.push({
      id: `ds_28999`,
      type: 'AddActivity',
      data: { label: 'Volunteer', sourcePosition: 'left',targetPosition: 'top', addActivity },
      position:position[3]
  })

  initialElements.push({
      id: `ds_289042`,
      type: 'AddActivity',
      data: { label: 'Diploma', sourcePosition: 'left',targetPosition: 'top', addActivity },
      position:position[4]
  })

  initialElements.push({
      id: `ds_2890499`,
      type: 'AddActivity',
      data: { label: 'Training', sourcePosition: 'left',targetPosition: 'top', addActivity },
      position:position[5]
  })

  initialElements.push({ 
        id: `edge_1`, 
        source: `ds_28904`, 
        target: `ds_28999`, 
        type: edgeType, 
        animated: true 
      }) 

  initialElements.push({ 
        id: `edge_2`, 
        source: `ds_28904`, 
        target: `ds_289042`, 
        type: edgeType, 
        animated: true 
      })

  initialElements.push({ 
        id: `edge_2`, 
        source: `ds_28904`, 
        target: `ds_2890499`, 
        type: edgeType, 
        animated: true 
      }) */
}

const setGradeActivities = (activities = {}, activityColor, activityIcon, openActivity, activityPaginate) => {
  for (let prop in activities) { 
    
    let p = { x: 0, y: 270 }

    if(activities[prop] && activities[prop].activities?.length > 0){
      
      let pe = initialElements.find((e)=> e.id == `future_career_${activities[prop].career_ds_fk_id}`)
      p.x = pe.position.x

      initialElements.push({
        id: prop+'_activities',
        type: 'AddActivity',
        data:{
          activities: activities[prop].activities,
          meta: activities[prop].meta,
          careerProp:prop,
          activityColor,
          activityIcon,
          openActivity,
          activityPaginate,
        },
        position: p,
      })

      initialElements.push({ 
        id: `edge_${prop}`,
        source: `future_career_${activities[prop].career_ds_fk_id}`, 
        target: prop+'_activities', 
        type: edgeType, 
        animated: true 
      })
    }  
  }
  
  /*activities.forEach((a, i) => {
    

    initialElements.push({
      id: `activity_${a.id}`,
      type: 'AddActivity',
      data: { 
        label: (a.data.record_title || a.data.name), 
        journey_category_id:a.journey_category_id,
        journey_profile_id:a.journey_profile_id,
        id:a.id,
        activity_form_id: a.activity_form_id,
        activity_type: a.activity_type,
        bg_color:activityColor[a.activity_form_id],
        icon:activityIcon[a.activity_form_id],
        openActivity
      },
      position: p,
    })

    initialElements.push({ 
      id: `activity_edge_${a.id}`, 
      source: 'ds_28904', 
      target: `activity_${a.id}`, 
      type: edgeType, 
      animated: true 
    })

  })*/
}

const setFutureCareers = (careers = [], routeActivityForm, routeCareerDetails) => {
  let p = { x: 10, y: 180 }
  let positionList = [p]
  careers.forEach((a, i) => {
    if(i!=0){
      p = Object.assign({}, positionList[i-1])
      p.x += 220
      //p.y += 100
      positionList.push(p)
    }

    initialElements.push({
      id: `future_career_${a.fk_id}`,
      type: 'AddFutureCareer',
      data: { 
        label: a.label, 
        id: a.fk_id,
        bg_color: 'lgreen',
        icon: 'fa fa-graduation-cap',
        career_no: '1',
        routeActivityForm,
        routeCareerDetails,
      },
      position: p,
    })

    initialElements.push({ 
      id: `future_career_edge_${i}`, 
      source: 'ds_28903', 
      target: `future_career_${a.fk_id}`,
      type: edgeType, 
      animated: true 
    })

  })
}

const setFutureCareers2 = (careers = [], routeActivityForm, routeCareerDetails) => {
  let p = { x: 800, y: 180 }
  let positionList = [p]
  careers.forEach((a, i) => {
    if(i!=0){
      p = Object.assign({}, positionList[i-1])
      p.x += 220
      //p.y += 100
      positionList.push(p)
    }

    initialElements.push({
      id: `future_career_${a.fk_id}`,
      type: 'AddFutureCareer',
      data: { 
        label: a.label, 
        id: a.fk_id,
        bg_color:'lgreen',
        icon:'fa fa-graduation-cap',
        career_no:'2',
        routeActivityForm,
        routeCareerDetails,
      },
      position: p,
    })

    initialElements.push({ 
      id: `future_career_2_edge_${i}`, 
      source: 'ds_29233', 
      target: `future_career_${a.fk_id}`,
      type: edgeType, 
      animated: true 
    })

  })
}

const setSuggestedActivities = (activities, activityColor, activityIcon, cloneActivity) =>{
  let p = { x: 400, y: 200 }

  initialElements.push({
    id: `sugg`,
    type: 'AddSuggestedActivity',
    data: { 
      activities,
      activityColor,
      activityIcon,
      cloneActivity
    },
    position:p,
  })

  initialElements.push({ 
    id: 'edge_sugg', 
    source: `ds_1111`, 
    target: `sugg`, 
    type: edgeType, 
    animated: true, 
  })

}

export {initialElements, gradeElement, setGradeActivities, resetElement, setSuggestedActivities, setFutureCareers, setFutureCareers2}
