import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../components/PreLoadSpinner"
import DynamicForm from '../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../constants/FormTemplateList'
import querystringify from "querystringify"
import FormContainer from '../FormBuilder/FormContainer'
import MemberService from "../../components/Members/MemberService"
import FormWizard from "../../components/FormBuilder/FormWizard"

let formMode = null 
let member = {} 
let data = {} 
let formFn = {}  

function MemberForm(props) {
  const {state:{user:currentUser}, dispatch} = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  formFn.journey_members_path = 'name'
  formFn.journey_members_pagenate = {
    page:1,
    per_page:30
  }

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    MemberService.get(params.id).then((res) => {
      if(res.status==200){
        member = res.data.member
        data = member.data || {}
        params.form_template_id = member.form_id

        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function(res){
      member = null
    })
  }
  
  useEffect(() => { 
    member = {} 
    data = {}
    formFn = {'form_type':'member'} 
    formMode = 'create-form-submissions'
    
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (member) => {
    member.form_id = params.form_template_id
    member.form_type_id = params.form_type_id  
    member.family_id = currentUser.domain_info.id
    MemberService.create(member).then((res) => {
      if(res.status==201){
        currentUser.journey_members = currentUser.journey_members || []
        currentUser.journey_members[res.data.member.id] = res.data.member

        dispatch({
          type: "UPDATE",
          payload: { user: currentUser }
        })
        redirectJourneyMenu(res.data.member)
      }
    }).catch(function(res){
       
    })
  }

  const update = (member) => {
    member.form_id = params.form_template_id
    member.form_type_id = params.form_type_id 
    MemberService.update(member).then((res) => {
      if(res.status==204){
       redirect()
      }
    }).catch(function(res){
       
    })
  }

  const checkIsExistingJourneyMember = (member) =>{
    if(data.new_or_existing == 1){
      /*Existing journey member*/
      props.history.push({
        pathname: '/journey_types/'+data.journey_member_id,
      })
    }else if(params.id){
      update(member)
    }else{
      create(member)
    }
  }

  formFn.get_journey_members = (search, on_success, on_failure) => {
    let req = {
      search: search,
      is_form_search: true,
      page: formFn.journey_members_pagenate.page,
      per_page: formFn.journey_members_pagenate.per_page
    }
    
    MemberService.getAll(req).then(({status, data})=>{
      formFn.journey_members_pagenate.pageSize = Math.ceil(data.meta.total/formFn.journey_members_pagenate.per_page)
      on_success(data.members)
    })
  }

  formFn.set_journey_members = (member) => {
    data.journey_member_id = member.id
    data.journey_members = member.name
  }

  const redirectJourneyMenu = (member) => {
    props.history.push({
      pathname: '/journey_types/'+member.id,
    })
  }

  const redirect = () => {
    props.history.goBack()
  }

  if (queryParam.show_form_wizard) {
    return (
      <FormWizard form_wizard_id={queryParam.form_wizard_id}
        form={member} data={data}
        formFn={formFn} onCreate={checkIsExistingJourneyMember}
        onUpdate={checkIsExistingJourneyMember} onCancel={redirect}
        onCloseSuccessMessage={redirectJourneyMenu}
      />
    )
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={params.form_template_id} form={member} 
          data={data} formFn={formFn} onCreate={checkIsExistingJourneyMember} 
          onUpdate={checkIsExistingJourneyMember} post={post} 
          onCancel={redirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null
  }
  
}

export default MemberForm;