import React, { Fragment,useContext } from "react"
import { Link } from "react-router-dom"
import Cs from "../../../../../services/CommonService"
import CheckAccess from '../../../Roles/CheckAccess'
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import {DataList, DataColumn, DataRow} from '../../../Common/ActivityLayout'
import GeneralRating from "../../../../Common/Rating"

const ListActivity = (props) => {
  const {state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)
  let {pageName, activityCategory, activities, activityReport, tabIndex, dataPosition, journey_category, journey_category_id, journey_profile_id, openTab, addComments, addChecklist} = props
  let height = {'height': screen.xs?'220px':'230px'}
  let gridCss = props.pageName == 'my_profile'?'col-xs-12 col-sm-6 br-rt-btm':'col-xs-12 col-sm-6 col-md-4 br-rt-btm'

  const List = ({group}) => activityCategory[group] ? activityCategory[group].map((k) => {
    let isTabOpen = (tabIndex == k.child_form)
    const link = `/highschool/activity/form/${journey_category_id}/${journey_profile_id}/${k.label}/${k.child_form}?form_wizard_id=${k.wizard_id}&fj_edu_entry_type=${k.value}`

    return (
      <Fragment key={k.value}>
        <div className={isTabOpen ? 'shadow-huge ver-icons':'ver-icons'} style={{display:'flow-root'}} onClick={e=>openTab(k)}>
          <span>
            <i className={k.icon || "fas fa-plus"} style={{ color: k.icon_color }}/>
          </span>
          <div className="popup-menu-title">{k.label}</div>
          <div className="bold-600 br-d-grey p-5 d-contents">           
            <span className="badge up bg-lred top-n2 m-l-15"> {activityReport[k.child_form] || 0} </span>
          </div>
          <i className={'fas fa-chevron-'+ (tabIndex == k.child_form? 'down':'right') +' lgrey pull-right lh-unset'} />
          {pageName == "activities" && CheckAccess.hasEditAccess(currentUser, journey_category, {}) && 
            <Link to={link} className="pull-right m-r-15">
              <i className="fas fa-plus lgrey" />
            </Link>
          }  
        </div>
        {isTabOpen && <ActivitiesView type={k}/>}
      </Fragment>
    )
  }):null

  const ActivitiesView = ({type}) => activities.map((a, i) => {
    let data = a.data
    let ds = a.data_source
    const borderLeft = {'borderLeft':'3px solid '+type.header_color}
    const editAccess = CheckAccess.hasEditAccess(currentUser, journey_category, a)
    const link = {
      pathname:`/highschool/activity/${journey_category_id}/${journey_profile_id}`, 
      search:`?id=${a.id}`,
      state:journey_category 
    }

    return(
      <div className="col-xs-12 br-d-grey p-7 ht-85" key={i} style={borderLeft}>
        <span className="pull-right highlight bold-600">
          {a.privacy_type=='private'?'Private':'Shared'}
        </span>
        
        <DataRow item={a} uiInfo={dataPosition[a.activity_form_id]}/>
        {pageName == "activities" && 
          <div>
            <Link to={link} className="pull-right m-t-5">
              <i className="fas fa-expand display-block font-16"/>
            </Link>
            <span className="pull-right m-r-15 lgrey m-t-5" onClick={e=>addComments(e, a)}>
              <i className="far fa-comment font-16"></i>
              <span className="badge up bg-lred">{a.comments_count}</span>
            </span>
            <span className="pull-right m-r-15 lgrey m-t-5" onClick={e=>addChecklist(e, a)}>
              <i className="far fa-check-circle font-16"></i>
              <span className="badge up bg-lred">{a.check_list_count}</span>
            </span>
            {editAccess &&
              <Link className="pull-right m-r-15 lgrey m-t-5" 
                to={`/highschool/activity/edit/${a.journey_category_id}/${a.education_journey_profile_id}/${a.id}?form_wizard_schedule_id=0&form_template_id=${a.activity_form_id}&item_id=${a.id}&item_type=education_journal_entries`}>
                <i className="far fa-lightbulb font-16"></i>
                <span className="badge up bg-lred">{a.empty_fields_count}</span>
              </Link>
            }
          </div>
        }
      </div>
    )
  })

  return(
    <Fragment>
      {activityCategory && (
        <div className="page-content row p-5 bg-white m-t-20">
          {activityCategory.academic &&
            <div className={gridCss}>
              <div className="bg-white popup-menu-grid scroll-auto p-5" style={height}>
                <p className="font-16 lh-24 title">Academic</p>
                <List group="academic" />
              </div>
              { props.pageName == 'my_profile' && <GeneralRating ratingData={4} dataKey="academic" onSelect={props.createRating}/>}
            </div>
          }
          
          {activityCategory.extracurricular &&  
            <div className={gridCss}>
              <div className="bg-white popup-menu-grid scroll-auto p-5" style={height}>  
                <p className="font-16 lh-24 title">Extracurricular</p>
                <List group="extracurricular" /> 
              </div>
              { props.pageName == 'my_profile' && <GeneralRating ratingData={4} dataKey="extracurricular" onSelect={props.createRating}/>}
            </div>
          }
          
          {activityCategory.special_experience &&    
            <div className={gridCss}>
              <div className="bg-white popup-menu-grid scroll-auto p-5" style={height}>
                <p className="font-16 lh-24 title">Special experience</p>
                <List group="special_experience" /> 
              </div>
              { props.pageName == 'my_profile' && <GeneralRating ratingData={4} dataKey="special_experience" onSelect={props.createRating}/>}
            </div>
          }

          {activityCategory['4-Year HS Schedule'] &&    
            <div className={gridCss}>
              <div className="bg-white popup-menu-grid scroll-auto p-5" style={height}>
                <p className="font-16 lh-24 title">College Schedule</p>
                <List group="4-Year HS Schedule" /> 
              </div>
              { props.pageName == 'my_profile' && <GeneralRating ratingData={4} dataKey="hs_schedule" onSelect={props.createRating}/>}
            </div>
          }
        </div>
      )}
    </Fragment>
  )
}

export default ListActivity;