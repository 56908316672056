import React, { useState, useEffect, useReducer } from "react"
import { Route, Switch, withRouter } from "react-router-dom"
import Home from "./components/Home"
import Login from "./components/Authentication/Login"
import Signup from "./components/Authentication/Signup"
import ForgetPassword from "./components/Authentication/ForgetPassword"
import ResetPassword from "./components/Authentication/ResetPassword"
import Logout from "./components/Authentication/Logout"
import PublicRoute from "./routes/PublicRoute"
import AxiosConfig from "./AxiosConfig"
import { AuthContext, initialState,} from "./contexts/Authentication/AuthStateProvider"
import Reducer from "./contexts/Authentication/AuthReducer"
import AuthenticationService from "./services/AuthService"
import PreLoadSpinner from "./components/PreLoadSpinner"
import User from "./constants/User"
import Marketing from "./components/Marketing/Marketing"
import DemoMarketing from "./components/Marketing/DemoMarketing"
import TemplateMarketing from "./components/Marketing/TemplateMarketing"
import AboutUs from "./components/Marketing/List/AboutUs"
import Subplans from "./components/Marketing/List/Subplans"
import DailyDabble from "./components/Marketing/List/DailyDabble"
import DailyDabbleQuote from "./components/Marketing/List/DailyDabbleQuote"
import Privacy from "./components/Terms/Privacy"
import CheckoutMarketing from "./components/Marketing/List/CheckoutMarketing"
import PackyourMarketing from "./components/Marketing/List/PackyourMarketing"
import TeamMarketing from "./components/Marketing/List/TeamMarketing"
import BlogMarketing from "./components/Marketing/List/BlogMarketing"
import ContactUs from "./components/Marketing/List/ContactUs"
import RegistrationConfirmation from "./components/Authentication/RegistrationConfirmation"
import WelcomeTour from "./components/Marketing/WelcomeTour"
import WelcomeTourScroll from "./components/Marketing/WelcomeTourScroll"
import ErrorBoundary from "./components/Common/ErrorBoundary"
import SubscriptionTerms from "./components/SubscriptionPayments/SubscriptionTerms"
import Router from "./constants/Route"
import FAQ from "./components/MarketingSnippets/FAQList"

//import ParentConsentForm from "./components/Journey/Form/ParentConsentForm";

function App(props) {
  const [state, dispatch] = useReducer(Reducer, initialState)
  const [loading, setLoading] = useState(true)

  /*Click Track*/
  /*document.addEventListener('click', (event)=> { 
    const {target:{innerText, className, id}} = event
    console.log(innerText)
    console.log(className)
    console.log(id)
    console.log(props.history.location.pathname)
  })*/

  useEffect(()=>{
    getCurrentUserSession()
  }, [])

  const getCurrentUserSession = () => {
    AuthenticationService.get().then((user) => {
      if (user.id) {
        AuthenticationService.setPartnership(user)
        state.user = user
        state.isAuthenticated = true
        setLoading(false)
      }else{
        setLoading(false)
      }
    },(err) => {
        setLoading(false)
      }
    )
  }

  if (loading) {
    return <PreLoadSpinner/>
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <ErrorBoundary>
      <Switch>
        <PublicRoute path="/" component={Login} exact />
        <PublicRoute path={Router.Login} component={Login} exact />
        <PublicRoute path={Router.Signup} component={Signup} exact />
        <PublicRoute path={"/about_us/:type"} component={Privacy} exact />
        <PublicRoute path="/subscription_terms" component={SubscriptionTerms} exact />
        <PublicRoute path={Router.ForgetPassword} component={ForgetPassword} exact />
        <PublicRoute path={Router.ResetPassword} component={ResetPassword} exact/>
		    <PublicRoute path="/aboutUs" component={AboutUs} exact />
        <PublicRoute path="/subplans" component={Subplans} exact />
        <PublicRoute path="/dailyCue" component={DailyDabble} exact />
        <PublicRoute path="/dailyCueQuote/:daily_dabble_id?" component={DailyDabbleQuote} exact />
		    <PublicRoute path="/features" component={CheckoutMarketing} exact />
		    <PublicRoute path="/essentials" component={PackyourMarketing} exact />
        <PublicRoute path="/blog/:blog_page_url?" component={BlogMarketing} exact />
		    <PublicRoute path="/team" component={TeamMarketing} exact />
		    <PublicRoute path="/contactUs" component={ContactUs} exact />
		    <PublicRoute path="/template/marketing" component={TemplateMarketing} exact />
		    <PublicRoute path="/demo/marketing" component={DemoMarketing} exact />
        <PublicRoute path="/marketing" component={Marketing} exact />
        <PublicRoute path="/faq" component={FAQ} exact />
        <PublicRoute path={Router.RegistrationConfirmation} component={RegistrationConfirmation} exact/>
        <PublicRoute path="/journeytour" component={WelcomeTour} exact />
        <PublicRoute path="/logout" component={Logout} exact />
        <Route path="/" component={Home} />
        <Route component={Page404} />
      </Switch>
      </ErrorBoundary>  
    </AuthContext.Provider>
  )
}

const Page404 = ({ location }) => (
  <div>
    <h2>
      No match found for <code>{location.pathname}</code>
    </h2>
  </div>
)

export default withRouter(App);