import React, {useState, useEffect, useRef} from 'react'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import CommonService from '../../../services/CommonService'
import RichText from '../../../components/Common/RichText' 
import SummerNoteEditor from '../../../components/Common/SummerNoteEditor'
import useStyle from '../../../hooks/useStyle';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description} from '../FieldLabel'

const InputRichText = ({user, field, formData, readOnly, errors, formFn, isFormWizard, formId, openFieldLabelModal, currentLocale}) => {
  //useStyle('smartblock')

  const [showError, setShowError] = useState(false);

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const onContentChange = (html) =>{
    formData[field.client_id] = html
    setError()
  }

  const setError = () => {
    if(field.required){
      errors[field.client_id].invalid = (formData[field.client_id] && formData[field.client_id].length>0)?false:true;
      errors[field.client_id].touched = true;
      if(errors[field.client_id].invalid){
        errors.invalid[field.client_id] = true;
      }else{
        delete errors.invalid[field.client_id]
      }
      setShowError(!showError)
      formFn.refreshFormSubmit();
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setMinMaxValidation(field, inputAttributes);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  return (
    <div {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
      <div className="flex coldir m-b-20">
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
          <SummerNoteEditor id={field.client_id} onContentChange={onContentChange} content={formData[field.client_id]} formData={formData} required={field.required} 
          restrictMediaFiles={field.restrict_media_files} currentUserEmail={user.email}/>
        {isFormWizard &&  <Description description={field.description}/>}
        <Error/>
      </div>
    </div> 
  )

}

export default InputRichText;