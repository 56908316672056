import React, {useState, Fragment} from 'react';
import {CueTooltip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import CommonService from '../../../services/CommonService';
import ImageEditor from "../../ImageEditor/ImageEditor";
import GenericModal from "../../Modals/GenericModal";
import useModal from "../../../hooks/useModal";
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

let selectedFile = null;

const InputFileUpload = ({field, formData, readOnly, errors, formFn, isFormWizard, formId, openFieldLabelModal, currentLocale}) => {
  const [model, setModel] = useState(formData[field.client_id]);
  const [showError, setShowError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileVirusError, setFileVirusError] = useState(false);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const { isOpen:isImageEditorOpen, toggleModal:toggleImageEditorModal } = useModal();

  let inputAttributes = {
    'type':'file',
    'id': 'file_selector_'+field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {required: true}
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  let privacyType = field.privacy_type || 'private';

  const onChange=event=>{ 
    if('image/svg+xml image/gif'.includes(event.target.files[0].type)){
      uploadFileToUrl(event.target.files, event.target)  
    }else if(field.edit_image){
      selectedFile = event.target.files[0]
      toggleImageEditorModal()
    }else{
      uploadFileToUrl(event.target.files, event.target)  
    }
  }

  const uploadFileToUrl = (files, ele, blob) => {
    try {
      let file = null;
      if(files){
        let FileSize = files[0].size / 1024 / 1024; // in MB
        if (FileSize > 2) {
          ele.value = null
          setFileSizeError(true)
          return;
        }else{
          file = files[0]
          setFileSizeError(false)
        }  
      }else if(blob){
        file = blob
        toggleImageEditorModal()
      }else{
        return
      }

      if(file){
        setShowUploadProgress(true)
        let imageData = {
          'fileable_type':formFn.form_type, 
          'fileable_id':formId,
          'journey_category_id':formFn.journey_category_id
        }
        CommonService.uploadFileToUrl(file, privacyType, imageData).then((res)=> {
          if(res.status == 201){
            let data = res.data;
            setModel(data.file_upload.url);
            formData[field.client_id] = data.file_upload.url;
            setError();  
          }else if(res.status == 422){
            setFileVirusError(true)
          }
          ele.value = null;
          setShowUploadProgress(false)
        }, (err)=>{
          setShowUploadProgress(false)
        })
      }
    } catch (e) {
      setShowUploadProgress(false)
      console.log(e)
    }
  }

  const removeImage= (file, e) => {
    try {
      e.stopPropagation();
      CommonService.deleteFileFromUrl(file).then(function(res) {
        if(res.status == 200 || res.status == 404){
          setModel(null)
          formData[field.client_id] = null;
          setError();
        }
      })
    } catch (e) {
      alert(e);
    }
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  const FileError = () => {
    return(
      <div className="errormsg">
        {fileSizeError && <div>File Size is Large. Max Size 2 MB.</div>}
        {fileVirusError && <div>File Is infected with virus.</div>}
      </div>
    )
  }

  const Image = () =>  {
    return(   
    <div className="upload-sm-card">
      <span className="upload-delete" onClick={e => removeImage(model, e)}>×</span>
      <a href="#">
        <div>
          <img className="w-85" src={CommonService.getIconByType(model)} alt="img"/>
          <strong>{CommonService.getFileName(model)}</strong>
        </div>
      </a>
    </div>
  )}

  return (
    <Fragment>
  	<div {...parentAttributes}>
      <div className="flex coldir m-b-20">
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        { model ? <div><Image/></div> :
          <React.Fragment>
            {!showUploadProgress &&
              <label className={`btn btn-default w-100p bg-highlight color-white ${readOnly && 'input-readonly'}`} htmlFor={`file_selector_${field.client_id}`}
              data-tip data-for={`tooltip_${field.client_id}`}>   
              Click to select and upload file(s)
              </label>
            }
            {showUploadProgress &&
              <label className="btn btn-default w-100p bg-highlight color-white" >   
                Uploading...
              </label>
            }
            <input {...inputAttributes} className="hidden" onChange={e => onChange(e)} />
          </React.Fragment> 
        }
        <span className="fts-13">{field.description}</span>
        <Error/>
        <FileError/>
        <CueTooltip id={field.client_id} description={field.tooltip}/>
      </div>
 	  </div> 
    {isImageEditorOpen && (
        <GenericModal
          widthCss="w-80p"
          component={ImageEditor}
          selectedFile={selectedFile}
          saveImage={uploadFileToUrl}
          title="Image Editor"
          isOpen={isImageEditorOpen}
          toggleModal={toggleImageEditorModal}
          minWidth = {field.min_width || 200}
          minHeight = {field.min_height || 200}
          maxWidth = {field.min_width || 200}
          maxHeight = {field.min_height || 200}/>
    )}
    </Fragment>
  )
}

export default InputFileUpload;