import React, {useState, Fragment, useEffect,useContext,useRef} from "react"
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Form from '../Forms/Form'
import UserFormService from "../UserFormService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import ProjectMenu from "../../Modals/ProjectMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import CommentsModal from "../../Modals/CommentsModal"
import CardMenu from "../../Modals/CardMenu"
import {Link} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import NotesModal from "../../Modals/NotesModal"
import MenuModal from "../../Modals/Menu"
import useStyle from '../../../hooks/useStyle'
import CheckAccess from '../../Journey/Roles/CheckAccess'
import PreLoadSpinner from "../../PreLoadSpinner"
import JourneyTitle from "../../Journey/JourneyTitle/JourneyTitle"
import SideMenuBar from "../../Common/SideMenuBar"
import NotificationPopup from "../../Common/NotificationPopup"
import HelpMessageList from '../../../constants/HelpMessageList'
import {NavIcon,CardMenuInfo,MenuItem,CardMainMenu} from '../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../Common/Swiper"
import CommonService from "../../../services/CommonService"
import AddFormField from "./AddFormField"
import GenericModal from "../../Modals/GenericModal"
import UserFormFieldService from "../UserFormFieldService"
import AddForm from "./AddForm"
import JourneyNotificationType from "../../Journey/JourneyUserGroup/JourneyNotificationType"
import JourneyUserGroupEmailTable from "../../Journey/JourneyUserGroup/JourneyUserGroupEmailTable"
import FavouritesService from "../../../services/FavouritesService"

let requestParams = {}
let selectedForm = {}
let selectedFormField = {}
let dataSource = {}
let journeyCategory = null
let cardViewCount = 1
let publishType = null
let searchParams = {}
let timer = null

const typeOfInput = [
  {'label':'Text Input', 'sid':'textInput'},
  {'label':'Select', 'sid':'select'},
  {'label':'Radio', 'sid':'radio'},
  {'label':'Checkbox', 'sid':'checkbox'},
  {'label':'Multi Select', 'sid':'multi_select'},
  {'label':'Color Picker', 'sid':'color_picker'},
  {'label':'Text Area', 'sid':'textArea'},
  {'label':'Rich Area', 'sid':'richtext'},
  {'label':'Tags', 'sid':'input_tags'},
  {'label':'Date Picker', 'sid':'datepicker'},
  {'label':'Time Picker', 'sid':'timepicker'},
]

const FormsList = (props) => {
  useStyle('card')
  useStyle('card_table')
  useStyle('add_role_dialog')

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "col-xs-12"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-xs-12 col-sm-6 col-md-8"
    cardHeightCss = "mih-380"
  }

  const indexEle = useRef(null)

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [formList, setFormList] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 
  const [loading, setLoading] = useState(false)
  const [pagemeta, setPagemeta] = useState([])
  const [share, setShare] = useState({})
  const [showSearch, setShowSearch] = useState(false)
  const [userGroupEmailNotifications, setUserGroupEmailNotifications] = useState([])

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const { isOpen:isFormFieldModalOpen, toggleModal:toggleFormFieldModal } = useModal()
  const { isOpen:isFormModalOpen, toggleModal:toggleFormModal } = useModal()
  const { isOpen:isNotificationTypeOpen, toggleModal:toggleNotificationType } = useModal()
  const { isOpen:isResultModalOpen, toggleModal:toggleResultModal } = useModal()
  const { isOpen:isUserGroupEmailNotificationOpen, toggleModal:toggleUserGroupEmailNotification } = useModal()

  useEffect(() => {
    journeyCategory = null
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'per_page':10,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'item_type':params.item_type,
      'item_id':params.item_id,
      'form_id':queryParam.case_assessment?FormTemplateId.CaseAssessment:null,
      'id':queryParam.id
    }

    getFormsList()

    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage])

  let getFormsList = () => { 
    setLoading(true)
    UserFormService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.data_source_list
        requestParams.totalItems = res.data.meta.total
        journeyCategory = res.data.journey_category
        setShare(res.data.invited_journey_member)
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
        CheckAccess.userAccess(currentUser, journeyCategory, res.data.invited_journey_member)
        
        if(requestParams.search){
          setFormList(res.data.user_forms)
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setFormList(res.data.user_forms)
        }else{
          setFormList([...formList, ...res.data.user_forms])  
        }

        res.data.user_forms.length == 0 && !requestParams.search && toggleHelpMessage()
      }
    })
  }

  let searchUserForm = () =>{
    UserFormService.search(searchParams).then((res)=>{
      setFormList(res.data.user_forms)
    })
  }

  let handleSearch = (e) =>{
    clearTimeout(timer)
    searchParams = {
      'page':currentpage,
      'per_page':20,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':e.target.value
    }
    timer = setTimeout(searchUserForm, 1000)
  }

  let getEmailNotificationList = (e, item) => { 
    e.preventDefault()
    setLoading(true)
    let req = {
      notifiable_id:item.id,
      notifiable_type:'user_form'
    }
    UserFormService.getEmailNotificationList(item.id, req).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        setUserGroupEmailNotifications(res.data.sent_email_notifications)
        toggleUserGroupEmailNotification()
      }
    })
  }

  const addNotes = (e, a) => {
    selectedForm = a
    toggleCommentModal(e)
  }

  /*const addChecklist = (e, a) => {
    selectedForm = a
    toggleChecklistModal(e)
  }*/

  const deleteAssessment = (e) => {
    e.preventDefault()
    UserFormService.delete(selectedForm.id).then((res)=>{
      if(res.status==204){
        setFormList(formList.filter(i => i.id !== selectedForm.id))
      }
    })
    toggleDeleteModal(e)
  }

  const update = (e, item, key) => {
    e && e.preventDefault()
    let req = null
    if(key){
      req = {[key]: !item[key], id: item.id}
    }

    UserFormService.update(req).then((res)=>{
      if(res.status==204){
        if(key)item[key] = !item[key]
        setFormList([...formList])
      }
    })
  }

  const showMenu = (e, a) => {
    selectedForm = a
    toggleMenuModal(e)
  }

  const updatePreview = (e, form) =>{
    update(e, form, 'is_previewed')
    props.history.push(`/form/submissions/${params.journey_category_id}/${params.journey_profile_id}/${form.id}`)
  }

  const openFormFieldModal = (e, question) =>{
    selectedFormField = question
    toggleFormFieldModal(e)
  }

  const edit = (e, item) =>{
    if(queryParam.case_assessment){
      props.history.push(`/create/form/${params.journey_category_id}/${params.journey_profile_id}/${FormTemplateId.CaseAssessment}/${item.id || ''}`)
    }else{
      selectedForm = item
      toggleFormModal(e)  
    }
  }

  const report = (e, item) =>{
    props.history.push(`/form/reports/${item.id}`,{project:journeyCategory})
  }

  const publishForm = (e) => {
    e.preventDefault()
    toggleResultModal(e)
    toggleNotificationType(e)
  }

  const openNotificationType = (e, item, type) =>{
    publishType = type
    selectedFormField = item
    toggleResultModal(e)
  }

  const updateNotificationItem = (req) =>{
    req[publishType] = true
    UserFormService.update(req).then((res)=>{
      if(res.status==204){
        setFormList([...formList])
      }
    })
  }

  const clone = (e, item) =>{
    e.preventDefault()
    let req = {
      'id':item.id,
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
    }

    UserFormService.clone(req).then((res)=>{
      if(res.status == 200){
        setFormList([res.data.user_form, ...formList])  
      }
    })
  }

  const toggleFavourite = (form) =>{
    setLoading(true)
    if(form.favourite_id){
      FavouritesService.delete(form.favourite_id).then((res)=>{
        if(res.status==204){
          form.favourite_id = null
          setLoading(false)
        }
      })
    }else{
      let req = {
        'favorable_id':form.id,
        'favorable_type':'user_form',
        'education_journey_profile_id':params.journey_profile_id, 
        'journey_category_id':params.journey_category_id
      }
      FavouritesService.create(req).then((res)=>{
        if(res.status==201){
          form.favourite_id = res.data.favourite.id 
          setLoading(false)
        }
      })
    }
  }
    
  const menuItems = ({item}) =>{
    const editAccess = CheckAccess.isPublishedItemEditable(currentUser, share, item)

    return(
      <Fragment>
        <CardMenuInfo recordId={item.record_id} createdAt={item.created_at} 
          userName={item.created_user.name}/>

        {editAccess && 
          <MenuItem id="edit_icon" onSelect={(e) => edit(e, item)}
          icon="far fa-edit" action="Edit"/>
        }

        {editAccess && 
          <MenuItem id="report_icon" onSelect={(e) => report(e, item)}
          icon="far fa-table" action="Report"/>
        }
        
        {editAccess &&
          <MenuItem id="delete_icon" icon="far fa-trash-alt"
            action="Delete" onSelect={(e) => toggleDeleteModal(e)}/>
        }

        {!item.is_published && 
          <MenuItem id="form_field_icon" onSelect={e => openFormFieldModal(e, {})}
          icon="far fa-sticky-note" action="Add Form Field"/>
        }

        {editAccess &&
          <MenuItem id="clone_icon" icon="fas fa-clone"
            action="Copy" onSelect={(e) => clone(e, item)}/>
        }

        {item.is_previewed &&   
          <MenuItem id="publish_icon" onSelect={(e) => openNotificationType(e, item, 'is_published')}
            icon={item.is_published?'far fa-check-circle':'far fa-circle'} 
            action="Publish Form"/>
        }
        
        <MenuItem id="enrollment_icon" onSelect={(e) => update(e, item, 'is_enrollment_form')}
          icon={item.is_enrollment_form?'far fa-check-circle':'far fa-circle'} 
          action="Enrollment Form"/>

        <MenuItem id="form_icon" 
          onSelect={e => updatePreview(e, item)}
          icon="far fa-file" action="Preview Forms"/> 

        {item.is_published && <MenuItem onSelect={e => getEmailNotificationList(e, item)}
          icon="far fa-eye" action="Student Access Status"/> 
        }
          
        {!editAccess && item.is_published && 
          <p className="m-t-10 p-5 lred">
            This item can no longer be edited or deleted since it has been published. Contact the journey administrator for assistance with this item.
          </p>
        }         
      </Fragment>
    )
  }

  const Menu = ({ form}) => (
    <CardMainMenu activity= {form} showMenu={showMenu} />
  )

  const formCard = formList && formList.map((a, i) => (
    <SwiperSlide key={i}>
      <div className={cardGridCss}>
        <Form form={a} addNotes={addNotes} dataSource={dataSource} menu={Menu} 
        journeyCategory={journeyCategory} cardHeightCss={cardHeightCss} 
        openFormFieldModal={openFormFieldModal} index={i}
        typeOfInput={typeOfInput} history={props.history}
        toggleFavourite={toggleFavourite} />
        
        {selectedForm.id == a.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedForm} 
          type="user_forms" MenuItems={menuItems} menuHeight="365px"/>
        }
      </div>
    </SwiperSlide>
  ))

  if(queryParam.id && !loading && formList.length == 0){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Not Found" message="Sorry, this message was deleted by the author"/>)
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4">
          <JourneyTitle title="Form List" project={journeyCategory}/>
        </div>
          
        <div className="col-md-8 float-right">         
          <NavIcon id="add_new_assessment" dataTip="Create New Form" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={e=>edit(e, {})}
            icon="fas fa-plus"/>

          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            onSelect={e=>CommonService.routeJourney(journeyCategory, props)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>

          <NavIcon id="toggle_search" dataTip="Toggle Search" 
            onSelect={e=>setShowSearch(!showSearch)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas far fa-search"/>

          {queryParam.id  && 
            <NavIcon id="back_icon" dataTip="Back to Form" 
              onSelect={e=>props.history.goBack()}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-arrow-left"/>
          }
        </div>
      </div>

      <div className="page-content row">
        {showSearch && 
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <a className="input-group m-t-10" id="search_box">
                <span className="input-group-btn" style={{zIndex:"9",left:'44px'}}>
                  <p type="button" className="input-sm" onClick={e=>handleSearch(e)} style={{height:'40px'}}>
                    <i className="fa fa-search lgrey" style={{fontSize:'26px'}}></i>
                  </p>
                </span>
                <input type="text" onChange={e=>handleSearch(e)} placeholder="Search" className="w-98p form-control input-sm" style={{fontSize:'16px',height:'40px',textIndent:"30px"}} />
                <span className="input-group-btn" style={{zIndex:"9",right:"48px"}}>
                  <p type="button" className="input-sm" onClick={e=> setShowSearch(!showSearch)} style={{height:'40px'}}>
                    <i className="fa fa-times lgrey" style={{fontSize:'26px'}}></i>
                  </p>
                </span>
              </a>
            </div>
          </div> 
        }
        { !queryParam.id && <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
            requestParams={requestParams} indexEle={indexEle} cardViewCount={cardViewCount}>
            {formCard}
          </SwiperContainer> 
        }
        { queryParam.id &&
          <Fragment>
            {formCard}
            <div className="col-sm-12 col-sm-6 col-md-4">
              { formList.length > 0 && <CommentsModal isOpen="true" toggleModal={toggleCommentModal} item={formList[0]} type="carrier_training.user_forms" showInline="true"/> }
            </div>
          </Fragment>           
        } 
        {!queryParam.id && <div className="col-md-12 col-sm-12 text-center">
            <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
          </div>
        }        
      </div>

      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedForm} type="user_form"/>}
     
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteAssessment}/>
     
      {!loading && formList?.length == 0 && 
        <NotificationPopup header="Note" message={HelpMessageList.new_user_form} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      }
     
      {isFormFieldModalOpen &&
        <GenericModal component={AddFormField} title="Add Form Field"
          isOpen={isFormFieldModalOpen} toggleModal={toggleFormFieldModal} form={selectedForm}
          formList={formList} setFormList={setFormList} typeOfInput={typeOfInput}
          existingFormField={selectedFormField} />
      }

      {isFormModalOpen &&
        <GenericModal component={AddForm} existingForm={selectedForm} title="Add Form"
          isOpen={isFormModalOpen} toggleModal={toggleFormModal} 
          formList={formList} setFormList={setFormList} typeOfInput={typeOfInput}/>
      }

      {isNotificationTypeOpen && 
        <GenericModal component={JourneyNotificationType} title="Notification Type" 
        isOpen={isNotificationTypeOpen} toggleModal={toggleNotificationType}
        journeyCategoryId={params.journey_category_id}
        journeyProileId={params.journey_profile_id}
        updateNotificationItem={updateNotificationItem}
        item={selectedForm}/>
      }

      <ConfirmMenu isOpen={isResultModalOpen} toggleModal={toggleResultModal} 
        success={publishForm} 
        message="Are you sure that this Form is complete and ready to be published?"/>
      
      <SideMenuBar helpId="15"/>

      {isUserGroupEmailNotificationOpen &&
        <GenericModal component={JourneyUserGroupEmailTable} title="User Email Status"
          isOpen={isUserGroupEmailNotificationOpen} 
          toggleModal={toggleUserGroupEmailNotification} 
          userList={userGroupEmailNotifications}/>
      }
    </Fragment>
  )

}

export default FormsList;