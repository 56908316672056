import React, {useState, useEffect, useContext, Fragment} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import JourneyUserGroupService from "./JourneyUserGroupService";
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';
import Cs from "../../../services/CommonService"  

let requestParams = {}

const JourneyUserGroupModal = (props) => {

  const [groups, setGroups] = useState([])
  let [group, setGroup] = useState({})
  let [selectedGroup, setSelectedGroup] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'journey_category_id':props.journeyCategoryId,
      'journey_profile_id':props.journeyProileId,
      'groupable_id':props.groupableId,
      'groupable_type':props.groupableType
    }

    getJourneyUserGroup()
  }, [])

  let getJourneyUserGroup = () => { 
    setLoading(true)
    JourneyUserGroupService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setGroups(res.data.journey_user_groups) 
        setLoading(false)
      }
    })
  }

  const deleteGroup = (e) => {
    setLoading(true)
    JourneyUserGroupService.delete(selectedGroup.id).then((res) => {
      if(res.status==204){
        setLoading(false)
      }
    })    
  }

  const addNewGroup = (existing_group) => {
    if(existing_group?.id){
      JourneyUserGroupService.update(existing_group).then((res) => {
        if(res.status==204){
          //restModal(e)
          setGroups([existing_group, ...(groups.filter(r => r.record_id !== existing_group.record_id))])
          setGroup({})
          props.setUserList([...props.userList])
        }
      })  
    }else{
      group.journey_category_id = props.journeyCategoryId
      group.journey_profile_id = props.journeyProfileId
      group.groupable_id = props.groupableId
      group.groupable_type = props.groupableType

      JourneyUserGroupService.create(group).then((res) => {
        if(res.status==201){
          setGroups([res.data, ...groups])
          setGroup({})
        }
      })  
    }
  }

  const setEdit = (existing_group) =>{
    setGroup({...existing_group})
  }

  const onDelete = (existing_group) =>{
    JourneyUserGroupService.delete(existing_group.id).then((res) => {
      if(res.status==204){
        setGroups(groups.filter(r => r.record_id !== existing_group.record_id));
      }
    })
  }

  const toggle = (existing_group, user) => {
    let list = existing_group.user_ids || [];
    let idx = list.indexOf(user.user_registration_id);
    if(idx > -1) {
      list = list.filter(i => i !== user.user_registration_id)
      user.journey_groups = user.journey_groups.filter(i => i.id !== existing_group.id)
    }else {
      list.push(user.user_registration_id)
      user.journey_groups = user.journey_groups || []
      user.journey_groups.push(existing_group)
    }
    existing_group.user_ids = list
    addNewGroup(existing_group)
  }

  const usersList = (existing_group) => props.userList.map((u, k) =>
    <Fragment key={k}>
      {u.id ?
        <div className="m-r-5 d-flex p-5" onClick={e => toggle(existing_group, u)}>
          <input type="checkbox" checked={existing_group.user_ids?.includes(u.user_registration_id)} readOnly/>
          <span className="m-l-5">
            {u.email}
          </span>
        </div>
        :
        null
      }
    </Fragment>
  )

  const groupList = groups.map((n, k) =>
    <div className="p-5 br-grey m-10-0" onClick={e=>setSelectedGroup(n)} key={k}>
      <b>{n.name}</b>
      <i className={'pull-right m-t-8 fas '+(n.id == selectedGroup?.id?'fa-chevron-up':'fa-chevron-down')}/>
      { props?.hasEditUserGroupPermission() &&
        <> 
          <i className="fas fa-edit m-r-20 text-muted p-7 pull-right" onClick={e=>setEdit(n)}/>
          <i className="fas fa-trash m-r-20 text-muted p-7 pull-right" onClick={e=>onDelete(n)}/>
        </>
      }
      {n.id == selectedGroup?.id && usersList(n)}
    </div>
  )

  return (
    <Fragment>
      {loading && <div className="spinner"/>}
      <GroupForm {...{addNewGroup, group, setGroup}}/>
      <div className="m-5 p-5">
        {groupList}
      </div>
    </Fragment>
  )

}

const GroupForm = ({addNewGroup, group, setGroup}) =>{
  return(
    <form className="p-10 br-btm-grey" onSubmit={e=>addNewGroup(group)}>
      <label>Group Name</label>  
      <input type="text" onChange={e=>setGroup({...group, name:e.target.value})} 
        className="form-control ml-margin-bottom"  placeholder="Name" 
        defaultValue={group.name} required/>
      {group.name &&  
        <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>addNewGroup(group)}>
          <b>{group.id?'Update':'Add'}</b>
        </div>
      }
    </form>
  )
} 

export default JourneyUserGroupModal;