import React, {useState, Fragment, useEffect, useRef, useContext} from "react"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Resource from '../List/Resource'
import ResourceService from "../ResourceService"
import ActivityService from "../../Activities/ActivityService"
import Pagination from '../../../../Pagination'
import ConfirmMenu from "../../../../Modals/ConfirmMenu"
import ProjectMenu from "../../../../Modals/ProjectMenu"
import CommentsModal from "../../../../Modals/CommentsModal"
import CardMenu from "../../../../Modals/CardMenu"
import {Link} from "react-router-dom"
import useModal from "../../../../../hooks/useModal"
import NotesModal from "../../../../Modals/NotesModal"
import MenuModal from "../../../../Modals/Menu"
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import useStyle from '../../../../../hooks/useStyle'
import CheckAccess from '../../../Roles/CheckAccess'
import FilterModal from "../../Activities/List/ActivityTypeMenu"
import FavouriteService from "../../../../../services/FavouritesService"
import PreLoadSpinner from "../../../../../components/PreLoadSpinner"
import JourneyTitle from "../../../JourneyTitle/JourneyTitle"
import SideMenuBar from "../../../../../components/Common/SideMenuBar"
import NotificationPopup from "../../../../Common/NotificationPopup"
import GenericModal from "../../../../Modals/GenericModal"
import {MenuItem, CardMenuInfo, NavIcon, CardMainMenu, CheckBoxGroupFilter} from '../../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import CommonService from "../../../../../services/CommonService"
import ReactTooltip from "react-tooltip"
import FilterSlider from "./../../../../Common/FilterSlider"
import {HighSchoolLink} from "../../../../Common/RouteLinks"

let requestParams = {}
let resource = {}
let selectedActivity = {}
let dataSource = {}
let cardViewCount = 1

const ResourceList = (props) => {

  let params = props.match.params;
  let project = props.location.state;

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);

  const [resourceList, setResourceList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState([]);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [filter, setFilter] = useState({});
  const [isFavourite, setIsFavourite] = useState(false); 
  const [formFields, setFormFields] = useState([]);

  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeletePopup } = useModal();
  const { isOpen:isSuccessMessageOpen, toggleModal:toggleSuccessMessage } = useModal();
  
  const indexEle = useRef(null);

  let disableClickEvent = {}
  if(currentUser.current_admin.admin_role_name == 'demo_user'){
    disableClickEvent.pointerEvents = 'none'
  }

  useEffect(() => {
    requestParams = {
      page : currentpage,
      paginate : 1,
      per_page : 15,
      sort_column : 'updated_at',
      sort_direction : 'desc',
      search : props.searchString,
      organization_id : currentUser.current_organization_id,
      favourite : isFavourite,
      latest_data : true, //get recent added information
      is_active : true, //get only active information
      data : filter,
      journey_category_id : params.journey_category_id,
      form_field_params:{
        ids:[193],
        is_table_view:true
      },
      data_source_params: {
        data_source_id:[2214, 3474, 3149, 3376, 2865, 2861, 3441],
      }
    }

    if(props.location.pathname.includes('admin') && currentUser.current_admin.role_content){
      setIsAdminUser(true);
      resource.privacy_type = ['public', 'shared']
      //resource.partnership_id = currentUser.domain_info.partnership_org_id
    }

    getJourneyActivityList()
    ReactTooltip.rebuild()

    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage, isFavourite, filter]);

  let getJourneyActivityList = () => { 
    ResourceService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
        cardViewCount = CommonService.getCardCount(screen) 
        dataSource = res.data.data_source_list
        if(requestParams.search || isFavourite || Object.keys(filter).length != 0){
          setResourceList(res.data.education_resources)
        }else{
          setResourceList([...resourceList, ...res.data.education_resources]);  
          if(currentpage === 1)setFormFields(res.data.form_fields);
        }
      }
    })
  }

  let addToFavorite =(e, a) =>{
    e.preventDefault();
    if(a.favourite_id == null){
      let resource ={}
      resource.favorable_id = a.id;
	    resource.favorable_type = "education_resources"; //type will vary based on card
	    resource.journey_category_id = params.journey_category_id;
  	  resource.event_tracking = {
  		  event_type : "education_resource", //type will vary based on card
  		  event_action : "save_favourite",
        event_label: a.data.label,
        event_id : a.id,
  	  }
      
      FavouriteService.add(resource).then((res)=>{
        if(res.status == 201){
          a.favourite_id = res.data.favourite.id
          setResourceList([...resourceList])
          toggleSuccessMessage(e)
        }
      })
    }else{
      FavouriteService.delete(a.favourite_id).then((res)=>{
        if(res.status == 204){
          a.favourite_id = null;
          setResourceList([...resourceList])
        }
      })
    }
  }

  const cloneEduResource = (e, resource, data) => {
    e.preventDefault();
    var req = {data:{}}
    req.data = data;
    req.data.fj_edu_entry_type = 10;//Other Category
    req.activity_type = 'Other';
    req.activity_form_id = 193;
    //req.activity_form_id = FormTemplateId.education_resource;
    req.education_journey_profile_id = params.journey_profile_id;
    req.journey_category_id = params.journey_category_id;
    req.education_resource_id = resource.id;

   /* req.event_tracking = {
      'event_type':'education_resource',
      'event_action':'clone',
      'event_label':resource.data.name,
      'event_id':resource.id,
      'education_journey_profile_id':params.journey_profile_id
    }*/
    
    //call create activity api
    ActivityService.create(req).then((res) => {
      if(res.status==201){
        props.history.push(`/highschool/activity/${params.journey_category_id}/${params.journey_profile_id}`)     
      }
    })
  }

  const updatePrivacy = (e, item) =>{
    e.preventDefault()
    if(['shared', 'private'].includes(item.privacy_type)){
      item.privacy_type = 'public'
      item.data.share_selection = 1
    }else if(item.privacy_type == 'public'){
      item.privacy_type = 'private'
      item.data.share_selection = 2
    }
    ResourceService.update(item).then((res) => {
      if(res.status==204){
        setResourceList([...resourceList])
      }
    })
  }

  const pinFavourite = (e) =>{
    e.preventDefault()
    setIsFavourite(!isFavourite)
  }

  const showMenu = (e, a) => {
    selectedActivity = a
    toggleMenuModal(e)
  }

  const menuItems = ({item}) =>{
    let isAuthor = (currentUser.id == item.created_by || isAdminUser)
    
    return(
      <Fragment>
        <CardMenuInfo  recordId={item.record_id} 
          createdAt={item.created_at} />

        <a href="#" onClick={e=>addToFavorite(e, item)}>
          <i className={(item.favourite_id?'fa':'far') + ' fa-heart font-15'} aria-hidden="true"></i>
          <span className="font-13">Favorite</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <Link to={`/highschool/resource/review/form/${params.journey_category_id}/${params.journey_profile_id}/${item.id}/`+(item.rating_id?item.rating_id:'')}>
          <i className={(item.rating_id?'fa':'far') + ' fa-star font-15'} aria-hidden="true"></i>
          <span className="font-13">Add Review</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <MenuItem id="copy_act_icon" 
          icon="far fa-clone"
          action="Copy to Activity tab"
          onSelect={(e) => cloneEduResource(e, item, item.data)}
        />
        {isAuthor &&  
          <MenuItem id="edit_icon" 
            link={`/highschool/resource/edit/${item.id}`}
            icon="far fa-edit"
            action="Edit"
          />
        }
        {isAuthor && 
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => toggleDeletePopup(e)}
          />
        }
        {isAdminUser &&  
          <a href="#" onClick={e=>updatePrivacy(e, item)}>
            <i className={(item.privacy_type == 'public' ? "far fa-check-circle": "far fa-circle")+" font-15"}></i>
            <span className="font-13 text-cap">{item.privacy_type}</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }
      </Fragment>
    )
  }

  const deleteResource = (e) => {
    e.preventDefault();
    ResourceService.delete(selectedActivity.id).then((res)=>{
      if(res.status==204){
        setResourceList(resourceList.filter(i => i.id !== selectedActivity.id))
      }
    })
    toggleDeletePopup(e);
  }

  const Menu = ({ activity}) => (
    <CardMainMenu activity= {activity} showMenu={showMenu} disableClickEvent={disableClickEvent} />
  )

  const ResourceFilterList = () => (
    <>
      <CheckBoxGroupFilter title="Type of Journey" name="journey_type_array" options={dataSource.journey.options} filter={filter} setFilter={setFilter}/>
      <small className="m-l-5">{dataSource.journey.id}</small>
      <CheckBoxGroupFilter title="Sharing" name="sharing_array" options={dataSource.sharing_suggestions.options} filter={filter} setFilter={setFilter}/>
      <small className="m-l-5">{dataSource.sharing_suggestions.id}</small>
      <CheckBoxGroupFilter title="Career" name="career_array" options={dataSource.career_types.options} filter={filter} setFilter={setFilter}/>
      <small className="m-l-5">{dataSource.career_types.id}</small>
      <CheckBoxGroupFilter title="Subject Interest" name="subjects_interest_array" options={dataSource.fj_subjects_interest.options} filter={filter} setFilter={setFilter}/>
      <small className="m-l-5">{dataSource.fj_subjects_interest.id}</small>
      <CheckBoxGroupFilter title="Months of Year" name="months_year_array" options={dataSource.months_year.options} filter={filter} setFilter={setFilter}/>
      <small className="m-l-5">{dataSource.months_year.id}</small>
    </>
  )

  const JourneyActivityCard = () => resourceList.map((a, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Resource activity={a} dataSource={dataSource} menu={Menu} 
      currentUser={currentUser} index={i} formFields={formFields[193]}/>
      {selectedActivity.id == a.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
        item={selectedActivity} type="activity" MenuItems={menuItems}/>
      }
    </div>
  ))
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <JourneyTitle title={"Enrich"} project={project} />
            </div>

            <div className="flex aligncenter">
              {!isAdminUser && 
                <NavIcon id="pin" dataTip="Filter by Favourites" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={pinFavourite} icon={"fas fa-heart " + (isFavourite ? 'black' : 'white')}/>
              }
              
              <NavIcon id="filter_res" dataTip="Filter" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-sliders-h" onSelect={toggleFilterModal}/>
              
              {params.journey_category_id && 
                <NavIcon id="home_icon" dataTip="Back to Journey Home" 
                link={HighSchoolLink.hs_portal(params)}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-home"/>
              }

              <NavIcon id="add_resource" dataTip="Create a Resource" 
                link={'/highschool/resource/create/?journey_category_id='+params.journey_category_id+'&journey_profile_id='+params.journey_profile_id}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-plus"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              <JourneyActivityCard/>
            </div>
          </div>
        </div>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeletePopup} 
      success={deleteResource}/>
      
      <SideMenuBar helpId="13"/> 

      {isSuccessMessageOpen &&
        <GenericModal
          component={NotificationPopup}
          header="Success"
          message="Added Successfully"
          onSuccess={toggleSuccessMessage}
          isOpen={isSuccessMessageOpen}
          toggleModal={toggleSuccessMessage}/>
      }

      {isFilterOpen && 
        <FilterSlider component1={ResourceFilterList} 
        openSideNav={isFilterOpen} 
        setOpenSideNav={toggleFilterModal}/>
      }
    </Fragment>
  )

}

export default ResourceList;