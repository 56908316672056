import React, { useEffect, useState, useContext} from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import JourneyPlannerService from "../../../../../components/Journey/HighSchoolJourney/Planners/JourneyPlannerService";
import FormContainer from '../../../../FormBuilder/FormContainer';

let formMode = null 
let activity = {} 
let data = {} 
let formFn = {}  
let form_template_id = null

function PlannerForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search)

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    JourneyPlannerService.get(params.id).then((res) => {
      if(res.status==200){
        activity = res.data.planner_list;
        data = activity.data || {}
        form_template_id = activity.planner_form_id
        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function(res){
       activity = null
    })
  }
  
  useEffect(() => { 
    activity = {} 
    data = {} 
    formFn = {'form_type':'planner_list'} 
    formMode = 'create-form-submissions';
    data = {} 
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (activity) => {
    activity.created_by = authState.user.id;
    activity.planner_type = params.type;
    activity.planner_form_id = params.form_template_id;  
    activity.data.fj_edu_entry_type = params.activity_id;
    activity.journey_category_id = queryParam.journey_category_id; 
    activity.journey_profile_id = queryParam.journey_profile_id; 
    activity.privacy_type = data.share_selection == 1 ? 'shared':'private'
    activity.partnership_id = authState.user.domain_info.partnership_org_id
    activity.organization_id = authState.user.current_organization_id
    JourneyPlannerService.create(activity).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    })
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id;
    activity.planner_type = params.type;
    activity.planner_form_id = params.form_template_id;

    JourneyPlannerService.update(activity).then((res) => {
      if(res.status==204){
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = () => {
    if(queryParam.journey_category_id){
      props.history.push(`/highschool/planners/${queryParam.journey_category_id}/${queryParam.journey_profile_id}`)
    }else{
      props.history.push(`/admin/highschool/planners`)
    }
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
         <DynamicForm formMode={formMode} formId={ params.form_template_id } form={activity} data={data} 
            formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null
  }
  
}

export default PlannerForm;