import React ,{Fragment, useState} from "react"
import Cs from '../../../../../services/CommonService'
import { HamburgerMenuIcon } from "../../../../Common/ImageIcons";
import {FormFieldTable, RenderData} from "../../../UserRoles/NotificationPill"

const PlannerContent = (props) => {
  let journeyPlanner = props.journeyPlanner;
  let {data, data_source} = journeyPlanner
  let formFields = props.formFieldsList[journeyPlanner.planner_form_id] || []

  let ds = props.dataSources.fj_edu_entry_type.options;
  const cardStyle = {}

  journeyPlanner.updated_at = Cs.formatUpdateDate(
    journeyPlanner.updated_at,
    "MMM DD YYYY"
  )
  
  try {
    cardStyle.backgroundColor = ds.fj_edu_entry_type.options[journeyPlanner.data.fj_edu_entry_type].header_color;
  } catch (e) {
    cardStyle.backgroundColor = '#607D8B'
  }

  const privacyType = (key) => {
    if(key == 'public'){
      return (<span className="font-16">C</span>);
    }else if(key == 'private'){
      return (<span className="font-16">P</span>);
    }else if(key == 'shared'){
      return (<span className="font-16">PC</span>);
    }
  }


  const Header = () =>(
    <div className="white card-header  p-5" style={cardStyle}>
      <div className="font-16 ellipsis-lg">
        {data.planner_heading || data.name}
      </div>
      <div className="pull-right font-16 top-n15 m-r-5">
        {privacyType(journeyPlanner.privacy_type)}
      </div>
      <div>{journeyPlanner.updated_at}</div>
    </div>
  )

  const GenericContent = () =>(
    <div className="bg-white border-rounded-10 m-t-10" style={cardStyle}>
      <div className="card-title card-title-gray flex aligncenter p20 justspacebetween p-b-15">
        <div className="flex aligncenter">
          <div className="w-100">
            <h4 className="fw700 card-heading">{data.planner_heading || data.name}</h4>
            {/* <p className="f12">{journeyPlanner.record_id}</p> */}
            <p className="f12">since {journeyPlanner.updated_at} by {journeyPlanner.author.name}
            </p>
          </div>
        </div>
        <div>
          <span className="badge badge-danger-dark text-white m-r-5 pull-right">{privacyType(journeyPlanner.privacy_type)}</span>
        </div>
      </div>
      <div className="card-content p10-20">
        <div className="b-tab-table active scroll-auto ht-400 m-t-5">
          <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
        </div>
      </div>
      <div className="card-footer flex justspacebetween p10-20 aligncenter">
        <ul className="flex aligncenter">
        </ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, journeyPlanner)}/>
      </div>
    </div>
  )

  if(!data)return null;
  return (<GenericContent/>)
    
}

export default PlannerContent;