import React, { Fragment, useState, useEffect, useContext } from "react"
import ReactDOM from "react-dom"
import { Link, useHistory } from "react-router-dom"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import useStyle from "../../../hooks/useStyle"
import CommonService from "../../../services/CommonService"
import useEscape from "../../../hooks/useEscape"
import FormTemplateId from "../../../constants/FormTemplateList"
import env from "../../../env"
import ReactTooltip from "react-tooltip"

let requestParams = {}

const JourneyTypeMenu = (props) => {
  useStyle("add_role_dialog")
  useStyle("filter_dialog")
  
  const history = useHistory()
  let params = props.match?.params
      
  let {isOpen, toggleModal, journeyMember, isPopupMode=true} = props

  useEscape(toggleModal)
  
  const {state: { user: currentUser }} = useContext(AuthContext)
  const [journeyCategory, setJourneyCategory] = useState()
  const [loading, setLoading] = useState(true)

  let profile = currentUser.domain_info
  let dataSource = profile.data_source
  let isAdmin = (currentUser.current_admin.role_master || env.admin_emails.includes(currentUser.email))

  if(params){
    if(params.journey_member_id)
      journeyMember = {id:params.journey_member_id}
    
    isPopupMode = false
  }    

  useEffect(() => {
    requestParams = {
      data_source_id: [2860],
    }
    getJourneyCategory()
  }, [])

  let getJourneyCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      //let d = CommonService.groupBy(res.fj_type_edu_jou.options, 'actions');
      setJourneyCategory(res.fj_type_edu_jou.options)
      setLoading(false)
    })
  }

  const routeJourney = (journey_type) => {
    let qp = ''
    if(journeyMember)
        qp += `&member_id=${journeyMember.id}`
      
    if(journey_type.value == 35){
      history.push(
        `/journey_profile?form_id=1012&fj_type_edu_jou=35${qp}`
      )
      return
    }

    history.push(
      `/journey_profile?show_form_wizard=1&form_id=${journey_type.child_form}&form_wizard_id=${journey_type.wizard_id}&fj_type_edu_jou=${journey_type.value}${qp}`
    )
  }

  const MenuItem = ({ k }) => (
    <div className="ver-icons" onClick={(e) => routeJourney(k)} data-tip={k.descriptions}>
      <span>
        <i className={k.icon || "fas fa-plus"} style={{ color: k.icon_color }}/>
      </span>
      <div className="popup-menu-title">{k.label}</div>
      <i className="fas fa-chevron-right lgrey pull-right m-t-8" />
      <p className="m-l-42 font-12 lgrey">
        {k.descriptions}
      </p>
    </div>
  )
  
  const checkAdmin = (k) => {
    if(k.read_only != "yes"){
      return true
    }else if(k.read_only == 'yes' && isAdmin){
      return true
    }
  }

  const PersonalTypeList = () => journeyCategory.map((k) => {
    return(
      <Fragment key={k.value}>
        {(k.user.includes("Personal") && checkAdmin(k)) &&(
          <MenuItem k={k} />
        )}
      </Fragment>
    )
  })

  /*k.org_user.includes(profile.data.type_organizational_use) && */
  
  const OrgTypeList = () => journeyCategory.map((k) => (
    <Fragment key={k.value}>
      { k.user.includes("Organizational") && checkAdmin(k) &&(
         <MenuItem k={k} />
      )}
    </Fragment>
  ))

  if(!isPopupMode){
    return(
      <div id="ct">
        <div className="app_content">
          <h5 className="m-t-5">TYPE OF EDUCATION JOURNEY</h5>
          {loading && <div className="spinner"/>}
          {journeyCategory && (
            <div className="popup-menu-grid">
              {profile.data.type_of_use == 1 && PersonalTypeList()}
              {profile.data.type_of_use == 2 && OrgTypeList()}
              <small className="font-9 lgrey lh-16 p-5">2860</small> 
              <ReactTooltip place="bottom"/> 
            </div>
          )}
        </div>
      </div>        
    )
  }

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">TYPE OF EDUCATION JOURNEY</h4>
            {toggleModal &&
              <button className="btn-close"
                title="Close Modal" onClick={(e) => toggleModal(e)}>×
              </button>
            }
          </div>
          {loading && <div className="spinner"/>}
          {journeyCategory && (
            <div id="ct">
              <div className="popup-menu-grid">
                {profile.data.type_of_use == 1 && PersonalTypeList()}
                {profile.data.type_of_use == 2 && OrgTypeList()}
                <small className="font-9 lgrey lh-16 p-5">2860</small> 
                <ReactTooltip place="bottom"/> 
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>, document.body
  )

}

export {JourneyTypeMenu};