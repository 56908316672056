import React, {useState, useEffect, Fragment, useContext} from "react"
import ReactDOM from 'react-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import CommonService from '../../services/CommonService'
import Notification from "./Notification"
import LiveNotification from "./LiveNotification"
import NotificationRoute from  "./NotificationRoute"
import SideMenuBar from "../../components/Common/SideMenuBar"

let requestParams = {}
let selectedNotification = null

const NotificationList = (props) => {
  useStyle('/css/notification.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  useStyle('/css/common.css', true)

  const { state:currentUser, dispatch } = useContext(AuthContext)
  const [notifications, setNotifications] = useState([])

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
    }

    if(props.instantNotification){
      setNotifications([props.instantNotification])
    }else{
      getNotificationsList()
	} 

	//document.addEventListener("keydown", handleEscape)
    //document.addEventListener("click", handleClick, true)
    return () => {
      //document.removeEventListener("keydown", handleEscape)
      //document.removeEventListener("click", handleClick, true)
    }
  }, [props.searchString])

  /*const handleEscape = (e) => {
    if (e.keyCode === 27)  props.toggleNotification(e)
  }

  const handleClick = (e) => {
	 props.toggleNotification(e)

  }*/

  let getNotificationsList = () => { 
    CommonService.getNotifications(requestParams).then((res)=>{
     if(res.status == 200){
       setNotifications(res.data.notifications)
      }
    })
  }
  
  const onDelete = (e, notification) => {
    selectedNotification = notification
    CommonService.deleteNotification({id:selectedNotification.id}).then((res) => {
      if(res.status==204){
        setNotifications(notifications.filter(r => r.id !== selectedNotification.id))
      }
    })   
  }

  const setNotificationRead = (n) => {
    let req = {'status':'read'}
    CommonService.updateNotification(n.id, req).then((res)=>{
      n.status = 'read'
    })
  }

  const routeNotification = (n, e) =>{
    setNotificationRead(n)
    NotificationRoute.routeNotification(n, props)
    //props.toggleNotification(e)
  }

  const NotificationView = () => notifications.map((n, i) => (
    <Notification key={i} notification={n} onDelete={onDelete} routeNotification={routeNotification}/>           
  ))

  const LiveNotificationView = () => notifications.map((n, i) => (
    <LiveNotification key={i} notification={n} onDelete={onDelete} routeNotification={routeNotification}/>           
  ))

  const Notifications = () =>(
    <Fragment>
      <div className="app-content-section m-t-30 m-t-0-xs m-t-20-xs">
        <div className="m-t-30 m-t-0-xs">
          <div className="bg-white p20 border-rounded-10">
            <div className="bg-white flex justspacebetween aligncenter">
              <h5 className="f15 fw500">Unread 1 notification</h5>
            </div>
            <div className="row m-t-30">
              <div className="col-md-12">
                {notifications.map((n, i) => 
                  <Notification key={i} notification={n} onDelete={onDelete} 
                  routeNotification={routeNotification}/>           
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
  
  if(props.showAsPopup){
    return(ReactDOM.createPortal(<Notifications/>, document.body))
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div  className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20">
            <div className="flex coldir fulwid p20">
              <div className="flex alignstart justspacebetween">
                <div>
                  <div className="app-title m-r-20">Notifications</div>
                  <p className="m-t-10">Org Master</p>
                </div>
              </div>
            </div>
          </div>
          <Notifications/>
          <SideMenuBar helpId="2"/>
        </div>
      </div>
    </Fragment>
  )

}

export default NotificationList;


/*
<div className={`col-xs-12 ${props.showAsPopup && 'popup-notification'}`}>
      <div className="card notification-ui_dd">
        <div className="popup-notification-container">
          <div className="notification-ui_dd-header">
    		    <h3>
              <i className="fas fa-comments m-r-5 font-24 color-highlight"/> 
              Notifications {props.showAsPopup && <span onClick={e=>props.toggleNotification(e)} className="clearfix fa fa-times notification-close"></span>}
            </h3>
          </div>
		      <div className={`m-t-10 ${props.showAsPopup && 'notification-ui_dd-content'}`}>
            {props.instantNotification ? <LiveNotificationView/> : <NotificationView/>}
		      </div>
		      {props.showAsPopup && 
            <div className="notification-ui_dd-footer" >
		          <a className="notification-list notification-success notification-block" href="/notifications">
                View All
		          </a>
            </div>
          }
		    </div>
      </div>
    </div>
*/