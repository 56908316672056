import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import querystringify from "querystringify"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from "../../../components/FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import JourneyProfileService from "../JourneyProfileService"
import JourneyCategoryService from "../JourneyCategoryService"
import CommonService from "../../../services/CommonService"
import FormWizard from "../../../components/FormBuilder/FormWizard"
import env from "../../../env"
import FormContainer from '../../FormBuilder/FormContainer'
import {CareerPosition} from '../../Common/CareerPosition'

let formMode = null
let formWizardId = null
let journeyProfile = {}
let journeyCategory = {}
let data = {}
let formFn = {}
let requestParams = {}

function JourneyProfileForm(props) {
  const { state: authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  formFn.school_name_pagenate = {page:1, per_page:10}
  formFn.school_name_path = 'school_name'

  formFn.colleges_pagenate = {page:1, per_page:10}
  formFn.colleges_path = "name"

  formFn.school_zipcode_pagenate = {
    page: 1,
    per_page: 10,
    unique: true,
    fields: "zip",
    sort_column: "zip"
  }

  formFn.school_zipcode_path = "zip"
  formFn.school_location_country_pagenate = {page:1, per_page:10}
  formFn.school_location_country_path = "name"

  formFn.college_pagenate = {page:1, per_page:10}
  formFn.college_path = "name"

  let currentUser = authState.user
  const journeyMemberId = (queryParam.member_id || currentUser.domain_info?.member_id)
  
  /*TODO: Move API to Router to load data before view*/
  const getById = (id) => {
    JourneyProfileService.get(id).then((res) => {
      if (res.status == 200) {
        journeyProfile = res.data.education_journey_profile;
        data = journeyProfile.data || {}
        setFormRendered(true)
      }
    })
  }

  useEffect(() => {
    formMode = "create-form-submissions"
    data = {fj_type_edu_jou: parseInt(queryParam.fj_type_edu_jou)}
    journeyProfile = {}
    journeyCategory = {}
    formFn = { form_type: "education_journey_profile" }
    requestParams = {}
    if (queryParam.view_mode) {
      getById(params.id)
      formMode = "view-form-submissions"
    } else if (params.id) {
      formMode = "edit-form-submissions"
      getById(params.id)
    } else {
      setFormRendered(true)
    }
    formFn.successMessage = `Next, you can launch your cueTree education journey. Click "OK" to begin`
  }, [])

  const create = (journey_profile) => {
    formFn.setLoading(true)
    journey_profile.created_by = currentUser.id
    journey_profile.family_id = currentUser.domain_info.id
    journey_profile.organization_id = currentUser.current_organization_id
    journey_profile.form_id = queryParam.form_id
    journey_profile.journey_member_id = queryParam.member_id
    requestParams.journey_profile = journey_profile
    requestParams.journey_member_id = journeyMemberId
    JourneyCategoryService.create(requestParams).then((res) => {
      if (res.status == 201) {
        journeyProfile = res.data.journey_profile
        journeyCategory = res.data.journey_category
        journeyCategory.education_journey_profiles = journeyProfile
        currentUser.journey_count += 1
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser }
        })
        formFn.setLoading(false)
        redirectSubscription()
      }
    })
  }

  const update = (journey_profile) => {
    formFn.setLoading(true)
    JourneyProfileService.updateJourneyProfile(journey_profile).then((res) => {
      if (res.status == 204) {
        journeyProfile = journey_profile
        formFn.setLoading(false)
        onSubmitPageRedirect()
      }
    })
  }

  const onCreateSuccess = () => formFn.setIsFormSubmitted && formFn.setIsFormSubmitted()

  const redirectSubscription = () => {
    if(true){//journeyCategory.subscription_id
      onSubmitPageRedirect()
    }else{
      props.history.push({
        pathname:`/plans/${journeyProfile.journey_category_id}`+(journeyMemberId?`/${journeyMemberId}`:''),
        search: '?journey_type='+(journeyProfile.data?.fj_type_edu_jou),
        state:{journey:journeyCategory}
      })
    }
  }

  const onSubmitPageRedirect = () => {
    const journey_type = journeyProfile.data?.fj_type_edu_jou;
    if(props.isPopupMode){
      journeyProfile?.id && props.onSubmit(journeyProfile)
      props.toggleModal()
    }else if (journey_type == 14) {
      /*Team journey template*/
      props.history.push(
        `/team/project/portal/${journeyProfile.journey_category_id}`
      )
    } else if (journey_type == 1) {
      /*Kids journey*/
    } else if (journey_type == 2) {
      /*Mid School journey*/
    } else if (journey_type == 6) {
      /*Course journey template*/
      props.history.push(
        `/course/project/portal/${journeyProfile.journey_category_id}`
      )
    } else if (journey_type == 3) {
      /*if (show_success_message) {
        formFn.setIsFormSubmitted()
        return;
      }*/
      
      /*props.history.push(
        `/highschool/project/portal/${journeyProfile.journey_category_id}`
      )*/
      const queryParam = {
        current_grade:journeyProfile?.data?.fj_edu_current_grade,
        carrier_interests:journeyProfile?.data?.goals?.career_interest?.single_goal_id
      }
      
      props.history.push({
        pathname:`/highschool/path/${journeyProfile.journey_category_id}/${journeyProfile.id}`,
        search:CommonService.getQueryString(queryParam)
      })
      /*High School journey*/
    }else if (journey_type == 4) {
      /*Undergraduate Journey*/
      props.history.push(
        `/college/portal/${journeyProfile.journey_category_id}`
      )
    }
    else if (journey_type == 20) {
      /*Training Journey*/
      props.history.push(
        `/career_training/project/portal/${journeyProfile.journey_category_id}`
      )
    }else if (journey_type == 30) {
      /*Pre College Journey*/
      props.history.push(
        `/college/admissions/${journeyProfile.journey_category_id}/${journeyProfile.id}`
      )
    }else if (journey_type == 35) {
      props.history.push(
        `/career/path/${journeyProfile.journey_category_id}/${journeyProfile.id}`
      )
    }else{
      back()
    }
  }

  const back = () => {
    props.history.goBack()
  }

  formFn.get_school_zipcode = function (search, on_success, on_failure) {
    if (data.school_location == "Other") return;
    formFn.school_zipcode_pagenate.zip = search;

    let req = {
      method: "get",
      url: `${env.family_tree_backend}schools`,
      params: formFn.school_zipcode_pagenate,
    }

    CommonService.useAxios(req).then((res) => {
      formFn.school_zipcode_list = res.data.schools
      if (res.data.meta.total && formFn.school_zipcode_pagenate.per_page) {
        formFn.school_zipcode_pagenate.pageSize = Math.ceil(
          res.data.meta.total / formFn.school_zipcode_pagenate.per_page
        )
      }
      on_success(formFn.school_zipcode_list)
    })
  }

  formFn.set_school_zipcode = function (obj) {
    data.school_zipcode = obj.zip;
    //formFn.get_school_name(obj.zip, true)
    formFn.school_zipcode_list = [];
  }

  formFn.get_school_name = function(search, on_success, on_failure){
    if(data.school_location=='Other')return;
    formFn.school_name_pagenate.school_name = search;  

    var req = {
      method: 'get',
      url: `${env.family_tree_backend}schools`,
      params: formFn.school_name_pagenate
    }
    
    CommonService.useAxios(req).then((res) => {
      formFn.school_name_list = res.data.schools;
      if(res.data.meta.total && formFn.school_name_pagenate.per_page){
        formFn.school_name_pagenate.pageSize = Math.ceil(res.data.meta.total/formFn.school_name_pagenate.per_page)
      }
      on_success(formFn.school_name_list)
    })
  }

  formFn.set_school_name = function(obj){
    data.school_zipcode = obj.zip;
    data.school_name = obj.school_name;
    formFn.school_name_list = [];
  }

  formFn.get_school_location_country = function(search, on_success, on_failure){
    formFn.school_location_country_pagenate.search = search;
  
    let req = {
      method: 'get',
      url: `${env.backend}countries`,
      params: formFn.school_location_country_pagenate
    }

    CommonService.useAxios(req).then(({data, status})=>{
      formFn.school_location_country_list = data.countries;
      if(data.meta.total && formFn.school_location_country_pagenate.per_page){
        formFn.school_location_country_pagenate.pageSize = Math.ceil(data.meta.total/formFn.school_location_country_pagenate.per_page)
      } 
      on_success(formFn.school_location_country_list)
    })
  }

  formFn.set_school_location_country = function(obj){
    data.school_location_country = obj.name;
    formFn.school_location_country_list = [];
  }

  formFn.get_college = function(search, on_success, on_failure){
    formFn.college_pagenate.college_name = search;
    let req = {
      method: 'post',
      url: `${env.family_tree_backend}colleges/search`,
      data: formFn.college_pagenate
    }

    CommonService.useAxios(req).then(({data, status})=>{
      formFn.college_list = data.colleges;
      if(data.meta.total && formFn.college_pagenate.per_page){
        formFn.college_pagenate.pageSize = Math.ceil(data.meta.total/formFn.college_pagenate.per_page)
      } 
      on_success(formFn.college_list)
    })
  }

  formFn.set_college = function(obj){
    data.college = obj.name;
    data.college_fk_id = obj.id;
    formFn.college_list = [];
  }

  formFn.get_colleges = function(search, on_success, on_failure){
    formFn.colleges_pagenate.college_name = search;
    let req = {
      method: 'post',
      url: `${env.family_tree_backend}colleges/search`,
      data: formFn.colleges_pagenate
    }

    CommonService.useAxios(req).then(({data, status})=>{
      formFn.colleges_list = data.colleges;
      if(data.meta.total && formFn.colleges_pagenate.per_page){
        formFn.colleges_pagenate.pageSize = Math.ceil(data.meta.total/formFn.colleges_pagenate.per_page);
      } 
      on_success(formFn.colleges_list)
    })
  }

  formFn.toggle_colleges = (item) =>{
    const idx = data.colleges_array.findIndex(o => o.id == item.id);
    if(idx > -1) {
      data.colleges_array.splice(idx, 1);
    }else {
      data.colleges_array.push({id:item.id, name:item.name});
    }
    data.colleges = data.colleges_array.map((o)=> o.id).join(",");
    return data.colleges_array
  }

  formFn.exist_colleges = (item) =>{
    return data.colleges_array.findIndex(o => o.id == item.id) > -1;
  }

  if (queryParam.show_form_wizard) {
    return (
      <FormWizard form_wizard_id={queryParam.form_wizard_id}
        form={journeyProfile} data={data}
        formFn={formFn} onCreate={create}
        onUpdate={update} onCancel={onSubmitPageRedirect}
        onCloseSuccessMessage={redirectSubscription}
        isInsideChatBot={props.isInsideChatBot}
        location={props.location}/>
    )
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={queryParam.form_id} 
         form={journeyProfile} data={data} formFn={formFn}
         onCreate={create} onUpdate={update} post={post} 
         onCancel={onSubmitPageRedirect} setPost={setPost} 
         onCloseSuccessMessage={redirectSubscription}/>
        <CareerPosition data={data} formFn={formFn} />
      </FormContainer>
    )
  } else {
    return null
  }

}

export default JourneyProfileForm;