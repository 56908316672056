import React, { Fragment, useEffect, useRef, useState, useContext } from "react"
import { Link } from "react-router-dom"
import AuthenticationService from "../../services/AuthService"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CheckAccess from "../Journey/Roles/CheckAccess"
import {SideMenuLink} from "../Common/RouteLinks"

let orgName = ""

const SideMenu = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const sideNavEl = useRef(null)
  const sideNavMenuEl = useRef(null)
  if (currentUser.current_organization) {
    orgName = currentUser.current_organization.name
  }
  
  let role_manager = CheckAccess.getUserPermission(currentUser, 'role_manager')

  const signout = (e) =>{
    e.stopPropagation()
    e.preventDefault()
    AuthenticationService.signout().then(()=>{
      dispatch({type: "LOGOUT"})
    })
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscape)
    document.addEventListener("click", handleClick, true)

    return () => {
      document.removeEventListener("keydown", handleEscape)
      document.removeEventListener("click", handleClick, true)
    }
  }, [])

  const handleEscape = (e) => {
    if (e.keyCode === 27) props.setOpenSideNav(false)
  }

  const handleClick = (e) => {
    if (sideNavEl.current && (!sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))){
      props.setOpenSideNav(false)
    }
  }
  
  return (
    <Fragment>
      <div id="menu-main" ref={sideNavEl}
        className={`menu menu-box-left menu-box-detached round-medium ${props.openSideNav ? "menu-active" : ""} `} style={{width: "260px",opacity: "1",display: "block"}}>
     
        <div className="menu-icons">
          <Link to="/journey" data-toggle-theme>
            <i className="fa fa-desktop color-yellow1-dark" />
          </Link>
          <Link to="/journey/members" data-menu="menu-highlights">
            <i className="fa fa-users color-green1-dark" />
          </Link>
          <Link to="/user/profile?tab=7" data-menu="menu-share">
            <i className="fa fa-dollar-sign color-orange-dark" />
          </Link>
          <Link to="/user/profile?tab=1"  onClick={(e) => props.setOpenSideNav(!props.openSideNav)}>
            <i className="fa fa-cog color-blue2-dark" />
          </Link>
          <a href="#" className="close-menu"
            onClick={(e) => {e.preventDefault();props.setOpenSideNav(!props.openSideNav);}}>
            <i className="fa fa-times color-red2-dark " />
          </a>
          <div className="clear" />
        </div>
     
      <div className="menu-items">
        <div className="center-text">
          <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
            <i className="fab fa-twitter" />
          </a>
          <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
            <i className="fab fa-instagram" />
          </a>
          <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
            <i className="fab fa-linkedin-in" />
          </a>
          <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
            <i className="fab fa-whatsapp" />
          </a>
        </div>
        <div className="menu-header">
          <p className="under-heading center-text font-11 p-7 lh-16">
            Signed in as <span className="highlight">{currentUser.name}</span>
          </p>
          <a href="#" className="d-block" />
          <h1 className="center-text font-800 font-12 line-height-medium uppercase">
            Transform education journeys
          </h1>
          <p className="under-heading center-text font-11 p-7 lh-16">For parents, students, 
            counselors, teams, and other education influencers
          </p>
        </div>
        <div className="sm-content top-20 bottom-10">
          <div className="link-list link-list-1" ref={sideNavMenuEl}>
            <Link id="nav-welcome" to={SideMenuLink.home}>
              <i className="fa fa-home color-blue2-dark" />
              <span>Home</span>
              <i className="fa fa-angle-right" />
            </Link>

            <Link id="nav-welcome" to={SideMenuLink.journey}>
              <i className="fa fa-desktop color-blue2-dark" />
              <span>Dashboard</span>
              <i className="fa fa-angle-right" />
            </Link>

            <a id="locale_btn" onClick={(e) => props.toggleLocaleModal()}>
              <i className="fas fa-globe color-blue2-dark" />
              <span>Langauge</span>
              <i className="fa fa-angle-right" />
            </a>

            {CheckAccess.hasJourneyMemberAccess(currentUser) &&
              <Link id="nav-members" to={SideMenuLink.members}>
                <i className="fa fa-users color-blue2-dark" />
                <span>List of Students</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
              
            <Link id="nav-contact" to={SideMenuLink.invite_users}>
              <i className="fa fa-users color-blue2-dark" />
              <span>Contacts</span>
              <i className="fa fa-angle-right" />
            </Link>

            {currentUser.current_admin.role_manager &&
              <Link id="nav-user-group" to={SideMenuLink.user_groups}>
                <i className="fas fa-user-lock color-blue2-dark" />
                <span>Permissions</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
              
            <Link id="nav-contact" to={SideMenuLink.schedules}>
              <i className="fas fa-calendar-alt color-blue2-dark" />
              <span>Calendar</span>
              <i className="fa fa-angle-right" />
            </Link>

            <Link id="nav-contact" to={SideMenuLink.news}>
              <i className="fa fa-globe color-blue2-dark" />
              <span>News</span>
              <i className="fa fa-angle-right" />
            </Link>
            
            <Link id="nav-roles" to={SideMenuLink.user_dabbles}>
              <i className="fas fa-book color-blue2-dark" />
              <span>User Submitted Cues</span>
              <i className="fa fa-angle-right" />
            </Link>
            
            <a id="nav-chat" href="/" onClick={e=>props.toggleChatModal(e)}>
              <i className="fas fa-comments color-blue2-dark" />
              <span>Chat Messenger</span>
              <i className="fa fa-angle-right" />
            </a>

            {currentUser.current_admin.role_content && 
              <Link id="nav-roles" to={SideMenuLink.digital_contents}>
                <i className="fas fa-newspaper color-blue2-dark" />
                <span>Digital Content</span>
                <i className="fa fa-angle-right" />
              </Link>
            }

            {role_manager.training_content && 
              <Link id="nav-roles" to={SideMenuLink.forums}>
                <i className="fas fa-newspaper color-blue2-dark" />
                <span>Training Forums</span>
                <i className="fa fa-angle-right" />
              </Link>
            }

            {role_manager.training_content && <Fragment>
              <Link id="nav-training-content" to={SideMenuLink.ad_course_module}>
                <i className="fa fa-chalkboard-teacher color-blue2-dark" />
                <span>Training Manager</span>
                <i className="fa fa-angle-right" />
              </Link>      
            </Fragment>}

            {role_manager.news &&
              <Link id="nav-contact" to={SideMenuLink.ad_news}>
                <i className="fa fa-globe color-blue2-dark" />
                <span>News Manager</span>
                <i className="fa fa-angle-right" />
              </Link>
            }  
            {role_manager.interview &&  
              <Link id="nav-contact" to={SideMenuLink.ad_questions}>
                <i className="fa fa-user-tie color-blue2-dark" />
                <span>Interview Manager</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
            {role_manager.planner &&  
              <Link id="nav-roles" to={SideMenuLink.ad_highschool_planners}>
                <i className="fas fa-sitemap color-blue2-dark" />
                <span>Planner Manager</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
            {role_manager.planner &&  
              <Link id="nav-roles" to={SideMenuLink.career_admin_planners}>
                <i className="fas fa-sitemap color-blue2-dark" />
                <span>Career Planner Manager</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
            {role_manager.planner &&  
              <Link id="nav-roles" to={SideMenuLink.intervention_protocols}>
                <i className="fas fa-book color-blue2-dark" />
                <span>Intervention Protocols</span>
                <i className="fa fa-angle-right" />
              </Link>
            }  
            {role_manager.enrichment &&  
              <Link id="nav-roles" to={SideMenuLink.ad_resources}>
                <i className="fas fa-book color-blue2-dark" />
                <span>Education Resources</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
            {role_manager.enrichment &&  
              <Link id="nav-roles" to={SideMenuLink.ad_scholarships}>
                <i className="fas fa-book color-blue2-dark" />
                <span>Education Scholarships</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
            {role_manager.enrichment &&  
              <Link id="nav-roles" to={SideMenuLink.list_daily_dabble}>
                <i className="fas fa-calendar-check color-blue2-dark" />
                <span>Daily Cue</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
            {currentUser.current_admin.role_admin && 
              <Link id="nav-roles" to={SideMenuLink.list_feedback}>
                <i className="far fa-comment color-blue2-dark" />
                <span>Feedback</span>
                <i className="fa fa-angle-right" />
              </Link>
            }

            {currentUser.current_admin.role_admin && 
              <Link id="nav-roles" to={SideMenuLink.leadership}>
                <i className="fas fa-trophy color-blue2-dark" />
                <span>LeaderShipDashboard</span>
                <i className="fa fa-angle-right" />
              </Link>
            }

            {currentUser.current_admin.role_admin && 
              <Link id="nav-roles" to={SideMenuLink.newsletter_emails}>
                <i className="fas fa-book color-blue2-dark" />
                <span>Newsletter</span>
                <i className="fa fa-angle-right" />
              </Link>
            }

            {currentUser.current_admin.role_admin && 
              <Link id="nav-roles" to={SideMenuLink.list_subscriptions}>
                <i className="fas fa-book color-blue2-dark" />
                <span>Subscriptions</span>
                <i className="fa fa-angle-right" />
              </Link>
            }

            <a href="/" onClick={(e) => signout(e)} className="">
              <i className="fa fa-sign-out-alt color-red2-dark " />
              <span>Sign Out</span>
            </a>
            
          </div>
          <div className="center-text bottom-75 m-t-10">
            <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
              <i className="fab fa-twitter" />
            </a>
            <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
              <i className="fab fa-instagram" />
            </a>
            <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
              <i className="fab fa-linkedin-in" />
            </a>
            <a href="#" className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
              <i className="fab fa-whatsapp" />
            </a>
            <p className="top-10 font-10 opacity-50">
              <i className="fa fa-copyright" aria-hidden="true"></i>
              {new Date().getFullYear()} IPIPAL INC. All Rights
              Reserved.
              <label className="m-l-5 text-muted">
                <Link className="font-12 text-muted m-r-10" to="/about_us/terms_of_service">&nbsp;Terms of Service</Link>
                <Link className="font-12 text-muted" to="/about_us/privacy_policy">&nbsp;Privacy Policy</Link>
              </label>
            </p>
          </div>
        </div>
     
        </div>
      </div>
    </Fragment>
  )
}

export default SideMenu;