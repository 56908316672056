import React, {Fragment, useState, useEffect} from "react"
import {Link, useParams} from "react-router-dom"
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc'
import arrayMove from '../../../Common/arrayMove'
import AssessmentListModal from "../Assessments/List/AssessmentListModal"
import useModal from "../../../../hooks/useModal"
import GenericModal from "../../../Modals/GenericModal"
import UserFormListModal from "../../../UserForms/UserFormListModal"
import ReactTooltip from "react-tooltip"
import {FileListLink, VedioLink} from '../../../Common/Image'

let selectedLesson = null;

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt m-5 black"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const CourseTopicsList = ({o, topicList, updateSortOrder, loading, module, modules, setModules, index, courseId}) => {
  let params = useParams()

  const [topics, setTopics] = useState(topicList || []);
  const [currentModule, setCurrentModule] = useState(o || []);

  const { isOpen:isAssessmentModalOpen, toggleModal:toggleAssessmentModal} = useModal();
  const { isOpen:isUserFormModalOpen, toggleModal:toggleUserFormModal} = useModal();

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      /*console.log(topics[newIndex].data.name)*/
      nextItemId = topics[newIndex].planner_id
    }else if(newIndex+1 == topics.length){
      /*console.log(topics[newIndex].data.name)*/
      priorItemId = topics[newIndex].planner_id
    }else if(oldIndex > newIndex){
      /*console.log(topics[newIndex-1].data.name)
      console.log(topics[newIndex].data.name)*/

      priorItemId = topics[newIndex-1].planner_id
      nextItemId = topics[newIndex].planner_id
    }else if(oldIndex < newIndex){
      /*console.log(topics[newIndex].data.name)
      console.log(topics[newIndex+1].data.name)*/

      priorItemId = topics[newIndex].planner_id
      nextItemId = topics[newIndex+1].planner_id
    }

    function onSuccess(){
      //setTopics()
      modules[index].topics = arrayMove(topics, oldIndex, newIndex)
      setModules([...modules]) 
    }

    updateSortOrder(topics[oldIndex].planner_id, priorItemId, nextItemId, onSuccess)
  }

  let ContentList = ({contents}) => contents.map((c, i) => (
    <Fragment>
      <div className="m-l-5 p-7" key={i}>
        &#8226; {c.data.name}
      </div>
      <div className="p-5">
        <div className="">
          <FileListLink list={c.data.upload} showFileName="true" styleName="pill m-r-15 black" iconClass="attach-sm"/>
          <VedioLink url={c.data.video_url} showFileName="true" styleName="pill black" iconClass="fas fa-video lblue"/> 
        </div>
        <div className="m-t-5 m-l-5 font-13">
          <div className="p-5">
            <i className={`fas fa-file-alt ${c.assessment?.id?'green':'highlight'}`}  data-tip="Assessment" onClick={e=>addAssessment(c)}></i><span className="m-l-5 black font-13">
            {c.assessment?.data?.title || "-"}
            </span>
          </div>
          <div className="p-5">
            <i className={`fas fa-user-check ${c.survey?.id?'green':'highlight'}`}  data-tip="Survey" onClick={e=>addSurveyForm(c)}></i><span className="m-l-5 black font-13">
            {c.survey?.data?.title || "-"}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  ))

  const addAssessment = (lesson) =>{
    selectedLesson = lesson
    toggleAssessmentModal()
  }

  const addSurveyForm = (lesson) =>{
    selectedLesson = lesson
    toggleUserFormModal()
  }

  const SortableItem = sortableElement(({o}) => {
    return(
      <div className="col-xs-12 m-t-5 br-btm-grey black d-block">
        <DragHandle/>
        {o.data.name}
        <div className="m-l-5 p-5">
          <div className="m-l-5">
            <FileListLink list={o.data.upload} showFileName="true" styleName="pill m-r-15 black" iconClass="attach-sm"/>
            <VedioLink url={o.data.video_url} showFileName="true" styleName="pill black" iconClass="fas fa-video m-l-5 lblue"/> 
          </div>
        </div>
        <div className="m-l-10">
          <ContentList contents={o.contents || []}/>
        </div>
      </div>
    )
  })

  const topicData = topics.map((o, k) =>
    <SortableItem key={`item-${k}`} index={k} o={o} />
  )

  return (
    <>
      <SortableContainer onSortEnd={onSortEnd} distance={1} useDragHandle>
        {topicData}
      </SortableContainer>

      {isAssessmentModalOpen && <GenericModal component={AssessmentListModal} 
        journeyCategoryId={params.journey_category_id} 
        journeyProfileId={params.journey_profile_id}
        toggleModal={toggleAssessmentModal}
        item={selectedLesson}
        title="Add Assessment"
        courseId={courseId}/>
      }

      {isUserFormModalOpen && <GenericModal component={UserFormListModal} 
        journeyCategoryId={params.journey_category_id} 
        journeyProfileId={params.journey_profile_id}
        toggleModal={toggleUserFormModal}
        item={selectedLesson}
        title="Add Review Form"
        courseId={courseId}/>
      }
    </>
  )

}

export default CourseTopicsList;