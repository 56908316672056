import React ,{Fragment ,useEffect, useState}from "react";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Teamnodes from '../../constants/Teamnodes';
import useStyle from '../../hooks/useStyle';
import skrollr from './skrollr.js'
import DigitalContentService from '../DigitalContent/DigitalContentService'
import env from "../../env"
import LottieLight from "../Common/LottieLight"
import { Link } from "react-router-dom";
import Logo from "../Authentication/Logo"

let requestParams = {}

const Marketing = () => {
  useStyle('marketing');

  window.addEventListener("scroll", ()=>{
    console.log(document.documentElement.scrollTop)
  })

  const { state:{screen, user:currentUser}, dispatch,isAuthenticated: isAuthenticated } = React.useContext(AuthContext);
  const [digitalContentList, setDigitalContentList] = useState([]);

  useEffect(() => {
    requestParams = {
      'content_type': 'marketing_image',
      'page': 1,
      'paginate': 1,
      'per_page': 10,
      'sort_column': 'updated_at',
      'sort_direction': 'asc',
      'totalItems': 0,
      'search': null,
    }

    getMarketingContent();
  }, []);

  let getMarketingContent = () => { 
    DigitalContentService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       requestParams.totalItems = res.data.meta.total
       setDigitalContentList(res.data.digital_contents)
       skrollr.init(); 
      }
    })
  }

  let skroll = skrollr || null;
  useEffect(() => {

  }, []);

  let style = {'position': 'fixed', 'float': 'none', 'margin': '8% 0% 5% 0%', 'width': '100%'}
  let scroll = [
    {"data-0":"right:0%;", "data-500":"", "data-1000":"right:100%;"},
    {"data-500":"right:-100%;", "data-750":"right:-50%;", "data-1000":"right:0%;", "data-1500":"", "data-2000":"right:100%;"},
    {"data-1500":"right:-100%;", "data-1750":"right:-50%;", "data-2000":"right:0%;", "data-2500":"", "data-3000":"right:100%;"},
    {"data-2500":"right:-100%", "data-2750":"right:-50%;", "data-3000":"right:0%;", "data-3500":"", "data-4000":"right:100%;"}
  ]

  const ImageMapArea = ({map}) =>{
    let coords = map.coords;
    if(map.type == 'circle'){
      return(<area shape="circle" coords={`${coords.cx} ${coords.cy} ${coords.radius}`} />)
    }else if(map.type == 'rectangle'){
      return(<area shape="rect" coords={`${coords.x} ${coords.y} ${coords.width} ${coords.height}`} />)
    }else if(map.type == 'polygen'){
      return(<area shape="poly" coords="140,121,181,116,204,160,204,222,191,270,140,329,85,355,58,352,37,322,40,259,103,161,128,147" />)
    }else{
      return null
    }
  }

  const ImageMapSvg = ({map}) =>{
    let coords = map.coords;
    let attributes = map.attributes;

    if(map.type == 'circle'){
      return(
          <g>
            <text x={coords.cx} y={coords.cy} fill="red">{attributes.title}</text>
            <circle data-tip={attributes.description} data-for='svgTooltip' cx={coords.cx} cy={coords.cy} r={coords.radius}></circle>
          </g>
      )
    }else if(map.type == 'rectangle'){
      return(
          <g>
            <text x={coords.x} y={coords.y} fill="red">{attributes.title}</text>
            <rect data-tip={attributes.description} data-for='svgTooltip' x={coords.x} y={coords.y} width={coords.width} height={coords.height}></rect>
          </g>
      )
    }else if(map.type == 'polygon'){
      let points = ''
      coords.points.forEach(function (p) {
        points = points+`${p.x} ${p.y} `
      })
      return(
        <g><polygon data-tip={attributes.description} data-for='svgTooltip' points={points}></polygon></g>
      )
    }else{
      return null
    }
  }

  const ImageMap = ({map_list, i}) =>{
    let mapList = JSON.parse(map_list.area_io)
    return(
      <Fragment>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.2" baseProfile="tiny" id="svg" width="1280" height="720">
          {mapList && mapList.areas.map((c, k) => (
            <ImageMapSvg map={c} key={k}/>
          ))}
        </svg>
        <ReactTooltip id='svgTooltip'  multiline={true} />
      </Fragment>
    ) 
  }

  /*data-0="right:0%;" data-500="" data-1000="right:100%;"*/

  const listContent =  digitalContentList && digitalContentList.map((c, k) => (
    <div className="row p-32" style={style} key={k} {...scroll[k]}>
        <div className="col-xs-12 p-0">
          <img src={env.file_url+c.data.image.image_url} useMap={`#image_map_${k}`}/>
          <ImageMap map_list={c.data.image} i={k}/>
        </div>
    </div> 
  ))

  return (
    <Fragment>
      <div className="marketing-header row">
        <div className="col-md-2 col-xs-4  m-t-5">
          <Logo />
        </div>
        <div className="col-md-10 col-xs-8 marketing-title m-t-5">
          <h4>cueTree Learning Journeys</h4>
          <span className="bg-black white round-tab">BETA</span>
        </div>
      </div>

      {listContent}
    </Fragment>
  )

  return (
  <Fragment>
    <div className="marketing-header row">
      <div className="col-md-2 col-xs-4  m-t-5">
      <a href="/signup"><img className="marketing-logo" src={'./../../../images/cueTree_logo.png'} /> </a></div>
      <div className="col-md-10 col-xs-8 marketing-title m-t-5">
        <h4>cueTree Learning Journeys</h4>
        <span className="bg-black white round-tab">BETA</span>
      </div>
    </div>

    <div className="row p-32" style={style}
    data-0="right:0%;" data-500="" data-1000="right:100%;">
      <div className="col-xs-12 p-0">
        <img src="images/marketing/Edu_Frame 1.svg"/>
      </div>
    </div>

    <div className="row p-32" style={style}
    data-500="right:-100%;" data-750="right:-50%;" data-1000="right:0%;" data-1500="" data-2000="right:100%;">
      <div className="col-xs-12 p-0">
        <img src="images/marketing/Edu_Frame 2.svg"/>
      </div>
    </div>

    <div className="row p-32" style={style}
      data-1500="right:-100%;" data-1750="right:-50%;" data-2000="right:0%;" data-2500="" data-3000="right:100%;">
      <div className="col-xs-12 p-0">
        <img src="images/marketing/Edu_Frame 3.svg"/>
      </div>
    </div>

    <div className="row p-32" style={style}
      data-2500="right:-100%" data-2750="right:-50%;" data-3000="right:0%;" data-3500="" data-4000="right:100%;">
      <div className="col-xs-12 p-0">
        <img src="images/marketing/Edu_Frame 4.svg"/>
      </div>
    </div>


  </Fragment>
  )
}

export default Marketing;


/*
<div className="marketing-footer row">
      <div className="col-md-2 col-xs-4  m-t-5">
      <i className="far fa-copyright"></i> 2020 IPIPAL INC. All Rights Reserved.</div>
      <div className="col-md-10 col-xs-8 m-t-5 p-12">
        <a href="" className="black">Terms</a>
        <a href="" className="black">Privacy</a>
        <a href="" className="black">Contact</a>
      </div>
    </div>
*/