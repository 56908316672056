import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";
class HelpService extends BaseService{
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}helps`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(id, req){
    console.log('getById help',id,req)
    return new Promise((resolve, reject) => {
      axios.get(`${url}helps/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url+'helps', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}helps/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}helps/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }*/

}

export default new HelpService('family_tree_backend', 'helps');
