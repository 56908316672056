import React, { useEffect, useState, useContext, Fragment } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import JourneyCategoryService from "../JourneyCategoryService"
import NewsService from "../../../components/News/NewsService"
import Cs from "../../../services/CommonService"
import Notification from "../../Notifications/Notification"
import Journey from "../../Journey/List/Journey"
import useStyle from "../../../hooks/useStyle"
import { Link } from "react-router-dom"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import env from "../../../env"
import NotificationRoute from "../../Notifications/NotificationRoute"
import SideMenuBar from "../../../components/Common/SideMenuBar"
import { NavIcon } from "../../Common/MenuItem"
import FormTemplateList from "../../../constants/FormTemplateList"

let journey_paginate = null;
let cue_news_paginate = null;
let notifications_paginate = null;
let schedules_paginate = null;
let data_source = null;
let users = {}
let profileImage = "images/avatars/2s.png";

const JourneyDashboard = (props) => {
  useStyle('/css/common.css', true);
  useStyle('/css/header.css', true);
  useStyle('/css/sidebar-theme.css', true);
  useStyle('/css/responsive.css', true);
  useStyle('/css/our-journey.css', true);
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [visible, setVisible] = useState(true);
  const [journeyList, setJourneyList] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [favJourneyList, setFavJourneyList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [schedulesList, setScheduleList] = useState([]);

  /*const activities = () =>{
    let req = {'accelerator_id': currentUser.accelerator.id}
    AcceleratorService.activities(req).then((res)=>{
      setData(res.data.activities);
    })
  }*/

  useEffect(() => {
    journey_paginate = {
      page: 1,
      paginate: 1,
      per_page: 4,
      search: null,
      sort_column: "updated_at",
      sort_direction: "desc",
      data_source_params: {
        response_type: "object",
        data_source_id: [3170, 3171, 2860, 3231],
        group_by: "value",
      },
    }

    getJourneyDashboard()
  }, [currentUser]);

  let getJourneyDashboard = () => {
    JourneyCategoryService.getJourneyDashboard().then((res) => {
      if (res.status == 200) {
        users = res.data.users;
        data_source = res.data.data_source_list;
        setJourneyList(res.data.journey_categories);
        setFavJourneyList(res.data.fav_journey_categories);
        setNewsList(res.data.cue_newses);
        setScheduleList(res.data.education_schedules);
      }
    })
  }

  const routeNotification = (n) => {
    NotificationRoute.routeNotification(n, props);
  }

  let routeCueNews = (news) => {
    //props.history.push(`/news?id=${news.id}`);
    window.open(news.data.news_url, '_blank')
  }

  let routeJourney = (journey) => {
    journey.education_journey_profiles.journey_category_id = journey.id;
    Cs.routeJourney(journey, props, null, currentUser);
  }

  let routeSchedules = (schedule) => {
    //TODO
  }

  const toggleShowView = () => {
    setVisible(!visible);
  }

  const onDelete = (e, notification) => {
    Cs.deleteNotification({ id: notification.id }).then((res) => {
      if (res.status == 204) {
        setNotifications(notifications.filter((r) => r.id !== notification.id));
      }
    })
  }

  const getProfileImg = (n) => {
    if (n.sender && n.sender.profile_image_url) {
      const userImg = env.file_url + n.sender.profile_image_url;
      return userImg;
    }
    return profileImage;
  }

  const handleClick =(id)=> {
    //document.getElementById('info').scrollTop -= 10;
    document.getElementById('info_'+id).scrollTop += 10;
  }

  /*const notificationView = notifications.map((n, k) => (
    <Fragment key={k}>
      <i className="pull-right fa fa-times lred" onClick={(e) => onDelete(e, n)}/>
      <div className="d-flex pointer" onClick={(e) => routeNotification(n)}>
        <div>
          <img src={getProfileImg(n)} className="rounded-circle bg-lgrey-2" width="50"/>
        </div>
        <div className="m-l-10">
          <h5 className="d-title">
            {n.journey_profile_name} - {n.email_data && n.email_data.type}
          </h5>
          <p className="d-description">
            {n.email_data && n.email_data.title.substring(0, 30) + "...."}
          </p>
          <p className="d-description ">
            {n.sender_name}
            <span className="lred">
              {Cs.formatUpdateDate(n.updated_at)}
            </span>
          </p>
        </div>
        <div className="ml-auto"></div>
      </div>
      <div className="divider" />
    </Fragment>
  ))*/

  const cueNewView = newsList.map((n, k) => (
    <Fragment key={k}>
      <div className="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
          <div className="flex">
            <div className="flex aligncenter justifycenter m-r-15">
              <img src={n.data.og_image}  width="150"/>
            </div>
            <div>
              <h4 className="fw700 f16 card-heading">
                <a href={n.data.news_url} target="_blank">{n.data.title_news_item}</a>
              </h4>
              <p className="text-gray f12 m-b-0">
                {Cs.extractHostName(n.data.news_url)}
              </p>
              <p className="text-gray f12 m-b-0">
              {Cs.formatUpdateDate(n.data.date_published_article_formatted_date)}
              </p>
            </div>
          </div>
          <a href={n.data.news_url} target="_blank" className="arrow-icon flex aligncenter justifycenter border-rounded-10">
            <img src="./images/arrow-right.png" alt="" />
          </a>
        </div>
    </Fragment>
  ))

  const JourneyTitle = ({j}) =>{
    let title = Cs.journeyTitle(j, j.education_journey_profiles)
    let subPlan = currentUser?.current_subscription?.name
    if(j.journey_member_id){
      subPlan = currentUser.journey_members[j.journey_member_id]?.subscription?.name
    }
    
    return(
      <Fragment>
        <h4 className="fw700 f16 card-heading">{title}</h4>
        <div className="f14 m-b-0">{subPlan}</div>
      </Fragment> 
    )
  }

  const journeyView = (list) => list && list.map((j, k) => {
    let profileImage = users[j.created_by] && users[j.created_by].profile_image
    let updatedBy, updatedAt = 0
    profileImage = Cs.getIconByType(profileImage, 'profile_image')
    if(j.education_journey_profiles){
      updatedBy = j.education_journey_profiles.updated_by
      updatedAt = j.education_journey_profiles.updated_at  
    }

    return (
      <Fragment key={k}>
        <div className="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
          <div className="flex">
            <div className="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
              <img src={profileImage}  width="50"/>
            </div>
            <div>
            <JourneyTitle j={j}/>
              <p className="text-gray f12 m-b-0">
                last by {users[updatedBy] && users[updatedBy].name} {Cs.formatUpdateDate(updatedAt)} 
              </p>
              <p className="text-gray f12 m-b-0">
                since {Cs.formatUpdateDate(j.created_at)} by {users[j.created_by] && users[j.created_by].name}
              </p>
            </div>
          </div>
          <a className="arrow-icon flex aligncenter justifycenter border-rounded-10" onClick={(e) => routeJourney(j)}>
            <img src="./images/arrow-right.png" alt="" />
          </a>
        </div>
      </Fragment>
    )
  })

  const schedulesView = schedulesList.map((s, k) => {
    return (
      <Fragment key={k}>
        <div className="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
          <div className="flex">
            <div className="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
              <img src="images/avatars/2s.png"  width="50"/>
            </div>
            <div>
              <h4 className="fw700 f16 card-heading">{s.title}</h4>
              <p className="text-gray f12 m-b-0">
                {Cs.formatUpdateDate(s.start)}
              </p>
            </div>
          </div>
          <a className="arrow-icon flex aligncenter justifycenter border-rounded-10" onClick={(e) => routeSchedules(s)}>
            <img src="./images/arrow-right.png" alt="" />
          </a>
        </div>
      </Fragment>
    )
  })

  /*if(newsList.length == 0 && schedulesList.length == 0 && journeyList.length == 0 && notifications.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">Education Dashboard</div>
            </div>
            <div className="flex aligncenter">
              <div className="badge badge-sm border-rounded-30 badge-dark text-white fw900 f12">
                This Month
              </div>
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs">
            <div className="row">
              <div className="col-md-3">
                <div className="card card-highlite card-yellow-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 className="fw700 card-heading">Pinned Journeys</h4>
                    <Link to="/journey?pinned=true" className="f12">Tab to view more</Link>
                  </div>
                  <div className="card-number m-r-10">{favJourneyList.length}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card card-highlite card-blue-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 className="fw700 card-heading">Upcoming Activities</h4>
                    <a href="" className="f12">Tab to view more</a>
                  </div>
                  <div className="card-number m-r-10">{schedulesList.length}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card card-highlite card-litegreen-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 className="fw700 card-heading">Recent Journeys</h4>
                    <Link to="/journey" className="f12">Tab to view more</Link>
                  </div>
                  <div className="card-number m-r-10">{journeyList.length}</div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="card card-highlite card-yellow-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 className="fw700 card-heading">News</h4>
                    <Link to="/news" className="f12">Tab to view more</Link>
                  </div>
                  <div className="card-number m-r-10">{newsList.length}</div>
                </div>
              </div>
            </div>
            <div className="row m-t-30">
              <div className="col-md-4">
                <div className="bg-white border-rounded-10 p20 m-b-15">
                  <div id="pinned_journeys" className="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div className="fa-card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <i className="fas fa-thumbtack"/>
                    </div>
                    <div className="w-100">
                      <Link to="/journey?pinned=true">
                        <h4 className="fw700 card-heading">Pinned Journeys</h4>
                        <p className="text-gray f12">Over all journey</p>
                      </Link>
                    </div>
                    <div id="new_journey"
                      className="fa-card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter">
                      <Link 
                        to="/journey/members">
                        <img src="./images/add-round-24.png" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-content">
                    {journeyView(favJourneyList)}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bg-white border-rounded-10 p20 m-b-15">
                  <div id="recent_journeys" className="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div className="fa-card-icon border-rounded card-litegreen-bg flex aligncenter justifycenter m-r-15">
                      <i className="fas fa-home" />
                    </div>
                    <div className="w-100">
                      <Link to="/journey" >
                        <h4 className="fw700 card-heading">Recent Journeys</h4>
                        <p className="text-gray f12">Over all journey</p>
                      </Link>
                    </div>
                    <div id="new_journey" className="fa-card-icon border-rounded card-litegreen-bg flex aligncenter justifycenter">
                      <Link 
                        to="/journey/members">
                        <img src="./images/add-round-24.png" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-content">                                     
                    {journeyView(journeyList)}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bg-white border-rounded-10 p20 m-b-15">
                  <div id="news" className="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div className="fa-card-icon border-rounded card-yellow-bg flex aligncenter justifycenter m-r-15">
                      <i className="fas fa-globe" />
                    </div>
                    <div className="w-100">
                      <Link to="/news">
                        <h4 className="fw700 card-heading">News</h4>
                        <p className="text-gray f12">Over all news</p>
                      </Link>
                    </div>
                    <div className="fa-card-icon border-rounded card-yellow-bg flex aligncenter justifycenter">
                      <Link to={`/news/create/241/`}>
                        <img src="./images/add-round-24.png" alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="card-content">
                    {cueNewView}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="bg-white border-rounded-10 p20 m-b-15">
                  <div id="schedules" className="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div className="fa-card-icon border-rounded card-blue-bg flex aligncenter justifycenter m-r-15">
                      <i className="far fa-calendar-alt" />
                    </div>
                    <div>
                      <h4 className="fw700 card-heading">Upcoming Activities</h4>
                      <p className="text-gray f12">Over all activity</p>
                    </div>
                  </div>
                  <div className="card-content">
                    {schedulesView}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div id="suggestions" className="bg-white border-rounded-10 p20 m-b-15">
                  <div className="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div className="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <i className="fas fa-desktop" />
                    </div>
                    <div>
                      <h4 className="fw700 card-heading">Suggestions for action</h4>
                      <p className="text-gray f12">Over all suggestions</p>
                    </div>
                  </div>
                  <div className="card-content">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 



    {/* <div id="ct">
      <div className="app_content">
          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_1">
                <Link to="/journey?pinned=true" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0 d-in-block">
                    <i className="fas fa-thumbtack m-r-10 font-20 bg-black white wh-35 text-center p-t-5"/>
                    Pinned Journeys
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>

                  <NavIcon id="add_new_journey" dataTip="Launch New Journey" 
                    className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                    icon="fas fa-plus" 
                    link="/journey/members"/>
                </Link>

                <div className="p-10 ht-home scroll-auto ">
                  {journeyView(favJourneyList)}
                </div>
              </div>
              <div className="p-5 ht-30">
                {favJourneyList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(1)}></p>}
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_2">
                <h3 className="head-boder br-btm-grey p-10 p-b-0">
                  <i className="far fa-calendar-alt m-r-10 font-24 bg-black white wh-35 text-center p-t-5"/>
                  Upcoming Activities
                  <p className="font-10 lgrey">Tap to view more.</p>
                </h3>
                <div className="p-10 ht-home scroll-auto ">
                  {schedulesView}
                </div>
              </div>
              <div className="p-5 ht-30">
                {schedulesList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(2)}></p>}
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_3">
                <Link to="/journey" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0 d-in-block">
                    <i className="fas fa-home m-r-10 font-24 bg-black white wh-35 text-center p-t-5" />
                    Recent Journeys
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>

                  <NavIcon id="add_new_journey" dataTip="Launch New Journey" 
                    className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                    icon="fas fa-plus" 
                    link="/journey/members"/>
                </Link>
               
                <div className="p-10 ht-home scroll-auto ">
                  {journeyView(journeyList)}
                </div>
              </div>
              <div className="p-5 ht-30">
                {journeyList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(3)}></p>}
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_4">
                <Link to="/news" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0 d-in-block">
                    <i className="fas fa-globe m-r-10 font-24 bg-black white wh-35 text-center p-t-5"/>
                    News
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>
                  <NavIcon id="add_new_journey" dataTip="Launch New Journey" 
                    className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                    icon="fas fa-plus" link={`/news/create/241/`}/>
                </Link>
                <div className="p-10 ht-home scroll-auto ">
                  {cueNewView}
                </div>
              </div>
              <div className="p-5 ht-30">
                {newsList.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(4)}></p>}
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <div className="card card-style">
              <div className="d-content m-b-0" id="info_4">
                <Link to="#" className="head-boder br-btm-grey">
                  <h3 className="p-10 p-b-0">
                    <i className="fas fa-desktop m-r-10 font-24 bg-black white wh-35 text-center p-t-5"/>
                    Suggestions for action
                    <p className="font-10 lgrey">Tap to view more.</p>
                  </h3>
                </Link>
                <div className="p-10 ht-home scroll-auto ">
                </div>
              </div>
              <div className="p-5 ht-30">
              </div>
            </div>
          </div>
      </div>
    </div> */}
    <SideMenuBar helpId="2"/>
      
    </Fragment>
  )
}

export default JourneyDashboard;