import React, { Fragment, useState, useEffect, useContext } from 'react';
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const CookieNotice = (props) => {
  useStyle('cookienotice');
  const [visible, setVisible] = useState(false);
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);

  const toggleAgree =() =>{
    localStorage.is_cookie_accepted = true
    setVisible(!visible)
  }

  if(!visible){
    return (
      <div className="cookie-banner" role="alert">
        <p className="p-10">
          <strong>NOTICE :</strong> This website and related services use cookies to record and manage usage by each user.
           We use this data to manage and improve the services. 
           To learn more, please refer to the <a className="white d-in-grid underline" target="_blank" href="/about_us/privacy_policy">cueTree Privacy policy</a>, as they are updated. 
           By closing this notice or continuing to use this website or related services, you consent to the use of cookies according to the applicable terms.
        </p>
        <button type="button" onClick={e=>toggleAgree()} className="btn acceptcookies pull-right" aria-label="Close">
          ACCEPT
        </button>
      </div>
    )
  }else{
    return null;
  }

}

export default CookieNotice;