import React, { useState, useEffect, Fragment, useContext} from "react";
import { Link } from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../PreLoadSpinner";
import {NavIcon} from '../../../Common/MenuItem'
import Pagination from '../../../Pagination'
import PlannerService from './PlannerService'
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle";
import TrainingModuleService from "../TrainingModules/TrainingModuleService";
import {FileListLink, VedioLink} from '../../../Common/Image'
import ReactTooltip from 'react-tooltip';

let requestParams = {}

const CourseFilterList = (props) => {
  useStyle('card')
  useStyle('card_table')

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  let params = props.match.params;
  let journey_category = props.location.state;

  const [courseModules, setCourseModules] = useState([]);
  const [currentpage, setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'add_planner_id':true,
      'journey_category_id':params.journey_category_id,
      'search': props.searchString,
      'add_contents': true,
      'filter':'planner',
      'course_id':params.course_id
    }  
    getModules()
  }, [props.searchString, currentpage])

  let getModules = () => { 
    setLoading(true)
    TrainingModuleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setCourseModules(res.data.modules)
        setLoading(false)
      }
    })
  }

  let addToCoursePlan = (item, filterable_type) =>{
    if(item.planner_id){
      PlannerService.delete(item.planner_id).then((res)=>{
        if(res.status == 204){
          item.planner_id = null;
          removePlannerId(item.topics)
          setCourseModules([...courseModules])
        }
      })
    }else{
      let req = {
        journey_category_id: params.journey_category_id,
        journey_profile_id: params.journey_profile_id,
        filterable_type: filterable_type,
        filterable_id : item.id,
        course_id: params.course_id
      }
      PlannerService.create(req).then((res)=>{
        if(res.status == 201){
          item.planner_id = res.data.course_filter.id
          setPlannerId(item.topics, res.data.topic_planner_ids)
          setCourseModules([...courseModules])
        }
      })
    }
  }   

  let setPlannerId = (list=[], planner_ids={}) =>{
    list.forEach((m)=>{
      m.planner_id = planner_ids[m.id]
    })
  }

  const handleClick =(id)=> {
    //document.getElementById('info').scrollTop -= 10;
    document.getElementById('info_'+id).scrollTop += 10;

  }

  let removePlannerId = (list=[]) =>{
    list.forEach((m)=>{
      m.planner_id = null
    })
  }

  let ContentList = ({contents}) => contents.map((c, i) => (
    <div className="p-5 m-l-15" key={i}>
      &#8226; 
      <span className="m-l-5 font-15">
        {c.data.name}&emsp;&emsp;
      </span>
      <div className="p-5">
        <div className="">
          <FileListLink list={c.data.upload} showFileName="true" styleName="pill m-r-15 black" iconClass="attach-sm"/>
          <VedioLink url={c.data.video_url} showFileName="true" styleName="pill black m-r-15" iconClass="fas fa-video lblue"/> 
        </div>
        <div className="m-t-5">
          {c.assessment && <Fragment>
            <i className="fas fa-file-alt green m-l-8"></i><span className="m-l-5 black font-13">
          {c.assessment?.data?.title}
          </span>
          </Fragment>}
          {c.survey && <Fragment>
            <i className="fas fa-user-check green m-l-8"></i><span className="m-l-5 black font-13">
          {c.survey?.data?.title}
          </span>
          </Fragment>}
        </div>
      </div>
    </div>
  ))

  let TopicList = ({module, topics}) => topics.map((c, i) => (
    <div className="p-5 m-l-15 br-btm-grey" key={i}>
      {module.planner_id ?
        <input type="checkbox" className="font-16" defaultChecked={c.planner_id} 
        onClick={e=>addToCoursePlan(c, 'carrier_training.topic')}/>
      :
        <span>&#8226;</span>  
      }
      <span className="m-l-5 font-15 bold">{c.data.name}&emsp;&emsp;</span>
      <div className="m-l-5">
        <FileListLink list={c.data.upload} showFileName="true" styleName="pill black m-l-15" iconClass="attach-sm m-l-5"/>
        <VedioLink url={c.data.video_url} showFileName="true" styleName="pill black m-l-15" iconClass="fas fa-video m-l-5 lblue"/> 
      </div>
      <ContentList contents={c.contents || []}/>
    </div>
  ))

  let ModuleList = () => courseModules.map((c, i) => {
    
    return(
      <div className="col-xs-12 col-sm-6 col-md-4 font-16 m-b-15" key={i}>
        <div className="br-grey bg-white">
          <div className="p-5 white d-flex" style={{backgroundColor:c.data.bg_color || 'black'}}>
          &emsp;&emsp;<input type="checkbox" className="font-16" defaultChecked={c.planner_id} 
              onClick={e=>addToCoursePlan(c, 'carrier_training.module')}/>&emsp;&emsp;
            <span className="m-l-5 font-16 ellipsis-lg bold">{c.data.name + " - " + c.data_source?.subjects?.[0]?.data?.label}</span>
          </div>
          <div id={`info_${i}`} className="ht-400 scroll-auto" >
            <TopicList module={c} topics={c.topics || []}/>
            {c.topics && c.topics.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(i)}></p>}
          </div>
        </div>
      </div>
    )}
  )

  if(loading)
    return(<div className="spinner"></div>)
  
  return (
    <div className="m-t-10">
      <ModuleList/>
      <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
      setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      <ReactTooltip place="bottom" />
    </div>  
  )
}

export default CourseFilterList;