import env from '../../../../env';
import axios from 'axios';
let url = `${env.family_tree_backend}education_resources`

class ResourceService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(id, req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  addReview(rating){
    return new Promise((resolve, reject) => {
      axios.post(env.family_tree_backend+'edu_resource_ratings',rating).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new ResourceService();