import React, { useEffect, useState } from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../components/PreLoadSpinner"
import DynamicForm from '../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../constants/FormTemplateList'
import querystringify from "querystringify"
import FormContainer from '../FormBuilder/FormContainer'
import UserContactService from "./UserContactService"

let formMode = null 
let member = {}
let data = {}
let formFn = {} 

function ContactForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    UserContactService.get(params.id).then((res) => {
      if(res.status==200){
        member = res.data.user_contact
        data = member.data || {}
        params.form_template_id = member.form_id

        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function(res){
       member = null
    })
  }
  
  useEffect(() => { 
    member = {} 
    data = {}
    formFn = {
      'form_type':'user_contact', 
    } 
    formMode = 'create-form-submissions'
    data = {} 
    
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (member) => {
    member.form_id = params.form_template_id
    member.form_type_id = params.form_type_id  
    UserContactService.create(member).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (member) => {
    member.form_id = params.form_template_id
    member.form_type_id = params.form_type_id 
    UserContactService.update(member).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/invite/users`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
       <DynamicForm formMode={formMode} formId={params.form_template_id} 
        form={member} data={data} formFn={formFn} onCreate={create} 
        onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
        setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null
  }
  
}

export default ContactForm;