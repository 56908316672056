import React, {useState, useEffect, useContext, Fragment} from "react";
import JourneyUserGroupService from "./JourneyUserGroupService";
import Cs from "../../../services/CommonService"  
import {NotificationTypeList} from "../../../constants/DataSourceList"

let requestParams = {}

const JourneyNotificationType = (props) => {
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(props.item.journey_group_ids || [])
  const [type, setType] = useState(props.item.email_notification_type)
  const [loading, setLoading] = useState(true)

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'journey_category_id':props.journeyCategoryId,
      'journey_profile_id':props.journeyProfileId,
      'groupable_id':props.groupableId,
      'groupable_type':props.groupableType
      //'filter_by_user':true
    }

    getJourneyUserGroup()
  }, [])

  let getJourneyUserGroup = () => { 
    setLoading(true)
    JourneyUserGroupService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setGroups(res.data.journey_user_groups) 
        setLoading(false)
      }
    })
  }

  const updateType = (selected_type) =>{
    setType(selected_type)
    if(selected_type == 'course_level'){
      let req = {
        id:props.item.id,
        email_notification_type:selected_type,
      }
      props.updateNotificationItem(req)
    }
  }

  const updateGroup = (group) =>{
    let list = selectedGroup
    let idx = list.indexOf(group.id);
    if(idx > -1) {
      list = list.filter(i => i !== group.id)
    }else {
      list.push(group.id)
    }

    setSelectedGroup([...list])

    let req = {
      id:props.item.id,
      email_notification_type:type,
      journey_group_ids:list
    }

    props.updateNotificationItem(req)    
  }

  const groupList = groups.map((g, k) =>
    <div className="m-l-20 d-flex" onClick={e => updateGroup(g)} key={k}>
      <input type="checkbox" className="ht-wt-30" checked={selectedGroup.includes(g.id)} readOnly/>
      <span className="m-l-5 m-t-5">
        {g.name}
      </span>
    </div>
  )

  const typeList = NotificationTypeList.map((g, k) =>
    <div className="d-flex p-10" onClick={e => updateType(g.sid)} key={k}>
      <input type="checkbox" className="ht-wt-30" checked={type == g.sid} readOnly/>
      <span className="m-l-5 m-t-8">
        {g.label}
      </span>
    </div>
  )

  return (
    <Fragment>
      {loading && <div className="spinner"/>}
      
      <div className="m-5">
        {typeList}
        {type == 'group_level' && groupList}
      </div>
    </Fragment>
  )

}

export default JourneyNotificationType;