import React, {Fragment, useState, useEffect} from "react"
import moment from '../../../node_modules/moment/min/moment.min.js'
import Cs from '../../services/CommonService'
import InterventionProtocolService from "./InterventionProtocolService"
import {PillList} from "../Journey/UserRoles/NotificationPill"
import {EnrichCard} from "../Journey/HighSchoolJourney/Resources/List/EnrichCard"
import {PlannerCard} from "../Journey/HighSchoolJourney/Planners/List/PlannerCard"
import useModal from "../../hooks/useModal"
import GenericModal from "../Modals/GenericModal"

const HSProtocolCard = (props) => {
  const [protocols, setProtocols] = useState([])
  const [enrich, setEnrich] = useState()
  const [planner, setPlanner] = useState()
  const [loading, setLoading] = useState(true)

  const { isOpen:isEnrichOpen, toggleModal:toggleEnrichModal } = useModal()
  const { isOpen:isPlannerOpen, toggleModal:togglePlannerModal } = useModal()

  useEffect(() => {
    get()
  }, [])

  const get = () => {
    if(props.journeyCategoryId){
      InterventionProtocolService.getNotifications({
        journey_category_id: props.journeyCategoryId
      }).then((res)=>{
        setProtocols(res.data.intervention_protocols)
        setLoading(false)
      })
    }
  }

  const onEnrichSelect = (enrich) =>{
    setEnrich(enrich)
    toggleEnrichModal()
  }

  const onPlannerSelect = (planner) =>{
    setPlanner(planner)
    togglePlannerModal()
  }

  const Card = ({protocol}) =>{
    return(
      <div className="bg-white border-rounded-10 card-shadow m-b-15 m-t-15">
        <div className="card-content p20">
          <div className="scroll-auto ht-450">
            <div className="flex">
              <h5 className="fw700 f18 text-left">
                {protocol.data.title || protocol.data.record_title} 
              </h5>
            </div>
              
            <div className="m-b-10">
              <p className="f12">since {Cs.formatUpdateDate(protocol.created_at)}</p>
            </div>
          
            <div className="border-dashed-sm p-b-10 m-b-5">
              <div dangerouslySetInnerHTML={{ __html: protocol.data.details}}/>
            </div>

            <div className="border-dashed-sm p-b-10 m-b-5">
              <h5 className="fw500 m-b-5">Enrichment Suggestions</h5>
              <PillList list={protocol.data.enrichments_array} dataKey='name'
              onSelect={onEnrichSelect} 
              style="pill white bg-lred m-r-5"/>
            </div>
            <div className="border-dashed-sm p-b-10 m-b-5">
              <h5 className="fw500 m-b-5">Planner Suggestions</h5>
              <PillList list={protocol.data.planners_array} dataKey='name' 
              onSelect={onPlannerSelect} 
              style="pill white bg-lred m-r-5"/>
            </div>
            <div className="border-dashed-sm p-b-10 m-b-5">
              <h5 className="fw500 m-b-5">News Suggestions</h5>
              <PillList list={protocol.data.cue_news_array} dataKey='name' 
              style="pill white bg-lred m-r-5"/>
            </div>
            <small>{protocol.record_id}-{protocol.form_id}</small>
          </div>
        </div>
      </div>
    )
  }

  const ProtocolCardsView = () => protocols && protocols.map((item, i) => (
    <div className="col-md-6" key={i}>
      <Card protocol={item}/>
    </div>
  ))

  if(isEnrichOpen){
    return <GenericModal component={EnrichCard} 
      isOpen={isEnrichOpen}
      toggleModal={toggleEnrichModal}
      id={enrich.edu_resource_id}/>
  }

  if(isPlannerOpen){
    return <GenericModal component={PlannerCard} 
      isOpen={isPlannerOpen}
      toggleModal={togglePlannerModal}
      id={planner.id}/>
  }

  return (
    loading?
    <div className="spinner"/>
    :
    <div id="ct">
      <div className="clearfix">
        <div className="bg-lwhite">
          <ProtocolCardsView/>
        </div>
      </div>
    </div>
  )
    
}

export {HSProtocolCard};