import React ,{Fragment, useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../../../services/CommonService'
import env from "../../../../env"
import {FormFieldTable, RenderData} from "../../../Journey/UserRoles/NotificationPill"
import { ChatGrayIcon, HamburgerMenuIcon } from "../../../Common/ImageIcons"

const Planner = (props) => {
  let {planner, formFieldsList} = props;
  let {data, data_source} = planner;
  let formFields = formFieldsList[planner.planner_form_id] || [];

  const cardStyle = {borderLeft: '10px solid #00A2FF'}

  const GenericContent = () =>(
    <div className="bg-white border-rounded-10 m-t-10">
      <div className="card-title card-title-gray flex aligncenter p10 p-b-15"  style={cardStyle}>
        <div className="flex aligncenter">
          <a id="head" className="content">
            <div className="w-100">
              <h4 className="fw700 card-heading truncate">{data.title || data.record_title || data.heading || data.faq}</h4>
              <p className="f12">{planner?.user?.name}</p>
              <p className="f12">since {Cs.formatUpdateDate(planner.created_at)}</p>
            </div>
          </a>
        </div>
      </div>
      <div className="card-content p10-20">
        <div className="b-tab-table active scroll-auto ht-250 m-t-5">
          <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
        </div>
      </div>
      <div className="card-footer flex justspacebetween p10-20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">
            <ChatGrayIcon onClick={e=>props.addNotes(e, planner)}/>
          </li>
        </ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, planner)}/>
      </div>
    </div>
  )

  if(!data)return null;

  return (<GenericContent/>)
    
}

export default Planner;