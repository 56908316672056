import CommonService from '../../services/CommonService'
import axios from 'axios'
import env from '../../env'

const PushNotification = () => {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification")
    return 
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === 'granted') {
    console.log("Permission to receive notifications has been granted");
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === 'denied') {
        return
      }
    })
  }

  // When the Service Worker is ready, enable the UI (button),
  // and see if we already have a subscription set up.
  navigator.serviceWorker.ready.then(function(registration) {
    console.log('service worker registered');
    return registration.pushManager.getSubscription();
  }).then(function(subscription) {
    if (subscription) {
      console.log('Already subscribed', subscription.endpoint)
      //sendSubscriptionToApi(subscription)
    } else {
      subscribe()
    }
  })

  // Get the `registration` from service worker and create a new
  // subscription using `registration.pushManager.subscribe`. Then
  // register received new subscription by sending a POST request with
  // the subscription to the server.
  function subscribe() {
    navigator.serviceWorker.ready.then(async function(registration) {
      // Get the server's public key
      const response = await axios(`${env.backend}push_notifications/vapid_public_key`);
      console.log(response)
      // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
      // urlBase64ToUint8Array() is defined 
      const convertedVapidKey = urlBase64ToUint8Array(response.data);

      // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
      // send notifications that don't have a visible effect for the user).
      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey
      })
    }).then(function(subscription) {
      sendSubscriptionToApi(subscription)
      console.log('Subscribed', subscription.endpoint)
    })
  }

  function sendSubscriptionToApi(subscription){
    // Send the subscription details to the server using the Fetch API.
    let req = {
      method: 'post',
      url: `${env.backend}push_notifications/register`,
      data: {'subscription': subscription}
    }

    CommonService.useAxios(req).then((res)=>{
      sendNotification(subscription) 
    }).catch((err)=>{
      console.log(err)
    })
  }

  // Get existing subscription from service worker, unsubscribe
  // (`subscription.unsubscribe()`) and unregister it in the server with
  // a POST request to stop sending push messages to
  // unexisting endpoint.
  function unsubscribe() {
    navigator.serviceWorker.ready.then(function(registration) {
      return registration.pushManager.getSubscription();
    }).then(function(subscription) {
      return subscription.unsubscribe().then(function() {
        console.log('Unsubscribed', subscription.endpoint);
        let req = {
          method: 'delete',
          url: `${env.backend}push_notifications/delete`,
          data: {
            'subscription': subscription,
          }
        }

        CommonService.useAxios(req).then((res)=>{
        
        }).catch((err)=>{
          console.log(err)
        })

      })
    })
  }

  function sendNotification(subscription){
    setTimeout(function() {
      let req = {
        method: 'post',
        url: `${env.backend}push_notifications/send_notification`,
        data: {
          'subscription': subscription,
          'message':'Welcome to CueTree Education'
        }
      }

      CommonService.useAxios(req).then((res)=>{
        
      }).catch((err)=>{
        console.log(err)
      })
    }, 5000)
  }

  function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4)
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')
 
    var rawData = window.atob(base64)
    var outputArray = new Uint8Array(rawData.length)
 
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

}

export default PushNotification;