import React, { Fragment, useState ,useRef, useContext, useEffect} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import moment from "../../../node_modules/moment/min/moment.min.js";
import useStyle from "../../hooks/useStyle";
import PreLoadSpinner from "../../components/PreLoadSpinner";
import PushNotification from "../../components/PushNotification/PushNotification";
import { Link } from "react-router-dom";
import HamBar from "./HamBar";
import OrgAccountInactiveNotice from './OrgAccountInactiveNotice'
import CommonService from "../../services/CommonService";
import CheckAccess from '../Journey/Roles/CheckAccess'

const Login = (props) => {
  useStyle('/css/login.css', true) 
  useStyle('/css/common.css', true)

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  const { state: authState, dispatch } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(Math.random())

  const userInitialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
    errorSign: {
      email: true,
      password: true,
    }
  }
  
  let [user, setUser] = useState(userInitialState)

  useEffect(() => {
    if (authState.isAuthenticated) {
      routeUser(authState.user)
    }
    invitationLinkVisited()
  }, [authState.isAuthenticated])
  
  const handleInputChange = (event) => {
    const {name, value} = event.target
    user.errorSign[name] = value.length === 0
    setUser({
      ...user,
      [name]: value
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setUser({
      ...user,
      isSubmitting: true,
      errorMessage: null,
    })
    
    let req = {
      user: {
        email: user.email,
        password: user.password,
        organization_use_case: "family_journey",
        invitation_id: queryParam.invite_id || null,
        user_agent: authState.userAgent,
        remember_me: user.isChecked,
      },
    }
   
    AuthenticationService.signin(req).then((res) => {
      if (res.status == 201) {          
        AuthenticationService.setPartnership(res.data.user)
          dispatch({
            type: "LOGIN",
            payload: res.data,
          })
          if (authState.userAgent != "Safari") PushNotification();
          setLoading(false)
      }else {
        setLoading(false)
        setUser({
          ...user,
          isSubmitting: false,
          errorMessage: res.data.error || res.statusText,
        })
      }
    }).catch((error) => {
      setLoading(false)
      setUser({ ...user, errorMessage: "There is no network connectivity" })
    })
  }

  const routeUser = (user) => {
    CheckAccess.routeUser(user, props.history)
  }

  const checkError = () => {
    for (const property in user.errorSign) {
      if (user.errorSign[property] === true) return false
    }
    return true
  }

  const invitationLinkVisited = () => {
    if (queryParam.share_type == "ribbon") {
      if (queryParam.invite_id && queryParam.invite_id.length > 0) {
        AuthenticationService.inviteRibbonLinkVisited(
          queryParam.invite_id
        ).then(function (response) {
          if (response.status == 204) {}
        })
      }
    }
  }

  if(loading){
    return(<PreLoadSpinner/>)
  }

  if(user.errorMessage == 'organization_not_verified'){
    return(<OrgAccountInactiveNotice/>)
  }

  return (
    <Fragment>
    <HamBar className="hidden"/>
    <div id="ct">
      <section className="login-page flex justspacebetween">
        <form className="login-left wid_50 pos_relative flex aligncenter">
          <div className="login-form">
            <h2 className="title m-b-10">Welcome</h2>
            <p className="text-lite-gray m-b-30">
              Enter your sign in details below
            </p>
            <div className="form-fields flex coldir m-b-20">
              <label className="form-label m-b-10">Email</label>
              <input
                type="email"
                className="form-input fulwid"
                placeholder="Enter your email"
                name="email"
                onChange={handleInputChange}
                value={user.email}
                required
              />
            </div>
            <div className="form-fields flex coldir">
              <label className="form-label m-b-10">Password</label>
              <input type="password" className="form-input"
                placeholder="password"
                name="password"
                onChange={handleInputChange}
                value={user.password}
                required />
            </div>
            
            {user.errorMessage && (
              <p className="m-t-5 error">{user.errorMessage}</p>
            )}
            <Link to="/forget_password" className="tright m-t-5 m-b-15 text-link">Forgot password</Link>
            <button
              onClick={handleFormSubmit}
              disabled={checkError() ? false : true}
              className="btn btn-primary fulwid f16 fw500 tcenter text-white">
              Sign in
            </button>
            <div className="m-t-50">
              <p>
                Don’t have an account?{" "}
                <Link to="/signup" className="text-link">
                  <strong>Create an account </strong>
                </Link>
              </p>
            </div>
          </div>
        </form>
        <div className="login-right tcenter justifycenter wid_50 pos_relative flex aligncenter">
          <div className="login-content">
            <a href="#" className="m-b-20">
              <img className="w-115" src="images/logo/cuetree_Icon.png" alt="" />
            </a>
            <h3 className="fw500 m-b-15">Transform education journeys</h3>
            <p className="text-lite-gray m-b-20 wid_80 w-80 wid_100_xs mx-auto">
               {CommonService.displayRandomPhrase(Math.floor(4*count))}
            </p>
            <div className="badge badge-md badge-content m-b-20 mx-auto fw500">
              Improving learning experiences by augmenting and enriching connections
            </div>
            <div className="m-t-10 mx-auto">
              <ul className="flex gap-5 justifycenter">
                <li>
                  <a href="#">
                    <img src="images/fb-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/instagram-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/twitter-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/linked-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/whatsapp-icon.png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex justspacebetween m-t-50">
              <p className="text-lite-gray f12">{new Date().getFullYear()} IPIPAL INC. All Rights Reserved.</p>
              <p className="f12">
                <Link to="/about_us/terms_of_service" className="text-link">
                  <strong>Terms of Service</strong>
                </Link>
                {" "}|{" "}
                <Link to="/about_us/privacy_policy" className="text-link">
                  <strong>Privacy policy </strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    </Fragment>
  )
}

export default Login;