import React, {useContext} from "react";
import { Link, useHistory } from "react-router-dom";
import Cs from '../../../services/CommonService';
import env from "../../../env";
import CheckAccess from "../Roles/CheckAccess"

export const TeamJourney = (props) => {
  let {journey, users, currentUser, cardStyle} = props;
  let data = journey.education_journey_profiles.data;
  const history = useHistory();
  
  const route = () =>{
    if(!CheckAccess.isDevUser(currentUser.email, env) && !journey.is_demo_journey && !journey.subscription_user_id){
      history.push({
        pathname:`/plans/${journey.id}`+(journey.journey_member_id?`/${journey.journey_member_id}`:''),
        search:'?journey_type='+(data?.fj_type_edu_jou)
      })
    }else{
      history.push(`/highschool/project/portal/${journey.id}`, journey)
    }
  }

  return (
    <div className="content-boxed shadow-small" style={cardStyle}>
      <div className="content vcard-header">
        <ul className="d-flex">
          <li>
            <img className="rounded-circle bg-highlight" src={env.file_url + users[journey.created_by].profile_image} width="50px"/>
          </li>
          <li className="m-l-15" onClick={e=>Cs.routeJourney(journey, {history:history}, false, currentUser)}>
            <span className="black-light-1" onClick={e=>route()}>
              <div className="vcard-title ellipsis">{data.fj_name_of_family_member}</div>
              <span className="lred">since {journey.updated_at}</span>
              <span> by {users[journey.created_by].name}</span>
            </span>
          </li>
        </ul>
      </div>
      <div className="content ht-227">
        {currentUser.id != journey.created_by && <small className="error">{users[journey.created_by].name} Shared Journey</small>}
        <div className="vcard-field">
         {data.school_name}
        </div> 
        <div className="vcard-field">
          Grade {data.fj_edu_current_grade}
        </div>  
      </div> 
        <ul className="vard-footer color-highlight">
          <li className="pull-left m-l-15"><props.details ja={journey} /></li>
          <li className="pull-right m-r-15"><props.menu ja={journey}/></li>
        </ul>
    </div>
  );
  
};

export default TeamJourney;