import React, { Fragment, useEffect, useState, useContext } from "react"
import useStyle from "../../../hooks/useStyle"
import Footer from "../../Footer/Footer"
import SideMenuBar from "../../../components/Common/SideMenuBar"
import HamBar from "./../../../components/Authentication/HamBar"
import NewsService from "../../../components/News/NewsService"
import DailyDabbleService from "../../../components/DailyDabbles/DailyDabbleService"
import SocialShareBtn from "../../../components/Common/SocialShareBtn"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import { Link } from "react-router-dom"
import FormTemplateId from "../../../constants/FormTemplateList"
import { useHistory } from "react-router-dom"
import Logo from "./../../../components/Authentication/Logo"
import NewsletterSnippet from "../../Authentication/NewsletterSnippet"
import FAQList from "../../MarketingSnippets/FAQList"
import moment from "moment"
import HeaderSnippet from "./HeaderSnippet"

let requestParamsCueNews = {}

const DailyDabbleQuote = (props) => {
  useStyle("daily_dabble")
  useStyle("spmarketing")

  let params = props.match.params

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  const [cueNewsList, setCueNewsList] = useState([])
  const [previousDabble, setPreviousDabble] = useState()
  const [previousDabbleResponses, setPreviousDabbleResponses] = useState()
  const history = useHistory()

  useEffect(() => {
    requestParamsCueNews = {
      page: 1,
      paginate: 1,
      per_page: 15,
      sort_column: "date_published_article_formatted_date",
      sort_direction: "desc",
    }

    getCueNews()
    getPreviousDabble()
  }, [])

  let getCueNews = () => {
    NewsService.getAll(requestParamsCueNews).then((res) => {
      if (res.status == 200) {
        setCueNewsList(res.data.cue_newses)
      }
    })
  }

  let getPreviousDabble = () => {
    DailyDabbleService.getPreviousDabble({
      daily_dabble_id: params.daily_dabble_id,
    }).then((res) => {
      if (res.status == 200) {
        setPreviousDabble(res.data.daily_dabble)
        setPreviousDabbleResponses(res.data.daily_dabble_responses)
      }
    })
  }

  const routeChange = (path) => {
    history.push(path)
  }

  const handleClick = (id) => {
    console.log("scroll")
    //document.getElementById('info').scrollTop -= 10;
    document.getElementById("cue_" + id).scrollTop += 10
  }

  const newsView = cueNewsList.map((n, k) => (
    <Fragment key={k}>
      <div className="d-flex pointer p-10">
        <div className="d-in-grid w-100p">
          <a href={n.data.news_url} target="_blank">
            <h5 className="d-title  black">{n.data.title_news_item}</h5>
            <p className="d-description ">{n.data.prompt_user}</p>
          </a>
          <p className="d-description lred">{n.data.date_published_article}</p>
        </div>
        <div className="ml-auto"></div>
      </div>
      <div className="divider m-b-0"></div>
    </Fragment>
  ))

  const previousDabbleResponsesView = previousDabbleResponses?.map((r, k) => (
    <div className="content br-grey p-t-0" key={k}>
      <div className="text-dabble m-10 ht-100 font-16"
        style={{
          width: "98%",
          padding: "12px 20px",
          boxSizing: " border-box",
          border: "0.5px solid #fff",
          borderRadius: "4px",
          backgroundColor: "#f8f8f8",
        }}
      >
        {r.data?.response}
      </div>
    </div>
  ))

  return (
    <Fragment>
      <div className="page-content row m-b-30 m-t-10">
        <div className="header bg-highlight ht-30"></div>
        <div className="bg-white row m-b-10">
          <div className="col-sm-12">
            <div className="p-20 ht-150">
              <HeaderSnippet />
            </div>
          </div>
          <div className="col-sm-8">
            <div className="col-sm-12">
              <div className="col-xs-12">
                <div className="bg-black m-0-15 white p-10 font-16">
                  <label>
                    Daily Cue Digest
                    {" for " +
                      moment(previousDabble?.data?.dabble_display_date).format(
                        "MMMM D, YYYY"
                      )}
                  </label>
                </div>
                <div className="content  p-t-0 br-grey">
                  <div
                    className="text-dabble m-10 ht-100 font-18"
                    style={{
                      width: "98%",
                      padding: "12px 20px",
                      boxSizing: " border-box",
                      border: "0.5px solid #fff",
                      borderRadius: "4px",
                      backgroundColor: "#f8f8f8",
                    }}
                  >
                    {previousDabble?.data?.record_title}
                  </div>

                  <div className="text-dabble m-10 ht-100 font-16"
                    style={{
                      width: "98%",
                      padding: "12px 20px",
                      boxSizing: " border-box",
                      border: "0.5px solid #fff",
                      borderRadius: "4px",
                      backgroundColor: "#f8f8f8",
                    }}
                  >
                    {previousDabble?.data?.notes}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="col-xs-12 m-b-30">
                {previousDabbleResponsesView}
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="col-xs-12 content m-b-30 w-90p br-grey m-l-20">
              <div className="d-content m-b-0">
                <Link to="/news" className="head-boder br-btm-grey">
                  <h3 className="p-10 ">
                    <i className="fas fa-globe m-r-10 font-24 bg-black white p-7" />
                    News
                  </h3>
                </Link>
                <div className="scroll-auto" id="cue_1" style={{height: "580px"}}>
                  {newsView}
                </div>
              </div>
              <p className="suggest-more pull-right text-right highlight" onClick={(e) => handleClick(1)}>
                View More
              </p>
              <br/>
              <Link to={`/news/create/${FormTemplateId.NewsUserRegistration}`}
                className="suggest-more pull-right highlight m-t-10 m-b-10">
                Suggest More
              </Link>
            </div>
          </div>
        </div>
        
        <main>
          {!currentUser && <NewsletterSnippet />}
        </main>
        
        <section>
          <FAQList web_page="1"/>
        </section>
        <footer></footer>
      </div>

      <SideMenuBar helpId="2"/>
      <Footer />
    </Fragment>
  )
}

export default DailyDabbleQuote