import React, { Fragment, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CheckAccess from "../../../Roles/CheckAccess";
import useStyle from '../../../../../hooks/useStyle';

const HrTimeline = (props) => {
  useStyle('hrtimeline');

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const cue_list = props.progressList

  let timeline =[]
  cue_list && timeline.push(
    <li className="timeline-item" key="first">
      <div className="timeline-badge">
        <span className="fa-stack fa-1x">
          <i className={`fas fa-circle fa-stack-2x black`}/>
          <i className={`fa-stack-1x fa fas fa-power-off white`} data-tip="Start"/>
        </span>                
      </div>
    </li>
  )

  const cue_item = cue_list && cue_list.map((p, i) => {
    let icon = p.data.status === "problem"?'fa far fa-thumbs-down white':'fa far fa-thumbs-up white'
    
    timeline.push(
      <li className="timeline-item" key={i}>
        <div className="timeline-badge" onClick={(e)=>props.setSelectedStepId(p.education_activity_id)}>
          <span className="fa-stack fa-1x">
            <i className={`fas fa-circle fa-stack-2x grey`}/>
            <i className={`fa-stack-1x ${icon}`} data-tip={p.data.action}/>
          </span>
        </div>
      </li>
    )
  })

  cue_list && timeline.push(
    <li className="timeline-item" key="last">
      <div className="timeline-badge">
        <span className="fa-stack fa-1x">
          <i className={`fas fa-circle fa-stack-2x green`}/>
          <i className={`fa-stack-1x fa fas fa-check white`} data-tip="Finish"/>
        </span>
      </div>
    </li>
  )

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-12">
          <div style={{display:'inline-block',width:'100%',overflowY:'auto'}}>
            <ul className="timeline timeline-horizontal">
              {timeline}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HrTimeline;
