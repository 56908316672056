import React ,{Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommonService from '../../services/CommonService'
import env from '../../../src/env'
import useStyle from '../../hooks/useStyle'

const Notification = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  let {notification, onDelete} = props
  let profileImage = 'images/avatars/2s.png'
  notification.updated_at = CommonService.formatUpdateDate(notification.updated_at)
  if(notification.sender && notification.sender.profile_image_url){
    profileImage = env.file_url+notification.sender.profile_image_url
  }

  const Title = () =>{
    if(notification.notifiable_type == 'invited_journey_member'){
      return(
        <h5 className="d-title ellipsis">{notification.journey_profile_name} - Shared Journey</h5>
      )
    }
    else{
      return(
        <h5 className="d-title ellipsis">{notification.journey_profile_name} - {notification.email_data && notification.email_data.type}</h5>  
      )
    }
  }

  return(
    <Fragment> 
      <div className="flex justspacebetween alignsflextart m-b-20 border-dashed-sm p-b-20 notification-card p10">
        <div className="flex alignsflextart" onClick={e=>props.routeNotification(notification, e)}>
          <span className="flex aligncenter user_dropdown-xs m-r-15">
            <span className="user_dropdown flex"></span>
          </span>
          <div className="flex coldir">
            <h5 className="f18 fw500 m-b-5">
              {notification.journey_profile_name}
              <span className="text-gray f14 m-l-10">{notification.updated_at}</span>
            </h5>
            <p className="m-b-5">
              {notification.message}
            </p>
            <p>{notification.sender_name} at {notification.updated_at}</p>
          </div>
        </div>
        <span onClick={e=>props.onDelete(e, notification)}
          className="table-icon table-icon-gray flex aligncenter justifycenter">
            <img src="images/delete-icon-black.png" alt="" />
        </span>
      </div>
    </Fragment>
  )
}

export default Notification;