import env from '../../env'
import axios from 'axios'
import BaseService from "../../services/BaseService"
class FeedbackService extends BaseService {
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.backend}feedbacks`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'feedbacks', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}feedbacks/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.backend}feedbacks/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }*/

}

export default new FeedbackService('backend', 'feedbacks');
