import env from '../../../env';
import axios from 'axios';
const url = `${env.family_tree_backend}roles`;

class RoleService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(role){
    return new Promise((resolve, reject) => {
      axios.post(url,role).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(role) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${role.id}`, role).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new RoleService();