import React, {useState, Fragment, useEffect,useContext,useRef} from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import JourneyActivity from '../../Activities/List/JourneyActivity';
import TeamActivityService from "../../Activities/TeamActivityService";
import FavouritesService from "../../../../../services/FavouritesService";
import Pagination from '../../../../Pagination'
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from '../../../../../constants/FormTemplateList';
import CommentsModal from "../../../../Modals/CommentsModal";
import CardMenu from "../../../../Modals/CardMenu"
import {Link} from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import useStyle from '../../../../../hooks/useStyle';
import CheckAccess from '../../../Roles/CheckAccess';
import FilterModal from "../../Activities/List/ActivityTypeMenu";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import CalendarModal from "../../../../Modals/CalendarModal"
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal"
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from '../../../../Common/arrayMove';
import HelpMessageList from '../../../../../constants/HelpMessageList'
import {NavIcon,CardMenuInfo,MenuItem,CardMainMenu} from '../../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import CommonService from "../../../../../services/CommonService";
import {TeamLink} from "../../../../Common/RouteLinks"

let requestParams = {}
let selectedActivity = {};
let data_source = {};
let journey_category = null;
let cardViewCount = 1;

let step_activity_form = {
  'team_book':{'form_id':260, 'type':'step_activity'},
  'team':{'form_id':232, 'type':'team_step_activity'},
  'sponsor':{'form_id':305, 'type':'sponsor_step_activity'}
}

const JourneyActivityList = (props) => {
  useStyle('card');
  useStyle('card_table');
  useStyle('add_role_dialog');

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = "col-xs-12";
  let cardHeightCss = "ht-400";
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8";
    cardHeightCss = "mih-380";
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [journeyActivityList, setJourneyActivityList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1); 
  const [loading, setLoading] = useState(true); 
  const [isFavourite, setIsFavourite] = useState(false); 
  const [pagemeta, setPagemeta] = useState([]);
  const [share, setShare] = useState()

  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCalendarOpen, toggleModal:toggleCalendarModal } = useModal()
  const { isOpen:isFileLinkOpen, toggleModal:toggleFileLinkModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const indexEle = useRef(null);

  useEffect(() => {
    journey_category = null
    requestParams = {
	    'is_favourites':isFavourite,
      'data_source_params':{"data_source_id":[3196,3375],"response_type":"object"},
      'education_journey_profile_id':params.education_journey_profile_id,
      'filter_by_role':false,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'paginate':1,
      'per_page':10,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'id':queryParam.id
    }

    getJourneyActivityList()

    return () => {
     requestParams = {} 
    }
  }, [props.searchString, currentpage, isFavourite, props.location]);

  let getJourneyActivityList = () => { 
    setLoading(true)
    TeamActivityService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        data_source = res.data.meta.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        journey_category = res.data.meta.journey_category;
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount);
        setShare(res.data.meta.invited_journey_member)
        CheckAccess.userAccess(currentUser, journey_category, res.data.meta.invited_journey_member);
        
        if(requestParams.search){
          setJourneyActivityList(res.data.activities)
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setJourneyActivityList(res.data.activities);
        }else{
          setJourneyActivityList([...journeyActivityList, ...res.data.activities]);  
        }

        setLoading(false)
        res.data.activities.length == 0 && !requestParams.search && toggleHelpMessage()
      }
    })
  }

  const pinFavourite = (e) =>{
    e.preventDefault()
	  setIsFavourite(!isFavourite)
  }

  const openFileLinks = (e) =>{
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const addNotes = (e, a) => {
    selectedActivity = a
    toggleCommentModal(e)
  }

  const addChecklist = (e, a) => {
    selectedActivity = a
    toggleChecklistModal(e)
  }

  const addReminderdate = (e, a) => {
    selectedActivity = a
    toggleCalendarModal(e)
  }

  const deleteActivity = (e) => {
    e.preventDefault()
    TeamActivityService.delete(selectedActivity).then((res)=>{
      if(res.status==204){
        setJourneyActivityList(journeyActivityList.filter(i => i.id !== selectedActivity.id))
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedActivity = a
    toggleMenuModal(e)
  }

  const routeActivity = (activity) =>{
    props.history.push(`/team/activity/${params.journey_category_id}/${params.education_journey_profile_id}?id=${activity.id}`)
    setJourneyActivityList(journeyActivityList.filter(i => i.id == activity.id))
  }

  const copyToPlanner = (e, activity) => {
    e.preventDefault()
    TeamActivityService.copyToPlanner(activity.id).then((res)=>{
      if(res.status==200){
        
      }
    })
  }

  const menuItems = ({item}) =>{
    const form_template_id = step_activity_form[item.activity_type].form_id;
    const activity_type = step_activity_form[item.activity_type].type;
    const title = item.data && item.data.name.replace(/\s/g, '_')
    const editAccess = CheckAccess.hasEditAccess(currentUser, journey_category, item, share);

    return(
      <Fragment>
        <CardMenuInfo formId={item.activity_form_id} recordId={item.record_id} 
          createdAt={item.created_at} />

        { editAccess && 
          <MenuItem id="edit_icon" 
            link={`/team/activity/form/${item.journey_category_id}/${item.education_journey_profile_id}/${item.activity_form_id}/${item.activity_type}/${item.id}?edit=${editAccess}`}
            icon="far fa-edit"
            action="Edit"
            />
        }
        
        {CheckAccess.hasDeleteAccess(currentUser, journey_category, item) && 
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDeleteModal(e)}}
          />
        }

        {CheckAccess.hasStepActivityAccess(currentUser, journey_category, item, share) &&
          <MenuItem id="activity_icon" 
            link={`/team/step_activity/form/${item.journey_category_id}/${item.education_journey_profile_id}/${item.id}/${form_template_id}/${activity_type}`}
            icon="far fa-sticky-note"
            action="Add Activity"
          />
        }
        
        {CheckAccess.hasRoleAccess(currentUser, journey_category, item) &&
        <Link to={{pathname:`/journey/user_role/${item.journey_category_id}/${item.education_journey_profile_id}/edu_team_journey.activity/${item.id}`, search:`?title=${item && item.data.name}`, state:{journey:props.location.state, activity:item} }}>
          <i className="far fa-eye-slash text-muted font-15" aria-hidden="true"></i>
          <span className="font-13">Permissions <i className={`fas ${item.user_roles == null ? 'fa-times m-l-120 m-t-n2 lred' : 'fa-check m-l-120 m-t-n2 green'} `}></i></span>
          <i className="fa fa-angle-right"></i>
        </Link>}

        <Link to={{pathname:`/journey/users/${item.journey_category_id}/${item.education_journey_profile_id}/edu_team_journey.activity/${item.id}`, state:{journey:props.location.state, activity:item} }}>
          <i className="fa fa-users font-15" aria-hidden="true"></i>
          <span className="font-13">Team - ({item.user_roles_count})</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        {CheckAccess.hasEditAccess(currentUser, journey_category, item) && 
        <a href="#" onClick={e=>copyToPlanner(e, item)}> 
          <i className="far fa-clone font-15" aria-hidden="true"></i>
          <span className="font-13">Copy to Planner</span>
          <i className="fa fa-angle-right"></i>
        </a>}

        <a href={`mailto:${title}-TA-${item.record_id}@cuetree.com`} target="_blank"> 
          <i className="far fa-envelope font-15" aria-hidden="true"></i>
          <span className="font-13">{`${title}-TA-${item.record_id}@cuetree.com`}</span>
          <i className="fa fa-angle-right"></i>
        </a>

      </Fragment>
    )
  }

  const Menu = ({ activity}) => (
    <CardMainMenu activity= {activity} showMenu={showMenu} />
  )

  const journeyActivityCard = journeyActivityList && journeyActivityList.map((a, i) => (
    // <SwiperSlide key={i}>
      <div className={cardGridCss} key={i}>
      <JourneyActivity activity={a} addNotes={addNotes} addChecklist={addChecklist} 
        dataSource={data_source} menu={Menu}  journeyCategory={journey_category} 
        cardHeightCss={cardHeightCss} routeActivity={routeActivity} addReminderdate={addReminderdate} 
        share={share} index={i}/>
        {selectedActivity.id == a.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedActivity} type="activity" MenuItems={menuItems} menuHeight="365px"/>
        }
      </div>
    // </SwiperSlide>
  ))

  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }

  if(queryParam.id && !loading && journeyActivityList.length == 0){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Not Found" message="Sorry, this message was deleted by the author"/>)
  }
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <JourneyTitle title={"Journey Activities"} project={journey_category} setHelpId={props.setHelpId}/>
            </div>

            <div className="flex aligncenter">
              {CheckAccess.hasTeamActivityAccess(journey_category, share) &&     
                <NavIcon id="add_new_activity" dataTip="Create New Activity" 
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  onSelect={toggleFilterModal} icon="fas fa-plus"/>
              }
                
              <NavIcon id="home_icon" dataTip="Back to Journey Home" 
                link={TeamLink.team_portal(params)}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-home"/>

              <NavIcon id="file_links" dataTip="Journey Files and Links" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={openFileLinks} icon="fas fa-link"/>

              {!queryParam.id && <NavIcon id="pin" dataTip="Filter Favourites" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={pinFavourite} icon={"fas fa-thumbtack " + (isFavourite ? 'black' : 'white')}/>}

              {queryParam.id  && 
                <NavIcon id="back_icon" dataTip="Back to Activity" 
                  onSelect={e=>{props.history.goBack()}}
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  icon="fas fa-arrow-left"/>
              }
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              { !queryParam.id && 
                journeyActivityCard
              }
              { queryParam.id &&
                <Fragment>
                  {journeyActivityCard}
                  <div className="col-sm-12 col-sm-6 col-md-4">
                    { journeyActivityList.length > 0 && <CommentsModal isOpen="true" toggleModal={toggleCommentModal} item={journeyActivityList[0]} type="team_activity" showInline="true"/> }
                  </div>
                </Fragment>           
              } 
            </div>
          </div>
        </div>
      </div>
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedActivity} type="team_activity"/>}
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedActivity} type="team_activity"/>}
      {isFilterOpen && <FilterModal data_source={data_source} params={params} isOpen={isFilterOpen} toggleModal={toggleFilterModal} />}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity}/>
      {isCalendarOpen && <CalendarModal toggleModal={toggleCalendarModal} item={selectedActivity} type="edu_team_journey.activity"/>}
      {isFileLinkOpen && <JourneyFilesLinksModal toggleModal={toggleFileLinkModal} journeyCategory={journey_category}/>}
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      )}
      <SideMenuBar helpId="15"/>
    </Fragment>
  )

}

export default JourneyActivityList;

/*
{CheckAccess.hasEditAccess(authState.user, journey_category, item) && 
        <a onClick={e=>{addReminderdate(e, item);toggleMenuModal(e)}}>
          <i className="fas fa-calendar-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Add Reminder</span>
          <i className="fa fa-angle-right"></i>
        </a>}
*/