import React, {Fragment, useState} from "react"

const GeneralRating = ({label, dataKey, ratingData, onSelect, ratingItem}) => {
  ratingData = ratingData || {}
  let [model, setModel] = useState(ratingData[dataKey])

  const toggle = (value) => {
    if(model == value){
      setModel(0)
      ratingData[dataKey] = 0
    }else{
      setModel(value)
      ratingData[dataKey] = value
    }
    
    onSelect(ratingItem, ratingData)
  }

  const FlexRating = () =>{  
    return(
      <div className="rbc-event bg-white m-l-5 miw-95 text-center">
        <span className="font-18">
          <i className={`${1<=model?'fas highlight':'far lborder'} fa-star`} onClick={e => toggle(1)}></i>
          <i className={`${2<=model?'fas highlight':'far lborder'} fa-star`} onClick={e => toggle(2)}></i>
          <i className={`${3<=model?'fas highlight':'far lborder'} fa-star`} onClick={e => toggle(3)}></i>
          <i className={`${4<=model?'fas highlight':'far lborder'} fa-star`} onClick={e => toggle(4)}></i>
          <i className={`${5<=model?'fas highlight':'far lborder'} fa-star`} onClick={e => toggle(5)}></i>
        </span>
      </div>
    )
  }

  return (
    <div className="p-5 m-t-5">
      <FlexRating/>
    </div>
  )

}

export default GeneralRating;