import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import CommonService from '../../../../../services/CommonService'
import FormContainer from '../../../../FormBuilder/FormContainer';

let formMode = null; 
let resource = {}; 
let data = {}; 
let formFn = {};  

function ReviewForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CommonService.getRatingById(params.id).then((res) => {
      if(res.status==200){
        resource = res.data.rating;
        data = resource.data || {};
        setFormRendered(true);
      }
    }).catch(function(res){
       resource = null;
    })
  }
  
  useEffect(() => { 
    resource = {}; 
    data = {}; 
    formFn = {'form_type':'education_resource'}; 
    formMode = 'create-form-submissions';
    data = {}; 

    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (resource) => {
    resource.created_by = authState.user.id;
    resource.education_journey_profile_id = params.journey_proile_id
    resource.education_resource_id = params.education_resource_id
    resource.journey_category_id  = params.journey_category_id
    resource.rating_type = 'education_resource'
    resource.rating_id = params.education_resource_id
    CommonService.createRating(resource).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (resource) => {
    CommonService.updateRating(resource, params.id).then((res) => {
      if(res.status==204){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/highschool/resources/${params.journey_category_id}/${params.journey_proile_id}`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.ReviewForm} form={resource} data={data} 
          formFn={formFn} onCreate={create}  onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default ReviewForm;