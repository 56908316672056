import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../../hooks/useStyle';
import useSort from '../../../../../hooks/useSorting';
import SortDirection from '../../../../../components/Common/SortDirection'
import {PermissionList, NotificationList} from './../../../UserRoles/NotificationPill'
import { Table, THead, TH, TR, TD, TBody } from "../../../../Common/TableView"

const TrainingModuleListTable = (props) => {
  useStyle('table');  
  const [currentTopic, setCurrentTopic] = useState();
  const {sort} = useSort(props.requestParams, props.getRoleList);

  const toggleTopic = (id) => {
    if(id === currentTopic){
      setCurrentTopic()
    }else{
      setCurrentTopic(id)
    }
  };

  const TopicData = ({module}) => module.topics && module.topics.map((n,i) =>
    <Fragment>
      <span className="p-5 round-tab" key={i}>{i+1}) {n.data.name}&emsp;</span><br/>
    </Fragment>
  )

  const tableData = props.modules.map((module, k) =>{
    return(
      <TR key={k}>
        <TD data-title="Id">{k+1}</TD>
        <TD data-title="Module">
          {module.data.name}
        </TD>
        <TD data-title="Permissions">
          <div>
            {module.topics && module.topics.length || 0} Topics
            {module.id == currentTopic ? <i className="m-l-5 m-t-5 fa fa-chevron-up"  onClick={e=>toggleTopic(module.id)}aria-hidden="true"></i>:<i className="m-l-5 m-t-5 fa fa-chevron-down"  onClick={e=>toggleTopic(module.id)} aria-hidden="true"></i>}
            <div>
              { module.id === currentTopic ? <TopicData module={module} /> :
                <div>
                </div>
              }
            </div>
          </div>
        </TD>
      </TR>
    )
  })

  return (
    <div className="col-xs-12 m-t-10">
      <Table className="shadow-small">
        <THead>
          <TR>
            <TH>Id 
            </TH>
            <TH>Module 
            </TH>
            <TH>Topics
            </TH>
          </TR>
        </THead>
        <TBody>
          {tableData}
        </TBody>
      </Table>
    </div>
  )

};

export default TrainingModuleListTable;