import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const Logo = (props) => {
  const { state: currentUser, isAuthenticated: isAuthenticated } = useContext(AuthContext)

  return (
    <Fragment>
      <div className="logo-container d-table-cell">
        <Link to={isAuthenticated?'/home':'/signin'}>
          <img className="pull-left m-t-10 ht-90" 
            src={"./../../../images/cueTree_logo.png"} 
            alt="logo-img"/>
        </Link>
        <Link>
          <div className="pull-left text-center black p-5-0">For your life journeys</div>
        </Link>
      </div>
    </Fragment>
  )
}

export default Logo;
