import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../PreLoadSpinner"
import DynamicForm from '../FormBuilder/DynamicForm'
import FormTemplateId from '../../constants/FormTemplateList'
import DailyDabbleService from "./DailyDabbleService"
import querystringify from "querystringify"
import FormContainer from '../FormBuilder/FormContainer'
import FormWizard from '../FormBuilder/FormWizard'

let formMode = null 
let dailyDabble = {} 
let data = {} 
let formFn = {}  
let formId = null

function DailyDabbleForm(props) {
  const { state:{ user:currentUser } } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const get = () => {
    DailyDabbleService.get(params.id).then((res) => {
      if(res.status==200){
        dailyDabble = res.data.daily_dabble || {}
        data = dailyDabble.data || {}
        setFormRendered(true)
      }
    }).catch((res)=>{
       dailyDabble = null
    })
  }
  
  useEffect(() => { 
    dailyDabble = {} 
    data = {} 
    formFn = {'form_type':'daily_dabble'} 
    formId = queryParam.is_user_suggestion?FormTemplateId.DailyDabbleUserSuggestion:FormTemplateId.DailyDabble
    formMode = 'create-form-submissions'
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (daily_dabble) => {
    daily_dabble.dabble_form_id = formId
    daily_dabble.dabble_type = params.dabble_type
    daily_dabble.is_active = queryParam.is_user_suggestion?false:true
    DailyDabbleService.create(daily_dabble).then((res) => {
      if(res.status == 201){
        onSubmitPageRedirect()
      }else if(res.status == 422){
        alert(res.data.error)
      }
    }).catch((res)=>{
       
    })
  }

  const update = (daily_dabble) => {
    DailyDabbleService.update(daily_dabble).then((res) => {
      if(res.status == 204){
        onSubmitPageRedirect()
      }
    }).catch((res)=>{
       
    })
  }

  const onSubmitPageRedirect = () => {
    if(!currentUser){
      props.history.goBack()
    }else{
      props.history.push(`/list/daily_dabble`)
    }
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id){
    return(
      <FormWizard form_wizard_id={queryParam.form_wizard_id} form={dailyDabble} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}/>
    )
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={formId} 
          form={dailyDabble} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default DailyDabbleForm;