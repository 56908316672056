import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../../services/CommonService';
import useStyle from '../../../hooks/useStyle';
import Pagination from '../../Pagination'

let requestParams = {};
const JourneyFileUploads = ({search, journeyCategory, journeyType}) => {
  useStyle('chat');
  
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [filesList, setFilesList] = useState([]);

  useEffect(() => {
    requestParams = {
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'user_id':currentUser.id,
      'page':1,
      'per_page':15,
      'search':search,
      'journey_category_id':journeyCategory.id,
      'edu_journey_type':journeyType
    }
    getFileUploads();
  }, [search]);
    
  //currentpage
  let getFileUploads = (page_no) => {
    requestParams.page = page_no || 1;
    CommonService.getFileUploads(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setFilesList(res.data.file_uploads);
      }
    })
  }

  const FileItemList = ({list, item}) => list && list.map((c, k) =>
    <File item={item} url={c} key={k}/>  
  )

  const File = ({item, url}) => (
    <div className="col-xs-12 col-sm-4 p-5 wk-center">
      <a target="_blank" href={CommonService.getFileUrl(url)}>
        <img src={CommonService.getIconByType(url)} width="75px" height="75px"/>
      </a>
      <p><span className="green">{CommonService.getFileName(url)}</span> , <span>{item.email}</span></p>
    </div>
  )

  const fileItem = filesList.map((c, k) =>{
    if(c.urls){
      return <FileItemList item={c} list={c.urls} key={k}/>
    }else{
      return <File item={c} url={c.url} key={k}/>
    }
  })
  
  const FileUplaodBox = () =>(
    <Fragment>
      <div className="chat-main" id="chat-main" style={{height:screen.height-300}}>
        {filesList.length < 0 && <div className="no-chat font-15">Loading... </div>}
        {fileItem}
      </div>
      <Pagination totalItems={requestParams.totalItems} currentpage={requestParams.page} setCurrentpage={getFileUploads} itemsPerPage={requestParams.per_page}/>
    </Fragment>    
  ) 

  return(
    <div className="m-t-15">
      <FileUplaodBox/>
    </div>
  )

}

export default JourneyFileUploads;