import env from '../env';
import axios from 'axios';

class AuthenticationService {
  
  logon(user){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'users.json',user).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  signin(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}users/sign_in`, user).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  signup(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}users.json`, data).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  forgotPassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}users/forgot_password`, user).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  ResetPassword(userData,userId,token) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}users/`+userId+`/password_reset/`+token+``, userData).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(){
    return new Promise((resolve, reject) => {
      var u = env.backend+'user'
      axios.get(u).then(function(response){
       resolve( response.data.user );
      })
    })
  }

  signout(){
    return new Promise((resolve, reject) => {
      var u = env.backend+'users/sign_out'
      axios.delete(u).then(function(response){
        resolve( response.data.user );
      })
    })
  }

  inviteRibbonLinkVisited(id){
    return new Promise((resolve, reject) => {
      var u = env.family_tree_backend+'invited_journey_members/invite_link_visited/'+id
      axios.get(u).then(function(response){
        resolve( response.data );
      })
    })
  };

  inviteCardLinkVisited(id){
    return new Promise((resolve, reject) => {
      var u = env.family_tree_backend+'shared_items/invite_link_visited/'+id
      axios.get(u).then(function(response){
        resolve( response.data );
      })
    })
  };

  updatePassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}users/change_password`, user).then((res) => {
        resolve(res);
      })
    })
  }

  updateUserName(user){
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}user`, user).then((res) => {
        resolve(res);
      })
    }) 
  }

  updateProfileImage(file){
    return new Promise((resolve, reject) => {
      let postImageUploadurl = env.backend+'user_profile_image.json';
      let fd = new FormData();
      fd.append('file', file);
      //fd.append('crop', JSON.stringify(file.crop));
      axios.post(postImageUploadurl, fd, {headers: {'Content-Type': undefined}})
      .then((res)=>{
        try{
          res.data.user.profile_image_url = res.data.user.profile_image_url.split('?')[0] 
          resolve(res);
        }catch(e){
          reject(res);
        }
      })
    }) 
  }

  getAllSecretQuestion(data){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}secret_questions`, {params: data}).then((res) => {
        resolve(res);
      })
    }) 
  }

  createSecretQuestion(data){
    return new Promise((resolve, reject) => {
      axios.post(`${env.family_tree_backend}secret_questions`, data).then((res) => {
        resolve(res);
      })
    }) 
  }

  updateSecretQuestion(data, id){
    return new Promise((resolve, reject) => {
      axios.put(`${env.family_tree_backend}secret_questions/${id}`, data).then((res) => {
        resolve(res);
      })
    }) 
  }

  registrationConfirmation(req){
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}user/registration_confirmation/${req.verification_id}`, req).then((res) => {
        resolve(res);
      })
    }) 
  }

  getPrivacyList(data){
    return new Promise((resolve, reject) => {
      axios.get(`${env.terms_backend}form_submissions`, {params: data}).then((res) => {
        resolve(res);
      })
    }) 
  }

  setPartnership(user){
    try{
      let typeOfUser = user.domain_info.data.type_of_use
      user.domain_info.partnership_org_id = (typeOfUser == 2) && user.domain_info.id
    }catch(e){
      //console.log(e)
    }
  }
}

export default new AuthenticationService();