import env from '../../../../env';
import axios from 'axios';
let url = env.family_tree_backend+'education_journal_entries'

class ActivityService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getCatId(req){
    return new Promise((resolve, reject) => {
      axios.post(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  reports(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}/report`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getActivityByType(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}activity_types`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  clone(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}/clone/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getActivityMenu(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}activity_type_menu`, {params: req}).then((res) => {
        // handle success
        resolve(res.data);
      })
    })
  }
}

export default new ActivityService();