import React, { Fragment, useEffect, useState, useContext } from "react"
import useStyle from "../../../hooks/useStyle"
import Footer from "./../../Footer/Footer"
import SideMenuBar from "../../../components/Common/SideMenuBar"
import HamBar from "./../../../components/Authentication/HamBar"
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService"
import NewsService from "../../../components/News/NewsService"
import CommonService from "../../../services/CommonService"
import DailyDabbleService from "../../../components/DailyDabbles/DailyDabbleService"
import DailyDabbleResponseService from "../../../components/DailyDabbleResponses/DailyDabbleResponseService"
import SocialShareBtn from "../../../components/Common/SocialShareBtn"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import { Link } from "react-router-dom"
import FormTemplateId from "../../../constants/FormTemplateList"
import Logo from "./../../../components/Authentication/Logo"
import NewsletterSnippet from "../../Authentication/NewsletterSnippet"
import MarketingList from "../../MarketingSnippets/MarketingList"
import FAQList from "../../MarketingSnippets/FAQList"
import querystringify from "querystringify"
import moment from "moment"
import HeaderSnippet from "./HeaderSnippet"

let requestParamsCueNews = {}
let requestParamsServiceMarketing = {}
let pageTitle = null

const DailyDabble = (props) => {
  useStyle("daily_dabble")
  useStyle("spmarketing")

  let queryParam = querystringify.parse(props.location.search)

  const {
    state: { screen, user: currentUser },
    dispatch,
  } = useContext(AuthContext)
  const [cueNewsList, setCueNewsList] = useState([])
  const [dailyDabble, setDailyDabble] = useState()
  const [dailyDabbleResponse, setDailyDabbleResponse] = useState()

  useEffect(() => {
    pageTitle = null;
    requestParamsCueNews = {
      page: 1,
      paginate: 1,
      per_page: 15,
      sort_column: "date_published_article_formatted_date",
      sort_direction: "desc",
      data_source_params: {
        data_source_id: [3368],
      },
    }

    getCueNews()
    getDailyDabble()
  }, [])

  let getCueNews = () => {
    NewsService.getAll(requestParamsCueNews).then((res) => {
      if (res.status == 200) {
        //pageTitle = res.data.data_source_list.web_pages.options[queryParam.i]?.page_heading
        setCueNewsList(res.data.cue_newses)
      }
    })
  }

  let getDailyDabble = () => {
    DailyDabbleService.getDailyDabble().then((res) => {
      setDailyDabble(res.data.daily_dabble)
    })
  }

  let createDailyDabbleResponse = () => {
    let req = {
      daily_dabble_id: dailyDabble.id,
      data: {
        response: dailyDabbleResponse,
      },
    }
    DailyDabbleResponseService.create(req).then((res) => {
      setDailyDabbleResponse("")
      props.history.push("/dailyCueQuote")
    })
  }

  const handleClick = (id) => {
    console.log("scroll")
    //document.getElementById('info').scrollTop -= 10;
    document.getElementById("cue_" + id).scrollTop += 10
  }

  const newsView = cueNewsList.map((n, k) => (
    <Fragment key={k}>
      <div className="d-flex pointer p-10">
        <div className="d-in-grid w-100p">
          <a href={n.data.news_url} target="_blank">
            <h5 className="d-title  black">{n.data.title_news_item}</h5>
            <p className="d-description ">{n.data.prompt_user}</p>
          </a>
          <p className="d-description lred">{n.data.date_published_article}</p>
        </div>
        <div className="ml-auto"></div>
      </div>
      <div className="divider m-b-0"></div>
    </Fragment>
  ))

  return (
    <Fragment>
      <div className="page-content row m-b-30">
        <div className="header bg-highlight ht-30"></div>
        <div className="bg-white row m-b-10 m-t-10">
          <div className="col-sm-12">
            <div className="p-20 ht-150">
              <HeaderSnippet />
            </div>
          </div>
          <div className="col-sm-8">
            <div className="col-sm-12">
              <div className="col-xs-12">
                <div className="bg-black m-0-15 white p-10 font-16">
                  <label>
                    {pageTitle || "Daily Cue"}
                    {" for " +
                      moment(dailyDabble?.data?.dabble_display_date).format(
                        "MMMM D, YYYY"
                      )}
                  </label>
                </div>
                <div className="content br-grey p-t-0">
                  <label className="m-0-15 font-20 m-t-10">
                    {dailyDabble?.data?.name}
                  </label>
                  <textarea className="text-dabble m-10 ht-110 font-16"
                    style={{
                      width: "98%",
                      padding: "12px 20px",
                      boxSizing: " border-box",
                      border: "0.5px solid #fff",
                      borderRadius: "4px",
                      backgroundColor: "#f8f8f8",
                    }}
                    placeholder="Add and submit your tip"
                    value={dailyDabbleResponse}
                    onChange={(e) => setDailyDabbleResponse(e.target.value)}
                  ></textarea>
                  <div className="ht-50">
                    {dailyDabbleResponse && (
                      <button
                        className="pull-left m-l-10 text-center white p-10 font-16 bg-green button button-full button-m shadow-large button-round-small"
                        onClick={(e) => createDailyDabbleResponse()}
                      >
                        Submit
                      </button>
                    )}
                    <Link to="/dailyCueQuote" className="highlight m-r-10 pull-right">
                      View previous Daily Cue
                    </Link>
                    <Link to="/new/daily_dabble?is_user_suggestion=true" className="highlight m-r-10 pull-right">
                      Suggest a new Daily Cue
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12"
              style={{
                width: "100%",
                display: "flex",
                whiteSpace: "nowrap",
                overflowX: "scroll",
              }}>
              <MarketingList screen="col-md-4" />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="col-xs-12 content m-b-30 w-90p br-grey m-l-20">
              <div className="d-content m-b-0">
                <Link to="/news" className="head-boder br-btm-grey">
                  <h3 className="p-10 ">
                    <i className="fas fa-globe m-r-10 font-24 bg-black white p-7" />
                    News
                  </h3>
                </Link>
                <div className="scroll-auto" id="cue_1" style={{height: "580px"}}>
                  {newsView}
                </div>
              </div>
              <p className="suggest-more pull-right text-right highlight" onClick={(e) => handleClick(1)}>
                View More
              </p>
              <br/>
              <Link to={`/news/create/${FormTemplateId.NewsUserRegistration}`}
                className="suggest-more pull-right text-right ht-50 highlight">
                Suggest a News Item
              </Link>
            </div>
          </div>
        </div>

        <main>
          {!currentUser &&  <NewsletterSnippet />}
        </main>
        
        <section>
          <FAQList web_page="1" />
        </section>
        <footer></footer>
      </div>

      <SideMenuBar helpId="2" />
      <Footer />
    </Fragment>
  )
}

export default DailyDabble;