import env from '../../env'
import axios from 'axios'
import BaseService from "../../services/BaseService"
class ForumService extends BaseService {
  /*
  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.backend}discussion_requests/${req}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.backend}discussion_requests`, {params: req}).then((res) => {
        // handle success
        console.log('forums get all',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'discussion_requests',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  reply(profile) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}discussion_responses`, profile).then((res) => {
        // handle success
        console.log('reply response',res);
        resolve(res);
      }).catch((e) => {
        console.log('reply error',e);
        // handle error
        reject(e);
      })
    })
  }

  deletePost(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.backend}discussion_requests/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }*/

  reply(profile) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}discussion_responses`, profile).then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        reject(e)
      })
    })
  }

}

export default new ForumService('backend', 'discussion_responses')