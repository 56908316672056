import React, {useState, Fragment, useContext, useEffect} from "react"
import querystringify from "querystringify"
import {Link} from "react-router-dom"
import ReactDOM from 'react-dom'
import Pagination from '../Pagination'
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import ReactTooltip from "react-tooltip"
import {OptionsObjList, Radio} from "../Common/FormInput"
import {Locales} from "../../constants/DataSourceList"
import importScript from '../../hooks/importScript'

const AddLocale = ({defaultLabel, localeType, onCancel, labelObj}) => {
  useStyle('add_role_dialog')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  let defaultLangauge = currentUser.current_locale || 'english'

  let locale = null
  let englishLabel = null
  if(localeType == 'data_source_list'){
    englishLabel = labelObj.data.label
    locale = labelObj.data.locale
  }else if(localeType == 'form_field'){
    englishLabel = labelObj.label
    locale = labelObj.locale
  }

  const editLocale = (d) =>{
    if(localeType=='data_source_list'){
      updateDataSourceList(d)
    }else if(localeType == 'form_field'){
      updateFormField(d)
    }
  }

  const updateDataSourceList = (d) =>{
    labelObj.data.locale = labelObj.data.locale || {}
    labelObj.data.locale[d.locale] = d.value
    Cs.updateDataSourceList(labelObj.id, labelObj).then((res)=>{
      onCancel()
    })
  }

  const updateFormField = (d) =>{
    let req = {
      id: labelObj.formTemplateId,
      client_id: labelObj.client_id,
      language: d.locale,
      value: d.value
    }

    Cs.updateFormFieldLocale(req).then((res)=>{
      onCancel()
    })
  }

  return(
    <Fragment>
      <div className="ml-container m-t-10">
        <LocaleForm onCancel={onCancel} editLocale={editLocale} localeList={locale || {}} 
        englishLabel={englishLabel} defaultLangauge={defaultLangauge}/>
      </div>
    </Fragment>
  )
}

const LocaleForm = ({onCancel, editLocale, localeList, englishLabel, defaultLangauge}) => {
  const [loading, setLoading] = useState(false)
  const [translatedText, setTranslatedText] = useState('')
  const [locale, setLocale] = useState(localeList || {})
  let [data, setData] = useState({locale: defaultLangauge, value:localeList[defaultLangauge]})

  const googleTranslate = (iso_code) =>{
    setLoading(true)
    let req = {
      "source": "en",
      "q": [englishLabel],
      "target": iso_code.target
    }
    
    Cs.googleTranslate(req).then((res)=>{
      setLoading(false)
      setTranslatedText(res.data.translations[0].translatedText)
      setData({...data, value:res.data.translations[0].translatedText})
    })
  } 

  const handleLocale = (e) =>{
    data = {locale: e.target.value, value:(localeList[e.target.value] || '')}
    setData({...data})
    let local = Locales[e.target.selectedIndex-1]
    if(local.iso_code)googleTranslate({"target": local.iso_code});
  }

  const handleInput = (e) =>{
    setData({...data, [e.target.name]:e.target.value})
  }

  return(
    <form>
      {loading && <div className="spinner"></div>}
      <div className="ml-section">
        <div className="col-xs-12 p-10 bg-ltint br-btm-grey ml-margin-bottom">
          <label className="text-cap"><b>Current Text</b></label>
          <p className="font-15">{englishLabel}</p>
        </div>
       
        <div className="col-xs-12 ml-margin-bottom">
          <label className="text-cap"><b>Pick Your Language</b></label>
          <select name="locale" id="locale" className="form-control" onChange={e=>handleLocale(e)} 
            defaultValue={data.locale}>
            <option value="" disabled selected>Select an item from list below</option>
            <OptionsObjList list={Locales} id_key="sid" label_key="label"/>
          </select>
        </div>

        <div className="col-xs-12 ml-margin-bottom">
          <label className="text-cap"><b>Suggested Translation</b></label>
          <p className="font-15">{translatedText}</p>
        </div>
        
        <div className="col-xs-12 ml-margin-bottom">
          {(data.locale) && <Fragment>
            <label className="text-cap"><b>Add Translated Text</b></label>
            <textarea name="value" id="value" className="form-control"
              onChange={e=>handleInput(e)} value={data.value}/>
            </Fragment>
          }
        </div> 
        
        <div className="ml-button ml-block ml-section ml-padding bg-highlight white font-16"
          onClick={e=>editLocale(data)}>
          <b>{data.id?'Update':'Create'}</b>
        </div>
        
        <div className="ml-button ml-block ml-section ml-padding bg-lred white font-16"
          onClick={e=>onCancel(false)}>
            <b>Cancel</b>
        </div>
      </div>
    </form>  
  )
}

export default AddLocale;