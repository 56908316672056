import React ,{useState, Fragment, useContext} from "react";
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import StepAssessmentService from "../StepAssessmentService";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import useModal from "../../../../../hooks/useModal";
import Cs from '../../../../../services/CommonService';
import CheckAccess from '../../../Roles/CheckAccess';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../../../Common/arrayMove';

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt step-drag-handle"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

let selectedStepAssessment = null;
const StepAssessments = (props) => {
  let history = useHistory()    
  let {assessmentList, journeyCategory, assessment, openQuestionModal, share} = props;
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState();
  const [visible, setVisible] = useState(false);
  const [stepAssessments, setStepAssessments] = useState(props.stepAssessments);
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();

  const toggleMenu =(i) =>{
    setActiveIndex(i==activeIndex?null:i)
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      //console.log(stepAssessments[newIndex].data.name)
      nextItemId = stepAssessments[newIndex].id
    }else if(newIndex+1 == stepAssessments.length){
      //console.log(stepAssessments[newIndex].data.name)
      priorItemId = stepAssessments[newIndex].id
    }else if(oldIndex > newIndex){
      //console.log(stepAssessments[newIndex-1].data.name)
      //console.log(stepAssessments[newIndex].data.name)

      priorItemId = stepAssessments[newIndex-1].id
      nextItemId = stepAssessments[newIndex].id
    }else if(oldIndex < newIndex){
      //console.log(stepAssessments[newIndex].data.name)
      //console.log(stepAssessments[newIndex+1].data.name)

      priorItemId = stepAssessments[newIndex].id
      nextItemId = stepAssessments[newIndex+1].id
    }

    function onSuccess(){
      setStepAssessments(arrayMove(stepAssessments, oldIndex, newIndex))
    }
    
    updateSortOrder(stepAssessments[oldIndex].id, priorItemId, nextItemId, onSuccess)
  }

  const updateSortOrder = (id, prior_item_id, next_item_id, success_callback) =>{
    let req = {
      'journey_category_id':journeyCategory.id,
      'update_type': 'sort_order',
      'prior_item_id':prior_item_id,
      'next_item_id':next_item_id,
      'id':id
    }
    StepAssessmentService.updateSortOrder(req).then((res)=>{
      if(res.status == 204){
        success_callback()
      }
    }) 
  }

  const deleteStepAssessment = (e) => {
    e.preventDefault();
    StepAssessmentService.delete(selectedStepAssessment).then((res)=>{
      if(res.status == 204){
        setStepAssessments(stepAssessments.filter(i => i.id !== selectedStepAssessment.id))
      }
    })
    toggleDeleteModal(e);
  }

  const confirmDelete = (e, step_assessment) =>{
    e.preventDefault();
    selectedStepAssessment = step_assessment;
    toggleDeleteModal(e);
  }

  const Menu = ({step_assessment}) => ( 
    <span className="dropup pull-right">
      {CheckAccess.isPublishedItemEditable(currentUser, share, assessment) &&
        <Fragment>
          <a>
            {currentUser.id == step_assessment.created_by &&
              <i className='fas fa-bars'></i>
            }
          </a>
          {currentUser.id == step_assessment.created_by &&
            <div className="dropup-content w-100p">
              <a href="#" onClick={e => openQuestionModal(e, step_assessment)}>
                <i className="far fa-edit"/> Edit
              </a>
      
              <a href="#" onClick={e => confirmDelete(e, step_assessment) }>
                <i className="far fa-trash-alt"/> Delete
              </a>
            </div>
          }
        </Fragment>
      }
    </span>
  )

  const FileList = ({files}) => {
    return(
      <Fragment>
        {files && files.map((file, k) =>  (
          <a key={k} className="m-t-5 inline-files-list" target="_blank" href={Cs.getFileUrl(file)}>
            <img src={Cs.getIconByType(file)} width="35" height="35"/>
            <span className="m-l-5">{Cs.getFileName(file)}</span>
          </a>
        ))}
      </Fragment>
    )
  }

  const FileLink = ({step_assessment, data}) =>{
    if(step_assessment.assessment_type == 'step_assessment') {
      return (<Fragment> 
        {data.file_upload && data.file_upload.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
       </Fragment>  
      )
    }else if(step_assessment.assessment_type == 'team_step_assessment'){
      return (<Fragment> 
        {data.upload_files && data.upload_files.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
       </Fragment>  
      )
    }else{
      return null
    }
  }

  const Card = ({step_assessment}) => {
    return(
      <div className="p-5 font-14">
        <p className="pull-right font-10" style={{border: "1px solid", padding: "2px"}}>
          {step_assessment.created_user.name}-{Cs.formatUpdateDate(step_assessment.created_at)}
        </p>
        <div className="m-t-5">Point - {step_assessment.point}</div>
        <div className="m-t-5">{step_assessment.input_type}</div>
        <p className="lgrey rich-text-img r-img-200" dangerouslySetInnerHTML={{ __html: step_assessment.description }} />
      </div>
    )
  }

  const RenderCard = ({step_assessment}) =>{
    return <Card step_assessment={step_assessment} />
  }

  const SortableItem = sortableElement(({s, i}) => 
    <div>
      <div className="card-activity" onClick={e=>toggleMenu(i)}>
        <DragHandle/>
        <Menu step_assessment={s}/>
        <div className="w-90p" dangerouslySetInnerHTML={{ __html: s.question }}></div>
      </div>
      {activeIndex == i && <RenderCard step_assessment={s}/>}  
    </div>
  )

  const sortData = stepAssessments && stepAssessments.map((s, i) =>
    <SortableItem key={`item-${i}`} index={i} s={s} i={i}/>
  )

  return (
    <Fragment>
      <div className={"p-5" + (props.flag ? 'ht-350 scroll-auto' :'')}>
        {props.flag ? <strong>Questions</strong> :''}
        <SortableContainer onSortEnd={onSortEnd} distance={1} lockAxis="y">
          {sortData}
        </SortableContainer>
      </div>
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteStepAssessment}/>
    </Fragment> 
  )

}

export default StepAssessments;