import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../../../hooks/useStyle";
import CommonService from "../../../../../services/CommonService";
import CollegeActivityService from "../../Activities/CollegeActivityService";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import useModal from "../../../../../hooks/useModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import CommentsModal from "../../../../Modals/CommentsModal";
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal";
import {NavIcon} from '../../../../Common/MenuItem'
import SideMenuBar from "../../../../../components/Common/SideMenuBar";

let requestParams = {};
let journey_category = null;
let selectedActivity = null;

const ActivityTypeList = (props) => {
  useStyle("filter_dialog");

  let params = props.match.params;
  let journey_category = props.location.state;

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [activityCategory, setActivityCategory] = useState();
  const [tabIndex, setTabIndex] = useState();
  const [activities, setActivities] = useState([]);
  
  const {isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal();
  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal,} = useModal();
  const {isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal,} = useModal();

  let height = {'height': screen.xs?'unser':'300px'}

  useEffect(() => {
    requestParams = {
      data_source_id: [3354],
    }
    getActivityCategory();
  }, [])

  let getActivityCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      let d = CommonService.groupBy(res.college_cat.options, "actions");
      setActivityCategory(d);
    })
  }

  let getActivityByType = (form_id) => {
    let req = {'journey_profile_id':params.journey_profile_id, 'activity_form_id':form_id}
    CollegeActivityService.getActivityByType(req).then((res) => {
      setActivities(res.data.activities)
    })
  }

  const openTab = (ds) =>{
    if(ds.pk_id != tabIndex){
      getActivityByType(ds.child_form)
      setActivities([])
      setTabIndex(ds.pk_id)
    }else{
      setTabIndex(null)
    }
  }

  const addComments = (e, activity) => {
    e.stopPropagation();
    selectedActivity = activity;
    //selectedDataSource.journey_category_id = params.journey_category_id
    toggleCommentModal();
  }

  const addChecklist = (e, activity) => {
    e.stopPropagation();
    selectedActivity = activity;
    //selectedDataSource.education_journey_profile_id = params.journey_profile_id
    //selectedDataSource.journey_category_id = params.journey_category_id
    toggleChecklistModal();
  }

  const List = (key) => activityCategory[key].map((k) => {
    let isTabOpen = (tabIndex == k.pk_id);
    const link = `/college/activity/form/${params.journey_category_id}/${params.journey_profile_id}/${k.child_form}/${k.label}?form_wizard_id=${k.wizard_id}&fj_edu_entry_type=${k.value}`
    
    return (
      <Fragment key={k.value}>
        <div className={isTabOpen ? 'shadow-huge ver-icons':'ver-icons'} onClick={e=>openTab(k)}>
          <a href="/">
            <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
          </a>
          <div className="popup-menu-title">{k.label}</div>
          { isTabOpen && <div className="bold-600 br-d-grey p-5 d-contents">
          <span className="m-l-15 m-r-15"><i className="fas fa-list"></i> {activities.length}</span> 
          <span><i className="fas fa-check"></i> 0</span>
        </div>}
          <i className={'fas fa-chevron-'+ (tabIndex == k.pk_id? 'down':'right') +' lgrey pull-right lh-unset'} />
          <Link to={link} className="pull-right m-r-15">
            <i className="fas fa-plus lgrey" />
          </Link>  
        </div>
        
        {isTabOpen && <ActivitiesView type={k}/>}
      </Fragment>
    )
  })

  const ActivitiesView = ({type}) => activities.map((a, i) => {
    let data = a.data
    let ds = a.data_source
    const link = { pathname:`/college/activity/${params.journey_category_id}/${params.journey_profile_id}`,
    search:`?id=${a.id}`,
    state:journey_category
   }
    const borderLeft = {'borderLeft':'3px solid '+type.header_color}

    return(
      <div className="br-d-grey" key={i}>
        <div className="br-d-grey p-5" style={borderLeft}>
          <span className="pull-right highlight bold-600">{a.privacy_type=='private'?'Private':'Shared'}</span>
          <div className="font-14 bold-600">{data.record_title}</div>
          <div>
            <span className="m-r-15">Grade: 
              {ds.grade && ds.grade.label}
            </span> 
            <span>Score: <b>770</b></span>
            <Link to={link} className="pull-right m-t-5">
              <i className="fas fa-expand font-16"/>
            </Link>
            <span className="pull-right m-r-15 lgrey m-t-5" onClick={e=>addComments(e, a)}>
              <i className="far fa-comment font-16"></i>
            </span>
            <span className="pull-right m-r-15 lgrey m-t-5" onClick={e=>addChecklist(e, a)}>
              <i className="far fa-check-circle font-16"></i>
            </span>
            <span className="pull-right m-r-15 lred m-t-5">
              <i className="fas fa-lightbulb font-16"></i>
            </span>
          </div>
        </div>
      </div>
    )
  })

  const setTitle = (activity) =>{
    let data = activity.data
    let ds = activity.data_source

    if (activity.activity_form_id == 967) {
      /*data.record_title
      data.name*/
    } else if (activity.activity_form_id == 968) {
      /*activity.data_source.research_pub_subject.label*/
      data.record_title = activity.data.research_title
    } else if (activity.activity_form_id == 960) {
      /*data.record_title*/
      /*activity.data_source.type_of_test && activity.data_source.type_of_test.label*/
    } else if (activity.activity_form_id == 970) {
      /*data.record_title
      activity.data.course_name*/
    } else if (activity.activity_form_id == 971) {
      /*data.record_title*/
    } else if (activity.activity_form_id == 961) {
      /*data.record_title
      data.name*/
    } else if (activity.activity_form_id == 969) {
      
    } else if (activity.activity_form_id == 962) {
      data.record_title = data.name
    } else if (activity.activity_form_id == 964) {
      
    } else if (activity.activity_form_id == 959) {
      
    } else if (activity.activity_form_id == 963) {
     
    } else if (activity.activity_form_id == 966) {
     
    } else if (activity.activity_form_id == 965) {
      
    }
  }

  return(
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Activities"} project={journey_category} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/college/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
            
            {journey_category && <NavIcon id="tile_view" dataTip="Tile View" 
              link={{
                  pathname:`/college/activity/${params.journey_category_id}/${journey_category.education_journey_profiles.id}`,
                  state:journey_category}}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-file"/>
            }

            <NavIcon id="file_links" dataTip="Journey Files and Links" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={toggleFileLinkModal} icon="fas fa-link"/>
        </div>
          <div className="clear"></div>
      </div>

      {activityCategory && (
        <div className="page-content row">
          <div className="col-xs-12 col-sm-6 col-md-4 m-t-10">
            <div className="bg-white popup-menu-grid scroll-auto" style={height}>
              <h5 className="title">Academic</h5>
              {List("academic")}
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 m-t-10">
            <div className="bg-white popup-menu-grid scroll-auto" style={height}>  
              <h5 className="title">Extracurricular</h5>
              {List("extracurricular")}
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 m-t-10">
            <div className="bg-white popup-menu-grid scroll-auto" style={height}>
              <h5 className="title">Special experience</h5>
              {List("special_experience")}
            </div>
          </div>

          <div className="col-xs-12 col-sm-6 col-md-4 m-t-10">
            <div className="bg-white popup-menu-grid scroll-auto" style={height}>
              <h5 className="title">College Schedule</h5>
              {List("college_schedule")}
            </div>
          </div>
        </div>
      )}

      {isCommentOpen && (
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal}
          item={selectedActivity} type="edu_college_journey.activity"/>
      )}
      
      {isChecklistOpen && (
        <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal}
          item={selectedActivity} type="edu_college_journey.activity"/>
      )}

      {isFileLinkOpen && (
        <JourneyFilesLinksModal 
          toggleModal={toggleFileLinkModal}
          journeyCategory={journey_category}/>
      )}
      <SideMenuBar helpId="15" />
    </Fragment>
  )
}

export default ActivityTypeList;