import React, {useContext} from "react"
import NotificationPopup from '../Common/NotificationPopup'
import CommonService from "../../services/CommonService"
import CheckAccess from '../Journey/Roles/CheckAccess'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"

const LoginSuccessMsg = (props) => {
  let message = `  Welcome to the cueTree Education service. 
  Next, you will be asked to fill out a short form to register certain account details.`

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  const onSuccess = () =>{
    currentUser.sign_in_count += 1
    AuthenticationService.updateUserName({sign_in_count:currentUser.sign_in_count}).then((res)=>{
      if(res.status == 200){
        currentUser.sign_in_count += 1
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser }
        })
        CheckAccess.routeUser(currentUser, props.history)
      }
    })
  }

  return (
    <NotificationPopup header="Welcome" message={message} onSuccess={onSuccess}/>
  )
}

export default LoginSuccessMsg;