import React, { useEffect, useState, useContext } from 'react'
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import DynamicForm from '../../FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import QuestionService from "../../Questions/QuestionService"
import FormWizard from '../../../components/FormBuilder/FormWizard'
import FormContainer from '../../FormBuilder/FormContainer'

let formMode = null 
let News = {}
let data = {} 
let formFn = {} 

function QuestionForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let currentUser = authState.user
  let queryParam = querystringify.parse(props.location.search)

  /*TODO: Move API to Router to load data before view*/
  const getById = (id) => {
    QuestionService.get(id).then((res) => {
      if(res.status == 200){
        News = res.data.interview_question
        data = News.data || {}
        setFormRendered(true)
      }
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions'
    data = {} 
    News = {} 
    formFn = {'form_type':'news', 'editPermission':true}
   
    if(props.match.path){
      if(props.match.path.includes('details')){
        getById(params.id)
        formMode = 'view-form-submissions'
      }else if(params.id){
        formMode = 'edit-form-submissions'
        getById(params.id)
      }else{
        setFormRendered(true)
      }
    }
  }, []);

  const create = (News) => {
    News.created_by = authState.user.id
    //News.is_user_suggested = true
    News.form_id = params.form_id
    News.partnership_id = currentUser.domain_info.partnership_org_id

    if(queryParam.data){
      let data = JSON.parse(queryParam.data) 
      News.data = {...News.data, ...data}
      News.user_type = 'user'
    }
    News.journey_category_id = queryParam.journey_category_id
    News.journey_profile_id = queryParam.journey_profile_id
    News.privacy_type = data.share_selection == 1 ? 'shared':'private'

    QuestionService.create(News).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    })
  }

  const update = (News) => {
    QuestionService.update(News).then((res) => {
      if(res.status==204){
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = () => {
    if(queryParam.form_wizard_id){
      props.history.goBack()
    }else{
      props.history.push("/admin/questions")
    }
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id){
    return(
      <FormWizard form_wizard_id={queryParam.form_wizard_id} form={News} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}/>
    )
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
         <DynamicForm formMode={formMode} formId={FormTemplateId.QuestionBankForm} 
            form={News} data={data} formFn={formFn} onCreate={create} 
            onUpdate={update} post={post} 
            onCancel={onSubmitPageRedirect} setPost={setPost} />
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default QuestionForm;