import React, { useEffect, useState, useContext } from 'react';
import querystringify from "querystringify"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormWizard from '../../../../../components/FormBuilder/FormWizard';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import ActivityService from "../../../../../components/Journey/HighSchoolJourney/Activities/ActivityService";
import FormContainer from '../../../../FormBuilder/FormContainer';
import Cs from '../../../../../services/CommonService'

let formMode = null
let activity = {} 
let data = {}
let formFn = {}  
let form_template_id = null

function ActivityForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  const {project} = props?.location?.state || {}
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    let req = {
      'id': params.id,
      'journey_category_id': params.journey_category_id
    }
    ActivityService.get(req).then((res) => {
      if(res.status==200){
        activity = res.data.education_journal_entry;
        data = activity.data || {};
        form_template_id = activity.activity_form_id
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       activity = null;
    })
  }
  
  useEffect(() => { 
    activity = {} 
    data = {}
    formFn = {
      'form_type':'education_journal_entry', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    }
    formMode = 'create-form-submissions'
    form_template_id = params.form_template_id
    if(params.id){
      get(params)
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true);
    }else if(props.location.state){
      activity = props.location.state
      data = activity.data
      setFormRendered(true)
    }
  }, [])

  const create = (activity) => {
    activity.created_by = authState.user.id;
    activity.education_journey_profile_id = params.journey_profile_id;
    activity.journey_category_id = params.journey_category_id;
    activity.activity_type = params.type;
    activity.activity_form_id = params.form_template_id; 
    activity.form_wizard_id = queryParam.form_wizard_id;
    activity.data.fj_edu_entry_type = parseInt(queryParam.fj_edu_entry_type);
    activity.privacy_type = 'private';
    activity.data.grade = project?.education_journey_profiles?.data?.fj_edu_current_grade
    ActivityService.create(activity).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect(activity);
      }
    }).catch(function(res){
       
    })
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id;
    activity.form_wizard_id = queryParam.form_wizard_id;
    ActivityService.update(activity).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect(activity);
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = (activity) => {
    if(props.isPopupMode){
      activity?.id && props.onSubmit(activity)
      props.toggleModal()
    }else if(queryParam.goBack){
      props.history.goBack()
    }else{
      formFn.setIsFormSubmitted && formFn.setIsFormSubmitted()
      //const currentGrade = project.education_journey_profiles?.data?.fj_edu_current_grade
      //const carrierInterests = project.education_journey_profiles?.data?.interest 
      Cs.routeJourney(project, props)
      //props.history.push(`/highschool/path/${project.id}/${project.education_journey_profiles.id}?current_grade=${currentGrade}&carrier_interests=${carrierInterests}`, project)
      //props.history.push(`/highschool/activity/${params.journey_category_id}/${params.journey_profile_id}`)
    }
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id || queryParam.unfilled_client_id){
    return(
      <FormWizard form_wizard_id={queryParam.form_wizard_id} form={activity} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}
      location={props.location}/>
    )
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={form_template_id} 
        form={activity} data={data} formFn={formFn} onCreate={create} 
        onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
        setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default ActivityForm;