import React, { Fragment, useState, useEffect, useContext,useRef } from 'react'
import ReactDOM from 'react-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommentsService from '../../services/CommentsService'
import Cs from '../../services/CommonService'
import useStyle from '../../hooks/useStyle'
import Pagination from '../Pagination'
import useEscape from '../../hooks/useEscape'
import ReactTooltip from "react-tooltip"

let comment = {}
let requestParams = null

const CommentsModal = ({ item, type, isOpen, toggleModal, showInline, journeyCategoryId, params}) => {
  useStyle('chat')
  useStyle('add_role_dialog')
  // useStyle('panel')
  useEscape(toggleModal)
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  
  if(item)item.comments = item.comments || []
  const [commentsList, setCommentsList] = useState([])
  const [text, setText] = useState('')
  const [parentComment, setParentComment] = useState({})
  const [loading, setLoading] = useState(true)
  const [fileSizeError, setFileSizeError] = useState(false)
  
  const listInnerRef = useRef()
  
  useEffect(() => {
    requestParams = {
      'commentable_id': item.id,
      'commentable_type': type,
      'journey_category_id':journeyCategoryId,
      'event_tracking': {
        "event_type":type,
        "event_action":"view_comment",
        "event_label":null,
        "event_id":item.id
      },
      'sort_column': 'updated_at',
      'sort_direction': 'asc',
      'page':"last",
      'per_page':15,
    }

    if(isOpen){
      resetComment()
      getNotesList()
    }
  }, [item.id])

  if(type == 'data_source'){
    requestParams.commentable_int_id = item.pk_id
    delete requestParams.commentable_id
  }

  let getNotesList = (is_lazy_loaded) => {
    setLoading(true)
    CommentsService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setLoading(false)
        setCommentsList([...res.data.comments, ...commentsList])
        if(requestParams.page=='last')onScroll();
        requestParams.page = res.data.meta.page
        requestParams.totalItems = res.data.meta.total
        scrollTo(res.data.comments, is_lazy_loaded)
      }
    })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      if(listInnerRef.current.scrollTop == 0){
        if(requestParams.page > 1){
          requestParams.page -= 1
          getNotesList(true)
        }
      }
    }
  }

  const resetComment = ()=>{
    comment = {};
    comment.commentable_id = item.id 
    comment.commentable_type = type
    comment.journey_category_id = item.journey_category_id
    if(type == 'data_source'){
      comment.commentable_int_id = item.pk_id
      delete comment.commentable_id;
    }
  }

  const addComment = (e, text) => {
    setLoading(true)
    e.preventDefault()
    if(text=='')return;
    if(params)comment = {...comment, ...params}  
    if(comment.id){
      comment.comment = text
      CommentsService.update(comment).then((res)=>{
        if(res.status == 204){
          setLoading(false)
          setText('')
          setCommentsList((commentsList.filter(i => i.id !== comment.id)).concat([comment]))
          resetComment()
          scrollTo(commentsList)
        }
      })
    }else{
      comment.comment = text
      if(parentComment.id)comment.parent_comment_id = parentComment.id
      CommentsService.create(comment).then((res)=>{
        if(res.status == 201){
          setLoading(false)
          comment = res.data.comment
          //comment.record_id = res.data.comment.record_id;
          comment.parent_comment = parentComment
          setCommentsList([...commentsList, comment])
          /*setText('');*/
          resetComment()
          setParentComment({})
          scrollTo(commentsList)
        }
      })  
    }
  }

  const deleteComment = (comment) => {
    setLoading(true)
    CommentsService.delete(comment.id).then((res)=>{
      setLoading(false)
      setCommentsList(commentsList.filter(i => i.id !== comment.id))
      scrollTo(commentsList);
    })  
  }

  const setEdit =(c)=>{
    comment = c
    setText(c.comment)
  }

  const setReply = (comment) =>{
    setParentComment(comment)
    scrollTo(commentsList)
  }

  function scrollTo(comments, is_lazy_loaded){
    if(comments.length>0){
      try{
        let ele = null
        if(is_lazy_loaded){
          let scroll_id = comments[comments.length - 1].id
          ele = document.getElementById('comment_'+scroll_id)  
        }else{
          ele = document.getElementById('last_comment')  
        }
        document.getElementById('chat-main').scrollTop = ele.offsetTop
      }catch(e){
        console.log(e)
      }
    }
  }

  const uploadFileToUrl = (event) => {
    try {
      let files = event.target.files
      let ele =  event.target
      let FileSize = files[0].size / 1024 / 1024 // in MB
      if (FileSize > 1) {
        ele.value = null
        setFileSizeError(true)
        setTimeout(()=>setFileSizeError(false), 5000) 
        return
      }

     if(files.length>0){
        setLoading(true)
        Cs.uploadFileToUrl(files[0], null, comment, 'comment', 'family_tree/comments/file_upload').then((res)=> {
          ele.value = null
          comment = res.data.comment  
          //comment.record_id = res.data.comment.record_id;
          setLoading(false)
          setCommentsList([...commentsList, comment])
          /*setText('');*/
          resetComment()
          scrollTo(commentsList)
        }, (err)=>{
          setLoading(false)
        })
      }
    } catch (e) {
      setLoading(false)
    }
  }

  const CommentType = ({comment}) =>{
    if(comment.file_upload_id){
      return(
        <a target="_blank" href={Cs.getFileUrl(comment.comment)}>
          <img src={Cs.getIconByType(comment.comment)} width="75px"/>
          <span>{Cs.getFileName(comment.comment)}</span>
        </a>
      )
    }else if(JSON.stringify(comment.parent_comment) != "{}"){
      return(
        <Fragment>
          {comment.parent_comment && 
            <div className="reply-comment">
              {comment.parent_comment.file_upload_id && 
                <a target="_blank" href={Cs.getFileUrl(comment.parent_comment.comment)}>
                  <img src={Cs.getIconByType(comment.parent_comment.comment)} width="75px"/>
                </a>
              }
              <span>{Cs.getFileName(comment.parent_comment.comment)}</span>
            </div>
          }
          <span dangerouslySetInnerHTML={{ __html: Cs.replace_content(comment.comment)}}></span>
        </Fragment>
      )
    }else{
      return(
        <Fragment>
          <span dangerouslySetInnerHTML={{ __html: Cs.replace_content(comment.comment)}}></span>
        </Fragment>
      )
    }
  }

  const commentsItem = commentsList.map((c, k) =>
    <Fragment key={k}>
      <div id={`comment_${c.id}`} className={`speech-bubble ${c.created_by == currentUser.id?'speech-left':'speech-right'}`}>
        <span className="portlet-dropdown">
          <a data-tip="Journey Menu" className="p-dropbtn">
            <i className='fas fa-caret-down'></i>
          </a>
          <div className="p-dropdown-comment">
            { c.created_by == currentUser.id &&
              <Fragment>
                {!c.file_upload_id &&
                  <a onClick={e=>setEdit(c)} >
                    <i className="far fa-edit"/> Edit
                  </a>
                }
                <a onClick={e=>deleteComment(c)}>
                  <i className="far fa-trash-alt"/> Delete
                </a>
              </Fragment>
            }
            <a onClick={e=>setReply(c)}>
              <i className="fas fa-reply" aria-hidden="true"></i> Reply
            </a>
          </div>
        </span>
        {c.created_by == currentUser.id &&
          <div className="pull-right">
            <div className="font-16 pull-right m-r-5">
              <i className={`far ${c.read_status?'fa-eye':'fa-eye-slash'} text-muted`} aria-hidden="true"></i>
            </div>
          </div>
        }
        <CommentType comment={c}/>
        <span className="speech-read mb-3">{c.author && c.author.name} - {Cs.formatUpdateDate(c.updated_at)}</span>
      </div>
      <div className="clear"/>
    </Fragment>
  )

  const CommentForm = (props) =>{
    const [text, setText] = useState(props.text || '')
    
    return(
      <div className="card-footer p10" style={{height:"60px"}}>
        <form className="flex aligncenter" onSubmit={e=>props.addComment(e, text)}>
          {fileSizeError && 
            <div className="speech-reply errormsg">
              File Size is Large. Max Size 1 MB
            </div>
          }
          {parentComment.id && 
            <div className="speech-reply">
              <CommentType comment={parentComment}/>
              <span onClick={e=>setReply({})} className="pull-right">
                <i className="fas fa-times"></i>
              </span>
            </div>
          }
          <label data-tip="Max File Size 1mb" htmlFor="file_upload"
            className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter m-r-10">
              <img src="./images/plus-icon-gray.png" className="w-16px" alt=""/>
          </label>

          <input type="file" id="file_upload" className="hidden" onChange={e => props.uploadFileToUrl(e)} />

          <input type="text" className="form-input form-input-gray form-input-full" 
            onChange={e => setText(e.target.value)}
            placeholder="Enter your message here"/>

          <span onClick={e=>addComment(e, text)}
            className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter m-l-10">
              <img src="./images/mesage-icon-gray.png" className="w-16px" alt=""/>
          </span>
        </form>
      </div>
    )
  }

  const CommentBox = () =>(
    <Fragment>
      <div className="card-title card-title-gray flex justspacebetween p-b-15 p20 font-16">
        Comments
        {!showInline && <span onClick={e=>toggleModal(e)}>X</span>}
      </div>

      {/*<div className="ml-center bg-lgreen white p-5">
        <div className="font-16 bold-600">Comments</div>
        {!showInline &&
          <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
            onClick={e=>toggleModal(e)}>×
          </span>
        }
      </div>*/}

      <div className="bottom-0 chat-main" id="chat-main" style={{height:screen.height-300}} onScroll={() => onScroll()} ref={listInnerRef}>
        <div id="first_comment" className="m-t-5"></div>
        {commentsItem.length==0 && 
          <div className="no-chat font-15">This {type} Tile has no comments yet.You can add one now.</div>
        }
        {loading ? <div className="spinner"></div> : commentsItem}
        <div id="last_comment" className="m-t-5"></div>
      </div>
      
      <CommentForm text={text} addComment={addComment} uploadFileToUrl={uploadFileToUrl}/>
    </Fragment>    
  ) 

  if(showInline){
    return(
      <div className="m-t-15">
        <div className="ml-card-4 bg-white">
          <div id="ct">
            <CommentBox/>
          </div>
        </div>
      </div>
    )
  }

  return(ReactDOM.createPortal(
    <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom bg-white">
        <div id="ct">
          <CommentBox/>
        </div>
      </div>
    </div>, document.body)
  )
}

export default CommentsModal;