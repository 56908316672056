import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import JourneyNotificationService from "./JourneyNotificationService"
import Cs from "../../../services/CommonService";

function NotificationList(props) {
  const { state:{ user:currentUser } } = useContext(AuthContext)

  const [notifications, setNotifications] = useState([])

  useEffect(() => { 
    get()
  }, [])

  const get = () => {
    let req = {
      page:1,
      per_page:15,
      item_type:props.item_type,
      journey_category_id:props.journey_category_id,
      //item_id:props.item_id
    }
    JourneyNotificationService.getAll(req).then((res) => {
      if(res.status==200){
        setNotifications(res.data.admin_notifications || [])
      }
    })
  }

  const TemplateCard = ({msg, date}) =>{
		const [show, setShow] = useState(true);
		if(!show)return null;
		return(
		  <div className="row p-7 font-16 white bg-grey shadow-small m-b-5">
        <span className="pull-left white" dangerouslySetInnerHTML={{ __html: msg }}/>
        <span className="pull-right white font-12">{Cs.formatUpdateDate(date)}</span>
		  </div>
		)
	}

  const NotificationPanel = () => notifications.map((n, i) => {
    return(
      <TemplateCard msg={n.data.text_msg} date={n.updated_at} key={i}/>
    )
  })
  
  return(  
    <div className="row bg-white br-grey p-12 ht-200 scroll-auto">
      <NotificationPanel/>     
    </div>
  )

}

export default NotificationList;