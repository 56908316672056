import React, { Fragment, useEffect, useState, useContext } from "react"
import useStyle from "../../../hooks/useStyle"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import { Link } from "react-router-dom"

const SignInAndSignUpBtn = (props) => {

  const { state: currentUser, isAuthenticated: isAuthenticated } = useContext(AuthContext)

  if(isAuthenticated)return null;

  return(
    <Fragment>
      <div className="row bg-white p-20">
        <div className="col-xs-12 font-18 bold p-20">
          Great! Let’s get you started with the journey you have requested. Pick the option below that applies to you.
        </div>
        <div className="col-xs-12 col-md-6 p-0-40 m-t-10">
          <h4 className="ht-50">If you have a cueTree Education account</h4>
          <div>Follow these steps</div>
          <div className="font-14 m-t-10">
            <ol>
              <li><Link className="d-contents" to="/signin"> Sign in </Link> in to your cueTree Education account.</li>
              <li>Once signed in, you will be asked to fill out your
                account profile and you will have the option to
                take a new user welcome tour. Once you have
                completed these, you will be able to launch your
                student journey. 
              </li>
              <li>If you have already created your account profile,
                you will be able to launch the student journey
                you requested.
              </li>
            </ol> 
          </div>
        </div>
        <div className="col-xs-12 col-md-6 p-0-40 m-t-10 br-lft">
          <h4 className="ht-50">If you do not have a cueTree Education account</h4>
          <div>Follow these steps</div>
          <div className="font-14 m-t-10">
            <ol>
              <li><Link className="d-contents" to="/signup">Sign up </Link>for your cueTree Education account.</li>
              <li>Click the verification link that you will receive in your email inbox, to
                  activate your account and sign in. If you do not see an email in your
                  inbox, check your spam folder for the missing email.  
              </li>
              <li>Once signed in, you will be asked to fill out your account profile and you
                  will have the option to take a new user welcome tour. Once you have
                  completed these, you will be able to launch your student journey. 
              </li>
            </ol> 
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default SignInAndSignUpBtn;