import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';

const JourneyUserGroupEmailTable = ({userList}) => {
  useStyle('table');  
  
  const tableData = userList.map((o, k) =>
    <tr key={k}>
      <td>{k}</td>
      <td>{o.email}</td>
      <td>
        <span className="m-r-5">
          <span className={o?.sent_email_notifications?.is_email_sent ? 'far fa-check-circle' : 'far fa-circle'}/>
        </span>
      </td>
      <td>
        <span className="m-r-5">
          <span className={o?.sent_email_notifications?.joined_from_invite ? 'far fa-check-circle' : 'far fa-circle'}/>
        </span>
      </td>
    </tr>
  )

  return (
    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th>Id </th>
            <th>Email </th>
            <th>Notification Sent </th>
            <th>Student Accessed Exam</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

}

export default JourneyUserGroupEmailTable;