import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import PublicRoute from "../../routes/PublicRoute"
import FormsList from "./Forms/FormsList"
import FormsListTable from "./Forms/FormsListTable"
import Form from "./FormSubmissions/Form"
import FormSubmissions from "./FormSubmissions/List"
import FormPage from "./Forms/FormPage"
import FormReport from './Forms/FormReport'

const UserFormRouter = (props) => (
  <Fragment>
  	<PrivateRoute path="/forms/:journey_category_id/:journey_profile_id/:item_type?/:item_id?" component={FormsList} exact />
  	<PrivateRoute path="/user/forms/:journey_category_id/:journey_profile_id" component={FormsListTable} exact />
  	<PrivateRoute path="/form/submissions/:journey_category_id/:journey_profile_id/:user_form_id/:id?" component={Form} exact />
  	<PublicRoute path="/public/submissions/:journey_category_id/:journey_profile_id/:user_form_id/:id?" component={Form} exact />
  	<PrivateRoute path="/list/submissions/:journey_category_id/:journey_profile_id/:user_form_id" component={FormSubmissions} exact />
  	<PrivateRoute path="/create/form/:journey_category_id/:journey_profile_id/:form_id/:id?" component={FormPage} exact />
	<PrivateRoute path="/form/reports/:id?" component={FormReport} exact />
  </Fragment>
)

export default UserFormRouter;