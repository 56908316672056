import React, { Fragment, useState } from 'react';
import useStyle from '../../hooks/useStyle'

let addToScreenPrompt = null;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  addToScreenPrompt = e;
})

const AddToHomeScreen = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  useStyle('snackbar');
  
  if (!addToScreenPrompt) {
    return null
  }

  if(!isOpen) return null;

  /*if (nativeApp && doesVersionSendPushMessages(nativeApp.version)) {
    // There’s an installed native app that handles sending push messages.
    // No need to do anything.
    return;
  }*/

  const addToHome = () =>{
    // Show the prompt
    if(addToScreenPrompt){
      addToScreenPrompt.prompt();
      // Wait for the user to respond to the prompt
      addToScreenPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
          setIsOpen(false);
        }
        addToScreenPrompt = null;
      })
    }
  }

  // Detects if device is on iOS 
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  // Checks if should display install popup notification:
  const InstallMessage = () => {
    if (isIos() && !isInStandaloneMode()) {
     return (
      <div className="font-15">
        Install this webapp on your iPhone<br/> 
        <img className="w-30 pull-left" src="images/add_to_home.png"/> 
        tap and then Add to homescreen.
      </div>
     )
    }else{
      return (
        <div className="add-to-home" onClick={e=>addToHome(e)}>ADD TO HOME SCREEN</div>
      )
    }
  }

  return (
    <div id="snackbar" className="show">
      <span onClick={e=>setIsOpen(false)} className="ml-button font-20 ml-hover-red ml-display-topright color-red2-dark" title="Close Modal">
        <i className="fa fa-times" aria-hidden="true"></i>
      </span>
      <div className="col-xs-3">
        <img src="images/logo/apple-touch-icon-57x57.png" alt="logo"/>
      </div>
      <div className="col-xs-9">
        <h4 className="green">cueTree Life</h4>
        <div className="font-15">CueTree Life</div>
      </div>
      <a><u>www.edu.beta.cuetree.com</u></a>
      <InstallMessage/>
    </div>
  )

}

export default AddToHomeScreen;