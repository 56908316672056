import React, { useState, useEffect, Fragment, useContext} from "react"
import {Link, Route, Redirect } from "react-router-dom"
import useStyle from "../../../../hooks/useStyle"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../PreLoadSpinner"
import {NavIcon, MenuItem, CardMainMenu, CardMenuInfo, FilterMenuItem} from '../../../Common/MenuItem'
import Pagination from '../../../Pagination'
import PlannerService from './PlannerService'
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle"
import TrainingModuleService from "../TrainingModules/TrainingModuleService"
import CoursePlannerTable from "./CoursePlannerTable"
import CourseFilterList from "./CourseFilterList"
import SideMenuBar from "../../../Common/SideMenuBar"
import TrainingModuleForm from "../TrainingModules/Form/TrainingModuleForm"
import CourseService from "../TrainingTopics/CourseService";
import Course from '../TrainingTopics/List/Course';
import ProjectMenu from "../../../Modals/ProjectMenu";
import useModal from "../../../../hooks/useModal";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import GenericModal from "../../../Modals/GenericModal"
import UsersWithGroupModal from "../../../Journey/UserRoles/UsersWithGroupModal"

let requestParams = {}
let courseDetail = {}
let dataSource = {}
let groupModalOption = {}

const PlannerCourseList = (props) => {
  useStyle('card')
  useStyle('card_table')
  useStyle("course")

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const {domain_info: {data_source: profileDs}} = currentUser;

  let params = props.match.params;
  let journey_category = props.location.state;

  const [courseList, setCourseList] = useState()
  const [showCourseDetailsForm, setCourseDetailsForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentpage, setCurrentpage] = useState(1) 
  const [share, setShare] = useState({role_sid:[]})

  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isStatusModalOpen, toggleModal:toggleStatusModal } = useModal()
  const { isOpen:isUserGroupModalOpen, toggleModal:toggleUserGroupModal } = useModal()


  useEffect(() => {
    requestParams = {
      'page':currentpage,
      'paginate':1,
      'per_page':100,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'journey_category_id':params.journey_category_id,
      'search': props.searchString,
      'filter':'planner',
      'data_source_params': {
        "data_source_id":[3432],
      }
    }  
    getCourses()
  }, [props.searchString, currentpage])

  let getCourses = () => { 
    setLoading(true)
    CourseService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setCourseList(res.data.course_details)
        setCourseDetailsForm(res.data.course_details.length==0?true:false)
        dataSource = res.data.data_source_list
        res.data.invited_journey_member.role_sid = res.data.invited_journey_member.role_sid || [] 
        setShare(res.data.invited_journey_member)
        setLoading(false)
      }
    })
  }

  let getModules = (course_id, callback) => {
    let req = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':1,
      'per_page':100,
      'sort_column':'mf.sort_order',
      'sort_direction':'asc',
      'exists_in_planner':true,
      'topic_exists_in_planner':true,
      'filter':'student_curriculum',
      'course_id':course_id,
    }

    TrainingModuleService.getAll(req).then((res) => {
      if(res.status == 200){
        callback(res.data.modules)
      }
    })
  }

  const setCourseDetails = (data) =>{
    if(courseDetail?.id == data.id){
      courseDetail = null
      setCourseList([...courseList])
    }else{
      setCourseList([data, ...courseList])
    }
  }

  const routeCoursePlanner = (course) =>{
    courseDetail = course
    setCourseDetailsForm(true)
  }

  const routeDetails = (course) =>{
    props.history.push(`/career_training/planner/${params.journey_category_id}/${params.journey_profile_id}/${course.id}`, journey_category)
  }
  
  const routeAssessment = (course) =>{
    props.history.push(`/career_training/assessments/${params.journey_category_id}/${params.journey_profile_id}/${course.id}`, journey_category)
  }

  const showStatusModal = (e, course) => {
    courseDetail = course;
    toggleStatusModal(e)
  }

  const updateAdminCourse = (e, status, type) => {
    let req = {
      id: courseDetail.id, 
      admin_status: status.label.toLowerCase(),
      journey_category_id: params.journey_category_id
    }
    TrainingModuleService.admin_update(req).then((res)=>{
      if(res.status == 204){
        courseDetail.admin_status = req.admin_status
        setCourseList([...courseList])
      }
    })
    toggleStatusModal(e)
  }

  const updatePrivacyType = (e, item, is_public) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      id: item.id, 
      is_public:is_public
    }
    TrainingModuleService.update(req).then((res)=>{
      if(res.status == 204){
        item.is_public = req.is_public
        setCourseList([...courseList])
        setLoading(false)
      }
    })
  }

  const deleteCourse = (e) => {
    e.preventDefault()
    let req = {id:courseDetail.id, journey_category_id:params.journey_category_id}
    TrainingModuleService.delete(req).then((res)=>{
      if(res.status==204){
        setCourseList(courseList.filter(i => i.id !== courseList.id))
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, course) => {
    courseDetail = course;
    toggleMenuModal(e)
  }

  const openUserGroup = (e, course, show_group, role, roleable_type) =>{
    groupModalOption.showGroup = show_group
    groupModalOption.role_sid = role
    groupModalOption.roleable_type = roleable_type
    groupModalOption.title = role === 'student'?'Invite Student':'Invite Teacher'
    courseDetail = course
    toggleUserGroupModal(e)
  }

  const hasEditUserGroupPermission = () =>{
    return share.role_sid.includes('admin') || share.role_sid.includes('teacher')
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <CardMenuInfo formId={item.module_form_id} recordId={item.record_id} 
          createdAt={item.updated_at} userName={item.created_user.name}/>
        
        {(journey_category.is_admin_user || share.role_sid.includes('admin') || share.role_sid.includes('teacher')) && 
          <>
            <MenuItem 
              onSelect={(e) => openUserGroup(e, item, true, 'student', 'carrier_training.course')}
              icon="far fas fa-users"
              action={`Invite Students (${item.student_count})`}/>

            <MenuItem 
              onSelect={e=> routeAssessment(item)}
              icon="far fas fa-newspaper"
              action="Create an Assessment"/>

            <MenuItem 
              link={`/forms/${params.journey_category_id}/${params.journey_profile_id}/carrier_training.course/${item.id}`}
              icon="far fas fa-newspaper"
              action="Create an User Form"/>

            <MenuItem 
              onSelect={(e) => openUserGroup(e, item, false, 'teacher', 'carrier_training.planner')}
              icon="far fas fa-user-tie"
              action={`Invite Teacher (${item.teacher_count})`}/>

            <MenuItem id="preview_icon" 
              link={`/career_training/modules/${params.journey_category_id}/${params.journey_profile_id}/${item.id}`}
              icon="far fas fa-eye"
              action="Preview"/>

            <MenuItem 
              onSelect={e=> updatePrivacyType(e, item, !item.is_public)}
              icon={item.is_public?'far fas fa-check-circle':'far fas fa-circle'} 
              action="Enable public display" />
          </>
        }

        {(journey_category.is_admin_user || share.role_sid.includes('admin')) && 
          <>
            <MenuItem id="status_icon" 
              onSelect={(e) => showStatusModal(e, item)}
              icon={item.admin_status?'fas fa-lock-open':'fas fa-lock'}
              action={item.admin_status || 'Activate this course'}/>
            
            <MenuItem 
              link={`/journey/price/form/${params.journey_category_id}/${params.journey_profile_id}/course/${item.id}`}
              icon="fas fa-dollar-sign"
              action="Set course fee"/>

            <MenuItem 
              link={`/journey/notification/form/${params.journey_category_id}/${params.journey_profile_id}/career_training.course/${item.id}`}
              icon="fas far fa-bell"
              action="Post a course notice"/>  
          </>
        }
            
        <MenuItem id="edit_icon" 
          onSelect={(e) => routeCoursePlanner(item)}
          icon="far fa-edit"
          action="Edit"/>

        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => {courseDetail = item;toggleDeleteModal(e)}}/>
      </Fragment>
    )
  }

  const Menu = ({course}) => (
    <CardMainMenu activity = {course} showMenu={showMenu} />
  )

  if(showCourseDetailsForm){
    let formProps = {
      match:{
        params:{
          journey_category_id: params.journey_category_id,
          journey_profile_id: params.journey_profile_id,
          form_template_id: 1009,
          type: 'planner_course_details',
          go_back: true,
          id: courseDetail?.id
        }
      },
      location:{}
    }

    return(
      <TrainingModuleForm {...formProps} setCourseDetailsForm={setCourseDetailsForm} setCourseDetails={setCourseDetails}/>
    )
  }

  const courseCard = courseList && courseList.map((c, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Course course={c} routeActivity={routeCoursePlanner} routeDetails={routeDetails} 
        getModules={getModules} index={i} type="planner" menu={Menu} currentUser={currentUser}/>
      {courseDetail && courseDetail.id == c.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={courseDetail} type="course_detail" MenuItems={menuItems} menuHeight="365px"/>
      }
    </div>
  ))

  return (
    <Fragment>
      {loading && <div className="spinner"/>}
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Course Planner"} project={journey_category} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/career_training/project/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>

          <NavIcon dataTip="Share" 
            link={{pathname:`/journey/user_role/${params.journey_category_id}/${params.journey_profile_id}/carrier_training.planner/${journey_category.id}`, search:`?title=planner`, state:{journey:journey_category, activity:journey_category} }}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="far fa-eye-slash"/>

          <NavIcon dataTip="Course Details Form" 
            onSelect={e=>setCourseDetailsForm(!showCourseDetailsForm)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"/>
        </div> 
        <div className="clear"/>
      </div>

      <div className="page-content row">
        {courseCard}
      </div>

      <div className="m-b-60"/>
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteCourse}/>
      {isStatusModalOpen && <GenericModal component={FilterMenuItem} title="Status" toggleModal={toggleStatusModal} 
        list={dataSource.training_course_status.options} onSelect={updateAdminCourse}/>
      }
      {isUserGroupModalOpen && <GenericModal component={UsersWithGroupModal} title={groupModalOption.title} 
        toggleModal={toggleUserGroupModal} journey_category_id={params.journey_category_id} 
        journey_profile_id={params.journey_profile_id} roleable_id={courseDetail.id} 
        options={groupModalOption} roleable_type={groupModalOption.roleable_type}
        hasEditUserGroupPermission={hasEditUserGroupPermission}/>
      }
    </Fragment>
  )
}

export default PlannerCourseList;

/*
{isStudentGroupModal && <GenericModal component={UsersWithGroupModal} title="Invite Student" 
        toggleModal={toggleStudentGroupModal} journey_category_id={params.journey_category_id} 
        journey_profile_id={params.journey_profile_id} roleable_id={courseDetail.id} 
        options={groupModalOption} share={share}/>
      }

*/