import React, { Fragment, useState, useEffect } from "react"
import AuthenticationService from "../../services/AuthService"

let data = {}
const SecretQuestions = (props) => {

  const [secretQuestion, setSecretQuestion] = useState({})
  const [questionsList, setQuestionsList] = useState([])

  useEffect(() => {
    getAllSecretQuestions()
  }, [])

  const getAllSecretQuestions = () => {
    let req = {
      data_source_params: {
        response_type: "object",
        data_source_id: [3371],
      },
      page:1,
      per_page:15
    }
   
    AuthenticationService.getAllSecretQuestion(req).then((res) => {
      if (res.status == 200) {
        let sq = res.data.secret_questions
        sq = (sq && sq[0]) || {}
        data = sq.data || {}
        setSecretQuestion(sq)

        let questionsList = Object.values(res.data.data_source_list.secret_questions.options)
        setQuestionsList(questionsList)
        //validateBeforeSubmit()
      } 
    })
  }

  const handleQuestion = (e) => {
    data[e.target.name] = e.target.value
    setQuestionsList([...questionsList])
    validateBeforeSubmit()
  }

  const handleAnswer = (e) => {
    data[e.target.name] = e.target.value
    validateBeforeSubmit()
    //setSecretQuestion({...secretQuestion})
  }

  const postSecretQuestion = () => {
    let req = {
      data: data,
    }
    if(secretQuestion.id){
      AuthenticationService.updateSecretQuestion(req, secretQuestion.id).then((res) => {
        if (res.status == 201) {
        
        } 
      })
    }else{
      AuthenticationService.createSecretQuestion(req).then((res) => {
        if(res.status==201){
          props.history.push(`/journey`)
        }
      })
    }
  }

  const Questions = ({question_input_id}) => questionsList.map((o, k) =>{
    if(checkQuestionAnswered(o.pk_id, question_input_id)){
      return <option key={k} value={o.pk_id}>{o.label}</option>
    }else{
      return null
    }
  })

  const checkQuestionAnswered = (question_id,  question_input_id) =>{
    let isQuestionNotSelected = true;
    if(question_input_id == 1 && (data.question_2_pk_id == question_id || data.question_3_pk_id == question_id)){
      isQuestionNotSelected = false; 
    }else if(question_input_id == 2 && (data.question_1_pk_id == question_id || data.question_3_pk_id == question_id)){
      isQuestionNotSelected = false;
    }else if(question_input_id == 3 && (data.question_1_pk_id == question_id || data.question_2_pk_id == question_id)){  
      isQuestionNotSelected = false;
    }
    return isQuestionNotSelected
  }

  const validateBeforeSubmit = () =>{
    if(data.answer_1 && data.answer_2 && data.answer_3){
      document.getElementById('submit_btn').classList.remove('hidden')
    }else{
      document.getElementById('submit_btn').classList.add('hidden')
    }
  }

  const QuestionList = () => [1,2,3].map((o, k) =>
    <div key={k}>
      <div className="form-group">
        <label>Question {o}</label>
        <select className="form-control" id={'question_'+o+'_pk_id'} name={'question_'+o+'_pk_id'} 
          defaultValue={data['question_'+o+'_pk_id']}
          onChange={e=>handleQuestion(e)}>
          <option>Pick Question</option>
          <Questions question_input_id={o}/>
        </select>
      </div>

      <div className="form-group">
        <label>Answer</label>
        <input className="form-control" id={'answer_'+o} name={'answer_'+o}
          defaultValue={data['answer_'+o]}
          onChange={e=>handleAnswer(e)}/>
      </div>
    </div>
  )

  /*onBlur={e=>setSecretQuestion({...secretQuestion})}*/

  if(!questionsList)return null;

  return(
    <div className="col-xs-12 col-md-12">
      <div className="profile-card profile-card-style">
        <div className="content mb-0">
          <h3 className="font-600">Your Secret Questions</h3>
          <p className="lred">You must set up your responses before you can continue with the application.</p>
          <p className="m-b-10 lred">Your responses may be used to permit access to your account. You may update your responses at any time from this screen.</p> 
          <label>Pick any three questions</label>
          <QuestionList/>
          <button id="submit_btn" type="button" onClick={(e) => postSecretQuestion(e)} 
              className="button bg-green p-5 m-b-10 hidden">
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default SecretQuestions;