import BaseService from "../services/BaseService";

//let url = env.carrier_training+'assessments'

class BookmarkService extends BaseService{
  
  /*getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }*/

}

export default new BookmarkService('family_tree_backend', 'bookmarks');