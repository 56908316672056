import React ,{Fragment,useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../../services/CommonService'
import env from "../../../env"
import {FormFieldTable, RenderData} from "../../Journey/UserRoles/NotificationPill"

const Scholarship = (props) => {
  let {scholarship, formFieldsList, dataSource} = props
  let {data, data_source} = scholarship
  let formFields = formFieldsList[scholarship.scholarship_form_id] || []
  const cardStyle = {backgroundColor: "#607D8B"}

  const Footer = () =>(
    <div className="card-footer">
      <div className="pull-right">
      </div> 
    </div>
  )

  const GenericContent = () => {
    return (
      <div className="bg-white border-rounded-10 m-t-10">
        <div className="card-title card-title-gray flex aligncenter p10 p-b-15" style={cardStyle}>
          <div className="flex aligncenter w-100p">
            <Link to="" id="head" className="content">
              <div className="w-100">
                <h4 className="fw700 card-heading">{data.title || data.record_title}</h4>
                <p className="f12">since {Cs.formatUpdateDate(scholarship.updated_at)}</p>
              </div>
            </Link>
          </div>
          <div className="menu-drop-icon flex justifycenter aligncenter">
          </div>
        </div>
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
            <b>{scholarship?.created_user?.name || 'Public'}</b>   
            <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          <ul className="flex aligncenter">
            <li className="m-r-25">
            </li>
          </ul>
          <props.menu item={scholarship} />
        </div>
      </div>
    )
  }

  if(!data)return null;

  return (<GenericContent/>)
    
}

export default Scholarship;