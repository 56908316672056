import React, {Fragment, useState, useEffect, useContext, useMemo} from "react"
import { Link, Redirect } from 'react-router-dom'
import querystringify from "querystringify"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import SubscriptionService from "./SubscriptionService"
import Cs from "../../services/CommonService"
import Tabs, { TabPane } from 'rc-tabs'
import SubscriptionAccessesService from '../Subscriptions/SubscriptionAccessesService'
import SubscriptionCompareTable from './SubscriptionCompareTable'
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"
import SignInAndSignUpBtn from "./../Marketing/List/SignInAndSignUpBtn"
import ConfirmSubscription from "../Modals/ConfirmSubscription"

const ProductList = (props) => {
  let {
    accountInformation, selectSubscription, isAuthenticated, 
    currentUser, queryParam, setLoading
  } = props

  const [subscriptions, setSubscriptions] = useState([])
  const [accessDataDources, setAccessDataSource] = useState({})

  const { isOpen:isSubscriptionCompareOpen, toggleModal:toggleSubscriptionCompare} = useModal()
  const { isOpen:isAuthModalOpen, toggleModal:toggleAuthModal } = useModal()

  useEffect(() => {
    getSubscriptions()
  }, [])

  const getSubscriptions = () =>{
    let req = {
      type_of_use: currentUser?.domain_info?.data?.type_of_use,
      journey_type: queryParam.journey_type
    }
    SubscriptionService.getSubscriptions(req).then((res)=>{
      let subscriptions = res.data.subscriptions
      /*subscriptions.forEach((s)=>{
        s.subscription_accesses = Cs.listToObj(
          s.subscription_accesses, 
          'data_source_list_id'
        )
      })*/

      setAccessDataSource(res.data.access_data_sources)
      setSubscriptions(subscriptions)
      setLoading(false)
    })
  }

  const planList = subscriptions && subscriptions.map((p, i)=>
    <Plan p={p} marketing={p.marketing_data} key={i} 
      accountInformation={accountInformation}
      selectSubscription={selectSubscription}
      accessDataDources={accessDataDources}
      isAuthenticated={isAuthenticated} 
      toggleAuthModal={toggleAuthModal} />
  )

  return(
    <Fragment>
      {/*<div className="underline font-16 text-right p-5 m-r-15 pointer" 
        onClick={e=>toggleSubscriptionCompare()}>
          Compare Subscription
      </div>
      
      <div className="row p-5">
        {planList} 
      </div>*/}
        
      <SubscriptionCompareTable
        accountInformation={accountInformation}
        selectSubscription={selectSubscription}
        isOpen={isSubscriptionCompareOpen}
        toggleModal={toggleSubscriptionCompare}
        subscriptions={subscriptions}
        accessDataDources={accessDataDources}
        isAuthenticated={isAuthenticated}
        isPopupMode="true" widthCss="w-98p"/>
       
      
      {isAuthModalOpen && 
        <GenericModal component={SignInAndSignUpBtn} title="CueTree" 
        toggleModal={toggleAuthModal}/>
      }

    </Fragment>
  )
}

const price = (list, sub) => list && list.map((p, i)=>{
  if(list.length == (i+1)){
    return(
      <li key={i}>For {p.min_journey} or more Active journeys - ${p.fee}/{sub.payment_type == 'month'?'Monthly':'Yearly'}</li>
    )
  }else{
    return(
      <li key={i}>Up to {p.max_journey} Active Journey - ${p.fee}/{sub.payment_type == 'month'?'Monthly':'Yearly'}</li>
    )
  }
})

const features = (marketing_data, list=[1,2,3,4,5]) => list && list.map((f)=>
  <li key={f}>{marketing_data['line_'+f]}</li>
)

const Plan = ({isAuthenticated, accountInformation, p, marketing, selectSubscription, accessDataDources, toggleAuthModal}) =>{
  /*const price = p.monthly_fee.split('.')*/
  /*${price[0]}<sup>.{price[1]}</sup>*/

  const [activeTab, setActiveTab] = useState()
  const [paymentType, setPaymentType] = useState()
  const [initialStageDataId, setInitialStageDataId] = useState()

  const {isOpen:isConfirmPlanModalOpen, toggleModal:toggleConfirmPlanModal} = useModal()

  const isActivePlan = p.id == accountInformation?.subscription_id

  const headerStyle = {
    'backgroundColor':(marketing.header || '#1AAD87'),
    'color':'white'
  }
  
  const bodyStyle = {
    'backgroundColor': isActivePlan ? '#1AAD87' :'black',
  }
  
  const activePlan = {
    'border': isActivePlan?'1px solid green':''
  }

  useEffect(()=>{
    const stageDsId = p.data.stage_data_source_id
    if(stageDsId){
      const stageList = accessDataDources[stageDsId]?.options || [] 
      setInitialStageDataId(stageList[0]?.pk_id)
    }
  }, [])

  const checkTab = (id) =>{
    setActiveTab(id==activeTab?null:id)
  }

  const routeUrl = () =>{
    toggleAuthModal()
  } 

  const confirmPlan = () => {
    selectSubscription(p)
  }

  const exists = (component_pk_id, component_data_source_id) =>{
    const i = p.subscription_accesses?.find(s => (
      initialStageDataId == s.level_data_source_list_id
      && s.data_source_id == component_data_source_id 
      && s.data_source_list_id == component_pk_id
    ))

    return i
  }

  return(
    <Fragment>
    <div className="col-xs-12 col-sm-4 col-md-4 p-10">
      <div className="pricing-4 round-medium shadow-small bg-white" style={activePlan}>
        {isActivePlan && <i className="fa fa-star white pull-left m-l-15 m-t-15"/>}
        <h1 style={headerStyle} 
          className="pricing-title center-text uppercase">
          {p.data.name}
        </h1>

        <div className="ht-400 scroll-auto p-10">
            <div className="black p-5 font-15 br-grey m-b-10 bl-61" 
              onClick={e=> checkTab('details')}>
                Details
                <i className={`fas ${activeTab == 'details' ? 'fa-chevron-up' : 'fa-chevron-down'} black pos-abs r-5 b-5`}/>
            </div>
            {activeTab == 'details' &&
              <Fragment>
                <ul className="pricing-list center-text">
                  <li className="font-16 font-500">
                    Subscription rate(USD): {p.data.fee}
                    per {p.data.interval == 'Monthly'?'month':'year'}
                  </li> 
                </ul>
                <ul className="pricing-list center-text">
                  <li className="font-16 font-500">
                    Minimum commitment period: {p.data.min_usage_period}
                    {p.data.interval == 'Monthly'?'month':'year'}
                  </li>
                </ul>
                <ul className="pricing-list center-text">
                  {features(marketing)}
                </ul>
                {p.is_discount_enabled && 
                  <ul className="pricing-list center-text lred">
                    <li>
                      <div className="font-16">Discount: {p.discount_percentage}%</div>
                      {p.discount_text}
                    </li>
                  </ul>
                }
              </Fragment>
            }
           
            <AccessComponents plan={p} activeTab={activeTab} 
              checkTab={checkTab} accessDataDources={accessDataDources}
              exists={exists}/>

            <h5 className="m-l-5">Select a payment option below</h5>
            
            <div className="m-t-10 pointer plan-my" onClick={()=>setPaymentType('yearly')}>
              <h2 className="h1 bold-700">
                {isAuthenticated && 
                  <span className={`fas ${paymentType=='yearly'?'fa-check-circle':'fa-circle'} lgrey m-r-15`}/>
                }
                ${p.data.annual_fee}
                <span className="font-13 font-400 m-l-2"> USD / Year</span>
                <div className="font-13 font-400 m-l-30">To be paid in advance annually</div>
              </h2>
            </div>
            
            <div className="m-t-15 pointer plan-my" onClick={()=>setPaymentType('monthly')}>
              <h2 className="h1 bold-700">
                {isAuthenticated && 
                  <span className={`fas ${paymentType=='monthly'?'fa-check-circle':'fa-circle'} lgrey m-r-15`}/>
                }
                ${p.data.monthly_fee}
                <span className="font-13 font-400 m-l-2"> USD / Monthly</span>
                <div className="font-13 font-400 m-l-30">To be paid in advance monthly</div>
              </h2>
            </div>

          </div>

        {isAuthenticated ?  
          <Fragment>
            {paymentType && 
              <>
                <div className="m-l-5 font-16 font-600 p-2 text-center">
                  By clicking select below, I agree to the <Link to="/subscription_terms"><u>Terms and Conditions</u></Link>
                </div>

                <div onClick={e=> isActivePlan ? '' : confirmPlan()} style={bodyStyle}
                  className="button button-xs button-center-large button-round-large uppercase m-b-10 m-t-15">
                  {isActivePlan ? "Active":"Select"}
                </div>
              </>
            }
          </Fragment>
          :
          <div onClick={e=>routeUrl()} className="button button-xs button-center-large button-round-large bg-black white uppercase m-b-10 m-t-15">Register Now</div>
        }
      </div>
      <div className="m-t-35"/>
    </div>
    {isConfirmPlanModalOpen &&
      <ConfirmSubscription isOpen={isConfirmPlanModalOpen} 
        toggleModal={toggleConfirmPlanModal} 
        onSuccess={confirmPlan} plan={p} isActivePlan={isActivePlan}/>
    }
    </Fragment>
  )
}

const AccessComponents = ({accessDataDources, checkTab, activeTab, plan, exists}) => Object.keys(accessDataDources).map((o, k) =>{
  const color = accessDataDources[o]?.settings?.bg_color || '#5E5E5E'
  const style = {borderLeft: `6px solid ${color}`}

  return(
    <Fragment key={k}>
      <div className="black p-5 font-15 br-grey m-b-10 m-t-10" 
        onClick={e=> checkTab(o)} 
        style={style}>
          {accessDataDources[o].label}
          <i className={`fas ${activeTab === k ? 'fa-chevron-up' : 'fa-chevron-down'} black pos-abs r-5 b-5`}/>
      </div>
              
      {(activeTab === o) && 
        <div className="popup-menu-grid br-grey">
          <Components plan={plan} list={accessDataDources[o].options} dataSourceId={o} exists={exists}/>
        </div>
      }
    </Fragment>
  )
})

const Components = ({plan, exists, dataSourceId, list=[]}) => list.map((o, k) =>{
  const checkedItem = exists(o.pk_id, dataSourceId)

  return(
    <Fragment key={k}>
      {checkedItem &&
        <div className="ver-icons">
          <span>
            <i className={o.icon || "fas fa-plus"} style={{color: o.icon_color}}/>
          </span>
          <div className="popup-menu-title">{o.label}</div>
          <div className="m-l-40 black-light-1">{o.description || o.detail}</div>
        </div>
      }
    </Fragment>
  )
})

export default ProductList;