import React, {useState, useEffect, Fragment, useContext} from "react"
import ReactDOM from 'react-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import { Link, useRouteMatch } from "react-router-dom"
import useModal from "../../hooks/useModal"
import HelpService from './HelpService'
import useStyle from '../../hooks/useStyle'
import env from '../../env'
import ConfirmMenu from "../Modals/ConfirmMenu"
import Cs from '../../services/CommonService'
import SortDirection from '../../components/Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc'
import arrayMove from '../Common/arrayMove'

let requestParams = {}
let selectedHelp = null

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt m-5 black"></span>)

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>
})

const HelpSideNav = (props) => {
  useStyle('help')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  const [helpIndex, setHelpIndex] = useState()
  const [helpList, setHelpList] = useState([])
  const [direction, setDirection] = useState('right')
  const [toggle, setToggle] = useState(true)
  const [hide, setHide] = useState(true)
  const [isSearchOpen, toggleSearch] = useState(false)
  const [loading, setLoading] = useState(true)

  const { isOpen:isDeleteOpen, toggleModal:toggleDeleteModal } = useModal()

  const match = useRouteMatch()
  const pageId = match.path.split("/:")[0]

  useEffect(() => {
    requestParams = {
      'page': 1,
      'paginate': 1,
      'per_page': 15,
      'sort_column': '',
      'sort_direction': 'desc',
    }

    getHelp()    
  }, [props.helpId, toggle])

  let getHelp = () => { 
    setLoading(true)
    setToggle(true)
    requestParams.page_url = pageId
    HelpService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setHelpList(res.data.helps)
        //setHelp(res.data.helps[0])
      }
    })
    setLoading(false)
  }

  const setPosition = (e) =>{
    e.preventDefault()
    let p = direction=='left'?'right':'left'
    setDirection(p)
  }

  const onDelete = (e, help) => {
    selectedHelp = help
    toggleDeleteModal(e)
  }

  const deleteHelp = (e) =>{
    e.preventDefault()
    HelpService.delete(selectedHelp.id).then((res)=>{
      if(res.status == 204){
        setHelpList(helpList.filter(r => r.record_id !== selectedHelp.record_id))
      }
    })
    toggleDeleteModal(e)
  }

  const toggleHelp = (i) =>{
    if(helpIndex == i){
      setHelpIndex(null)
    }else{
      setHelpIndex(i)
    }
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      console.log(helpList[newIndex].data.name)
      nextItemId = helpList[newIndex].id
    }else if(newIndex+1 == helpList.length){
      console.log(helpList[newIndex].data.name)
      priorItemId = helpList[newIndex].id
    }else if(oldIndex > newIndex){
      console.log(helpList[newIndex-1].data.name)
      console.log(helpList[newIndex].data.name)

      priorItemId = helpList[newIndex-1].id
      nextItemId = helpList[newIndex].id
    }else if(oldIndex < newIndex){
      console.log(helpList[newIndex].data.name)
      console.log(helpList[newIndex+1].data.name)

      priorItemId = helpList[newIndex].id
      nextItemId = helpList[newIndex+1].id
    }

    setHelpList(arrayMove(helpList, oldIndex, newIndex))
  }

  const SortableItem = sortableElement(({help}) => {
    return(
      <div className="p-5" style={{border: helpIndex == help.id ? '1px solid #00a2ff':''}} key={help.id}>
        <h5 className="pointer p-b-5 br-b-grey" onClick={e=>toggleHelp(help.id)}>
          {currentUser.current_admin.role_admin && <DragHandle/> }
          <i className={`fas ${help.id != helpIndex?'fa-angle-down':'fa-angle-up'}`}></i>
          &nbsp;{help.data.title}
          {env.admin_emails.includes(currentUser.email) &&
            <div className="font-16 ht-25 pull-right">
              <Link to={`/help/create/${help.id}`} className="pull-right">
                <i className="fa fa-edit "/>
              </Link>
              <a href="#" className="m-r-20 pull-right" onClick={e=>onDelete(e, help)}>
                <i className="fa fa-trash "/>
              </a>
            </div>
          }
        </h5>
        
        {helpIndex == help.id && 
          <Fragment>
            <div id="help_img" dangerouslySetInnerHTML={{ __html: help.data.help_detail }}></div>
          </Fragment>
        }
      </div>
  )})

  const helpListPanel =  helpList && helpList.map((help, i) => {
    let view = <SortableItem key={`item-${i}`} i={i} help={help} />

    /*let user_type = help.data.user_type_array || []
    if(env.admin_emails.includes(currentUser.email)){
      return view
    }else if(user_type.length == 0){
      return view
    }else if(user_type.indexOf(1)>-1 && sessionStorage.getItem('is_journey_admin') == 'true'){
      return view
    }else if(user_type.indexOf(2)>-1 && sessionStorage.getItem('is_journey_admin') == 'false'){
      return view
    }*/
    return <SortableItem key={`item-${i}`} index={i} help={help} />
  })

  if(helpList.length < 0)return null

  return(ReactDOM.createPortal(
    <Fragment>
      <div className={`col-xs-12 col-sm-10 col-md-10 col-lg-8 zIndex-99 ${hide ? 'help-side-nav':'help-side-nav-hide'}`} 
        style={{'right': direction=='right'?'unset':0,display:toggle? 'block' :'none'}}>
        <div className="help-header bg-highlight clearfix">
          <div className={`col-xs-12 help-icon ${ direction=='right'?'pull-right':'pull-left'}`}> 
            <p className="pull-left white">
              Help
            </p>

            <a href="#" className="m-r-20 pull-right" onClick={e=>{e.preventDefault();props.setIsHelpOpen(false);setToggle(!toggle)}}>
              <i className="fa fa-times white"/>
            </a>

            <a className="input-group m-r-20 pull-right" id="search_box">
              {isSearchOpen && <input type="text" placeholder="Search" className="form-control input-sm"/>}
              <span className="input-group-btn" onClick={e=>toggleSearch(!isSearchOpen)}>
                <p type="button" className="bg-highlight input-sm">
                  <i className="fa fa-search white"></i>
                </p>
              </span>
            </a>

            <a href="#" className="m-r-20 pull-right" onClick={e=>setPosition(e)}>
              <i className="fas fa-exchange-alt white"/>
            </a>

            <a href="#" className="m-r-20 pull-right" onClick={e=>{e.preventDefault();setHide(!hide);}}>
              <i className={`white ${hide ? 'fa fa-caret-down' : 'fa fa-caret-up'}`}/>
            </a>

            {env.admin_emails.includes(currentUser.email) &&
              <Link to={`/help/create?page=${pageId}`} className="m-r-20 pull-right">
                <i className="fas fa-plus white"/>
              </Link>
            }
          </div>
        </div> 
        
        {loading &&  <div className="spinner"/>}
        
        {hide &&
          <div className="row">
            <div className="scroll-y ht-420">
              <SortableContainer onSortEnd={onSortEnd} distance={1} useDragHandle>
                {helpListPanel}
              </SortableContainer>
            </div>
          </div>
        }
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteHelp}/>
    </Fragment>, document.body)
  )
}

export default HelpSideNav;