import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import useStyle from '../../hooks/useStyle'
import CommonService from "../../services/CommonService"
import useEscape from '../../hooks/useEscape'

const GenericModal = ({ component: Component, ...props }) => {
  useStyle('add_role_dialog')
  useStyle('filter_dialog')
  useEscape(props.toggleModal)
  
  return(
    ReactDOM.createPortal(
      <div className="ml-modal">
        <div className={`ml-modal-content ml-animate-zoom ${props.widthCss}`}>
          {props.title ?
            <div className={`flex justspacebetween modal-title ${props.HeaderColor ? props.HeaderColor:''}`}>
              <h4 className="f18 text-white fw500 p12-10-15">
                {props.title}
                {props.subtitle && <h5>{props.subtitle}</h5>}
              </h4>
              <button className="btn-close" onClick={e=>props.toggleModal(e)}>⨉</button>
            </div>
            :
            <button className="btn-close abs" onClick={e=>props.toggleModal(e)}>⨉</button>
          }
          {Component && <Component {...props} />}
        </div>
      </div>, document.body
    )
  )

}

export default GenericModal;