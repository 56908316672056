import React, { useState, useEffect, Fragment, useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import {NavIcon} from '../../../../Common/MenuItem'
import Pagination from '../../../../Pagination'
import JourneyPlannerService from '../JourneyPlannerService'
import PlannerGroupTable from "./PlannerGroupTable"
import DataGroupItemService from '../../../../DataGroups/DataGroupItemService'

let requestParams = {}

const PlannerGroupList = (props) => {
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext);

  const [groupList, setGroupList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    requestParams = {
      'data_source_params': {
        "data_source_id":[2216,2223,2224,2235,3250,3255,2240,3256,2244,2232,2233,2234,2256,2257,2258,2226,2227,2228,2230,2219,2220,2222,2221,2214,3124,3125,3126,3127,3128,3129,3130,3131,3132,3133,3134,3289,3320,2861],
        "response_type":"object"
      },
      'data':{},
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'exists_in_data_group':true,
      'planner_group':true
    }

    if(props.location.pathname.includes('admin') && currentUser.current_admin.role_content){
      requestParams.privacy_type = ['public', 'shared']
      requestParams.partnership_id = currentUser.domain_info.partnership_org_id
    }

    getGroupList()
  }, [props.searchString, currentpage])

  let getGroupList = () => { 
    setLoading(true)
    JourneyPlannerService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total;
        setGroupList(res.data.planner_lists);
        setLoading(false)
      }
    })
  }

  let updateSortOrder = (id, prior_item_id, next_item_id, group_id, success_callback) =>{
    setLoading(true)
    let req = {
      'group_id':group_id,
      'update_type': 'sort_order',
      'prior_item_id':prior_item_id,
      'next_item_id':next_item_id,
      'id':id
    }
    DataGroupItemService.update(req).then((res)=>{
      setLoading(false)
      if(res.status == 204){
        success_callback()
      }
    })  
  }

  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <Fragment>
      <PlannerGroupTable groupList={groupList} setGroupList={setGroupList} 
        getGroupList={getGroupList} requestParams={requestParams} 
        updateSortOrder={updateSortOrder} loading={loading}/>
    </Fragment>
  )
}

export default PlannerGroupList;