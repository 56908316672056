import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import NewsService from "../../../components/News/NewsService"
import FormContainer from '../../FormBuilder/FormContainer'

let formMode = null
let News = {} 
let data = {} 
let formFn = {} 

function NewsForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let currentUser = authState.user
  
  /*TODO: Move API to Router to load data before view*/
   const getById = (id) => {
    NewsService.get(id).then((res) => {
      if(res.status==200){
        News = res.data.cue_news
        data = News.data || {}
        setFormRendered(true)
      }
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions'
    data = {} 
    News = {} 
    formFn = {'form_type':'news', 'editPermission':true}
   
    if(props.match.path){
      if(props.match.path.includes('details')){
        getById(params.id)
        formMode = 'view-form-submissions'
      }else if(params.id){
        formMode = 'edit-form-submissions'
        getById(params.id)
      }else{
        setFormRendered(true)
      }
    }
  }, [])

  const create = (News) => {
    News.created_by = authState.user.id
    News.is_user_suggested = (params.form_id == FormTemplateId.NewsUserRegistration)
    News.is_active = !News.is_user_suggested
    News.form_id = params.form_id
    News.partnership_id = currentUser?.domain_info?.partnership_org_id

    NewsService.create(News).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    })
  }

  const update = (News) => {
    NewsService.update(News).then((res) => {
      if(res.status==204){
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = (id) => {
    if(params.form_id == FormTemplateId.NewsRegistration){
      props.history.push("/admin/news")
    }else if(!currentUser){
      props.history.goBack()
    }else{
      props.history.push("/news")
    }
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
         <DynamicForm formMode={formMode} formId={params.form_id} 
            form={News} data={data} formFn={formFn} onCreate={create} 
            onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
            setPost={setPost} />
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default NewsForm;