import React, {useState, Fragment, useContext, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../../hooks/useStyle';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CollegeService from "../../Colleges/CollegeServices";
import College from "./College"
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import CommonService from "../../../../../services/CommonService";
import querystringify from "querystringify";
import {NavIcon} from '../../../../Common/MenuItem'
import ReactTooltip from "react-tooltip";
import Suggestions from "../../HighSchoolPortalPage/Suggestions"

let requestParams = {};
let selectedCollege = null;

const CollegeDetails = (props) => {
  useStyle('card');
  useStyle('card_table');

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [college, setCollege] = useState();
  const [loading, setLoading] = useState(true);
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal();

  //let params = props.match.params;
  const {id,journey_category_id,journey} = props

  useEffect(() => {
    getCollege();
  }, []);


  let getCollege = () => { 
    setLoading(true)
    CollegeService.search({page:1, per_page:10, id: id, include_college_cue:true}).then((res)=>{
      if(res.status == 200){
        setCollege(res.data.colleges[0])
        setLoading(false)
      }
    })
  }

  const addComments = (college, e) =>{
    selectedCollege = college
    selectedCollege.id = selectedCollege.id
    toggleCommentModal(e)
  }

  const addChecklist = (college, e) =>{
    selectedCollege = college
    toggleChecklistModal(e)
  }

  if(loading)
    return(<div className="spinner"/>);

    return (
    <Fragment>
      <div className="page-content row">
        <div className="col-xs-12 col-md-6 p-7">
          <College viewType="bar_graph"  addComments={addComments} addChecklist={addChecklist} college={college} index="0" currentUser={currentUser}/>
        </div>
        <div className="col-xs-12 col-md-6 m-t-20">
            <div className="ml-center bg-lgreen white p-5">
              <div className="font-16 bold-600">Suggestions</div>
            </div>
            
          </div>
          
      </div>
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedCollege} type="college" journeyCategoryId={journey_category_id}/>}
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedCollege} type="college"/>}

    </Fragment>
  )
}

const IFrame = ({url}) =>{
  let u = url.includes('http')?url:`http://${url}`;
  return(
    <iframe src={u} height="100%" width="100%" />
  )
}

export default CollegeDetails;


/* 

<Suggestions history={props.history} journey={journey} journeyProfiles={journey.education_journey_profiles}
                suggestions={suggestions} dataSource={data_source} currentUser={currentUser}/>
                */