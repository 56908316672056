import React, { Fragment, useEffect, useState, useContext } from "react"
import useStyle from "../../hooks/useStyle"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import DigitalContentService from "../DigitalContent/DigitalContentService"
import env from "../../env"
import HamBar from "../Authentication/HamBar"
import Logo from "../Authentication/Logo"
import LottieLight from "../Common/LottieLight"
import { Link } from "react-router-dom"
import Footer from "../Footer/Footer"
import NewsletterSnippet from "../Authentication/NewsletterSnippet"
import SocialShareBtn from "../Common/SocialShareBtn"
import SideMenuBar from "../Common/SideMenuBar"
import {Button} from "../Marketing/Common"

let requestParamsServiceMarketing = {}

const MarketingList = (props) => {
  useStyle("spmarketing")
  useStyle("faq")
  useStyle("daily_dabble")

  const { state: currentUser, dispatch, isAuthenticated:isAuthenticated } = useContext(AuthContext)
  const [marketingList, setMarketingList] = useState([])

  /*window.addEventListener("scroll", () => {
    console.log(document.documentElement.scrollTop);
  })*/

  useEffect(() => {
    requestParamsServiceMarketing = {
      content_type: "service_marketing",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: null,
      data_source_filter: [
        {"key":"web_page.label","operator":"IN","value":"About us"},
        {"key":"content_type.label","operator":"=","value":"Service marketing tile"}
      ]
      //data_filter:[{key:'web_page' , operator:'@>' , value: props?.web_page || ''}]
    }
   
    getServiceMarketing()
  }, [currentUser.id])

  let getServiceMarketing = () => {
    DigitalContentService.getAll(requestParamsServiceMarketing).then((res) => {
      if (res.status == 200) {
        setMarketingList(res.data.digital_contents);
      }
    })
  }

  const marketingView = marketingList.map((n, k) => (
    <Fragment key={k}>
      <div className={`col-xs-12 col-sm-6 ${props.screen}`}>
        <div className="card content br-grey" style={{width:'265px', whiteSpace:'normal'}}>
          <div className="bg-black white p-10 font-16 ht-65">{n.data_source.section_heading.label}</div>
          <div className="ht-200 wk-center">
            <img src={n.data.image} className="m-t-5 p-10 img-responsive ht-200" alt="Sample Image"/>
          </div>
          <div className="p-10 ht-110">
            <h5 className="font-16">{n.data.title}</h5>
            <p className="m-t-10 lh-16">
              {n.data.sub_title}
            </p>
          </div>
          <div className="ht-50 text-center">
            <Button item={n}/>
          </div>
        </div>
      </div>
    </Fragment>
  ))
  

  return (
    <Fragment>
      {marketingView}
    </Fragment>
  )
}

export default MarketingList;