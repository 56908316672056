import { useState } from 'react';

const useNotification = () => {

  function showNotification(title, message) {
    Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
            let notification = new Notification(title, {
                body: message,
                icon: 'images/cueTree_logo.png'
            })
        }
    })
  }

  return {
    showNotification
  }

};

export default useNotification;