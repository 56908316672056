import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";

export const PortalPath = (props) => {
  let report = (props.statics && props.statics.report) || {};

  let p = { ...props.portalPath.options };

  if (report) {
    return (
      <Fragment>
        <div className="row margin-unset">
          {(p.planners || p.activities || p.calendars) && (
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="edu-path">
                <div className="head">PLAN</div>
                <div className="icons">
                  {p.planners && (
                    <div
                      className="three-half"
                      onClick={(e) => props.routePath(e, p.planners)}
                    >
                      <i className="fas fa-clipboard-check red" />
                      <div>Planners</div>
                      <span className="notification">
                        {report.planners}
                      </span>
                    </div>
                  )}
                  {p.activities && (
                    <div
                      className="three-half"
                      onClick={(e) => props.routePath(e, p.activities)}
                    >
                      <i className="far fa-sticky-note l-green" />
                      <div>Activities</div>
                      <span className="notification">
                        {report.activities}
                      </span>
                    </div>
                  )}
				  {p.calendars && (
                    <div
                      className="three-half"
                      onClick={(e) => props.routePath(e, p.calendars)}
                    >
                      <i className="far fa-calendar-check grey" />
                      <div>Calendars</div>
                      <span className="notification">
                        {report.schedules}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  } else {
    return <div></div>;
  }
};

export default PortalPath;
