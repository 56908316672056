import React, {useState, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";

const route = (f, openForm) =>{
  if(f.item_type === 'education_journal_entries'){
    return `/highschool/activity/edit/${f.journey_category_id}/${f.education_journey_profile_id}/${f.item_id}?form_template_id=${f.form_template_id}&unfilled_client_id=${f.unfilled_client_id}&goBack=1`
  }else if(f.item_type === 'career_journey.activity'){
    return `/career/activity/edit/${f.item_id}?form_template_id=${f.form_template_id}&unfilled_client_id=${f.unfilled_client_id}&goBack=1`
  }else{
    return `/college/review/${f.item_type}/${f.form_template_id}/${f.college_id}/${f.item_id}?form_template_id=${f.form_template_id}&unfilled_client_id=${f.unfilled_client_id}&goBack=1`  
  }
}

let EmptyFormFieldList = ({fields, openForm}) => fields ? fields.map((f, i) => {
  return(
    <div key={i} className="next-action-text br-btm-grey clearfix">
      <div className="col-xs-12 p10 d-flex">
        <div className="font-24">
          <i className="far fa-dot-circle grey"/>
        </div>
        <div className="m-l-15 w-100p">
          <h5 className="pointer lh-20 font-14">
            {openForm?
              <div className="darkblue" onClick={()=>openForm(f)}>{f.label}</div>
              :
              <Link className="darkblue" to={route(f)}>{f.label}</Link>
            }
          </h5>
          <div className="lh-18" dangerouslySetInnerHTML={{ __html: f.cue_prompt_description}}></div>
        </div>
        <div className="font-18 pull-right">
          <i className="fas fa-chevron-right lgrey m-l-10"/>
        </div>
      </div>
    </div>  
  ) 
}):null

export {EmptyFormFieldList};