import React, {Fragment, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../hooks/useStyle';
import useSort from '../../../../hooks/useSorting';
import SortDirection from '../../../../components/Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../../Common/arrayMove';
import CourseTopicsList from "./CourseTopicsList";
import TrainingModuleService from "../TrainingModules/TrainingModuleService"
import useModal from "../../../../hooks/useModal";

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

let requestParams = {}

const CoursePlannerTable = ({params, searchString, modules, setModules, requestParams, updateSortOrder, loading, setLoading}) => {
  useStyle('table');  
  const [currentTopic, setCurrentTopic] = useState();
  const [currentpage, setCurrentpage] = useState(1); 

  useEffect(() => {
    requestParams = {
      'page':currentpage,
      'paginate':1,
      'per_page':100,
      'sort_column':'mf.sort_order',
      'sort_direction':'asc',
      'totalItems':0,
      'add_planner_id':true,
      'exists_in_planner':true,
      'topic_exists_in_planner':true,
      'journey_category_id':params.journey_category_id,
      'search': searchString,
      'add_contents': true,
      'filter':'planner_sorting',
      'course_id':params.course_id
    }  
    getModules();
  }, [searchString, currentpage])

  let getModules = () => { 
    setLoading(true)
    setModules([])
    TrainingModuleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        var filteredModules = res.data.modules.filter(function (el) {
          return el.planner_id 
        });
        setModules(filteredModules)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getModules);

  const toggleTopic = (id) => {
    if(id === currentTopic){
      setCurrentTopic()
    }else{
      setCurrentTopic(id)
    }
  };

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      console.log(modules[newIndex].data.name)
      nextItemId = modules[newIndex].planner_id
    }else if(newIndex+1 == modules.length){
      console.log(modules[newIndex].data.name)
      priorItemId = modules[newIndex].planner_id
    }else if(oldIndex > newIndex){
      console.log(modules[newIndex-1].data.name)
      console.log(modules[newIndex].data.name)

      priorItemId = modules[newIndex-1].planner_id
      nextItemId = modules[newIndex].planner_id
    }else if(oldIndex < newIndex){
      console.log(modules[newIndex].data.name)
      console.log(modules[newIndex+1].data.name)

      priorItemId = modules[newIndex].planner_id
      nextItemId = modules[newIndex+1].planner_id
    }

    function onSuccess(){
      setModules(arrayMove(modules, oldIndex, newIndex))
    }
    updateSortOrder(modules[oldIndex].planner_id, priorItemId, nextItemId, onSuccess)
  }

  const SortableItem = sortableElement(({o, i}) =>{
    return(
      <tr>
        <td style={{backgroundColor:o.data.bg_color && o.data.bg_color || "green",color:"white"}}><DragHandle /></td>
        <td data-title="Id">{o.record_id}</td>
        <td data-title="Module" >{o.data.name}</td>
        <td data-title="Topics">
          <div>
            {o.topics && o.topics.length || 0} Topics
            {o.id == currentTopic ? <i className="m-l-5 m-t-5 fa fa-chevron-up pull-right" onClick={e=>toggleTopic(o.id)} aria-hidden="true"></i>:<i className="m-l-5 m-t-5 fa fa-chevron-down pull-right" onClick={e=>toggleTopic(o.id)} aria-hidden="true"></i>}
          </div>

          {o.id === currentTopic ? <CourseTopicsList o={o} topicList={o.topics} updateSortOrder={updateSortOrder} loading={loading}
          module={module} modules={modules} setModules={setModules} index={i} courseId={params.course_id}/>:
          <div>
          </div>}      
        </td>
      </tr>
    )
  })

  const tableData = modules.map((o, k) =>
    <SortableItem key={`item-${k}`} index={k} o={o} i={k}/>
  )

  if(loading)
    return(<div className="spinner"></div>)

  return (
    <div className="col-xs-12 m-t-10">
      <SortableContainer onSortEnd={onSortEnd} distance={1} lockAxis="y" useDragHandle>
        <div className="table-responsive p-0">
          <table className="shadow-small">
            <thead>
              <tr className="white bg-black">
                <th>Sort
                </th>
                <th>Id 
                  <SortDirection sort_column={requestParams.sort_column} column="id" reverse={requestParams.reverse}/> 
                </th>
                <th>Module 
                  <SortDirection sort_column={requestParams.sort_column} column="module" reverse={requestParams.reverse}/>
                </th>
                <th>Topic
                  <SortDirection sort_column={requestParams.sort_column} column="topic" reverse={requestParams.reverse}/>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </table>
        </div>
      </SortableContainer>
    </div>
  )

}

export default CoursePlannerTable;