import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import Cs from "../../../services/CommonService";
import CheckAccess from "./CheckAccess";

const Role = (props) => {
  let {role, role:{data} } = props;  
  role.updated_at = Cs.formatUpdateDate(role.updated_at)

  return (
    <Fragment>
      <div className="bg-white shadow-small p-7">
        <h4 className="vcard-title pull-left color-highlight bold">
          {role.role}
        </h4>
        <div className="user-role-list-left ht-300">
          <div className="col-md-12 col-xs-12">
            <span className="pull-right">{ role.role_users && role.role_users.length || 0} Users</span>

            <div className="color-highlight">
              {role.permissions && Object.keys(role.permissions).map((p)=>(
                <span className="pill br-groove-black bg-blue-white" key={p}>{props.permissions[p] || p.replace(/_/g, ' ')}&emsp;</span>
              ))}
            </div>

            <a className="black">{role.role_description}</a>
            <a className="black">
              <span className="pill br-groove-black bg-blue-white">{ role.access_type?.replace(/_/g, ' ')}&emsp;</span>
            </a>

            <div className="color-highlight">
              {role.access_list.length > 0 ? role.access_list.map((p)=>
                <span className="pill br-groove-black bg-blue-white" key={p}>{p}&emsp;</span>
              ): <span className="pill br-groove-black bg-blue-white" >Full Access&emsp;</span>}
            </div>

            <div className="role-list-scroll p-7">
              {role.role_users && role.role_users.map((u, i) => (
                <div className="float-left m-l-15" key={i}>
                  <i onClick={e=>props.deleteInviteUser(e, u, role)} className="pull-right fa fa-times lred" />
                  <img src="images/avatars/2s.png" alt="img" className="shadow-small bg-blue2-dark" />
                  <a className="profile-pic">{u.email.substring(0,5)}</a>
                </div> 
              ))}
            </div>

          </div>
        </div>
        <div className="card-footer clearfix">
          {CheckAccess.hasEditAccess(props.currentUser, props.journey, role) && <div>
            <div className="action-btn bg-highlight" onClick={e=>{props.restModalUser(e, role.record_id)}}>Add User</div>
            <div className="action-btn bg-highlight" onClick={e=>{props.setEdit(e, role)}}>Edit</div>
            <div className="action-btn bg-highlight" onClick={e=>{props.selectRole(role);props.setDelete(e, role)}}>Delete</div>
            </div>
          }
          <div className="col-xs-12">By <u>{role.user && role.user.name}</u> {role.updated_at}</div>
        </div>
      </div>
    </Fragment>
  )

}

export default Role;