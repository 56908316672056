import React, { useEffect, useState } from "react";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import DynamicForm from "../../../../../components/FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CoursePreparationsService from "../../../../../components/Journey/CourseJourney/CoursePreparations/CoursePreparationsService";
import FormContainer from '../../../../FormBuilder/FormContainer';

let formMode = null;
let preparation = {};
let data = {};
let formFn = {};

function CoursePreparationForm(props) {
  const { state: authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CoursePreparationsService.get(params.id).then((res) => {
      if (res.status == 200) {
        preparation = res.data.course_preparation;
        data = preparation.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      preparation = null;
    })
  }

  useEffect(() => {
    preparation = {};
    data = {};
    formFn = {
      form_type: "academic_courses.preparation",
      journey_category_id: params.journey_category_id,
    }
    formMode = "create-form-submissions";
    data = {};

    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (preparation) => {
    preparation.created_by = authState.user.id;
    preparation.journey_profile_id = params.journey_profile_id;
    preparation.journey_category_id = params.journey_category_id;
    preparation.privacy_type = 'private';
    preparation.course_form_id = FormTemplateId.CoursePreparationForm
    preparation.course_type = 'course'
    CoursePreparationsService.create(preparation).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    })
  }

  const update = (preparation) => {
    preparation.updated_by = authState.user.id;
    CoursePreparationsService.update(preparation).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(
      `/course/preparation/${params.journey_category_id}/${params.journey_profile_id}`
    )
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.CoursePreparationForm}  form={preparation} data={data} formFn={formFn}
          onCreate={create} onUpdate={update} post={post}  onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default CoursePreparationForm;