import React, { useState, useEffect } from "react"
import SubscriptionService from "./SubscriptionService"

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
)

export default function Checkout() {
  const [message, setMessage] = useState("")

  const query = new URLSearchParams(window.location.search)
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout

    if (query.get("success")) {
      getPortalPage()
      setMessage("Order placed! You will receive an email confirmation.")
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      )
    }
  }, [])

  const getPortalPage = () =>{
    let req = {'sessionId':query.get('session_id')}
    SubscriptionService.getCustomerPortal(req).then((res)=>{
      if(res.status == 200){
        window.location.replace(res.data.url);
      }else{

      }
    })  
  } 

  return message ? (
    <Message message={message} />
  ) : (
    null
  )

}

/*
Advantage:
apple pay
google pay

disadvantage
we dont have control over subscription cancel, update stripe provide ui
*/