import React, {useState, Fragment, useEffect, useContext} from "react"
import {Link} from "react-router-dom"
import moment from 'moment/min/moment.min'
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../Common/SortDirection'
import Cs from '../../../services/CommonService'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import UserFormSubmissionService from "../UserFormSubmissionService"
import JourneyTitle from "../../Journey/JourneyTitle/JourneyTitle"
import {NavIcon,CardMenuInfo,MenuItem,CardMainMenu} from '../../Common/MenuItem'
import useModal from "../../../hooks/useModal"
import GenericModal from "../../Modals/GenericModal"
import UserFormFieldService from "../UserFormFieldService"

let requestParams = {}
let selectedSubmission = {}

const FormSubmissions = (props) => {
  useStyle('table') 
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [submissions, setSubmissions] = useState([])
  const [form, setForm] = useState()
  const [formFields, setFormFields] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 
  const [loading, setLoading] = useState(true)
  const [pagemeta, setPagemeta] = useState([])

  let params = props.match.params

  useEffect(() => {
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'user_form_id':params.user_form_id
    }

    getFormSubmissions()

    return () => {
     requestParams = {} 
     selectedSubmission = {}
    }
  }, [props.searchString, currentpage]);

  let getFormSubmissions = () => { 
    setLoading(true)
    UserFormSubmissionService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setForm(res.data.user_form)
        setFormFields(res.data.user_form_fields)
        setSubmissions(res.data.user_form_submissions)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getFormSubmissions)
  
  /*const openAnswerPopup = (submission) =>{
    selectedSubmission = submission
    toggleAnswerPopup()
  }*/

  const FormData = ({submission}) => formFields.map((q, k) =>{
    return(
      <div key={k} className="bold-100">
        <span>{q.label} - </span>
        <span>
          {submission.data[q.record_id]}
        </span>
      </div>
    )
  })

  const tableData = submissions.map((a, k) =>{
    return(
      <tr key={k} className="bold-100">
        <td data-title="Id">{a.record_id}</td>

        <td className="font-16">
          {a.created_user.name}
        </td>
        
        <td>
          <FormData submission={a}/>
        </td>

        <td className="font-16">
          {Cs.formatUpdateDate(a.created_at, 'MMM DD, YYYY hh:mm a')}
        </td>

        <td className="font-16">
          {form.is_editable && 
            <Link to={`/form/submissions/${a.journey_category_id}/${a.journey_profile_id}/${a.user_form_id}/${a.id}`}>
              <i className="far fa-edit"/>
            </Link>
          }
        </td>
      </tr>
    )
  })

  if(loading){
    return(<div className="spinner"/>)
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-6">
          <JourneyTitle title={form.title}/>
        </div>

        <div className="col-xs-6 float-right">         
          <NavIcon id="back_icon" dataTip="Back to Assessment List" 
            onSelect={e=>props.history.goBack()}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-arrow-left"/>
        </div>
      </div>

    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')}>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('user')}>Student
              <SortDirection sort_column={requestParams.sort_column} column="users.name" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data')}>Data 
              <SortDirection sort_column={requestParams.data} column="data" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('created_at')}>Date 
              <SortDirection sort_column={requestParams.created_at} column="created_at" reverse={requestParams.reverse}/> 
            </th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
     
  </Fragment>
  )

};

export default FormSubmissions;