import React, {Fragment, useEffect} from "react";
import {Link} from "react-router-dom";
import useModal from '../../../../../hooks/useModal';
import Cs from '../../../../../services/CommonService';
import CardMenuIcon from "../../../../../components/Common/CardMenuIcon"
import CollegeInfoBar from "./CollegeInfoBar"
import ProjectMenu from "../../../../Modals/ProjectMenu";
import {MenuItem} from '../../../../Common/MenuItem'
import BarChart from "../../../../Charts/BarChart"
import {Timeline} from '../TimeLineComponent/Timeline'
import Tabs, { TabPane } from 'rc-tabs'
import ReactTooltip from "react-tooltip"

const College = (props) => {
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  let {college, addComments, addChecklist, addToFavourite, index, favCollegeIds, currentUser} = props;
  college.updated_at = Cs.formatUpdateDate(college.updated_at);
  const showTrends = college.college_cue?.application_notes && college.college_cue?.research

  const cardStyle = {
    height: '420px',
    borderTop: '5px solid #607D8B'
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const EmptyFieldCard = ({fieldName}) =>(
    <Fragment>
      <div className="col-xs-6">
        <div className="font-16 lgrey p-30">{fieldName} not available for display</div>        
      </div>
    </Fragment>
  )

  const MenuItems = () =>{
    return(
      <Fragment>
        {props.viewType != 'bar_graph' ? <MenuItem id="fav_icon"
          icon="far fa-heart"
          action="Add Favorites"
          onSelect={(e) => {addToFavourite(college, e);toggleMenuModal(e)}}/> :''}
      </Fragment>
    )
  }

  const GraphContainer = () => {
    let colors = ['#77B6EA']
    let height = 400
    let markers = null
      
    let series = [{
      data: [
        college.act_scores, 
        college.sat_scores, 
        college.tution_cost_in_state, 
        college.tution_cost_out_of_state, 
        college.student_size],
    }]

    let title = {
      text: '',
      align: 'center',
      floating: true
    }

    let x_axis = ['ACT Score', 'SAT Score', 'In-State', 'Out-of-State', 'Student Size']

    return(
      <Fragment>
        <BarChart series={series} x_axis={x_axis} title={title}
          y_axis_label="College" colors={colors}
          x_axis_label="Admissions" markers={markers}
          className="clearfix" chart_type="bar" 
          id="college" height={height}/>
      </Fragment>
    )
  }

  const CollegeView = () =>(
    <Fragment>

      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5 bg-highlight">
          <div className="font-16 lh-18 ellipsis">
            {college.name}
            <a className="pull-right white link" target="_blank" href={college.school_url}>
              <i className="fas fa-share"></i>
            </a>
          </div>
          <div>{college.state}, {college.city} <span className="m-l-15">{college.locale}</span></div>
        </div>

        <div className="ht-400 scroll-auto">
          <div className="row ht-100 br-btm-grey">
            {college.act_scores ? <Fragment>
              <div className="col-xs-6">
                <CollegeInfoBar leftLabel="ACT" rightLabelString="36" index={index} name="act"
                  maxScoreString="36" earnedScore={college.act_scores} barClass="btn-success">
                </CollegeInfoBar>
                <div className="clearfix"></div>
              </div>
            </Fragment>: <EmptyFieldCard fieldName="ACT Score" />}
                
            {college.sat_scores ? <Fragment>
              <div className="col-xs-6">
                <CollegeInfoBar leftLabel="SAT" rightLabelString="1600" maxScoreString="1600"
                  earnedScore={college.sat_scores} index={index} name="sat"
                  barClass="btn-info">
                </CollegeInfoBar>
                <div className="clearfix"></div>
              </div>
            </Fragment>: <EmptyFieldCard fieldName="SAT Score"/>}
          </div>  
       
          <div className="row ht-100 br-btm-grey">
            {college.tution_cost_in_state ? <Fragment>
              <div className="col-xs-6">
                <CollegeInfoBar leftLabel="In-State" index={index}
                  rightLabelString="$73,132" maxScoreString="73132" name="in_state" 
                  earnedScore={college.tution_cost_in_state} barClass="btn-purple" filterName="currency">
                </CollegeInfoBar>
                <div className="clearfix"></div>
              </div>
            </Fragment>
            : <EmptyFieldCard fieldName="In-State Score"/>}
                
            {college.tution_cost_out_of_state ? <Fragment>
              <div className="col-xs-6">
                <CollegeInfoBar leftLabel="Out-of-State" index={index}
                  rightLabelString="$73,132" maxScoreString="73132" name="out_state"
                  earnedScore={college.tution_cost_out_of_state} barClass="btn-pink" filterName="currency">
                </CollegeInfoBar>
                <div className="clearfix"></div>
              </div>
              </Fragment>
              : <EmptyFieldCard fieldName="Out-of-State Score"/>}
          </div>
        </div>

        <div className="card-footer p-5">
          <div className="m-r-5 pull-left font-bold">
            <span className="m-l-5" id={`check_list_${props.index}`}
              onClick={(e) => {addToFavourite(college,e)}}>
              <i className="far fa-heart font-18 text-muted" data-tip="Likes"></i>
              <span className="badge up bg-lgrey">{college.favourites_count}</span>
            </span>
          </div>
          <div className="pull-right">
            <div className="p-b-4 m-r-5">
              <i onClick={e=>toggleMenuModal(e)} className="fas fa-bars font-16" />
            </div>
          </div>
        </div>

      </div>
      <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} MenuItems={MenuItems}/>
    </Fragment>
  )

  const CollegeBarView = () => (
    <Fragment>

      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5 bg-highlight">
          <div className="font-16 lh-18 ellipsis">
            {college.name}
            <a className="pull-right white link" target="_blank" href={college.school_url}>
              <i className="fas fa-share"></i>
            </a>
          </div>
          <div>{college.state}, {college.city} <span className="m-l-15">{college.locale}</span></div>
        </div>

        <div className="ht-400 scroll-auto">
          <Tabs defaultActiveKey={'1'}>
            <TabPane tab="Trends" key="1">
            <div className="col-xs-12 ht-300 br-btm-grey">
                {showTrends ? <GraphContainer/> : <h5 className="text-center black m-t-10">To view the Trends charts, please first fill and submit the <br/>
                  <Link className="underline" to={`/college/review/research/1001/${college.id}/${college.college_cues?.id || ''}`}>Research and Rate
                  this College</Link> and <Link className="underline" to={`/highschool/college/review/application_notes/1003/${college.id}/${college.college_cues?.id || ''}`}>Organize College Application</Link> items.</h5>}
              </div>
            </TabPane>
            <TabPane tab="Scores" key="2">
            </TabPane>
            <TabPane tab="Info" key="3">
            </TabPane>
          </Tabs>
        </div>

        <div className="card-footer p-5">
          <span className="m-l-5" id={`comment_${props.index}`}
            onClick={(e) => {addComments(college,e)}}>
            <i className="far fa-comment font-18 text-muted" data-tip="Chat with users you invite"></i>
            <span className="badge up bg-lgrey">{college.comments_count || 0}</span>
          </span>
          <span className="m-l-25" id={`check_list_${props.index}`}
            onClick={(e) => {addChecklist(college,e)}}>
            <i className="far fa-check-circle font-18 text-muted" data-tip="Manage checklists with users you invite"></i>
            <span className="badge up bg-lgrey">{college.check_list_count || 0}</span>
          </span>
            <span className="m-l-25" id={`mail_list_${props.index}`}>
              <i className="far fa-envelope font-18 text-muted" data-tip="View saved emails about this college"></i>
              <span className="badge up bg-lgrey">{0}</span>
            </span>
          
            <span className="m-l-25">
              <i className="far fa-lightbulb font-18 text-muted" data-tip="View cueTree suggestions"></i>
              <span className="badge up bg-lgrey">{college.unfilled_field_count || 0}</span>
            </span>

            <span className="m-l-25" id={`favourites_count_${props.index}`}>
              <i className="far fa-heart font-18 text-muted" data-tip="Likes"></i>
              <span className="badge up bg-lgrey">{college.favourites_count || 0}</span>
            </span>
          <div className="pull-right">
            <div className="p-b-4 m-r-5">
              <i onClick={e=>toggleMenuModal(e)} data-tip="Click to access menu" className="fas fa-bars font-16" />
            </div>
          </div>
        </div>  

      </div>
      <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} MenuItems={MenuItems}/>
    </Fragment>      
  )
  
  if(props.viewType == 'bar_graph'){
    return <CollegeBarView/>
  }else{
    return <CollegeView/>    
  }

}

export default College;