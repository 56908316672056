import React, {Fragment, useEffect} from "react";
import useStyle from '../../hooks/useStyle';

const NotificationPopup = (props) => {
  useStyle('dashboard')      
  let iconClass = props.iconClass || "fa fa-check bg-green1-dark success-ico"

  /*useEffect(()=>{
    <span id="btn-ico" className="btn-ico fa"></span>
    let ele= document.getElementById("btn-ico");
    function hand() {
      ele.innerHTML = "&#xf25a;";
      setTimeout(function () {
        ele.innerHTML = ele.innerHTML == '&#xf25a;'?"&#xf259;":"&#xf25a;";
      }, 1000);
    }
    hand();
  }, [])*/

  return(
    <div className="col-xs-12 m-t-10">
        <div className="card card-style">
          <div className="success d-flex">
            <div>
              <h3><i className={`${iconClass}`}/></h3>
            </div>
            <div className="success-txt">
              <h3>{props.header || 'Success'}</h3>
              <p dangerouslySetInnerHTML={{ __html: props.message }}></p>
              {props.children}
            </div>
          </div>
          <div className="clearfix">
            {props.onSuccess && <span onClick={e=>props.onSuccess()} className="success-btn">
                OK
              </span>
            }
          </div>  
        </div>
    </div>
  )
}

export default NotificationPopup;