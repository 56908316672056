import React, { useState, useEffect, Fragment, useContext} from "react";
import { Link } from "react-router-dom";
import {PortalHeader} from "./PortalHeader";
import PortalPath from "./PortalPath";
import JourneyCategoryService from "../../JourneyCategoryService";
import useStyle from "../../../../hooks/useStyle";
import CheckAccess from "../../Roles/CheckAccess";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import CareerList from "../InterViews/CareerList"

let portalPath = null;
let userTypeList = null;
let activeJourney = null;

const PortalDetails = (props) => {
  useStyle("panel");
  useStyle("path");

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  let params = props.match.params;
  
  const [journeyCategory, setJourneyCategory] = useState({});
  const [statics, setStatics] = useState({});
  const [share, setShare] = useState({});

  useEffect(() => {
    let req = {
      journey_category_id: params.journey_category_id,
      show_journey_templates: false,
      data_source_params: {
        data_source_id: [3418, 2541], //Individual journey data_source
        /*"response_type":"object",
        "group_by":"sid"*/
      }
    }

    JourneyCategoryService.getJourneyById(req).then((res) => {
      if (res.status == 200) {
        res = res.data;
        res.journey_category = res.journey_category[0];
        portalPath = res.data_source_list.undergrad_college;
        userTypeList = res.data_source_list.fj_i_am;
        CheckAccess.userAccess(currentUser, res.journey_category, res.invited_journey_member)
        setJourneyCategory(res.journey_category);
        setStatics(res.statics);
        setShare(res.invited_journey_member);
        activeJourney = res;
      }
    })
  }, []);

  const routePath = (e, path) => {
    //3354
    e.preventDefault();
    if (path.sid == "planners") {
      props.history.push(
        `/college/planners/${params.journey_category_id}/${journeyCategory.education_journey_profiles.id}`,
        journeyCategory
      )
    } else if(path.sid == 'roles'){
      props.history.push(`/journey/role/${params.journey_category_id}/${journeyCategory.education_journey_profiles.id}`, journeyCategory)
    } else if (path.sid == "edu_resources") {
      props.history.push(
        `/college/resources/${params.journey_category_id}/${journeyCategory.education_journey_profiles.id}`,
        journeyCategory
      )
    }else if (path.sid == "activities") {
      props.history.push(
        `/college/activity/type/${params.journey_category_id}/${journeyCategory.education_journey_profiles.id}`,
        journeyCategory
      )
    } else if (path.sid == "invite_users") {
      props.history.push(
        `/invite/user/${journeyCategory.id}/${journeyCategory.education_journey_profiles.id}`,
        {journey:journeyCategory, share:share}
      )
    } else if (path.sid == "calendars") {
      props.history.push(
        `/college/schedules/${journeyCategory.id}/${journeyCategory.education_journey_profiles.id}/college`,
        journeyCategory
      )
    }else if (path.sid == "interviews") {
      props.history.push(
        `/college/interviews/${journeyCategory.id}/${journeyCategory.education_journey_profiles.id}`,
        journeyCategory
      )
    }else if (currentUser.current_admin.role_master && path.sid == 'path') {
      props.history.push(
        `/college/path/${journeyCategory.id}/${journeyCategory.education_journey_profiles.id}`,
        journeyCategory
      )
    }else if (path.sid == "favorite_colleges") {
      props.history.push(
        `/college/favourite/colleges/${journeyCategory.id}/${journeyCategory.education_journey_profiles.id}`,
        journeyCategory
      )
    }
  };

  if (!journeyCategory.id) return <PreLoadSpinner />;

  return (
    <Fragment>
      <div className="p-5">
        <div className="portlet">
          <PortalHeader journeyCategory={journeyCategory} statics={statics} share={share}
            userTypeList={userTypeList} app={props.app}/>
          <div id="bg-info" className="panel-collapse collapse in" style={{ minHeight: "260px" }}>
            <div className="portlet-body">
              <h5>Shape your journey through college your way.</h5>
              <PortalPath currentUser={currentUser} portalPath={portalPath} statics={statics}
                routePath={routePath} share={share} journeyCategory={journeyCategory}/>
              <small className="font-12 p-5 lh-16">3327</small>
            </div>
          </div>
        </div>
      </div>
      <SideMenuBar helpId="3" app={props.app} />
    </Fragment>
  )
  
}

export default PortalDetails;