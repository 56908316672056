import React, { Fragment, useState, useEffect, useContext,useRef } from 'react'
import ReactDOM from 'react-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommentsService from '../../services/CommentsService'
import Cs from '../../services/CommonService'
import useStyle from '../../hooks/useStyle'
import Pagination from '../Pagination'
import useEscape from '../../hooks/useEscape'

let comment = {}
let requestParams = null

const FeedModal = ({ item, type, isOpen, toggleModal, showInline}) => {
  useStyle('chat')
  useStyle('add_role_dialog')
  useStyle('panel')
  useEscape(toggleModal)
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [feedsList, setFeedsList] = useState([])
  const [loading, setLoading] = useState(true)
  const listInnerRef = useRef()
  
  useEffect(() => {
    requestParams = {
      'apikey': 'pub_710c9d684264c05b0a97dc5aa16777e4533',
      'category': 'sports',
      'language':'en'
    }
      getNotesList()
  }, [])

  let getNotesList = (is_lazy_loaded) => {
    setLoading(true)
    CommentsService.getAllFeeds(requestParams).then((res) => {
      if (res.status == 200) {
        setLoading(false)
        setFeedsList(res.data.results)
      }
    })
  }


  const feedsItem = feedsList && feedsList.map((c, k) =>
    <Fragment key={k}>
      <div id={`comment_${c.id}`} className="speech-bubble col-xs-12">
        <span className="portlet-dropdown">
            <a data-tip="Journey Menu" className="p-dropbtn"><i className='fas fa-caret-down'></i></a>
            <div className="p-dropdown-comment">
              <a>
                <i className="fas fa-eye" aria-hidden="true"></i> Bookmark
              </a>
              <a>
                <i className="fas fa-edit" aria-hidden="true"></i> Add as step Activity
              </a>
            </div>
        </span>
        <div className="font-16 bold ellipsis">{c.title}</div>
        <div className="font-12">{c.description}</div>
        <a href={c.link} target="_blank">Link</a>
      </div>
    </Fragment>
  )

  const FeedBox = () =>(
    <Fragment>
      <div className="flex justspacebetween modal-title">
        <h4 className="f18 text-white fw500 p12-10-15">Feeds</h4>
        {!showInline &&
          <button className="btn-close"
            title="Close Modal" onClick={e=>toggleModal(e)}>×
          </button>
        }
      </div>

      <div className="bottom-0 chat-main" id="chat-main" style={{height:screen.height-300}} ref={listInnerRef}>
        <div id="first_comment" className="m-t-5"></div>
        {feedsItem.length==0 && 
          <div className="no-chat font-15">This Activity Tile has no feeds yet.</div>
        }
        {loading ? <div className="spinner"></div> : feedsItem}
        <div id="last_comment" className="m-t-5"></div>
      </div>
    </Fragment>    
  ) 

  if(showInline){
    return(
      <div className="m-t-15">
        <div className="ml-card-4 bg-white">
          <FeedBox/>
        </div>
      </div>
    )
  }

  return(
    ReactDOM.createPortal(
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom bg-white">
          <FeedBox/>
        </div>
      </div>, document.body
    )
  )
}

export default FeedModal;