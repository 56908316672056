import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";
class DataGroupItemService extends BaseService {
  
  /*getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(item){
    return new Promise((resolve, reject) => {
      axios.post(url, item).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(item) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${item.id}`, item).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }*/

}

export default new DataGroupItemService('backend', 'data_group_items');
