import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../../../hooks/useStyle";
import CommonService from "../../../../../services/CommonService";
import useEscape from "../../../../../hooks/useEscape";

let requestParams = {};

const ActivityTypeMenu = ({futureCareer, menuType, journey_category_id, journey_profile_id, params, isOpen, toggleModal,}) => {
  
  useStyle("add_role_dialog");
  useStyle("filter_dialog");
  useEscape(toggleModal);

  const history = useHistory();  
  const {state: { screen, user: currentUser },dispatch,} = useContext(AuthContext);
  const [activityCategory, setActivityCategory] = useState();

  useEffect(() => {
    requestParams = {
      data_source_id: [3438],
    }
    getActivityCategory()
  }, [])

  let getActivityCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      let d = CommonService.groupBy(res.career_j_activity_cat.options, "actions")
      setActivityCategory(d);
    })
  }

  const onSelect = (k) =>{
    if(menuType == "planner"){
      history.push(`/career/planner/form/${k.label}/${k.Planner_form}`)
    }else{
      history.push(`/career/activity/form/${journey_category_id}/${journey_profile_id}/${k.label}/${k.child_form}?form_wizard_id=${k.wizard_id}&occupation_group=${futureCareer.occupation_group}&career=${futureCareer.label}&career_ds_fk_id=${futureCareer.id}`)
    }
  }

  const List = (key) => activityCategory[key].map((k, i) => (
    <Fragment key={i}>
      <div className="ver-icons" onClick={e=>onSelect(k)}>
        <span>
          <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
        </span>
        <div className="popup-menu-title">{k.label}</div>
        <i className="fas fa-chevron-right lgrey pull-right" />
      </div>
    </Fragment>
  ))

  let ActivityGroup = () => activityCategory && Object.keys(activityCategory).length>0 ? Object.keys(activityCategory).map((n, i) =>
    <div className="popup-menu-grid" key={i}>
      <div className="head" key={i}>{n}</div>
      {List(n)}
    </div>
  ): <span>None</span>

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom" style={{width: '400px'}}>
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select an item</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>

          {activityCategory && (
            <div className="p-5 scroll-x" style={{height:screen.height-120}}>
              <ActivityGroup/>
              <small className="font-9 lgrey lh-16 p-5">3438</small>
            </div>
          )}
        </div>
      </div>
    </Fragment>, document.body
  )
}

export default ActivityTypeMenu;