import React, {useState, useEffect, Fragment} from 'react'
import ReactTooltip from 'react-tooltip'
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Link} from "react-router-dom"
import JourneyCategoryService from "../../JourneyCategoryService"
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle"
import AddEvent from "../../HighSchoolJourney/Calendars/AddEvent"
import useModal from '../../../../hooks/useModal'
import useStyle from '../../../../hooks/useStyle'
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import {NavIcon} from '../../../Common/MenuItem'
import Cs from "../../../../services/CommonService";
import {TeamLink} from "../../../Common/RouteLinks"

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
let newEvent = null;

let TeamSchedules = (props) => {
  let params = props.match.params;
  let project = props.location.state;

  useStyle('add_role_dialog');

  const [eventsList, setEventsList] = useState([]);
  const [isNewEventPopupOpen, setNewEventPopupOpen] = useState(false);

  let events = [];

  useEffect(() => {
    let req = {
      'journey_type':params.journey_type,
      'journey_category_id':params.journey_category_id,
      'education_journal_entry_params':{
        'page': 1,
        'paginate': 1,
        'per_page': 10,
        'sort_column': 'updated_at', 
        'sort_direction': 'desc',
        'journey_category_id': params.journey_category_id,
        'education_journey_profile_id': params.journey_profile_id
      },
      'data_source_params':{
        'data_source_id': 2214
      }
    }

    JourneyCategoryService.getSchedules(req).then((res)=>{
      if(res.status==200){
        res.data.education_schedules.forEach((e, i)=>{
          if(e.start){
            /*e.start = Cs.stringToDate(e.start)
            e.end = e.end?moment(e.end).toDate():e.start */
            events.push({
              'id':i,
              'title':e.title,
              'start': Cs.stringToDate(e.start),
              'end': Cs.stringToDate(e.end || e.start),
              'allDay':true,
              'item_id':e.item_id,
              'item_type':e.item_type
            })
          }
        })
        setEventsList(events);
        /* config.events = res.data.education_schedules;
        $scope.activities= res.data.activities;
        $scope.journalEntryTypes= res.data.data_source_list[0].options;*/
      }
    })
  }, []);

  const onSelectEvent = (event) =>{
    if(event.item_type == 'edu_team_journey.activity'){
      props.history.push(`/team/activity/form/${params.journey_category_id}/${params.journey_profile_id}/null/null/${event.item_id}`)
    }else if(event.item_type == 'edu_team_journey.step_activity'){
      props.history.push(`/team/step_activity/form/${params.journey_category_id}/${params.journey_profile_id}/null/null/null/${event.item_id}`)
    }
  }

  const onSelectSlot = (slotInfo)=>{  
    newEvent = {
      'schedule_item_id': params.journey_profile_id, 
      'schedule_item_type': 'education_journey_profile',
      'journey_category_id': params.journey_category_id,
      'start_date': moment(slotInfo.start).format("YYYY-MM-DD"),
      'data':{}
    }
    setNewEventPopupOpen(true)
  }

  const addNewEvent = (e, event)=>{
    JourneyCategoryService.createSchedule(event).then((res)=>{
      if(res.status==201){
        let e = res.data;
        if(e.start_date){
          /*e.start = moment(e.start_date).toDate()
          e.end = e.end_date?moment(e.end_date).toDate():e.start;*/
          let d = {
            'id':e.id,
            'title':e.data.title,
            'start': Cs.stringToDate(e.start),
            'end': Cs.stringToDate(e.end || e.start),
            'allDay':true,
            'item_id':e.item_id,
            'item_type':e.item_type
          }
          setEventsList([d].concat(eventsList))
        }     
      }
    })
    setNewEventPopupOpen(false)
  }

  if(eventsList.length==0)return null;

  return(
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Team Schedules"} project={project} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="back_home" dataTip="Back to Journey Home" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            link={TeamLink.team_portal(params)}
            icon="fas fa-home"/>
        </div>            
        <ReactTooltip place="left" />
      </div>

      <div className="p-5 bg-white">
        <Calendar 
          localizer={localizer}
          events={eventsList}
          startAccessor="start"
          endAccessor="end" 
          selectable
          onSelectEvent={(event)=>onSelectEvent(event)}
          onSelectSlot={(slotInfo)=>onSelectSlot(slotInfo)}/>
      </div>

      {isNewEventPopupOpen &&
        <AddEvent newEvent={newEvent} isOpen={isNewEventPopupOpen} toggleModal={setNewEventPopupOpen} addNewEvent={addNewEvent}/>
      }
    </Fragment>
  )
}

export default TeamSchedules