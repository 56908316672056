import React, {useState, Fragment, useEffect, useContext, useRef} from "react";
import {Link} from "react-router-dom";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Activity from '../../Activities/List/Activity';
import ActivityService from "../../Activities/ActivityService";
import FavouritesService from "../../../../../services/FavouritesService";
import Pagination from '../../../../Pagination'
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from '../../../../../constants/FormTemplateList';
import CommentsModal from "../../../../Modals/CommentsModal";
import FeedModal from "../../../../Modals/FeedModal";
import CardMenu from "../../../../Modals/CardMenu"
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import useStyle from '../../../../../hooks/useStyle';
import CheckAccess from '../../../Roles/CheckAccess';
import ActivityTypeMenu from "../../Activities/List/ActivityTypeMenu";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal"
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import {usePageTour} from "../../../../../components/Common/usePageTour"
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import HelpMessageList from '../../../../../constants/HelpMessageList'
import {MenuItem,CardMenuInfo,NavIcon,CardMainMenu} from '../../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import CommonService from "../../../../../services/CommonService";
import ReactTooltip from "react-tooltip";
import Suggestions from "../../HighSchoolPortalPage/Suggestions"
import Tabs, { TabPane } from 'rc-tabs'
import ShareItemModal from "../../../UserRoles/ShareItemModal"
import {HighSchoolLink} from "../../../../Common/RouteLinks"
import ActivityForm from "../Form/ActivityForm"
import GenericModal from "../../../../Modals/GenericModal"

let requestParams = {}
let selectedActivity = {}
let dataSource = {}
let journeyCategory = null
let cardViewCount = 1

const ActivityList = (props) => {
  // useStyle('card');
  // useStyle('card_table');
  // useStyle('add_role_dialog');
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
 
  let cardGridCss = "col-xs-12 col-sm-6 col-md-8";
  let cardHeightCss = "ht-400";
  const showIcons = params.journey_category_id != null;

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [journeyActivityList, setJourneyActivityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState();
  const [isFavourite, setIsFavourite] = useState(false); 
  const [cueSuggestions, setCueSuggestions] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [editableField, setEditableField] = useState();

  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isFeedOpen, toggleModal:toggleFeedModal } = useModal()
  const { isOpen:isActivityTypeOpen, toggleModal:toggleActivityTypeModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isFileLinkOpen, toggleModal:toggleFileLinkModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const { isOpen:isShareItemOpen, toggleModal:toggleShareItem } = useModal()
  const { isOpen:isActivityFieldOpen, toggleModal:toggleActivityFieldModal } = useModal()

  const indexEle = useRef(null);

  let tour = usePageTour();

  if (queryParam.id || props.id) {
    cardGridCss = "col-xs-12 p-0";
    cardHeightCss = "mih-380";
    queryParam.id = queryParam.id || props.id;
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    journeyCategory = null;
    requestParams = {
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'last_modified_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'journey_category_id':params.journey_category_id,
      'education_journey_profile_id':params.journey_profile_id,
      'id':queryParam.id,
      'data': {},
      'is_favourites':isFavourite,
      'filter_by_role':false,
      'load_form_fields_in_result_set':true,
      'data_source_params': {
        "data_source_id":[3344 ,2216, 2223, 2224, 2235, 2861, 3250, 3255, 2240, 3256, 2244, 2232, 2233, 2234, 2256, 2257, 2258, 2226, 2227, 2228, 2230, 2219, 2220, 2222, 2221, 2214, 3125, 3126, 3127, 3128, 3129, 3130, 3131, 3132, 3133, 3134, 3289, 3124, 3410],
       // "response_type":"object"
      }
    }

    getJourneyActivityList()
    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage, isFavourite, props.location])

  let getJourneyActivityList = () => { 
    setLoading(true)
    ActivityService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.meta.data_source_list;
        //setPagemeta(res.data.meta)
        requestParams.totalItems = res.data.meta.total;
        journeyCategory = res.data.journey_category;
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount);
        CheckAccess.userAccess(currentUser, journeyCategory, res.data.invited_journey_member);
        if(requestParams.search){
          setJourneyActivityList(res.data.education_journal_entries)
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setJourneyActivityList(res.data.education_journal_entries);
        }else{
          setJourneyActivityList([...journeyActivityList, ...res.data.education_journal_entries]);  
        }
        setLoading(false)
        setCueSuggestions(res.data.cue_suggestions)
        setFormFields(res.data.form_fields);
        res.data.education_journal_entries.length == 0 && toggleHelpMessage()
        /*if(currentpage==1){
          setTimeout(function() {
            tour && tour.start()
          }, 1000);
        }*/
      }
    })
  }

  const onActivitySubmit = () =>{

  }

  const editActivityField = (field) =>{
    setEditableField(field)
    toggleActivityFieldModal()
  }

  const getProps = () =>{
    return {
      component:ActivityForm,
      toggleModal:toggleActivityFieldModal,
      onSubmit:onActivitySubmit,
      match:{params:{
        journey_profile_id: params.journey_profile_id,
        id: queryParam.id
      }},
      location:{
        search: `?form_template_id=${editableField.id}&unfilled_client_id=${editableField.client_id}&goBack=1`
      }
    }
  }

  const pinFavourite = (e) =>{
    e.preventDefault()
	  setIsFavourite(!isFavourite)
  }

  const openFileLinks = (e) =>{
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const addChecklist = (e, a) => {
    selectedActivity = a;
    toggleChecklistModal(e);
  }

  const addNotes = (e, a) => {
    selectedActivity = a;
    toggleCommentModal(e);
  }

  const viewFeeds = (e, a) => {
    selectedActivity = a;
    toggleFeedModal(e);
  }

  const openShareModal = (e, a) => {
    selectedActivity = a;
    toggleShareItem(e);
  }

  const deleteActivity = (e) => {
    e.preventDefault();
    let req = {id:selectedActivity.id, journey_category_id:params.journey_category_id}
    ActivityService.delete(req).then((res)=>{
      if(res.status==204){
        setJourneyActivityList(journeyActivityList.filter(i => i.id !== selectedActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const updatePrivacy = (id, privacy_type, callback) =>{
    let req = {
      id:id,
      privacy_type:privacy_type
    }
    ActivityService.update(req).then((res)=>{
      if(res.status==204){
        callback(privacy_type)
      }
    })
  }

  const showMenu = (e, a) => {
    selectedActivity = a;
    toggleMenuModal(e);
  }

  const routeActivity = (activity) =>{
    props.history.push(`/highschool/activity/${params.journey_category_id}/${params.journey_profile_id}?id=${activity.id}`)
    setJourneyActivityList(journeyActivityList.filter(i => i.id == activity.id))
  }

  const menuItems = ({item}) =>{
    const title = item.activity_type && item.activity_type.replace(/\s/g, '_')
    const editAccess = CheckAccess.hasEditAccess(currentUser, journeyCategory, item);
    const educationActivity = FormTemplateId.EducationActivity;

    return(
      <Fragment>
        <CardMenuInfo formId={item.activity_form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

        {editAccess && 
          <MenuItem id="edit_icon" 
            link={HighSchoolLink.hs_activity_edit(item, `edit=${editAccess}`, {project:journeyCategory})}
            icon="far fa-edit"
            action="Edit"/>
        }
        
        {CheckAccess.hasDeleteAccess(currentUser, journeyCategory, item) && 
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDeleteModal(e)}}/>
        }

        <MenuItem id="add_activity_icon" 
            link={HighSchoolLink.add_activity(item, educationActivity)}
            icon="far fa-sticky-note "
            action="Add Activity"/> 
            
        {editAccess &&  
          <MenuItem id="add_user_icon" 
            link={HighSchoolLink.add_user(item, {journey:journeyCategory, activity:item})}
            icon="fa fa-users"
            action="Add User"/>
        }

        {editAccess &&  
          <MenuItem id="share_item" 
            onSelect={(e) => openShareModal(e, item)}
            icon="fa fa-users"
            action="Share Item"/>
        }

        <a href={`mailto:${title}-EA-${item.record_id}@cuetree.com`} target="_blank"> 
          <i className=" font-15" aria-hidden="true"></i>
          <span className="font-13">{`${title}-EA-${item.record_id}@cuetree.com`}</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ activity}) => (
    <CardMainMenu activity={activity} showMenu={showMenu} />
  )

  const JourneyActivityCard = () => journeyActivityList && journeyActivityList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <Activity addNotes={addNotes} viewFeeds={viewFeeds} journeyCategory={journeyCategory} 
        addChecklist={addChecklist} activity={a}  dataSource={dataSource} menu={Menu}
        cardHeightCss={cardHeightCss} routeActivity={routeActivity} index={i} 
        popUpView={props.isPopupView} showIcons={showIcons}
        editActivityField={editActivityField} formFields={formFields}/>
        {selectedActivity.id == a.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={selectedActivity} type="activity" MenuItems={menuItems} 
          menuHeight="300px"/>
        }
    </div>
  ))
  
  if(loading){
    return <div className="spinner"/>
  }

  if(queryParam.id && !loading && journeyActivityList.length == 0){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Not Found" message="Sorry, this message was deleted by the author"/>)
  }

  return (
    <Fragment>

      <div id="ct">
        <div className="app_content">
          {!props.id &&
            <div className="app-header flex justspacebetween m-t-15">
              <div className="flex aligncenter">
                <JourneyTitle title={"Activities"} project={journeyCategory} />
              </div>

              <div className="flex aligncenter">
                {showIcons &&  
                  <NavIcon id="home_icon" dataTip="Back to Journey Home" 
                    link={HighSchoolLink.hs_portal(params)}
                    className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                    icon="fas fa-home"/>
                }
                    
                {journeyCategory && 
                  <NavIcon id="tile_view" dataTip="Toggle View" 
                    link={HighSchoolLink.hs_activity_type({
                      journey_category_id:params.journey_category_id, 
                      journey_profile_id:journeyCategory.education_journey_profiles.id
                    }, journeyCategory)}
                    className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                    icon="fas fa-file"/>
                }
                
                {showIcons &&
                  <NavIcon id="file_links" dataTip="Journey Files and Links" 
                    className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                    onSelect={openFileLinks} icon="fas fa-link"/>
                }
                    
                {!queryParam.id && showIcons && <NavIcon id="pin" dataTip="Filter Favourites" 
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  onSelect={pinFavourite} icon={"fas fa-thumbtack " + (isFavourite ? 'black' : 'white')}/>}

                {queryParam.id  && 
                  <NavIcon id="back_icon" dataTip="Back to Activity" 
                    onSelect={e=>{props.history.goBack()}}
                    className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                    icon="fas fa-arrow-left"/>
                }

                {journeyCategory?.is_admin_user && 
                  <NavIcon id="add_new_activity" dataTip="Create New Activity" 
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  onSelect={toggleActivityTypeModal} icon="fas fa-plus"/>
                }
              </div>
            </div>
          }
          <div className="app-content-section">
            <div className="row">
              <JourneyActivityCard/>
            </div>
          </div>
        </div>
      </div>
      
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedActivity} type="education_journal_entry"/>}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedActivity} type="education_journal_entry"/>}
      {isFeedOpen && <FeedModal isOpen={isFeedOpen} toggleModal={toggleFeedModal} item={selectedActivity} type="education_journal_entry"/>}
      {isActivityTypeOpen && 
        <ActivityTypeMenu journey_category_id={params.journey_category_id} 
        education_journey_profile_id={params.journey_profile_id} 
        params={dataSource} isOpen={isActivityTypeOpen} 
        toggleModal={toggleActivityTypeModal} />
      }
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity}/>
      {isFileLinkOpen && 
        <JourneyFilesLinksModal toggleModal={toggleFileLinkModal} 
        journeyCategory={journeyCategory}/>
      }
      <SideMenuBar helpId="8"/>
      {isHelpMessageOpen && 
        <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      }
      {isShareItemOpen && 
        <ShareItemModal isOpen={isShareItemOpen} toggleModal={toggleShareItem} 
        itemId={selectedActivity.id} itemType="education_journal_entry" 
        itemPrivacyType={selectedActivity.privacy_type} 
        journeyCategoryId={selectedActivity.journey_category_id} 
        journeyProfileId={selectedActivity.education_journey_profile_id} 
        updateItem={updatePrivacy} itemFormId={selectedActivity.activity_form_id}/>
      }
      {isActivityFieldOpen && <GenericModal {...getProps()}/>}
    </Fragment>
  )

}

export default ActivityList;