const SideMenuLink = {	
  home:'/home',
  journey:'/journey',
  members:'/journey/members',
  invite_users:'/invite/users',
  user_groups:'/user/groups',
  schedules:'/schedules?filter=all',
  news:'/news',
  user_dabbles:'/user/dabbles',
  digital_contents:'/digital_contents',
  forums:'/forums',
  ad_course_module:'/admin/course/modules',
  ad_news:'/admin/news',
  ad_questions:'/admin/questions',
  ad_highschool_planners:'/admin/highschool/planners',
  career_admin_planners:'/career/admin/planners',
  ad_resources:'/admin/resources',
  ad_scholarships:'/admin/scholarships',
  list_daily_dabble:'/list/daily_dabble',
  list_feedback:'/list/feedback',
  leadership:'/leadership',
  newsletter_emails:'/newsletter/emails',
  list_subscriptions:'/list/subscriptions',
  intervention_protocols:'/intervention_protocols'
}

const HighSchoolLink = {  
  hs_portal : (params) =>{
    return `/highschool/project/portal/${params.journey_category_id}`
  },
  hs_activity_type : (params, state) =>{
    return {
      pathname:`/highschool/activity/type/${params.journey_category_id}/${params.journey_profile_id}`,
      state:state
    }  
  },
  hs_activity_edit : (item, qp, state) => {
    return {
      pathname:`/highschool/activity/edit/${item.journey_category_id}/${item.education_journey_profile_id}/${item.id}`,
      search:qp,
      state:state
    } 
  },
  add_activity:(item, educationActivity)=>{
    return {
      pathname:`/highschool/step_activity/form/${item.journey_category_id}/${item.education_journey_profile_id}/${item.id}/${educationActivity}/step_activity`
    }
  },
  add_user:(item, state)=>{
    return{
      pathname:`/journey/user_role/${item.journey_category_id}/${item.education_journey_profile_id}/education_journal_entry/${item.id}`, 
      state:state
    }
  },
  hs_college_search:(params, profileId)=>{
    return {
      pathname:`/highschool/college/search/${params.journey_category_id}/${params.journey_profile_id}/${profileId}`
    }
  },
  hs_calendar:(params, state)=>{
    return {
      pathname:`/highschool/schedules/${params.journey_category_id}/${params.journey_profile_id.id}/education`,
      state:state
    }
  }
}

const TeamLink = {
  team_portal : (params) =>{
    return `/team/project/portal/${params.journey_category_id}`
  },
  team_activity_type : (params, state) =>{
    return {
      pathname:`/team/activity/${params.journey_category_id}/${params.journey_profile_id}`,
      state:state
    }  
  },
} 

const CollegeLink = {  
  hs_portal : (params) =>{
    return `/college/portal/${params.journey_category_id}`
  },
  hs_calendar:(params, state)=>{
    return {
      pathname:`/college/schedules/${params.journey_category_id}/${params.journey_profile_id}/college`,
      state:state
    }
  }
}

const PlannerLink = {

} 

const DailyDabbleLink = {

} 

const QuestionLink = {

}

const DataSourceLink = {

}

const DigitalContentLink = {

}

const ForumLink = {}

const NewsLink = {}

export {SideMenuLink, HighSchoolLink, CollegeLink, TeamLink}