import React, { useEffect, useState } from 'react';
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm';
import FormWizard from '../../../../../components/FormBuilder/FormWizard';
import FormTemplateId from '../../../../../constants/FormTemplateList'
import CollegeReviewService from "./ReviewService";
import FormContainer from '../../../../FormBuilder/FormContainer';

let formMode = null; 
let review = {}; 
let data = {}; 
let formFn = {};  

function CollegeReviewForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CollegeReviewService.get(params).then((res) => {
      if(res.status==200){
        review = res.data.college_cue;
        data = review.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       review = null;
    })
  }
  
  useEffect(() => { 
    review = {}; 
    data = {}; 
    formFn = {
      'form_type':'college_cue', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    }
    formMode = 'create-form-submissions';
    data = {}; 
  
    if(params.id && params.id!='null'){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (review) => {
    review.created_by = authState.user.id;
    review.education_journey_profile_id = params.journey_profile_id;
    review.journey_category_id = params.journey_category_id;
    review.college_id = params.college_id
    review.favourite_id = params.favourite_id
    review.form_id = params.form_template_id
    review.form_type = params.type
    review.form_wizard_id = queryParam.form_wizard_fk_id
    CollegeReviewService.create(review).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect(res.data.college_cue);
      }
    }).catch(function(res){
       
    })
  }

  const update = (review) => {
    review.updated_by = authState.user.id;
    CollegeReviewService.update(review).then((res) => {
      if(res.status==200){
       onSubmitPageRedirect(res.data.college_cue);
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = (review) => {
    if(props.isPopupMode){
      review?.id && props.onSubmit(review)
      props.toggleModal()
    }else if(queryParam.goBack){
      props.history.goBack()
    }else if(!params.journey_category_id){
      props.history.push(`/college/details/${params.college_id}`)
    }else{
      props.history.push(`/highschool/favourite/colleges/${params.journey_category_id}/${params.journey_profile_id}`)      
    }
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id || queryParam.unfilled_client_id){
    return(<FormWizard form_wizard_id={queryParam.form_wizard_id} form={review} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}/>)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode} formId={params.form_template_id} form={review} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect}  setPost={setPost}/>
      </FormContainer>
    )
  }else{
    return null;
  }
  
}

export default CollegeReviewForm;