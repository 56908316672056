import React, {useState, useEffect, Fragment} from "react";
import {Link} from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import Cs from "../../../../services/CommonService";
import JourneyTitle from '../../JourneyTitle/JourneyTitle'
import {NavIcon} from '../../../Common/MenuItem'
import {HighSchoolLink} from "../../../Common/RouteLinks"

let requestParams = null;

const CollegeSuggestionList = (props) => {
  useStyle('panel')
  let {history} = props;
  let params = props.match.params;
  let journey = props.location.state;
  let [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    requestParams = {
      page: 1,
      per_page: 15,
      item_type:'college'
    }
    getCollegeSuggestion()
  }, [])

  let getCollegeSuggestion = () => {
    Cs.getEduSuggestions(requestParams).then((res) => {
      setSuggestions(res.data.education_suggestions)
    })
  }

  let Colleges = ({}) => suggestions.map((c, i) => {
    return(
      <div key={i} className="next-action-text clearfix">
        <div className="col-xs-1 font-24"><i className="fas fa-university" style={{'color':Cs.getRandomColor()}}/></div>
        <div className="col-xs-11"><span className="next-action-hover">{c.title}</span></div>
      </div>  
    ) 
  })

  return(
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4">
          <JourneyTitle title={"Possible Colleges Nearby"} project={journey} />
        </div>
        <div className="col-md-8 float-right">
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={HighSchoolLink.hs_portal(params)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
        </div>
        <div className="clear"></div>
      </div>
    	
      <div className="page-content row">
  	    <div className="col-xs-12 next-action-container ht-200 auto-scroll">
  	  	  <Colleges/>	
  	    </div>		
  	  </div>
    </Fragment>
  )	
} 

export default CollegeSuggestionList;