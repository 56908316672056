import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import ChatUsers from './ChatUsers'
import ChatConversation from './ChatConversation'
import useStyle from '../../hooks/useStyle';
import useEscape from '../../hooks/useEscape';

const ChatModal = ({ isOpen, toggleModal, socketData}) => {
  useStyle('add_role_dialog');
  useEscape(toggleModal);
  
  const [chatUser, setChatUser] = useState();

  return(ReactDOM.createPortal(
    <div className="ml-modal">
      <div className="ml-modal-content ml-animate-zoom bg-white">
        {!chatUser && <ChatUsers setChatUser={setChatUser} toggleModal={toggleModal}/>}
        {chatUser && <ChatConversation socketData={socketData} setChatUser={setChatUser} chatUser={chatUser} toggleModal={toggleModal}/>}
      </div>
    </div>, document.body)
  )
}

export default ChatModal;

