import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';

const RegistrationConfirmation = (props) => {
  useStyle('signup');
  
  const params = props.match.params;
  const [status, setStatus] = useState('none');

  useEffect(() => {
    let req = {
      'verification_id':params.verification_id
    }
    AuthenticationService.registrationConfirmation(req).then((res) => {
      if(res.status == 204){
        setStatus('success');
        props.history.push("/signin");
      }else{
        setStatus("failed")
      }
    })
  }, []);

  return (
    <Fragment>
      <div className="page-content">
        <div className="page-title-bg bg-20 ht-150" ></div>
        <div className="page-title-bg dark-mode-tint ht-150"></div>
        <div className="page-title-bg opacity-90 bg-highlight ht-150"></div>
        <div className="page-title-small color-white bottom-30">
          <h1>
            Email Link Verification
          </h1>
        </div>
        
        {status != 'none' && 
          <form className="signform">
            <div className="content-boxed shadow-small p-b-5">
              <div className="content">
                <h4>
                  Account
                  {status == 'success'? ' Verification Successfull':' Verification failed'}
                </h4>
              </div>
            </div>
            {status == 'success' &&
              <Link to="/signin"
                className="button button-m button-full round-small button-margins bg-green1-dark bottom-30 shadow-samll">
                Want to Sign In? Click Here
              </Link>
            }
          </form>
        }

        {/* Footer */}
        <div className="signinbg">
          <div className="signincon">
            <img src={'./../../../images/cueTree_logo.png'} className="mlr-auto" />
			      <h1 className="m-t-10">Transform education journeys</h1>
            <p className="m-t-10 f-16">
              Improving learning experiences and outcomes by empowering students, parents, and educators
            </p>
		      </div>
          <div className="signcopy hidden-xs">
            <i className="far fa-copyright"></i> {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.</div>
          </div>
        </div>
    </Fragment>
  );
};

export default RegistrationConfirmation;