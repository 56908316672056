import React, { useState, Fragment, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Tabs, { TabPane } from 'rc-tabs';
import Cs from "../../../../../services/CommonService";
import AddToFavourite from "../../../../Common/AddToFavourite"
import ReactTooltip from "react-tooltip";
import {PillDsList} from "../../../UserRoles/NotificationPill" 
import ProfileImageUpload from "../../../../UserProfile/ProfileImageUpload"
import {Image, FileListLink, VedioLink} from "../../../../Common/Image"

const Course = (props) => {
  const [loading, setLoading] = useState(false);
  const [tabKey, setTabKey] = useState('1');

  let course = props.course
  course.updated_at = Cs.formatUpdateDate(course.updated_at, "MMM DD YYYY")

  let created_user = course.created_user
  let data = course.data
  let ds = course.data_source
  let fee = course.course_fee || {data:{}} 
  const cardStyle = {backgroundColor: data.color || "#607D8B",}

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const category = (key) => {
    if(ds){
      return (
        <div>
          { ds[key]?.icon && <i className={ds[key]?.icon + ' m-r-10'} style={{color:ds[key]?.icon_color}}></i>}
          {ds[key]?.label}
        </div>
      )
    }else{
      return (<div>
        </div>
      )
    }
  }

  if (!data) return null;  

  const Fee = ({label, price, type}) => (
    <>
      {fee.data.student_subscription_model_array?.includes(type)?
        <div>
          <i className="fas fa-dollar-sign"/>
          {price} {label}
        </div>:null
      }
    </>
  )

  const CopyToClipboard = async () =>{
    await navigator.clipboard.writeText(`${window.location.origin}/public/career_training/course_page/${course.id}`)
    alert('Link copied')
  }
  
  const Footer = () => (
    <div className="card-footer p-t-5">
      {props.type != 'planner' &&
        <div className="col-xs-12 text-center">
          <button type="button" className="dropbtn bg-highlight" onClick={(e) => props.routeDetails(course)} >View More</button>
          <button type="button" className="dropbtn bg-highlight" onClick={(e) => props.routeActivity(course)}>Curriculum</button>
        </div>
      }

      {props.type == 'planner' &&
        <div className="pull-right">
          <props.menu course={course} />
        </div>
      }
    </div>
  )

  const Header = () =>(
    <div className="white card-header p-7 br-btm-grey ht-60" style={cardStyle}>
      <div className="font-16 ellipsis" onClick={(e) => props.routeActivity(course)}>
        {data.course_name}
      </div>
      <div className="pull-right top-n22">
        <i className="fas fa-expand pull-right white expand"
         onClick={(e) => props.routeDetails(course)} ></i>
      </div>
      <div>
        <span className="">
          {course.updated_at}
        </span>
        <div className="pull-right">{ ds['subject']?.label}</div>
      </div>
    </div>
  )

  let callback = (key) => {
    if(key == 2){
      setLoading(true)
      props.getModules(course.id ,setModule)
    }
  }

  let setModule = (modules) =>{
    course.modules = modules
    setLoading(false)
    setTabKey('2')
  }

  const ModulesList = ({modules}) => modules.map((m, i) => { 
    return(
      <div className={"p-5 m-10-5 bg-white"} key={i}>
        <div className="row bold-600">
          { i+1 +") " + m.data.name}
        </div>
        <div className="m-l-5 p-5">
          <div className="m-l-5">
            <FileListLink list={m.data.upload} showFileName="true" styleName="pill m-r-15 black" iconClass="attach-sm m-l-5"/>
            <VedioLink url={m.data.video_url} showFileName="true" styleName="pill black" iconClass="fas fa-video m-l-5 lblue"/> 
          </div>
        </div>
        <Topics topics={m.topics || []} />
      </div>  
    )
  })

  const Topics = ({topics}) => topics.map((topic, i) =>{
    return(
      <Fragment key={i}>
        <div className="module-title font-16 bg-white p-10-5">
          <div className="row">
            { i+1 +". " + topic.data.name}
          </div>
          <div className="m-l-5 p-5">
            <div className="m-l-5">
              <FileListLink list={topic.data.upload} showFileName="true" styleName="pill m-r-15 black" iconClass="attach-sm m-l-5"/>
              <VedioLink url={topic.data.video_url} showFileName="true" styleName="pill black" iconClass="fas fa-video m-l-5 lblue"/> 
            </div>
          </div>
        </div>
      </Fragment>
    )
  })

  const CoursePlanCard = () => {
    return (
      <div className="card-container bg-white shadow-small" style={{height:"400px"}}>
        <Header/>
        <div className={`w-100p scroll-auto ht-300 p-7`}>
          <Tabs defaultActiveKey={tabKey} onChange={callback}>
            <TabPane tab="Description" key="1">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label>Target Audience</label>
                      {data.audience}
                    </td>
                    {course.is_public && 
                    <td>
                      <label>Public URL</label>
                      <div className="pill bg-highlight white" onClick={(e)=>CopyToClipboard(e)}>
                        Copy Link
                      </div>
                    </td>
                    }
                  </tr>
                  <tr>
                    <td>
                      <label>Assignments</label>
                      {data.assignments}
                    </td>
                    <td>
                      <label>Quiz</label>
                      {category('quiz')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Language</label>
                      {category('language')}
                    </td>
                  </tr>
                  {fee.id &&
                    <tr>
                      <td colSpan="2">
                        <label>Fee</label>
                        <Fee type={1} label="Per User" price={fee.data.course_fee_student_individual}/>
                        <Fee type={2} label="Per Buddy Group" price={fee.data.course_fee_student_buddy}/>
                      </td>
                    </tr>
                  }
                  <tr>
                    <td colSpan="2">
                      <label>Certificate</label>
                      {category('certificate')} <br/>
                      <div className="richtext" dangerouslySetInnerHTML={{ __html: data.certification}}></div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <label>Details</label>
                      <div className="richtext" dangerouslySetInnerHTML={{ __html: data.course_description}}></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TabPane>
            
            <TabPane tab="Curriculum" key="2">
              <ModulesList modules={course.modules || []} />
            </TabPane>
            
            <TabPane tab="Instructor" key="3">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {props.currentUser.id == course.created_by ?
                        <div className="w-85"><ProfileImageUpload/></div> : 
                        <Image styleName="w-85" image={created_user.profile_image?(created_user.id +'/'+ created_user.profile_image):0} 
                        type="user_profile_image"/>
                      }
                    </td>
                    <td>
                      <p>
                        <i className="fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal"></i>
                        {data.contact_number || "-"}
                      </p>
                      <p>
                        <i className="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-teal"></i>
                        {data.contact_email || "-"}
                      </p>
                      <p>
                        <i className="fab fa-linkedin fa-fw w3-margin-right w3-large w3-text-teal"></i>
                        {data.linkedin || "-"}
                      </p>
                      <p>
                        <i className="fab fa-skype fa-fw w3-margin-right w3-large w3-text-teal"></i>
                        {data.skype || "-"}
                      </p>
                      <p>
                        <i className="fab fa-whatsapp fa-fw w3-margin-right w3-large w3-text-teal"></i>
                        {data.whatsapp || "-"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <p>
                        {data.name || "-"}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <div className="richtext" dangerouslySetInnerHTML={{ __html: data.bio}}></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TabPane>
          </Tabs>
        </div>
        <Footer/>
      </div>
    )
  }

  return <CoursePlanCard />;
  
}

export default Course;