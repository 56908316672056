import React, { Fragment, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../services/CommonService';
import InvitedUserService from '../Journey/InvitedUsers/InvitedUserService';
import useStyle from '../../hooks/useStyle';

let requestParams = {}
let profileImage = 'images/avatars/2s.png'
let userLists = []

const ChatUsers = (props) => {
  useStyle('chat');
  useStyle('dashboard');

  const { state:currentUser, dispatch } = useContext(AuthContext);
  const [userList, setUserList] = useState([]);

  useEffect(() => {  
  	requestParams = {
      'page': 1, 
      'per_page' : 100,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'is_user_unique': true,
      'filter_created_by': true
    }

    getInvitedUserList()
  }, [])

  let getInvitedUserList = () => { 
    InvitedUserService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
        userLists = res.data.invited_journey_members
        setUserList(userLists)
      }
    })
  }

  let UserInfo = ({user}) => {
  	return(
    	<Fragment>  
      	<div className="d-flex" onClick={e=>props.setChatUser(user)}>
        	<div>
          	<img src={profileImage} className="rounded-circle bg-lgrey-2" width="50"/>
        	</div>
        	<div className="m-l-10">  
          	<div className="d-title font-14">{user[0]}</div>
        	</div>
          <div className="pos-abs r-0" style={{position:"absolute",right:"0px"}}>
            <div className="d-title font-12 lgrey">
              <i className="fa fa-comments" aria-hidden="true"></i>
              <span className="badge up bg-lgrey">{0}</span>
              <i className="fa fa-microphone-slash m-l-15" aria-hidden="true"></i>
              <i className="fa fa-circle m-l-15 green" aria-hidden="true"></i>
            </div>
            <div className="d-title font-12">Last Seen at 12:45 am</div>
          </div>
      	</div>
      	<div className="divider"/>
    	</Fragment>
  	)
  }

  const search = (e) =>{
    let s = e.target.value.length>0?[]:userLists;
    userList.forEach((u)=>{
      if (u[0].toUpperCase().indexOf(e.target.value.toUpperCase()) > -1) {
        s.unshift(u)
      }
    })
    setUserList(s)
  }

  return (
  	<Fragment>
      <div className="col-xs-12">
        <div className="card card-style ht-400 scroll-auto">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Users</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>props.toggleModal(e)}>×
            </button>
          </div>
          <div className="content">
            <p className="m-b-10 m-t-10">
              Tap to chat.
            </p> 
            <a className="input-group m-r-20 m-b-10" id="search_box">
              <input type="text" onChange={e=>search(e)} placeholder="Search" className="form-control input-sm"/>
            </a>
            {userList && userList.map((u, i) => (
              <UserInfo key={i} user={u}/>           
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )

}

export default ChatUsers;