import { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import importScript from "../../hooks/importScript";
import useStyle from "../../hooks/useStyle";
//import Shepherd from 'Shepherd';

let tour = {};
let page_tours = {};

const getButtons = (idx) => {
  const buttons = [
    {
      action() {
        window.Shepherd.activeTour.complete();
      },
      text: "Don't Show Automatically",
    },
    {
      action() {
        window.Shepherd.activeTour.currentStep.destroy();
        return this.next();
      },
      text: "Next",
    }
  ]
  if(idx != 0){
    buttons.push({
      action() {
        window.Shepherd.activeTour.currentStep.destroy();
        return this.back();
      },
      text: "Back",
    })
  }
  return buttons
}

page_tours["header"] = [
  {
    title: " Edit/Update your account settings",
    text: `Click this icon to update your user name, password,
      profile photograph, and other cueTree settings.`,
    attachTo: {
      element: "#edit_user_profile",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "edit_user_profile",
    showOn: function () {
      return document.querySelector(`#edit_user_profile`) ? true : false;
    },
  },
  {
    title: "Access the cueTree Menu",
    text: `Click this icon to access the main menu for your account`,
    attachTo: {
      element: "#side_menu_btn",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "side_menu_btn",
    showOn: function () {
      return document.querySelector(`#side_menu_btn`) ? true : false;
    },
  },
  {
    title: "View account notifications",
    text: `Click this icon to view cueTree notifications for your account `,
    attachTo: {
      element: "#notification_btn",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "notification_btn",
    showOn: function () {
      return document.querySelector(`#notification_btn`) ? true : false;
    },
  },
  {
    title: "Search this page",
    text: `Click this icon to search for content on this page`,
    attachTo: {
      element: "#search_btn",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "search_btn",
    showOn: function () {
      return document.querySelector(`#search_btn`) ? true : false;
    },
  },
];
page_tours["/journey"] = [
  {
    title: "Add New Journey",
    text: `Click the + button \ to launch and customize a new \`Journey\` .`,
    attachTo: {
      element: "#add_new_journey",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_new_journey",
    showOn: function () {
      return document.querySelector(`#add_new_journey`) ? true : false;
    },
  },
  {
    title: "Journey Card",
    text: `Click the to view more details about jourey`,
    attachTo: {
      element: "#head",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "head",
    showOn: function () {
      return document.querySelector(`#head`) ? true : false;
    },
  },
  {
    title: "Journey Footer",
    text: `Use to edit, delete, share and comment journey`,
    attachTo: {
      element: "#footer",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "footer",
    showOn: function () {
      return document.querySelector(`#footer`) ? true : false;
    },
  }
];

page_tours["/team/project/portal"] = page_tours[
  "/highschool/project/portal"
] = [
  {
    title: "Team Journey Menu",
    text:
      "Click this icon to view the menu items to edit and update details for this team journey.",
    attachTo: {
      element: "#portal_menu",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "portal_menu",
    showOn: function () {
      return document.querySelector(`#portal_menu`) ? true : false;
    },
  },
];

page_tours["/highschool/activity"] = [
  {
    title: "Add New High School Activity Tile",
    text: `Click this icon to add a new tile to the High School Activities.`,
    attachTo: {
      element: "#add_new_activity",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_new_activity",
    showOn: function () {
      return document.querySelector(`#add_new_activity`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    },
  },
  {
    title: "View content items for this Journey",
    text: `Click this icon to view all uploaded files, links, and chats relating to this Journey`,
    attachTo: {
      element: "#file_links",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "file_links",
    showOn: function () {
      return document.querySelector(`#file_links`) ? true : false;
    },
  },
  {
    title: "View emails sent directly to this Tile",
    text: `Click this icon to view emails relating to the topic of this Tile, sent by collaborators.`,
    attachTo: {
      element: "#mail_0",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "email",
    showOn: function () {
      return document.querySelector(`#mail_0`) ? true : false;
    },
  },
  {
    title: "Create/Edit/Read Checklist Items for this Tile",
    text: ` Click this icon to collaborate with others on this Tile.
          Create, edit and read checklist items relating to this Tile.`,
    attachTo: {
      element: "#check_list_0",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "check_list",
    showOn: function () {
      return document.querySelector(`#check_list_0`) ? true : false;
    },
  },
  {
    title: "Send/Read Messages on this Tile",
    text: ` Click this icon to read and write messages to other users who are collaborating with you on this Tile.`,
    attachTo: {
      element: "#comment_0",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "chat",
    showOn: function () {
      return document.querySelector(`#comment_0`) ? true : false;
    },
  },
];

page_tours["/team/activity"] = [
  {
    title: "Add a New Team Activity Tile",
    text: `Click this icon to add a new tile to the Team Activities.`,
    attachTo: {
      element: "#add_new_activity",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_new_activity",
    showOn: function () {
      return document.querySelector(`#add_new_activity`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    },
  },
  {
    title: "View content items for this Journey",
    text: `Click this icon to view all uploaded files, links, and chats relating to this Journey`,
    attachTo: {
      element: "#file_links",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "file_links",
    showOn: function () {
      return document.querySelector(`#file_links`) ? true : false;
    },
  },
  {
    title: "View emails sent directly to this Tile",
    text: `Click this icon to view emails relating to the topic of this Tile, sent by collaborators.`,
    attachTo: {
      element: "#mail_0",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "email",
    showOn: function () {
      return document.querySelector(`#mail_0`) ? true : false;
    },
  },
  {
    title: "Create/Edit/Read Checklist Items for this Tile",
    text: ` Click this icon to collaborate with others on this Tile.
          Create, edit and read checklist items relating to this Tile.`,
    attachTo: {
      element: "#check_list_0",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "check_list",
    showOn: function () {
      return document.querySelector(`#check_list_0`) ? true : false;
    },
  },
  {
    title: "Send/Read Messages on this Tile",
    text: ` Click this icon to read and write messages to other users who are collaborating with you on this Tile.`,
    attachTo: {
      element: "#comment_0",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "chat",
    showOn: function () {
      return document.querySelector(`#comment_0`) ? true : false;
    },
  },
];

page_tours["/career_training/planner"] = [
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    }
  },
  {
    title: "Select Training Modules",
    text: `Click this icon to Select Training Modules.`,
    attachTo: {
      element: "#select_training_modules",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "select_training_modules",
    showOn: function () {
      return document.querySelector(`#select_training_modules`) ? true : false;
    }
  }
]

page_tours["/career_training/modules"] = [
  {
    title: "Add New modules",
    text: `Click this icon to add a new tile to the High School Activities.`,
    attachTo: {
      element: "#add_new_module",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_new_module",
    showOn: function () {
      return document.querySelector(`#add_new_module`) ? true : false;
    },
  },
  {
    title: "Share Admin",
    text: `Click this icon to share modules to other users.`,
    attachTo: {
      element: "#share_admin",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "share_admin",
    showOn: function () {
      return document.querySelector(`#share_admin`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    },
  },
  {
    title: "View content items for this Journey",
    text: `Click this icon to view all uploaded files, links, and chats relating to this Journey`,
    attachTo: {
      element: "#file_links",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "file_links",
    showOn: function () {
      return document.querySelector(`#file_links`) ? true : false;
    },
  },
];

page_tours["/career_training/schedules"] = [
  {
    title: "Add New Schedule",
    text: `Click this icon to add a new schedule.`,
    attachTo: {
      element: "#add_schedule_type",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_schedule_type",
    showOn: function () {
      return document.querySelector(`#add_schedule_type`) ? true : false;
    },
  },
  {
    title: "Toggle view",
    text: `Click this icon to view schedules in card view.`,
    attachTo: {
      element: "#toggle_icon",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "toggle_icon",
    showOn: function () {
      return document.querySelector(`#toggle_icon`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#home_icon",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "home_icon",
    showOn: function () {
      return document.querySelector(`#home_icon`) ? true : false;
    },
  },
];

page_tours["/invite/user"] = [
  {
    title: "Invite New User",
    text: `Click this icon to add new user and assign role.`,
    attachTo: {
      element: "#add_role",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_role",
    showOn: function () {
      return document.querySelector(`#add_role`) ? true : false;
    },
  },
  {
    title: "Toggle view",
    text: `Click this icon to view in table view.`,
    attachTo: {
      element: "#toggle_role",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "toggle_role",
    showOn: function () {
      return document.querySelector(`#toggle_role`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#back_home",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "back_home",
    showOn: function () {
      return document.querySelector(`#back_home`) ? true : false;
    },
  },
];

page_tours["/journey/role"] = [
  {
    title: "Add New Role",
    text: `Click this icon to add new role and assign permissions.`,
    attachTo: {
      element: "#add_role",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "add_role",
    showOn: function () {
      return document.querySelector(`#add_role`) ? true : false;
    },
  },
  {
    title: "Toggle view",
    text: `Click this icon to view in table view.`,
    attachTo: {
      element: "#toggle_role",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "toggle_role",
    showOn: function () {
      return document.querySelector(`#toggle_role`) ? true : false;
    },
  },
  {
    title: "Go to Journey Dashboard Page",
    text: `Click this icon to go to the Journey Dashboard page and access other Journey features.`,
    attachTo: {
      element: "#back_home",
      on: "bottom",
    },
    buttons: getButtons(),
    id: "back_home",
    showOn: function () {
      return document.querySelector(`#back_home`) ? true : false;
    },
  },
];

const setPageTour = (key, data) =>{
  if(!page_tours[key]){
    page_tours[key] = []
  }
  page_tours[key].push({
    title: data.title,
    text: data.description,
    attachTo: {
      element: data.class?`.${data.class}`:`#${data.id}`,
      on: "bottom",
    },
    buttons: getButtons(0),
    id: data.id,
    showOn: function () {
      return document.querySelector(`#${data.id}`) ? true : false;
    },
  })
}

const usePageTour = () => {
  useStyle("shepherd");

  const match = useRouteMatch();

  tour.init = (restart_tour, page_id) => {
    const pageId = page_id || match.path.split("/:")[0];
    const isTourComplete = localStorage.getItem(`${pageId}_page_tour`)
    if(!restart_tour && isTourComplete){
      return
    }  

    importScript("/scripts/shepherd_2.min.js", () => {
      if (window.Shepherd) {
        Object.assign(
          tour,
          new window.Shepherd.Tour({
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
              useModalOverlay: true,
              allowSkipSteps: true,
              showCancelLink: true,
              scrollTo:true,
              scrollTo: {
                behavior: 'smooth',
                block: 'center'
              },
            },
            useModalOverlay: true,
            tourName: pageId,
          })
        )

        window.Shepherd.on("complete", () => {
          localStorage.setItem(`${pageId}_page_tour`, true);
        })

        tour.addSteps(page_tours[pageId]);
        tour.start()
      }
    }, window.Shepherd)
  }

  return tour;
};

export { usePageTour, setPageTour};

/*scrollTo: { behavior: "smooth", block: "center" },*/
