import React from 'react';
import PrivateRoute from "../../../routes/PrivateRoute";
import JourneyPlannerList from '../../Journey/TeamJourney/Planners/List/JourneyPlannerList'
import JourneyActivityList from '../../Journey/TeamJourney/Activities/List/JourneyActivityList'
import JourneyActivityForm from '../../Journey/TeamJourney/Activities/Form/JourneyActivityForm'
import PortalDetails from '../../Journey/TeamJourney/TeamPortalPage/PortalDetails'
import StepActivityForm from '../../Journey/TeamJourney/StepActivities/StepActivityForm'
import TeamSchedules from '../../Journey/TeamJourney/Calendars/TeamSchedules'

const JourneyRouter = (props) => (
  <React.Fragment>	
    <PrivateRoute path="/team/activity/:journey_category_id?/:education_journey_profile_id?" setHelpId={props.setHelpId} searchString={props.searchString} component={JourneyActivityList} exact />
    <PrivateRoute path="/team/activity/form/:journey_category_id/:education_journey_profile_id/:form_template_id/:type/:id?" component={JourneyActivityForm} exact />
    <PrivateRoute path="/team/planners/:journey_category_id/:journey_proile_id?" searchString={props.searchString} component={JourneyPlannerList} exact />
    <PrivateRoute path="/team/project/portal/:project_id?" app={props.app} component={PortalDetails} exact/>
    <PrivateRoute path="/team/step_activity/form/:journey_category_id/:journey_profile_id/:activity_id/:form_template_id/:activity_type/:id?" component={StepActivityForm} exact />
    <PrivateRoute path="/team/schedules/:journey_category_id/:journey_profile_id/:journey_type" component={TeamSchedules} exact />
  </React.Fragment>
)

export default JourneyRouter;
