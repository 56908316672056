import React ,{Fragment, useState} from "react"
import moment from '../../../../node_modules/moment/min/moment.min.js'
//import Skills from '../../../components/ServiceProvider/List/Skills'
import {Link} from "react-router-dom"
import Cs from '../../../services/CommonService'
import env from "../../../env"
import {FormFieldTable, RenderData} from "../../Journey/UserRoles/NotificationPill"
import { ChatGrayIcon, HamburgerMenuIcon } from "../../Common/ImageIcons.js"

const DigitalContentTable = (props) => {
  let {digitalContent, formFieldsList, Datasource} = props
  let {data, data_source} = digitalContent
  let formFields = formFieldsList[digitalContent.content_form_id] || []

  const [activeIndex, setActiveIndex] = useState()
  const [activeView, setActiveView] = useState()

  let digitalContentType = Datasource.menu_digital_content.options
  const cardStyle = {}

  try {
    const subject = digitalContentType.filter(function (el) {
       return el.sid === digitalContent.content_type
    })
    cardStyle.borderLeft = "10px solid "+ subject[0].icon_color
  } catch (e) {
    cardStyle.borderLeft = "10px solid #607D8B"
  }

  const toggleView = (id) => {
    if(activeView == id){
      setActiveView(null)    
    }else{
      setActiveView(id)
    }
  }

  const RenderCard = ({label, details, id}) =>{
    return(
      <Fragment>
        <div className="step-activity" onClick={e=>toggleView(id)}>
          {label}
        </div>
        {activeView == id && 
          <div className="vcard-field p-5">
            <div dangerouslySetInnerHTML={{ __html: details }}  />
          </div>
        }
      </Fragment>
    )
  }

  const GenericContent = () =>(
    <div className="bg-white border-rounded-10 m-t-10">
      <div className="card-title card-title-gray flex aligncenter p10 p-b-15" style={cardStyle}>
        <div className="flex aligncenter">
          <a id="head" className="content">
            <div className="w-100">
              <h4 className="fw700 card-heading">{data.title || data.record_title || data.heading || data.faq}</h4>
              <p className="f12">by {digitalContent.user.name} since {Cs.formatUpdateDate(digitalContent.created_at)}</p>
            </div>
          </a>
        </div>
      </div>
      <div className="card-content p10-20">
        <div className="b-tab-table active scroll-auto ht-250 m-t-5">
          <b>Content Type:</b>{digitalContent.content_type.replace(/_/g, ' ')}  
          <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>   
        </div>
      </div>
      <div className="card-footer flex justspacebetween p10-20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">
            <ChatGrayIcon onSelect={e=>props.addNotes(e, digitalContent)}/>
          </li>
          <li className="m-r-25">
            <props.details ja={digitalContent} />
          </li>
        </ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, digitalContent)}/>
      </div>
    </div>
  )

  if(!data)return null;

  return (<GenericContent/>)
    
}

export default DigitalContentTable;