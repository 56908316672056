import React, { useState, useEffect, Fragment, useContext} from "react";
import { Link } from "react-router-dom";
import PortalHeader from "./PortalHeader";
import PortalPath from "./PortalPath";
import PortalPathTypeMenu from "./PortalPathTypeMenu";
import JourneyCategoryService from "../../JourneyCategoryService";
import useStyle from "../../../../hooks/useStyle";
import Teamnodes from "../../../../constants/Teamnodes";
import CheckAccess from "../../Roles/CheckAccess";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../PreLoadSpinner";
import SideMenuBar from "../../../Common/SideMenuBar";
import CtTicker from "../../../Common/CtTicker"
import env from "../../../../env"
import NotificationList from "../../JourneyNotification/JourneyNotificationList"

let portalPath = null;
let userTypeList = null;
let activeJourney = null;

const PortalDetails = (props) => {
  useStyle('css/tile-components.css')

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  
  let params = props.match.params;
  
  const [project, setProject] = useState({});
  const [statics, setStatics] = useState({});
  const [share, setShare] = useState({});
  const [isHelpMessageOpen, setIsHelpMessageOpen ] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let req = {
      journey_category_id: params.journey_category_id,
      show_journey_templates: false,
      data_source_params: {
        data_source_id: [3386]
      }
    };

    JourneyCategoryService.getJourneyById(req).then((res) => {
      if (res.status == 200) {
        res = res.data;
        res.journey_category = res.journey_category[0];
        portalPath = res.data_source_list.training_journey_nodes;
        userTypeList = res.data_source_list.fj_i_am;
        CheckAccess.userAccess(currentUser, res.journey_category, res.invited_journey_member)
        res.journey_category.education_journey_profiles.data.fj_name_of_family_member = res.journey_category.education_journey_profiles.data.journey_name
        setProject(res.journey_category);
        setStatics(res.statics);
        setShare(res.invited_journey_member);
        activeJourney = res;
        setLoading(false)
      }
    })
  }, [])

  const routePath = (e, path) => {
    e.preventDefault();
    if (path.sid == "planners") {
      props.history.push(
        `/career_training/planners/courses/${params.journey_category_id}/${project.education_journey_profiles.id}`, project
      )
    } else if(path.sid == 'roles'){
      props.history.push(`/journey/role/${params.journey_category_id}/${project.education_journey_profiles.id}`, project)
    } else if (path.sid == "enrichs") {
      props.history.push(
        `/career_training/resources/${params.journey_category_id}/${project.education_journey_profiles.id}`, project
      )
    } else if (path.sid == "curriculum") {
      props.history.push(
        `/career_training/courses/${params.journey_category_id}/${project.education_journey_profiles.id}`, project
      )
      /*props.history.push(
        `/career_training/modules/${params.journey_category_id}/${project.education_journey_profiles.id}`, project
      )*/
    } else if (path.sid == "invite_users") {
      props.history.push(
        `/invite/user/${project.id}/${project.education_journey_profiles.id}`, {'journey': project, 'share':share}
      )
    } else if (path.sid == "calendars") {
      props.history.push(
        `/career_training/schedules/${project.id}/${project.education_journey_profiles.id}/training`, project
      )
    } else if (path.sid == "interviews") {
      props.history.push(
        `/career_training/interviews/${project.id}/${project.education_journey_profiles.id}`, project
      )
    }else if (path.sid == "meetings") {
      props.history.push(
        `/zoom/meetings/${project.id}`, project
      )
    }else if (path.sid == "exams") {
      props.history.push(
        `/career_training/exams/${project.id}/${project.education_journey_profiles.id}`, project
      )
    }else if (path.sid == 'assessments') {
      props.history.push(
        `/career_training/assessments/${project.id}/${project.education_journey_profiles.id}`, project
      )
    }else if(path.sid == 'forms'){
      props.history.push(
        `/forms/${project.id}/${project.education_journey_profiles.id}`, project
      )
    }else if(path.sid == 'form_submissions'){
      props.history.push(
        `/user/forms/${project.id}/${project.education_journey_profiles.id}`, project
      )
    }else if(path.sid == 'field_projects'){
      props.history.push(
        `/forms/${project.id}/${project.education_journey_profiles.id}?case_assessment=1`, project
      )
    }
  }

  if (!project.id) return <PreLoadSpinner />;

  return (
    <>
      <div id="ct">
        <div className="app_content">
          <PortalHeader project={project} share={share} statics={statics} 
          userTypeList={userTypeList} app={props.app} />

          <div className="app-content-section">
            <PortalPath project={project} currentUser={currentUser}
                portalPath={portalPath} routePath={routePath} 
                isHelpMessageOpen={isHelpMessageOpen}
                statics={statics} share={share} journeyCategory={project}/>    
          </div>
          <small className="font-12 p-5 lh-16 lgrey">3386</small>
        </div>
      </div>
    </>
  )

  return (
    <Fragment>
      <div className="p-5 bg-white">
        <div className="portlet">
          <PortalHeader project={project} share={share} statics={statics} userTypeList={userTypeList} app={props.app} />
          <div id="bg-info" className="panel-collapse collapse in mht-260" style={{padding: screen.xs?'0px':'0 13% 0 13%'}}>
            <div className="portlet-body br-grey m-t-10">
              <h5>I want to...
                <span className="font-20 m-r-20 far fa-bell pull-right" data-tip="Admin Messages"  onClick={(e)=>setIsHelpMessageOpen(!isHelpMessageOpen)}/>
              </h5>
              {isHelpMessageOpen &&
                <NotificationList item_type={['journey_profile', 'career_training.course']} journey_category_id={project.id}/>
              }
              <PortalPathTypeMenu project={project} currentUser={currentUser}
                portalPath={portalPath} routePath={routePath} 
                isHelpMessageOpen={isHelpMessageOpen}
                statics={statics} share={share} journeyCategory={project}/>
            </div>
            <small className="font-12 p-5 lh-16 lgrey">3386</small>
          </div>
        </div>
      </div>
      <SideMenuBar helpId="3" app={props.app} />
    </Fragment>
  )
}

export default PortalDetails;