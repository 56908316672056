import React, { Fragment, useState, useEffect, useContext, useRef} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from "../../env"

const OrganizationList = (props) => {
  useStyle('dashboard')

  const { state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext)
  const orgEl = useRef(null)
  let profileImage = 'images/org.png'

  const updateCurrentOrg = (org) => {
    if(!org.is_active) return null

    let req = {
      user: { current_organization_id: org.id },
    }
    AuthenticationService.updateUserName(req).then((res) => {
      if (res.status == 200) {
        dispatch({
          type: "UPDATE",
          payload: { user: res.data.user },
        })
      } 
    })
  }

  useEffect(() => {
    document.addEventListener("click", handleClick, true)
    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [])

  const handleClick = (e) => {
    if (!orgEl.current.contains(e.target)){
      props.toggleModal(e)
    }
  }

  const Organization = () => currentUser.organizations.map((o, i) => (
	  <Fragment key={i}>
  	  <div className={currentUser.current_organization_id == o.id?'d-flex org-active':'d-flex'} onClick={e=>updateCurrentOrg(o)}>
  	    <div>
  	      <img src={ env.file_url + o.logo_url || profileImage} className="rounded-circle bg-lgrey-2 m-t-10" width="50"/>
  	    </div>
  	    <div className={o.is_active?'m-l-10':'op-06 m-l-10'}>  
  	      <h5 className="d-title ellipsis">{o.name}</h5>
  	      <p className={currentUser.current_organization_id == o.id?'d-description white':'d-description black'}>{Cs.formatUpdateDate(o.updated_at)}</p>
  	      {!o.is_active && <p className="lred">Inactive</p>}
        </div>
  	  </div>
  	  <div className="divider"/>           
  	</Fragment>
  )) 

  const Organizations = () =>(
    <div className="ml-container" ref={orgEl}>
      <div className="ml-section">
        <div className="content">
          <Organization/>
		    </div>
      </div>
    </div>
  )
  
  return(<Organizations/>)
}

export default OrganizationList;