import React, { useState, Fragment, useEffect } from "react";
import BookmarkService from "../../services/BookmarkService"
import useStyle from '../../hooks/useStyle';
import useSort from '../../hooks/useSorting';
import SortDirection from '../../components/Common/SortDirection'

let requestParams = {}
const VideoBookmark = ({selectedTag}) => {
  const [bookmarkSteps, setBookmarkSteps] = useState([]);
  let [newStep, setNewStep] = useState({
    bookmark_id: selectedTag.id,
    journey_category_id:selectedTag.journey_category_id,
    journey_profile_id:selectedTag.journey_profile_id,
    bookmark_type:selectedTag.bookmark_type, 
    data:{}
  })

  useEffect(() => {
  	requestParams = {
  	  bookmark_id: selectedTag.id,
      bookmark_type:selectedTag.bookmark_type,
      page:"1",
      per_page:"15"
  	}
    getBookmarkSteps()
  }, [selectedTag.id])

  const getBookmarkSteps = () =>{
  	BookmarkService.getAll(requestParams).then((res)=>{
  	  setBookmarkSteps(res.data.bookmarks)	
  	})	  	
  }

  const {sort} = useSort(requestParams, getBookmarkSteps)

  const handleInputChange = (event) => {
    newStep.data[event.target.name] = event.target.value
    setNewStep({...newStep})
  }

  const resetForm = () =>{
    setNewStep({
      bookmark_id:selectedTag.id, 
      journey_category_id:selectedTag.journey_category_id,
      journey_profile_id:selectedTag.journey_profile_id,
      bookmark_type:selectedTag.bookmark_type, 
      data:{}
    })
  }

  const addBookmarkStep = () =>{
  	if(newStep.id){
  	  BookmarkService.update(newStep, newStep.id).then((res)=>{
  	  	if(res.status == 204){
          setBookmarkSteps([res.data, ...bookmarkSteps])
          resetForm()
  	    }	
	    })	
  	}else{
  	  BookmarkService.create(newStep).then((res)=>{
    		if(res.status == 201){
    		  setBookmarkSteps([res.data, ...bookmarkSteps])
    		  resetForm()
    		}	
    	})	
  	}
  }

  const deleteStep = (step) => {
    BookmarkService.delete(step.id).then((res) => {
      if (res.status == 204) {
        setBookmarkSteps(
          bookmarkSteps.filter((i) => i.id !== step.id)
        )
      }
    })
  }

  const tableData = bookmarkSteps && bookmarkSteps.map((p, i)=>{
    let seconds = 0
    try{
      const a = p.data.start_time.split(':')
      a.length<3 && a.unshift(0)
      seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
    }catch(e){

    }

    if(p && p !== undefined){
      return(
        <tr key={i}>
          <td></td>

          <td>
            {p.data.description}
          </td>

          <td>
            {!p.file_name && 
              <Fragment>
                <span onClick={e=>deleteStep(p)} className="far fa-trash-alt font-15"/>
                <span onClick={e=>setNewStep(p)} className="m-l-15 far fa-edit font-15"/>		
              </Fragment>
            }
          </td>
        </tr>
      )
    }
  })

  const StepListTable = () => (
  	<div className="col-xs-12 table-responsive m-t-10">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th className="th-sm" onClick={e=>sort('data.start_time')}>ID
              <SortDirection sort_column={requestParams.sort_column} column="data.start_time" reverse={requestParams.reverse}/> 
            </th>
            <th className="th-sm" onClick={e=>sort('data.title')}>Narration 
              <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
            </th>
            <th className="th-sm">
              Options	 
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

  return (
    <Fragment>
	    <div className="row p-5">
    	  <div className="col-xs-12 col-sm-6 p-5">
    	    <label className="text-cap"><b>Time</b></label>
    	    <input placeholder="HH:MM:SS" type="text" name="start_time" id="start_time" 
    	      onChange={e=>handleInputChange(e)} className="form-control"
    	      defaultValue={newStep.data.start_time} value={newStep.data.start_time || ''}/>
    	  </div>
    	  <div className="col-xs-12 col-sm-6 p-5">
    	    <label className="text-cap"><b>Description</b></label>
    	    <textarea onChange={e=>handleInputChange(e)} placeholder="Description" 
    	      name="description" id="description" className="form-control"
    	      defaultValue={newStep.data.description} value={newStep.data.description || ''}/>
    	  </div>
    	  <div className="col-xs-12 text-center">
    	    {newStep.data.start_time && newStep.data.description &&
    	      <button className="dropbtn bg-lightblue" onClick={e=>addBookmarkStep()}>
    	        <b>{newStep.id?'Update':'Create'}</b>
    	      </button>
    	    }  
    	  </div>
	      <StepListTable/>
	    </div>
	  </Fragment>
  )
}

export default VideoBookmark;