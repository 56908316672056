import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import UserFormSubmissionService from "../UserFormSubmissionService"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'
import FormWizard from '../../../components/FormBuilder/FormWizard'
import Cs from "../../../services/CommonService"

let formMode = null 
let form = {} 
let data = {} 
let formFn = {}  

function Form(props) {
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  const get = () => {
    UserFormSubmissionService.get(params.id).then((res) => {
      if(res.status==200){
        form = res.data.user_form_submission
        data = form.data || {}
        setFormRendered(true)
      }
    })
  }
  
  useEffect(() => { 
    form = {}
    data = {} 
    formFn = {
      'form_type':'form_submission', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    } 
    formMode = 'create-form-submissions'
    data = {}
    
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (form) => {
    form.journey_profile_id = params.journey_profile_id
    form.journey_category_id = params.journey_category_id
    form.user_form_id = params.user_form_id
    UserFormSubmissionService.create(form).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect(res.data.user_form_submission)
      }
    })
  }

  const update = (form) => {
    UserFormSubmissionService.update(form).then((res) => {
      if(res.status==204){
        onSubmitPageRedirect(form)
      }
    })
  }

  const onSubmitPageRedirect = (res) => {
    formFn.successMessage = `Your submitted response (ID: ${res.record_id}) was received at ${Cs.formatUpdateDate(res.updated_at, 'MMM DD, YYYY hh:mm a')} `
    if(queryParam.is_form_wizard && formFn.setIsFormSubmitted){
      formFn.setIsFormSubmitted()
    }else{
      onCancel()
    } 
  }

  const onCancel = () =>{
    props.history.push(`/user/forms/${params.journey_category_id}/${params.journey_profile_id}`)
  }

  if(queryParam.is_form_wizard && params.user_form_id){
    return(
      <FormWizard user_form_id={params.user_form_id} form={form} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onCancel}/>
    )
  }else if(params.user_form_id && isFormRendered){
    return(
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} user_form_id={params.user_form_id} form={form} data={data} 
        formFn={formFn} onCreate={create} onUpdate={update} post={post} 
        onCancel={onCancel} setPost={setPost}/>
      </FormContainer>
    )
  }

  return null
  
}

export default Form;