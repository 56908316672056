import React, { Fragment, useState, useEffect, useContext} from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import useStyle from "../../../../../hooks/useStyle"
import CommonService from "../../../../../services/CommonService"
import useEscape from "../../../../../hooks/useEscape"

let requestParams = {}

const ActivityTypeMenu = ({journeyCategoryId, journeyProfileId, pathNode, params, isOpen, toggleModal}) => {
  useStyle("add_role_dialog")
  useStyle("filter_dialog")
  useEscape(toggleModal)

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [activityCategory, setActivityCategory] = useState()

  useEffect(() => {
    requestParams = {
      data_source_id: [3354],
    }
    getActivityCategory()
  }, [])

  let getActivityCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      setActivityCategory(
        CommonService.groupBy(res.college_cat.options, "actions")
      )
    })
  }

  const List = (key) => activityCategory[key].map((k) => {
    return (
      <Fragment key={k.value}>
        <Link className="ver-icons"
          to={`/college/activity/form/${journeyCategoryId}/${journeyProfileId}/${k.child_form}/${k.label}?activity_type=${k.value}&grade_dsl_id=${pathNode.path_id}&grade=${pathNode.path_value}`}>
          <span>
            <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
          </span>
          <div className="popup-menu-title">{k.label}</div>
          <i className="fas fa-chevron-right lgrey pull-right" />
        </Link>
      </Fragment>
    )
  })

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom" style={{width: '400px'}}>
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select an item</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>

          {activityCategory && (
            <div id="ct" className="p-5 scroll-x" style={{height:screen.height-120}}>
              <div className="popup-menu-grid">
                <div className="head">Academic</div>
                {List("academic")}
              </div>

              <div className="popup-menu-grid">
                <div className="head">Extracurricular</div>
                {List("extracurricular")}
              </div>

              <div className="popup-menu-grid">
                <div className="head">Special experience</div>
                {List("special_experience")}
              </div>

              <div className="popup-menu-grid">
                <div className="head">College Schedule</div>
                {List("college_schedule")}
              </div>

              <small className="font-9 p-5 lgrey lh-16">3354</small>
            </div>
          )}
        </div>
      </div>
    </Fragment>, document.body
  )
}

export default ActivityTypeMenu;