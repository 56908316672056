import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import ReactTooltip from "react-tooltip";
import CheckAccess from "../../Roles/CheckAccess";
import Cs from "../../../../services/CommonService";

const PortalPath = (props) => {
  useStyle("path");
  let {journeyCategory, invitedUser} = props
  let report = (props.statics && props.statics.report) || {}
  let groups = Cs.groupBy(props.portalPath.options, 'group')
  
  let disableIconStyle = {}
  let disableClickEvent = {}
  if(props.currentUser.current_admin.admin_role_name == 'demo_user'){
    disableIconStyle.color = 'grey'
    disableClickEvent.pointerEvents = 'none'
  }

  const nonAdminAccessList = ['activities', 'favorite_colleges']

  /*o.forEach((node)=>{
    node.has_access = CheckAccess.hasPathAccess(props.journeyCategory, access_list, node.sid)
  })*/

  useEffect(() => {
    setTimeout(() => {
      for (const g in groups) {
        if(document.getElementById(`path_icons_${g}`).childElementCount == 0) 
          document.getElementById(g).style.display = "none"
      }
    }, 0);
  }, [])

  const Icon = ({icon, icon_color, path}) =>{
    if(nonAdminAccessList.includes(path.sid) || journeyCategory.is_admin_user){
      return(
        <div className="three-half">
          <div className="ht-60" style={{backgroundColor:icon_color}} onClick={e=>props.routePath(e, path)}>
            <i className={'fa '+icon + ' m-t-10'} style={{color:"white"}} data-tip={path.describe}/>
            <span className="notification">{report[path.sid] || 0}</span>
          </div>
          <div>{path.label}</div>
        </div>
      )  
    }else{
      return null
    }
  }

  const Icons = ({icons}) => icons && icons.map((p, i) => (
    <Icon icon={p.icon} icon_color={p.icon_color} path={p}  key={i}/>
  ))

  const Groups = ({}) => Object.keys(groups).map((g, i) =>
    <div className="col-xs-12 col-sm-6 col-md-4" key={i} id={g}>
      <div className="edu-path">
        <div className="head">{g}</div> 
        <div className="icons" id={`path_icons_${g}`}>
          <Icons icons={groups[g]}/>
        </div>     
      </div>  
    </div>
  )

  if(groups){
    return (
      <div className="row margin-unset">
        <Groups/>
        <ReactTooltip place="bottom" />
      </div>
    )
  }else{
    return null
  }

}

export default PortalPath;