import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import querystringify from "querystringify";
import NotificationRoute from  "../Notifications/NotificationRoute"

const RedirectNotification = (props) => {
  let queryParam = querystringify.parse(props.location.search);
  let data = queryParam.data;

  try{
    NotificationRoute.routeNotification(JSON.parse(data), props)
  }catch(e){

  } 

  return (<div className="spinner"></div>)

};

export default RedirectNotification;

