import env from '../../env';
import axios from 'axios';
import CommonService from '../../services/CommonService'
const url = `${env.family_tree_backend}chats`

class CommentsService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params: req}).then((res) => {
        // handle success
        res.data.chats.forEach((item)=>{
          if(!item.file_upload_id){
            item.message = CommonService.linkify(item.message)
          }
        })

        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  add(note){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, note).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  chatbot(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.backend}chatbot`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(note){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${note.id}`, note).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new CommentsService();