import React, {Fragment, useState, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import CommonService from "../../services/CommonService"

const UserSubscription = (props) => {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  let [usageReport, setUsageReport] = useState(null)
  let [subscriptions, setSubscriptions] = useState([])
  let [journeyMembers, setJourneyMembers] = useState([])

  useEffect(() => {
    /*CommonService.getUsageReport().then((res)=>{
      setUsageReport(res.data.usage_report)
    })*/
    if(['parent', 'tutor'].includes(currentUser.user_type)){
      setJourneyMembers(Object.values(currentUser.journey_members))
    }else{
      setSubscriptions([currentUser.current_subscription])
    }
  }, [])

  const JourneyMembers = () => journeyMembers.map((s, i) => {
    let sub = s.subscription
    return(
      <tr key={i}>
        <td>{s.data?.name}-{sub?.name}</td>
        <td>{CommonService.formatUpdateDate(s.created_at)}</td>
        <td>$ {sub?.monthly_fee}</td>
        <td>{sub?.payment_type == 'month'?'Monthly':'Yearly'}</td>
        <td>{CommonService.formatUpdateDate(s.created_at)}</td>
        <td>$ {sub?.monthly_fee}</td>
        <td>312AX97BY4Z</td>
      </tr>
    )
  })

  const Subscriptions = () => subscriptions.map((s, i) => {
    let sub = s.subscription
    return(
      <tr key={i}>
        <td>{sub.name} </td>
        <td>{CommonService.formatUpdateDate(sub.created_at)}</td>
        <td>$ {sub.monthly_fee}</td>
        <td>{sub.payment_type == 'month'?'Monthly':'Yearly'}</td>
        <td>{CommonService.formatUpdateDate(s.created_at)}</td>
        <td>$ {sub.monthly_fee}</td>
        <td>312AX97BY4Z</td>
      </tr>
    )
  })

  const UsageReport = ({list, paymentType}) => list && list.map((s, i) => {
    return(
      <tr key={i}>
        <td className="text-cap">{s[0].replace(/_/g, ' ')}</td>
        <td>{paymentType}</td>
        <td>{s[1]}</td>
      </tr>
    )
  })

  return(
    <Fragment>
      <div className="content">
        <h1 className="mb-0 pt-1">Subscriptions</h1>
        
        <div className="col-xs-12 scroll-auto">
          <table id="subscription">
            <tbody>
              <tr>
                <th>Plan</th>
                <th>Start</th>
                <th>Rate</th>
                <th>Period</th>
                <th>Payment Date</th>
                <th>Payment Amount</th>
                <th>Reference</th>
              </tr>
              <Subscriptions/>
              <JourneyMembers/>
            </tbody>
          </table>
        </div>

        <div className="col-xs-12 m-t-5">
          <div className="cancel-txt hidden">Account cancellation date: 05/31/2021</div>
          {subscriptions.length > 0 && 
            <Link to="/plans" className="ml-button pull-right subscribe-btn bg-red">Cancel Subscription</Link>
          }
          {subscriptions.length == 0 && 
            <Link to="/plans" className="ml-button pull-right subscribe-btn bg-lgreen">Start New Subscription</Link>
          }
        </div>
          
      </div>

      <div className="content m-t-15">
          <h1 className="mb-0 pt-1">Usage Record</h1>
          {usageReport &&
            <div className="col-xs-12 scroll-auto">
              <table id="subscription">
                <tbody>
                  <tr>
                    <th>Journey Type</th>
                    <th>Plan</th>
                    <th>Usage</th>
                  </tr>
                  <UsageReport list={Object.entries(usageReport.paid_journey)} paymentType="Usage Based"/>
                  <UsageReport list={Object.entries(usageReport.free_journey)} paymentType="Free"/>
                </tbody>
              </table>
            </div>
          }
      </div>
    </Fragment>
  )
}

export default UserSubscription;

/*
<div className="col-xs-12 col-sm-6 m-t-5 lh-30 font-16">
        <div><b>Subscription Plan:</b>  Introductory 2021 Plan</div>
        <div><b>Amount:</b> US$3.99</div>
      </div>
      
      <div className="col-xs-12 col-sm-6 m-b-5 lh-30 font-16">
        <div><b>Date:</b> 04/22/2021</div>
        <div><b>Type:</b> Monthly</div>
      </div>
*/