import React, {useState} from "react"
import {Link} from "react-router-dom"
import CommonService from '../../../services/CommonService'
import { HamburgerMenuIcon } from "../../Common/ImageIcons"

const Feedback = (props) => {
  let feedback = props.feedback
  const [data, setData] = useState(feedback.data)
  
  const cardStyle = {
    borderTop: '3px solid ',
    height: '60px'
  }

  const onStatusUpdate = (data) =>{
    setData({...data})
  }

  if(data !== null){
    return (
    <div className="bg-white border-rounded-10 m-t-10">
      <div className="card-title card-title-gray flex aligncenter p10 p-b-15"  style={cardStyle}>
        <div className="flex aligncenter">
          <a id="head" className="content">
            <div className="w-100">
              <h4 className="fw700 card-heading truncate">{data.title}</h4>
              <p className="f12">since {CommonService.formatUpdateDate(feedback.updated_at)}</p>
            </div>
          </a>
        </div>
      </div>
      <div className="card-content p10-20">
        <div className="b-tab-table active scroll-auto ht-100 m-t-5">
          <div className="vcard-field">
            <strong>Type of Feedback</strong>
            <div className="font-15">{data.type_of_feedback}</div>
          </div>

          <div className="vcard-field">
            <strong>Notes</strong>
            <div className="font-15">{data.note_explain_feedback}</div>
          </div>
        </div>
      </div>
      <div className="card-footer flex justspacebetween p10-20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">
            <span onClick={e=>props.updateStatus(feedback, onStatusUpdate)}>
              <i className={`far ${data.status==1?'fa-check-circle':'fa-circle'} font-18`}/>
            </span>
          </li>
        </ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, feedback)}/>
      </div>
    </div>
    )
  }else{
    return null
  }
  
}

export default Feedback;