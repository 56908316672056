import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../../hooks/useStyle';
import useSort from '../../../hooks/useSorting';
import SortDirection from '../../../components/Common/SortDirection'
import {PermissionList, NotificationList} from './NotificationPill'

const UsersRolesListTable = (props) => {
  useStyle('table');  
  const {sort} = useSort(props.requestParams, props.getRoleList);
  let {user_role, addUser, dataSources:{permissions, notificationsTypes, roleType}} = props;

  const tableData = props.userRolesList.map((user_role, k) =>
    <tr key={k}>
      <td data-title="Id">{k}</td>
      <td data-title="Email">
        {user_role.email }
      </td>
      <td data-title="Permissions">
        <PermissionList list={user_role.permissions} obj={permissions}/>
      </td>
      <td data-title="Notification Step Activity">
        <NotificationList list={user_role.notification_step_activity} obj={notificationsTypes}/>
      </td>
      <td data-title="Notification Comment Posting">
        <NotificationList list={user_role.notification_comment} obj={notificationsTypes}/>
      </td>
      <td>
        {user_role.id && <div className="action-btn bg-highlight" onClick={e=>{props.selectUserRole(e, user_role)}}>Remove</div>}
        {!user_role.id && <div className="action-btn bg-highlight" onClick={e=>{addUser(e, user_role)}}>Add</div>}
        {user_role.id && <div className="action-btn bg-highlight" onClick={e=>{props.setEdit(e, user_role)}}>Edit</div>}
      </td>
    </tr>
  )

  return (
    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className="text-center">Id 
              <SortDirection sort_column={props.requestParams.sort_column} column="record_id" reverse={props.requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('filter_type')} className="text-center">Email 
              <SortDirection sort_column={props.requestParams.sort_column} column="filter_type" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('permissions')} className="text-center">Permissions
              <SortDirection sort_column={props.requestParams.sort_column} column="permissions" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('notification_step_activity')} className="text-center">Notification Step Activity
              <SortDirection sort_column={props.requestParams.sort_column} column="notification_step_activity" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('notification_comment')} className="text-center">Notification Comment 
              <SortDirection sort_column={props.requestParams.sort_column} column="notification_comment" reverse={props.requestParams.reverse}/>
            </th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

};

export default UsersRolesListTable;