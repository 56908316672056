import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../../../../hooks/useStyle';
import CommonService from "../../../../../services/CommonService";

let requestParams = {}
const PlannerTypeMenu = ({ journey_category_id, journey_profile_id, isOpen, toggleModal }) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [activityCategory, setActivityCategory] = useState();

  useEffect(() => {
    requestParams = {
      data_source_id: [2214],
    }
    getPlannerCategory();
  }, [])

  let getPlannerCategory = () => {
    CommonService.getDataSource(requestParams).then((res) => {
      let d = CommonService.groupBy(res.fj_edu_entry_type.options, "actions");
      setActivityCategory(d);
    })
  }

  const List = (key) => activityCategory[key].map(k => 
    <Fragment key={k.value}>
      <Link className="ver-icons"
        to={`/highschool/planner/form/${k.label}/${k.Planner_form}/${k.value}?journey_category_id=${journey_category_id}&journey_profile_id=${journey_profile_id}`}>
        <a href="/">
          <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
        </a>
        <div className="popup-menu-title">{k.label}</div>
        <i className="fas fa-chevron-right lgrey pull-right" />
      </Link>
    </Fragment>  
  )

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom" style={{width: '400px'}}>
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select a planner</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          {!activityCategory ? <h4 className="text-center">Loading</h4> :
            <div id="ct">
              <div className="p-5 scroll-x" style={{height:screen.height-120}}>
                <div className="popup-menu-grid"> 
                  <div className="head">Academic</div>
                  {List('academic')}
                </div>

                <div className="popup-menu-grid"> 
                  <div className="head">Extracurricular</div>
                  {List('extracurricular')}
                </div>
                
                <div className="popup-menu-grid"> 
                  <div className="head">Special experience</div>
                  {List('special_experience')}
                </div>

                <div className="popup-menu-grid"> 
                  <div className="head">4-Year HS Schedule</div>
                  {List('4-Year HS Schedule')}
                </div>
              </div>
              <div className="p-5">2214</div>
            </div>
          }
            
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default PlannerTypeMenu;