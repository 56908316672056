import React, {useState, useEffect, useContext, Fragment} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import User from "../../Journey/UserRoles/User";
import UserRoleService from "../../Journey/UserRoles/UserRoleService";
import RoleService from "../../Journey/Roles/RoleService";
import InvitedUserService from "../../Journey/InvitedUsers/InvitedUserService";
import ConfirmMenu from '../../Modals/ConfirmMenu'
import AddRole from '../../Journey/Roles/AddRole'
import {Link} from "react-router-dom";
import useModal from '../../../hooks/useModal';
import useStyle from '../../../hooks/useStyle';
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import {NavIcon} from '../../Common/MenuItem'
import Cs from "../../../services/CommonService";

let requestParams = {}
let newRole = {};
let dataSources = {};
let rolesUser = [];
let newInviteUser = {};
let selectedInviteUser = {};
let selectedRole = {};

const UsersList = (props) => {
  useStyle('user_lists');
  useStyle('add_role_dialog');
  
  let params = props.match.params;

  const { state:currentUser, dispatch } = useContext(AuthContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id':params.journey_category_id,
      'roleable_id': params.roleable_id,
      'roleable_type': params.roleable_type,
      'is_shared_with_user':true
    }
    getUsers();
  }, [props.searchString]);

  let getUsers = () => { 
    UserRoleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){ 
        setUsers(res.data.user_roles) 
       }
     })
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                User Lists
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="home_icon" dataTip="Back to Journey Home" 
              onSelect={e=>Cs.routeJourney(props.location.state.journey, props)}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-home"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              { users && users.map((u, i) => (
                  <div className="col-xs-12 col-md-3" key={i}>
                    <User user={u} key={i} />
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
};

export default UsersList;

