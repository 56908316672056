import React, {useState, Fragment, useEffect} from "react";
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import TrainingModule from '../../TrainingModules/List/TrainingModule';
import TrainingModuleService from "../../TrainingModules/TrainingModuleService";
import FavouritesService from "../../../../../services/FavouritesService";
import Pagination from '../../../../Pagination'
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from '../../../../../constants/FormTemplateList';
import CommentsModal from "../../../../Modals/CommentsModal";
import CardMenu from "../../../../Modals/CardMenu"
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import useStyle from '../../../../../hooks/useStyle';
import CheckAccess from '../../../Roles/CheckAccess';
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal"
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import {usePageTour} from "../../../../../components/Common/usePageTour"
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import HelpMessageList from '../../../../../constants/HelpMessageList'
import {NavIcon,MenuItem,CardMainMenu} from '../../../../Common/MenuItem'

let requestParams = {}
let selectedActivity = {};
let data_source = {};
let journey_category = null;

const TrainingModuleList = (props) => {
  useStyle('card');
  useStyle('card_table');
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let project = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
 
  let cardGridCss = 'col-xs-12 col-sm-6 col-md-4'
  let cardHeightCss = 'ht-227'
  if(queryParam.id){
    cardGridCss = 'col-sm-12 col-sm-6 col-md-8'
    cardHeightCss = 'mih-380'
  }

  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [trainingModuleList, setTrainingModuleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState();
  const [isFavourite, setIsFavourite] = useState(false); 
  
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal();
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const { isOpen:isActivityTypeOpen, toggleModal:toggleActivityTypeModal } = useModal();
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  const { isOpen:isFileLinkOpen, toggleModal:toggleFileLinkModal } = useModal();
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal();
  let tour = usePageTour();

  useEffect(() => {
    journey_category = null;
    requestParams = {
	    'data': {},
	    'is_favourites':isFavourite,
      'data_source_params': {
        "data_source_id":[3344 ,2216,2223,2224,2235,2861,3250,3255,2240,3256,2244,2232,2233,2234,2256,2257,2258,2226,2227,2228,2230,2219,2220,2222,2221,2214,3125,3126,3127,3128,3129,3130,3131,3132,3133,3134,3289,3124],
        "response_type":"object"},
      'education_journey_profile_id':params.education_journey_profile_id,
      'filter_by_role':false,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'id':queryParam.id,
      'exists_in_planner':true
    }

    getJourneyActivityList();

  }, [props.searchString, currentpage,isFavourite]);

  let getJourneyActivityList = () => { 
    setLoading(true)
    TrainingModuleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        data_source = res.data.meta.data_source_list;
        //setPagemeta(res.data.meta)
        requestParams.totalItems = res.data.meta.total;
        journey_category = res.data.journey_category;
        CheckAccess.userAccess(authState.user, journey_category, res.data.invited_journey_member);
        setTrainingModuleList(res.data.education_journal_entries)
        setLoading(false)
        res.data.education_journal_entries.length == 0 && toggleHelpMessage()
        if(currentpage==1){
          setTimeout(function() {
            tour && tour.start()
          }, 1000);
        }
      }
    })
  }

  const pinFavourite = (e) =>{
    e.preventDefault()
	  setIsFavourite(!isFavourite)
  }

  const openFileLinks = (e) =>{
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const addChecklist = (e, a) => {
    selectedActivity = a;
    toggleChecklistModal(e);
  }

  const addNotes = (e, a) => {
    selectedActivity = a;
    toggleCommentModal(e);
  }

  const deleteActivity = (e) => {
    e.preventDefault();
    let req = {id:selectedActivity.id, journey_category_id:params.journey_category_id}
    TrainingModuleService.delete(req).then((res)=>{
      if(res.status==204){
        setTrainingModuleList(trainingModuleList.filter(i => i.id !== selectedActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const showMenu = (e, a) => {
    selectedActivity = a;
    toggleMenuModal(e);
  }

  const routeActivity = (activity) =>{
    props.history.push(`/highschool/activity/${params.journey_category_id}/${params.education_journey_profile_id}?id=${activity.id}`)
    setTrainingModuleList(trainingModuleList.filter(i => i.id == activity.id))
  }

  const menuItems = ({item}) =>{
    const title = item.activity_type && item.activity_type.replace(/\s/g, '_')
    const editAccess = CheckAccess.hasEditAccess(authState.user, journey_category, item);

    return(
      <Fragment>
        {editAccess && 
          <MenuItem id="edit_icon" 
            link={`/highschool/activity/edit/${item.journey_category_id}/${item.education_journey_profile_id}/${item.id}?edit=${editAccess}`}
            icon="far fa-edit"
            action="Edit"
            />
        }
        
        {CheckAccess.hasDeleteAccess(authState.user, journey_category, item) && 
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDeleteModal(e);}}
          />
        }

        {CheckAccess.hasStepActivityAccess(authState.user, journey_category, item) &&
         <Link to={`/highschool/step_activity/form/${item.journey_category_id}/${item.education_journey_profile_id}/${item.id}/${FormTemplateId.EducationActivity}/step_activity`}>
          <i className="far fa-sticky-note font-15" aria-hidden="true"></i>
          <span className="font-13">Add Activity</span>
          <i className="fa fa-angle-right"></i>
         </Link>}

        {editAccess &&  
        <Link to={{pathname:`/journey/user_role/${item.journey_category_id}/${item.education_journey_profile_id}/education_journal_entry/${item.id}`, state:{journey:journey_category, activity:item} }}>
          <i className="fa fa-users font-15" aria-hidden="true"></i>
          <span className="font-13">Add User</span>
          <i className="fa fa-angle-right"></i>
        </Link>}

        <a href={`mailto:${title}-EA-${item.record_id}@cuetree.com`} target="_blank"> 
          <i className="far fa-envelope font-15" aria-hidden="true"></i>
          <span className="font-13">{`${title}-EA-${item.record_id}@cuetree.com`}</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ activity}) => (
    <CardMainMenu activity= {activity} showMenu={showMenu} />
  )
  
  const trainingModuleCard =  trainingModuleList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <TrainingModule addNotes={addNotes}  addChecklist={addChecklist} activity={a}  dataSource={data_source} menu={Menu} cardHeightCss={cardHeightCss} routeActivity={routeActivity} index={i}/>

      {selectedActivity.id == a.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedActivity} type="activity" MenuItems={menuItems} menuHeight="365px"/>
      }
    </div>
  ))
  
  /*if(trainingModuleList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  if(queryParam.id && !loading && trainingModuleList.length == 0){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Not Found" message="Sorry, this message was deleted by the author"/>)
  }

  return (
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Activities"} project={journey_category} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="add_new_activity" dataTip="Create New Activity" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={toggleActivityTypeModal} icon="fas fa-plus"/>

          {journey_category && 
            <NavIcon id="tile_view" dataTip="Tile View" 
            link={{pathname:`/highschool/activity/type/${params.journey_category_id}/${journey_category.education_journey_profiles.id}`,
              state:journey_category}}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-file"/>
          }

          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/highschool/project/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
          
          <NavIcon id="file_links" dataTip="Journey Files and Links" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={openFileLinks} icon="fas fa-link"/>

          {!queryParam.id && <NavIcon id="pin" dataTip="Filter Favourites" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={pinFavourite} icon={"fas fa-thumbtack " + (isFavourite ? 'black' : 'white')}/>}
        </div>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {trainingModuleCard}
        {queryParam.id && trainingModuleList.length>0 && <div className="col-sm-12 col-sm-6 col-md-4">
          <CommentsModal isOpen="true" toggleModal={toggleCommentModal} item={trainingModuleList[0]} type="education_journal_entry" showInline="true"/>
        </div>}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedActivity} type="education_journal_entry"/>}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedActivity} type="education_journal_entry"/>}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity}/>
      {isFileLinkOpen && <JourneyFilesLinksModal toggleModal={toggleFileLinkModal} journeyCategory={journey_category} journeyType="training_journey"/>}
      <SideMenuBar helpId="8"/>
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      )}
      <ReactTooltip place="left" />
    </Fragment>
  )

};

export default TrainingModuleList;