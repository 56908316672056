import React, {useState, Fragment, useEffect, useContext} from "react"
import {Link} from "react-router-dom"
import moment from 'moment/min/moment.min'
import useStyle from '../../../../hooks/useStyle'
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import Cs from '../../../../services/CommonService'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import AssessmentService from "../Assessments/AssessmentService"
import JourneyTitle from "../../JourneyTitle/JourneyTitle"
import {NavIcon, CardMenuInfo, MenuItem, CardMainMenu} from '../../../Common/MenuItem'
import Pagination from '../../../Pagination'

let requestParams = {}

const ExamList = (props) => {
  useStyle('table')  

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [exams, setExams] = useState([])
  const [journey, setJourney] = useState({}) 
  const [currentpage, setCurrentpage] = useState(1) 
  const [loading, setLoading] = useState(true) 
  const [pagemeta, setPagemeta] = useState([])

  let params = props.match.params;
  let project = props.location.state

  useEffect(() => {
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'per_page':20,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'is_published':true,
      'join_submissions':true,
    }

    getExamList()

    return () => {
     requestParams = {} 
    }
  }, [props.searchString, currentpage])

  let getExamList = () => { 
    setLoading(true)
    AssessmentService.getAllExams(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setJourney(res.data.journey_category)
        setExams(res.data.assessments)
        setLoading(false)
      }
    })
  }
  
  const routeForm = (assessment) => {
    if(!assessment.answer_id){
      props.history.push(`/career_training/question/${assessment.journey_category_id}/${assessment.journey_profile_id}/${assessment.id}`)    
    }
  }

  const {sort} = useSort(requestParams, getExamList)

  const tableData = exams.map((q, k) =>{
    return(
      <tr key={k} className="bold-100">
        <td data-title="Id">{q.record_id}</td>

        <td className="font-16 miw-95 maw-95">
          {q.data.record_title}
        </td>

        <td className="font-16 miw-100 maw-120">
          <span dangerouslySetInnerHTML={{ __html: q.data.details}}/>
        </td>
     
        <td className="font-16 maw-50">
          {Cs.formatUpdateDate(q.data.date_time_formatted_date, 'MMM DD, YYYY hh:mm a')}
        </td>

        <td className="font-16 maw-50">
          {Cs.formatUpdateDate(q.data.submission_deadline_formatted_date, 'MMM DD, YYYY hh:mm a')}
        </td>

        <td className="font-16">
          {q.answer_id ? 'Completed' : 'Open'}
          <div>{Cs.formatUpdateDate(q.submitted_at, 'MMM DD, YYYY hh:mm a')}</div>
        </td>

        <td className="font-16">
          {q.total_score_earned?q.total_score_earned:'Not graded'}/{q.total_score}
        </td>

        <td className="font-16 maw-50">
          {q.is_answer_window_open ? <>
              <span onClick={e =>routeForm(q)}>
                <i className={`far fa-file-alt font-18 ${q.answer_id?'green':'text-muted'}`}/>
              </span>

              <br/>
              <small className="text-muted">{q.answer_id ? 'Grade Posted' : 'Exam is active, but you have not opened it yet'}</small>
            </> : <small className="text-muted">Exam is Inactive</small>
          }
        </td>
      </tr>
    )
  })

  if(loading){
    return(<div className="spinner"/>)
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4">
          <JourneyTitle title="Exams" project={journey || project}/>
        </div>

        <div className="col-md-8 float-right">         
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/career_training/project/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
        </div>
      </div>

      <div className="table-responsive">
        <table className="shadow-small">
          <thead>
            <tr className="white bg-black">
              <th onClick={e=>sort('record_id')}>Id 
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </th>
              <th onClick={e=>sort('data.record_title')} className="miw-95 maw-95">Subject 
                <SortDirection sort_column={requestParams.created_at} column="data.record_title" reverse={requestParams.reverse}/> 
              </th>
              <th onClick={e=>sort('data.details')} className="miw-100 maw-120">Details
                <SortDirection sort_column={requestParams.sort_column} column="data.details" reverse={requestParams.reverse}/>
              </th>
              <th onClick={e=>sort('data.date_time_formatted_date')} className="maw-50">Start 
                <SortDirection sort_column={requestParams.sort_column} column="data.date_time_formatted_date" reverse={requestParams.reverse}/>
              </th>
              <th onClick={e=>sort('data.submission_deadline_formatted_date')} className="maw-50">Submit
                <SortDirection sort_column={requestParams.sort_column} column="data.submission_deadline_formatted_date" reverse={requestParams.reverse}/>
              </th>
              <th onClick={e=>sort('status')}>Status 
                <SortDirection sort_column={requestParams.sort_column} column="status" reverse={requestParams.reverse}/>
              </th>
              <th onClick={e=>sort('total_score')}>Score
                <SortDirection sort_column={requestParams.sort_column} column="total_score" reverse={requestParams.reverse}/>
              </th>  
              <th className="maw-50">Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData}
          </tbody>
        </table>
      </div>

      <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
      setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
    </Fragment>
  )

};

export default ExamList;