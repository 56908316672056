import React, { useState, useEffect, Fragment, useMemo } from "react";
import useStyle from "../../../../hooks/useStyle";
import { useHistory } from "react-router-dom";
import JourneyProfileService from "../../../Journey/JourneyProfileService";
import useModal from "../../../../hooks/useModal";
import ActivityForm from "../Activities/Form/ActivityForm"
import JourneyProfileForm from "../../Form/JourneyProfileForm"
import GenericModal from "../../../Modals/GenericModal"

const UpcomingActivities = (props) => {
  useStyle("notifications");
  let history = useHistory();

  const [upcomingActivities, setUpcomingActivities] = useState(
    props.upcomingActivities
  )
  const [unfilledField, setUnfilledField] = useState()

  const { isOpen:isFieldOpen, toggleModal:toggleFieldModal } = useModal()

  if (!upcomingActivities) return null;

  const onSubmit = () =>{

  }

  const getProps = () =>{
    if(unfilledField.item_type === "education_journey_profile"){
      ///journey_profile/${suggestion.education_journey_profile_id}?form_id=${suggestion.form_template_id}
      return {
        component:JourneyProfileForm,
        toggleModal:toggleFieldModal,
        onSubmit:onSubmit,
        isPopupMode:true,
        match:{
          params:{
          journey_profile_id: unfilledField.education_journey_profile_id,
          id: unfilledField.item_id
        }},
        location:{
          search: `?form_template_id=${unfilledField.form_template_id}&unfilled_client_id=${unfilledField.unfilled_client_id}&show_form_wizard=1`
        }
      }
    }else if(unfilledField.item_type === "education_journal_entries"){
      return {
        component:ActivityForm,
        toggleModal:toggleFieldModal,
        onSubmit:onSubmit,
        isPopupMode:true,
        match:{
          params:{
          journey_profile_id: unfilledField.education_journey_profile_id,
          id: unfilledField.item_id
        }},
        location:{
          search: `?form_template_id=${unfilledField.form_template_id}&unfilled_client_id=${unfilledField.unfilled_client_id}&goBack=1`
        }
      }
    }
  }

  const openFormWizard = (suggestion) => {
    setUnfilledField(suggestion)
    if (suggestion.item_type === "education_journal_entries"){
      toggleFieldModal()
      /*history.push(
        `/highschool/activity/edit/${suggestion.journey_category_id}/${suggestion.education_journey_profile_id}/${suggestion.item_id}?form_wizard_schedule_id=${suggestion.form_wizard_schedule_id}&unfilled_client_id=${suggestion.unfilled_client_id}`
      )*/
    }else if (suggestion.item_type === "college_cues") {
      history.push(
        `/highschool/college/review/${suggestion.journey_category_id}/${suggestion.education_journey_profile_id}/0/0/${suggestion.item_id}?form_wizard_schedule_id=${suggestion.form_wizard_schedule_id}&unfilled_client_id=${suggestion.unfilled_client_id}`
      )
    }else if (suggestion.item_type === "education_journey_profile"){
      toggleFieldModal()
      /*history.push(
        `/journey_profile/${suggestion.education_journey_profile_id}?form_id=${suggestion.form_template_id}`
      )*/
    }
  }

  const updateReminder = (activity, ev) => {
    ev.stopPropagation();
    let req = { is_remind_later: !activity.is_remind_later };
    JourneyProfileService.updateActivityReminder(activity, req).then((res) => {
      if (res.status == 204) {
        setUpcomingActivities(
          upcomingActivities.filter((r) => r.record_id !== activity.record_id)
        )
      }
    })
  }

  const ActivityTypes = () => {
    const itemTypeList = useMemo(() => Object.keys(upcomingActivities), [upcomingActivities]);

    return (
      <Fragment>
        {itemTypeList.map((type, k) => 
          <ActivityList items={upcomingActivities[type]} key={k}/>
        )}
      </Fragment>
    );
  }

  const ActivityList = ({ items }) => {
    const itemIds = useMemo(() => Object.keys(items), [items]);
    return (
      <Fragment>
        {itemIds.map((id, k) => 
          <ActivityFields fields={items[id]} key={k} />
        )}
      </Fragment>
    )
  }

  const ActivityFields = ({ fields=[] }) => {
    const activity = fields[0] || {}
    const style = { backgroundColor: activity.data?.border_color || "blue" };

    return (
        <div className="col-xs-12 col-sm-6 col-md-4 m-t-5 clearfix">
          <div className="notification bg-theme shadow-xl m-5">
            <div className="toast-header white mht-40" style={style}>
              <strong className="mr-auto font-16">
                {activity.data?.item_name || activity.data?.category}
              </strong>
            </div>
            <div className="toast-body">
              {fields.map((f, k) => 
                <TodoActivities uc={f} key={k} />
              )}
              <div>
                {activity.form_template_id}-{activity.form_wizard_id}
              </div>
            </div>
          </div>
        </div>
    )
  }

  const TodoActivities = ({ uc }) => {
    return (
      <div onClick={()=>openFormWizard(uc)} className="next-action-text br-btm-grey p-5 clearfix">
        <div className="col-xs-12 d-flex">
          <div className="font-24">
            <i className="far fa-dot-circle grey"/>
          </div>
          <div className="m-l-15 w-100p">
            <h5 className="pointer lh-20 font-14">
              {uc.label}
            </h5>
            <div className="lh-18" dangerouslySetInnerHTML={{ __html: uc.cue_prompt_description}}></div>
          </div>
          <div className="font-18 pull-right">
            <i onClick={(e) => updateReminder(uc, e)} className="fas fa-remove lgrey m-l-10"/>
          </div>
        </div>
      </div>
    )
  }

  const TodoActivitiesPanel = ({ uc, openFormWizard }) => {
    uc.data = uc.data || {};
    let style = {
      borderLeft: `10px solid ${uc.data.border_color || "#607D8B"} `,
    }

    return (
      <Fragment>
        <div className="m-t-5 pull-right" onClick={(e) => updateReminder(uc, e)}>
          <i className="fa fa-times color-red2-dark " />
        </div>
        <div className="panel-xs pointer" style={style} onClick={(e) => openFormWizard(uc)}>
          <div>{uc.data.item_name || uc.data.category} - {uc.label}</div>
          <div>
            <u>{uc.label}</u>
          </div>
        </div>
      </Fragment>
    )
  }

  const TodoActivitiesList = ({ uc, openFormWizard }) => {
    uc.data = uc.data || {}
    let style = {
      borderLeft: `10px solid ${uc.data.border_color || "#607D8B"} `,
    }

    return (
      <Fragment>
        <div className="m-t-5 pull-right">
          <i className="fas fa-chevron-right lgrey font-15" />
        </div>
        <div className="panel-xs pointer m-t-5" style={style} onClick={(e) => openFormWizard(uc)}>
          <div className="m-l-5">{uc.data.item_name || uc.data.category} - {uc.label}</div>
        </div>
      </Fragment>
    )
  }

  if(isFieldOpen) 
    return <GenericModal {...getProps()}/>

  if(props.page_name === "journey_dashboard") {
    return (
      <Fragment>
        {upcomingActivities.map((uc, k) => (
          <TodoActivitiesPanel
            uc={uc}
            openFormWizard={openFormWizard}
            key={k}
          />
        ))}
      </Fragment>
    );
  } else if (props.page_name === "journey_list") {
    /*return (
      <>
        {upcomingActivities?.length > 0 ? upcomingActivities.map((uc, k) => 
          <TodoActivitiesList uc={uc} openFormWizard={openFormWizard} key={k} />
        ):null}
      </>
    );*/
      return null
  } else {
    return <ActivityTypes/>
  }
};

export default UpcomingActivities;