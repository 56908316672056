import React, {useState, useEffect} from "react"
import importScript from '../../hooks/importScript'
import env from '../../env'

let url = env.socket;
let socket = {}

const Socket = (currentUser, setNotification, setSocketData) => {
    
    let [isConnected, setIsConnected] = useState(false)
    
    useEffect(() => {
        if(!isConnected)importScript('/scripts/faye.js', init)
        return () => {
          try{  
            socket.subscription.cancel()
            socket.fayeClient.disconnect()
          }catch(e){
            console.log(e)
          }  
        }

        if (window.Notification && Notification.permission !== "granted") {
            Notification.requestPermission(function (status) {
                if (Notification.permission !== status) {
                    Notification.permission = status
                }
            })
        }
    }, []);

    function init(){
        if(!currentUser)return;
        let sender_channel = '/notification_channel_'+currentUser.id
        socket.fayeClient = new window.Faye.Client(url, {timeout: 120, retry: 3})
        //client.disable('in-process');
        //client.disable('long-polling');
        //client.disable('callback-polling');
        setIsConnected(true)
        socket.subscription = socket.fayeClient.subscribe (sender_channel, function (res) {
            if (window.Notification && Notification.permission === "granted") {
                systemNotification(res)
            }else if (window.Notification && Notification.permission !== "denied") {
              Notification.requestPermission(function (status) {
                if (status === "granted") {
                   systemNotification(res) 
                }else {
                    uiNotification(res)
                }
              })
            }else {
               uiNotification(res)
            }
            //uiNotification(res);
        })
    }

    const uiNotification = (res) =>{
        if(res.notification_type == 'chat'){
            res.data.notifiable_type = 'chat'
            setSocketData && setSocketData(res.data)
        }
        res.data.sender_name = res.sender_name
        setNotification(res.data)
    }

    const systemNotification = (res) =>{
        let notification = {}
        if(res.notification_type == 'chat'){
            notification = new Notification(`Chat-${res.data.author.name}`, {
                body: res.data.message,
                icon: 'images/cueTree_logo.png'
            })
            setSocketData && setSocketData(res.data)
        }else{
            notification = new Notification(res.data.message, {
                body: res.data.email_data.title,
                icon: 'images/cueTree_logo.png'
            })
        }  

        notification.onclick = function(event) {
            event.preventDefault() // prevent the browser from focusing the Notification's tab
            if(res.notification_type == 'chat'){
                
            }else{
                window.open('https://edu.beta.cuetree.com/redirect?data='+JSON.stringify(res.data || {}))    
            }
        }
    }

}

export default Socket;