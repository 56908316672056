import React, { useEffect, useState, useContext } from 'react'
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormContainer from '../../FormBuilder/FormContainer'
import FormTemplateId from '../../../constants/FormTemplateList'
import HelpService from '../HelpService'

let formMode = null
let formId = null
let help = {}
let data = {} 
let formFn = {} 

function HelpForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let currentUser = authState.user
  let queryParam = querystringify.parse(props.location.search)

  /*TODO: Move API to Router to load data before view*/
  const getById = (id) => {
    HelpService.get(id).then((res) => {
      if(res.status==200){
        help = res.data.help
        data = help.data || {}
        setFormRendered(true)
      }
    }).catch(function(res){
      help = null
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions'
    data = {}
    help = {} 
    formId = FormTemplateId.HelpRegistration
    formFn = {
      'form_type':'help', 
      'editPermission':currentUser.current_admin.role_content
    }
   
    if(params.id){
      getById(params.id)
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (help) => {
    help.created_by = authState.user.id
    help.is_user_suggested = true
    help.data.page = queryParam.page
    HelpService.create(help).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    })
  }

  const update = (help) => {
    HelpService.update(help).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = (id) => {
    props.history.push("/help")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={formId} form={help} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} 
          onCancel={onSubmitPageRedirect} 
          setPost={setPost} />
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default HelpForm;