import React, {useState} from 'react';
import {CueTooltip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import ImageMapModal from '../ImageMapModal'
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const InputRichImageMap = ({field, formData, readOnly, errors, formFn, isFormWizard, user, openFieldLabelModal, currentLocale}) => {
  const [showError, setShowError] = useState(false);
  const [imageMapData, setImageMapData] = useState();
  const [isImageMapOpen, setIsImageMapOpen] = useState(false);

  let KEY_NAME = 'SummerHTMLImageMapCreator';

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }

  const openImageMap = (e) => {
    let imageMapData = formData[field.client_id] || {}
    imageMapData.user_id = user.id
    window.sessionStorage.setItem(KEY_NAME, JSON.stringify(imageMapData));
    setImageMapData(imageMapData)
    setIsImageMapOpen(true);
  }

  const closeImageMap = (e) =>{
    let imageMapData = JSON.parse(window.sessionStorage.getItem(KEY_NAME));
    formData[field.client_id] = imageMapData;
    setIsImageMapOpen(false)
  }

  const setError = (e) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[name]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setDisabled(field, inputAttributes);
  return (
    <div className="form-group col-xs-12 col-sm-6" title="tooltip">
     <FieldLabel field={field} labelAttributes={labelAttributes} 
      currentLocale={currentLocale}
      openFieldLabelModal={openFieldLabelModal}/>
     <label onClick={e=>openImageMap()} className="btn btn-default w-100p bg-highlight color-white" >   
      Open Image Map {isImageMapOpen ? <i className="fa fa-times notification-close"/> :<i className="fa fa-paperclip white" />}
     </label>  
     <span className="fts-13">{field.description}</span>  
     <Error/>
     <CueTooltip id={field.client_id} description={field.tooltip}/>
     {isImageMapOpen && <ImageMapModal imageMapData={imageMapData} onClose={closeImageMap}/>}
    </div> 
  )
} 

export default InputRichImageMap;