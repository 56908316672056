import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../../../constants/FormTemplateList'
import CollegeProfileService from "./CollegeProfileService"
import CommonService from "../../../../../services/CommonService"
import env from '../../../../../env'
import FormContainer from '../../../../FormBuilder/FormContainer'
import {HighSchoolLink} from "../../../../Common/RouteLinks"

let formMode = null
let collegeProfile = {} 
let data = {} 
let formFn = {}  

function CollegeProfileForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  let params = props.match.params

  formFn.college_names_pagenate = {page:1, per_page:10}
  formFn.college_names_path = "name"

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CollegeProfileService.get(params).then((res) => {
      if(res.status==200){
        collegeProfile = res.data
        data = collegeProfile.data || {}
        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function(res){
       collegeProfile = null
    })
  }
  
  useEffect(() => { 
    formFn = {'editPermission':true}
    collegeProfile = {} 
    data = {} 
    formMode = 'create-form-submissions'
    data = {} 
  
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (collegeProfile) => {
    collegeProfile.created_by = currentUser.id
    collegeProfile.education_journey_profile_id = params.journey_profile_id
    collegeProfile.journey_category_id = params.journey_category_id
    collegeProfile.family_id = currentUser.domain_info.journey_category_id
    CollegeProfileService.create(collegeProfile).then((res) => {
      if(res.status==201){
        sessionStorage.activeCollegeProfile = JSON.stringify(res.data)
        onSubmitPageRedirect(res.data.id, res.data)
      }
    }).catch(function(res){
       
    })
  }

  const update = (collegeProfile) => {
    collegeProfile.updated_by = currentUser.id;
    CollegeProfileService.update(collegeProfile).then((res) => {
      if(res.status==204){
       sessionStorage.activeCollegeProfile = JSON.stringify(collegeProfile) 
       onSubmitPageRedirect(collegeProfile.id, collegeProfile)
      }
    }).catch(function(res){
       
    })
  }

  formFn.get_college_names = function(search, on_success, on_failure){
    formFn.college_names_pagenate.college_name = search;
    let req = {
      method: 'post',
      url: `${env.family_tree_backend}colleges/search`,
      data: formFn.college_names_pagenate
    }

    CommonService.useAxios(req).then(({data, status})=>{
      formFn.college_names_list = data.colleges;
      if(data.meta.total && formFn.college_names_pagenate.per_page){
        formFn.college_names_pagenate.pageSize = Math.ceil(data.meta.total/formFn.college_names_pagenate.per_page);
      } 
      on_success(formFn.college_names_list)
    })
  }

  formFn.toggle_college_names = function(item){
    let found = data.college_names_array.findIndex(function (d) {
      return d.id == item.id
    })
    if(found>-1){ 
      data.college_names_array.splice(found, 1);
    }else{
      data.college_names_array.push({'id':item.id,'name':item.name}); 
    }
    data.college_names = data.college_names_array.map(function(item) {
      return item.name;
    })
    data.college_names = data.college_names.toString();
    return data.college_names_array;
  }

  formFn.exist_college_names = function (item) {
    let found = data.college_names_array.findIndex(function (d) {
      return d.id == item.id
    })
    return found>-1
  }

  const onSubmitPageRedirect = (id, college_profile) => {
    if(props.setSearchFilter){
      props.setSearchFilter(college_profile)
      props.toggleModal()
    }else{
      props.history.push(HighSchoolLink.hs_college_search({
        params,id
       }))
      //props.history.push(`/highschool/college/search/${params.journey_category_id}/${params.journey_profile_id}/${id}`);
    }
  }

  const onCancel = (id) => {
    props.toggleModal()
    //props.history.push(`/highschool/college/profiles/${params.journey_category_id}/${params.journey_profile_id}`);
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
         <DynamicForm formMode={formMode} formId={FormTemplateId.CollegeProfileForm} form={collegeProfile} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onCancel} setPost={setPost}/>
      </FormContainer>
    )
  }else{
    return null
  }
  
}

export default CollegeProfileForm;