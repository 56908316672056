
import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import News from '../../../components/News/List/News'
import querystringify from "querystringify"
import NewsService from "../../../components/News/NewsService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import CommentsModal from "../../Modals/CommentsModal"
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link, useHistory} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import NotesModal from "../../Modals/NotesModal"
import MenuModal from "../../Modals/Menu"
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle'
import env from '../../../env'
import NewsListTable from './NewsListTable'
import CheckAccess from "../../Journey/Roles/CheckAccess"
//import FilterModal from "../../../components/Organizations/List/OrganizationsFilterModal";
import SideMenuBar from "../../../components/Common/SideMenuBar"

let requestParams = {}
let selectedNews = {}
let data_source = {}
let roleCreateNews = false

const NewsList = (props) => {
  useStyle('/css/notification.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  useStyle('/css/common.css', true)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [newsList, setNewsList] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [showTableView, setShowTableView] = useState(false)

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal()
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()

  let history = useHistory()
  let queryParam = querystringify.parse(props.location.search)
  
  let cardGridCss = "col-xs-12"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8"
    cardHeightCss = "mih-380"
  }
  
  useEffect(() => {
    requestParams = {
      'data_source_params': {
        'data_source_id': [2162, 3142],
      },
      'filter_favourites': false,
      'is_user_suggested': true,
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': 'date_published_article_formatted_date',
      'sort_direction': 'desc',
      'search': props.searchString,
      'id': queryParam.id,
    }

    let role_manager = CheckAccess.getUserPermission(currentUser, 'role_manager')
    if(env.admin_emails.includes(currentUser.email) || role_manager.news){
      roleCreateNews = true
      requestParams.partnership_id = currentUser.domain_info.partnership_org_id
      
      delete requestParams.is_user_suggested
      delete requestParams.is_active
      delete requestParams.filter_favourites
    }

    if(history.location.pathname == '/admin/news'){
      setShowTableView(true)
      requestParams.is_user_suggested = 'all'
      requestParams.sort_column = 'updated_at'
    }
      
    getNewsList()
  }, [props.searchString, currentUser.id, currentpage])

  let getNewsList = () => { 
    NewsService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       requestParams.totalItems = res.data.meta.total
       data_source = res.data.data_source_list
       setNewsList(res.data.cue_newses)
      }
    })
  }

  const addDelete = (e, ja) => {
    selectedNews = ja
    toggleDeleteModal(e)
  }

  const addMenu = (e, ja) => {
    selectedNews = ja
    toggleMenuModal(e)
  }

  const addNotes = (e, ja) => {
    selectedNews = ja
    toggleCommentModal(e)
  }

  const updateStatus = (e, item) =>{
    e.preventDefault()
    item.is_active = !item.is_active 
    NewsService.update(item).then((res) => {
      if(res.status==204){
        setNewsList([...newsList])
      }
    })
  }

  const deleteNews = (e) => {
    e.preventDefault()
    NewsService.delete(selectedNews.id).then((res) => {
      if (res.status == 204) {
        setNewsList(newsList.filter(i => i.id !== selectedNews.id))
      }
    })
    toggleDeleteModal(e)
  }

  const routeForm = (e, id) =>{
    e.preventDefault()
    if(history.location.pathname == '/admin/news'){
      history.push('/news/create/'+FormTemplateId.NewsRegistration+'/'+(id?id:''))
    }else if(history.location.pathname == '/news'){
      history.push('/news/create/'+FormTemplateId.NewsUserRegistration+'/'+(id?id:''))
    }
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        {roleCreateNews &&
          <a href="#" onClick={e=>routeForm(e, item.id)}>
            <i className="fa fa-edit"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }
        <Link to="/" onClick={e=>addNotes(e, item)}>
          <i className="far fa-comment"></i>
          <span className="font-13">Comments</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        {roleCreateNews  &&
          <a href="/" onClick={e=>updateStatus(e, item)}>
             <i className={item.is_active ? "far fa-check-circle": "far fa-circle"}></i>
            <span className="font-13">Pub Active</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }
      </Fragment>
    )
  }

  const Menu = ({ ja, fn }) => (
    <i onClick={e=>{addMenu(e, ja)}} className="fas fa-bars" />     
  )

  const Details = ({ ja, fn }) => (
    <div className="dropup card-menu-left">
    
    </div>
  )
     
  const newsListCard =  newsList && newsList.map((ja, i) => (
    <div className={cardGridCss} key={i}>
      <News newsList={ja}  menu={Menu} dataSource={data_source}
        details={Details} addNotes={addNotes} roleCreateNews={roleCreateNews}
        currentUser={currentUser} toggleModal={toggleDeleteModal} 
        cardHeightCss={cardHeightCss} />
        
        {selectedNews.id == ja.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedNews} 
          addDelete={addDelete} type="journey" MenuItems={menuItems} menuHeight="250px"
          roleCreateNews={roleCreateNews}/>
        }
    </div>
  ))
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">News List</div>
            </div>
            <div className="flex aligncenter">
              {roleCreateNews &&
                <a href="#" className="bg-highlight round-btn float-right m-r-5 m-t-4"
                  onClick={e=>routeForm(e)}>  
                  <i className="fas fa-plus"></i>
                </a>
              }
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs">
          <div className="m-t-30 m-t-0-xs">
          <div className="bg-white p20 border-rounded-10">
            <div className="row m-t-30">
              <div className="col-md-12">
                {!showTableView && newsListCard}
                {queryParam.id &&
                  <Fragment>
                    {newsListCard}
                    <div className="col-sm-12 col-sm-6 col-md-4">
                      {newsList.length > 0 && 
                        <CommentsModal isOpen="true" toggleModal={toggleCommentModal} 
                        item={newsList[0]} type="cue_news" 
                        showInline="true"/> 
                      }
                    </div>
                  </Fragment>           
                }

                {showTableView &&
                  <div className="page-content row">
                    <NewsListTable setEdit={routeForm} setDelete={addDelete} menu={Menu} 
                    requestParams={requestParams} newsList={newsList} getNewsList={getNewsList}
                    updateStatus={updateStatus} currentUser={currentUser} 
                    roleCreateNews={roleCreateNews}/>
                  </div>
                }

                <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
                setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>

              </div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>

      <ConfirmMenu
        isOpen={isOpen}
        toggleModal={toggleDeleteModal}
        success={deleteNews}/>
      
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={selectedNews} type="cue_news"/>
      }
      <SideMenuBar helpId="2" />
    </Fragment>
  )

}

export default NewsList;