import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const ActivityInfoList = (props) => {
  return (
    <Fragment>
      {props.activities.map((a, k) => {
        return(
        <Fragment key={k}>
          <div className="m-t-5 pull-right">
            <i className="fas fa-chevron-right lgrey font-15" />
          </div>
          <div className="panel-xs pointer m-t-5"
            style={{borderLeft: "10px solid " + (a.data.background_color || "#607D8B"),}}>
            <Link className="black m-l-5"
              to={`/team/activity/${a.journey_category_id}/${a.education_journey_profile_id}?id=${a.id}`}
            >
              {a.data.record_title || a.data.name}
            </Link>
          </div>
        </Fragment>
      )})}
    </Fragment>
  );
};

export default ActivityInfoList;
