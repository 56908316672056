export default {
  Login: "/signin",
  Signup: "/signup",
  ForgetPassword: "/forget_password",
  ResetPassword: "/password_reset/:userId/:token",
  RegistrationConfirmation: "/registration/confirmation/:verification_id",
  Profile: '/getstarted',
  ProfileForm: '/profile',
  Form: '/form/:form_template_id',
  Roles: '/roles',
  TrackRelationshipForm: '/list/relationship/tracking/:member_id/:id?',
  MemberForm: '/member/form/:form_type_id/:form_template_id/:id?',
  MemberList: '/invite/users',
  JourneyRouter:{
  	JourneyDashboard: '/journey/dashboard',
  	JourneyList: '/journey',
  	JourneyProfileForm: '/journey_profile/:id?',
  	RoleList: '/journey/role/:journey_category_id/:journey_proile_id?',
  	UserRoleList: '/journey/user_role/:journey_category_id/:journey_proile_id/:roleable_type/:roleable_id',
  	UsersList: '/journey/users/:journey_category_id/:journey_proile_id/:roleable_type/:roleable_id',
  	InvitedUserList: '/invite/user/:journey_category_id/:journey_profile_id',
  	QuickUpdateForm: '/journey/activity/update',
  	IncomingMails: '/journey/incoming/emails/:type/:id'
  },
  HighSchoolRouter:{
  	ActivityTypeList: '/highschool/activity/type/:journey_category_id?/:journey_profile_id?'
  },
  TeamRouter:{
  	JourneyActivityList: '/team/activity/:journey_category_id?/:education_journey_profile_id?'
  }
}