import React, {Fragment, useEffect, useState} from "react";
import {Link,withRouter,useHistory} from "react-router-dom";
import CommonService from '../../../../../services/CommonService';
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import useStyle from '../../../../../hooks/useStyle';

export const HsPlannerFilter = (props) => {
  useStyle('card');
  useStyle('card_table');
  const history = useHistory();

  let project = props.project;

  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    getPlannerCategory();
  }, []) 

  let getPlannerCategory = () => { 
  	let requestParams = {
      "data_source_id":[2861, 3320],
    }
    CommonService.getDataSource(requestParams).then((res)=>{
      setDataSource(res);
    })
  }

  if(!dataSource){
  	return null
  }

  const onClickFilter = (grade, semester) =>{
    props.setGradeData({
      "grade":grade,"semester_period":semester
    });
    props.filterByGrade();
    //history.push(`/highschool/planners/${props.journey_category_id}/${props.journey_profile_id}?grade=${grade}&semester_period=${semester}`, project)
  }

  const semesterPeriods = (grade) => dataSource.seasons_semester_periods.options.map((p, k) => (
    <a className="ver-icons p-5" key={k} >
      <input type="checkbox"  onClick={e=>onClickFilter(grade, p.value)} className="lgrey m-r-5" />
      <div className="popup-menu-title text-cap m-t-5">{p.label}</div>
  	</a>
  ))

  const grade = dataSource.fj_edu_current_grade && dataSource.fj_edu_current_grade.options.map((p, i) => (
    <div key={i} className="col-xs-12">
      <div className="bg-white">
      <div className="p-5 bg-white m-b-10">	
    	<div className="font-15 bold-600">Grade {p.label}</div>
    	<div className="m-t-5">{semesterPeriods(p.value)}</div>
      </div>	
      </div>
    </div>
  ))

  return(
  	<Fragment>
      <div className="page-content bg-white p-5">
        <h5 className="font-16 text-center bg-highlight white">
          Planner Category
        </h5>
        {grade}
      </div>
    </Fragment>
  )
}

export default (HsPlannerFilter);