import React, {useState, Fragment, useRef, useContext, useEffect} from "react";
import querystringify from "querystringify";
import {Link} from "react-router-dom";
import Pagination from '../../../../Pagination'
import useStyle from '../../../../../hooks/useStyle';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CollegeService from "../../Colleges/CollegeServices";
import FilterModal from "../../Colleges/Favourites/FavouritesFilterModal";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import Favourite from "./Favourite"
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import FavouritesService from "../../../../../services/FavouritesService"
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import { SwiperContainer, SwiperSlide } from "../../../../../components/Common/Swiper";
import CommonService from "../../../../../services/CommonService";
import CheckAccess from '../../../Roles/CheckAccess';
import ReactTooltip from "react-tooltip";
import CollegeProfileForm from "../CollegeProfileForm/CollegeProfileForm"
import GenericModal from "../../../../Modals/GenericModal" 
import Suggestions from "../../HighSchoolPortalPage/Suggestions"
import Tabs, { TabPane } from 'rc-tabs'
import {EmptyFormFieldList} from "../../../Common/FormFieldSuggestions"
import {EmptyCollegeCueForms} from '../TimeLineComponent/Timeline'
import ReviewForm from '../Reviews/ReviewForm'

let requestParams = {}
let dataSource = {}
let selectedFavourite = null
let cardViewCount = 1
let cueFormProps = {}

const CollegeFavouritesList = (props) => {
  useStyle('card');
  useStyle('card_table');

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  let [favourites, setFavourites] = useState([]);
  const [journeyCategory, setJourneyCategory] = useState({});
  const [collegeSearch, setCollegeSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [cueSuggestions, setCueSuggestions] = useState({});

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal();
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isSearchFilterOpen, toggleModal:toggleSearchFilterModal } = useModal();
  const { isOpen:isCollegeCueFormOpen, toggleModal:toggleCollegeCueForm } = useModal();

  const indexEle = useRef(null);

  let params = props.match?.params || props;
  let journey = props.location?.state || props.journey;
  let queryParam = querystringify.parse(props.location?.search) || null;

  let cardGridCss = "col-xs-12";
  let cardHeightCss = "ht-450";
  if (queryParam.id || props.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-7";
    cardHeightCss = "mih-370";
  }

  let disableClickEvent = {}
  if(currentUser.current_admin.admin_role_name == 'demo_user'){
    disableClickEvent.pointerEvents = 'none'
  }

  let collegeSearchProps = {
    history:props.history,
    match:{
      params:{
        journey_category_id: params.journey_category_id,
        journey_profile_id: params.journey_profile_id,
        go_back:true,
        id:collegeSearch?.id
      }
    },
    location:{}
  }

  useEffect(() => {
    ReactTooltip.rebuild()

    requestParams = {
      'data':{},
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'user_rating', 
      'sort_direction': 'desc',
      'education_journey_profile_id': params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'include_college_data': true,
      'id': queryParam.id || props.id,
      'data_source_params': {
        'data_source_id':[3246, 2214, 3410, 3426],
        'response_type':'object'
      },
    }

    getFavourites()
  }, [props.searchString, currentpage, queryParam.id]);

  let getFavourites = () => { 
    setLoading(true)
    CollegeService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.data_source_list; 
        dataSource.college_app_status = Object.values(dataSource.college_app_status.options)
        requestParams.totalItems = res.data.meta.total
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount);
        if(requestParams.search){
          setFavourites(res.data.favourites)
        }else if(queryParam.id || props.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setFavourites(res.data.favourites);
        }else{
          setFavourites([...favourites, ...res.data.favourites]);  
          setCollegeSearch(res.data.college_admission_profile) 
        }
        setCueSuggestions(res.data.cue_suggestions)
        setLoading(false)
        CheckAccess.userAccess(currentUser, res.data.journey_category, res.data.invited_journey_member);
        setJourneyCategory(res.data.journey_category)
      }
    })
  }

  const updateApplicationStatus = (id, data, index) => {
    const req = {
      id:id,
      data:data
    }
    FavouritesService.update(req).then((res)=>{
      if(res.status == 204){
        favourites[index].data = data
        setFavourites([...favourites])
      }
    })
  }

  const openCollegeCueForm = (cue, favourite, id) =>{
    selectedFavourite = favourite
    cueFormProps = {
      history: props.history,
      isPopupMode: true,
      onSubmit:onCollegeCueSubmit,
      match:{
        params:{
          type: cue.sid,
          form_template_id: cue.child_form,
          journey_category_id: params.journey_category_id,
          journey_profile_id: params.journey_profile_id,
          college_id: favourite.college_id,
          favourite_id: favourite.id,
          id: id || '',
        }
      },
      location:{
        search:'edit=true'
      }
    }

    toggleCollegeCueForm()
  }

  const onCollegeCueSubmit = (cue) => {
    selectedFavourite.college_cues = selectedFavourite.college_cues || []
    const cues = selectedFavourite.college_cues.map(e =>
      e.id == cue.id ? cue : e
    )
    selectedFavourite.college_cues = cues
    setFavourites([...favourites])
  }

  const SuggestionsCard = () =>{
    const cardMarginCss = screen.xs?'':'card-container'
    if(favourites.length > 0){
      return(
        <div className="col-sm-12 col-sm-6 col-md-5">
          <div className={cardMarginCss}>
            <div className="ml-center bg-lgreen white p-5">
              <div className="font-16 bold-600">
                Suggestions to learn more about this college
              </div>
            </div>
            <div className="shadow-small ht-420 scroll-auto">
              <EmptyFormFieldList fields={cueSuggestions?.empty_fields}/>
            </div>
          </div>
        </div>
      )
    }else{
      return(<div />)
    }
  }

  const FavouritePage = () =>{
    if(screen.xs){
      return(
        <Tabs defaultActiveKey="1" className="card-container">
          <TabPane tab="Activity" key="1">
            {favouritesList}
          </TabPane>

          <TabPane tab="Suggestions" key="2">
            <SuggestionsCard/>
          </TabPane>
        </Tabs>  
      )
    }else{
      return(
        <>
          {favouritesList}
          <SuggestionsCard/>
        </>
      )
    }
  }

  const addComments = (favourite, e) =>{
    selectedFavourite = Object.assign({}, favourite)
    selectedFavourite.id = selectedFavourite.college_id
    toggleCommentModal(e)
  }

  const addChecklist = (favourite, e) =>{
    selectedFavourite = favourite
    toggleChecklistModal(e)
  }

  const onDelete = (favourite, e) =>{
    selectedFavourite = favourite
    toggleDeleteModal(e)
  }

  const deleteFavourite = (e) =>{
    toggleDeleteModal(e);
    FavouritesService.delete(selectedFavourite.id).then((res)=>{
      if(res.status==204){
        setFavourites(favourites.filter(i => i.id !== selectedFavourite.id))
      }
    })
  }

  const getNearByCollege = (e) =>{
    e.preventDefault()
    let activeCollegeProfile = {}
    activeCollegeProfile = {'data':{'filter_nearby_college':true}}
    sessionStorage.activeCollegeProfile = JSON.stringify(activeCollegeProfile)
    props.history.push(`/highschool/college/search/${params.journey_category_id}/${params.journey_profile_id}/`)
  }

  const routeFavourite = (favourite) =>{
    props.history.push(`/highschool/favourite/colleges/${params.journey_category_id}/${params.journey_profile_id}?id=${favourite.college_id}`)
  }

  const favouritesList = favourites && favourites.map((f, i) => (
    // <SwiperSlide key={i}>
      <div className={cardGridCss} key={i}>
        <Favourite favourite={f} dataSource={dataSource} addComments={addComments} 
        addChecklist={addChecklist} onDelete={onDelete} index={i} cardHeightCss={cardHeightCss} 
        disableClickEvent={disableClickEvent} currentUser={currentUser}
        journeyCategory={journeyCategory} routeFavourite={routeFavourite} 
        isPopupView={props.isPopupView} openCollegeCueForm={openCollegeCueForm} 
        updateApplicationStatus={updateApplicationStatus}/>
      </div>
    // </SwiperSlide>
  ))

  if(loading)
    return <div className="spinner"/>
  
  return (
    <Fragment>
        <div id="ct">
          <div className="app_content">
            {!props.isPopupView && 
              <div className="app-header flex justspacebetween m-t-15">
                <div className="flex aligncenter">
                  <JourneyTitle title={"Colleges"} project={journey} />
                </div>

                <div className="flex aligncenter">
                  <a style={disableClickEvent} href="#" data-tip="Filter Favorite Colleges" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>toggleSearchFilterModal(e)}>
                    <i className="fas fa-sliders-h"></i>
                  </a>
                  
                  {currentUser.domain_info.data.country_code == 'US' &&
                    <a style={disableClickEvent} href="#" data-tip="Nearby Colleges" onClick={e=>getNearByCollege(e)} className="bg-highlight round-btn float-right m-r-5 m-t-4">
                      <i className="fas fa-route"></i>
                    </a>
                  }

                  <Link style={disableClickEvent} data-tip="Saved College Searches" to={{pathname:`/highschool/college/profiles/${params.journey_category_id}/${params.journey_profile_id}`, state:journey }} className="bg-highlight round-btn float-right m-r-5 m-t-4">
                    <i className="fas fa-search-location"></i>
                  </Link>
                
                  <Link style={disableClickEvent} data-tip="Back to Journey Path" to={{pathname:`/highschool/path/${params.journey_category_id}/${params.journey_profile_id}`, state:journey }} className="bg-highlight round-btn float-right m-r-5 m-t-4">
                    <i className="fas fa-home"></i>
                  </Link>

                  <Link style={disableClickEvent} data-tip="New College Search" to={{pathname:`/highschool/college/profile/${params.journey_category_id}/${params.journey_profile_id}`, state:journey }} className="bg-highlight round-btn float-right m-r-5 m-t-4">
                    <i className="fas fa-plus"></i>
                  </Link>
                </div>
              </div>
            }
            <div className={`app-content-section ${(!queryParam.id && !props.id) ? 'm-t-30': ''}`}>
              <div className="row">
                <div className="col-md-12">
                  {(!queryParam.id && !props.id) && favouritesList }
                  {(queryParam.id || props.id) && <FavouritePage/>}
                </div>
              </div>
            </div>
          </div>
        </div>

      {isFilterOpen && <FilterModal filterData={requestParams.data} 
        filterDataSources={dataSource} isOpen={isFilterOpen} 
        toggleModal={toggleFilterModal} onSearch={getFavourites}/>}
      
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} item={selectedFavourite} type="college" 
        journeyCategoryId={params.journey_category_id}/>}
      
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} 
        toggleModal={toggleChecklistModal} item={selectedFavourite} type="college"/>}
      
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} 
        success={deleteFavourite}/>

      {isSearchFilterOpen && 
        <GenericModal component={CollegeProfileForm} 
        {...collegeSearchProps} 
        toggleModal={toggleSearchFilterModal}/>
      }

      {isCollegeCueFormOpen && 
        <GenericModal component={ReviewForm}
        isOpen={isCollegeCueFormOpen} toggleModal={toggleCollegeCueForm} 
        {...cueFormProps}/>
      }

      <SideMenuBar helpId="12"/>
    </Fragment>
  )
}

export default CollegeFavouritesList;