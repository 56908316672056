import React, { useState, Fragment, useEffect, useContext} from "react";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import useEscape from '../../../../hooks/useEscape';
import InterviewService from "./InterviewService";

const AnswerModal = (props) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');

  useEscape(props.toggleModal);

  let answerTxt = props.userResponse && props.userResponse.response;
  const [answer, setAnswer] = useState(answerTxt);

  const handleChange = (e) =>{
    setAnswer(e.target.value)
  }

  const submitAnswer = () =>{
    document.getElementById("SubmitAnswer").style.visibility = "hidden";
    let req = {
      'question_id':props.question.id,
      'response':answer,
      'response_type':'answer',
      'journey_category_id':props.journeyCategoryId,
      'journey_profile_id':props.journeyProfileId,
      'carrier_ds_id': props.carrierDataDourceId,
      'question_category_ds_id':props.question.question_category_ds_id,
      'journey_type_ds_id':props.question.journey_category_ds_id,
      'graduate_program_ds_id':props.question.graduate_program_ds_id
    }
  
    if(props.userResponse){
      req.id = props.userResponse.id
      InterviewService.update(req).then((res)=>{
        if(res.status == 204){
          props.userResponse.response = answer
          props.toggleModal()
        }
      })
    }else{
      InterviewService.create(req).then((res)=>{
        if(res.status == 201){
          props.answerList[props.question.index].answer = res.data.interview_preparation
          props.setAnswerList([...props.answerList])
          props.toggleModal()
        }
      })
    }
  }

  return(
    <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      {props.title &&
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p12-10-15">{props.title}</h4>
          <button className="btn-close"
            title="Close Modal" onClick={e=>props.toggleModal(e)}>×
          </button>
        </div>
      }
      <div className="row">
        <div className="form-group col-xs-12">
          <label>{props.label}</label>
          <textarea onChange={e => handleChange(e)} value={answer} rows="8" className="form-control plainTxt2 textAreaht"/>
        </div>
        
        {answer &&
          <div id="SubmitAnswer" className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>submitAnswer()}>
            <b>Submit</b>
          </div>
        }
      </div>
      </div>
    </div>
  )
}

export default AnswerModal;