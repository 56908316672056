import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import FormContainer from '../../FormBuilder/FormContainer';

let formMode = null; 
let updateForm = {}; 
let data = {}; 
let formFn = {};  
let form_template_id = null;

function QuickUpdateForm(props) {
  
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;

  useEffect(() => { 
    updateForm = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    data = {}; 

    if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (req) => {
    /*req.created_by = authState.user.id;
    ResourceService.create(req).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })*/
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/highschool/forms/${params.journey_category_id}`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
         <DynamicForm formMode={formMode} formId={FormTemplateId.JourneyQuickUpdateForm} form={updateForm} data={data} 
          formFn={formFn} onCreate={create} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default QuickUpdateForm;