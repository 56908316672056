import React, { Fragment,useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import {CheckBox} from "./FormInput"
import FormHelper from "../FormBuilder/FormHelpers"

const CardMainMenu = ({ activity, showMenu, disableClickEvent}) => (
  <div style={disableClickEvent} className="m-r-15">
    <i data-tip="Menu" onClick={e=>showMenu(e, activity)} className="fas fa-bars font-20" />
  </div>
)

const MenuItem = ({id, link, className, icon, action,onSelect}) => {
  if(link){
    let linkAttribute = {'id':id, 'to':link, 'className':className}
    return (
      <Link {...linkAttribute}  onClick={e=>onSelect && onSelect(e)}>
        <i className={icon}></i>
        <span className="font-13">{action}</span>
        <i className="fa fa-angle-right"></i>
      </Link>  
    )  
  }else{
    let linkAttribute = {'id':id, 'className':className}
    return (
      <a {...linkAttribute} href='/' onClick={e=>{e.preventDefault();onSelect && onSelect(e)}}>
        <i className={icon}></i>
          <span className="font-13">{action}</span>
        <i className="fa fa-angle-right"></i>
      </a>
    )
  }
  
}

const CardMenuInfo = ({formId, recordId, createdAt, userName}) => {
  const {state: { screen }, dispatch,} = useContext(AuthContext)

  return (
    <a href="">
      <i className="fas fa-info-circle"></i>
      <span className={(screen.width < 460 && '')+' font-15'}>
        <small className="lred">since { Cs.formatUpdateDate(createdAt)}</small>
        <small className="m-l-10">by  {userName}</small>
        <small className="m-l-10">({formId}-{recordId})</small>
      </span>
      <i className="fa fa-angle-right"></i>
    </a>  
  )
}

const NavIcon = ({id, dataTip, link, className, icon, onSelect}) => {
  let linkAttribute = {'id':id, 'data-tip':dataTip, 'to':link, 'className':className}

  if(link){
    return (
      <Link {...linkAttribute}>
        <i className={icon}></i>
      </Link>  
    )
  }else{
    return (
      <span {...linkAttribute} onClick={e=>onSelect && onSelect(e)}>
        <i className={icon}></i>
      </span>
    )   
  }
  
}

const FilterMenuItem = ({list, onSelect, dataSourceId}) =>{
  const List = () => list.map((k, i) => {
    return (
      <Fragment key={i}>
        <div className="ver-icons" onClick={e=>onSelect(e, k)}>
          <span>
            <i className={`${k.icon || "fas fa-plus"}`} style={{ color: k.icon_color }}/>
          </span>
          <div className="popup-menu-title">{k.label}</div>
          <i className="fas fa-chevron-right lgrey pull-right"/>
        </div>
      </Fragment>
    )
  })

  return (
    <div id="ct">
      <div className="popup-menu-grid">
        <List/>
        <div className="m-l-10">{dataSourceId}</div>
      </div>
    </div>
  )
}

const CheckBoxGroupFilter = ({title, name, options = [], filter = {}, setFilter}) => {
    const setter = (selected_items) => {
      setFilter({...filter, [name]:selected_items})
    }

    const toggle = (value) =>{
      FormHelper.toggleListString(value, filter[name], setter)  
    }
    
    const exists = (value) =>{
      return FormHelper.existsListString(value, filter[name])
    }

    return(
      <div className="p-5 scroll-x">
        <div className="bg-white">
          <h5 className="font-16 text-center bg-highlight white">
            {title}
          </h5>

          <CheckBox list={options} labelKey="label" valueKey="value" toggle={toggle} exists={exists}/> 
        </div>
      </div>
    )
}

export {MenuItem, CardMenuInfo, NavIcon, CardMainMenu, FilterMenuItem, CheckBoxGroupFilter}
