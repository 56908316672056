import React, { Fragment, useEffect, useRef, useContext } from "react"
import { Link } from "react-router-dom"
import ReactDOM from 'react-dom'
import useStyle from '../../hooks/useStyle'
import AuthenticationService from "../../services/AuthService"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CheckAccess from "../Journey/Roles/CheckAccess"

const FilterSlider = ({ component1: Component1, component2:Component2, component3:Component3, component4:Component4, ...props }) => {
  useStyle('add_role_dialog')
  useStyle('filter_dialog')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)  
  const sideNavEl = useRef(null)

  useEffect(() => {
    document.addEventListener("click", handleClick, true)
    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [])

  const handleClick = (e) => {
    if (!sideNavEl.current.contains(e.target)){
      props.setOpenSideNav(false)
    }
  }
  
  return(ReactDOM.createPortal(
    <Fragment>
      <div id="menu-main" ref={sideNavEl}
        className={`scroll-auto menu filter-box-right menu-box-detached ${props.openSideNav ? "menu-active" : ""} `}
        style={{ width: screen.xs ? 260:320, opacity: 1, display: "block" }}>
        <div className="menu-icons">
          <h3 className="center-text bg-black white br-btm-grey p-10">
            {props.title ? props.title :"FILTERS"}
          </h3>
        </div>
        {Component1 && <Component1 {...props} />}
        {Component2 && <Component2 {...props} />}
        {Component3 && <Component3 {...props} />}
        {Component4 && <Component4 {...props} />}
      </div>
    </Fragment>, document.body)
  )
}

export default FilterSlider;