import env from '../../../../env';
import axios from 'axios';
let url = env.carrier_training+'answers'

class AnswerService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getAnswerFields(req){
    return new Promise((resolve, reject) => {
      axios.get(env.carrier_training+'answer_fields', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  updateAnswerField(req) {
    return new Promise((resolve, reject) => {
      axios.put(env.carrier_training+'answer_fields/'+req.id, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new AnswerService();