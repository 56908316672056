import React, {useState, Fragment} from "react";
import {Link} from "react-router-dom";
import Pagination from '../../../../Pagination'
import useStyle from '../../../../../hooks/useStyle';
import useModal from '../../../../../hooks/useModal';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import StudentProfileServices from "../../StudentProfiles/StudentProfileServices";
import StudentProfile from "./StudentProfile"
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import {HighSchoolLink} from "../../../../Common/RouteLinks"

let requestParams = {};
let data_source = {};
let selectedProfile = null;

export const CollegeFavouritesList = (props) => {
  useStyle('card');

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [studentProfiles, setStudentProfiles] = useState([]);
  let params = props.match.params;
  let project = props.location.state;
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();

  React.useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'data_source_params': {
        "data_source_id":[2530,2653,3321,4,3,2,2214],
        "response_type":"object"
      },
      'journey_category_id': params.journey_category_id,
      'education_journey_profile_id': params.journey_profile_id
    }

    getStudentProfiles();
  }, [props.searchString, currentpage]);

  let getStudentProfiles = () => { 
    StudentProfileServices.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       data_source = res.data.data_source_list; 
       requestParams.totalItems = res.data.meta.total;
       setStudentProfiles(res.data.student_profiles); 
      }
    })
  }

  const onDelete = (profile, e) =>{
    selectedProfile = profile;
    toggleDeleteModal(e);
  }

  const deleteStudentProfile = (e) =>{
    toggleDeleteModal(e);
    StudentProfileServices.delete(selectedProfile.id).then((res)=>{
      if(res.status==204){
        setStudentProfiles(studentProfiles.filter(i => i.id !== selectedProfile.id))
      }
    })
  }

  /*if(studentProfiles.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/
  
  const studentProfilesList = studentProfiles.map((p, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <StudentProfile studentProfile={p}  dataSource={data_source} onDelete={onDelete}/>
    </div>
  ))

  return (
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
           <JourneyTitle title={"Student Profiles"} project={project} />
        </div>
      
      <div className="col-md-8 pull-right" >
        <Link data-tip="Create New Profile" to={`/highschool/student/profile/form/${params.journey_category_id}/${params.journey_profile_id}`} className="bg-highlight round-btn float-right m-r-5 m-t-4">
          <i className="fas fa-plus"></i>
        </Link>

        <Link data-tip="Back to Journey Home" to={HighSchoolLink.hs_portal(params)} className="bg-highlight round-btn float-right m-r-5 m-t-4">
          <i className="fas fa-home"></i>
        </Link>

        <Link data-tip="My Activity Profile" to={`/highschool/profile/${params.journey_category_id}/${params.journey_profile_id}`} className="bg-highlight round-btn float-right m-r-5 m-t-4">
          <i className="fa fa-user"></i>
        </Link>
      </div>

        <div className="clear"></div>
      </div>

      <div className="col-xs-12">
        {studentProfilesList}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteStudentProfile}/>
    </Fragment>
  )
};

export default CollegeFavouritesList;