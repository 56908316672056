import React, {useState, Fragment, useContext, useEffect} from "react"
import querystringify from "querystringify"
import {Link} from "react-router-dom"
import ReactDOM from 'react-dom'
import Pagination from '../Pagination'
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import ReactTooltip from "react-tooltip"
import {Radio} from "../Common/FormInput"
import AddLocale from "../Locale/AddLocale"

let requestParams = {}
let selectedData = null

const DataSourceListModal = (props) => {
  useStyle('add_role_dialog')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [dataSourceList, setDataSourceList] = useState([])
  const [dataSource, setDataSource] = useState({})
  const [showDataSourceForm, setShowDataSourceForm] = useState(false)
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [showLocale, setShowLocale] = useState(false)

  useEffect(() => {
    requestParams = {
      'per_page' : 30,
      'page': currentpage, 
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'form_template_id':props.form_template_id,
      'data_source_id':props.data_source_id, 
      'journey_profile_id':props.journey_profile_id,
    }

    getDataSourceList()
  }, [currentpage])

  let getDataSourceList = () => { 
    Cs.getDataSourceList(requestParams).then((res)=>{
     if(res.status == 200){
       setDataSourceList(res.data.data_source_lists)
       setDataSource(res.data.data_source)
      }
    })
  }

  let updateStatus = (o) =>{
    let req = {
      filterable_type: 'data_source_list',
      created_by: currentUser.id,
      journey_profile_id: props.journey_profile_id,
      filterable_id: o.id,
      data_source_id: o.data_source_id,
      form_template_id: props.form_template_id 
    }

    Cs.createOrDeleteDataFilter(req).then((res)=>{
      if(res.status == 201){
        o.inactive_status=0
      }else if(res.status == 204){
        o.inactive_status=null
      }
      setDataSourceList([...dataSourceList])
    }) 
  }

  const addDataSource = (data) =>{
    let req = {
      'form_template_id':props.form_template_id,
      'data_source_id': dataSource.id, 
      'item_id': props.journey_profile_id, 
      'item_type': 'journey_profile',
      'privacy_type':'private',
      'data':data,
      'consent_to_share':data.consent_to_share,
      'organization_id':currentUser.current_organization_id
    }
    delete data.consent_to_share
    Cs.createDataSourceList(req).then((res)=>{
      if(res.status == 201){
        setDataSourceList([res.data.data_source_list , ...dataSourceList])
        setShowDataSourceForm(false)
      }
    })
  }

  const onLocaleChange = (e, d) =>{
    e.stopPropagation()
    selectedData = d
    setShowLocale(true)
  }

  const DataSourceForm = () => {
    const [newDataSource, setNewDataSource] = useState({consent_to_share:'false'})

    const handleInput = (e) =>{
      setNewDataSource({...newDataSource, [e.target.name]:e.target.value})
    }

    return(
      <form>
        <div className="ml-section">
          <label><b>Add Custom Values</b></label>
          <input onChange={e=>handleInput(e)} className="ml-input ml-border ml-margin-bottom"
            type="text" placeholder="Label" name="label"
            required defaultValue={newDataSource.label}/>
          <p className="p-2">Share this custom value with the cueTree community?</p>
          <Radio list={[{label:'Yes', value:'true'}, {label:'No', value:'false'}]} 
            model={newDataSource.consent_to_share} name="consent_to_share" handleChange={handleInput}/>
          <p className="red">If you click “Yes,” your custom value may be made available to other users of this data set after editorial review first by cueTree</p>
          <div className="ml-button ml-block ml-section ml-padding bg-highlight white font-16" onClick={e=>addDataSource(newDataSource)}>
            <b>{newDataSource.id?'Update':'Create'}</b>
          </div>  
        </div>
      </form>  
    )
  }

  const DataSourceList = dataSourceList.map((o, k) => 
    <Fragment key={k}>
      <span className="checkbox-container m-t-10" key={k} onClick={e => updateStatus(o)}>
        <span data-tip={o.data.hover_text}>
          {o.data.label}
          <i className="fas fa-language m-l-15" onClick={e=>onLocaleChange(e, o)}></i>
        </span>
        <input type="checkbox" checked={o.inactive_status==null} readOnly/>
        <span className="checkbox-checkmark"></span>
      </span>
      <ReactTooltip place="right" />
    </Fragment>
  )

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom bg-white">
          <div className="bg-lgreen white p-5">
            <div className="m-l-5 font-16 bold-600 center-text">
              {dataSource.title}
              <br/>
              <small>Data Choices</small>
            </div>
            <span className="ml-button ml-xlarge ml-hover-red ml-display-topright" title="Close Modal"
              onClick={e=>props.toggleModal(e)}>×
            </span>
          </div>

          {!showLocale && 
            <div className="ml-container m-t-10">
              {!showDataSourceForm && <Fragment>
                <span className="text-right font-15 cursor bg-highlight white m-r-5 pill-xs p-5 m-b-10" onClick={e=>setShowDataSourceForm(true)}>
                  <u style={{textDecoration:"none"}}>Add custom values</u>
                </span>
                <p className="p-2">Share this custom value with the cueTree community?</p>
                {DataSourceList}
                <Pagination pagemeta={pagemeta} currentpage={currentpage} setCurrentpage={setCurrentpage}/>
                </Fragment>
              }
              {showDataSourceForm && <DataSourceForm/>}
            </div>
          }
            
          {showLocale && <AddLocale defaultLabel="label" localeType="data_source_list" onCancel={setShowLocale} labelObj={selectedData}/>}
        </div>
      </div>
    </Fragment>, document.body)
  )
};

export default DataSourceListModal;