import React, {useState, useRef, Fragment, useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import querystringify from "querystringify";
import useStyle from '../../../../hooks/useStyle';
import useModal from '../../../../hooks/useModal';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import StudentProfileServices from "../StudentProfiles/StudentProfileServices";
import CommonService from "../../../../services/CommonService";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle";
import MultiRating from "../../../Common/MultiRating";
import GeneralRating from "../../../Common/Rating";
import RatingView from "../../../Common/RatingView"
import EduPublicProfile from "./EduPublicProfile";
import ActivityService from "../Activities/ActivityService";
import ListActivity from "../Activities/List/ListActivity";
import {HighSchoolLink} from "../../../Common/RouteLinks"

let requestParams = {};
let data_source = {};
let dataPosition = null;
let selectedCollege = null

const MyEduProfile = (props) => {
  useStyle('panel');
  useStyle("filter_dialog");
  useStyle('add_role_dialog');
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [cueMeProfile, setCueMeProfile] = useState({});
  const [college, setCollege] = useState({});
  const [showSharingConsent, setShowSharingConsent] = useState(false);
  const [targetCollegeRating, setTargetCollegeRating] = useState({});
  const [targetCollegeRatingAvg, setTargetCollegeRatingAvg] = useState([]);
  const [collegeId, setCollegeId] = useState({});
  const [activities, setActivities] = useState([]);
  const [activityCategory, setActivityCategory] = useState();
  const [activityReport, setActivityReport] = useState({});
  const [tabIndex, setTabIndex] = useState();

  const activityContainer = useRef()

  let params = props.match.params;
  let project = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  useEffect(() => {
    requestParams = {
      'id': params.id || 'user',
      'data_source_params': {
        "data_source_id":[2530, 2653, 3321, 4, 3, 2, 2214, 3345],
        "response_type":"object"
      },
      'journey_category_id': params.journey_category_id,
      'education_journey_profile_id': params.journey_profile_id,
      'college_id':queryParam.college_id
    }
    getActivityByType();
    //getStudentProfiles();
    /*document.getElementById('my-edu-profile').addEventListener('swiped', function(e) {
      console.log(e.detail.dir); // swipe direction
      setShowRating(e.detail.dir=='left');
    })*/

  }, [collegeId, selectedCollege]);

  let getActivityByType = (form_id) => {

    let req 
    if(selectedCollege != null){
      req = {
        'journey_category_id':selectedCollege.journey_category_id,
        'journey_profile_id':selectedCollege.education_journey_profile_id, 
        'activity_form_id':form_id,
        'data_position':{
          data_source_id: 2214,
          key:'child_form'
        },
        'data_source_params': {
          data_source_id: 2214,
        }
      }
    }else{
      req = {
        'journey_category_id':params.journey_category_id,
        'journey_profile_id':params.journey_profile_id,
        'activity_form_id':form_id,
        'data_position':{
          data_source_id: 2214,
          key:'child_form'
        },
        'data_source_params': {
          data_source_id: 2214,
        }
      }
    }
    ActivityService.getActivityByType(req).then((res) => {
      setActivities(res.data.activities)
      console.log('getacttype',res);
      if(!form_id){
        let d = CommonService.groupBy(res.data.data_source_list?.fj_edu_entry_type.options, "actions")
        dataPosition = res.data.data_position
        console.log('getacttype d',d);
        setActivityCategory(d)
        setActivityReport(res.data.activity_report.report || {})
      }
    })
  }

  const loadCollegeProfile = (e,p)=>{
    setCollegeId(p.id)
    selectedCollege = p
  }

  const openTab = (ds) =>{
    if(ds.child_form != tabIndex){
      getActivityByType(ds.child_form)
      setActivities([])
      setTabIndex(ds.child_form)
    }else{
      setTabIndex(null)
    }
  }

  let getStudentProfiles = () => { 
    StudentProfileServices.getEduActivityStatsById(requestParams).then((res)=>{
      if(res.status == 200){
        data_source = res.data.data_source_list; 
        setTargetCollegeRating(res.data.target_college_rating || {})
        setCueMeProfile(res.data.education_activity_stat || {})
        setTargetCollegeRatingAvg(res.data.target_college_rating_avg || [])
        setCollege(res.data.college)
      }
    })
  }

  const createRating = (rating_item, data) =>{
    let req = {
      'education_journey_profile_id':rating_item.education_journey_profile_id,
      'journey_category_id':rating_item.journey_category_id,
      'rating_type':'target_college',
      'rating_id': queryParam.college_id,
      'data':data
    }
    if(targetCollegeRating && targetCollegeRating.id){
      CommonService.updateRating(req, targetCollegeRating.id).then((res)=>{
        
      })
    }else{
      CommonService.createRating(req).then((res)=>{
        rating_item.rating = res.data
      })
    }
  }

  const updatePrivacyType = () =>{
    let req = {
      privacy_type : cueMeProfile.privacy_type == 'private' ? 'public':'private',
      id: cueMeProfile.id
    }
    StudentProfileServices.updateEduActivityStats(req).then((res)=>{
     if(res.status == 204){
        setCueMeProfile({
          ...cueMeProfile,
          privacy_type: cueMeProfile.privacy_type == 'private' ? 'public':'private',
        })
        setShowSharingConsent(false)
      }
    })  
  }

  const toggleMyProfile = (e) =>{
    setCollegeId()
    selectedCollege = null 
    getActivityByType()
  }

  const toggleHome = (e) =>{
    selectedCollege = null 
    window.location.href =  HighSchoolLink.hs_portal(params)
  }

  const toggleSharingConsent = (e) =>{
    e.preventDefault();
    setShowSharingConsent(!showSharingConsent)
  }

  const TargetCollegeRating = () => {
    return (<Fragment>
      {targetCollegeRatingAvg && targetCollegeRatingAvg.map((c, k) =>  (
        <div className="col-xs-6 col-sm-6 col-md-3 p-5" key={k}>
          <div className="panel bg-white">
            <div className="panel-content">
              <h4 className="text-white font-500 m-t-10">{c.name}-{c.key}</h4>
              <RatingView noOfRating="1" sumOfRating={c.avg}/>
            </div>
          </div>
        </div> 
      ))}
    </Fragment>
  )}
  
  const onRatingScroll = (e) =>{
    scrollScore()
    /*let el = document.getElementById('');
    let rect = el.getBoundingClientRect();
    console.log(rect.bottom > 0 &&
        rect.right > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth)  &&
        rect.top < (window.innerHeight || document.documentElement.clientHeight) );*/
  }

  let scrollScore = () => {
    let ac = activityContainer.current
    const activityScrollWidth = ac.scrollWidth;
    if (ac.scrollLeft !== activityScrollWidth) {
      ac.scrollTo(0, ac.scrollLeft + 300);
    }
  }

  const Consent = ({cueMeProfile}) =>{
    return(
      <Fragment>
        <div className="col-xs-12" ref={activityContainer}>
        <div className="col-xs-12 m-b-5 m-t-5">
          <details>
            <summary>CONSENT TO SHARE FOR FEEDBACK</summary>
            <span className="checkbox-container" onClick={e => updatePrivacyType(e)}>
              <span>
                Yes, share this student profile with other users of this Journey so that i can try
                and get their feedback.

                I understand that my profile will be shared as shown below without any user name. All feedback will also be
                without any user name.
              </span>
              <input type="checkbox" checked="" readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>
          </details>
        </div>
        </div>
      </Fragment>
    )
  }

  const List = (list) => list && list.map((k) => (
    <span className="m-r-5">{k.data.label}, </span>
  ))

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-12">
          <div className="col-md-4 pull-left">
            <JourneyTitle title={selectedCollege == null ? "My Profile" :`${ selectedCollege.college_name + ", " +selectedCollege.journey_profile.school_state }`} project={project} />
          </div>
          <div className="col-md-8 pull-right">     
            <Link data-tip="Back to Journey Home" onClick={e=>toggleHome(e)} className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-home"></i>
            </Link>
          
            <Link  to={{pathname:`/search/student/profiles/${params.journey_category_id}/${params.journey_profile_id}`, state:project}} data-tip="Search public profile" className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-search"></i>
            </Link>

            <Link data-tip="Create New Profile" to={`/highschool/student/profile/form/${params.journey_category_id}/${params.journey_profile_id}`} className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-plus"></i>
            </Link>
            {selectedCollege != null &&
              <Link data-tip="My Profile" onClick={e=>toggleMyProfile(e)} className="bg-highlight round-btn float-right m-r-5 m-t-4">
                <i className="fas fa-user"></i>
              </Link>
            }
          </div>
          <div className="clear"></div>
        </div>
      </div>

      <Consent />

      <div className="col-xs-12 p-0" id="my-edu-profile">
        <ListActivity flag={true} activityReport={activityReport} 
          activityCategory={activityCategory}  journey_category_id={params.journey_category_id} 
          journey_profile_id={params.journey_profile_id} tabIndex={tabIndex} activities={activities} 
          dataPosition={dataPosition} openTab={openTab} pageName="my_profile" createRating={createRating}/>
      </div>

    </Fragment>
  )
}

export default MyEduProfile;

/*
{requestParams.id!='user' && 
          <MultiRating ratingItem={cueMeProfile} onSelect={createRating} 
          ratingList={data_source.rating_student_profile} 
          ratingData={cueMeProfile.rating && cueMeProfile.rating.data}/>
        }
*/