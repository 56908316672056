import env from '../env';
import axios from 'axios';
import BaseService from "../services/BaseService";
const url = `${env.family_tree_backend}favourites`

class FavouritesService extends BaseService {
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  add(favourite){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, favourite).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }*/

}

export default new FavouritesService('family_tree_backend', 'favourites');
