import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";

const Logout = (props) => {
  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);

  useEffect(() => {
    currentUser?.id && signout()   
  }, [])

  const signout = () =>{
    AuthenticationService.signout().then(()=>{
      dispatch({type: "LOGOUT"})
    })
  }

  return null
}

export default Logout;