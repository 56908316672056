import React from "react"
import {Image, ImageList} from "../../Common/Image"
import Cs from "../../../services/CommonService"

let PermissionList = ({list, obj}) => list && Object.keys(list).length>0 ? Object.keys(list).map((n) =>
  <span className="text-cap round-tab bg-yellow-white" key={n}>{obj[n]}&emsp;</span>
): <span>None</span>

let NotificationList = ({list, obj}) => list && list.length>0 ? list.map((n) =>
  <span className="text-cap round-tab bg-yellow-white" key={n}>{obj[n]}&emsp;</span>
): <span>None</span>

let PillDsList = ({list, dataKey, style, styleFn}) => list && list.length>0 ? list.map((n, i) =>
  <span className={style?style:'text-cap round-tab bg-highlight white p-5'} style={styleFn && styleFn(n)} key={i}>{n.data[dataKey]}&emsp;</span>
): null

let PillStringList = ({list, style, styleFn}) => list && list.length>0 ? list.map((n, i) =>
  <span className={style?style:'text-cap round-tab bg-highlight white p-5'} style={styleFn && styleFn(n)} key={i}>{n}&emsp;</span>
): null

let TablePillDsList = ({list, dataKey, style, styleFn}) => list && list.length>0 ? list.map((n, i) =>
  <td>
    <span className={style?style:'text-cap round-tab bg-highlight white p-5'} style={styleFn && styleFn(n)} key={i}>{n.data[dataKey]}&emsp;</span>
  </td>
): <span className={style}>None</span>

let PillList = ({list, dataKey, style, onSelect}) => list && list.length>0 ? list.map((n, i) =>{
  const props = {
    className : style?style:'text-cap round-tab bg-highlight white p-5'
  }
  if(onSelect){
    props.onClick = ()=>onSelect(n)
  }

  return(
    <span {...props} key={i}>
      {n[dataKey]}&emsp;
    </span>
  )  
}): <span className={style}>None</span>

let PillDsField = ({field, dataKey, style}) => field ?
  <span className={style?style:'text-cap round-tab bg-highlight white p-5'} >{field[dataKey] && field[dataKey]}</span>: <div>None</div>

let RenderData = ({data = {}, ds = {}, dataKey}) =>{

  const d = ds?.[dataKey] 	
  if(d){
    if(Array.isArray(d)){
      return <PillDsList list={d} dataKey='label' style="pill white bg-lred m-r-5"/>
    }else{
      return <>{d.label}</>
    }
  }else if(Array.isArray(data[dataKey+'_array'])){
    return <PillList list={data[dataKey+'_array']} dataKey='name' style="pill white bg-lred m-r-5"/>
  }else if(data[dataKey]){
    if(Cs.isUrl(data[dataKey])){
      return <a href={data[dataKey]} target="_blank" className="underline">{data[dataKey]}</a>
    }else{
      return <>{data[dataKey]}</>
    }
  }

  return null	
}

const FormFieldLabel = ({field, labelClass, editFormField}) =>{
  const editIcoprops = {
    className : "fas fa-edit m-l-10"
  }
  
  if(editFormField){
    editIcoprops.onClick = ()=>editFormField(field)
  }

  return (
    <h5 className={`fw500 m-b-5 ${labelClass}`}>
      {field.label}
      <span {...editIcoprops}/>
    </h5>
  )
}

const FormFieldTable = ({formFields = [], data = {}, dataSource = {}, labelClass='', editFormField}) => formFields.map((f, i)=>{
  if(f.component == 'richtext'){
    return(
      <div key={i} className="border-dashed-sm p-b-10 m-b-5">
        <FormFieldLabel field={f} labelClass={labelClass} editFormField={editFormField}/>
        <div dangerouslySetInnerHTML={{ __html: data?.[f.client_id]}}/>
      </div>
    )
  }else if('imageupload'.includes(f.component)){
    return(
      <div key={i} className="border-dashed-sm p-b-10 m-b-5">
        <FormFieldLabel field={f} labelClass={labelClass} editFormField={editFormField}/>
        <Image image={data?.[f.client_id]} styleName="w-70p"/>
      </div>
    )
  }else if('multi_file_upload'.includes(f.component)){
    return(
      <div key={i} className="border-dashed-sm p-b-10 m-b-5">
        <FormFieldLabel field={f} labelClass={labelClass} editFormField={editFormField}/>
        <ImageList list={data?.[f.client_id]} styleName="w-70p"/>
      </div>
    )
  }else if('input_tags'.includes(f.component)){
    return(
      <div key={i} className="border-dashed-sm p-b-10 m-b-5">
        <FormFieldLabel field={f} labelClass={labelClass} editFormField={editFormField}/>
        {data?.[f.client_id+'_array'] ? 
          <PillStringList list={data?.[f.client_id+'_array']} style="pill white bg-lred m-r-5"/>
          :
          null
        }
      </div>
    )
  }else if(f.component == 'rich_image_map'){
    return null
  }else if(f.component == 'lottie_svg'){
    return null
  }

  return( 
    <div key={i} className="border-dashed-sm p-b-10 m-b-5">
      <FormFieldLabel field={f} labelClass={labelClass} editFormField={editFormField}/>
      <div>
        <RenderData data={data} ds={dataSource} dataKey={f.client_id}/>
      </div>
    </div>
  )
})

export {FormFieldTable, PermissionList, NotificationList, PillList, PillDsList, RenderData, TablePillDsList, PillDsField, PillStringList};