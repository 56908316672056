import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../../hooks/useStyle";
import CommonService from "../../../../services/CommonService";
import useEscape from "../../../../hooks/useEscape";
import Tabs, { TabPane } from 'rc-tabs';

let requestParams = {};

const CareerDetailsTab = ({futureCareer, menuType, journey_category_id, journey_profile_id, params, isOpen, toggleModal, widthCss}) => {
  useStyle("add_role_dialog");
  useStyle("filter_dialog");
  useEscape(toggleModal);

  const history = useHistory();  
  const {state: { screen, user: currentUser },dispatch,} = useContext(AuthContext);
  const [careerCategory, setCareerCategory] = useState();

  useEffect(() => {
    requestParams = {
      career_id: futureCareer.id,
      page:1,
      per_page:2
    }
    getCareerCategory()
  }, [])

  let getCareerCategory = () => {
    CommonService.getDataSourceList(requestParams).then((res) => {
      //let d = CommonService.groupBy(res.career_j_activity_cat.options, "actions")
      setCareerCategory(res.data.data_source_lists[0].data);
    })
  }

  const DataComponent =(props) =>{
    const {data} = props
    let tabKeys =[]
    let tabValues =[]
    if(data !== undefined ){
      for (const [key, value] of Object.entries(data)) {
        tabKeys.push(key)
        tabValues.push(value)
      } 
    }    
    return(
      <div className="col-xs-12 p-20">
      <Tabs defaultActiveKey="1">
        <TabPane tab={tabKeys[4]} key="1">
          <span dangerouslySetInnerHTML={{ __html: tabValues[4]}}></span>
        </TabPane>
        <TabPane tab={tabKeys[8]} key="2">
          <span dangerouslySetInnerHTML={{ __html: tabValues[8]}}></span>
        </TabPane>
        <TabPane tab={tabKeys[9]} key="3">
          <span dangerouslySetInnerHTML={{ __html: tabValues[9]}}></span>
        </TabPane>
        <TabPane tab={tabKeys[10]} key="4">
          <span dangerouslySetInnerHTML={{ __html: tabValues[10]}}></span>
        </TabPane>
        <TabPane tab={tabKeys[0]} key="5">
          <span dangerouslySetInnerHTML={{ __html: tabValues[0]}}></span>
        </TabPane>
        <TabPane tab={tabKeys[7]} key="6">
          <span dangerouslySetInnerHTML={{ __html: tabValues[7]}}></span> 
        </TabPane>
        <TabPane tab={tabKeys[11]} key="7">
          <span dangerouslySetInnerHTML={{ __html: tabValues[11]}}></span>
        </TabPane>
        <TabPane tab={tabKeys[13]} key="8">
          <span dangerouslySetInnerHTML={{ __html: tabValues[13]}}></span>
        </TabPane>
        <TabPane tab={tabKeys[5]} key="9">
          <span dangerouslySetInnerHTML={{ __html: tabValues[5]}}></span>
        </TabPane>
      </Tabs>
      </div>
    )

  }

 

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className={`ml-modal-content ml-card-4 ml-animate-zoom ${widthCss}`} >
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Career Details</h4>
            <button className="btn-close"
            title="Close Modal" onClick={(e) => toggleModal(e)}>×
            </button>
          </div>

          <div className="row p-5">
            <DataComponent data={careerCategory} />
          </div>
        </div>
      </div>
    </Fragment>, document.body
  )
}

export default CareerDetailsTab;