import axios from 'axios';

//axios.defaults.baseURL = 'https://jsonplaceholder.typicode.com';
//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
//axios.defaults.withCredentials = 'include';

axios.interceptors.request.use(request => {
    console.log(request);
    // Edit request config

    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log(response);
     // Edit response config
     return response;
}, error => {
    //console.log(error.response.status);
    return error.response;
    //Promise.reject(error);
});