import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CheckAccess from "../../../Roles/CheckAccess";
import CommonService from "../../../../../services/CommonService";
import TeamStepActivity from "../../StepActivities/StepActivities";
import AddToFavourite from "../../../../Common/AddToFavourite"
import {SortableContainer, SortableElement} from 'react-sortable-hoc';

const JourneyActivity = (props) => {
  const { state: authState, dispatch } = useContext(AuthContext);

  let activity = props.activity;
  activity.updated_at = CommonService.formatUpdateDate(activity.updated_at);
  let data = activity.data;
  let ds = props.dataSource;

  const cardStyle = {borderLeft: "10px solid "+data.background_color || data.header_background || "#607D8B"}

  if (!data) return null;

  let userRoleLink = {
    pathname: `/journey/users/${activity.journey_category_id}/${activity.education_journey_profile_id}/edu_team_journey.activity/${activity.id}`,
    state: { journey: props.journeyCategory, activity: activity },
  }

  let incomingMailLink = {
    pathname: `/journey/incoming/emails/edu_team_journey.activity/${props.activity.id}`,
    state: { journey: props.journeyCategory, activity: props.activity },
  }

  const MailTag = () => (
    <a href={`mailto: ${activity.id}-team_activity@reply.cuetree.com?subject=Education Activity-${data.name}-${activity.record_id}`}
      target="_blank">
      <i className="far fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="card-title card-title-gray flex aligncenter p10 p-b-15" style={cardStyle}>
      <div className="flex aligncenter w-100p">
        <Link to="" id="head" className="content">
          <div className="w-100">
            <h4 className="fw700 card-heading truncate">{data.name}</h4>
            <p className="f12">by {activity.author.name} since {CommonService.formatUpdateDate(activity.updated_at)}</p>
          </div>
        </Link>
      </div>
      <div className="menu-drop-icon flex justifycenter aligncenter">
        {/* <i className="fas fa-expand pull-right white expand" onClick={(e) => props.routeActivity(activity)}></i> */}
        <AddToFavourite favouriteId={activity.favourite_id}
          favorableType="edu_team_journey.activity"
          favorableId={activity.id}
          journeyCategoryId={activity.journey_category_id}
          classNameActive="pin-40 green" classNameDisable="pin-40 white"
          journeyProfileId={activity.education_journey_profile_id}/>
          {activity.user_roles_count > 0 && (
            <Link className="pull-right white expand p-top-0" onClick={(e) => e.stopPropagation()}
              to={userRoleLink}>
              <i className="fa fa-users"></i>
            </Link>
          )}
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer flex justspacebetween p10-20 aligncenter">
      <ul className="flex aligncenter">
        <li id={`comment_${props.index}`}
          className="m-r-25"
          onClick={(e) => {props.addNotes(e, activity);}}>
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge up bg-darkgrey">{activity.comments_count}</span>
        </li>
        <li id={`check_list_${props.index}`}
          className="m-r-25"
          onClick={(e) => {props.addChecklist(e, activity);}}>
          <i className="far fa-check-circle font-18 text-muted"></i>
          <span className="badge up bg-darkgrey">{activity.check_list_count}</span>
        </li>
        <li id={`mail_${props.index}`} className="m-r-25">
          <Link onClick={(e) => e.stopPropagation()} to={incomingMailLink}>
            <i className="far fa-envelope font-18 text-muted"></i>
            <span className="badge up bg-darkgrey">
              {activity.mail_count || 0}
            </span>
          </Link>
        </li>
      </ul>
      <props.menu activity={activity} />
    </div>
  );

  const SponsorCard = () => {
    return (
      <div className="bg-white border-rounded-10 m-t-10">
        <Header />
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span className="pull-right">
                    Target Date: {CommonService.formatUpdateDate(data.reminder)}
                  </span>
                  <strong>Drop Off</strong>
                  <div>
                    {data.street_name}
                    <br />
                    {data.city_state}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Notes</strong>
                  <div className="ht-70 scroll-auto">{data.notes}</div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <TeamStepActivity
            stepActivities={activity.step_activities}
            activity={activity}
            journeyCategory={props.journeyCategory}
            share={props.share}
          />
        </div>
        <Footer />
       
      </div>
    );
  }

  const TeamBookCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header />
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span className="pull-right">
                    Target Date: {data.reminder}
                  </span>
                  <strong>Intro</strong>
                  <div className="ht-70 scroll-auto">{data.intro}</div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <TeamStepActivity
            stepActivities={activity.step_activities}
            activity={activity}
            journeyCategory={props.journeyCategory}
            share={props.share}
          />
        </div>
        <Footer />
      </div>
    );
  }

  const TeamCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header />
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span className="pull-right">
                    Target Date: {data.reminder_end}
                  </span>
                  <strong>Notes</strong>
                  <div className="ht-70 scroll-auto">{data.notes}</div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          <TeamStepActivity
            stepActivities={activity.step_activities}
            activity={activity}
            journeyCategory={props.journeyCategory}
            share={props.share}
          />
        </div>
        <Footer />
      </div>
    );
  }

  if (activity.activity_type == "sponsor") {
    return (<SponsorCard />);
  } else if (activity.activity_type == "team_book") {
    return (<TeamBookCard />);
  } else if (activity.activity_type == "team") {
    return (<TeamCard />);
  }else{
    return null
  }
}

export default JourneyActivity;

/*<span className="m-l-25"><MailTag/></span>*/
