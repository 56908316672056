import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import useStyle from "../../../../hooks/useStyle";
import useModal from "../../../../hooks/useModal";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import StudentProfileServices from "../StudentProfiles/StudentProfileServices";
import CommonService from "../../../../services/CommonService";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle";
import {HighSchoolLink} from "../../../Common/RouteLinks"

let requestParams = {};
let data_source = {};
let timer = null;
let searchKeyword = null;

const EduPublicProfile = (props) => {
  useStyle("panel");
  useStyle("profile");
  useStyle('card');

  const { state: { screen, user: currentUser }, dispatch, } = React.useContext(AuthContext);
  const [cueMePublicProfile, setCueMePublicProfile] = useState([]);

  let params = props.match.params;
  let project = props.location.state;

  React.useEffect(() => {
    requestParams = {
      page: 1,
      per_page: 30,
      search: searchKeyword || null,
      sort_column: "updated_at",
      sort_direction: "desc",
      data_source_params: {
        data_source_id: [2530, 2653, 3321, 4, 3, 2, 2214, 3345],
        response_type: "object",
      },
      journey_category_id: props.journey_category_id,
      education_journey_profile_id: props.journey_profile_id,
      privacy_type: "public",
    };

    getStudentProfiles();
  }, []);

  let getStudentProfiles = (search) => {
    requestParams.search = search || null;
    StudentProfileServices.getEduActivityStats(requestParams).then((res) => {
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        setCueMePublicProfile(res.data.education_activity_stats);
      }
    });
  };

  const onSearchChange = (e) => {
    clearTimeout(timer);
    searchKeyword = e.target.value;
    timer = setTimeout(triggerSearch, 1000);
  };

  const triggerSearch = () => {
    getStudentProfiles(searchKeyword);
  };

  const List = (list) => list && list.map((k,i) => (
    <div className="display-inline" key={i}>
      <i className={k.data.icon + ' p-5'} style={{color:k.data.icon_color}}></i>
      {k.data.label}
    </div>
  ))

  const CueMeProfiles = () => {
    return (
      <Fragment>
        <div className="row bg-white">
          <div className="search-box search-color bg-white shadow-tiny bottom-10">
            <i className="fa fa-search"></i>
            <input type="text" defaultValue={searchKeyword} placeholder="Search here.."
              autoFocus onChange={(e) => onSearchChange(e)}/>
          </div>

          <div className="row">
            <div className="p-5 center-text">
              {cueMePublicProfile && cueMePublicProfile.map((p, k) => (
                <div className={ props.flag != true ? `col-xs-12 col-sm-6 col-md-4 br-rt-btm text-left card-container ht-150`:`col-xs-12 text-left center-text card-container br-rt-btm m-t-5 ht-150`} key={k}>
                  <table  className="table card-table bg-white" style={{ height: "100px", width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="font-14">
                          {p.college_name && (
                            <p className="highlight">{p.college_name}</p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-14">
                          {moment(p.journey_profile.data.grad_date).format("YYYY")}-{p.privacy_type}
                        </td>
                        <td rowSpan="2" className="pull-right">
                          <Link onClick={(e) => props.loadCollegeProfile(e,p)}
                            className="btn f-16 p-5 m-t-5">
                              RATE
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-14 bold-600">
                          {p.journey_profile.school_state}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" className="font-14">
                          {List(p.journey_profile.data_source.subjects_most_interest)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
     <div className="content clearfix">
        <div className="col-xs-12">
      <div className="col-xs-8">
        <div className="font-15 font-500">RATE / GIVE FEEDBACK</div>
      </div>
      <div className="col-xs-4">
        <Link
          data-tip="Back to Journey Home"
          to={HighSchoolLink.hs_portal(params)}
          className="bg-highlight round-btn float-right m-r-5">
          <i className="fas fa-home"></i>
        </Link>

        <Link
          to={{pathname:`/highschool/profile/${params.journey_category_id}/${params.journey_profile_id}`,state:project}}
          data-tip="Search profile"
          className="bg-highlight round-btn float-right m-r-5">
          <i className="fas fa-user"></i>
        </Link>
      </div>
      </div>
      </div>
      <div className="page-content">
        <CueMeProfiles />
      </div>
    </Fragment>
  );
};

export default EduPublicProfile;
