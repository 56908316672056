import React ,{useState, Fragment, useEffect} from "react";
import moment from 'moment'
import { useHistory, Link } from 'react-router-dom'
import StepActivitiesService from "./StepActivitiesService";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import useModal from "../../../../hooks/useModal";
import CommonService from '../../../../services/CommonService';
import GenericModal from "../../../Modals/GenericModal";
import TrackProgress from "../StepActivityResponses/TrackProgress"

let selectedStepActivity = null;

const HighSchoolStepActivity = (props) => {
  let history = useHistory()  	
  //let stepActivities = props.stepActivities;
  const [activeIndex, setActiveIndex] = useState();
  /*const [visible, setVisible] = useState(false);*/
  const [stepActivities, setStepActivities] = useState(props.stepActivities);
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();
  const {isOpen:isStepActivityOpen, toggleModal:toggleStepActivityModal } = useModal()
  const {isOpen:isTrackProgressOpen, toggleModal:toggleTrackProgressModal } = useModal()

  const toggleMenu =(i, s) =>{
    selectedStepActivity = s
    toggleStepActivityModal(i,s)
    /*setVisible(!visible)*/
  }

  useEffect(() => {
    if(props.selectStepId){
      document.getElementById(`step-activity-${props.selectStepId}`).scrollIntoView();
    }
  }, [props.selectStepId]);

  const deleteStepActivity = (e) => {
    e.preventDefault();
    StepActivitiesService.delete(selectedStepActivity).then((res)=>{
      if(res.status==204){
        setStepActivities(stepActivities.filter(i => i.id !== selectedStepActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const confirmDelete = (e, step_activity) =>{
    e.preventDefault();
    selectedStepActivity = step_activity;
    toggleDeleteModal(e);
  }

  const updateStatus = (e, step_activity) =>{
    e.preventDefault();
    step_activity.data.completion_status = step_activity.data.completion_status=='1'?'0':'1';
    StepActivitiesService.update(step_activity).then((res)=>{
      if(res.status==204){
        setStepActivities([...stepActivities]);
      }
    })
  }

  const openTrackProgress = (e, step_activity, status) =>{
    const activity_type = props.activityTypeList[props.activity.data.fj_edu_entry_type]
    e.stopPropagation()
    selectedStepActivity = step_activity
    selectedStepActivity.progress_status = status
    selectedStepActivity.activity_type = activity_type
    toggleTrackProgressModal(e)
  }

  const FileList = ({files}) => {
    return(
      <Fragment>
        {files && files.map((file, k) =>  (
          <a key={k} className="m-t-5 inline-files-list" target="_blank" href={CommonService.getFileUrl(file)}>
            <img src={CommonService.getIconByType(file)} width="35" height="35"/>
            <span className="m-l-5">{CommonService.getFileName(file)}</span>
          </a>
        ))}
      </Fragment>
    )
  }

  const Menu = ({step_activity}) => (
    <span className="dropup pull-right">
      <a><i className='fas fa-bars'></i></a>
      <div className="dropup-content">
        <Link to={`/highschool/step_activity/form/${step_activity.journey_category_id}/${step_activity.education_journey_profile_id}/${step_activity.activity_id}/${step_activity.activity_form_id}/${step_activity.activity_type}/${step_activity.id}`}>
          <i className="far fa-edit"/> Edit
        </Link>
        <a href="#" onClick={e =>confirmDelete(e, step_activity)}>
          <i className="far fa-trash-alt"/> Delete
        </a>
        <a href="#" onClick={e=>openTrackProgress(e, step_activity, 'progress')}>
          <i className="far fa-thumbs-up"/> Progress
        </a>
        <a href="#" onClick={e=>openTrackProgress(e, step_activity, 'problem')}>
          <i className="far fa-thumbs-down"/> Problem
        </a>
      </div>
    </span>
  )

  const SponsorCard = ({step_activity, data}) => {
    return(<div className="p-5">
      <div className="vcard-field">
        <strong>Quantity</strong>
        <a href="#">{data.quantity_requested}</a>
      </div>
      <div className="vcard-field">
        <strong>Drop Off</strong>
        <a href="#">{data.drop_off}</a>
      </div>
      <div className="vcard-field">
        <strong>Notes</strong>
        <div dangerouslySetInnerHTML={{ __html: data.notes }}  />
      </div>
    </div>)
  }

  const TeamBookCard = ({step_activity, data}) => {
    return(<div className="p-5 font-14">
    <p className="pull-right font-10" style={{    border: "1px solid",
      padding: "2px"}}>{step_activity.author_name}-{CommonService.formatUpdateDate(step_activity.created_at)}</p>
      <FileList files={data.upload_files}/>
      <a href={data.reference_url} target="_blank">{CommonService.extractHostName(data.reference_url)}</a>
      <div className="m-t-5" dangerouslySetInnerHTML={{ __html: data.notes }}  />
    </div>
    )
  }

  const TeamCard = ({step_activity, data}) => {
    return(<div className="p-5 font-14">
      <p className="pull-right font-10" style={{    border: "1px solid",
        padding: "2px"}}>{step_activity.author_name}-{CommonService.formatUpdateDate(step_activity.created_at)}</p>
        <a href={data.reference_url} target="_blank">{data.reference_url}</a>
        <FileList files={data.upload_files}/>
        <a className="m-t-5" href="#">{data.reminder}</a>
        <div className="m-t-5" dangerouslySetInnerHTML={{ __html: data.notes }}  />
      </div>
    )
  }

  const FileLink = ({step_activity, data}) =>{
    if(step_activity.activity_type == 'step_activity') {
      return (<Fragment> 
        {data.file_upload && data.file_upload.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
       </Fragment>  
      )
    }else if(step_activity.activity_type == 'team_step_activity'){
      return (<Fragment> 
        {data.upload_files && data.upload_files.length>0 && <span className="pointer pull-right m-r-15">
          <i className="fas fa-paperclip color-highlight"></i>
        </span>}
       </Fragment>  
      )
    }else{
      return null
    }
  }

  const RenderCard = ({step_activity, data}) =>{
    if(step_activity.activity_type == 'sponsor_step_activity'){
      return <SponsorCard step_activity={step_activity} data={data} />
    }else if(step_activity.activity_type == 'step_activity') {
      return <TeamBookCard step_activity={step_activity} data={data} />
    }else{
      return <TeamCard step_activity={step_activity} data={data} />
    }
  }
  
  return (
  	<Fragment>
  		<div>
        {stepActivities && stepActivities.map((s, i) => {
          let step_ribbon_color
         
          if(s.updated_at){
            let duration
            var a = moment(s.updated_at);
            var b = moment();

            duration = b.diff(a, 'days')+1;
           
            if(duration < 7){
              step_ribbon_color= 'green'
            }else if(duration > 7  && duration < 14){
              step_ribbon_color	= 'orange'
            }else {
              step_ribbon_color	= 'red'
            }

          }else{
            let duration
            var a = moment(s.created_at);
            var b = moment();
            duration = b.diff(a, 'days')+1;

            if(duration < 7){
              step_ribbon_color= 'green'
            }else if(duration > 7  && duration < 14){
              step_ribbon_color	= 'orange'
            }else {
              step_ribbon_color	= 'red'
            }
          }
          
          return (
            <Fragment key={i}>
            <div id={`step-activity-${props.selectStepId}`} className={`card-item active m-b-15 m-t-15`} onClick={e=>toggleMenu(e, s)}>
              <p className="m-b-5">{s.data.name}</p>
              <Menu step_activity={s}/>
              <div className="flex aligncenter m-b-5 card-bullets">
                <span className="m-r-10">{s.data.reminder}</span>
                <span className="m-r-10">
                  <FileLink step_activity={s} data={s.data} />
                </span>
                <span className="m-r-10">
                  <Link className="pull-right m-r-15" onClick={e=>openTrackProgress(e, s, 'problem')}>
                    <span className="fa-stack font-10 fa-1x">
                      <i className={`fas fa-circle fa-stack-2x lgrey`}/>
                      <i className={`fa-stack-1x fa far fa-thumbs-down white`}/>
                    </span>          
                  </Link>
                </span>
                <span className="m-r-10">
                  <Link className="pull-right font-10 m-r-15" onClick={e=>openTrackProgress(e, s, 'progress')}>
                    <span className="fa-stack fa-1x">
                      <i className={`fas fa-circle fa-stack-2x lgrey`}/>
                      <i className={`fa-stack-1x fa far fa-thumbs-up white`}/>
                    </span>
                  </Link>
                </span>
              </div>
            </div>
            </Fragment>
          )})}
        </div>
        {isStepActivityOpen && (
          <GenericModal component={RenderCard} title={selectedStepActivity.data.name} step_activity={selectedStepActivity}
            data={selectedStepActivity.data}
            isOpen={isStepActivityOpen} toggleModal={toggleStepActivityModal} />
        )}
        {isTrackProgressOpen && (
          <GenericModal component={TrackProgress} title={selectedStepActivity.data.name} 
            stepActivity={selectedStepActivity}
            isOpen={isTrackProgressOpen} toggleModal={toggleTrackProgressModal} />
        )}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteStepActivity}/>
  	</Fragment>
  )

};

export default HighSchoolStepActivity;