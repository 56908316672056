import React, { Fragment } from "react";

const DataList = ({list}) => list && list.map((k) => (
  <span className="col-xs-4">{k.data.label}, </span>
))

const DataColumn = ({data, ds, row, ui}) => ['left', 'middle', 'right'].map((col, i) => {
  let p = ui[col+'_'+row]
  if(ds[p]){
    let d = ds[p]
    if(Array.isArray(d)){
      return <DataList list={d}/>
    }else{
      return(
        <Fragment key={i}>
          <span className="col-xs-4">{d.label}</span> 
        </Fragment>
      )  
    }
  }else if(data[p]){
    return(
      <Fragment key={i}>
        <span className="col-xs-4">{data[p]}</span> 
      </Fragment>
    )
  }
    
    return null  
})

const DataRow = ({item, uiInfo}) =>{
  if(!uiInfo) return null;

  return(
    <Fragment>
      {[0, 1].map((i) =>
        <div key={i} className="row">
          <DataColumn data={item.data || {}} ds={item.data_source||{}} row={i} ui={uiInfo}/>          
        </div>
      )}
    </Fragment>
  )
}

export {DataList, DataColumn, DataRow}