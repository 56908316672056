import React, { Fragment, useState, useContext } from "react"
import ReactTooltip from "react-tooltip"
import { AuthContext } from "../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../components/PreLoadSpinner"
import PrivateRoute from "../routes/PrivateRoute"
import PublicRoute from "../routes/PublicRoute"
import { Route, Switch } from "react-router-dom"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import SideMenu from "../components/SideMenu/SideMenu"
import Form from "../components/FormBuilder/Form"
import Profile from "../components/Dashboard/Profile"
import ForumRouter from '../components/Forums/ForumRouter'
import ProfileForm from "../components/Journey/Form/ProfileForm"
import PushNotification from "../components/Notification/PushNotification"
import Roles from "../components/Journey/Roles/RoleList"
import DataSourceList from "../components/DataSources/DataSourceList"
import DataSourceListFilter from "../components/DataSources/DataSourceListFilter"
import JourneyRouter from "../components/Journey/JourneyRouter"
import NewsRouter from "../components/News/NewsRouter"
import QuestionRouter from "../components/Questions/QuestionRouter"
import HelpRouter from "../components/Help/HelpRouter"
import DigitalContentRouter from "../components/DigitalContent/DigitalContentRouter"
import AddToHomeScreen from "./Common/AddToHomeScreen"
import NotificationList from "../components/Notifications/NotificationList"
import UserProfile from "../components/UserProfile/UserProfile"
import DemoUserProfile from "../components/UserProfile/DemoUserProfile"
import JourneyDashboard from "../components/Journey/Dashboard/JourneyDashboard"
import PublicComment from "../components/Comments/PublicComment"
import FeedbackForm from "../components/Feedback/FeedbackForm"
import FeedbackList from "../components/Feedback/List/FeedbackList"
import HighSchoolSchedules from "../components/Journey/HighSchoolJourney/Calendars/HighSchoolSchedules"
import ChatUsers from "../components/Chats/ChatUsers"
import ChatModal from "../components/Chats/ChatModal"
import ChatBot from "../components/Chats/ChatBot"
import useModal from "../hooks/useModal"
import Welcome from "../components/Welcome/Welcome"
import RedirectNotification from "../components/Common/RedirectNotification"
import InviteOthersList from "../components/InviteOthers/InviteOthersList"
import ImageMapModal from "../components/FormBuilder/ImageMapModal"
import env from "../env"
import PricingTable from "../components/SubscriptionPayments/PricingTable"
import SubscriptionDetails from "../components/SubscriptionPayments/SubscriptionDetails"
import Checkout from "../components/SubscriptionPayments/Checkout"
import HeaderMsgBar from "../components/Common/HeaderMsgBar"
import PaymentForm from "../components/SubscriptionPayments/PaymentForm"
import PricingChange from "../components/SubscriptionPayments/PricingChange"
//import TestNotification from "../components/Notification/TestNotification";
import ZoomMeetingList from '../components/Zoom/ZoomMeetingList'
import MeetingDetails from '../components/Zoom/MeetingDetails'
import UserGroupList from '../components/UserGroups/UserGroupList'
import OrgAccountInactiveNotice from '../components/Authentication/OrgAccountInactiveNotice'
import GenericModal from "./Modals/GenericModal"
import OrganizationList from "./Header/OrganizationList"
import UserFormRoute from "../components/UserForms/UserFormRoute"
import LocaleList from "../components/Locale/LocaleMenu"
import LeaderShipDashboard from "../components/Dashboard/LeaderShipDashboard"
import ReactFlowRenderer from "../components/ReactFlow/ReactFlowRenderer"
import AccountConfirmation from "../components/Common/AccountConfirmation"
import WelcomeJourney from "../components/Common/WelcomeJourney"
import DailyDabbleForm from "../components/DailyDabbles/DailyDabbleForm"
import DailyDabbleList from "../components/DailyDabbles/DailyDabbleList"
import DailyDabbleResponsesTable from "../components/DailyDabbleResponses/DailyDabbleResponsesTable"
import DailyDabbleUserView from "../components/DailyDabbles/DailyDabbleUserView"
import EmailList from "../components/NewsletterEmails/EmailList"
import UnSubscribeForm from "../components/NewsletterEmails/Form/UnSubscribeForm"
import SubscriptionRoute from "../components/Subscriptions/Route"
import MemberList from "../components/Members/MemberList"
import MemberForm from "../components/Members/MemberForm"
import ContactList from "../components/UserContacts/ContactList"
import ContactForm from "../components/UserContacts/ContactForm"
import TrackRelationshipForm from "../components/UserContacts/TrackRelationship/TrackRelationshipForm"
import LoginSuccessMsg from "./Authentication/LoginSuccessMsg"
import InterventionProtocolRouter from './InterventionProtocols/Route'

const Home = (props) => {
  const { state: { screen, user: currentUser, isAuthenticated: isAuthenticated }, dispatch, } = useContext(AuthContext)
  
  let [openSideNav, setOpenSideNav] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [socketData, setSocketData] = useState()

  const {isOpen: showChatModal, toggleModal: toggleChatModal } = useModal()
  const {isOpen: showOrgModal, toggleModal: toggleOrgModal} = useModal()
  const {isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal()

  let [openChatBot, setOpenChatBot] = useState(false)

  let app = {}

  return (
    <Fragment>
      {isAuthenticated && (
        <Header openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} 
          setSocketData={setSocketData} searchString={searchString} 
          setSearchString={setSearchString} toggleOrgModal={toggleOrgModal} 
          toggleLocaleModal={toggleLocaleModal}
          {...props}/>
      )}

      {isAuthenticated && <HeaderMsgBar {...props}/>}
      
      {isAuthenticated && (currentUser.domain_info.data) && (
        <SideMenu openSideNav={openSideNav} setOpenSideNav={setOpenSideNav}
          toggleChatModal={toggleChatModal} toggleLocaleModal={toggleLocaleModal}/>
      )}
      
      {screen.xs && <AddToHomeScreen/>}

      {showOrgModal && <GenericModal component={OrganizationList} 
        isOpen={showOrgModal} toggleModal={toggleOrgModal}
        title="Select Organization"/>
      }
      
      {showLocaleModal && <GenericModal component={LocaleList} 
        title="Langauage" isOpen={showLocaleModal} 
        toggleModal={toggleLocaleModal}/>
      }

      {showChatModal && (
        <ChatModal isOpen={showChatModal} toggleModal={toggleChatModal} 
        socketData={socketData}/>
      )}

      <Switch>
        <Route path="/redirect" component={RedirectNotification} />
        <PrivateRoute path="/getstarted" component={Profile} exact />
        <Route path="/profile" component={ProfileForm} exact />
        <Route path="/reactflow" component={ReactFlowRenderer} exact />
        <Route path="/leadership" component={LeaderShipDashboard} exact />
        <PrivateRoute path="/form/:form_template_id" component={Form} exact />
        <PrivateRoute path="/roles" component={Roles} exact />
        <PrivateRoute path="/notification" component={PushNotification} exact />
        <PrivateRoute path="/list/data_sources" component={DataSourceList} exact/>
        <PrivateRoute path="/data_source_list/filters/:data_source_id" component={DataSourceListFilter} exact/>
        <PrivateRoute path="/notifications" component={NotificationList} exact/>
        <PrivateRoute path="/user/profile" component={DemoUserProfile} exact />
        <PrivateRoute path="/home" component={JourneyDashboard} exact />
        <PrivateRoute path="/feedback" component={FeedbackForm} exact />
        <PrivateRoute path="/list/feedback" component={FeedbackList} permissionType='current_admin.role_admin' exact />
        <PrivateRoute path="/schedules" component={HighSchoolSchedules} exact />
        <PrivateRoute path="/list/chat_user" component={ChatUsers} exact />
        <PrivateRoute path="/image_map" component={ImageMapModal} exact />
        <PublicRoute
          path="/YXBwL21vZHVsZXMvZmFtaWx5X2pvdXJuZXkvY29tbWVudC9wdWJsaWNfcmVwbHkuaHRtbA==/:comment_id/:email"
          component={PublicComment} exact/>
        <PrivateRoute path="/welcome" component={Welcome} exact />
        <PrivateRoute path="/account_confirm" component={AccountConfirmation} exact />
        <PrivateRoute path="/welcome_journey" component={WelcomeJourney} exact />
        <PrivateRoute path="/invite/others" component={InviteOthersList} exact/>
        <PrivateRoute path="/plans/:journey_category_id/:journey_member_id?" component={PricingTable} exact />
        <PrivateRoute path="/checkout" component={PaymentForm} exact />
        <PrivateRoute path="/account" component={SubscriptionDetails} exact />
        <PrivateRoute path="/change_plans" component={PricingChange} exact />
        <PublicRoute path="/checkouts" component={Checkout} exact />
        <PrivateRoute path="/zoom/meetings/:journey_category_id" component={ZoomMeetingList} exact />
        <PrivateRoute path="/zoom/meeting/:journey_category_id/:meeting_id" component={MeetingDetails} exact />
        <PrivateRoute path="/user/groups" component={UserGroupList} permissionType='current_admin.role_manager' exact />
        <PrivateRoute path="/org/inactive" component={OrgAccountInactiveNotice} exact />
        <PublicRoute path="/new/daily_dabble/:id?" component={DailyDabbleForm} exact />
        <PrivateRoute path="/list/daily_dabble" component={DailyDabbleList} searchString={searchString} permissionType='role_manager.enrichment' exact />
        <PrivateRoute path="/list/daily_dabble_responses/:daily_dabble_id" component={DailyDabbleResponsesTable} searchString={searchString} exact />
        <PrivateRoute path="/user/dabbles" component={DailyDabbleUserView} exact/>
        <PrivateRoute path="/newsletter/emails" component={EmailList} permissionType='current_admin.role_admin' exact />
        <PrivateRoute path="/newsletter/unsubscribe/:id?" component={UnSubscribeForm} exact />
        <PrivateRoute path="/list/relationship/tracking/:member_id/:id?" component={TrackRelationshipForm} exact />
        <PrivateRoute path="/member/form/:form_type_id/:form_template_id/:id?" component={MemberForm} exact />
        <PrivateRoute path="/journey/members" component={MemberList} exact />
        <PrivateRoute path="/invite/users" component={ContactList} exact />
        <PrivateRoute path="/add/contact/:form_type_id/:form_template_id/:id?" component={ContactForm} exact />
        <PublicRoute path="/welcome/message" component={LoginSuccessMsg} exact />
      </Switch>
      <Switch>
        <JourneyRouter searchString={searchString} app={app} />
      </Switch>
      <Switch>
        <NewsRouter searchString={searchString} />
      </Switch>
      <Switch>
        <QuestionRouter searchString={searchString} />
      </Switch>
      <Switch>
        <HelpRouter searchString={searchString} />
      </Switch>
      <Switch>
        <DigitalContentRouter searchString={searchString} />
      </Switch>
      <Switch><ForumRouter searchString={searchString} /></Switch>
      <Switch><UserFormRoute/></Switch>
      <Switch><SubscriptionRoute/></Switch>
      <Switch><InterventionProtocolRouter/></Switch>
      
      {isAuthenticated && env.admin_emails.includes(currentUser.email) && openChatBot && 
        <ChatBot openChatBot={openChatBot} setOpenChatBot={setOpenChatBot}/>
      }
      
      {isAuthenticated && 
        <Footer openChatBot={openChatBot} setOpenChatBot={setOpenChatBot}/>
      }
      
      <ReactTooltip place="bottom" />
    </Fragment>
  )
}

export default Home;