import { useState } from 'react';

const useModal = (value) => {
  const [isOpen, setIsOpen] = useState(value || false);

  function toggleModal(e, is_open) {
  	e && e.preventDefault();
    setIsOpen(!isOpen);
    is_open && setTimeout(() =>setIsOpen(is_open), 0);
  }

  return {
    isOpen,
    toggleModal,
  }
};

export default useModal;