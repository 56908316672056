import React, {useState, useEffect, Fragment} from "react";
import useStyle from '../../hooks/useStyle';
import CommonService from '../../services/CommonService';
import CommentsService from '../../services/CommentsService';
import PreLoadSpinner from "../../components/PreLoadSpinner";

const PublicComment = (props) => {
  useStyle('notifications');

  let params = props.match.params;

  const [loading, setLoading] = useState(false);
  const [isReplySent, setIsReplySent] = useState(false);
  const [email, setEmail] = useState(false);
  const [comment, setComment] = useState({});
  const [notification, setNotification] = useState({data:{}, email_data:{}});

  useEffect(() => {
    setEmail(CommonService.b64DecodeUnicode(params.email));
    getComment();
  }, []);

  const getComment = () =>{
    setLoading(true)
    CommentsService.get(params.comment_id).then((res)=>{
      setComment(res.data.comment);
      setNotification(res.data.notification);
      setLoading(false)
    })
  }

  const addComment = () => {
    setLoading(true)
    let req = {
      "commentable_id":comment.commentable_id,
      "commentable_type":comment.commentable_type,
      "journey_category_id":comment.journey_category_id,
      "comment":comment.reply_comment,
      "email":email
    }
    CommentsService.create(req).then((res)=>{
      if(res.status==201){
        setLoading(false)
        setIsReplySent(true);
        //setTimeout(() => {window.close();}, 10); 
      }
    })
  }

  const handleOnChange = (e) =>{
    comment.reply_comment = e.target.value;
    setComment({...comment});
  }

  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <Fragment>
      <div className="p-5 top-10 bg-highlight white">
        <div className="float-left font-500 font-15">
          {notification.journey_profile_name}
        </div>
        <div className="clear"/>
      </div>

      {!isReplySent &&
        <div className="p-5">
          <div className="notification bg-theme shadow-xl mht-120">
            <div className="toast-header color-highlight text-cap">
              <div className="mr-auto font-500 font-15">{notification.data.item_name}</div>
            </div>
            <div className="toast-body">
              <div className="font-500 font-14">{notification.email_data.description}</div>

              <textarea className="form-control" rows="5" placeholder="Add Reply Comment"
                name="reply_comment" required onChange={e=>handleOnChange(e)}>
              </textarea>

              <div className="clearfix text-center">
                {comment.reply_comment && <button onClick={e=>addComment()} className="btn btn-inverse m-t-5 text-center">
                  SUBMIT</button>
                }
              </div>
            </div>
          </div>
        </div>  
      }

      {isReplySent && 
        <div className="col-xs-12 text-center bg-white p-5">
          <h4 className="green">Your Comment was Received</h4>
        </div>
      }
    </Fragment>
  )

}

export default PublicComment;