import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Scholarship from './Scholarship'
import querystringify from "querystringify"
import ScholarshipService from "./ScholarshipService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import {MenuItem,NavIcon} from '../../Common/MenuItem'
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link, useHistory} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import useStyle from '../../../hooks/useStyle'
import Cs from '../../../services/CommonService'

let requestParams = {}
let selectedScholarship = {}
let dataSource = {}

const ScholarshipList = (props) => {
  useStyle('card')
  useStyle('card_table')

  let history = useHistory()
  let queryParam = querystringify.parse(props.location.search)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  
  const [scholarshipList, setScholarshipList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [formFieldsList, setFormFieldsList] = useState({})

  const {isOpen, toggleModal:toggleDeleteModal } = useModal()
  const {isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  
  let cardGridCss = "col-sm-12 col-sm-6 col-md-4"
  let cardHeightCss = "mih-380"
  
  useEffect(() => {
    requestParams = {
      'form_field_params': {
        'ids':[FormTemplateId.ScholarshipForm]
      },
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': 'latest_dabble_date',
      'sort_direction': 'desc',
      'search': props.searchString,
    }

    getScholarshipList()
  }, [props.searchString, currentUser.id, currentpage])

  let getScholarshipList = () => { 
    ScholarshipService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setPagemeta(res.data.meta)
        setScholarshipList(res.data.scholarships)
        setFormFieldsList(res.data.form_fields || {})
      }
    })
  }

  const addDelete = (e, item) => {
    selectedScholarship = item
    toggleDeleteModal(e)
  }

  const addMenu = (e, item) => {
    selectedScholarship = item
    toggleMenuModal(e)
  }

  const updateStatus = (e, item) =>{
    e.preventDefault()
    item.is_active = !item.is_active 
    ScholarshipService.update(item).then((res) => {
      if(res.status==204){
        setScholarshipList([...scholarshipList])
      }
    })
  }

  const deleteScholarship = (e) =>{
    e.preventDefault()
    ScholarshipService.delete(selectedScholarship.id).then((res) => {
      if (res.status == 204) {
        setScholarshipList(scholarshipList.filter((i) => i.id !== selectedScholarship.id)) 
      }
    })  
    toggleDeleteModal(e)
  }

  const routeForm = (e, id) =>{
    e.preventDefault()
    history.push('/new/scholarship'+(id?`/${id}`:''))
  }
  
  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <a href="#" onClick={e=>addDelete(e, item)}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <a href="#" onClick={e=>routeForm(e, item.id)}>
          <i className="fa fa-edit"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <a href="/" onClick={e=>updateStatus(e, item)}>
          <i className={item.is_active ? "far fa-check-circle": "far fa-circle"}></i>
          <span className="font-13">Pub Active</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ item, fn }) => (
    <i onClick={e=>addMenu(e, item)} className="fas fa-bars font-16 m-r-15" />     
  )

  const scholarshipCard =  scholarshipList  && scholarshipList.map((item, i) => (
    <div className={cardGridCss} key={i}>
      <Scholarship scholarship={item} menu={Menu} dataSource={dataSource}
        currentUser={currentUser} toggleModal={toggleDeleteModal} 
        cardHeightCss={cardHeightCss} formFieldsList={formFieldsList}/>
        
        {selectedScholarship.id == item.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={selectedScholarship} addDelete={addDelete} type="scholarship" 
          MenuItems={menuItems} menuHeight="250px"/>
        }
    </div>
  ))
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                Scholarship List
              </div>
            </div>

            <div className="flex aligncenter">
              <a href="#" className="bg-highlight round-btn float-right m-r-5 m-t-4" data-tip="Add a new Daily Cue" onClick={e=>routeForm(e)}>  
                <i className="fas fa-plus"/>
              </a>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              {scholarshipCard}

              <Pagination totalItems={pagemeta.total} currentpage={currentpage} 
                setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
            </div>
          </div>
        </div>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteScholarship}/>  
    </Fragment>
  )

}

export default ScholarshipList;