import React, {useEffect, useState, Fragment} from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from "react-tooltip";
import UserRoleService from "../../Journey/UserRoles/UserRoleService";
//import UserRoleService from "../../ShareItems/UserRoleService";

let requestParams = {}
const ShareItemModal = ({isOpen, toggleModal, itemId, itemType, itemPrivacyType, journeyCategoryId, journeyProfileId, updateItem, itemFormId}) => {
  
  const [privacy, setPrivacy] = useState();
  let [share, setShare] = useState({});
  const [shareEmailList, setShareEmailList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState([]);
  let [selectedFormFields, setSelectedFormFields] = useState([]);

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 100,
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id': journeyCategoryId,
      'roleable_id': itemId,
      'roleable_type': itemType,
      'filter_type': 'email',
      'context': 'share_item'
    }

    if(itemFormId){
      requestParams.form_field_params = {
        'ids':[itemFormId]
      }
    }
    getShareList();
  }, []);

  /*let setPrivacyType = (privacy) =>{
    updateItem(itemId, privacy, onItemUpdateSuccess);
  }

  let onItemUpdateSuccess = (privacy) =>{
    setPrivacy(privacy)
  }*/

  let getShareList = () => { 
    setLoading(true)
    UserRoleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setShareEmailList(res.data.user_roles) 
        setFormFields(itemFormId?res.data.form_fields[itemFormId]:[])
        setLoading(false)
      }
    })
  }

  const addShareItem = () => {
    if(share.id){
      UserRoleService.update(share).then((res) => {
        if(res.status==204){
          setShare({})
        }
      })  
    }else{
      share.journey_category_id = journeyCategoryId;
      share.journey_profile_id = journeyProfileId;
      share.shareable_item_id = itemId;
      share.shareable_item_type = itemType;
      share.filter_type = 'email';
      share.privacy_type = privacy;
      share.form_template_id = itemFormId;
      share.shared_form_fields = selectedFormFields;

      UserRoleService.create(share).then((res) => {
        if(res.status==201){
          setShareEmailList([res.data, ...shareEmailList])
          setShare({})
        }
      })  
    }
  }

  const deleteShareItem = (item) => {
    setLoading(true)
    UserRoleService.delete(item.id).then((res) => {
      if(res.status==204){
        setShareEmailList(shareEmailList.filter(i => i.id !== item.id))
        setLoading(false)
      }
    })    
  }

  const toggleFormFields = (field) => {
    const idx = selectedFormFields.findIndex(o => o.client_id === field.client_id);
    if(idx > -1) {
      selectedFormFields.splice(idx, 1);
    }else{
      selectedFormFields.push(field);
    }
    setSelectedFormFields([...selectedFormFields]) 
  } 

  const existFormFields = (client_id) => {
    return selectedFormFields.findIndex(o => o.client_id === client_id) > -1;
  }

  const EmailList = ({}) => shareEmailList.map((d, i) => (
    <span className='pill pill-xs m-l-5 white' key={i}>{d.email}
      <span className="m-l-5" onClick={e=>deleteShareItem(d)}>x</span>
    </span>
  ))

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Share Item</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
    
          {loading ? <div className="spinner"/> :
            <form className="ml-container">
              <div className="ml-section">
                <label className="font-15 bold-600" data-tip="Notification for new chat message">
                  Select applicable option
                </label>
                
                <span className="radio-container m-t-15" onClick={e => setPrivacy('invited')}>
                  <span className="font-16">Share with others by entering their email address</span>
                  <input type="radio" checked={privacy == 'invited'} readOnly/>
                  <span className="radio-checkmark"></span>
                </span>

                {privacy === 'invited' &&
                  <Fragment>
                    <input className="form-control ml-margin-bottom" type="text" 
                    placeholder="Enter email"
                    defaultValue={share.email}
                    onChange={e=>setShare({email: e.target.value})}/>

                    <EmailList/>
                  </Fragment>
                }
                  
                <span className="radio-container m-t-15" onClick={e => setPrivacy('public')}>
                  <span className="font-16">Share with cueTree for possible use by all users</span>
                  <input type="radio" checked={privacy == 'public'} readOnly/>
                  <span className="radio-checkmark"></span>
                </span>
                
                <label className="font-15 bold-600 m-b-5" data-tip="Notification for new chat message">
                  Select fields to share
                </label>
                <div className="m-l-5">
                  {formFields.map((f, i)=>
                    <div onClick={()=> toggleFormFields(f)} key={i}
                      className="flex aligncenter gap-15 m-b-10">
                      <span className={existFormFields(f.client_id)?'far fa-check-square font-24':'far fa-square font-24'}/> 
                      <div className="font-16">
                        <div>{f.label}</div>
                        <div>{f.description}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="ml-button ml-block ml-section bg-highlight white" onClick={()=>addShareItem()}>
                <b>Add</b>
              </div>
            </form>
          }
          <ReactTooltip place="bottom" />
        </div>
      </div>
    </Fragment>, 
  document.body))
}

export default ShareItemModal;