import React, { useState, useEffect, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import useEscape from '../../hooks/useEscape'

const MultiSelectModal = ({ isOpen, toggleModal, title, options, onUpdate, selectedList}) => {
  useStyle('add_role_dialog')
  useEscape(toggleModal)
  
  const [list, setList] = useState(selectedList || [])
  const [loading, setLoading] = useState(false)

  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
    let idx = list.indexOf(item)
    let tempList = []
    if (idx > -1) {
      tempList = list.filter(i => i !== item)
    }else {
      tempList = [...list, item]
    }
    setList([...tempList])
    onUpdate(tempList)
  }

  const exists = (item) => {
    return list.indexOf(item) > -1
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">{title}</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
    
          <div className="ml-container">
            <div className="clearfix m-t-5">
              { loading ? <div className="spinner"></div> : options && options.map((c, key) =>(
              <div key={key}>
                <span className="checkbox-container" onClick={e=>toggle(c.sid)}>
                  <span>{c.label}</span>
                  <input type="checkbox" checked={exists(c.sid)} readOnly/>
                  <span className="checkbox-checkmark"></span>
                </span>
              </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </Fragment>, document.body)
  )
}

export default MultiSelectModal;