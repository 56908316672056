import React, {useContext, useEffect, Fragment} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import {Link} from "react-router-dom"

export const Profile = (props) => {
  useStyle('signup')
  
  const { state: authState, dispatch } = useContext(AuthContext);

  useEffect(() => {
    if(authState.user.domain_info.data !== null){
      props.history.push("/home")
    }
  }, [authState.isAuthenticated])

  return (
    <Fragment>
      <div className="page-content">
        <div className="page-title-bg opacity-90 bg-highlight ht-150" ></div>
        
        <div className="page-title-small color-white bottom-30">
          <h1>
            Welcome!
          </h1>
        </div>

        <div className="content-boxed shadow-small">
            <div className="content bottom-0">
                <h4>Let's get started</h4>
                <p className="bottom-20">
                  Follow the steps below in the sequence .You can come back to this page anytime.
                </p>
            </div>
        </div>
        
        <div className="content">
            <div className="one-half">
                <Link to="/profile" className="caption round-medium shadow-large bg-theme bottom-15 content-one-half">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i></i>
                        </h1>
                        <h4 className="center-text color-theme">Step 1</h4>
                        <h4 className="center-text color-theme">Profile</h4>
                        <p className="under-heading color-highlight center-text font-11 color-highlight">
                            Set up your profile
                        </p>
                    </div>
                    <div className="caption-bottom">
                        <p className="bottom-10 opacity-40 font-10 center-text">Tap to View</p>
                    </div>
                </Link>       
            </div>
            <div className="one-half">
                <Link to="/profile"  className="caption round-medium shadow-large bg-theme bottom-15 content-one-half">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i>
                            </i>
                        </h1>
                        <h4 className="center-text color-theme">Step 2</h4>
                        <h4 className="center-text color-theme">Settings</h4>
                        <p className="under-heading color-highlight center-text font-11 color-highlight">
                            Customize settings
                        </p>
                    </div>
                    <div className="caption-bottom">
                        <p className="bottom-10 opacity-40 font-10 center-text">Tap to View</p>
                    </div>
                </Link>       
            </div>
            <div className="one-half">
                <Link to="/profile"  className="caption round-medium shadow-large bg-theme bottom-15 content-one-half">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i></i>
                        </h1>
                        <h4 className="center-text color-theme">Step 3</h4>
                        <h4 className="center-text color-theme">Journey</h4>

                        <p className="under-heading color-highlight center-text font-11 color-highlight">
                            Launch a journey
                        </p>
                    </div>
                    <div className="caption-bottom">
                        <p className="bottom-10 opacity-40 font-10 center-text">Click here </p>
                    </div>
                </Link>       
            </div>
            <div className="clear"></div>     
        </div>

      </div>
    </Fragment>
  )
}

export default Profile;