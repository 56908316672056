import React, {useState, Fragment, useContext, useEffect} from "react"
import Pagination from '../Pagination'
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommonService from "../../services/CommonService"

let requestParams = {}

export const DataSourceListFilter = (props) => {
  useStyle('filter_dialog')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [dataSourceList, setDataSourceList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  let params = props.match.params

  useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
    }

    getDataSourceList()
  }, [props.searchString, currentpage])

  let getDataSourceList = () => { 
    let req = {'data_source_id':params.data_source_id, 'per_page' : 30}
    CommonService.getDataSourceList(req).then((res)=>{
     if(res){
       requestParams.totalItems = res.data.meta.total 
       setDataSourceList(res.data.data_source_lists)
      }
    })
  }

  let updateStatus = (o) =>{
    let req = {
      data_source_list_id:o.id, 
      data_source_id:o.data_source_id, 
      created_by:currentUser.id
    }
    CommonService.updateDataSourceListStatus(req).then((res)=>{
     if(res.status == 200){
       if(o.inactive_status){
        o.inactive_status=null
       }else{
        o.inactive_status={}
       }
       setDataSourceList([...dataSourceList])
      }
    }) 
  }

  const DataSourceList = dataSourceList.map((o, k) => 
    <Fragment key={k}>
        <span className="checkbox-container" key={k} onClick={e => updateStatus(o)}>
        <span>{o.data.label}</span>
        <input type="checkbox" checked={o.inactive_status==null} readOnly/>
        <span className="checkbox-checkmark"></span>
      </span>
    </Fragment>
  )

  return (
    <Fragment>
      <div className="top-10 col-xs-12">
        <h5 className="float-left font-500">Data Source</h5>
        <div className="clear"></div>
      </div>

      <div className="col-xs-12">
        {DataSourceList}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
        setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
    </Fragment>
  )
}

export default DataSourceListFilter;