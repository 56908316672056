import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import ReactTooltip from "react-tooltip";
import CheckAccess from "../../Roles/CheckAccess";
import Cs from "../../../../services/CommonService";

const PortalPath = (props) => {
  useStyle("path");

  let {journeyCategory, invitedUser, isHelpMessageOpen} = props
  let report = (props.statics && props.statics.report) || {}
  let {access_list, access_type} = props.share
  let groups = Cs.groupBy(props.portalPath.options, 'group')
  
  let disableIconStyle = {}
  let disableClickEvent = {}
  if(props.currentUser.current_admin.admin_role_name == 'demo_user'){
    disableIconStyle.color = 'grey'
    disableClickEvent.pointerEvents = 'none'
  }

  props.portalPath.options.forEach((node)=>{
    node.has_access = CheckAccess.hasPathAccess(journeyCategory, access_list, node.sid)
  })

  useEffect(() => {
    ReactTooltip.rebuild()
  });

   useEffect(() => {
    try{
      setTimeout(() => {
        for (const g in groups) {
          if(document.getElementById(`path_icons_${g}`).childElementCount == 0)
            document.getElementById(g).style.display = "none";
            //.remove()
        }
      }, 0);
    }catch(e){
      console.log(e)
    }
  })

  const Icon = ({icon, icon_color, path}) =>{
    if(path.has_access || journeyCategory.is_admin_user){
      return(
        <div onClick={e=>props.routePath(e, path)}
          className="flex aligncenter justspacebetween border-dashed-sm p-b-15 m-t-15 p-l-15 p-r-15">
          <div className="flex">
            <i className={'fa '+icon + ' m-r-10 f22'} data-tip={path.describe}/>
            <h5 className="f16 text-cap">{path.label}</h5>
          </div>
          <span className="f16">{report[path.sid] || 0}</span>
        </div>
      )  
    }else{
      return null
    }
  }

  const Icons = ({icons}) => icons && icons.map((p, i) => (
    <Icon icon={p.icon} icon_color={p.icon_color} path={p}  key={i}/>
  ))

  const Groups = ({}) => Object.keys(groups).map((g, i) => 
    <div className="col-md-4 m-t-30" key={i} id={g}>
      <div className="bg-white border-rounded-10 p20 card-min-ht">
        <div className="card-title flex justspacebetween border-dashed-sm p-b-15">
          <h4 className="fw700 f15 card-heading card-heading flex aligncenter text-cap">
            {g}
          </h4>
        </div>
        <div id={`path_icons_${g}`} className="card-content m-t-15">
          <Icons icons={groups[g]}/>          
        </div>           
      </div>  
    </div>
  )

  if(groups){
    return (
      <div className="row">
        <Groups/>
      </div>
    )
  }else{
    return null
  }

}

export default PortalPath;