import React, { useState, Fragment, useEffect } from 'react'
import DataGroupService from './DataGroupService'

let requestParams = null

const FilterDataGroup = ({ item, isOpen, list, setList, groupType, toggleModal, filterByGroup }) => {
  const [checkedItems, setCheckedItems] = useState({})
  const [group, setGroup] = useState({'group_type': groupType})
  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    requestParams = {
      'page':1,
      'per_page':100,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'group_type':groupType
    }

    getDataGroup()
  }, [])

  const getDataGroup = () =>{
    DataGroupService.getAll(requestParams).then((res) => {
      if(res.status == 200)setGroupList(res.data.data_groups)
    })
  }

  const filter = (g,event) =>{    
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked
    });  

    if(event.target.checked == true){
      setGroup(g)
      filterByGroup(g.id)
    }else{
      g = {}
      setGroup({'group_type': g})
      filterByGroup(g.id)
    }
  
  }

  const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {  
    return (
      <input className="lgrey m-r-5" type={type} name={name} checked={checked} onChange={onChange} />
    )
  }

  const GroupList = () => groupList && groupList.map((g, k) =>{
    return(
      <Fragment>
        <a className="ver-icons p-5"  >
        <Checkbox
            name={g.title}
            checked={checkedItems[g.title]}
            onChange={e=>filter(g, e)}
          />
          <div className="popup-menu-title text-cap m-t-5">{g.title}</div>
        </a>
      </Fragment>
    )
  })

  return(
    <Fragment>
      <form className="p-5 scroll-x">
        <div className="bg-white">
          <h5 className="font-16 text-center bg-highlight white">
            Group Names
          </h5>
          <div>
            <GroupList/>
          </div> 
        </div>
      </form>
    </Fragment>
  )

}

export default FilterDataGroup;