import React, { Fragment, useEffect, useState, useContext } from "react"
import useStyle from "../../../hooks/useStyle"
import { Link } from "react-router-dom"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService"
import HamBar from "./../../../components/Authentication/HamBar"
import Logo from "./../../../components/Authentication/Logo"
import env from "../../../env"
import LottieLight from "../../Common/LottieLight"
import Footer from "./../../Footer/Footer"
import NewsletterSnippet from "../../Authentication/NewsletterSnippet"
import SocialShareBtn from "../../../components/Common/SocialShareBtn"
import SideMenuBar from "../../../components/Common/SideMenuBar"
import FAQList from "../../MarketingSnippets/FAQList"
import MarketingList from "../../MarketingSnippets/MarketingList"
import querystringify from "querystringify"
import TestimonialList from "../../MarketingSnippets/TestimonialList"
import HeaderSnippet from "./HeaderSnippet"
import {Button} from "../Common"

let requestParams = {}
let pageTitle = null

const TeamMarketing = (props) => {
  useStyle("spmarketing")
  useStyle("daily_dabble")

  let queryParam = querystringify.parse(props.location.search)
  const {
    state: currentUser,
    dispatch,
    isAuthenticated: isAuthenticated,
  } = useContext(AuthContext);
  const [digitalContentList, setDigitalContentList] = useState([]);

  window.addEventListener("scroll", () => {
    console.log(document.documentElement.scrollTop)
  })

  useEffect(() => {
    pageTitle = null
    requestParams = {
      content_type: "marketing",
      page: 1,
      paginate: 1,
      per_page: 100,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: null,
      is_active: true,
      data_filter: [{ key: "web_page", operator: "=", value: 40 }],
      data_source_params: {
        data_source_id: [3368],
      },
    }

    getMarketingContent()
  }, [currentUser.id])

  let getMarketingContent = () => {
    DigitalContentService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total
        pageTitle =  res.data.data_source_list.web_pages.options[queryParam.i]?.page_heading;
        setDigitalContentList(res.data.digital_contents)
      }
    })
  }

  let filteredTeamJourney = []

  for (var i = 0; i < digitalContentList.length; i++) {
    if (digitalContentList[i].data.web_page == 40) {
      filteredTeamJourney.push(digitalContentList[i])
    }
  }

  const ImageSvgRender = ({ ma }) => {
    if (ma.data.lottie) {
      return <LottieLight id={ma.id} {...ma.data.lottie} isPublic="true" />
    } else {
      return <img src={ma.data.illustration} />
    }
  }

  const SocialShare = ({ ma }) => {
    return (
      <div className="center-text">
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fa fa-share-alt" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
          <i className="fab fa-facebook-f" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
          <i className="fab fa-twitter" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
          <i className="fab fa-instagram" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
          <i className="fab fa-linkedin-in" />
        </a>
        <a href="#"
          className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
          <i className="fab fa-whatsapp" />
        </a>
      </div>
    )
  }

  const SectionWithButton = ({ ma }) => {
    let color = {color: ma.data.text_color}
    return (
      <div className="row m-b-30 p-20 section" style={{backgroundColor: ma.data.color}}>
        {i % 2 == 0 && (
          <div className="intro-img col-xs-12 col-sm-4 wk-center p-20-0">
            <ImageSvgRender ma={ma} />
          </div>
        )}
        <div className="intro-content col-xs-12 col-sm-8 text-left p-20 ">
          <h3 className="bold-600 font-24" style={color}>
            {ma.data.title}
          </h3>
          <p className="rich-text lg-p mt-2"
            dangerouslySetInnerHTML={{ __html: ma.data.notes}}
            style={color}></p>
          <Button item={ma} eleType='button'/>
        </div>
        {i % 2 == 1 && (
          <div className="col-xs-12 col-sm-4 wk-center">
            <ImageSvgRender ma={ma} />
          </div>
        )}
      </div>
    )
  }

  const SectionOnly = ({ ma, i }) => {
    let color = {color: ma.data.text_color}
    return (
      <div className="row m-b-30 p-20 section" style={{backgroundColor: ma.data.color}}>
        {i % 2 == 0 && (
          <div className="col-xs-12 col-sm-4 wk-center">
            <ImageSvgRender ma={ma} />
          </div>
        )}
        <div className="col-xs-12 col-sm-8 text-left p-20">
          <h3 className="bold-600 font-24" style={color}>
            {ma.data.title}
          </h3>
          <p className="rich-text lg-p mt-2"
            dangerouslySetInnerHTML={{ __html: ma.data.notes }}
            style={color}></p>
        </div>
        {i % 2 == 1 && (
          <div className="col-xs-12 col-sm-4 wk-center">
            <ImageSvgRender ma={ma} />
          </div>
        )}
      </div>
    )
  }

  const ThreeSection = ({ ma }) => {
    let color = {color: ma.data.text_color}

    return (
      <div className="m-b-30 p-50 section" style={{backgroundColor: ma.data.color}}>
        <div className="row">
          <div className="col-xs-12 col-sm-6 intro-img">
            <ImageSvgRender ma={ma} />
          </div>
          <div className="col-xs-12 col-sm-6 m-t-10 text-left intro-content">
            <h3 className="bold-600 font-24" style={color}>
              {ma.data.heading}
            </h3>
            <p className="rich-text lg-p mt-2"
              dangerouslySetInnerHTML={{ __html: ma.data.notes}}
              style={color}
            ></p>
          </div>
        </div>
        <div className="row m-b-30">
          <div className="col-xs-12 col-sm-4">
            <div className="testimonial-item-text">
              <img className="img-responsive m-t-15 m-b-15 ht-200" src={ma.data.image_box_1}/>
              <h3 style={color}> {ma.data.heading_1}</h3>
              <p style={color}>{ma.data.box_1_note}</p>
            </div>
            <div className="testimonial-item-reviewer"></div>
          </div>

          <div className="col-xs-12 col-sm-4">
            <div className="testimonial-item-text">
              <img  className="img-responsive m-t-15 m-b-15 ht-200" src={ma.data.image_box_2}/>
              <h3 style={color}> {ma.data.heading_2}</h3>
              <p style={color}>{ma.data.box_2_note}</p>
            </div>
            <div className="testimonial-item-reviewer"></div>
          </div>

          <div className="col-xs-12 col-sm-4">
            <div className="testimonial-item-text">
              <img className="img-responsive m-t-15 m-b-15 ht-200" src={ma.data.image_box_3}/>
              <h3 style={color}> {ma.data.heading_3}</h3>
              <p style={color}>{ma.data.box_3_note}</p>
            </div>
            <div className="testimonial-item-reviewer"></div>
          </div>
        </div>
      </div>
    )
  }

  const filteredTeamJourneyCard = filteredTeamJourney.map((ma, i) => {
    if (ma.data.template == 1) {
      return <SectionWithButton ma={ma} i={i}/>
    } else if (ma.data.template == 2) {
      return <SectionOnly ma={ma} i={i}/>
    } else {
      return <ThreeSection ma={ma} i={i}/>
    }
  })

  return (
    <Fragment>
      <div className="page-content row m-b-30 m-t-10">
        <div className="header bg-highlight ht-30"></div>
        <div className="col-sm-12">
          <div className="p-20 ht-150">
            <HeaderSnippet />
          </div>
        </div>
        <main>
          <div className="col-xs-12">
            <div className="bg-black white p-7">
              <h1 className="text-left m-l-20 m-r-20 mobile-text-center"> {pageTitle}</h1>
            </div>
          </div>
      		<section className="intro">
      		  <div className="w-100p">
      		  	{filteredTeamJourneyCard}
      		  </div>
      		</section>
          {!currentUser.isAuthenticated && <NewsletterSnippet />}
        </main>
        <section className="row">
          <div className="col-xs-12"
            style={{
              width: "100%",
              display: "flex",
              whiteSpace: "nowrap",
              overflowX: "scroll",
            }}
          >
            <MarketingList web_page="40" screen="col-md-3" />
          </div>
        </section>
        <section className="row">
          <FAQList web_page="40" />
        </section>
        <footer></footer>
      </div>
      <SideMenuBar helpId="2" />
      <Footer />
    </Fragment>
  )
}

export default TeamMarketing;