import React, { useState, useContext, useEffect, Fragment } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Forum from "../../../components/Forums/List/Forum"
import ForumService from "../../../components/Forums/ForumService"
import Pagination from "../../Pagination"
import { Link } from "react-router-dom"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import FormTemplateId from "../../../constants/FormTemplateList"
import useStyle from "../../../hooks/useStyle"
import {NavIcon} from '../../Common/MenuItem'
import ReactTooltip from 'react-tooltip'

let requestParams = {}

const ForumList = (props) => {

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [forumList, setForumList] = useState([])
  const [isFavourite, setIsfavourite] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'per_page':15,
      'page':1,
      'sort_column' : 'updated_at',
      'sort_direction' : 'desc',
      'is_favourite':isFavourite,
      'keywords': props.searchString
    }
    getForumsList()
  }, [props.searchString, isFavourite])

  let getForumsList = () => {
    ForumService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setForumList(res.data.discussion_requests);
      }
    })
  }

  const pinFavourite = () =>{
    setIsfavourite(!isFavourite)
  }

  const deleteForumPost = (e, forum) => {
    e.preventDefault()
    ForumService.delete(forum.id).then((res) => {
      if(res.status == 204) {
        setForumList(forumList.filter(r => r.record_id !== forum.record_id))
      }
    })
  }

  const Delete = ({ forum }) => (
    <a href="#" onClick={(e) => deleteForumPost(e, forum)} className="m-l-25">
      <i className="fa fa-trash lgrey" data-tip="Delete" aria-hidden="true"></i>
    </a>
  )

  const Edit = ({ forum }) => (
    <Link to={`/forums/create/${forum.id}`} className="m-l-25">
      <i className="far fa-edit lgrey" data-tip="Edit" aria-hidden="true"></i>
    </Link>
  )

  const Reply = ({ forum }) => (
    <Link to={`/forums/reply/${forum.id}`} className="m-l-25">
      <i className="fa fa-reply lgrey" data-tip="Reply" aria-hidden="true"></i>
    </Link>
  )

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                Training Forum
              </div>
            </div>

            <div className="flex aligncenter">
              <Link to="/forums/create"
                data-tip="Add Forum"
                className="bg-highlight round-btn float-right m-r-5 m-t-4">
                <i className="fa fa-plus"></i>
              </Link>

              <NavIcon id="pin" dataTip="Pinned Forums" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={pinFavourite} icon={"fas fa-thumbtack " + (isFavourite ? 'black' : 'white')}/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              {forumList && forumList.map((form, i) => (
                <div className="col-xs-12" key={i}>
                  <Forum forum={form} reply={Reply} edit={Edit} delete={Delete}/>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ForumList;