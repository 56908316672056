import React, {useState} from 'react'
import Cs from '../../services/CommonService'

let ImageList = ({list, styleName, showFileName}) => list && list.length>0 ? list.map((n, i) =>
  <>
   <img src={Cs.getIconByType(n)} className={styleName} key={i}/>
   {showFileName &&<span>{Cs.getFileName(n)}</span>}
  </>
): null;

let Image = ({image, styleName, type}) => {
  if(!image)return null;
  
  let url = Cs.getIconByType(image, type)	
   
   return(					   
   	 <img className={styleName} src={url}/>
   )	 
}

let FileListLink = ({list, styleName, iconClass, showFileName}) => list && list.length>0 ? list.map((n, i) =>
  <a className={styleName} href={Cs.getFileUrl(n)} target="_blank" key={i}>
    <img src={Cs.getIconByType(n)} className={iconClass}/>
    {showFileName && <span className="m-l-5 font-13" data-tip={Cs.getFileName(n)}>{Cs.getFileName(n).substring(0,20)}</span>}
  </a>
): null;

let VedioLink = ({url, styleName, iconClass, showFileName}) => url?(
   <a className={styleName} href={Cs.getFileUrl(url)} target="_blank">
      <i className={iconClass}/>
      {showFileName && <span className="m-l-5 font-13" data-tip={url}>{Cs.extractHostName(url)}</span>}
   </a>
):null;

let ImageFlexList = ({list, styleName, preUrl}) => {
  if(!(list && list.length>0))return null;

  return( 
    <div className="d-flex">
      {list.map((i, k)=>
        <img key={k} src={preUrl?(preUrl + i):Cs.getIconByType(i)} className={styleName}/>  
      )}
    </div>
  )
}

export {ImageList, Image, FileListLink, VedioLink, ImageFlexList};
