import React, {Fragment, useState, useEffect, useContext, useRef} from "react";
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import InvitedUser from '../../Journey/InvitedUsers/InvitedUser'
import InvitedUserService from "../../Journey/InvitedUsers/InvitedUserService";
import ConfirmMenu from '../../Modals/ConfirmMenu'
import InvitedUserModal from '../../Journey/InvitedUsers/InvitedUserModal'
import useModal from '../../../hooks/useModal';
import useStyle from '../../../hooks/useStyle';
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import JourneyTitle from "../../Journey/JourneyTitle/JourneyTitle";
import InvitedUsersTable from "./InvitedUsersTable"
import {NavIcon} from '../../Common/MenuItem'
import querystringify from "querystringify"
import CommonService from "../../../services/CommonService";
import Pagination from '../../Pagination'
import SideMenuBar from "../../../components/Common/SideMenuBar";
import {SwiperContainer, SwiperSlide} from "../../../components/Common/Swiper"
import {Portal} from '../../../constants/DataSourceList';

let requestParams = {};
let familyMemberParams = {};
let newInviteUser = {};
let dataSources = {};
let familyMembers = [];
let roles = [];
let rolesObj ={};
let selectedInviteUser = null
let componentList = null
let cardViewCount = 1;
let journey = null;
let share = null;

const InvitedUserList = (props) => {
  useStyle('user_lists');
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let state = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [invitedUserList, setInvitedUserList] = useState([]);
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [currentpage,setCurrentpage] = useState(1); 
  const [showTableView, setShowTableView] = useState(false);
  const indexEle = useRef(null);

  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal();
  const {isOpen:isInviteUserRolePopupOpen, toggleModal:toggleInviteUserPopup} = useModal();

  let dataSources = {
    'notificationsList':{
      'new_journal_entry':'New Tile',
      'new_activity':'New Activity Entry',
      'new_comment':'New Comment'
    },
    'permissions':{
      'full_access':'Full Access',
      'add_activity':'Create Tile',
    }
  }

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'totalItems':0,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id':params.journey_category_id,
      'family_id':currentUser.domain_info.id
    }

    familyMemberParams = {
      'page': 1, 
      'per_page' : 100,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id':params.journey_category_id,
      'family_id':currentUser.domain_info.id,
      'data_source_params':{
        "data_source_id":[3244, 3245, 3384, Portal[state.journey.edu_journey_type]],
        "response_type":"object"
      },
      'user_role_params': {
        "journey_category_id":params.journey_category_id
      }
    }

    getFamilyMembersList();
    getInvitedUserList();
  }, [props.searchString]);

  let getInvitedUserList = () => { 
    InvitedUserService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
        share = res.data.invited_journey_member
        journey = res.data.journey_category
        requestParams.totalItems = res.data.meta.total;
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(res.data.meta.total / cardViewCount); 
        setInvitedUserList(res.data.invited_journey_members)
      }
    })
  }

  let getFamilyMembersList = () => { 
    InvitedUserService.getFamilyMembers(familyMemberParams).then((res)=>{
     if(res.status == 200){
       familyMembers = res.data.family_members;
       dataSources = res.data.data_source_list;
       //componentList = CommonService.findById(dataSources, queryParam.permission_list_id)
       roles = res.data.roles;
       rolesObj = roles.reduce((obj, item) => (obj[item.record_id] = item, obj) ,{});
       setDataLoaded(true)
      }
    })
  }

  const deleteUser = (e) => {
    e.preventDefault()
    InvitedUserService.delete(selectedInviteUser.id).then((res) => {
      if(res.status==204){
        setInvitedUserList(invitedUserList.filter(r => r.id !== selectedInviteUser.id))
      }
    })  
    toggleDeletePopup(e)
  }

  const setEdit = (e, invite_user) => {
    e.stopPropagation()
    newInviteUser = invite_user
    toggleInviteUserPopup(e)
  }
  
  const addInviteUser = (e, new_invite_user) => {
    e.preventDefault()
    if(new_invite_user.id){
      InvitedUserService.update(new_invite_user).then((res) => {
        if(res.status==204){
          setInvitedUserList([new_invite_user].concat(invitedUserList.filter(r => r.id !== new_invite_user.id)))
          restModal(e)
        }
      })  
    }else{
      new_invite_user.created_by = currentUser.id
      new_invite_user.journey_category_id = params.journey_category_id
      new_invite_user.journey_proile_id = params.journey_proile_id
      new_invite_user.family_id = currentUser.domain_info.id
      new_invite_user.organization_id = currentUser.current_organization_id

      InvitedUserService.create(new_invite_user).then((res) => {
        if(res.status==201){
          res.data.invited_journey_members.forEach((d, i)=>{
            d.author = {'id': currentUser.id, 'name':currentUser.name}
          })
          //new_invite_user.id = res.data["invited_journey_members"].id
          setInvitedUserList(res.data.invited_journey_members.concat(invitedUserList))
          restModal(e)
        }
      })  
    }
  }

  const restModal = (e) =>{
    e.stopPropagation()
    newInviteUser = {}
    toggleInviteUserPopup(e)
  }

  const selectInviteUser = (e, invited_user) =>{
    selectedInviteUser=invited_user
    toggleDeletePopup(e, invited_user)
  }

  const Menu = ({invited_user}) => (
    <div className="dropup pull-right m-r-15">
      <button className="dropbtn">Menu</button>      
      <div className="dropup-content">
        <a href="#" onClick={e => {setEdit(e, invited_user)}}>Edit</a>
        <a href="#" onClick={e => {selectInviteUser(e, invited_user)} }>Delete</a>
      </div>
    </div>
  )

  const invitedUserCard = invitedUserList && invitedUserList.map((invited_user, i) => (
    // <SwiperSlide key={i}>
      <div className="col-xs-12 col-sm-6 col-md-4">
      <InvitedUser invited_user={invited_user} menu={Menu} setEdit={setEdit} 
        selectInviteUser ={selectInviteUser} rolesObj={rolesObj} currentUser={currentUser}
        journey={state.journey} share={share}/>
      </div>
    // </SwiperSlide>
  ))

  /*if(invitedUserList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/
    
  return (
    <Fragment>

      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <JourneyTitle title={"Invited Users"} project={state.journey} />
            </div>

            <div className="flex aligncenter">
              {state.journey.is_admin_user && 
                <NavIcon id="add_role" dataTip="Add New Role" 
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  onSelect={restModal} icon="fas fa-plus"/>
              }
                    
              <NavIcon id="toggle_role" dataTip="Toggle Table" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={e=>setShowTableView(!showTableView)} icon="fas fa-table"/>

              <NavIcon id="back_home" dataTip="Back to Journey Home" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={e=>{props.history.goBack();e.preventDefault();}} icon="fas fa-home"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              {!showTableView && invitedUserCard}
              {showTableView && 
                <InvitedUsersTable invitedUserList={invitedUserList} getInvitedUserList={getInvitedUserList} 
                requestParams={requestParams} setEdit={setEdit} selectInviteUser={selectInviteUser} 
                rolesObj={rolesObj} currentUser={currentUser} journey={state.journey}/>
              }
            </div>
          </div>
        </div>
      </div>
      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteUser}/>
      {isInviteUserRolePopupOpen && isDataLoaded &&
        <InvitedUserModal inviteUser={newInviteUser} isOpen={isInviteUserRolePopupOpen} toggleModal={toggleInviteUserPopup} 
        addInviteUser={addInviteUser} roles={roles} journey={state.journey}/>
      }
      <SideMenuBar helpId="15"/>  
    </Fragment>
  )

};

export default InvitedUserList;