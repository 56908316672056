import React, { useState, useEffect, Fragment, useContext, useRef} from "react"
import { Link } from "react-router-dom"
import querystringify from "querystringify"
import useStyle from "../../../../hooks/useStyle"
import CommentsModal from "../../../Modals/CommentsModal"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../PreLoadSpinner"
import {NavIcon} from '../../../Common/MenuItem'
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle"
import ReactTooltip from 'react-tooltip'
import useModal from "../../../../hooks/useModal"
import useEscape from "../../../../hooks/useEscape"
import TrainingTopicService from "./TrainingTopicService"
import Cs from "../../../../services/CommonService"
import TrainingModuleService from "../TrainingModules/TrainingModuleService"
import TrainingContentService from "../TrainingContents/TrainingContentService"
import ProjectMenu from "../../../Modals/ProjectMenu"
import NotesModal from "../../../Modals/NotesModal"
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import SideMenuBar from "../../../../components/Common/SideMenuBar"
import GenericModal from "../../../Modals/GenericModal"
import VimeoPlayer from "../../../Common/VimeoPlayer"
import VideoBookmark from "../../../Common/VideoBookmark"
import { PDFObject } from 'react-pdfobject'
import Tabs, { TabPane } from 'rc-tabs'
import NotificationList from "../../JourneyNotification/JourneyNotificationList"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

let requestParams = {}
let selectedData = {}

const Topic = (props) => {
  useStyle('course_details')
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  let journey_category = props.location.state

  const contentNavEl = useRef(null);
  const [viewType, setViewType] = useState()
  const [showSideNav, setShowSideNav] = useState(screen.xs?true:true)
  const [subtopics, setSubTopics] = useState()
  const [subtopic, setSubTopic] = useState()
  const [topics, setTopics] = useState()
  const [topic, setTopic] = useState()
  const [module, setModule] = useState({})
  const [modules, setModules] = useState([])
  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isHelpMessageOpen, setIsHelpMessageOpen ] = useState(false);
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState()
  const [courseDetails, setCourseDetails] = useState([]);
  const [courseTrainerId, setCourseTrainerId] = useState(params.course_id)
  
  /*const [trainingManager, setTrainingManager] = useState({})*/
  /*const [topicId, setActiveTopicId] = useState();
  const [activeSubTopicId, setActiveSubTopicId] = useState()*/

  const {isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  const {isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();
  const {isOpen:isNoteOpen, toggleModal:toggleNoteModal } = useModal();
  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal,} = useModal();
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isAttachmentOpen, toggleModal:toggleAttachmentModal } = useModal()
  const { isOpen:isCourseDetailsOpen, toggleModal:toggleCourseDetailsModal } = useModal()
  const { isOpen:isBookMarkOpen, toggleModal:toggleBookMarkModal } = useModal()
  const [file, setFile] = useState();
  const [tabKey, setTabKey] = useState('1');

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'paginate':1,
      'per_page':100,
      'sort_column':'mf.sort_order',
      'sort_direction':'asc',
      'totalItems':0,
      'search':props.searchString,
      'exists_in_planner':true,
      'topic_exists_in_planner':true,
      'add_content_count':true,
      'filter':'student_curriculum',
      'course_id':courseTrainerId,
    }

    getModules()

    document.addEventListener("click", handleClick, true)
    return () => {
      document.removeEventListener("click", handleClick, true)
    }

  }, [props.history.location.pathname, courseTrainerId])


  const handleClick = (e) => {
    if (contentNavEl.current && (!contentNavEl.current.contains(e.target)) && screen.xs){
      setShowSideNav(false)
    }
  }

  let getModules = () => {
    TrainingModuleService.getAll(requestParams).then((res) => {
      if(res.status == 200){
        setModules(res.data.modules)
        //setModule(res.data.modules[0])
        setViewType('blank')
        setCourseDetails(res.data.planner_course_details)
        openComment()
        //setTrainingManager(res.data.shared_item)
      }
    })
  }

  let getAllTopic = (topic_id) => { 
    let req = {
      'page':1,
      'per_page':100,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'add_planner':true,
      'id':topic_id
    }  
    setLoading(true)
    setTopics([])
    TrainingTopicService.getAll(req).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        setTopics(res.data.topics)
        //setSubTopics(res.data.topics[0].contents)
      }
    })
  }

  let getSubTopics = (topic_id) => {
    let req = {
      'filter':'student_curriculum',
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'topic_id':topic_id,
      'page':1,
      'per_page':100
    }
    //setActiveTopicId(topic_id)
    setSubTopics([])
    setLoading(true)
    TrainingContentService.getAll(req).then((res) => {
      setSubTopics(res.data.contents)
      setLoading(false)
      if(queryParam.commentable_type == 'carrier_training.content'){
        triggerClick('subtopic_'+queryParam.commentable_id) 
      } 
    })
    setSideNav()
  }

  const openComment = () =>{
    switch (queryParam.commentable_type) {
      case 'carrier_training.content':
        triggerClick('module_'+queryParam.module_id)
        triggerClick('topic_'+queryParam.topic_id) 
        break;
      case 'carrier_training.topic':
        triggerClick('module_'+queryParam.module_id) 
        triggerClick('topic_'+queryParam.topic_id) 
        break;
      case 'carrier_training.module':
        triggerClick('module_'+queryParam.module_id) 
        break;
    }
  }

  const triggerClick = (id) => {
    const ele = document.getElementById(id)
    ele && ele.click() 
  }

  const showMenu = (e, a) => {
    toggleMenuModal(e, true);
  }

  const confirmDelete = (e, item, type) => {
    item.type = type
    toggleDeleteModal(e)
  }

  const setSideNav = () =>{
    /*setShowSideNav(true)*/
  }

  const onCourseChange = (course) =>{
    setCourseTrainerId(course.id)
  }

  const showAttachment = (e, a) => {
    selectedData = a;
    toggleAttachmentModal(e, true)
  }

  const Attachments = ({upload}) => upload ? upload.map((d, i) => (
    <Fragment>
      <div className="col-xs-12 col-sm-4 col-md-3 m-t-5">
        <img src={Cs.getIconByType(d)} onClick={e=>setFile(d)} className="img-thumbnail ht-wt-50" width="100px"/>
        <a target="_blank" key={i} href={Cs.getFileUrl(d)}>
          <div className="m-l-5 blackdark">{Cs.getFileName(d)}</div>
        </a>
      </div>
    </Fragment>
  )):null

  const addComments = (content, type) => {
    selectedData = content
    selectedData.type = `carrier_training.${type}`
    selectedData.journey_category_id = params.journey_category_id
    toggleCommentModal()
  }

  const addChecklist = (content, type) => {
    selectedData = content
    selectedData.type = `carrier_training.${type}`
    selectedData.journey_category_id = params.journey_category_id
    selectedData.journey_profile_id = params.journey_profile_id
    toggleChecklistModal()
  }

  const showNoteModal = (content, type) => {
    selectedData = content
    selectedData.type = `carrier_training.${type}`
    selectedData.journey_category_id = params.journey_category_id
    selectedData.journey_profile_id = params.journey_profile_id
    toggleNoteModal()
  }

  const addBookMark =(content, type) =>{
    //setActiveIndex(i==activeIndex?null:i)
    selectedData = content
    selectedData.bookmark_type = `carrier_training.${type}`
    selectedData.journey_category_id = params.journey_category_id
    selectedData.journey_profile_id = params.journey_profile_id
    toggleBookMarkModal()
  }

  let callback = (key) => {
    if(key == 3){
      setTabKey('3')
    }
  }

  const setData = (type, data) => {
    if(type == 'module'){
      if(module.id == data.id){
        setModule({})
        type = 'blank' 
      }else{
        setModule(data)
      }
    }else if(type == 'topic'){
      setTopic(data)
    }else if(type == 'subtopic'){
      setSubTopic(data)
    }
    setViewType(type)
  }

  const AttachmentModal =({item}) =>{
    return(
      <Fragment>
        <div className="row font-14 p-7">
          <Attachments upload={item.data.upload}/>
        </div>
      </Fragment>  
    )
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to={`/career_training/topic/topic/991?module_id=${item.id}`} >
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Add Topics</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <Link to={`/career_training/module/module/988/${item.id}`} >
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        
        <a href="#" onClick={e=>confirmDelete(e, item, 'module')}>
          <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Module = ({}) => {
    return(
      <Fragment>
        <div className="course_details">
          <div className="row bg-black">
            <div className="col-xs-12 col-md-6 p-10">
              <div className="pull-left font-16 white bold m-l-10">{module.data.name}</div>
            </div>
            <div className="col-xs-12 col-md-6 p-10">
              <div className="pull-right white m-t-5">
                <span className="font-20 m-r-20 far fa-sticky-note pull-right" data-tip="Create and manage private notes" onClick={e=>showNoteModal(module, 'module')}>
                  <span className="badge up bg-white black">{module.notes_count}</span>
                </span>
                <span className="font-20 m-r-20 far fa-check-circle pull-right" data-tip="Create and manage checklists with class" onClick={e=>addChecklist(module, 'module')}>
                  <span className="badge up bg-white black">{module.check_lists_count}</span>
                </span>
                <span className="font-20 m-r-20 far fa-comment pull-right" data-tip="Post messages to class" onClick={e=>addComments(module, 'module')}>
                  <span className="badge up bg-white black">{module.comments_count}</span>
                </span>
                <span className="font-20 m-r-20 far fa-bookmark pull-right" data-tip="Add bookmarks to video lessons" onClick={e=>addBookMark(module, 'module')}>
                  <span className="badge up bg-white black">{module.bookmarks_count}</span>
                </span>
              </div>
            </div>
          </div>
          
          <div className="col-xs-12 m-t-10" dangerouslySetInnerHTML={{ __html: module.data.overview }}></div>
        </div> 
        <ReactTooltip place="bottom" />
      </Fragment>
    )
  }

  const Topic = ({}) => {
    var thumbnail
    if(topic.data.video_url && topic.data.video_url.includes('vimeo')){
      
    }else if(topic.data.video_url && topic.data.video_url.includes('youtube')){
      var videoid = topic.data.video_url && topic.data.video_url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if(videoid !== null){
        thumbnail = `https://img.youtube.com/vi/${videoid[1]}/maxresdefault.jpg`
      }
    }else{
      thumbnail =`https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png`
    }

    var objs = topic.data.upload && topic.data.upload.map(function(x) { 
      return { 
        //uri:"https://life.admin.cuetree.com/files?url=https://selftree-images.s3.amazonaws.com/uploads/file_upload/fj_file/18310/One%20page%20supplement%20Note%20Wed%20Dec%208.pdf"
        uri: "https://life.admin.cuetree.com/files?url="+x,
        fileType: x.split('.').pop()
        //uri:"https://code.visualstudio.com/shortcuts/keyboard-shortcuts-macos.pdf"
      }; 
    });

    return(
      <Fragment>
        <div className="course_details">
          <div className="row bg-black">
          <div className="col-xs-12 col-md-6 p-10">
            <div className="pull-left font-16 white bold m-l-10">{topic.data.name}</div>
          </div>
          <div className="col-xs-12 col-md-6 p-10">
            <div className="pull-right white m-t-5">
              <span className="font-20 m-r-20 far fa-sticky-note pull-right" data-tip="Create and manage private notes" onClick={e=>showNoteModal(topic, 'topic')}>
                <span className="badge up bg-white black">{topic.notes_count}</span>
              </span>
              <span className="font-20 m-r-20 far fa-check-circle pull-right" data-tip="Create and manage checklists with class" onClick={e=>addChecklist(topic, 'topic')}>
                <span className="badge up bg-white black">{topic.check_lists_count}</span>
              </span>
              <span className="font-20 m-r-20 far fa-comment pull-right" data-tip="Post messages to class" onClick={e=>addComments(topic, 'topic')}>
                <span className="badge up bg-white black">{topic.comments_count}</span>
              </span>
              <span className="font-20 m-r-20 far fa-bookmark pull-right" data-tip="Add bookmarks to video lessons" onClick={e=>addBookMark(topic, 'topic')}>
                <span className="badge up bg-white black">{topic.bookmarks_count}</span>
              </span>
            </div>
          </div>
          </div>
          <div className="col-xs-12">
            <div className="font-12 lgrey">{topic.topic_form_id}</div>
            <Tabs defaultActiveKey={tabKey} onChange={callback}>
              <TabPane tab="Overview" key="1">
                { topic.data.url && 
                  <div className="col-xs-12 col-md-6 p-7 mobile-hr">
                    <p className="m-t-10" dangerouslySetInnerHTML={{ __html: topic.data.overview || 'No content to display at this time'}}></p>
                    <a href={topic.data.url} target="_blank">
                      {topic.data.url}
                    </a>
                  </div>
                }
              </TabPane>
              <TabPane tab="Video" key="2">
                { topic.data.video_url && 
                  <div className="col-xs-12 col-md-6 p-7">
                    {(topic.data.video_url && !topic.data.video_url.includes('vimeo')) ?
                    <a href={topic.data.video_url} target="_blank">
                      {thumbnail && 
                        <Fragment>
                          <div class="thumb-wrapper">
                            <img src={thumbnail} className="w-100p" alt="My Video Thumb" />
                            <span className="w-100p"></span>
                          </div>
                        </Fragment> 
                      }
                    </a>:
                    <VimeoPlayer url={topic.data.video_url} width="100%" height="300px"/>}
                  </div>
                }
              </TabPane>
              <TabPane tab={"Uploads " + "("+topic.data.upload.length+")"} key="3">
                <div className="col-xs-12">
                  <div className="col-xs-12">
                    { file === "" ? <b>No content to display at this time.</b>:<PDFObject height="400px" url={"https://life.admin.cuetree.com/files?url="+file} />}
                  </div>
                  
                  <Attachments upload={topic.data.upload}/>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div> 
        <ReactTooltip place="bottom" />
      </Fragment>
    )
  }

  const SubTopic = ({}) => {
    var thumbnail
    if(subtopic.data.video_url && subtopic.data.video_url.includes('vimeo')){
      
    }else if(subtopic.data.video_url && subtopic.data.video_url.includes('youtube')){
      var videoid = subtopic.data.video_url && subtopic.data.video_url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if(videoid !== null){
        thumbnail = `https://img.youtube.com/vi/${videoid[1]}/maxresdefault.jpg`
      }
    }else{
      thumbnail =`https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png`
    }

    var objs = topic.data.upload && topic.data.upload.map(function(x) { 
      return { 
        //uri:"https://life.admin.cuetree.com/files?url=https://selftree-images.s3.amazonaws.com/uploads/file_upload/fj_file/18310/One%20page%20supplement%20Note%20Wed%20Dec%208.pdf"
        uri: "https://life.admin.cuetree.com/files?url="+x,
        fileType: x.split('.').pop()
        //uri:"https://code.visualstudio.com/shortcuts/keyboard-shortcuts-macos.pdf"
      }; 
    });

    return(
      <Fragment>
        <div className="course_details">
          <div className="row bg-black">
            <div className="col-xs-12 col-md-6 p-10">
              <div className="pull-left font-16 white bold m-l-10">{subtopic.data.name}</div>
            </div>
            <div className="col-xs-12 col-md-6 p-10">
              <div className="pull-right white m-t-5">
                <span className="font-20 m-r-20 far fa-sticky-note pull-right" data-tip="Create and manage private notes" onClick={e=>showNoteModal(subtopic, 'content')}>
                  <span className="badge up bg-white black">{subtopic.notes_count}</span>
                </span>
                <span className="font-20 m-r-20 far fa-check-circle pull-right" data-tip="Create and manage checklists with class" onClick={e=>addChecklist(subtopic, 'content')}>
                  <span className="badge up bg-white black">{subtopic.check_lists_count}</span>
                </span>
                <span className="font-20 m-r-20 far fa-comment pull-right" data-tip="Post messages to class" onClick={e=>addComments(subtopic, 'content')}>
                  <span className="badge up bg-white black">{subtopic.comments_count}</span>
                </span>
                <span className="font-20 m-r-20 far fa-bookmark pull-right" data-tip="Add bookmarks to video lessons" onClick={e=>addBookMark(subtopic, 'content')}>
                  <span className="badge up bg-white black">{subtopic.bookmarks_count}</span>
                </span>
                <span className="font-20 m-r-20 far fa-clipboard pull-right" data-tip="Attachments" onClick={e=>showAttachment(e,subtopic)}>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <div className="font-12 lgrey">{subtopic.content_form_id}</div>
            <Tabs defaultActiveKey={tabKey} onChange={callback}>
              <TabPane tab="Overview" key="1">
                <div className="col-xs-12 p-7">
                  <p dangerouslySetInnerHTML={{ __html: subtopic.data.overview || 'No content to display at this time'}}></p>
                  {subtopic.data.url && <Fragment>
                    <a href={subtopic.data.url} target="_blank">
                      {subtopic.data.url}
                    </a>
                  </Fragment>
                  }
                </div>
              </TabPane>
              <TabPane tab="Video" key="2">
                {subtopic.data.video_url && 
                  <div className="col-xs-12 col-md-6 p-7">
                    {(subtopic.data.video_url && !subtopic.data.video_url.includes('vimeo')) ?
                    <a href={subtopic.data.video_url} target="_blank">
                      {thumbnail && 
                        <Fragment>
                          <div class="thumb-wrapper">
                            <img src={thumbnail} className="w-100p" alt="My Video Thumb" />
                            <span className="w-100p"></span>
                          </div>
                        </Fragment> 
                      }
                    </a>:
                    <VimeoPlayer url={subtopic.data.video_url} width="100%" height="300px"/>}
                  </div>
                }
              </TabPane>
              <TabPane tab={"Uploads " + "("+subtopic.data.upload.length+")"} key="3">
                <div className="col-xs-12">
                  <div className="col-xs-12">
                    { file === "" ? <b>No content to display at this time.</b>:<PDFObject height="400px" url={"https://life.admin.cuetree.com/files?url="+file} />}
                  </div>
                  
                  <Attachments upload={subtopic.data.upload}/>
                </div>
              </TabPane>
              <TabPane tab="Assessment" key="4">
                <div className="col-xs-12 p-7">
                    <div className="p-5">
                      {subtopic.assessment ? 
                        <Fragment>
                          <Link target="_blank" 
                            className="fas fa-user-check font-15"
                            to={`/career_training/question/${params.journey_category_id}/${params.journey_profile_id}/${subtopic.assessment.review_id}`}>&emsp;<u>{subtopic.assessment.data?.title}</u></Link>
                        </Fragment> : 'No content to display at this time'
                      }
                    </div>
                </div>
              </TabPane>
              <TabPane tab="Survey" key="5">
                <div className="p-5">
                  {subtopic.survey ?
                    <Fragment>
                      <Link target="_blank" 
                      className="fas fa-file-alt font-15"
                      to={`/form/submissions/${params.journey_category_id}/${params.journey_profile_id}/${subtopic.survey.review_id}`}>&emsp;<u>{subtopic.survey.data?.title}</u></Link>
                    </Fragment> : 'No content to display at this time'
                  }
                </div>
              </TabPane>
            </Tabs>       
          </div>
        </div> 
        <ReactTooltip place="bottom" />
      </Fragment>
    )
  }

  const courseName = courseDetails.filter(function (el)
    {
      return courseTrainerId === el.id
    }
  );

  if(!modules) return(<PreLoadSpinner/>)

  return (
    <Fragment>
      <div className="content">
        <div className="col-sm-4">
          <JourneyTitle title={"Topics"}  project={journey_category}/>
        </div>

        <div className="col-sm-8">
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/career_training/project/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>

          <NavIcon id="back_icon" dataTip="Back to Course List" 
            onSelect={e=>props.history.goBack()}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-arrow-left"/>

          <NavIcon id="menu" dataTip="Toggle Table of Contents" onSelect={e=>setShowSideNav(!showSideNav)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-bars"/>

          <NavIcon dataTip="Open Course List" onSelect={e=>toggleCourseDetailsModal(e)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-book"/>
          
          <NavIcon dataTip="Open Course Notifications" onSelect={e=>setIsHelpMessageOpen(!isHelpMessageOpen)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-bell"/>
        </div>
        
        <div className="clear"/>
      </div>
      {isHelpMessageOpen && (
        <NotificationList item_type="journey_profile" item_id={params.journey_profile_id}/>
      )}
      <div className="page-content row bg-white p-12">
        <div className="row bg-darkblue white">
          <div className="col-xs-12 col-md-6 p-7">
            <div className="pull-left font-16 white bold m-l-10">{courseName.length > 0 ? courseName[0].data.course_name :''}</div>
          </div>
        </div>
        
        <div className={`col-xs-12 col-sm-4 col-md-3 col-lg-3 ht-500 scroll-auto course_module bg-darkblue ${showSideNav?'':'hidden'}`} ref={contentNavEl}>
            <ModulesList modules={modules} setModule={setModule} getAllTopic={getAllTopic} 
            setTopics={setTopics} getSubTopics={getSubTopics} subtopics={subtopics} 
            module={module} setShowSideNav={setSideNav} setData={setData} setFile={setFile} setTabKey={setTabKey}/>
        </div>
                  
        <div className={ showSideNav ? 'col-xs-12 col-sm-8 col-md-9 col-lg-9 bg-white p-5 ht-500 scroll-auto grey':'col-xs-12 col-sm-12 col-md-12 col-lg-12 bg-white p-5 mht-500 scroll-auto grey'}>
          {viewType == 'module' && <Module/>}
          {viewType == 'topic' && <Topic/>}
          {viewType == 'subtopic' && <SubTopic/>} 
          {viewType == 'blank' && <Fragment>
              <h4 className="p-7">Click on table of contents for details</h4>
          </Fragment>}
        </div>
        
        { modules.length > 0 && module.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={module} 
          type="module" MenuItems={menuItems} menuHeight="250px"/>
        }
       
      </div>

      <SideMenuBar helpId="15"/>

      {isBookMarkOpen && <GenericModal title="Video BookMark" component={VideoBookmark} toggleModal={toggleBookMarkModal} selectedTag={selectedData}/>}

      {isAttachmentOpen && (
        <GenericModal component={AttachmentModal} title={"Attachments"} item={selectedData}
          isOpen={isAttachmentOpen} toggleModal={toggleAttachmentModal}/>
      )}

      {isCommentOpen && (
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal}
          item={selectedData} type={selectedData.type} 
          journeyCategoryId={params.journey_category_id} params={{course_id:params.course_id}}/>
      )}
      
      {isChecklistOpen && (
        <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal}
          item={selectedData} type={selectedData.type}/>
      )}

      {isNoteOpen && <NotesModal toggleModal={toggleNoteModal} type={selectedData.type}  id={selectedData.id}/>}
      
      {isCourseDetailsOpen && (
        <GenericModal component={CourseDetailsList} title={'Course Details List'}
          courseDetails={courseDetails} isOpen={isCourseDetailsOpen} 
          toggleModal={toggleCourseDetailsModal} onCourseChange={onCourseChange} courseTrainerId={courseTrainerId}/>
      )}

    </Fragment>
  )
}

const CourseDetailsList = ({courseDetails, onCourseChange, courseTrainerId}) => courseDetails.map((m, i) => {  
  return(
    <div className={"fstResultItem font-16" + (courseTrainerId === m.id ? ' bg-highlight white':'')} key={i} onClick={e=>onCourseChange(m)}>
      {m.data?.course_name}
    </div>  
  )
})

const ModulesList = ({modules, module, setModule, getAllTopic, setTopics, getSubTopics, subtopics, setSideNav, setData, setFile, setTabKey}) => modules.map((m, i) => {  
  return(
    <div className={"p-5 m-0-15 bg-white"} key={i}>
      <Module i={i} m={m} module={module} setModule={setModule} getAllTopic={getAllTopic} 
      setTopics={setTopics} getSubTopics={getSubTopics} subtopics={subtopics} 
      setShowSideNav={setSideNav} setData={setData} setFile={setFile} setTabKey={setTabKey}/>
    </div>  
  )
})

const Module = ({i, m, module, setModule, getAllTopic, setTopics, getSubTopics, subtopics, setSideNav, setData, setFile, setTabKey}) => {
  const styleBorder = {'borderLeft': '3px solid #ee6565'}
  const [activeTopicId, setActiveTopicId] = useState()
  const [activeSubTopicId, setActiveSubTopicId] = useState()

  let style = {textDecoration:module.record_id == m.record_id?'':''}
  return(
    <Fragment>
      <div className="module-title font-20 bg-white p-10" style={module.record_id == m.record_id ? style : style} onClick={e=>setData('module', m)} id={`module_${m.id}`}>
        <div className="row">
          <div className="pull-left"> 
            <span className={"col-xs-4 circle-number m-r-5 m-b-5 p-10 white font-16 " +(module.record_id == m.record_id ? 'bg-darkblue':'bg-blackdark')} style={{width:"18px"}}>{i+1}</span>
            <span className="col-xs-8">{ m.data.name +" - "+ m.data_source?.subjects[0].data.label}</span>
            <div className="col-xs-12 m-l-10 m-t-5 text-left font-14">{ m.topics ? m.topics.length + " Topics" : "0" + " Topics"} </div>
          </div>
          <div className="pull-right">{module.record_id == m.record_id ? <i className="fa fa-chevron-up" aria-hidden="true"/>:<i className="fa fa-chevron-down" aria-hidden="true"/>}</div>
        </div>
      </div>
      {(module.record_id == m.record_id) && <Topics module={m} topics={(m.topics || [])} getAllTopic={getAllTopic} 
      activeTopicId={activeTopicId} setActiveTopicId={setActiveTopicId} getSubTopics={getSubTopics} subtopics={subtopics} 
      setData={setData} activeSubTopicId={activeSubTopicId} setActiveSubTopicId={setActiveSubTopicId} setFile={setFile} setTabKey={setTabKey}/>}
    </Fragment>  
  )
}

const Topics = ({module, topics, getAllTopic, activeTopicId, setActiveTopicId, getSubTopics, subtopics, setData, activeSubTopicId, setActiveSubTopicId, setFile, setTabKey}) => topics && topics.map((t, i) => {
  
  const styleBorder = {'color':'#2675d7'}
  let style = {textDecoration: (activeTopicId == t.record_id)?'':''}

  const getSubTopic= (t) =>{
    setActiveTopicId(t.record_id)
    //getAllTopic(t.id)
    getSubTopics(t.id)
    setData('topic', t)
    setFile('');
    setTabKey('1')
  }

  return (
    <Fragment key={i}>
      <div className="module-title m-t-5 m-l-8 font-18 p-10 bg-white" style={ activeTopicId == t.record_id && !activeSubTopicId ? styleBorder : style} 
      key={i} onClick={e=> getSubTopic(t)} id={`topic_${t.id}`}>
        <div className="row">
          <div className="pull-left">{i+1+"."} {t.data.name}</div>
          <div className="pull-right">{activeTopicId == t.record_id ? <i className="fa fa-chevron-up" aria-hidden="true"/>:<i className="fa fa-chevron-down" aria-hidden="true"/>}</div>
          <div className="m-l-10 m-t-10 text-left font-14">
          <br/>({t.content_count} Lessons) 
          {t.data.upload.length > 0 ? " Uploads (" +t.data.upload.length + ") " :''}
           </div>
        </div>
      </div>
      <ul>
        {(t.record_id == activeTopicId) && <SubTopics module={t} subtopics={(subtopics || [])} getSubTopics={getSubTopics} 
        activeSubTopicId={activeSubTopicId} setActiveSubTopicId={setActiveSubTopicId} setData={setData} setFile={setFile} setTabKey={setTabKey}/> }
      </ul>
    </Fragment>
  )
})

const SubTopics = ({module, subtopics, getSubTopics, activeSubTopicId, setActiveSubTopicId, setData, setFile, setTabKey}) => subtopics && subtopics.map((t, i) => {
  let style = {textDecoration: (activeSubTopicId == t.record_id)?'':''}
  const styleBorder = {'color':'#2675d7'}

  const setSubTopicId= (t) =>{
    setData('subtopic', t)
    setActiveSubTopicId(t.id)
    setFile('')
    setTabKey('1')
  }

  return (
    <Fragment key={i}>
      <li type="a" className="module-title m-t-10 m-l-42 font-16 p-10 bg-white" style={ activeSubTopicId == t.id ? styleBorder : style} key={i} onClick={e=>setSubTopicId(t)} id={`subtopic_${t.id}`}>
        <div className="pull-left">{t.data.name}</div>
        <div className="pull-right">
          {t.data.upload.length > 0 ? " Uploads (" + t.data.upload.length + ")" :''}
          {t.assessment ? <i className="fa fa-book m-l-10" data-tip="Assessment" aria-hidden="true"/>:''}
        </div>
      </li>
    </Fragment>
  )
})

export default Topic;