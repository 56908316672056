export default {
  user_setting:`Personalize your user name, profile picture, secret questions, and other settings.
The secret questions are required.`,
  team_activity_role: `You must first invite users in the Journey Settings menu before you can add them to this Activity tile.`,
  profile_close: `You must fill and submit the Account Profile form before you can use cueTree Education. `,
  new_journey: `Click the + button icon in the top right corner to add new journey.`,
  new_activity: `Click the + button icon in the top right corner to add new activity.`,
  new_planner: `Click the + button icon in the top right corner to add new planner.`,
  new_assessment: `Click the + button icon in the top right corner to add new assessment.`,
  new_user_form: `Click the + button icon in the top right corner to add new form.`,
};