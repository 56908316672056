import React, { useState, Fragment, useEffect, useRef, useContext } from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import JourneyPlanner from "../../../../../components/Journey/HighSchoolJourney/Planners/List/JourneyPlanner";
import PlannerFilter from '../../../../../components/Journey/HighSchoolJourney/Planners/List/PlannerFilter';
import JourneyPlannerService from "../../../../../components/Journey/HighSchoolJourney/Planners/JourneyPlannerService";
import ActivityService from "../../Activities/ActivityService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import CardMenu from "../../../../Modals/CardMenu";
import { Link, useLocation } from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import useStyle from "../../../../../hooks/useStyle";
import PlannerFilterModal from "../../Planners/List/PlannerFilterModal";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import PlannerTypeMenu from "../../Planners/List/PlannerTypeMenu";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import {usePageTour} from "../../../../../components/Common/usePageTour";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import FilterSlider from "../../../../Common/FilterSlider"
import { MenuItem,CardMenuInfo,NavIcon,CardMainMenu } from "../../../../Common/MenuItem";
import FilterDataGroup from "../../../../DataGroups/FilterDataGroup";
import AddDataGroup from "../../../../DataGroups/AddDataGroup";
import GenericModal from "../../../../Modals/GenericModal";
import PlannerGroupList from "./PlannerGroupList";
import { SwiperContainer, SwiperSlide } from "../../../../Common/Swiper";
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import HelpMessageList from "../../../../../constants/HelpMessageList";
import CommonService from "../../../../../services/CommonService";
import ReactTooltip from "react-tooltip";
import {HighSchoolLink} from "../../../../Common/RouteLinks"
import PlannerContentTableList from "./PlannerContentTableList";
import PlannerContent from "./PlannerContent";

let requestParams = {}
let data_source = {}
let selectedPlanner = {}
let filter_data_source = {}
let cardViewCount = 1;

const JourneyPlannerList = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)

  const location = useLocation()

  let params = props.match.params;
  let project = props.location.state;
  
  let cardGridCss = "col-md-4";
  let cardHeightCss = "ht-400";

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [journeyPlannerList, setJourneyPlannerList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [isAdminUser, setIsAdminUser] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showGroup, setShowGroup] = useState(false)
  let [openSideNav, setOpenSideNav] = useState(false)
  const [gradeData, setGradeData] = useState({})
  const [checkedItems, setCheckedItems] = useState({})

  const indexEle = useRef(null)
  let tour = usePageTour()

  const {isOpen: isActivityTypeMenuOpen, toggleModal: toggleActivityTypeMenuModal,} = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  //const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const {isOpen: isGroupModalOpen, toggleModal: toggleGroupModal,} = useModal()
  const {isOpen: isPrivacyModalOpen, toggleModal: togglePrivacyModal,} = useModal()
  const {isOpen: isHelpMessageOpen, toggleModal: toggleHelpMessage,} = useModal()
  const [showPlannerContentTable, setShowPlannerContentTable] = useState(false)
  const [formFieldsList, setFormFieldsList] = useState({})

  let disableClickEvent = {}
  if (currentUser.current_admin.admin_role_name == "demo_user") {
    disableClickEvent.pointerEvents = "none";
  }

  useEffect(() => {
    requestParams = {
      'data_source_params': {
        data_source_id: [2216,2223,2224,2235,3250,3255,2240,3256,2244,2232,2233,2234,2256,2257,2258,2226,2227,2228,2230,2219,2220,2222,2221,2214,3124,3125,3126,3127,3128,3129,3130,3131,3132,3133,3134,3289,3320, 2861],
        response_type: "object",
      },
      'form_field_params': {
        'ids':[337,344,342,341,338,340,339,347,346,343,345,3,1047]
      },
      'data':{},
      'is_active': true,
      'search':props.searchString,
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'journey_category_id': params.journey_category_id
    }

    if(props.location.pathname.includes('admin')){
      delete requestParams.is_active
      requestParams.user_role = 'user_group'

      if(currentUser.current_admin.role_content){
        setIsAdminUser(true)
        requestParams.user_role = 'admin'
        //requestParams.partnership_id = currentUser.domain_info.partnership_org_id
      }
    }

    getPlannerList()
    ReactTooltip.rebuild()
    return () => {
     requestParams = {} 
    }
  }, [props.searchString, currentpage])

  let getPlannerList = (filter) => {
    if (filter) {
      requestParams.data.grade = filter.fj_edu_current_grade;
      requestParams.data.semester_period = filter.seasons_semester_periods;
    } else if(project) {
      let journeyProfile = project?.education_journey_profiles?.data
      requestParams.data.grade = parseInt(journeyProfile.fj_edu_current_grade)
      requestParams.data.subject_interests = parseInt(journeyProfile.subjects_most_interest_array)
      requestParams.data.career_interests = parseInt(journeyProfile.interest_array)
      /*let f = querystringify.parse(props.location.search)
      requestParams.data.grade = parseInt(f.grade)
      requestParams.data.semester_period = parseInt(f.semester_period)*/
    }

    JourneyPlannerService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        setFormFieldsList(res.data.form_fields)
        requestParams.totalItems = res.data.meta.total;
        cardViewCount = CommonService.getCardCount(screen)
        requestParams.totalPages = Math.ceil(
          requestParams.totalItems / cardViewCount
        )
        filter_data_source.fj_edu_current_grade =
          data_source.fj_edu_current_grade;
        filter_data_source.seasons_semester_periods =
          data_source.seasons_semester_periods;
        if (requestParams.search || requestParams.group_id) {
          setJourneyPlannerList(res.data.planner_lists)
        } else {
          setJourneyPlannerList([
            ...journeyPlannerList,
            ...res.data.planner_lists,
          ])
        }
        setLoading(false)
        res.data.planner_lists.length == 0 && toggleHelpMessage()
        if (currentpage == 1) {
          setTimeout(function () {
            tour && tour.start()
          }, 1000)
        }
      }
    })
  }

  const deletePlanner = (e) => {
    e.preventDefault()
    JourneyPlannerService.delete(selectedPlanner.id).then((res) => {
      if (res.status == 204) {
        setJourneyPlannerList(
          journeyPlannerList.filter((i) => i.id !== selectedPlanner.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, planner) => {
    selectedPlanner = planner;
    toggleMenuModal(e)
  }

  const clonePlanner = (e) => {
    e.preventDefault()
    createActivity(selectedPlanner)
    toggleMenuModal(e)
  }

  const createActivity = (planner) => {
    if (location.pathname.includes("course/planners")) {
      return;
    }

    let req = { data: {}, draft: true }
    req.education_journey_profile_id = params.journey_profile_id;
    req.data = planner.data;

    /*Remove Planner data*/
    delete req.data.grade;
    delete req.data.grade_array;
    delete req.data.semester_period;
    delete req.data.semester_period_array;
    delete req.data.record_title;

    const type = data_source.fj_edu_entry_type.options[planner.data.fj_edu_entry_type]
    /*Remove Planner data*/
    req.journey_category_id = params.journey_category_id;
    req.planner_list_id = planner.id;
    req.activity_type = type.label;
    req.activity_form_id = type.child_form;
    req.education_activities = planner.education_activities;

    props.history.push(
      `/highschool/activity/form/${params.journey_category_id}/${params.journey_profile_id}/${type.label}/${type.child_form}?fj_edu_entry_type=${type.value}`,
      req
    )

    //Call Create activity api
    /*ActivityService.create(req).then((res) => {
      if (res.status == 201) {
        props.history.push(
          `/highschool/activity/${params.journey_category_id}/${params.journey_profile_id}`
        )
      }
    })*/
  }

  const toggleFilterModal = (e) => {
    e.preventDefault()
    props.history.push(
      `/highschool/planners/filter/${params.journey_category_id}/${params.journey_profile_id}`,
      project
    )
  }

  const updatePrivacy = (e, item) => {
    e.preventDefault()
    if (["shared", "private"].includes(item.privacy_type)) {
      item.privacy_type = "community";
      item.data.share_selection = 1;
    } else if (item.privacy_type == "community") {
      item.privacy_type = "private";
      item.data.share_selection = 2;
    }
    JourneyPlannerService.update(item).then((res) => {
      if (res.status == 204) {
        setJourneyPlannerList([...journeyPlannerList])
      }
    })
  }

  const Checkbox = ({ type = "checkbox", name, checked = false, onChange }) => {  
    return (
      <input className="lgrey m-r-5" type={type} name={name} checked={checked} onChange={onChange} />
    )
  }

  const PlannerSelection = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Selection of Planner
        </h5>
      </div>
      <p className="ver-icons p-5 text-center">
        <label className="toggleSwitch nolabel" onclick="">
          <input type="checkbox" />
          <span>
            <span>OFF</span>
            <span>ON</span>
          </span>
          <a></a>
        </label>
      </p>
    </div>
  )

  const PrivacyModal = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Types of Planner
        </h5>
        <PrivacyList />
      </div>
    </div>
  )

  const PrivacyList = (key) => ["all", "community", "private", "shared"].map((k) => {
    if(k == 'all'){
      return(
        <Fragment key={k}>
          <a className="ver-icons p-5">
            <Checkbox name={k} checked={checkedItems[k]} onChange={(e) => filterPrivacy(e, k)}/>
            <div className="popup-menu-title text-cap">{k}</div>
          </a>
        </Fragment>
      )
    }else{
      return(
        <Fragment key={k}>
          <a className="ver-icons p-5">
            <Checkbox name={k} checked={checkedItems[k]} onChange={(e) => filterPrivacy(e, k)}/>
            <div className="popup-menu-title text-cap">{k}</div>
          </a>
        </Fragment>
      )
    }
  })

  const filterPrivacy = (e, type) => {
    setCheckedItems({...checkedItems, [e.target.name]: e.target.checked})
    e.preventDefault()
    requestParams.privacy_type = type == "all" ? null : [type];
    getPlannerList()
  }

  const getPlannerByGroup = (group_id) =>{
    requestParams.group_id = group_id
    getPlannerList(requestParams)
  }

  const getPlannerByGrade = () =>{
    requestParams.data = gradeData
    getPlannerList(requestParams)
  }

  const togglePlannerContentTable = () => setShowPlannerContentTable(!showPlannerContentTable)

  const menuItems = ({ item }) => {
    let isAuthor = currentUser.id == item.created_by || isAdminUser;

    return (
      <Fragment>
        <CardMenuInfo formId={item.planner_form_id} recordId={item.record_id}
          createdAt={item.created_at} userName={item.author.name}/>

        {params.journey_category_id && (
          <MenuItem id="copy_act_icon" icon="far fa-copyright"
            action="Copy to Activity tab" onSelect={(e) => {clonePlanner(e)}}/>
        )}
        {isAuthor && (
          <MenuItem id="edit_icon"
            link={`/highschool/planner/edit/${item.planner_type}/${item.planner_form_id}/${item.id}`}
            icon="far fa-edit" action="Edit"/>
        )}
        {isAuthor && (
          <MenuItem id="delete_icon" icon="far fa-trash-alt"
            action="Delete" onSelect={(e) => toggleDeleteModal(e)}/>
        )}
        {isAdminUser && (
          <a href="#" onClick={(e) => updatePrivacy(e, item)}>
            <i className={(item.privacy_type == "community"? "far fa-check-circle": "far fa-circle") + " font-15"}></i>
            <span className="font-13 text-cap">{item.privacy_type}</span>
            <i className="fa fa-angle-right"></i>
          </a>
        )}

        <MenuItem id="create_group" icon="fas fa-object-group" action="Add Planner to a Group"
          onSelect={toggleGroupModal}/>
      </Fragment>
    )
  }

  const Menu = ({ planner }) => (
    <CardMainMenu activity={planner} showMenu={showMenu} disableClickEvent={disableClickEvent}/>
  )

  const journeyPlannerCard = journeyPlannerList && journeyPlannerList.map((p, i) => (
    // <SwiperSlide key={i}>
      <div className={cardGridCss} key={i}>
        <PlannerContent journeyPlanner={p} dataSources={data_source}
          menu={Menu} addMenu={showMenu} updatePrivacy={updatePrivacy} currentUser={currentUser}
          cardHeightCss={cardHeightCss} index={i} formFieldsList={formFieldsList} />
        {selectedPlanner.record_id == p.record_id && (
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
            item={selectedPlanner} MenuItems={menuItems} menuHeight="300px"/>
        )}
      </div>
    // </SwiperSlide>
  ))

  if (loading) {
    return <PreLoadSpinner />;
  }
  
  return (
    <Fragment>
       <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <JourneyTitle title={"Planner"} project={project} />
              <div className="font-15">
                {requestParams.totalItems} Items
              </div>
            </div>

            <div className="flex aligncenter">
              {params.journey_category_id && (
                <NavIcon id="home_icon" dataTip="Back to Journey Home"
                  link={HighSchoolLink.hs_portal(params)}
                  className="bg-highlight round-btn float-right m-r-5 m-t-4"
                  icon="fas fa-home"/>
              )}
              <NavIcon id="toggle_group" dataTip="Show Grouped Planner" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={e=>setShowGroup(!showGroup)} icon="fas fa-object-group"/>

              <NavIcon style={disableClickEvent} id="toggle_group"
                dataTip="Filters" className="bg-highlight round-btn float-right m-r-5 m-t-4"
                onSelect={(e) => setOpenSideNav(!openSideNav)}
                icon="fas fa-sliders-h"/>

              <NavIcon id="toggle_table" dataTip="Toggle Planner Content Table" 
                onSelect={togglePlannerContentTable}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fa fa-table"/>

              <NavIcon id="create_planner" dataTip="Add New Planner"
                className="bg-highlight round-btn float-right m-r-5 m-t-4"
                onSelect={toggleActivityTypeMenuModal}
                icon="fas fa-plus"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            {!showPlannerContentTable && !showGroup && (
              <div className="row">
                {/* <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
                  requestParams={requestParams} indexEle={indexEle} cardViewCount={cardViewCount}> */}
                    {journeyPlannerCard}
                {/* </SwiperContainer> */}
                <div className="col-md-12 col-xs-12 text-center">
                  <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
                </div>
              </div>
            )}

            {showPlannerContentTable && !showGroup && <Fragment>
              <PlannerContentTableList plannerList={journeyPlannerList} requestParams={requestParams}
                selectedPlanner={selectedPlanner} updatePrivacy={updatePrivacy} toggleDeleteModal={toggleDeleteModal}
                getPlannerList={getPlannerList} />
              </Fragment>
            }

            {showGroup && !showPlannerContentTable && <PlannerGroupList location={props.location} />}

          </div>
        </div>
      </div>

 
      {isActivityTypeMenuOpen && (
        <PlannerTypeMenu journey_category_id={params.journey_category_id}
          journey_profile_id={params.journey_profile_id} isOpen={isActivityTypeMenuOpen}
          toggleModal={toggleActivityTypeMenuModal}/>
      )}

      {openSideNav && <FilterSlider        
        component2={FilterDataGroup}
        component3={PrivacyModal}
        component4={PlannerFilter}
        component1 ={PlannerSelection}
        openSideNav={openSideNav}
        setOpenSideNav={setOpenSideNav}
        item={selectedPlanner}
        groupType="planner"
        list={journeyPlannerList}
        setList={setJourneyPlannerList}
        journey_category_id={params.journey_category_id}
        journey_profile_id={params.journey_profile_id}
        project={project}
        filterByGroup={getPlannerByGroup}
        setGradeData={setGradeData}
        filterByGrade={getPlannerByGrade}/> 
      }

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deletePlanner}/>

      {isGroupModalOpen && (
        <GenericModal component={AddDataGroup} title="Group" item={selectedPlanner}
          list={journeyPlannerList} setList={setJourneyPlannerList}
          toggleModal={toggleGroupModal} groupType="planner"/>
      )}

      {isPrivacyModalOpen && (
        <GenericModal component={PrivacyModal} title="Privacy Filter" toggleModal={togglePrivacyModal}/>
      )}

      <SideMenuBar helpId="2" />

      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_planner}
          onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      )}
    </Fragment>
  )
}

export default JourneyPlannerList;

/*
{isFilterOpen && <PlannerFilterModal filterData={requestParams.data} filterDataSources={filter_data_source} isOpen={isFilterOpen} toggleModal={toggleFilterModal} onSearch={getPlannerList}/>}
*/
