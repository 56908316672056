import env from '../../../../env';
import axios from 'axios';
let url = env.carrier_training+'topics'

class TrainingTopicService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  } 

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  uploadCsvData(files, ele, fd){
    return new Promise((resolve, reject) => {
      fd.append('file', files[0])

      let reader = new FileReader()
      reader.readAsDataURL(files[0])  

      reader.onload = () => {
        axios.post(`${url}/upload_csv_data`, fd, {
          headers: { "Content-Type": undefined },
        }).then((res) => {
          resolve(res);
        })
      }
      ele.value = null;
    })
  }

}

export default new TrainingTopicService();