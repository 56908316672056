import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import moment from '../../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../../hooks/useStyle';
import useSort from '../../../hooks/useSorting';
import SortDirection from '../../../components/Common/SortDirection'
import CheckAccess from "./CheckAccess";

const RoleListTable = (props) => {
  useStyle('table');  
  const {sort} = useSort(props.requestParams, props.getRoleList);
  
  const tableData = props.roles.map((role, k) =>
    <tr key={k}>
      <td data-title="Id">{k}</td>

      <td data-title="Role">
        {role.role}
      </td>
     
      <td data-title="Permissions">
        {role.permissions && Object.keys(role.permissions).map((p)=>(
            <span className="margin-2 br-groove-black p-2 bg-blue-white black-light-1" key={p}>{props.permissions[p]}&emsp;</span>
        ))}
      </td>
      
      <td data-title="Users">
        {role.role_users && role.role_users.map((u, i) => (
          <div>{u.email}</div>
        ))}
      </td>
      <td>
      {CheckAccess.hasEditAccess(props.currentUser, props.journey, role) && <Fragment>
        <div className="action-btn bg-highlight" onClick={e=>{props.restModalUser(e)}}>Add User</div>
        <div className="action-btn bg-highlight" onClick={e=>{props.setEdit(e, role)}}>Edit</div>
        <div className="action-btn bg-highlight" onClick={e=>{props.selectRole(role);props.setDelete(e, role)}}>Delete</div>
      </Fragment>}
      </td>
    </tr>
  )

  return (
    <div className="table-responsive">
      <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')}>Id 
              <SortDirection sort_column={props.requestParams.sort_column} column="record_id" reverse={props.requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('role')}>Role 
              <SortDirection sort_column={props.requestParams.sort_column} column="role" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('permissions')}>Permissions 
              <SortDirection sort_column={props.requestParams.sort_column} column="permissions" reverse={props.requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('users')}>Users
              <SortDirection sort_column={props.requestParams.sort_column} column="users" reverse={props.requestParams.reverse}/>
            </th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {tableData}
        </tbody>
      </table>
    </div>
  )

};

export default RoleListTable;