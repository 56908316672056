import React, { Fragment, useState, useEffect, useContext, useRef} from "react";
import ReactTooltip from 'react-tooltip';
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import useStyle from "../../../../../hooks/useStyle";
import CommonService from "../../../../../services/CommonService";
import TrainingModuleService from "../../TrainingModules/TrainingModuleService";
import TrainingContentService from "../../TrainingContents/TrainingContentService"
import TrainingTopicService from "../../TrainingTopics/TrainingTopicService"
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import useModal from "../../../../../hooks/useModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import CommentsModal from "../../../../Modals/CommentsModal";
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal";
import {NavIcon, FilterMenuItem} from '../../../../Common/MenuItem';
import ProjectMenu from "../../../../Modals/ProjectMenu";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import NotesModal from "../../../../Modals/NotesModal";
import SideMenuBar from "../../../../Common/SideMenuBar";
import ShareItemModal from "../../../../ShareItems/ShareItemModal"
import Topic from "../../TrainingTopics/Topic"
import GenericModal from "../../../../Modals/GenericModal";
import TrainingModuleListTable from "./TrainingModuleListTable"
import FilterSlider from "./../../../../Common/FilterSlider"
import {PillDsList} from "../../../UserRoles/NotificationPill" 
import {CheckBox} from "../../../../Common/FormInput"

let requestParams = {}
let journey_category = null
let selectedModule = {}
let selectedContent = {}
let selectedTopic = {}
let selectedItem = null
let totalLessons = 0;
let subjectList = []

const TrainingModuleTypeList = (props) => {
  useStyle("filter_dialog")
  // useStyle('card')
  // useStyle('card_table')
  useStyle('add_role_dialog')

  const inputFileRef = useRef( null )

  let params = props.match.params;
  let journey_category = props.location.state;

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [modules, setModules] = useState([])
  const [tabIndex, setTabIndex] = useState()
  const [subjectId, setSubjectId] = useState()
  const [contents, setContents] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 
  const [contentIndex, setContentIndex] = useState()
  //const [trainingManager, setTrainingManager] = useState({})

  const {isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()
  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal,} = useModal()
  const {isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal,} = useModal()
  const {isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const {isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const {isOpen:isNoteOpen, toggleModal:toggleNoteModal } = useModal()
  /*const {isOpen:isShareItemOpen, toggleModal:toggleShareItemModal } = useModal()*/
  const {isOpen:isTopicOpen, toggleModal:toggleTopicModal } = useModal()
  const {isOpen:isBulkUploadOpen, toggleModal:toggleBulkUploadModal } = useModal()
  const {isOpen:isSubjectFilterModalOpen, toggleModal:toggleSubjectFilterModal } = useModal()
  const [showTableView, setShowTableView] = useState(false)
  let [openSideNav, setOpenSideNav] = useState(false)
  const [filter, setFilter] = useState();

  let height = {'height': '250px', 'minHeight':'unset', border:'1px solid #dbdada'}
  let isAdmin = false
  let showJourneyIcons = true
  if(props.history.location.pathname == '/admin/course/modules'){
    isAdmin = true
    showJourneyIcons = false
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'paginate':1,
      'per_page':100,
      'sort_column':'tm.sort_order',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'exists_in_planner':true,
      'topic_exists_in_planner':true,
      'add_content_count':true,
      'subject_id':subjectId,
      'data_source_params': {
        "data_source_id":[2865],
      }
    }

    if(props.history.location.pathname == '/admin/course/modules'){
      delete requestParams.exists_in_planner
      delete requestParams.topic_exists_in_planner
      requestParams.is_training_manager = true
      requestParams.sort_column = 'updated_at'
      requestParams.sort_direction = 'desc'
    }
    getModules()
  }, [props.history.location.pathname, subjectId])

  let getModules = () => {
    TrainingModuleService.getAll(requestParams).then((res) => {
      if(res.status == 200){
        subjectList = res.data.data_source_list.fj_subjects_interest
        setModules(res.data.modules)
        totalLessons = res.data.contents_count
        //setTrainingManager(res.data.shared_item)
      }
    })
  }

  let getContents = (topic_id) => {
    let req = {
      'topic_id':topic_id,
      'page':1,
      'per_page':100
    }
    TrainingContentService.getAll(req).then((res) => {
      setContents(res.data.contents)
    })
  }

  const openTab = (topic) =>{
    if(topic.record_id != tabIndex){
      getContents(topic.id)
      setContents([])
      setTabIndex(topic.record_id)
    }else{
      setTabIndex(null)
    }
  }

  const addComments = (e, content) => {
    e.stopPropagation()
    selectedContent = content;
    selectedContent.journey_category_id = params.journey_category_id
    toggleCommentModal()
  }

  const addChecklist = (e, content) => {
    e.stopPropagation()
    selectedContent = content;
    //selectedDataSource.education_journey_profile_id = params.journey_profile_id
    selectedContent.journey_category_id = params.journey_category_id
    toggleChecklistModal()
  }

  const showTopic = (e, a) => {
    selectedTopic = a;
    toggleTopicModal(e, true)
  }

  const showBulkUpload = (e, a) => {
    selectedTopic = a;
    toggleBulkUploadModal(e, true)
  }

  const showMenu = (e, a) => {
    selectedModule = a;
    toggleMenuModal(e, true)
  }

  const confirmDelete = (e, item, type) => {
    item.type = type
    selectedItem = item
    toggleDeleteModal(e)
  }

  const showNoteModal = (e, m) =>{
    selectedModule = m
    toggleNoteModal(e)
  }

  const uploadCsv = (e) =>{
    let fd = new FormData()
    fd.append('topic_form_id', 991)
    fd.append('topic_type', 'topic')
    fd.append('module_id', selectedModule.id)

    TrainingTopicService.uploadCsvData(e.currentTarget.files, e.currentTarget, fd).then((res)=>{
      if(res.status == 201){

      }
    })
  }

  const handleClick =(id)=> {
    console.log('scroll')
    //document.getElementById('info').scrollTop -= 10;
    document.getElementById('info_'+id).scrollTop += 10;

  }

  const deleteItem = (e) => {
    e.preventDefault()
    let req = {id:selectedItem.id, journey_category_id:params.journey_category_id}
    if(selectedItem.type == 'module'){
      TrainingModuleService.delete(req).then((res)=>{
        if(res.status==204){
          setModules(modules.filter(i => i.id !== selectedItem.id))
        }
      })  
    }else if(selectedItem.type == 'topic'){
      TrainingTopicService.delete(req).then((res)=>{
        if(res.status==204){
          let m = modules.filter(i => i.id == selectedItem.module_id)
          let t = m[0].topics.filter(i => i.id !== selectedItem.id)
          m[0].topics = t
          setModules([...modules])
        }
      })
    }else if(selectedItem.type == 'content'){
      TrainingContentService.delete(req).then((res)=>{
        if(res.status==204){
          setContents(contents.filter(i => i.id !== selectedItem.id))
        }
      })
    }
    
    toggleDeleteModal(e)
  }

  /*const setShareItem = (e) =>{
    shareItemInfo = {
      'shareable_item_type':'training_manager_page',
      'shareable_item_id':currentUser.domain_info.id,
      'training_manager_user_id': (trainingManager && trainingManager.training_manager_user_id) || currentUser.id
    }
    toggleShareItemModal(e)
  }*/

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to={`/career_training/topic/topic/991?module_id=${item.id}`} >
          <i className="far fa-plus-square font-15 black" aria-hidden="true"></i>
          <span className="font-13">Add Topics</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <a href onClick={e=>inputFileRef.current.click()}>
          <i className="far fa-file font-15 black" aria-hidden="true"></i>
          <span className="font-13">Upload Topics</span>
          <i className="fa fa-angle-right"></i>
        </a>

        <Link to={`/career_training/module/module/988/${item.id}?edit=true`} >
          <i className="far fa-edit font-15 black" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        
        <a href="#" onClick={e=>confirmDelete(e, item, 'module')}>
          <i className="far fa-trash-alt font-15 black" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const openContent = (e,content) =>{
    if(content.record_id != contentIndex){
      setContentIndex(content.record_id)
      setTimeout(function() {
        let ele = document.getElementById(`content_list_${content.record_id}`)
        ele.scrollIntoView()
      }, 0) 
    }else{
      setContentIndex(null)
    }
    toggleTopicModal(e, true)
  }

  const Topics = ({module, topics}) => topics.map((t,i) => {
    let isTabOpen = (tabIndex == t.record_id)
    const topicLink = `/career_training/topic/topic/991/${t.id}?edit=true`
    const contentLink = `/career_training/content/content/992?topic_id=${t.id}&module_id=${module.id}&edit=true`
    return (
      <Fragment key={t.record_id}>
        <div className={tabIndex == t.record_id ? "bg-white shadow-large-highlight":"bg-white"} >
          <div className="ver-icons p-7 br-d-grey clearfix">
            <div className="popup-menu-title d-flex bold">{i+1})  {t.data.name}</div>
            <span>Reference Url</span><a href={t.data.url} target="_blank">{t.data.url}</a><br/>
            <span>Video Url</span><a href={t.data.video_url} target="_blank">{t.data.video_url}</a>
            {t.content_count !== 0 &&<span  onClick={e=>openTab(t)} className={isTabOpen ? 'fas fa-chevron-down lgrey pull-right lh-unset':'fas fa-chevron-right lgrey pull-right lh-unset'} />}
            <span className="pull-right bold-600 m-r-15 green">{t.content_count} Lessons</span>
            {isAdmin &&
              <Fragment>
                <span className="far fa-trash-alt pull-right m-r-15 lgrey lh-unset" onClick={e=>confirmDelete(e, t, 'topic')}/>
                <Link to={topicLink} className="pull-right m-r-15">
                  <i className="far fa-edit lgrey" />
                </Link>  
                <Link to={contentLink} className="pull-right m-r-15">
                  <i className="fas fa-plus lgrey" />
                </Link>
                <span className="fa fa-expand pull-right m-r-15 lgrey lh-unset"  onClick={e=>showTopic(e,t)}/>
              </Fragment>
            }
          </div>
          {isTabOpen && <Contents module={module} topic={t}/>}
        </div>
      </Fragment>
    )
  })

  const Attachments = ({upload}) => upload.map((d, i) => (
    <a target="_blank" key={i} href={CommonService.getFileUrl(d)} className="col-xs-12 col-sm-4 col-md-3 m-t-5">
      <img src={CommonService.getIconByType(d)} className="img-thumbnail" width="75px"/>
      <div className="m-l-5">{CommonService.getFileName(d)}</div>
    </a>
  ))

  const TopicDate =({topic}) =>{
    let data = topic.data
    return(
      <Fragment>
        <div className="row font-14 p-7">
          <span>Video Url</span><a href={data.video_url} target="_blank">{data.video_url}</a>
          <span>Reference Url</span><a href={data.url} target="_blank">{data.url}</a>
          <p className="m-t-10" dangerouslySetInnerHTML={{ __html: data.overview}}></p>
          <Attachments upload={data.upload}/>
          <div className="font-12 lgrey">{topic.content_form_id} - {topic.record_id}</div>
        </div>
      </Fragment>  
    )
  }

  const BulkUploadTemplate =({topic}) =>{
    return(
      <Fragment>
        <div className="row font-14 p-7">
          <div className="font-16">
            <p>
              First, download <a href="#">this CSV template</a> and use it to upload training content onto this Module
            </p>
            <p>
              Next, <a className="" href="#">upload</a> training content onto this Module using the downloaded CSV template
            </p>
          </div>
          
        </div>
      </Fragment>  
    )
  }

  const Contents = ({module, topic}) => contents.map((a, i) => {
    let data = a.data
    
    const link = {
      pathname:`/career_training/content/content/992/${a.id}?edit=true`, 
    }

    const topicLink = {
      pathname:`/career_training/topic_page/${params.journey_category_id}/${params.journey_profile_id}/${topic.id}`, 
    }

    const styleBorder = {'border': '1px solid lightgrey','boxShadow': 'rgb(0 0 0 / 20%) 0px 4px 8px 0px, rgb(0 0 0 / 19%) 0px 6px 20px 0px'}

    return(
      <div className="row br-d-grey" id={`content_list_${a.record_id}`} key={i} style={contentIndex == a.record_id ? styleBorder : {}}>
        <div className="p-7 m-l-8">
          <div className={contentIndex == a.record_id ? 'font-14 bold':'font-14'} onClick={e=>showTopic(e,a)}>
            <div className="d-flex bold">{data.record_title}</div>
            <span>Reference Url</span><a href={data.url} target="_blank">{data.url}</a>
            <span>Video Url</span><a href={data.video_url} target="_blank">{data.video_url}</a>
            <div className="pull-right">
              {isAdmin &&
                <Fragment>
                  <span className="far fa-trash-alt pull-right m-r-15 lh-unset lgrey m-t-5" 
                    onClick={e=>confirmDelete(e, a, 'content')}/>
                  <Link to={link} className="pull-right m-r-15 m-t-5 lgrey">
                    <i className="far fa-edit font-16" data-tip="Edit"/>
                  </Link>
                </Fragment>
              }
              { a.data.upload && a.data.upload.length > 0 &&
                <span className="p-dropbtn  pull-right m-r-15 m-t-5 lgrey">
                  <i className="fa fa-paperclip font-16" data-tip="Files"/>
                  <span className="badge up bg-lred">{data.upload.length}</span>
                </span>
              }
              <Link to={topicLink} className="pull-right m-r-15 m-t-5 lgrey">
                <i className="fas fa-expand font-16" data-tip="Full Screen"/>
              </Link>
              
              {showJourneyIcons && <Fragment>
                <span className="m-r-15 lgrey m-t-5 pull-right" onClick={e=>addComments(e, a)}>
                  <i className="far fa-comment font-16" data-tip="Chat"></i>
                </span>
                <span className="m-r-15 lgrey m-t-5 pull-right" onClick={e=>addChecklist(e, a)}>
                  <i className="far fa-check-circle font-16" data-tip="Checklist"></i>
                </span>
              </Fragment>}
            </div>  
          </div>
        </div>
      </div>
    )
  })

  const ModulesList = () => modules.map((m, i) => {
    const cardStyle = {}

    try {
      cardStyle.borderLeft = "10px solid "+ m.data.bg_color
    } catch (e) {
      cardStyle.borderLeft = "10px solid #607D8B"
    }

    return(
      <Fragment key={i}>
        <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
          <div className="bg-white border-rounded-10 m-t-10">
            <div className="card-title card-title-gray flex aligncenter p10 p-b-15" style={cardStyle}>
              <div className="flex aligncenter w-100p">
                <Link to="" id="head" className="content">
                  <div className="w-100">
                    <h4 className="fw700 card-heading truncate">{m.data.name}</h4>
                    <p className="f12"><PillDsList list={m.data_source?.subjects} dataKey="label" style="d-block font-12"/>({m.topics && m.topics.length + " Topics" || 0 + " Topics"})</p>
                  </div>
                </Link>
              </div>
              <div className="menu-drop-icon flex justifycenter aligncenter">
                {/* <a href="#">
                  <img src="./images/menu-drop.png" alt="" />
                </a> */}
                {isAdmin && <span onClick={e=>showMenu(e, m)} className="fa fa-bars pull-right"/>}
                {showJourneyIcons && <span onClick={e=>showNoteModal(e, m)} className="m-r-20 fas fa-sticky-note pull-right"/>}
                {showJourneyIcons && <span className="m-r-20 fas fa-plus pull-right"/>}
                {showJourneyIcons && <span className="m-r-20 fas fa-paperclip pull-right"/>}
              </div>
            </div>
            <div className="card-content p10-20">
              <div id={`info_${i}`} className="b-tab-table active scroll-auto ht-250 m-t-5">
                <Topics module={m} topics={(m.topics || [])}/>
                {m.topics && m.topics.length > 3 && <p className="scroll-more pull-right highlight" onClick={(e)=>handleClick(i)}></p>}
              </div>
            </div>
            <div className="card-footer flex justspacebetween p10-20 aligncenter">
              <ul className="flex aligncenter">
                <li className="m-r-25">
                  {isAdmin && selectedModule.id == m.id && 
                    <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedModule} 
                    type="module" MenuItems={menuItems} menuHeight="250px"/>
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
  )})

  const subjectFilter = (e, subject)=>{
    e.preventDefault()
    setSubjectId(subject.value)
  }

  const SubjectFilterModal = ({list, subjectFilter}) =>{
    return(
      <FilterMenuItem list={list} onSelect={subjectFilter}/>
    )
  }

  const toggle = (value) => {
     setFilter(value);
     setSubjectId(value)
  }

  const exist = (value) =>{
    return value === filter 
  }

  const TrainingSubjectFilter = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Types of Subject
        </h5>
        
        <span className="checkbox-container" onClick={e => toggle()}>
          <span>All</span>
          <input type="checkbox" checked={filter === undefined }/>
          <span className="checkbox-checkmark"></span>
        </span>

        <CheckBox list={subjectList.options || []} labelKey="label" valueKey="value" 
          toggle={toggle} exists={exist}/> 
      </div>
    </div>
  )

  return(
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <JourneyTitle title={isAdmin?'Training Manager':'Curriculum'} project={journey_category} />
              {isAdmin && <div className="font-15">{modules.length} Modules, {totalLessons} Lessons</div>}

            </div>

            <div className="flex aligncenter">
              {showJourneyIcons && <NavIcon id="home_icon" dataTip="Back to Journey Home" 
                link={`/career_training/project/portal/${params.journey_category_id}`}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-home"/>}
              
              {isAdmin && <NavIcon id="add_new_module" dataTip="Create New Module" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                link={`/career_training/module/module/988`}
                icon="fas fa-plus"/>
              }

              {isAdmin && <a href="./../../../scripts/csv/topic.csv" target="_blank" data-tip="Topic Template" className="bg-highlight round-btn float-right m-r-5 m-t-4">
                  <i className="fas fa-download"></i>
                </a>
              }

              {isAdmin && <NavIcon id="subject_filter" dataTip="Subject Filter" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={(e) => setOpenSideNav(!openSideNav)}
                icon="fas fa-sliders-h"/>
              }

              {showJourneyIcons && <NavIcon id="file_links" dataTip="Journey Files and Links" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={toggleFileLinkModal} icon="fas fa-link"/>}

              <span data-tip="Toggle Table" className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onClick={e=>setShowTableView(!showTableView)}>
                <i className="fas fa-table"></i>
              </span>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              {modules &&  (
                <Fragment>
                  {!showTableView && <ModulesList/> }

                  {showTableView &&
                      <TrainingModuleListTable modules={modules}  requestParams={requestParams} />
                  }
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div> 
      {isBulkUploadOpen && (
        <GenericModal component={BulkUploadTemplate} title="Bulk upload template" topic={selectedTopic}
          isOpen={isBulkUploadOpen} toggleModal={toggleBulkUploadModal}/>
      )}

      {isTopicOpen && (
        <GenericModal component={TopicDate} title={selectedTopic.data.name} topic={selectedTopic}
          isOpen={isTopicOpen} toggleModal={toggleTopicModal}/>
      )}

      {isCommentOpen && (
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal}
          item={selectedContent} type="carrier_training.content"/>
      )}
      
      {isChecklistOpen && (
        <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal}
          item={selectedContent} type="carrier_training.content"/>
      )}

      {isFileLinkOpen && (
        <JourneyFilesLinksModal toggleModal={toggleFileLinkModal}
          journeyCategory={journey_category} journeyType="training_journey"/>
      )}

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteItem}/>
      {isNoteOpen && <NotesModal toggleModal={toggleNoteModal} type="carrier_training.module" id={selectedModule.id}/>}
      <SideMenuBar helpId="8"/>
      <input type="file" ref={inputFileRef} className="hidden" onChange={e => uploadCsv(e)} />
      
      {openSideNav && 
        <FilterSlider        
        component1={TrainingSubjectFilter}
        openSideNav={openSideNav}
        setOpenSideNav={setOpenSideNav}
        />}     
    </Fragment>
  )
}

export default TrainingModuleTypeList;