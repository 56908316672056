import React from 'react';
import ReactTooltip from 'react-tooltip';

const CueTooltip = (props) => {
	return(
	  <ReactTooltip id={`tooltip_${props.id}`} place="bottom" type="info" effect='solid' className='p10'>
        <span dangerouslySetInnerHTML={{ __html: props.description }}></span>
      </ReactTooltip>
	)
}

const CTTooltip = (props) => {
	return(
		<ReactTooltip place="bottom" className='p10' overridePosition={ ({ left, top }, currentEvent, currentTarget, node) => {
			const d = document.documentElement;
			left = Math.min(d.clientWidth - node.clientWidth, left);
			top = Math.min(d.clientHeight - node.clientHeight, top);
			left = Math.max(0, left);
			top = Math.max(0, top);
			return { top, left }
		}}/>
	)
}

const CueTip = ({tooltip, positionCss}) => (
	<span className={`tooltiptext ${positionCss?positionCss:'top'}`}>{tooltip}</span>
)

export {CueTooltip, CTTooltip, CueTip};