import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import Cs from "../../../services/CommonService";
import CheckAccess from "../Roles/CheckAccess";
import ReactTooltip from "react-tooltip";

const Role = (props) => {
  let { invited_user, rolesObj, share, currentUser, journey } = props;
  invited_user.updated_at = Cs.formatUpdateDate(invited_user.updated_at)
  let isEditable = CheckAccess.isInviteEditable(currentUser, journey, invited_user, share)

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 m-t-10">
        <div className="card-title card-title-gray flex aligncenter p10 p-b-15">
          <div className="flex aligncenter">
              <div className="w-100">
                <h4 className="fw700 card-heading truncate">{invited_user.email}</h4>
                <p className="f12">by {invited_user.author.name} since {invited_user.updated_at}</p>
              </div>
          </div>
          <div className="menu-drop-icon flex justifycenter aligncenter">
            {/* <a href="#">
              <img src="./images/menu-drop.png" alt="" />
            </a> */}
          </div>
        </div>
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto user-role-list-left ht-150 m-t-5">
            <div className="col-md-12 col-xs-12 ht-120">
              {invited_user.role_ids && invited_user.role_ids.length>0 && (
                <div className="">
                  Roles
                  {invited_user.role_ids.map((role_id, i) => (
                    <span key={i} className="pill bg-highlight white"
                      data-tip={rolesObj[role_id] && rolesObj[role_id].role_description}>{rolesObj[role_id] && rolesObj[role_id].role}</span>
                  ))}
                </div>
              )}

              {invited_user.access_type == 'full_access' && (
                <div className="">
                  <span className="pill bg-highlight white" data-tip="All permissions are identical to that of the journey owner, including, adding or removing other users with Full Access">
                    Full Access
                  </span>
                </div>
              )}

              <img src="images/avatars/2s.png" alt="img" className="shadow-small pull-left bg-blue2-dark"/>
            </div>

            <div className="col-md-12 col-xs-12">
              <div className="text-cap role" style={{ display: "inline" }}>
                  <div className="text-cap role">
                    <a className="black">
                      {invited_user.message && invited_user.message.substring(0, 110)}
                    </a>
                    <span>
                      <strong>Email</strong>
                      <a>
                        <span className={invited_user.is_email_sent ? "far fa-check-circle" : "far fa-circle"}></span>
                      </a>
                    </span>
                    <span>
                      <strong>Visit</strong>
                      <a>
                        <span className={invited_user.is_invite_link_visited? "far fa-check-circle": "far fa-circle"}></span>
                      </a>
                    </span>
                    <span>
                      <strong>Join</strong>
                      <a>
                        <span className={invited_user.joined_from_invite? "far fa-check-circle": "far fa-circle"}></span>
                      </a>
                    </span>
                  </div>
                </div>
            </div>
            <div className="col-md-12 col-xs-12">
              <div className="pull-left">
                <span>{invited_user.activity_count} Activities</span>&emsp;|&emsp;
                <span>{invited_user.comments_count} Messages</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          {isEditable &&
            <ul className="flex aligncenter">
              <li className="m-r-25"
                onClick={(e) => {props.setEdit(e, invited_user);}}>
                <i className="far fa-edit font-18 text-muted"></i>
                Edit
              </li>
              <li className="action-btn bg-highlight"
                onClick={(e) => {props.selectInviteUser(e, invited_user);}}>
                <i className="far fa-trash font-18 text-muted"></i>
                Delete
              </li>
            </ul>
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Role;
