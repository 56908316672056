import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cs from "../../../../../services/CommonService";
import HighSchoolStepActivity from "../../StepActivities/StepActivities";
import AddToFavourite from "../../../../Common/AddToFavourite"
import ReactTooltip from "react-tooltip";
import Timeline from '../TimeLineComponent/HrTimeline';
import Tabs, { TabPane } from 'rc-tabs'
import {FormFieldTable, RenderData} from "../../../UserRoles/NotificationPill"

const Activity = (props) => {
  const [selectStepId, setSelectedStepId] = useState();

  let eduJourneyType = props.dataSource.fj_edu_entry_type.options;
  let cardStyle = {};
  let activity = props.activity;
  let data = activity.data;
  let data_source = activity.data_source || {};
  let ds = props.dataSource.fj_edu_entry_type.options;
  activity.updated_at = Cs.formatUpdateDate(
    activity.updated_at,
    "MMM DD YYYY"
  )

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!data) return null;  
  try {
    cardStyle.backgroundColor = eduJourneyType[activity.data.fj_edu_entry_type].header_color;
  } catch (e) {
    cardStyle.backgroundColor = '#607D8B'
  }

  let userRoleLink = {
    pathname: `/journey/users/${activity.journey_category_id}/${activity.education_journey_profile_id}/education_journal_entry/${activity.id}`,
    state: { journey: props.journeyCategory, activity: activity },
  }

  const category = (key) => {
    let obj = ds && ds.find(o => o.value === key);
    return (
      <div>
        <i className={obj.icon + ' m-r-10'} style={{color:obj.icon_color}}></i>
        {obj.label}
      </div>
    );
  }

  const SpanList = ({name, label, styleName}) => data_source[name] && data_source[name].map((d, i) => (
    <span className={styleName +' m-l-5'} key={i}>{d.data[label]}</span>
  ))

  const list = (key) => {
    return <div>{data_source[key] && data_source[key].label}</div>;
  }

  const Favourite = () =>(
	  <AddToFavourite favouriteId={activity.favourite_id}
	   favorableType="education_journal_entry"
	   favorableId={activity.id}
	   journeyCategoryId={activity.journey_category_id}
	   classNameActive="pin-40 green" classNameDisable="pin-40 white"
	   journeyProfileId={activity.education_journey_profile_id}/>
  )

  const Footer = () => (
    <div className="card-footer flex justspacebetween aligncenter">
      <ul className="flex aligncenter">
        <li className="m-l-10 m-r-25" id={`comment_${props.index}`}
          onClick={(e) => {props.addNotes(e, activity)}}>
          <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
          <span className="badge2 up bg-lgrey">{activity.comments_count}</span>
        </li>
        <li className="m-r-25" id={`check_list_${props.index}`}
          onClick={(e) => {props.addChecklist(e, activity)}}>
          <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
          <span className="badge2 up bg-lgrey">{activity.check_list_count}</span>
        </li>
        <li className="m-r-25" id={`mail_${props.index}`}>
          <Link to={`/journey/incoming/emails/education_journal_entry/${activity.id}`}>
            <i className="far fa-envelope font-18 text-muted" data-tip="Mail"></i>
            <span className="badge2 up bg-lgrey">{activity.mail_count}</span>
          </Link>
        </li>

        {activity.empty_fields_count != 0 &&
          <li className="m-r-25">
            <Link to={`/highschool/activity/edit/${activity.journey_category_id}/${activity.education_journey_profile_id}/${activity.id}?form_wizard_schedule_id=0&form_template_id=${activity.activity_form_id}&item_id=${activity.id}&item_type=education_journal_entries`}>
              <i className="far fa-lightbulb font-18" data-tip="Suggestions"></i>
              <span className="badge2 up bg-lgrey">{activity.empty_fields_count}</span>
            </Link>
          </li>
        }
      </ul>
      <props.menu activity={activity} />
    </div>
  )

  const Header = ({}) =>(
    <Fragment>
      <div className="card-title-gray flex aligncenter p10 p-b-15" style={cardStyle}>
        <div className="w-100">
          <h4 className="fw700 card-heading truncate">{data.name || activity.activity_type}</h4>
          <p className="f12">since {activity.updated_at}</p>
        </div>
        <div className="menu-drop-icon">
          {props.showIcons && 
            <Fragment>
              {/* {!props.popUpView && <i className="fas fa-expand top-3 pull-right white expand m-r-5" data-tip="Full Screen"
                onClick={(e) => props.routeActivity(activity)}></i>} */}
              <Link className="white expand p-top-0 pull-right" to={userRoleLink}>
                <i className="fa fa-users" data-tip="Users"></i>
              </Link>
              <Favourite />
            </Fragment>
          }
        </div>
      </div>
      {activity.activity_responses &&
        <div className="p-0-20">
          <Timeline progressList={activity.activity_responses} 
          setSelectedStepId={setSelectedStepId}/>
        </div>
      }
    </Fragment>
  )

  const DateField = () =>(
    <tr>
      <td>
        <strong className="lgrey">
          Start Date 
        </strong>
        <div>
         { activity.data.start_date && activity.data.start_date}
        </div>
      </td>
      <td>
        <strong className="lgrey">
          End Date 
        </strong>
        <div>
          {activity.data.end_date && activity.data.end_date}
        </div>
      </td>
    </tr>

  )

  const Notes = () =>(
    <tr>
      <td colSpan="2">
        <strong className="lgrey">Notes</strong>
        <div dangerouslySetInnerHTML={{ __html: data.notes }}></div>
      </td>
    </tr>
  )
  
  const PlannerFlag = () =>(
    <div className="red m-r-5" >
      added from planner
    </div>
  )
  
  const Card = () => {
    return(
      <div className="bg-white border-rounded-10 m-b-10">
        <Header/>
        <div className="card-content p10 ht-250 scroll-auto">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
              <div className="b-tab-table active m-t-5">
                {activity. planner_list_id && <PlannerFlag/>}
                <div className="border-dashed-sm p-b-10 m-b-5">
                  {activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}
                </div>
                <FormFieldTable data={data} labelClass="f15"
                  editFormField={props.editActivityField}
                  formFields={props.formFields[activity.activity_form_id]} 
                  dataSource={data_source}/>
              </div>
            </TabPane>
            <TabPane tab="Steps" key="2">
              <HighSchoolStepActivity 
                selectStepId={selectStepId} 
                activity={activity} 
                activityTypeList={ds} 
                stepActivities={activity.education_activities}/>
            </TabPane>
          </Tabs> 
        </div>
        <Footer/>
      </div>    
    )
  }

  const HonorCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
            <Tabs defaultActiveKey='1'>
              <TabPane tab="Details" key="1">
                <table className="table card-table">
                  <tbody>
                    { activity. planner_list_id && <PlannerFlag />}
                    <tr>
                      <td>
                        <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                      </td>
                      <td>
                        <strong className="lgrey">Grade</strong>
                        <div>
                          {activity.data_source.grade &&
                            activity.data_source.grade.label}
                        </div>
                      </td>
                    </tr>
                    <DateField />
                    <tr>
                      <td colSpan="2">
                        <strong className="lgrey">Recognition</strong>
                        <div>{data.title_recognition}</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <strong className="lgrey">Url</strong>
                        <div>{data.url}</div>
                      </td>
                    </tr>
                    <Notes />
                  </tbody>
                </table>    
              </TabPane>

              <TabPane tab="Steps" key="2">
                <HighSchoolStepActivity 
                selectStepId={selectStepId} 
                activity={activity} 
                activityTypeList={ds} 
                stepActivities={activity.education_activities}/>
              </TabPane>
            </Tabs> 
          </div>
        </div>
        <Footer/>
      </div>
    )
  }

  const ResearchCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
              <table className="table card-table">
                <tbody>
                  { activity. planner_list_id && <PlannerFlag />}
                  <tr>
                    <td>
                      <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                    </td>
                    <td>
                      <strong className="lgrey">Grade</strong>
                      <div>
                        {activity.data_source.grade &&
                          activity.data_source.grade.label}
                      </div>
                    </td>
                  </tr>
                  <DateField />
                  <tr>
                    <td colSpan="2">
                      <strong className="lgrey">Research</strong>
                      <div>{activity.data_source.research_pub_subject.label}</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <strong className="lgrey">Research Url</strong>
                      <div>{data.research_pub_url}</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <strong className="lgrey">Notes</strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Cs.subString(data.research_pub_notes),
                        }}
                      ></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TabPane>
            <TabPane tab="Steps" key="2">
              <HighSchoolStepActivity selectStepId={selectStepId} 
              activity={activity} activityTypeList={ds} 
              stepActivities={activity.education_activities} />
            </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const ScholarCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
              <table className="table card-table">
                <tbody>
                  { activity. planner_list_id && <PlannerFlag />}
                  <tr>
                    <td colSpan="2">
                      <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                    </td>
                  </tr>
                  <DateField />
                  <tr>
                    <td colSpan="2">
                      <strong className="lgrey">Amount</strong>
                      <div>{activity.data.amount}</div>
                    </td>
                  </tr>
                  <Notes />
                </tbody>
              </table>
            </TabPane>
            <TabPane tab="Steps" key="2">
              <HighSchoolStepActivity selectStepId={selectStepId} 
              activity={activity} activityTypeList={ds} 
              stepActivities={activity.education_activities} />
            </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const TestingCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
              <table className="table card-table">
                <tbody>
                  { activity. planner_list_id && <PlannerFlag />}
                  <tr>
                    <td>
                      <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                    </td>
                    <td>
                      <strong className="lgrey">Grade</strong>
                      <div>
                      {activity.data_source.grade &&
                        activity.data_source.grade.label}
                      </div>
                    </td>
                  </tr>
                  <DateField />
                  <tr>
                    <td colSpan="2">
                      <strong className="lgrey">
                        Name
                      </strong>
                      <div>{activity.data_source.type_of_test &&
                          activity.data_source.type_of_test.label}</div>
                    </td>
                  </tr>
                  <Notes />
                </tbody>
              </table>
            </TabPane>
            <TabPane tab="Steps" key="2">
              <HighSchoolStepActivity selectStepId={selectStepId} 
              activity={activity} activityTypeList={ds} 
              stepActivities={activity.education_activities} />
            </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const CourseCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td>
                  <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
                <td>
                  <strong className="lgrey">Grade</strong>
                  <div>
                  {activity.data_source.grade &&
                    activity.data_source.grade.label}
                  </div>
                </td>
              </tr>
              <DateField />
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Course Type</strong>
                  <div>
                    {activity.data_source.course &&
                      activity.data_source.course.label}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Level</strong>
                  <div>
                    {activity.data_source.course_level &&
                      activity.data_source.course_level.label}
                  </div>
                </td>
              </tr>
              <Notes />
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} 
            activity={activity} activityTypeList={ds} 
            stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const SummerCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td>
                  <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
                <td>
                  <strong className="lgrey">Grade</strong>
                  <div>{data.grade}</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Junior Notes: {data.junior && data.junior}
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  Senior Notes: {data.senior && data.senior}
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                 Sophomore Notes: {data.sophomore && data.sophomore}
                </td>
              </tr>
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} activity={activity} 
            activityTypeList={ds} stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const AthleticsCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header/>
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td>
                  <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
                <td>
                  <strong className="lgrey">Grade</strong>
                  <div>{data.grade}</div>
                </td>
              </tr>
              <DateField />
              <tr>
                <td>
                  <strong className="lgrey">Sport</strong>
                  <div>
                    {data_source.sport && data_source.sport.label}
                  </div>
                </td>
                <td>
                  <strong className="lgrey">Role</strong>
                  <div>
                    {activity.data_source.role &&
                      activity.data_source.role.label}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Commitment</strong>
                  <div>
                    {activity.data_source.time_commitment &&
                      activity.data_source.time_commitment.label}
                  </div>
                </td>
              </tr>
              <Notes />
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} 
            activity={activity} activityTypeList={ds} 
            stepActivities={activity.education_activities} />
          </TabPane>  
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const OtherCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">

          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td colSpan="2">
                </td>
              </tr>
              <DateField />
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Date</strong>
                  <div>{data.start_date}</div>
                </td>
              </tr>
             <Notes />
            </tbody>
          </table>
          </div>
          <HighSchoolStepActivity selectStepId={selectStepId} activity={activity} activityTypeList={ds} stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const CompetitionCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
            <Tabs defaultActiveKey='1'>
              <TabPane tab="Details" key="1">
              <table className="table card-table">
                <tbody>
                  { activity. planner_list_id && <PlannerFlag />}
                  <tr>
                    <td>
                      <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                    </td>
                    <td>
                      <strong className="lgrey">Grade</strong>
                      <div>{data.grade}</div>
                    </td>
                  </tr>
                  <DateField />
                  <tr>
                    <td colSpan="2">
                      <strong className="lgrey">Subject</strong>
                      <div>
                        {activity.data_source.edu_competition_subject &&
                          activity.data_source.edu_competition_subject.label}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <strong className="lgrey">Notes</strong>
                      <div>{data.acad_recog_notes}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TabPane>
            <TabPane tab="Steps" key="2">
              <HighSchoolStepActivity selectStepId={selectStepId} activity={activity} 
              activityTypeList={ds} stepActivities={activity.education_activities} />
            </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }

  const ArtsCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td>
                  <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <td>
                <strong className="lgrey">Art</strong>
                <div>
                  {data_source.creative_name && data_source.creative_name.label}
                </div>
              </td>
              <DateField />
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Time Commitment</strong>
                  <div>{list("time_commitment")}</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Role</strong>
                  <div>{list("role")}</div>
                </td>
                <td></td>
              </tr>
              <Notes />
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} activity={activity} 
            activityTypeList={ds} stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }

  const EnrichCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td colSpan="2">
                  <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <DateField />              
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Category</strong>
                  <div>{list("category")}</div>
                </td>
              </tr>
              <Notes />
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} activity={activity} 
            activityTypeList={ds} stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const ClubCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td colSpan="2">
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <DateField />
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Role</strong>
                  <div>{list("role")}</div>
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Club Type</strong>
                  <div>{list("club_type")}</div>
                </td>
              </tr>
              <Notes />
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} 
            activity={activity} activityTypeList={ds} 
            stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const JobInternCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td colSpan="2">
                  <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <DateField />
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Department</strong>
                  <div>{list("department")}</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Url</strong>
                  <div>{data.org_url}</div>
                </td>
              </tr>
              <Notes />
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} 
            activity={activity} activityTypeList={ds} 
            stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const CollegeApplication = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td colSpan="2">
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">General Campus Visit</strong>
                  <div>
                    <SpanList name="general_campus_visit" label="label" styleName="pill text-left"/>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <strong className="lgrey">Questions Asked</strong>
                  <div>
                    <SpanList name="questions_to_ask" label="label" styleName="pill text-left"/>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Notes: {data.key_notes}
                </td>
              </tr>
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} 
            activity={activity} activityTypeList={ds} 
            stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  const CoursePlanCard = () => {
    return (
      <div className="bg-white border-rounded-10">
        <Header />
        <div className="card-content">
          <div className="b-tab-table active scroll-auto ht-250">
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
          <table className="table card-table">
            <tbody>
              { activity. planner_list_id && <PlannerFlag />}
              <tr>
                <td colSpan="2">
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  Junior Notes: {data.junior && data.junior}
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  Senior Notes: {data.senior && data.senior}
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                 Sophomore Notes: {data.sophomore && data.sophomore}
                </td>
              </tr>
            </tbody>
          </table>
          </TabPane>
          <TabPane tab="Steps" key="2">
            <HighSchoolStepActivity selectStepId={selectStepId} 
            activity={activity} activityTypeList={ds} 
            stepActivities={activity.education_activities} />
          </TabPane>
          </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  return <Card/>

  /*if (activity.activity_form_id == 295) {
    return <HonorCard />;
  } else if (activity.activity_form_id == 282) {
    return <ResearchCard />;
  } else if (activity.activity_form_id == 296) {
    return <ScholarCard />;
  } else if (activity.activity_form_id == 294) {
    return <TestingCard />;
  } else if (activity.activity_form_id == 293) {
    return <CourseCard />;
  } else if (activity.activity_form_id == 356) {
    return <SummerCard />;
  } else if (activity.activity_form_id == 300) {
    return <AthleticsCard />;
  } else if (activity.activity_form_id == 193) {
    return <OtherCard />;
  } else if (activity.activity_form_id == 290) {
    return <CompetitionCard />;
  } else if (activity.activity_form_id == 297) {
    return <ArtsCard />;
  } else if (activity.activity_form_id == 302) {
    return <EnrichCard />;
  } else if (activity.activity_form_id == 298) {
    return <ClubCard />;
  } else if (activity.activity_form_id == 292) {
    return <JobInternCard />;
  } else if (activity.activity_form_id == 146) {
    return <CollegeApplication />;
  } else if (activity.activity_form_id == 2) {
    return <CoursePlanCard />;
  } else {
    return null;
  }*/
}

export default Activity;
