import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import useStyle from '../../hooks/useStyle'
import CommonService from "../../services/CommonService"
import useEscape from '../../hooks/useEscape'

const FilterModal = ({ component1: Component1, component2:Component2, component3:Component3, component4:Component4, ...props }) => {
  useStyle('add_role_dialog')
  useStyle('filter_dialog')
  useEscape(props.toggleModal)
  
  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className={`ml-modal-content ml-card-4 ml-animate-zoom ${props.widthCss}`}>
          
          {props.title &&
            <div className="flex justspacebetween modal-title">
              <h4 className="f18 text-white fw500 p12-10-15">{props.title}</h4>
                <button className="btn-close"
                  title="Close Modal" onClick={e=>props.setOpenSideNav(e)}>×
                </button>
            </div>
          }

          {props.isPopupMode && 
            <button className="btn-close"
              title="Close Modal" onClick={e=>props.setOpenSideNav(e)}>×
            </button>
          }

          {Component1 && <Component1 {...props} />}
          {Component2 && <Component2 {...props} />}
          {Component3 && <Component3 {...props} />}
          {Component4 && <Component4 {...props} />}
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default FilterModal;