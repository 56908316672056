import React ,{Fragment, useState} from "react"
import moment from '../../../node_modules/moment/min/moment.min.js'
import {Link} from "react-router-dom"
import Cs from '../../services/CommonService'
import env from "../../env"
import {FormFieldTable, RenderData} from "../Journey/UserRoles/NotificationPill"
import { ChatGrayIcon, HamburgerMenuIcon } from "../Common/ImageIcons.js"

const ProtocolCard = (props) => {
  let {protocol, formFieldsList, datasource} = props
  let {data, data_source} = protocol
  let formFields = formFieldsList[protocol.form_id] || []

  const Card = () =>(
    <div className="bg-white border-rounded-10 m-t-10">
      <div className="card-title card-title-gray flex aligncenter p10 p-b-15">
        <div className="flex aligncenter">
          <a id="head" className="content">
            <div className="w-100">
              <h4 className="fw700 card-heading">{data.title || data.record_title}</h4>
              <p className="f12">since {Cs.formatUpdateDate(protocol.created_at)}</p>
            </div>
          </a>
        </div>
      </div>
      <div className="card-content p10-20">
        <div className="b-tab-table active scroll-auto ht-250 m-t-5">
          <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
        </div>
      </div>
      <div className="card-footer flex justspacebetween p10-20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">
            <ChatGrayIcon onClick={e=>props.addNotes(e, protocol)}/>
          </li>
        </ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, protocol)}/>
      </div>
    </div>
  )

  if(!data)return null;

  return (<Card/>)
    
}

export {ProtocolCard};