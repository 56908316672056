import React, { useState, Fragment } from "react";

const ImagePlaceholder = ({url, imageClass}) => {

  const [loaded, setLoaded] = useState(false);
  const imageStyle = !loaded ? { display: "none" } : {};

  const handleImageLoaded = () => {
    setLoaded(true);
  }

  const DefaultSvg = () =>(
    <div className="text-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="100%" viewBox="0 0 300 150">
        <rect fill="#ddd" width="300" height="150"/>
        <text fill="rgba(0,0,0,0.5)" fontFamily="sans-serif" fontSize="30" dy="10.5" fontWeight="bold" x="50%" y="50%" textAnchor="middle">Loading...</text>
      </svg>
    </div>
  )
  
  return(
    <Fragment>
      {!loaded &&  <DefaultSvg/>}
      <img src={url} style={imageStyle} className={imageClass} onLoad={e=>handleImageLoaded()} />
    </Fragment>
  )
}

export default ImagePlaceholder;