import React, {useState, Fragment, useEffect,useContext,useRef} from "react";
import {Link} from "react-router-dom";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Activity from '../../Activities/List/Activity';
import ActivityService from "../ActivityService";
import FavouritesService from "../../../../../services/FavouritesService";
import Pagination from '../../../../Pagination'
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from '../../../../../constants/FormTemplateList';
import CommentsModal from "../../../../Modals/CommentsModal";
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import useStyle from '../../../../../hooks/useStyle';
import CheckAccess from '../../../Roles/CheckAccess';
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import HelpMessageList from '../../../../../constants/HelpMessageList'
import {MenuItem,CardMenuInfo,NavIcon,CardMainMenu} from '../../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import CommonService from "../../../../../services/CommonService";
import ReactTooltip from "react-tooltip";
import Tabs, { TabPane } from 'rc-tabs'
import ActivityTypeMenu from "./ActivityTypeMenu"
import Suggestions from "../../CareerPortalPage/Suggestions"
import FilterSlider from "./../../../../Common/FilterSlider"
import VideoBookmark from "../../../../Common/VideoBookmark"
import GenericModal from "../../../../Modals/GenericModal"

let requestParams = {}
let selectedActivity = {};
let dataSource = {};
let journeyCategory = null;
let cardViewCount = 1;

const ActivityList = (props) => {
  useStyle('card');
  useStyle('card_table');
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let project = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
  let cardGridCss = "col-xs-12";
  let cardHeightCss = "ht-400";
  if (queryParam.id || props.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-7";
    cardHeightCss = "mih-380";
    queryParam.id = queryParam.id || props.id;
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [activityList, setActivityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState();
  const [isFavourite, setIsFavourite] = useState(false);
  const [cueSuggestions, setCueSuggestions] = useState({});
  const indexEle = useRef(null);

  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const { isOpen:isActivityTypeOpen, toggleModal:toggleActivityTypeModal } = useModal()
  const { isOpen:isPlannerOpen, toggleModal:togglePlannerModal } = useModal();
  const [formFieldsList, setFormFieldsList] = useState({})
  const { isOpen:isBookMarkOpen, toggleModal:toggleBookMarkModal } = useModal()

  useEffect(() => {
    journeyCategory = null;
    requestParams = {
      'data': {},
      'data_source_params': {
        'data_source_id':[3344],
        'response_type':'object'
      },
      'form_field_params': {
        'ids':[1024, 1017, 1018, 1015, 1016, 1025, 1016, 1014]
      },
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'id':queryParam.id
    }

    getActivityList();
    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage]);

  let getActivityList = () => { 
    setLoading(true)
    ActivityService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.meta.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        journeyCategory = res.data.journey_category;
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount);
        CheckAccess.userAccess(currentUser, journeyCategory, res.data.invited_journey_member);
        setFormFieldsList(res.data.form_fields || {})
        if(requestParams.search){
          setActivityList(res.data.activities)
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setActivityList(res.data.activities);
        }else{
          setActivityList([...activityList, ...res.data.activities]);  
        }
        setCueSuggestions(res.data.cue_suggestions)
        setLoading(false)
        res.data.activities.length == 0 && toggleHelpMessage()
      }
    })
  }

  const SuggestionsCard = () =>{
    const cardMarginCss = screen.xs?'':'card-container'
    return(
      <div className="col-sm-12 col-sm-6 col-md-5">
        <div className={cardMarginCss}>
          <div className="ml-center bg-lgreen white p-5">
            <div className="font-16 bold-600">Suggestions</div>
          </div>
          
          <Suggestions history={props.history} journey={journeyCategory} 
            journeyProfile={journeyCategory?.education_journey_profiles}
            suggestions={cueSuggestions.suggestions} dataSource={props.dataSource}  
            currentUser={currentUser} style={{height:'484px'}} activity={activityList[0]}
            cueSuggestions={cueSuggestions} />
        </div>
      </div>
    )
  }

  const ActivityPage = () =>{
    if(screen.xs){
      return(
        <Tabs defaultActiveKey="1" className="card-container">
          <TabPane tab="Activity" key="1">
            {activityCard}
          </TabPane>
          <TabPane tab="Suggestions" key="2">
            <SuggestionsCard/>
          </TabPane>
        </Tabs>  
      )
    }else{
      return(
        <>
          {activityCard}
          <SuggestionsCard/>
        </>
      )
    }
  }

  const PlannerMenu = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Planner
        </h5>
        
        <span className="checkbox-container">
          <span>All</span>
          <input type="checkbox"/>
          <span className="checkbox-checkmark"></span>
        </span>
      </div>
    </div>
  )

  const pinFavourite = (e) =>{
    e.preventDefault()
    //setIsFavourite(!isFavourite)
  }

  const addChecklist = (e, a) => {
    selectedActivity = a;
    toggleChecklistModal(e);
  }

  const addNotes = (e, a) => {
    selectedActivity = a;
    toggleCommentModal(e);
  }

  const addBookMark =(e, a) =>{
    selectedActivity = a;
    toggleBookMarkModal(e)
  }

  const deleteActivity = (e) => {
    e.preventDefault();
    let req = {id:selectedActivity.id, journey_category_id:params.journey_category_id}
    ActivityService.delete(req.id, req).then((res)=>{
      if(res.status==204){
        setActivityList(activityList.filter(i => i.id !== selectedActivity.id))
      }
    })
    toggleDeleteModal(e);
  }

  const showMenu = (e, a) => {
    selectedActivity = a;
    toggleMenuModal(e);
  }

  const routeActivity = (activity) =>{
    props.history.push(`/career/activities/${params.journey_category_id}/${params.journey_profile_id}?id=${activity.id}`)
    setActivityList(activityList.filter(i => i.id == activity.id))
  }

  const menuItems = ({item}) =>{
    const title = item.activity_type && item.activity_type.replace(/\s/g, '_')
    const editAccess = CheckAccess.hasEditAccess(currentUser, journeyCategory, item);

    return(
      <Fragment>
        <CardMenuInfo formId={item.activity_form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

        {editAccess && 
          <MenuItem id="edit_icon" 
            link={`/career/activity/form/${item.journey_category_id}/${item.journey_profile_id}/${item.activity_type}/${item.activity_form_id}/${item.id}?edit=${editAccess}`}
            icon="far fa-edit" action="Edit"/>
        }
        
        {CheckAccess.hasDeleteAccess(currentUser, journeyCategory, item) && 
          <MenuItem id="delete_icon"  icon="far fa-trash-alt" action="Delete"
            onSelect={(e) => toggleDeleteModal(e)}/>
        }

        {editAccess &&  
          <MenuItem id="add_user_icon" 
            link={{
              pathname:`/journey/user_role/${item.journey_category_id}/${item.journey_profile_id}/career_journey.activity/${item.id}`, 
              search:`?title=${item.data.record_title}`,
              state:{journey:journeyCategory, activity:item} 
            }}
            icon="fa fa-users" action="Add User"/>
        }

        <a href={`mailto:${title}-EA-${item.record_id}@cuetree.com`} target="_blank"> 
          <i className=" font-15" aria-hidden="true"></i>
          <span className="font-13">{`${title}-EA-${item.record_id}@cuetree.com`}</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({activity}) => (
    <CardMainMenu activity= {activity} showMenu={showMenu} />
  )

  const activityCard = activityList && activityList.map((a, i) => (
    <SwiperSlide key={i}>
      <div className={cardGridCss}>
        <Activity addNotes={addNotes} journeyCategory={journeyCategory} 
        addChecklist={addChecklist} addBookMark={addBookMark} activity={a}  dataSource={dataSource} menu={Menu}
        cardHeightCss={cardHeightCss} routeActivity={routeActivity} index={i} 
        popUpView={props.isPopupView} formFieldsList={formFieldsList}/>
        {selectedActivity.id == a.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={selectedActivity} type="career_journey.activity" MenuItems={menuItems} 
          menuHeight="300px"/>
        }
      </div>
    </SwiperSlide>
  ))
  
  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }

  if(queryParam.id && !loading && activityList.length == 0){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Not Found" message="Sorry, this message was deleted by the author"/>)
  }

  return (
    <Fragment>
      {!props.id &&
        <div className="content top-10">
          <div className="col-md-4">
            <JourneyTitle title={"Activities"} project={journeyCategory} />
          </div>
          
          <div className="col-md-8 float-right">
            <NavIcon id="home_icon" dataTip="Back to Journey Home" 
              link={`/career/path/${params.journey_category_id}`}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-home"/>

            {queryParam.id  && 
              <NavIcon id="back_icon" dataTip="Back to Activity" 
                onSelect={e=>props.history.goBack()}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-arrow-left"/>
            }

            {CheckAccess.hasEditAccess(currentUser, journeyCategory, {}) && 
              <NavIcon id="add_new_activity" dataTip="Create New Activity" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              onSelect={toggleActivityTypeModal} icon="fas fa-plus"/>
            }

            <NavIcon id="planner_items" dataTip="Planners" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={togglePlannerModal} icon="fas fa-sliders-h"/>
          </div>
          <div className="clear"></div>
        </div>
      }

      <div className="page-content row">
        {!queryParam.id && <SwiperContainer currentpage={currentpage} 
          setCurrentpage={setCurrentpage} requestParams={requestParams} 
          indexEle={indexEle} cardViewCount={cardViewCount}>
            {activityCard}
          </SwiperContainer> 
        }
        {queryParam.id && <ActivityPage/>}
        {!queryParam.id && <div className="col-md-12 col-sm-12 text-center">
            <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
          </div>
        } 
      </div>
      
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedActivity} type="career_journey.activity"/>}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedActivity} type="career_journey.activity"/>}
      {isActivityTypeOpen && <ActivityTypeMenu journey_category_id={params.journey_category_id} journey_profile_id={params.journey_profile_id} params={dataSource} 
      isOpen={isActivityTypeOpen} toggleModal={toggleActivityTypeModal} />}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity}/>
      <SideMenuBar helpId="8"/>
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      )}
      {isPlannerOpen && 
        <FilterSlider component1={PlannerMenu} openSideNav={isPlannerOpen} setOpenSideNav={togglePlannerModal}/>
      }
      {isBookMarkOpen && <GenericModal title="Video BookMark" component={VideoBookmark} toggleModal={toggleBookMarkModal} selectedTag={selectedActivity}/>}

    </Fragment>
  )

}

export default ActivityList;