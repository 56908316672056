import React, {PureComponent, Fragment, useState,useEffect} from "react";
import {Link} from "react-router-dom";
import moment from 'moment'
import importScript from '../../hooks/importScript'

let chartObj = {}

const BarChart = ({id, chart_type, height, series, x_axis, x_axis_label, y_axis_label, colors, className, title, tooltip, events, data}) => {
  
  if(chartObj['chart_'+id]==null){
    chartObj['chart_'+id] = {}
  }

  useEffect(() => {
    importScript('/scripts/apexcharts/apexcharts.min.js', setChart, window.ApexCharts);
    if(chart_type == 'bar'){
      chartObj['options_'+id] = {
        series: series,
        chart: {
          type: 'bar',
          height: 250,
          background: '#fff',
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            barHeight: '90%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: colors,
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
          },
          offsetX: 0,
          dropShadow: {
            enabled: false
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: x_axis,
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: title,
        tooltip: {
            theme: 'dark',
            x: {
              show: false
            },
            y: {
              title: {
                formatter: function () {
                  return ''
                }
              }
          }
        }
      };
    }else if(chart_type == 'pie'){
      chartObj['options_'+id] = {
        series: series[0].data,
        chart: {
          height: 250,
          type: 'pie',
          toolbar: {
            show: false
          },
        },
        labels: x_axis,
        /*responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]*/
      }
    }else if(chart_type == 'treemap'){
      chartObj['options_'+id] = {
        series: series,
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            show: false
          },
        },
        title: title,
      }
    }else if(chart_type == 'line'){
      chartObj['options_'+id] = {
        id:id,
        series: series,
        chart: {
          height: height || 350,
          type: 'line',
          background:"#fff",
          toolbar: {
            show: false
          },
        },
        colors: colors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'smooth',
          width: 3,
        },
        grid: {
          borderColor: '#e7e7e7',  
        },
        xaxis: {
          type: 'category',
          categories: x_axis,
          tickAmount:5,
          title: {
            text: x_axis_label,
          },
        },
        yaxis:{
          type:'numeric',
          title: {
            text: y_axis_label
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: false,
        },
      }
    }else if(chart_type == 'custom'){
      chartObj['options_'+id] = data
    }  

    return () => {
      chartObj['chart_'+id].destroy && chartObj['chart_'+id].destroy()
      chartObj['chart_'+id] = {}
    }
  }, [id])

  function setChart(){
    setTimeout(() => {
      console.log(id)
      console.log(chartObj['options_'+id].series)
      
      chartObj['chart_'+id] = new window.ApexCharts(document.querySelector(`#chart_${id}`), chartObj['options_'+id]);
      chartObj['chart_'+id] && chartObj['chart_'+id].render();  
    }, 0);
  }

  return(
    <div className={className}>
      <div id={'chart_'+id}></div>
    </div>
  )
 
}

export default BarChart;