import React, {useState, useEffect} from 'react'
import {CueTooltip} from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'
import Cs from '../../../services/CommonService'
import {TDTextInput, TDInputSelect, TDInputMultiSelect, TDInputRadio} from '../../Common/FormInput'
import {Image} from '../../../components/Common/Image'

const InputTableView = ({field, screen, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)
  let [data, setData] = useState(formData[field.client_id] || {})
  const [dataSourceObject, setDataSourceObject] = useState({})

  useEffect(() => {
      if(field?.row_data_source_id){
        const dataSourceIds = []
        for (const r in field.row_col_config) {
          for (const c in field.row_col_config[r]) {
            const column = field.row_col_config[r][c]
            if(column?.data_source_id){
              dataSourceIds.push(column.data_source_id)
              const df = column.visibility_logic?.dependent_field
              if(df?.data_source_id){
                dataSourceIds.push(df.data_source_id)
              }
            }
          }
        }
        dataSourceIds.push(field?.row_data_source_id)
        getDataSourceList(dataSourceIds)  
      }        
  }, [])

  /*if(!renderField){
    return null
  }*/

  const getDataSourceList = (data_source_ids) =>{
    setLoading(true)
    let req = {
      parent_key: 'data_source_id',
      data_source_id: data_source_ids,
      fields:['data_type']
    }

    Cs.getDataSource(req).then((res) => {
      setDataSourceObject(res)
      setLoading(false)
    })
  } 

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes)
  labelAttributes.className = 'm-b-10 f18 fw500'
  let validation = {
    required: field.required
  }

  const handleChange = (rowData, row_id) => {
    data[row_id] = rowData
    //dsl_id -> data_source_list id
    data[row_id].dsl_id = row_id
    formData[field.client_id] = data
    setData({...data})
    setError()
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setDisabled(field, inputAttributes)

  return (
    <div {...parentAttributes} title="tooltip">
      <div className="flex coldir m-b-20">
        <FieldLabel field={field} 
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}
        />
        <span className="fts-13">{field.description}</span>  
        <Error/>
        {loading ? 
          <div className="spinner"/>
          :
          <div className="table-responsive tab-with-table p-0">
            <table className="table table-stripe table-head w-full"> 
              <thead>
                <tr>
                  <th></th> 
                  <TableHeader formField={field} columns={field.columns}/>   
                </tr>
              </thead>

              <tbody>
                <TableRow formField={field} 
                  rows={dataSourceObject[field.row_data_source_id]?.options}
                  columns={field.columns}
                  dataSourceObject={dataSourceObject}
                  handleChange={handleChange}
                  currentLocale={currentLocale}
                  screen={screen}
                  formData={formData}
                  data={data}
                /> 
              </tbody>
            </table>
          </div>
        }
      </div>  
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

const TableHeader = ({formField, columns=[]}) => columns.map((h, k) => {
  return(  
    <th key={k} className='tcenter'>
      {h.label} 
    </th>   
  )
})

const TableRow = ({data, formData, formField, screen, currentLocale, columns, rows=[], dataSourceObject, handleChange}) => rows.map((r, k) => {
  const rowConfig = formField.row_col_config?.['row_'+r.pk_id]
  const result = FormHelper.checkFieldConditionality(rowConfig, formData)
  if(!result){
    if(data['row_'+r.pk_id])handleChange(null, r.pk_id)
    return null
  }

  const rowData = data['row_'+r.pk_id] || {}

  return(  
    <tr key={k}>
      <td className="font-15">
        {r.image_url && <Image image={r.image_url} styleName="w-100" style={{height:"60px"}}/>}
        {r.label}
      </td>
      <TableColumnData columns={columns} rowConfig={rowConfig}
        row={r} dataSourceObject={dataSourceObject}
        handleChange={handleChange}
        currentLocale={currentLocale}
        screen={screen}
        formField={formField}
        formData={formData}
        rowData={rowData}
        idx={k}
      />
    </tr>
  )
})

const TableColumnData = ({rowConfig, formData, rowData, formField, screen, currentLocale, idx, columns=[], row, dataSourceObject, handleChange}) => columns.map((c, k) => {
  const colConfig = rowConfig?.[c.index]
  if(!colConfig){
    return null
  }

  const dependentField = colConfig.visibility_logic?.dependent_field
  const colDs = dataSourceObject[colConfig?.data_source_id]
  const result = FormHelper.checkFieldConditionality(colConfig, formData)
  let dependentFieldDs = null
  try{
    if(result && dependentField){
      dependentFieldDs = dataSourceObject[dependentField?.data_source_id]
    }else if(!result && rowData[dependentField.client_id]){
      delete rowData[dependentField.client_id]
      delete rowData[dependentField.client_id+'_array']
      handleChange(rowData, row.pk_id)
    }
  }catch(e){
    console.log(e.message)
  } 

  return(
    <td key={k}>
      <InputComponent rowData={rowData} screen={screen} currentLocale={currentLocale} 
        idx={idx} column={c} row={row} handleChange={handleChange} colDs={colDs}/>
      {result && colConfig.visibility_logic?.dependent_field && 
        <>
          <div className="m-t-10 bold-400 text-center font-15">{dependentField.label}</div>
          <InputComponent rowData={rowData} screen={screen} 
            currentLocale={currentLocale} idx={idx} 
            column={colConfig.visibility_logic?.dependent_field} 
            row={row} handleChange={handleChange} colDs={dependentFieldDs}/>   
        </>
      }
    </td>
  )

})


const InputComponent = ({rowData, screen, currentLocale, colDs, idx, column, row, handleChange}) =>{
  if(column.input_type == 'text'){
    return(  
      <TDTextInput rowData={rowData} column={column} label={row.label} 
        idx={row.sid} handleChange={handleChange}/>   
    )
  }else if(column.input_type == 'select' && colDs?.options){
    return(  
      <TDInputSelect rowData={rowData} column={column} idx={row.sid} 
        handleChange={handleChange} isObject={true}
        currentLocale={currentLocale} screen={screen}
        dataTypes={colDs.data_type} options={colDs?.options}
        label={row.label}/>   
    )
  }else if(column.input_type == 'multi_select' && colDs?.options){
    return(
        <TDInputMultiSelect rowData={rowData} column={column} idx={row.sid} 
        handleChange={handleChange} screen={screen}
        options={colDs?.options} label={row.label}/>  
    ) 
  }else if(column.input_type == 'radio' && colDs?.options){
    return(
        <TDInputRadio rowData={rowData} column={column} idx={row.sid} 
        handleChange={handleChange} isObject={true}
        currentLocale={currentLocale} screen={screen}
        options={colDs?.options}
        label={row.label}/>  
    ) 
  }else{
    return null
  }
}

export default InputTableView;