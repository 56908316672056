import BaseService from "../../../../services/BaseService";
import env from '../../../../env';
import axios from 'axios';
//let url = env.carrier_training+'assessments'

class AssessmentService extends BaseService{
  
  getAllExams(req){
    return new Promise((resolve, reject) => {
      axios.get(env.carrier_training+'student/exams', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getEmailNotificationList(id, req){
    return new Promise((resolve, reject) => {
      axios.get(env.carrier_training+'email_notification/assessments/'+id, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  clone(req){
    return new Promise((resolve, reject) => {
      axios.post(env.carrier_training+'clone/assessments', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  search(req){
    return new Promise((resolve, reject) => {
      axios.post(env.carrier_training+'search/assessments', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  /*
  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }*/

}

export default new AssessmentService('carrier_training', 'assessments');