import env from '../../../../env';
import axios from 'axios';
let url = env.carrier_training+'step_assessments'
let options_url = env.carrier_training+'question_options'

class StepAssessmentService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getOptions(req){
    return new Promise((resolve, reject) => {
      axios.get(`${options_url}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  deleteOption(req){
    return new Promise((resolve, reject) => {
      axios.delete(`${options_url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  updateOption(req){
    return new Promise((resolve, reject) => {
      axios.put(`${options_url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  updateSortOrder(req){
    return new Promise((resolve, reject) => {
      axios.put(`${env.carrier_training}sort/step_assessments/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }
}

export default new StepAssessmentService();