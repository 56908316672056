import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import DigitalContentList from '../../components/DigitalContent/List/DigitalContentList'
import DigitalContentForm from '../../components/DigitalContent/Form/DigitalContentForm'
import DigitalContentDetails from '../../components/DigitalContent/Details/DigitalContentDetails'

const DigitalContentRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/digital_contents" component={DigitalContentList} searchString={props.searchString} permissionType='current_admin.role_content' exact />
    <PrivateRoute path="/digital_contents/edit/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/digital_contents/details/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/content_details/:id?" component={DigitalContentDetails} exact />
    <PrivateRoute path="/digital_contents/create/:type?/:formId?/:id?" component={DigitalContentForm} exact />
  </Fragment>
)	

export default DigitalContentRouter;