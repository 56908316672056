import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Help from './Help'
import HelpService from '../HelpService'
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import CommentsModal from "../../Modals/CommentsModal"
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import useStyle from '../../../hooks/useStyle'
import env from '../../../env'
import GenericModal from "../../Modals/GenericModal"
import {MenuItem,NavIcon} from '../../Common/MenuItem'

let requestParams = {}
let selectedHelp = {}
let dataSource = {}

const HelpList = (props) => {
  useStyle('card')
  useStyle('card_table')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  const [helpList, setHelpList] = useState([])
  const [helpPageList, setHelpPageList] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isHelpTypeOpen, toggleModal:toggleHelpTypeModal } = useModal()

  useEffect(() => {
    requestParams = {
      'filter_favourites': false,
      'is_active': true,
      'is_user_suggested': false, 
      'page': currentpage,
      'paginate': 1,
      'per_page': 30,
      'sort_column': '',
      'sort_direction': 'desc',
      'search': null,
    }

    getHelpList()
  }, [currentUser.id, currentpage])

  let getHelpList = () => { 
    HelpService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setHelpList(res.data.helps)
        setHelpPageList(res.data.page_list)
        requestParams.totalItems = res.data.meta.total
        dataSource = res.data.data_source_list
      }
    })
  }

  const onDelete = (e, help) => {
    selectedHelp = help
    toggleDeleteModal(e)
  }

  const addMenu = (e, help) => {
    selectedHelp = help
    toggleMenuModal(e)
  }

  const deleteHelp = (e) =>{
    e.preventDefault()
    HelpService.delete(selectedHelp.id).then((res)=>{
      if(res.status == 204){
        setHelpList(helpList.filter(r => r.record_id !== selectedHelp.record_id))
      }
    })
  }

  const HelpFilters = ({handleClick, list}) =>{
    return(
      <Fragment>
        <div className="popup-menu-grid">
          {list.map((o, k) =>
            <Fragment> 
              <a className="ver-icons" onClick={e=>{e.preventDefault();handleClick(o)}}>
                <span><i className="fas fa-plus"/></span>
                <div className="popup-menu-title">{o.label}</div>
                <i className="fas fa-chevron-right lgrey pull-right" />
              </a>
            </Fragment> 
          )}
        </div>
      </Fragment>  
    )
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <MenuItem id="edit_icon" 
          link={`/help/create/${item.id}?`}
          icon="far fa-edit"
          action="Edit"
        />
       <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => {onDelete(e,item)}}
        />
      </Fragment>
    )
  }

  const Menu = ({ help, fn }) => (
    <i onClick={e=>addMenu(e, help)} className="fas fa-bars" />     
  )
     
  const helpListCard =  helpList && helpList.map((help, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Help helpList={help} menu={Menu} toggleModal={toggleDeleteModal}/>
      {selectedHelp.id == help.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
        item={selectedHelp} onDelete={onDelete} type="journey" MenuItems={menuItems} 
        menuHeight="150px"/>
      }
    </div>
  ))
  
  return (
    <Fragment>
      <div className="content top-10">
        <h5 className="float-left font-500">Help List</h5>
        
        <NavIcon id="filter_items" dataTip="Filter Items" 
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          onSelect={toggleHelpTypeModal} icon="fas fa-sliders-h"/>

        <Link className="bg-highlight round-btn float-right m-r-5 m-t-4" to="/help/create/">  
          <i className="fas fa-plus"></i>
        </Link>

        <div className="clear"></div>
      </div>
      
      <div className="page-content row">
        {helpListCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
        setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteHelp}/>
      
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedHelp} type="cue_news"/>
      }
      
      {isHelpTypeOpen && (
        <GenericModal component={HelpFilters} isOpen={isHelpTypeOpen} 
        toggleModal={toggleHelpTypeModal}
        handleClick={getHelpList} list={helpPageList} title="Page Filter"/>
      )} 
    </Fragment>
  )

}

export default HelpList;