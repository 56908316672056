import React from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CheckAccess from "../../../Roles/CheckAccess";
import CommonService from "../../../../../services/CommonService";
import PreparationSteps from "../../PreparationSteps/PreparationSteps";
import FormTemplateId from "../../../../../constants/FormTemplateList";

const CoursePreparation = (props) => {
  const { state: authState, dispatch } = React.useContext(AuthContext);

  let activity = props.activity;
  activity.updated_at = CommonService.formatUpdateDate(activity.updated_at);
  let data = activity.data;
  let ds = props.dataSource;

  const cardStyle = {
    backgroundColor:
      data.background_color || data.header_background || "#607D8B",
  };

  if (!data) return null;

  let userRoleLink = {
    pathname: `/journey/users/${activity.journey_category_id}/${activity.education_journey_profile_id}/academic_courses.course_preparation/${activity.id}`,
    state: { journey: props.journeyCategory, activity: activity },
  };

  let incomingMailLink = {
    pathname: `/journey/incoming/emails/academic_courses.course_preparation/${props.activity.id}`,
    state: { journey: props.journeyCategory, activity: props.activity },
  };

  const MailTag = () => (
    <a
      href={`mailto: ${activity.id}-course_preparation@reply.cuetree.com?subject=Course Preparation-${data.name}-${activity.record_id}`}
      target="_blank"
    >
      <i className="far fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  );

  const Header = () => (
    <div
      className="white card-header p-5"
      style={cardStyle}
    >
      <div className="font-16 ellipsis">
        {data.name}
        <i className="fas fa-expand pull-right white expand"></i>
      </div>
      <div className="font-16">
        { CommonService.formatUpdateDate(activity.created_at)}
          <Link
            className="pull-right white expand p-top-0"
            onClick={(e) => e.stopPropagation()}
            to={userRoleLink}
          >
            <i className="fa fa-users white"></i>
          </Link>
      </div>
    </div>
  );

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span
          id={`comment_${props.index}`}
          className="m-l-25"
          onClick={(e) => {
            props.addNotes(e, activity);
          }}
        >
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge up bg-lgrey">{activity.comments_count || 0}</span>
        </span>
        <span
          id={`check_list_${props.index}`}
          className="m-l-25"
          onClick={(e) => {
            props.addChecklist(e, activity);
          }}
        >
          <i className="far fa-check-circle font-18 text-muted"></i>
          <span className="badge up bg-lgrey">{activity.check_list_count || 0}</span>
        </span>
        <span id={`mail_${props.index}`} className="m-l-25">
          <Link onClick={(e) => e.stopPropagation()} to={incomingMailLink}>
            <i className="far fa-envelope font-18 text-muted"></i>
            <span className="badge up bg-lgrey">
              {activity.mail_count || 0}
            </span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  );

  const PrepCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header />
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span className="pull-right">
                    Target Date: {data.reminder_end}
                  </span>
                  <strong>Overview</strong>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.overview }}
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <PreparationSteps
            stepActivities={activity.step_activities}
            activity={activity}
            journeyCategory={props.journeyCategory}
          />
        </div>
        <Footer />
      </div>
    );
  };

  return <PrepCard />;
};

export default CoursePreparation;
