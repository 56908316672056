import React, {useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import useStyle from '../../hooks/useStyle'
import { Link } from "react-router-dom"

export const JourneyDashboard = (props) => {
  useStyle('signup')
  
  const { state: authState, dispatch } = useContext(AuthContext)

    let categoryList = {
        'JourneyCategoryList':[
            {
              id:'37cbde71-4776-4e0b-a17b-7a5f6d444445', 
              title:'EDUCATION',
              description:'eDUCATION',
              image:'assets/img/jc/edu.png',
              header_bg_color:'#3fb7ee',
              status:'ACTIVE',
              icon:'fa fa-graduation-cap'
            },
            {
              id:'d2ad7a1d-fb1e-4545-ae93-2c7bd6bfa8fe', 
              title:'SPORTS',
              description:'Sports Journey',
              image:'assets/img/jc/edu.png',
              header_bg_color:'#3fb7ee',
              status:'ACTIVE',
              icon:'fa fa-trophy'
            },
            {
              id:'6faa2cdf-54bd-44bf-97b2-ff36718b69c0', 
              title:'HEALTH & WELLBEING',
              description:'eDUCATION',
              image:'assets/img/jc/health.png',
              header_bg_color:'#d74548',
              status:'STAY TUNED FOR RELEASE',
              icon:'fa fa-medkit'
            },{
              id:'8874c1f0-cd8a-41ea-89fb-d40c7c81192e',
              title:'CIVIC',
              image:'assets/img/jc/community.png',
              header_bg_color:'#f7c836',
              status:'STAY TUNED FOR RELEASE',
              icon:'fa fa-users'
            },
            {
              id:'48f2eca6-36ff-41bb-b942-78d0016dfe47', 
              title:'PROPERTY',
              image:'assets/img/jc/property.png',
              header_bg_color:'#fc5d93',
              status:'STAY TUNED FOR RELEASE',
              icon:'fa fa-building'
            },
            {
              id:'acd90956-f62f-4888-997b-3fedac04d562',
              title:'UNEXPECTED',
              image:'assets/img/jc/unexp.png',
              header_bg_color:'#444a53',
              status:'STAY TUNED FOR RELEASE',
              icon:'fa fa-universal-access'
            }
          ]
    }

  const categoryCard = categoryList.JourneyCategoryList.map((cat, i) => (
    <div className={`one-half ${cat.status!='ACTIVE'?'input-disabled':''}`} key={i} style={{    border:`1px solid ${cat.header_bg_color}`,margin:'3px'    }}>
                <Link to={`/journey/form/${cat.title}/${cat.id}`} className="caption round-medium shadow-large bg-theme bottom-15 content-one-half">
                    <div className="caption-center">
                        <h1 className="center-text">
                            <i className={cat.icon} data-feather="file" 
                               data-feather-line="1" 
                               data-feather-size="50" 
                               data-feather-color="blue2-dark" 
                               data-feather-bg="blue2-fade-light">
                            </i>
                        </h1>
                        <h4 className="center-text color-theme">{cat.title}</h4>
                        <p className="under-heading color-highlight center-text font-11 color-highlight">
                                {cat.description}
                        </p>
                    </div>
                    <div className="caption-bottom">
                        <p className="bottom-10 opacity-40 font-10 center-text">Tap to View</p>
                    </div>
                </Link>       
            </div>
  ))

  React.useEffect(() => {
  
  }, [authState.isAuthenticated]);


  return (
    <React.Fragment>
      <div className="header header-fixed header-logo-app header-auto-show">
        <a href="index.html" className="header-subtitle">
          Back to Pages
        </a>
        <a href="#" data-back-button className="header-icon header-icon-1">
          <i className="fas fa-arrow-left" />
        </a>
        <a href="#" data-toggle-theme className="header-icon header-icon-2">
          <i className="fas fa-lightbulb" />
        </a>
        <a href="settings.html" className="header-icon header-icon-3">
          <i className="fas fa-cog" />
        </a>
      </div>
      <div className="page-content">
        <div className="page-title-bg bg-20 ht-150" >
          {/* image */}
        </div>
        <div
          className="page-title-bg dark-mode-tint ht-150"
          
        >
          {/* contrast for dark mode */}
        </div>
        <div
          className="page-title-bg opacity-90 bg-highlight ht-150"
          
        >
          {/* background color */}
        </div>
        <div className="page-title-small color-white bottom-30">
         
        </div>

        <div className="content-boxed-dashboard shadow-small">
            <div className="content bottom-0">
                <h4 className="bottom-20"> Select a Category to start a new journey</h4>
            </div>
        </div>
        
        <div className="content">
          {categoryCard}
            <div className="clear"></div>     
        </div>

        {/* Footer */}
      
        <div className="footer" data-footer-load="menu-footer.html" />
      </div>
    </React.Fragment>
  );
};

export default JourneyDashboard;
