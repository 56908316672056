import React, {useState, Fragment, useEffect,useContext,useRef} from "react"
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Form from '../Forms/Form'
import UserFormService from "../UserFormService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import CommentsModal from "../../Modals/CommentsModal"
import {Link} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import NotesModal from "../../Modals/NotesModal"
import useStyle from '../../../hooks/useStyle'
import CheckAccess from '../../Journey/Roles/CheckAccess'
import PreLoadSpinner from "../../PreLoadSpinner"
import JourneyTitle from "../../Journey/JourneyTitle/JourneyTitle"
import SideMenuBar from "../../Common/SideMenuBar"
import NotificationPopup from "../../Common/NotificationPopup"
import HelpMessageList from '../../../constants/HelpMessageList'
import Cs from "../../../services/CommonService"
import ReactTooltip from "react-tooltip"
import SortDirection from '../../Common/SortDirection'
import {NavIcon,CardMenuInfo,MenuItem,CardMainMenu} from '../../Common/MenuItem'
import useSort from '../../../hooks/useSorting'

let requestParams = {}
let selectedForm = {}
let dataSource = {}
let journeyCategory = null

const FormsListTable = (props) => {
  useStyle('table')  

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "col-xs-12"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-xs-12 col-sm-6 col-md-8"
    cardHeightCss = "mih-380"
  }

  const indexEle = useRef(null)

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [formList, setFormList] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 
  const [loading, setLoading] = useState(false)
  const [pagemeta, setPagemeta] = useState([])

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    journeyCategory = null
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'form_id':queryParam.case_assessment?FormTemplateId.CaseAssessment:null,
      'page':currentpage,
      'per_page':10,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'id':queryParam.id
    }

    getFormsListTable()

    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage, props.location])

  let getFormsListTable = () => { 
    setLoading(true)
    UserFormService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        journeyCategory = res.data.journey_category
        CheckAccess.userAccess(currentUser, journeyCategory, res.data.invited_journey_member)
        setFormList(res.data.user_forms)
        res.data.user_forms.length == 0 && !requestParams.search && toggleHelpMessage()
      }
    })
  }

  const {sort} = useSort(requestParams, getFormsListTable)
  
  const addNotes = (e, a) => {
    selectedForm = a
    toggleCommentModal(e)
  }

  const tableData = formList.map((a, k) =>{
    return(
      <tr key={k} className="bold-100">
        <td data-title="Id">{a.record_id}</td>

        <td className="font-16">
          {a.title}
        </td>
        
        <td className="font-16">
          <p dangerouslySetInnerHTML={{ __html: a.description }}/>
        </td>

        <td className="font-16 maw-50" >
          {Cs.formatUpdateDate(a.created_at, 'MMM DD, YYYY hh:mm a')}
        </td>

        <td className="font-16 d-flex">
          {(a.submission_count == 0 || a.submission_type == 'multiple')  && 
            <Link className="w-50p" data-tip="Add Submission" id={a.id} to={`/form/submissions/${a.journey_category_id}/${a.journey_profile_id}/${a.id}`}>
              <i className="fas fa-plus"/>
            </Link>
          }
            
          <Link className="w-50p" data-tip="View Submission" id={a.id} to={`/list/submissions/${a.journey_category_id}/${a.journey_profile_id}/${a.id}`}>
            <i className="far fa-sticky-note"/>
          </Link>
        </td>
      </tr>
    )
  })

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4 pull-left">
          <JourneyTitle title="Form List" project={journeyCategory}/>
        </div>
          
        <div className="col-md-8 float-right">         
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            onSelect={e=>Cs.routeJourney(journeyCategory, props)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
        </div>
      </div>

      <div className="table-responsive">
        <table className="shadow-small">
          <thead>
            <tr className="white bg-black">
              <th onClick={e=>sort('record_id')}>Id 
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </th>
              <th onClick={e=>sort('title')}>Title
                <SortDirection sort_column={requestParams.sort_column} column="users.name" reverse={requestParams.reverse}/>
              </th>
              <th onClick={e=>sort('description')}>Description
                <SortDirection sort_column={requestParams.data} column="data" reverse={requestParams.reverse}/> 
              </th>
              <th onClick={e=>sort('created_at')} className="maw-50">Date 
                <SortDirection sort_column={requestParams.created_at} column="created_at" reverse={requestParams.reverse}/> 
              </th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {tableData}
          </tbody>
        </table>
      </div>
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedForm} type="user_form"/>
      }
     
      {isHelpMessageOpen && 
        <NotificationPopup header="Note" message={HelpMessageList.new_user_form} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      }
      <SideMenuBar helpId="15"/>
    </Fragment>
  )

}

export default FormsListTable;