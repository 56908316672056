import env from '../../../../env';
import axios from 'axios';
const url = `${env.edu_college_journey}interview_preparations`;

class InterviewService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url,req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getCompanyList(req){
    return new Promise((resolve, reject) => {
      axios.post(env.edu_college_journey+'interview_questions/company_name', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new InterviewService();