import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Cs from "../../../../../services/CommonService";
import AddToFavourite from "../../../../Common/AddToFavourite"
import ReactTooltip from "react-tooltip";
import VimeoPlayer from "../../../../Common/VimeoPlayer"
import {FormFieldTable, RenderData} from "../../../../Journey/UserRoles/NotificationPill"

const Activity = (props) => {
  let { formFieldsList, activity, dataSource} = props;
  let formFields = formFieldsList[activity.activity_form_id] || []

  activity.updated_at = Cs.formatUpdateDate(
    activity.updated_at,
    "MMM DD YYYY"
  )
  let cardStyle = {};
  cardStyle.backgroundColor = '#607D8B'
  let data = activity.data;
  let data_source = activity.data_source || {};

  if (!data) return null;  

  const Favourite = () =>(
    <AddToFavourite favouriteId={activity.favourite_id}
     favorableType="career_journey.activity"
     favorableId={activity.id}
     journeyCategoryId={activity.journey_category_id}
     classNameActive="pin-40 green" classNameDisable="pin-40 white"
     journeyProfileId={activity.journey_profile_id}/>
  )

  const VideoFooter = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span className="m-l-25" id={`comment_${props.index}`}
          onClick={(e) => {props.addNotes(e, activity)}}>
          <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
          <span className="badge up bg-lgrey">{activity.comments_count || 0}</span>
        </span>
        <span className="m-l-25" id={`check_list_${props.index}`}
          onClick={(e) => {props.addChecklist(e, activity)}}>
          <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
          <span className="badge up bg-lgrey">{activity.check_list_count || 0}</span>
        </span>
        <span className="m-l-25" id={`bookmark_${props.index}`}
          onClick={(e) => {props.addBookMark(e, activity)}}>
          <i className="far fa-bookmark font-18 text-muted" data-tip="Add bookmarks to video lessons"></i>
        </span>
        <span className="m-l-25" id={`mail_${props.index}`}>
          <Link to={`/journey/incoming/emails/career_journey.activity/${activity.id}`}>
            <i className="far fa-envelope font-18 text-muted" data-tip="Mail"></i>
            <span className="badge up bg-lgrey">{activity.mail_count || 0}</span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  )

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span className="m-l-25" id={`comment_${props.index}`}
          onClick={(e) => {props.addNotes(e, activity)}}>
          <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
          <span className="badge up bg-lgrey">{activity.comments_count || 0}</span>
        </span>
        <span className="m-l-25" id={`check_list_${props.index}`}
          onClick={(e) => {props.addChecklist(e, activity)}}>
          <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
          <span className="badge up bg-lgrey">{activity.check_list_count || 0}</span>
        </span>
        <span className="m-l-25" id={`mail_${props.index}`}>
          <Link to={`/journey/incoming/emails/career_journey.activity/${activity.id}`}>
            <i className="far fa-envelope font-18 text-muted" data-tip="Mail"></i>
            <span className="badge up bg-lgrey">{activity.mail_count || 0}</span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  )

  const Header = () =>(
    <Fragment>
      <div className="white card-header p-5" style={cardStyle}>
        <div className="font-16">
          {data.record_title || activity.activity_type}
          {!props.popUpView && 
            <i className="fas fa-expand top-3 pull-right white expand" data-tip="Full Screen"
            onClick={(e) => props.routeActivity(activity)}></i>
          }
          <Favourite />
        </div>
        <div>{activity.updated_at}
        </div>
      </div>
    </Fragment>
  )

  const DateField = () =>(
    <tr>
      <td>
        <strong className="lgrey">
          Start Date 
        </strong>
        <div>
         { activity.data.start_date && activity.data.start_date}
        </div>
      </td>
      <td>
        <strong className="lgrey">
          End Date 
        </strong>
        <div>
          {activity.data.end_date && activity.data.end_date}
        </div>
      </td>
    </tr>

  )

  const Notes = () =>(
    <tr>
      <td>
        <strong className="lgrey">Notes</strong>
        <div dangerouslySetInnerHTML={{ __html: data.notes }}></div>
      </td>
    </tr>
  )

  const Consent = () =>(
    <tr>
      <td>
          <input type="checkbox" className="m-r-10" />
          I want to share the URL reference from this item with other registered users
      </td>
    </tr>
  )
  
  const VideoCard = () => {
    var thumbnail
    if(activity.data.video_url && activity.data.video_url.includes('vimeo')){
      
    }else if(activity.data.video_url && activity.data.video_url.includes('youtube')){
      var videoid = activity.data.video_url && activity.data.video_url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if(videoid !== null){
        thumbnail = `https://img.youtube.com/vi/${videoid[1]}/maxresdefault.jpg`
      }
    }else{
      thumbnail =`https://resources.construx.com/wp-content/uploads/2016/08/video-placeholder-brain-bites.png`
    }

    return (
      <div className="card-container bg-white shadow-small">
        <Header />
        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
              <tr>
              { activity.data.video_url && 
                  <div className="col-xs-12 p-7">
                    {(activity.data.video_url && !activity.data.video_url.includes('vimeo')) ?
                    <a href={activity.data.video_url} target="_blank">
                      {thumbnail && 
                        <Fragment>
                          <div className="thumb-wrapper">
                            <img src={thumbnail} className="w-100p" alt="My Video Thumb" />
                            <span className="w-100p"></span>
                          </div>
                        </Fragment> 
                      }
                    </a>:
                    <VimeoPlayer url={activity.data.video_url} width="100%" height="300px"/>}
                  </div>
                }
              </tr>
              <Consent />
            </tbody>
          </table>
        </div>
        <VideoFooter />
      </div>
    )
  }

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header />
        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <div>{activity.activity_type}</div>
                </td>
                <td>
                  <strong className="lgrey">Grade</strong>
                  <div>
                    {activity?.data_source?.grade?.label}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="lgrey">Recognition</strong>
                  <div>{data.course_name}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="lgrey">Url</strong>
                  <div>{data.url}</div>
                </td>
              </tr>
              <Notes />
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }

  const GenericContent = () =>(
    <div className="card-container bg-white shadow-small">
      <Header />
      <div className="ht-400 scroll-auto">
        <div className="col-xs-12">
          <div className="pull-left p-5"><b>Activity Type: </b>{activity.activity_type}</div>
        </div> 
        <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
      </div>
      <Footer />
    </div>
  )

  if (activity.activity_form_id == 1024) {
    //video
    return <VideoCard />;
  }else {
    return <GenericContent />;
  }
}

export default Activity;
