import React, {useState, useEffect, useRef} from 'react';
import {CueTooltip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import moment from '../../../../node_modules/moment/min/moment.min.js'
import "react-datepicker/dist/react-datepicker.css";
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

// import Calendar Component 

//import DatePicker from 'react-date-picker'
import DatePicker from "react-datepicker";

const InputDateTimePicker = ({field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  var dateFormat = "YYYY-MM-DD";
  
  if(field.component=='timepicker')dateFormat = "YYYY-MM-DD HH:mm";

  let d = null
  if(formData[field.client_id+'_formatted_date']){
    d = moment(formData[field.client_id+'_formatted_date']).toDate();
  }
  
  const [value, setDate] = useState(d);
  const [showError, setShowError] = useState(false);
  const [isOpen, setIsOpen] = useState(isFormWizard);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  });
  
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'className':'form-input form-input-gray form-input-full'
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {required: true}
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  const onDateChanged = (value) =>{
    console.log(value)
    setIsOpen(!isOpen);
  	formData[field.client_id+'_formatted_date'] = moment(value).format(dateFormat);
    formData[field.client_id] = moment(value).format(field.dateFormat);
    setDate(value);
    setError();
  }

  FormHelper.setDisabled(field, inputAttributes);
  FormHelper.setDatePicker(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id] == null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
  	<div ref={ref} {...parentAttributes} data-tip data-for={`tooltip_${field.client_id}`}>
  	  <div className="flex coldir m-b-20">
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <input className="form-input form-input-gray form-input-full" value={value && moment(value).format(dateFormat)} onClick={handleClick}></input>
        {isOpen && (
          <DatePicker dateFormat={dateFormat} selected={value} onChange={date => onDateChanged(date)} peekNextMonth
          showMonthDropdown showYearDropdown dropdownMode="select" {...inputAttributes} shouldCloseOnSelect={false} inline/>
        )}
        <div className="fts-13">{field.description}</div>
        <Error/>
        <CueTooltip id={field.client_id} description={field.tooltip}/>
      </div>
	  </div> 
  )
 
}

export default InputDateTimePicker;

/*<DatePicker onChange={onDateChanged} value={value} {...inputAttributes}/>*/