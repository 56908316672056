import React, { memo, useRef, useState, useEffect, Fragment } from 'react'
import { Handle } from 'react-flow-renderer'
import {Link} from "react-router-dom"
import ReactTooltip from "react-tooltip"
import BarChart from "../../../Charts/BarChart"

const AddDataSource = ({ data, isConnectable }) => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  return (
    <>
      <div>
        <Handle type="target" position="left" style={{ borderRadius: 0 }} />
        <button type="button" className="btn-card border-rounded-5 flex aligncenter">
          <span className="p-l-20">{data.label}</span>
          <div ref={ref1} id={`${data.label === "Colleges" ? "add_college":"add_activity"}`} 
            className="btn-plus text-white flex aligncenter justifycenter m-l-20 f18"
            data-tip={`${data.label === "Colleges" ? "Add College" : "Add Activity"}`} 
            onClick={e=>data.addActivity(data)} 
            onMouseOver={() => { ReactTooltip.show(ref1.current); }}
            onMouseLeave={() => { ReactTooltip.hide(ref1.current); }}>
            +
          </div>
        </button>
      </div>
      <Handle type="source" id="a" style={{borderRadius: 0}}/>
    </>
  )
}

/*
<div>
        <Handle type="target" position="left" style={{ borderRadius: 0 }} />
        <span ref={ref1} data-tip={`${data.label == "Colleges" ? "Add College" : "Add Activity"}`} onClick={e=>data.addActivity(data)} className="fas fa-plus font-14 add-btn bg-highlight"
          onMouseOver={() => { ReactTooltip.show(ref1.current); }}
          onMouseLeave={() => { ReactTooltip.hide(ref1.current); }}
        />
        {data.currentGrade == data.label &&
          <span ref={ref2} data-tip="Suggestions"
            onClick={e=>data.toggleSuggestionModal()} className="fas fa-compass font-14 add-btn bg-black r-unset"
            onMouseOver={() => ReactTooltip.show(ref2.current)}
            onMouseLeave={() => ReactTooltip.hide(ref2.current)}
          />
        }
        <div className={`datasource-node-highschool ${data.currentGrade == data.label ? 'bg-highlight white':''} `}>
          {data.label}
        </div>
      </div>
*/

const SuggestedActivityList = ({ activities, activityColor, activityIcon, cloneActivity }) => activities.map((data, i)=>{
  data.icon = activityIcon[data.activity_form_id]
  data.bg_color = activityColor[data.activity_form_id]
  return (
    <>
      <span key={i} className="fa-stack fa-2x m-t-5">
        <i className="fas fa-circle fa-stack-2x" style={{color:data.bg_color}}></i>
        <i className={`fa-stack-1x fa-inverse ${data.icon}`}></i>
      </span>
      <div>{data.data.name || data.data.record_title}</div>
      <div className="col-xs-12">
        <div className="text-center col-xs-6">
          <span onClick={e=>cloneActivity(data)} className="fas fa-check green"/>
        </div>
        <div className="text-center col-xs-6">
          <span className="fas fa-times-circle lred"/>
        </div>
      </div>
    </>
  )
})

const PaginationSmall = (props) => {
  if(!props.totalItems || !props.itemsPerPage)return null;

  const nextPageClick=() =>{
    props.setCurrentpage(props.currentpage + 1)
  }
 
  const prevPageClick=() =>{
    props.setCurrentpage(props.currentpage - 1)
  }
 
  const totalPage = Math.ceil(props.totalItems / props.itemsPerPage);

  if(totalPage.length==0){
    return null;
  }
  
  return (
    <div className="pagenate-parent">
      {props.currentpage > 1 && <span className="fas fa-chevron-circle-left pagenate-left" onClick={e=>prevPageClick()}/>}
      <span className="badge2 bg-lred pagenate-text">{props.currentpage} / {totalPage}</span>
      {props.currentpage < totalPage && <span className="fas fa-chevron-circle-right pagenate-right" onClick={e=>nextPageClick()}/>}
    </div>
  )
}

const ActivityList = ({ activities, activityColor, activityIcon, modalActivity }) => activities.map((data, i)=>{
  data.icon = activityIcon[data.activity_form_id]
  data.bg_color = activityColor[data.activity_form_id]
  const nodeStyle = {borderWidth:"1px 1px 1px 20px", borderColor:data.bg_color,borderStyle:"solid"}

  return (
    <Fragment key={i}>
      <div className="d-flex m-t-10 font-15" style={nodeStyle} onClick={e=>modalActivity(data)}>
      <span className={`${data.icon} m-t-5 m-b-5 m-l-10  lgrey`}></span>
      <div className="" style={{margin:"auto"}}>{data.name || data.record_title}</div>
      </div>
    </Fragment>
  )
})

const AddActivity = ({id, data, isConnectable }) => {
  const {activities, meta, activityColor, activityIcon, modalActivity, activityPaginate} = data
  
  const [activitiesList, setActivitiesList] = useState([])
  const [pageMeta, setPageMeta] = useState(meta)
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setActivities()
  }, [])

  const setActivities = () => setActivitiesList(activities);

  const setCurrentpage = (page) => {
    setLoading(true)
    activityPaginate(page, data.grade, onSuccess)
  }
    
  const onSuccess = (res) => {
    setActivitiesList([...res.education_journal_entries])
    setPageMeta(res.meta)
    setLoading(false)
  }
  
  if(loading) 
    return <div className="m-l-15 spinner"/>

  return (
    <div className="br-grey p-10">
      <div id="view_activity" className="wk-center" style={{width:"200px"}}>
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <ActivityList activities={activitiesList} {...{activityIcon, activityColor, modalActivity}} />
      </div>
      <PaginationSmall totalItems={pageMeta.total} currentpage={parseInt(pageMeta.page)} 
        itemsPerPage="15" setCurrentpage={setCurrentpage}/>
      <Handle type="source" position="bottom" id="a" style={{borderRadius: 0}}/>
    </div>
  )
}

const AddSuggestedActivity = ({ data, isConnectable }) => {
  const {activities, activityColor, activityIcon, cloneActivity} = data

  return (
    <>
      <div className="wk-center" style={{width:"200px",height:"20px"}}>
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <SuggestedActivityList {...{activities, activityIcon, activityColor, cloneActivity}} />
      </div>
    </>
  )
}

const CollegeList = ({ colleges, selectedCollege, collegeUserViews }) => colleges.map((data, i)=>{
  let graphData = collegeUserViews[data.college_id]

  let chart = {
    id:'college_chart_'+i,
    chart: {
      group: 'sparklines',
      type: 'area',
      height: 50,
      sparkline: {
        enabled: true
      },
    },
    stroke: {
      curve: 'straight'
    },
    fill: {
      opacity: 1,
    },
    series: [{
      name: 'View',
      data: graphData?.count
    }],
    yaxis: {
      min: 0
    },
    xaxis: {
      type: 'category',
    },
    colors: ['#008FFB'],
  }

  return (
    <Fragment key={i}>
      <div className="black br-top-grey font-15 p-5" onClick={e=>selectedCollege(data)}>{data.label}</div>
      {graphData && <BarChart id={chart.id} chart_type="custom" data={chart}/>}
    </Fragment>
  )
})

const AddCollege = ({data, isConnectable }) => {
  let {colleges, selectedCollege, collegeUserViews} = data
  
  return (
    <>
      <div className="wk-center bg-white round-medium br-grey" style={{width:'200px'}}>
        <Handle type="target" position="top" style={{borderRadius:0}} />
        <CollegeList colleges={colleges} selectedCollege={selectedCollege} collegeUserViews={collegeUserViews}/>
      </div>
      <Handle type="source" position="bottom" id="a" style={{borderRadius:0}}/>
    </>
  )
}

export {AddActivity, AddDataSource, AddSuggestedActivity, AddCollege};

/*
<span className="fa-stack fa-2x" onClick={e=>data.selectedCollege(data)}>
          <i className="fas fa-square fa-stack-2x"/>
          <i className="fa-stack-1x fa fas fa-landmark white"/>
        </span>
        <div className="black">{data.label}</div>
*/


/*

<div className="p-20" style={{maxHeight:'400px'}}>
            <BarChart series={chart.series} x_axis={chart.x_axis} 
              title={chart.title} chart_type={chart.chart_type}
               colors={colors}
               markers={markers}
              className="clearfix" 
              id={'line'} height={height}/>
          </div>*/