import React, {useState, useEffect, useContext, Fragment} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import UserRole from '../../Journey/UserRoles/UserRole'
import UserRoleService from "../../Journey/UserRoles/UserRoleService";
import ConfirmMenu from '../../Modals/ConfirmMenu'
import AddRole from '../../Journey/UserRoles/AddUserRole'
import {Link} from "react-router-dom";
import useModal from '../../../hooks/useModal';
import useStyle from '../../../hooks/useStyle';
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import JourneyTitle from "../../Journey/JourneyTitle/JourneyTitle";
import TeamActivityService from "../TeamJourney/Activities/TeamActivityService";
import ActivityService from "../HighSchoolJourney/Activities/ActivityService"
import CollegeActivityService from "../CollegeJourney/Activities/CollegeActivityService"
import CoursePreparationsService from "../CourseJourney/CoursePreparations/CoursePreparationsService"
import SideMenuBar from "../../../components/Common/SideMenuBar";
import UsersRolesListTable from "./UsersRolesListTable"
import NotificationPopup from '../../Common/NotificationPopup'
import HelpMessageList from '../../../constants/HelpMessageList'
import GenericModal from "../../Modals/GenericModal";
import Cs from "../../../services/CommonService"
import querystringify from "querystringify";

let requestParams = {}
let newUserRole = {}
let selectedUserRole = {}
let dataSources = {}
let roles = []
let invited_journey_members = []

const UserRoleList = (props) => {
  useStyle('user_lists');
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let {journey, activity} = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
  
  const { state:currentUser, dispatch } = useContext(AuthContext);
  const [userRoles, setUserRoles] = useState([]);
  const [privacyType, setPrivacyType] = useState(activity.privacy_type);
  const [showTableView, setShowTableView] = useState(false);
  const [loading, setLoading] = useState(false);

  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal();
  const {isOpen:isNewRolePopupOpen, toggleModal:toggleNewRolePopup} = useModal();
  const {isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage} = useModal();

  let dataSources = {
    'notificationsTypes' : {
      'hourly':'Hourly Report',
      'daily_digest':'Daily Digest',
      'immediate':'Immediate',
    },
    'permissions':{
      'activity_admin':'Full Access (Admin level permission)',
      /*'add_review':'Add Comments',
      'add_checklist':'Create Checklists',
      'add_step_activity':'Create Step Activities',*/
    },
    'roleType':{}
  }

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id':params.journey_category_id,
      'roleable_id': params.roleable_id,
      'roleable_type': params.roleable_type,
      'add_invited_journey_member':true
    }

    getRoleList();
  }, [props.searchString]);

  let getRoleList = () => { 
    setLoading(true)
    UserRoleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        //roles = res.data.roles;
        //dataSources = res.data.data_source_list;
        //dataSources.roleType = roles.reduce((obj, item) => (obj[item.record_id] = item.role, obj) ,{}); 
        //invited_journey_members = res.data.invited_journey_members;*/
        requestParams.totalItems = res.data.meta.total
        roles = res.data.roles.reduce((obj, item) => (obj[item.record_id] = item, obj) ,{});
        setUserRoles(res.data.user_roles) 
        res.data.user_roles.length == 0 && toggleHelpMessage()
        setLoading(false)
      }
    })
  }

  const deleteRole = (e) => {
    toggleDeletePopup(e)
    setLoading(true)
    UserRoleService.delete(selectedUserRole.id).then((res) => {
      if(res.status==204){
        selectedUserRole.id = null
        setUserRoles([...userRoles])
        setLoading(false)
        //setUserRoles(userRoles.filter(r => r.record_id !== selectedUserRole.record_id));
      }
    })    
  }

  const setEdit = (e, userRoles) => {
    newUserRole = userRoles;
    toggleNewRolePopup(e);
  }
  
  const selectUserRole = (e, user_role) =>{
    e.preventDefault();
    selectedUserRole = user_role;
    toggleDeletePopup(e);
  }

  const addNewRole = (e, newUserRole) => {
    e.preventDefault();
    if(newUserRole.id){
      UserRoleService.update(newUserRole).then((res) => {
        if(res.status==204){
          restModal(e);
        }
      })  
    }else{
      newUserRole.created_by = currentUser.user.id;
      newUserRole.journey_category_id = params.journey_category_id;
      newUserRole.journey_proile_id = params.journey_proile_id;
      newUserRole.roleable_id = params.roleable_id;
      newUserRole.roleable_type = params.roleable_type;

      UserRoleService.create(newUserRole).then((res) => {
        if(res.status==201){
          let invite_user = userRoles.find(r => r.user_registration_id == newUserRole.user_id)
          //invite_user.id = res.data.id;
          Object.assign(invite_user, res.data);
          setUserRoles([...userRoles])
          restModal()
        }
      })  
    }
  }

  const restModal = (e) =>{
    newUserRole = {}
    toggleNewRolePopup(e);
    //getRoleList();
  }

  const addUser = (e, invite_user) =>{
    newUserRole = {
      filter_type: 'user',
      email: invite_user.email,
      user_id: invite_user.user_registration_id
    }
    toggleNewRolePopup(e);
    //addNewRole(e, invite_user)
  }

  /*const updateActivityPrivacy = (e) => {
    e.preventDefault();
    let req = {
      'id':activity.id,
      'privacy_type':(activity.privacy_type=='public'?'private':'public'),
      'journey_category_id':activity.journey_category_id,
      'education_journey_profile_id':activity.education_journey_profile_id
    }
    let Service = {}
    if(params.roleable_type == 'edu_team_journey.activity'){
      Service = TeamActivityService
    }else if(params.roleable_type == 'education_journal_entry'){
      Service = ActivityService
    }else if(params.roleable_type == 'edu_college_journey.activity'){
      Service = CollegeActivityService
    }else if(params.roleable_type == 'academic_courses.course_preparation'){
      Service = CoursePreparationsService
    }

    Service.update(req).then((res)=>{
      if(res.status==204){
        props.location.state.activity.privacy_type = req.privacy_type;
        setPrivacyType(req.privacy_type);
      }
    })
  }*/

 /*if(userRoles.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  return (
    <Fragment>
      {loading && <div className="spinner"/>}
      
      <div className="content m-t-15 clearfix">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={queryParam.title || activity.data?.name  + " Permissions"} project={journey} />
        </div>
        <div className="col-md-8 pull-right">
          <span href="#" data-tip="Toggle Table" className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onClick={e=>setShowTableView(!showTableView)}>
            <i className="fas fa-table"></i>
          </span>
          <span className="bg-highlight round-btn float-right m-r-5 m-t-4"
            onClick={e=>Cs.routeJourney(journey, props)}>
            <i className="fas fa-home"></i>
          </span>
          <span className="float-right font-15 m-4-12">{requestParams.totalItems} Users</span>
        </div>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {!showTableView &&
          <Fragment>          
            {userRoles.map((role, i) => (
              <div className="col-xs-12 col-sm-6 col-md-4 user-list-container" key={i}>
                <UserRole user_role={role} setEdit={setEdit}
                dataSources={dataSources} selectUserRole={selectUserRole}
                addUser={addUser} roles={roles}/>
              </div>  
            ))}
          </Fragment>  
        }

        {showTableView &&
          <div className="row">
            <UsersRolesListTable userRolesList={userRoles} setEdit={setEdit}
            dataSources={dataSources} getRoleList={getRoleList}
            selectUserRole={selectUserRole} requestParams={requestParams} 
            addUser={addUser}/>
          </div>
        }
      </div>

      <ConfirmMenu isOpen={isDeletePopupOpen} success={deleteRole} toggleModal={toggleDeletePopup}/>
      {isNewRolePopupOpen && <AddRole role={newUserRole} isOpen={isNewRolePopupOpen} toggleModal={toggleNewRolePopup} addNewRole={addNewRole} dataSources={dataSources} roles={roles} invited_journey_members={invited_journey_members}/>}
      {isHelpMessageOpen && (
        <NotificationPopup header="Note" message={HelpMessageList.team_activity_role} onSuccess={toggleHelpMessage}>
         <Link to={{pathname:`/invite/user/${params.journey_category_id}/${params.journey_proile_id}`, state:props.location.state}} className="green font-15 underline"> 
            Click here to invite users to this high school journey.
         </Link>
        </NotificationPopup>
      )}
    </Fragment>
  )

};

export default UserRoleList;

/*
<div className="col-xs-12">
          <div className="bg-white p-5">
          <label className="radio-container">
            <span>Restrict access to this tile based on the settings below.</span>
            <input type="radio" onClick={e=>updateActivityPrivacy(e)} 
            checked={privacyType=='private'} readOnly/>
            <span className="radio-checkmark"></span>
          </label>

          <label className="radio-container">
            <span>Allow access to this tile and posting of comments by any users with access to this journey ribbon.</span>
            <input type="radio" onClick={e=>updateActivityPrivacy(e)}  
            checked={privacyType=='public'} readOnly/>
            <span className="radio-checkmark"></span>
          </label>
          </div>
        </div>
*/

/*
 {privacyType == 'private' && isHelpMessageOpen && (
        <GenericModal component={NotificationPopup} header="Note"
          message={HelpMessageList.team_activity_role} onSuccess={toggleHelpMessage}
          isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      )} 
*/