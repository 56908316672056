import React, {Fragment, useState, useMemo, useEffect} from "react"
import useStyle from '../../hooks/useStyle'
import Cs from "../../services/CommonService"
import useModal from "../../hooks/useModal"
import { Link } from 'react-router-dom'

const SubscriptionCompareTable = (props) => {
  useStyle('table')
  
  let {subscriptions, accessDataDources, isAuthenticated, toggleModal, selectSubscription, accountInformation} = props
  
  const [paymentType, setPaymentType] = useState('yearly')
  const [selectedPlan, setSelectedPlan] = useState()

  const {isOpen:isConfirmPlanModalOpen, toggleModal:toggleConfirmPlanModal} = useModal()

  useEffect(()=>{
    setSelectedPlan()
  }, [paymentType])

  const exists = (subscription, component_pk_id, component_data_source_id) =>{
    const stageDsId = subscription.data.stage_data_source_id
    let initialStageDataId = null
    if(stageDsId){
      const stageList = accessDataDources[stageDsId]?.options || [] 
      initialStageDataId = stageList[0]?.pk_id
    }

    const i = subscription.subscription_accesses?.find(s => (
      initialStageDataId == s.level_data_source_list_id
      && s.data_source_id == component_data_source_id 
      && s.data_source_list_id == component_pk_id
    ))

    return i
  }

  return(
    <Fragment>
      <div className="ml-center bg-black white p-5">
        <h5 className="bold-600">Comparison of High School Journey Subscription Plans</h5>
      </div>

      <div className="table-responsive table-fix-head p-0 ht-600 m-b-60">
        <table className="shadow-small w-800">
          <thead>
            <tr>
              <th className="p-2 bold-400 text-center w-95 vam">
                <div className="m-t-5">
                  <span onClick={()=>setPaymentType('yearly')} className={`fas ${paymentType=='yearly'?'fa-check-circle':'fa-circle'} lgrey`}>
                    <span className="m-l-5 m-r-15 black">Annual</span>
                  </span>

                  <span onClick={()=>setPaymentType('monthly')} className={`fas ${paymentType=='monthly'?'fa-check-circle':'fa-circle'} lgrey`}>
                    <span className="m-l-5 m-r-15 black">Monthly</span>
                  </span>
                </div>
              </th>
              <Tablehead subscriptions={subscriptions}
              isAuthenticated={isAuthenticated}
              accountInformation={accountInformation}
              selectSubscription={selectSubscription}
              paymentType={paymentType}
              selectedPlan={selectedPlan} 
              setSelectedPlan={setSelectedPlan}/>
            </tr>
          </thead>
          <tbody className="sub-cmp">
            <TableBody subscriptions={subscriptions} 
              accessDataDources={accessDataDources} 
              exists={exists}/>
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

const Tablehead = (props) => props.subscriptions?.map((s, k) =>
  <Plan s={s} {...props} key={k}/>
)

const Plan = ({s, isAuthenticated, accountInformation, selectSubscription, paymentType, selectedPlan, setSelectedPlan}) =>{

  const isActivePlan = false
  //s.id == accountInformation?.subscription_id
  
  const bodyStyle = {
    'backgroundColor':(s.marketing_data.header || 'rgb(84, 84, 75)'),
    'color':'white'
  }

  return(
    <Fragment>
      <th className="text-center w-95 vat">
        <div style={bodyStyle} className="bold-400 p-2 br-15">{s.data.name}</div>
        {s.data.is_trial_subscription == 1 ? 
          <div className="m-t-10 pointer plan-my" onClick={()=>setSelectedPlan(s.id)}>
            <h2 className="h1 bold-700">
              <span className={`fas ${selectedPlan == s.id?'fa-check-circle':'fa-circle'} lgrey m-r-15`}/>
              {s.data.trial_period_in_days} Days
              <div className="font-13 font-600 m-l-2"> Free Trail</div>
              <div className="font-13 font-400">Trail ends after free period</div>
            </h2>
          </div>
          :
          <Fragment>
            {paymentType=='yearly' &&
              <div className="m-t-10 pointer plan-my" onClick={()=>setSelectedPlan(s.id)}>
                <h2 className="h1 bold-700">
                  <span className={`fas ${selectedPlan == s.id?'fa-check-circle':'fa-circle'} lgrey m-r-15`}/>
                  ${s.data.annual_fee}
                  <span className="font-13 font-600 m-l-2"> USD / Year</span>
                  <div className="font-13 font-400">To be paid in advance annually</div>
                </h2>
              </div>
            }

            {paymentType=='monthly' &&    
              <div className="m-t-10 pointer plan-my" onClick={()=>setSelectedPlan(s.id)}>
                <h2 className="h1 bold-700">
                  <span className={`fas ${selectedPlan == s.id?'fa-check-circle':'fa-circle'} lgrey m-r-15`}/>
                  ${s.data.monthly_fee}
                  <span className="font-12 font-600 m-l-2"> USD / Monthly</span>
                  <div className="font-13 font-400">To be paid in advance monthly</div>
                </h2>
              </div>
            }
          </Fragment>
        }
          
        {isAuthenticated &&  
          <Fragment>
            {selectedPlan == s.id && 
              <>
                <div className="m-l-5 font-14 font-600 p-2 text-center">
                  By clicking select below, I agree to the 
                  <Link to="/subscription_terms"><u>Terms and Conditions</u></Link>
                </div>

                <div onClick={e=> isActivePlan ? '' : selectSubscription(s)} style={bodyStyle}
                  className="button button-xs button-center-large button-round-large uppercase m-b-10 m-t-15">
                  {isActivePlan ? "Active":"Select"}
                </div>
              </>
            }
          </Fragment>
        }
      </th>
    </Fragment>
  )
}

const TableBody = ({subscriptions, accessDataDources=[], exists}) => Object.keys(accessDataDources).map((o, k) =>{
  return(
    <Fragment key={k}>
      <tr>
        <td className="text-left font-18 bold-600" colSpan="3">
          {accessDataDources[o].label}
        </td>
        <td>
          <i className="fas fa-chevron-down black pull-right m-t-5 m-r-10"/>
        </td>
      </tr>       
      <TableBodyRows subscriptions={subscriptions} 
        components={accessDataDources[o].options}
        componentDataSourceId={accessDataDources[o].id}
        exists={exists}/> 
      <tr className="br-btm-grey"></tr>
    </Fragment>
  )
})

const TableBodyRows = ({subscriptions, components=[], componentDataSourceId, exists}) => components.map((o, k) =>{
  return(
    <tr key={k}>
      <td className="text-left p-l-30">
        <div className="font-16">{o.label}</div>
        <div className="black-light-1 font-14">{o.description || o.detail}</div>
      </td>
      <TableBodyCol subscriptions={subscriptions} component={o} componentDataSourceId={componentDataSourceId} exists={exists}/>
    </tr>
  )
})

const TableBodyCol = ({subscriptions=[], component, componentDataSourceId, exists}) => subscriptions.map((p, k) =>{
  const checkedItem = exists(p, component.pk_id, componentDataSourceId)

  return(
    <Fragment key={k}>
      <td className="text-center font-15">
        {checkedItem?
          <i className="fa fa-check green"/>
          :
          (p.level_data?.[component.pk_id]?
            <p>{p.level_data?.[component.pk_id]}</p>
            :
            <i className="fas fa-times lred"/>
          )
        }
      </td>
    </Fragment>
  )
})

export default SubscriptionCompareTable;