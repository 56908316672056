import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PortalHeader from "../../../../components/Journey/CourseJourney/CoursePortalPage/PortalHeader";
import PortalPath from "../../../../components/Journey/CourseJourney/CoursePortalPage/PortalPath";
import JourneyCategoryService from "../../JourneyCategoryService";
import useStyle from "../../../../hooks/useStyle";
import Teamnodes from "../../../../constants/Teamnodes";
import CheckAccess from "../../Roles/CheckAccess";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import SideMenuBar from "../../../../components/Common/SideMenuBar";

let portalPath = null;

const PortalDetails = (props) => {
  useStyle("panel");
  useStyle("path");

  const { state: { screen, user: currentUser }, dispatch,} = React.useContext(AuthContext);

  const [project, setProject] = useState({});
  const [statics, setStatics] = useState({});
  let params = props.match.params;

  React.useEffect(() => {
    let req = {
      journey_category_id: params.journey_category_id,
      show_journey_templates: false,
      data_source_params: {
        data_source_id: [3326],
        response_type: "object",
        group_by: "sid",
      },
    };

    JourneyCategoryService.getJourneyById(req).then((res) => {
      if (res.status == 200) {
        res = res.data;
        res.journey_category = res.journey_category[0];
        portalPath = res.data_source_list.ct_life_team_nodes;
        /* if(res.journey_category.education_journey_profiles.data.fj_type_edu_jou == 14){
         }else{
          PortalPath = Teamnodes.indi_path_nodes;
        }*/
        sessionStorage.activeJourney = JSON.stringify(res);
        CheckAccess.userAccess(
          currentUser,
          res.journey_category,
          res.invited_journey_member
        );
        setStatics(res.statics);
        checkPathAccess(res.journey_category);
        setProject(res.journey_category);
      }
    });
  }, []);

  const checkPathAccess = (journey_category) => {
    if (!journey_category.is_admin_user) {
      Object.values(portalPath.options).map((path, k) => {
        if (path.sid == "planners" || path.sid == "roles") {
          delete portalPath.options[path.sid];
        }
      });
    }
  };

  const routePath = (e, path) => {
    e.preventDefault();
    if (path.sid == "planners") {
      props.history.push(
        `/course/planners/${params.journey_category_id}/${project.education_journey_profiles.id}`,
        project
      );
    } else if (path.sid == "roles") {
      props.history.push(
        `/journey/role/${params.journey_category_id}/${project.education_journey_profiles.id}`,
        project
      );
      /*}else if(path.sid == 'contract'){
      props.history.push(`/list/project/contracts/${project.id}`)*/
    } else if (path.sid == "activities") {
      props.history.push(
        `/course/preparation/${params.journey_category_id}/${project.education_journey_profiles.id}`,
        project
      );
    } else if (path.sid == "calendars") {
      props.history.push(
        `/course/schedules/${params.journey_category_id}/${project.education_journey_profiles.id}/academic_courses`,
        project
      );
    }
  };

  if (!project.id) return <PreLoadSpinner />;

  return (
    <Fragment>
      <div className="p-5">
        <div className="portlet">
          <PortalHeader project={project} statics={statics} app={props.app} />
          <div id="bg-info" className="panel-collapse collapse in" style={{ minHeight: "250px" }}>
            <div className="portlet-body">
              <PortalPath portalPath={portalPath} routePath={routePath} statics={statics}/>
              <small className="font-12 p-5 lh-16">3326</small>
            </div>
          </div>
        </div>
      </div>
      <SideMenuBar helpId="14" />
    </Fragment>
  );
};

export default PortalDetails;