import BaseService from "../../services/BaseService"
import env from '../../env'
import axios from 'axios'

class UserFormService extends BaseService{

  getEmailNotificationList(id, req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+'email_notification/user_forms/'+id, {params: req}).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }	

  clone(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'clone/user_forms', req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  search(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'search/user_forms', req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  report(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+'report/user_forms', req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }
  
}

export default new UserFormService('backend', 'user_forms');