import React, {Fragment} from "react"
import PrivateRoute from "../../../routes/PrivateRoute"
import ScholarshipList from "./ScholarshipList"
import ScholarshipForm from "./ScholarshipForm"

const ScholarshipRouter = (props) => (
  <Fragment>
    <PrivateRoute
      path="/admin/scholarships"
      searchString={props.searchString}
      component={ScholarshipList}
      permissionType='role_manager.enrichment'
      exact
    />
    <PrivateRoute
      path="/new/scholarship/:id?"
      component={ScholarshipForm}
      exact
    />
  </Fragment>
)

export default ScholarshipRouter;