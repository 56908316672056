import React ,{useContext} from "react";
import env from "../../../env";
import Cs from "../../../services/CommonService";
import AddToFavourite from "../../Common/AddToFavourite"
import { Link, useHistory } from "react-router-dom";
import { HamburgerMenuIcon } from "../../Common/ImageIcons";
import CheckAccess from "../Roles/CheckAccess"

const CourseJourney = (props) => {

  let { journey, users, currentUser,dataSources, cardStyle } = props;
  let data = journey.education_journey_profiles.data;
  let ds = journey.education_journey_profiles.data_source;
  let bodyStyle = { height: "238px" };
  let dsource = props.dataSources.fj_type_edu_jou.options
  const history = useHistory();
  
  const category = (key) => {
    return (
      <div className="card-title flex justspacebetween m-t-20 p-b-15">
        <div className="flex aligncenter">
          <div className="card-icon-small border-rounded card-icon-blue-bg flex aligncenter justifycenter m-r-15">
            <i className={dsource[key].icon} style={{color: dsource[key].icon_color ? dsource[key].icon_color :'black'}}/>
          </div>
          <div>
            <h4 className="fw700 f14 card-heading-blue">
              {dsource[key].label}
            </h4>
          </div>
        </div>
      </div>
    )
  }

  const route = () =>{
    history.push(`/course/portal/${journey.id}`, journey)
    /*if(!CheckAccess.isDevUser(currentUser.email, env) && !journey.is_demo_journey && !journey.subscription_user_id){
      history.push({
        pathname:`/plans/${journey.id}`+(journey.journey_member_id?`/${journey.journey_member_id}`:''),
        search:'?journey_type='+(data?.fj_type_edu_jou)
      })
    }else{
      history.push(`/course/portal/${journey.id}`, journey)
    }*/
  }

  return (
    <div className="bg-white border-rounded-10 p20" style={cardStyle}>
      <div onClick={e=>Cs.routeJourney(journey, {history:history}, false, currentUser)}
        className="card-title flex aligncenter justspacebetween border-dashed-sm p-b-15">
        <div className="flex aligncenter justifycenter">
          <div className="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
            <img src={Cs.getIconByType(users[journey.created_by].profile_image, 'profile_image')} className="icon-gray" alt="" width="50"/>
          </div>
          <div>
            <h4 className="fw700 card-heading">{dataSources.fj_type_edu_jou.options[data.fj_type_edu_jou].label}</h4>
            <p className="text-gray f12">since {journey.updated_at}</p>
            <p className="text-gray f12">by {users[journey.created_by].name}</p>
          </div>
        </div>
        <div>
          <div className="menu-drop-icon flex justifycenter aligncenter m-7">
            <img src="./images/arrow-right.png" alt=""/>
          </div>
          <div className="menu-drop-icon flex justifycenter aligncenter m-7"> 
            <AddToFavourite favouriteId={journey.favourite_id} favorableType="journey_category" 
              favorableId={journey.id} journeyCategoryId={journey.id} classNameActive="pin-0 green" 
              classNameDisable="pin-0 lgrey"
              journeyProfileId={journey.education_journey_profiles.id}/>
          </div>
        </div>
      </div>
      {category(data.fj_type_edu_jou)}
      <div className="card-content">
        <div className="flex coldir">
          <div className="m-b-15 fw500">
            <span>
              <b>Subject: </b>
              {ds.subject.label}
            </span>
            <span className="pull-right">
              <b>Grade: </b> {ds.grade_enrollment[0].data.label}
            </span>
          </div>
        </div>
      </div>
      <div className="card-footer flex justspacebetween aligncenter">
        <ul className="flex aligncenter"></ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, journey)}/>
      </div>
    </div>
  )
}

export default CourseJourney;