import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import moment from "../../../../node_modules/moment/min/moment.min.js"
import Cs from "../../../services/CommonService"
import ReactTooltip from 'react-tooltip'
import {PillDsList} from "../../Journey/UserRoles/NotificationPill"
import ForumResponseService from "../ForumResponseService"
import ForumRatingService from "../ForumRatingService"

const Forum = (props) => {
  let profileImage = "images/avatars/2s.png"
  
  const [forum, setForum] = useState(props.forum)
  const [toggle, setToggle] = useState(false)
  const [responses, setResponses] = useState([])

  let data = forum.data

  useEffect(() => {
    ReactTooltip.rebuild()
    toggle && getResponses()    
  }, [toggle])

  const getResponses = () =>{
    let req = {
      'per_page': 150,
      'page': 1,
      'sort_column' : 'updated_at',
      'sort_direction' : 'desc',
      'discussion_request_id': forum.id
    }
    ForumResponseService.getAll(req).then((res)=>{
      if(res.status == 200){
        setResponses(res.data.discussion_responses)
      }
    })
  }

  const setRating = (action_type) =>{
    let req = {
      item_type: 'discussion_request',
      item_id: forum.id,
      action_type: action_type
    }
    ForumRatingService.create(req).then((res)=>{
      if(res.status == 201){
        setForum({...forum, [action_type]: (forum[action_type]+1)})
      }
    })
  }

  const unsetFavorite = () =>{
    ForumRatingService.delete(forum.id, {action_type:'favourite'}).then((res)=>{
      if(res.status == 201){
        setForum({...forum, 'favourite': 0})
      }
    })
  }

  const replyList = responses.map((a, i) => {
    return (
      <div className="d-flex" key={i}>
        <div className="card-header">
          <img src={profileImage} className="rounded-circle bg-lgrey-2" width="50"/>
        </div>
        <div className="card-body">
          <p className="font-15" dangerouslySetInnerHTML={{ __html: a.data.response}}></p>
        </div>
      </div>
    )
  })

  const ReplyCount = () => {
    if (forum.discussion_response_count) {
      if (forum.discussion_response_count > 1) {
        return forum.discussion_response_count + " replies"
      } else {
        return forum.discussion_response_count + " reply"
      }
    } else {
      return null
    }
  }

  return (
    <div className="container-fluid mt-100">
      <div className="card mb-4">
        <div className="card-header">
          <div className="media flex-wrap w-100 align-items-center">
            <img src={profileImage} className="d-block ui-w-40 rounded-circle" alt=""/>
              <div className="media-body ml-3">
                <a href="#" data-abc="true" className="font-16">
                  {forum.created_user.name}
                </a>
                <div className="text-muted small font-14">
                  {Cs.formatUpdateDate(forum.created_at)}
                </div>
              </div>
              <div className="text-muted small ml-3 font-12">
                <div>
                  Member since <strong>01/1/2019</strong>
                </div>
                <div>
                  <strong>{forum.discussion_response_count}</strong> posts
                </div>
              </div>
            </div>
          </div>
          <div className="card-body font-15">
            <div className="pull-right d-flex font-16">
              <props.reply forum={forum} />
              <props.edit forum={forum} />
              <props.delete forum={forum} />
            </div>
            <p>{data.subject} </p>
            <PillDsList list={forum.data_source?.section} dataKey="label" style="pill bg-highlight white"/>
            <p dangerouslySetInnerHTML={{ __html: data.post }}></p>
            <div className="highlight underline font-14" onClick={(e) => setToggle(!toggle)}>
              <ReplyCount />
              {toggle ? replyList : ""}
            </div>
          </div>
          <div className="card-footer d-flex flex-wrap justify-content-between align-items-center px-0 pt-0 pb-3">
            <div className="px-4 pt-3 font-16">
              <i className="fa fa-heart lgrey" data-tip="Favourites" onClick={e=>{forum.favourite>0?unsetFavorite():setRating('favourite')}}></i>
              <span className="badge up-step align-middle m-r-15 top-n10">{forum.favourite}</span>

              <i className="fa fa-eye fsize-3 lgrey" data-tip="Views"></i>
              <span className="badge up-step align-middle m-r-15 top-n10">0</span>

              <i className="far fa-thumbs-up fsize-3 lgrey" data-tip="Up Votes" onClick={e=>setRating('upvote')}></i>
              <span className="badge up-step align-middle m-r-15 top-n10">{forum.upvote}</span>

              <i className="far fa-thumbs-down fsize-3 lgrey" data-tip="Down Votes" onClick={e=>setRating('downvote')}></i>
              <span className="badge up-step align-middle m-r-15 top-n10">{forum.downvote}</span>
            </div>
              
          </div>
        </div>
    </div>
  )
}

export default Forum;