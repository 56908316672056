import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Cs from "../../../../services/CommonService";
import { PillDsField } from "../../UserRoles/NotificationPill";
import ReactTooltip from "react-tooltip";
import env from "../../../../env";

const HSReel = (props) => {
 

  const cardStyle = {
    backgroundColor: "#607D8B",
  };


  /*const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16">{data.name}</div>
      <div className="font-16">{counseling.updated_at}</div>
    </div>
  );*/

  /*const Footer = () => {
    return (
      <div className="card-footer">
        <div className="pull-left">
          <span
            className="m-l-25"
            onClick={(e) => props.addNotes(e, counseling)}
          >
            <i
              className="far fa-comment font-18 text-muted"
              data-tip="Chat"
            ></i>
            <span className="badge up bg-lgrey">
              {counseling.comments_count || 0}
            </span>
          </span>
          <span
            className="m-l-25"
            onClick={(e) => props.addChecklist(e, counseling)}
          >
            <i
              className="far fa-check-circle font-18 text-muted"
              data-tip="Checklist"
            ></i>
            <span className="badge up bg-lgrey">
              {counseling.check_list_count || 0}
            </span>
          </span>
          {env.admin_emails.includes(props.currentUser.email) && (
            <span
              className="m-l-25"
              onClick={(e) => props.openMapper(counseling)}
            >
              <i
                className="fas fa-hands-helping font-18 text-muted"
                data-tip="Add Data Source Mapper"
              ></i>
            </span>
          )}
        </div>
        <div className="pull-right">
          <props.menu counseling={counseling} />
        </div>
      </div>
    );
  };*/

  const CounselingCard = () => {
    return (
      <div className="card-container bg-white shadow-small w-350 m-r-15">
        <div className="content-boxed shadow-small p-t-0">
          <div className="content vcard-header">
            <ul className="d-flex">
              <li></li>
              <li className="">
                <span className="black-light-1">
                  <div className="vcard-title black-light-1 ellipsis">
                    New Oakridge high school
                  </div>
                  <span className="lred">since Jan 25, 2022</span>
                  <span> by n.niranjan.v+123</span>
                </span>
              </li>
              <li></li>
            </ul>
          </div>
          <div className="content">
            <span className="pull-right">
              <b>Grade: </b> 12
            </span>
            <span>
              <div>
                <i className="fa fa-graduation-cap m-r-10"></i>Grades 8 through
                12
              </div>
            </span>
            <div className="vcard-field">
              <b>Subject of Most Interest: </b>
              <span className="pill white">Health / Medical </span>
            </div>
            <div className="vcard-field">
              <span>
                <b>Interest: </b>
                <span className="pill white">Health and Medicine </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <CounselingCard />;
};

export default HSReel;
