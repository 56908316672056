import React, { Fragment, useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'
import useStyle from '../../hooks/useStyle'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import SubscriptionAccessesService from './SubscriptionAccessesService'

const UserAccessComponents = ({isOpen, toggleModal, subscription, journeyMemberId}) => {
  useStyle("add_role_dialog")
  useStyle("filter_dialog")
  
  const history = useHistory()
  
  const { state:{user:currentUser}} = useContext(AuthContext)

  const [components, setComponents] = useState({})
  const [loading, setLoading] = useState(true)
  const [index, setIndex] = useState(null)

  useEffect(()=>{
    SubscriptionAccessesService.getUserAccesses({
      subscription_id: subscription?.id,
      journey_member_id: journeyMemberId
    }).then(({status, data})=>{
      if(status == 200){
        setComponents(data)
        setLoading(false)
      }
    })
  }, [])

  const Components = ({list=[], dataSourceId}) =>{
    return(
      <Fragment>
        <div className="popup-menu-grid br-grey" id={`dsl_${dataSourceId}`}>
          {list.map((o, k) =>
            <div className="ver-icons" key={k}>
              <span>
                <i className={o.icon || "fas fa-plus"} style={{color: o.icon_color}}/>
              </span>
              <div className="popup-menu-title">{o.label}</div>
              <div className="m-l-40 black-light-1">{o.description || o.detail}</div>
            </div>
          )}
        </div>
      </Fragment>  
    )
  }

  const DataSource = () => Object.keys(components).map((o, k) =>{
    const color = components[o]?.settings?.bg_color || '#5E5E5E'
    const style = {borderLeft: `6px solid ${color}`}
    
    return(
      <Fragment key={k}>
        <div className="black p-5 font-15 br-grey m-b-10 m-t-10" id={`ds_${o}`} 
          onClick={e=> index === k ? setIndex(null) : setIndex(k)} 
          style={style}>
            {components[o].label}
            <i className={`fas ${index === k ? 'fa-chevron-up' : 'fa-chevron-down'} black pull-right m-t-5 m-r-10`} 
              onClick={e=>setIndex(index === k?null:k)}
            />
        </div>
            
        {(index === k || index === "5") && <Components list={components[o].options} dataSourceId={o}/>}
      </Fragment>
    )
  })
    
  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Services available in {subscription?.name}</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          {loading?
            <div className="spinner"/>:
            <div className="p-10 scroll-x ht-400">
              <span className="checkbox-container" onClick={e => index === "5" ? setIndex(null) : setIndex("5")}>
                <span className="font-15 bold-500">View all</span>
                <input type="checkbox" checked={index === "5"} readOnly/>
                <span className="checkbox-checkmark"/>
              </span>
              <DataSource/>
            </div>
          }
            
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default UserAccessComponents;