import React, {useState} from "react"
import {NotificationBadge} from "./BadgePill"
import {Link} from "react-router-dom"

const TagContainer = (props) =>{
  if(props.tag == 'a'){
    return(
      <Link {...props}>{props.children}</Link>  
    )
  }else if(props.tag == 'span'){
    return(
      <span onClick={(e)=>props.onSelect(e)} {...props}>{props.children}</span>  
    )
  }
}

const PhoneIcon = ({title}) => {
  const defaultCss = 'gray-icon tcenter flex aligncenter justifycenter m-r-5'
  return (
    <>
      <span className={defaultCss}>
        <img src="images/phone-icon.png" alt="" />
      </span>
      {title}
    </>
  )
}

const MapIcon = ({title}) => {
  const defaultCss = 'gray-icon tcenter flex aligncenter justifycenter m-r-5'
  return (
    <>
      <span className={defaultCss}>
      <img src="images/map-icon.png" alt="" /></span>
      {title}
    </>
  )
}

const LinkMapIcon = ({onSelect, link}) => {
  const defaultCss = 'table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} href="#" className={defaultCss}>
      <img src="images/map-icon.png" alt=""/>
    </TagContainer>
  )
}

const LinkMailIcon = ({onSelect, link, count}) => {
  const defaultCss="table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10 pos_relative"
   
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="images/mail-icon.png" alt="" />
      <NotificationBadge count={count}/>
    </TagContainer>
  )
}                      

const LinkUserIcon = ({onSelect, link}) => {
  const defaultCss = 'table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="images/user-icon.png" alt="" />
    </TagContainer>
  )
}                      

const LinkDeleteIcon = ({onSelect, link}) => {
  const defaultCss = 'table-icon table-icon-lgrey flex aligncenter justifycenter m-l-5'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="images/delete-icon.png" alt="" />
    </TagContainer>
  )
} 

const LinkFontAwesomeIcon = ({onSelect, link, css, count}) => {
  const defaultCss = 'table-icon table-icon-lgrey flex aligncenter justifycenter pos_relative'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <span className={css}/>
      {count && <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>}
    </TagContainer>
  )
}

const EnvelopeGrayIcon = ({onSelect, link, css, count}) =>{
  const defaultCss = 'pos_relative'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/envelope-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const EditGrayIcon = ({onSelect, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/edit-icon-gray.png" />
    </TagContainer>
  )
}

const HamburgerMenuIcon = ({id, onSelect, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer id={id} tag={link ? 'a':'span'} to={link} onSelect={onSelect}>
      <img src="./images/hamburger-meni-icon-gray.png" alt="" />
      {/*<i className="fas fa-bars font-16" />*/}
    </TagContainer>
  )
}

const MaximizeIcon = ({onSelect, link, css, children}) =>{
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={css}>
      <img src="./images/maximize-icon.png" />
    </TagContainer>
  )
}

const ChatGrayIcon = ({onSelect, count, link, css}) => {
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      {/* <img src="./images/mesage-icon-gray.png" /> */}
      <i className="far fa-comment font-16" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const CheckListGrayIcon = ({onSelect, count, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/check-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )  
}

const UserGroupGrayIcon = ({onSelect, count, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/user-group-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )                      
}

const MapGrayIcon = ({onSelect, count, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/map-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const BookmarkGrayIcon = ({onSelect, count, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/bookmark-icon-gray.png"/>
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const EyeGrayIcon = ({onSelect, count, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/eye=icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}
                        
const GlobeGrayIcon = ({onSelect, count, link, css}) =>{
  const defaultCss = 'pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss}>
      <img src="./images/globe-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
} 

export {GlobeGrayIcon, MaximizeIcon, EnvelopeGrayIcon, EditGrayIcon, HamburgerMenuIcon, 
  PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon, 
  LinkFontAwesomeIcon, EyeGrayIcon, BookmarkGrayIcon, MapGrayIcon, UserGroupGrayIcon,
  CheckListGrayIcon, ChatGrayIcon,
};