let CheckAccess = {}
let role = {}

CheckAccess.userAccessTeam = (currentUser, journey, invited_journey_member) =>{
  sessionStorage.setItem('is_journey_admin', false)
  if(currentUser.id == journey.updated_by ||
    (invited_journey_member && (invited_journey_member.access_type == 'full_access' ||  
    (invited_journey_member.permissions && invited_journey_member.permissions.full_access)))){
      journey.is_admin_user = true;
      journey.is_template_journey = false;
      sessionStorage.setItem('is_journey_admin', true);
  }else if(journey.education_journey_profiles.data.ai_training_user=='Yes'){
      journey.is_template_journey = true;
  }
}

CheckAccess.userAccess = (currentUser, journey = {}, invited_journey_member = {}) =>{
  sessionStorage.setItem('is_journey_admin', false);
  /*
    removed currentUser.id == journey.updated_by
    take care by full_access
  */

  if(currentUser.id == journey.created_by ||
    (invited_journey_member && (invited_journey_member.access_type && invited_journey_member.access_type?.includes('full_access') ||  
    (invited_journey_member.permissions && invited_journey_member?.permissions?.full_access)))){
      journey.is_admin_user = true;
      journey.is_template_journey = false;
      sessionStorage.setItem('is_journey_admin', true);
  }else if(journey.education_journey_profiles?.data?.ai_training_user=='Yes'){
      journey.is_template_journey = true;
  }
}

CheckAccess.hasTeamActivityAccess = (journey, share) =>{
  try{
    if(journey.is_admin_user || share?.permissions?.add_activity){
      return true;
    }
  }catch(e){
      return false;  
  }
}

CheckAccess.hasStepActivityAccess = (currentUser, journey, activity, share) =>{
  try{
    if(journey.is_admin_user || currentUser.id == activity.author.id){
      return true;
    }else if(activity.user_roles && activity.user_roles.permissions.activity_admin){
      return true;
    }else if(share.permissions.add_step_activity){
      return true;
    }
  }catch(e){
      return false;  
  }
}

CheckAccess.hasChecklistAccess = (journey, activity) =>{
	return (journey.is_admin_user || (activity.user_roles && activity.user_roles.permissions.activity_admin))
}

CheckAccess.hasDeleteAccess = (currentUser, journey, activity) =>{
  try{
  	return (journey.is_admin_user || currentUser.id == activity.created_by 
      || (activity.user_roles && activity.user_roles.permissions.activity_admin))
  }catch(e){
    return false
  }  
}

CheckAccess.hasEditAccess = (currentUser, journey, activity, share) => {
  try{
	  return (journey.is_admin_user || currentUser.id == activity.created_by
      || (activity.user_roles && activity.user_roles.permissions.activity_admin)
      || share?.permissions?.add_step_activity)
  }catch(e){
    return false
  }
}

CheckAccess.hasRatingAccess = (activity) =>{
	return ((activity.user_roles && activity.user_roles.permissions.activity_admin)  
    || (activity.user_roles && activity.user_roles.permissions.activity_admin))
}

CheckAccess.hasRoleAccess = (currentUser, journey, activity) =>{
	return (journey.is_admin_user || currentUser.id == activity.created_by
    || (activity.user_roles && activity.user_roles.permissions.activity_admin))
}

CheckAccess.isJourneyAuthor = (currentUser, journey)=>{
  return currentUser.id == journey.updated_by
}

CheckAccess.hasInterviewAccess = (currentUser, journey, question)=>{
  return (journey.is_admin_user && (currentUser.id == question.created_by || question.question_type == 'user'))
}

CheckAccess.hasPathAccess = (journey, access_list, component)=>{
  return (journey.is_admin_user || (access_list && access_list.includes(component)))
}

CheckAccess.getUserPermission = (currentUser, type) =>{
  let role_manager;
  try{
    role_manager = currentUser.current_admin.permissions[type]
  }catch(e){
    
  }
  return role_manager || {}
}

CheckAccess.isInviteEditable = (currentUser, journey, invited_user, share) =>{
  /*Check access type issue in strgin agg for role and invite user*/
  try{
    if(invited_user.user_registration_id == currentUser.id){
      return false      
    }else if(journey.created_by == currentUser.id || (share.access_type == 'full_access' && share.permissions && share.permissions.is_primary_admin_removeable)){
      return true
    }
  }catch(e){

  }
}

CheckAccess.isPublishedItemEditable = (currentUser, share, item)=>{
  return (share.role_sid.includes('admin') || (!item.is_published && item.created_by == currentUser.id))
}

CheckAccess.getUserProfileType = (currentUser)=>{
  let temp = {}
  temp.accountType = currentUser.domain_info?.data_source?.type_of_use?.label
  temp.userType = currentUser.domain_info?.data_source?.fj_your_role?.label

  return temp
}

CheckAccess.hasJourneyMemberAccess = (currentUser)=>{
  const accountInfo = CheckAccess.getUserProfileType(currentUser)
  return (
    accountInfo.accountType === 'Personal Use'
    &&
    ['Parent/Guardian', 'Personal Tutor or Counselor'].includes(accountInfo.userType) 
  )
}

CheckAccess.getUserType = (currentUser) =>{
  const accountInfo = CheckAccess.getUserProfileType(currentUser)
  if(accountInfo.accountType === 'Personal Use'){
    if('Parent/Guardian' === accountInfo.userType){
      return 'parent'
    }else if('Personal Tutor or Counselor' === accountInfo.userType){
      return 'tutor'
    }else if('Student' === accountInfo.userType){
      return 'student'
    }
  }else if(accountInfo.accountType === 'Organizational Use'){
    return 'organizational'
  }  
}

CheckAccess.routeUser = (currentUser, history)=>{
  if (currentUser.sign_in_count == 1) {
    history.push("/welcome/message")
  }else if (currentUser.domain_info.data == null) {
    history.push("/profile?show_form_wizard=1")
  }else {
    const accountInfo = CheckAccess.getUserProfileType(currentUser)
    if(accountInfo.accountType === 'Organizational use'){
      if(!currentUser.current_subscription?.id){
        history.push('/plans')
      }else{
        history.push('/home')
      }  
    }else{
      const isParentOrTutor = CheckAccess.hasJourneyMemberAccess(currentUser)
      if(isParentOrTutor){
        if(Object.keys(currentUser.journey_members || [])?.length > 0){
          history.push('/home')
        }else{
          history.push('/member/form/1/1037')
        }
      }/*else if(currentUser.journey_count == 0){
        history.push('/journey_types')
      }*/else if(localStorage.getItem("ct_life_last_url")){
        history.push(localStorage.getItem("ct_life_last_url"))
        localStorage.removeItem("ct_life_last_url");
        //window.open(localStorage.getItem("ct_life_last_url"));
      }else{
        history.push('/home')
      }  
    }
  }

  /*else if (currentUser.setting.welcome_tour == null) {
        //np - next page = security question
        props.history.push("/journeytour?np=sq") 
      }
    if(currentUser.current_admin?.admin_role_name == "demo_user") {
    history.push("/welcome")
  }else 
  */
}

CheckAccess.isDevUser = (email, env)=>{
  return env.admin_emails.indexOf(email) > -1
}
export default CheckAccess;