import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import QuestionList from '../Questions/List/QuestionList'
import QuestionForm from '../Questions/Form/QuestionForm'

const QuestionRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/questions" component={QuestionList} searchString={props.searchString} exact />
    <PrivateRoute path="/admin/questions" component={QuestionList} searchString={props.searchString} permissionType='role_manager.interview' exact />
    <PrivateRoute path="/questions/create/:form_id/:id?" component={QuestionForm} exact />
  </Fragment>
)

export default QuestionRouter;