import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SubscriptionService from "./SubscriptionService";
import useStyle from '../../hooks/useStyle';

function SubscriptionDetails({ location, history }) {
  console.log(location.state.accountInformation)
  useStyle('pricing');

  const [accountInformation] = useState(location.state.accountInformation);
  let [customerPaymentMethod, setCustomerPaymentmethod] = useState(null);
  let [subscriptionCancelled, setSubscriptionCancelled] = useState(false);
  let [selectedProducted, setSelectedProduct] = useState(
    accountInformation.priceId
  );

  useEffect(() => {
    let req = {
      paymentMethodId: accountInformation.paymentMethodId,
    }
    
    SubscriptionService.retrieveCustomerPaymentMethod(req).then((res)=>{
      const paymentMethod = res.payment_method.card.brand + ' •••• ' + res.payment_method.card.last4;
      setCustomerPaymentmethod(paymentMethod);
    })
  }, [accountInformation.paymentMethodId]);

  function handleChangePriceForm() {
    let param = {
      pathname: '/change_plans',
      search: '?change_plan=1',
      state: { 'accountInformation':accountInformation}
    }
    history.push(param)
    //setShowChangePriceForm(true);
  }

  function cancelSubscription() {
    console.log(accountInformation.subscription);
    let req = {
      subscriptionId: accountInformation.subscription.id,
    }
    SubscriptionService.cancelStripeSubscription(req).then((res)=>{
      setSubscriptionCancelled(true);
    })
  }

  return (
    <div className="p-6">
      {subscriptionCancelled ? (
        <div className="col-xs-12 p-7 text-center">
          <h4>Subscription canceled</h4>
        </div>
      ) : (
        <div className="col-xs-12 p-7">
          <div className="round-medium shadow-small bg-white">
            
            <div className="col-xs-12 bg-green-3 white lh1p6 bg-highlight">
              <h4 className="m-t-15">
                Account settings
              </h4>
            </div>

            <div className="col-xs-12 m-t-15">
              <p className="font-16">
                <b>Current price:</b> {selectedProducted}.
              </p>
              <p className="font-16">
                <b>Credit card:</b> {customerPaymentMethod}.
              </p>
            </div>

            <div className="col-xs-12 col-sm-6 m-b-5">
              <div onClick={() => handleChangePriceForm()} className="price-btn bg-green">
                Change pricing plan
              </div>
            </div>
            
            <div className="col-xs-12 col-sm-6 m-b-5">
              <div onClick={() => cancelSubscription()} className="price-btn bg-lred">
                Cancel subscription
              </div>
            </div>
              
          </div>
        </div>
      )}
    </div>
  );
}

export default SubscriptionDetails;
