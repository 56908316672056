import React,{useState} from "react"
import Cs from '../../services/CommonService'

const Table = (props) => {
  return (
    <table className="table table-stripe">
      {props.children}
    </table>
  )
}

const THead = (props) => {
  return (
    <thead className={props.className}>
      {props.children}
    </thead>
  )
}

const TH = (props) => {
  return (
    <th className={props.className} onClick={()=>props.onClick && props.onClick()}>
      {props.children}
    </th>
  )
}

const TR = (props) => {
  return (
    <tr className={props.className} onClick={()=>props.onSelect && props.onSelect()}>{props.children}</tr>
  )
}

const TD = (props) => {
  return (
    <td className={props.className} colSpan={props?.colSpan}>{props.children}</td>
  )
}

const TBody = (props) => {
  return (
    <tbody className={props.className}>{props.children}</tbody>
  )
}            

export {Table, THead, TH, TR, TD, TBody};