import React, { useEffect, useState, Fragment, useContext } from 'react'
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../../../constants/FormTemplateList'
import CommonService from "../../../../../services/CommonService"
import env from '../../../../../env'
import FormContainer from '../../../../FormBuilder/FormContainer'
import CollegeReviewService from "../Reviews/ReviewService";
import {PillDsList} from "../../../../Journey/UserRoles/NotificationPill"

let formMode = null
let review = {}
let data = {}
let formFn = {} 

function EssayTileForm(props) {
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [showForm, setShowForm] = useState(false)
  const [loading, setLoading] = useState(true)
  const [reviewList, setReviewList] = useState([])
  const [post, setPost] = useState({})
  const {selectedPrompt} = props

  const getAll = () => {
    setLoading(true)
    let req = {
      college_cue_id: selectedPrompt.id,  
      form_id: FormTemplateId.EssayPromptReplyForm,
      form_type: 'essay_review'
    }
    CollegeReviewService.getAll(req).then((res) => {
      if(res.status==200){
        setReviewList(res.data.college_cues)
        setLoading(false)
      }
    })
  }
  
  useEffect(() => { 
    review = {}
    data = {}
    formMode = 'create-form-submissions'
    data = {}
    getAll()
  }, [])

  const create = (review) => {
    review.created_by = currentUser.id
    review.education_journey_profile_id = selectedPrompt.education_journey_profile_id
    review.journey_category_id = selectedPrompt.journey_category_id
    review.college_id = selectedPrompt.college_id
    review.favourite_id = selectedPrompt.favourite_id
    review.form_id = FormTemplateId.EssayPromptReplyForm
    review.form_type = 'essay_review'
    review.college_cue_id = selectedPrompt.id
    CollegeReviewService.create(review).then((res) => {
      if(res.status==201){
        res.data.college_cue.created_user = {name:currentUser.name}
        setReviewList([res.data.college_cue, ...reviewList])
        setShowForm(false)
      }
    })
  }

  const update = (review) => {
    CollegeReviewService.update(review).then((res) => {
      if(res.status==204){
        setShowForm(false)
      }
    })
  }

  const onSubmitPageRedirect = (id) => {
  
  }

  const onCancel = () => {
    setShowForm(false)
  }

  const FileList = ({files}) => {
    return(
      <Fragment>
        {files && files.map((file, k) =>  (
          <a key={k} className="m-t-5 inline-files-list" target="_blank" href={CommonService.getFileUrl(file)}>
            <img src={CommonService.getIconByType(file)} width="35" height="35"/>
            <span className="m-l-5">{CommonService.getFileName(file)}</span>
          </a>
        ))}
      </Fragment>
    )
  }

  const ReviewList = () => reviewList.map((r, i) => {
    const ds = r.data_source || {}
    return(
      <Fragment key={i}>
        <div className="col-xs-12 br-btm-grey p-5">
          <i className="fa fa-arrow-right pull-left m-r-5 lgrey m-t-5" aria-hidden="true"></i>
          <div className="pull-left" dangerouslySetInnerHTML={{ __html: r.data.response_text}}></div>
          {r.data_source && r.data_source.suggest_actions && 
            <div className="col-xs-12">
              <PillDsList list={ds.suggest_actions} dataKey="label"/>
            </div>
          }
          <div className="col-xs-12 lgrey font-13">
            <span className="pull-left">{r.created_user.name}</span>
            <span className="pull-right">{CommonService.formatUpdateDate(r.created_at)}</span>
          </div>
        </div>
      </Fragment>
    )
  })

  if(loading){
    return (<div className="spinner"/>)
  }

  return (
    <Fragment>      
      {showForm &&
        <FormContainer post={post}>
          <DynamicForm formMode={formMode} formId={FormTemplateId.EssayPromptReplyForm} form={review} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onCancel} setPost={setPost}/>
        </FormContainer>
      }

      <div className="row p-5 font-14">
        <div className="col-xs-12 p-5 br-btm-grey">
          <i className="pull-left p-10 fa fa-envelope-open lgrey" aria-hidden="true"></i>
          <FileList files={selectedPrompt.data.upload}/>
          <div className="m-t-5 font-16" dangerouslySetInnerHTML={{ __html: selectedPrompt.data.response}}></div>
          <div className="col-xs-12">
            <span className="pull-right p-2 lgrey">{CommonService.formatUpdateDate(selectedPrompt.created_at)}</span>
            <span onClick={e=>setShowForm(true)} className="pull-left bg-highlight white m-r-5 pill-xs p-5 m-10">ADD FEEDBACK</span>
          </div>
        </div>
        <div className="col-xs-12">
          {reviewList.length > 0 && <h5 className="p-5">Responses</h5>}
            <ReviewList/> 
        </div>
      </div>
    </Fragment>
  ) 
  
}

export default EssayTileForm;