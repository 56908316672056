import React, { useEffect, useState, useCallback, Fragment, useContext } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import {OptionsObjList} from '../../../Common/FormInput'
import JourneyTitle from '../../JourneyTitle/JourneyTitle';
import {NavIcon} from '../../../Common/MenuItem'
import ReactFlow, { ReactFlowProvider, addEdge, isNode, MiniMap, Controls,} from 'react-flow-renderer';
import dagre from 'dagre';
import MultiSelectModal from "../../../Modals/MultiSelectModal";
import {setFutureCareers, setFutureCareers2, initialElements, gradeElement, gradeElement2, setGradeActivities, setSuggestedActivities, resetElement} from './initialElements';
import {AddActivity, AddDataSource, AddFutureCareer} from './Nodes'
import useModal from '../../../../hooks/useModal';
import './careerLayouting.css';
import querystringify from 'querystringify';
import {PillDsList} from '../../UserRoles/NotificationPill'
import Cs from '../../../../services/CommonService'
import ActivityTypeMenu from "../Activities/List/ActivityTypeMenu";
import ActivityService from "../Activities/ActivityService"
import ActivityList from "../Activities/List/ActivityList"
import GenericModal from "../../../Modals/GenericModal"
import JourneyFilesLinksModal from "../../JourneyFilesLinks/JourneyFilesLinksModal"
import JourneyCategoryService from "../../JourneyCategoryService"
import useNotification from "../../../../hooks/useNotification";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import CheckAccess from "../../Roles/CheckAccess";
import useStyle from "../../../../hooks/useStyle";
import FilterSlider from "./../../../Common/FilterSlider"
import PlannerService from "../Planners/PlannerService"
import Suggestions from "../CareerPortalPage/Suggestions"
import SideMenuBar from "../../../Common/SideMenuBar"
import CareerDetailsTab from './CareerDetailsTab';


// In order to keep this example simple the node width and height are hardcoded.
// In a real world app you would use the correct width and height values of
// const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height

const nodeWidth = 200;
const nodeHeight = 60;
let reactFlowInstance = null;
let selectedFutureCareer = null;

const nodeTypes = {
  AddActivity,
  AddDataSource,
  AddFutureCareer
}

const getLayoutedElements = (elements, direction = 'TB') => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const isHorizontal = direction === 'TB';
  dagreGraph.setGraph({ rankdir: direction })

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight })
    } else {
      dagreGraph.setEdge(el.source, el.target)
    }
  })

  dagre.layout(dagreGraph)

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id)
      el.targetPosition = isHorizontal ? 'left' : 'top';
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  })
}

let requestParams = {}
let dataSource = {}
let favouriteColleges = []
let journeyCategory = {}
let journeyProfile = {}
let currentPosition = {}
let futurePositions = {}
let title = null
let selectedActivity = null
let plannerParams = {}

const LayoutFlow = (props) => {
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  useStyle('/css/tile-components.css', true)
  useStyle('/css/grade-stage.css', true)
  
  let params = props.match.params;
  
  //let edu_profile_data_source = journey_category.education_journey_profiles.data_source;
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  let queryParam = querystringify.parse(props.location.search)

  const [journeyData, setJourneyData] = useState({})
  const [planners, setPlanners] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [activities, setActivities] = useState([])
  const [elements, setElements] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { isOpen:isCareerDetailsOpen, toggleModal:toggleCareerDetailsModal } = useModal()
  const { isOpen:isActivityTypeOpen, toggleModal:toggleActivityTypeModal } = useModal()
  const { isOpen: isActivityOpen, toggleModal: toggleActivityModal} = useModal()
  const { isOpen:isFileLinkOpen, toggleModal:toggleFileLinkModal } = useModal()
  const { isOpen:isPlannerOpen, toggleModal:togglePlannerModal } = useModal(true);

  useEffect(() => {
    requestParams = {
      page: 1,
      per_page :15,
      group_by:'career',
      journey_category_id: params.journey_category_id,
      data_source_params:{
        data_source_id:[3437, 3438, 3445,3510],
      }
    }
    getDataSource()
    return () => {
      requestParams = {}
      dataSource = {}
      journeyCategory = {}
      selectedActivity = null
    }
  }, [])

  let getDataSource = () => {
    setIsLoading(true)
    ActivityService.getAll(requestParams).then((res) => {
      res = res.data
      journeyCategory = res.journey_category
      journeyProfile = journeyCategory.education_journey_profiles
      setPlanners(res.planners)
      setSuggestions(res.suggestions)
      CheckAccess.userAccess(currentUser, journeyCategory, {})
      if(res.data_source_list){
        resetElement()
        dataSource = res.data_source_list;
        dataSource.activityColor = dataSource.career_j_activity_cat.options.reduce((obj, item) => (obj[item.child_form] = item.header_color, obj) ,{});
        dataSource.activityIcon = dataSource.career_j_activity_cat.options.reduce((obj, item) => (obj[item.child_form] = item.icon, obj) ,{});

        dataSource.plannerIds = dataSource.career_j_activity_cat.options.reduce((obj, item) => (obj[item.Planner_form] = item, obj) ,{});
        gradeElement(dataSource.path_nodes_career_journey.options, onGradeClick, journeyProfile, routeInterview)
      }
      currentPosition = res.current_position
      futurePositions = res.future_positions
      setTitle()
      setFutureCareers(journeyProfile.data.future_careers_array, routeActivityForm, routeCareerDetails)
      setFutureCareers2(journeyProfile.data.future_careers_2_array, routeActivityForm, routeCareerDetails)
      setGradeActivities(res.activities, dataSource.activityColor, dataSource.activityIcon, openActivity, activityPaginate)
      setElements(initialElements)
      setIsLoading(false)
    })
  }

  const setTitle = () =>{
    try{
      if(journeyProfile.data.current_job){
        let s = journeyProfile.data.current_job.split(' ')
        title = s[0]
      }

      if(journeyProfile.data.future_careers_array || journeyProfile.data.future_careers_array){
        let s = journeyProfile.data.future_careers_array[0].label.split(' ')
        let p = journeyProfile.data.future_careers_2_array[0]?.label.split(' ')
        if(p === undefined){
          title = title +' - '+ s[0]
        }else{
          title = title +' - '+ s[0] +' / '+ p[0]
        }
      }
    }catch(e){
      console.log(e.message)
    } 
  }

  let activityPaginate = (page, career, onSuccess) => { 
    let req = Object.assign({}, requestParams)
    req.page = page
    delete req.data_source_params

    ActivityService.getAll(req).then((res) => {
      if(res.status == 200){
        onSuccess(res.data.activities[career])
      }
    })
  }
  
  const onLoad = (react_flow_instance) => {
    reactFlowInstance = react_flow_instance;
  } 

  const clonePlanner = (planner) =>{
    setIsLoading(true)
    planner.activity_type = planner.planner_type
    planner.activity_form_id = dataSource.plannerIds[planner.planner_form_id].child_form
    planner.journey_category_id = params.journey_category_id
    planner.journey_profile_id = params.journey_profile_id
    ActivityService.create(planner).then((res)=>{
      if(res.status == 201){
        /*setActivities([...activities, res.data.activity])
        resetElement()
        gradeElement(dataSource.path_nodes_career_journey.options, onGradeClick, journeyProfile)
        setGradeActivities(res.activities, dataSource.activityColor, dataSource.activityIcon, openActivity)
        setFutureCareers(journeyProfile.data.future_careers_array, openFuture)
        setElements(initialElements)*/
        getDataSource()
        setIsLoading(false)
      }
    })
  }

  let getActivityByFilter = (target) => { 
    setIsLoading(true)
    requestParams[target.name] = target.value == 'all'?null:target.value
    resetElement()
    getDataSource()
  }

  const onGradeClick = (ds) =>{
    if(ds.value == 1){
      props.history.push(`/career/activity/form/${params.journey_category_id}/${params.journey_profile_id}/current_position/1014/${currentPosition?.id || ''}`)
    }else if(ds.value == 6){
      props.history.push(`/career/activity/form/${params.journey_category_id}/${params.journey_profile_id}/future_position/1014`)
    }
    toggleActivityTypeModal()  
  }

  const openActivity = (activity) =>{
    selectedActivity = activity
    toggleActivityModal()
  }

  const routeInterview = (career_stage) =>{
    const data = journeyProfile.data
    const ds = journeyProfile.data_source
    const future_occup_groups = career_stage.value == "7"?data.future_occup_groups_2:data.future_occup_groups
    const future_occup_title = career_stage.value == "7"?ds.future_occup_groups_2?.label:ds.future_occup_groups.label

    return(
      <Link className="fas fa-user-tie font-14 add-left-btn bg-black" to={{
        pathname: `/college/interview/questions/${journeyCategory.id}/${journeyProfile.id}/3382/28416`, 
        search: `graduate_program_ds_id=${future_occup_groups}&journey_category_ds_id=${data.fj_type_edu_jou}&title=${future_occup_title}`,
        state: journeyCategory 
      }}>
    </Link>)
  }

  const routeActivityForm = (data) =>{
    selectedFutureCareer = data
    if(data.career_no == 2){
      selectedFutureCareer.occupation_group = journeyProfile.data.future_occup_groups_2  
    }else{
      selectedFutureCareer.occupation_group = journeyProfile.data.future_occup_groups
    }
    
    toggleActivityTypeModal()
  }

  const routeCareerDetails = (data) =>{
    selectedFutureCareer = data    
    toggleCareerDetailsModal()
  }

  const openFuture =() =>{

  }
  
  const openFileLinks = (e) =>{
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params, type: 'smoothstep', animated: true }, els)
    )
  
  /*const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els))*/

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction)
      setElements(layoutedElements)
    },
    [elements]
  )

  let styleFn = (o) => ({backgroundColor: (o.data.icon_color)})

  const PlannerMenu = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Planner
        </h5>
        
        {planners.map((p, i)=>
          <div className="p-5 br-grey" key={i}>
            <h5 className="bg-blue white">{p.data.record_title}</h5>
            <div className="font-16">{p.data.course_name}</div>
            <a href={p.data.url} target="_blank">{Cs.extractHostName(p.data.url)}</a>
            <div className="m-t-4 pointer">
              <span className="far fa-clone font-20" onClick={e=>clonePlanner(p)}/>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const FilterCareer =()=>(
    <Fragment>
      <p className="m-t-10 m-b-10">Filter by</p>
      <div className="row form-group br-btm-grey">
          <div className="flex aligncenter coldir-xs">
            <select onChange={e => getActivityByFilter(e.target)} name="activity_form_id" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" 
              defaultValue={requestParams.activity_form_id}>
                <option value="all">Activity Type</option>
                <OptionsObjList list={dataSource.career_j_activity_cat.options} 
                label_key="label" id_key="child_form"/>
            </select>
            <select name="grade" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" 
              defaultValue={journeyProfile.data.current_job}>
                <option value="all">Career Stage</option>
                <OptionsObjList list={dataSource.career_stage.options} 
                label_key="label" id_key="pk_id"/>
            </select>
            <select name="subject_search" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs"
              defaultValue={requestParams.subject_search}>
                <option value="all">Target Career 1</option>
                <OptionsObjList list={journeyProfile.data.future_careers_array} label_key="label" id_key="label" />
            </select>
            <select name="subject_search" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" 
              defaultValue={requestParams.subject_search}>
                <option value="all">Target Career 2</option>
                <OptionsObjList list={journeyProfile.data.future_careers_2_array} label_key="label" id_key="label" />
            </select>
          </div>
        </div>
    </Fragment>
  )

  const FilterCareerBar =()=>(
    <Fragment>
      <p className="m-t-10 m-b-10">Filter by</p>
      <div className="flex aligncenter coldir-xs">
        <select onChange={e => getActivityByFilter(e.target)} name="activity_form_id" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" 
          defaultValue={requestParams.activity_form_id}>
            <option value="all">Activity Type</option>
            <OptionsObjList list={dataSource.career_j_activity_cat.options} 
            label_key="label" id_key="child_form"/>
        </select>
        <select name="grade" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" 
          defaultValue={journeyProfile.data.current_job}>
            <option value="all">Career Stage</option>
            <OptionsObjList list={dataSource.career_stage.options} 
            label_key="label" id_key="pk_id"/>
        </select>
        <select name="subject_search" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" 
          defaultValue={requestParams.subject_search}>
            <option value="all">Target Career 1</option>
            <OptionsObjList list={journeyProfile.data.future_careers_array} label_key="label" id_key="label" />
        </select>
        <select name="subject_search" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" 
          defaultValue={requestParams.subject_search}>
            <option value="all">Target Career 2</option>
            <OptionsObjList list={journeyProfile.data.future_careers_2_array} label_key="label" id_key="label" />
        </select>
      </div>
    </Fragment>
  )

  if(isLoading)return null;

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20">
            <div className="flex coldir fulwid p20">
              <div className="flex justspacebetween">
                <div>
                  <JourneyTitle title={`Activities for ${title}`} project={journeyCategory} />
                </div>
                <div className="flex aligncenter">
                  <NavIcon id="home_icon" dataTip="Back to Journey Home" 
                    link={`/journey`}
                    className="bg-highlight round-btn float-right m-r-15 m-t-4" 
                    icon="fas fa-home"/>

                  <NavIcon id="back_icon" dataTip="Back to Journey List" 
                    link={`/journey`}
                    className="bg-highlight round-btn float-right m-r-15 m-t-4" 
                    icon="fas fa-arrow-left"/>

                  <Menu project={journeyCategory} setLoading={setIsLoading} 
                    history={props.history} app={props.app}
                    menuCss="bg-highlight round-btn float-right m-r-15 m-t-4"
                    iconCss="white"/>

                  <NavIcon id="file_links" dataTip="Journey Files and Links" 
                    className="bg-highlight round-btn float-right m-r-15 m-t-4" 
                    onSelect={openFileLinks} icon="fas fa-link"/>

                  <NavIcon id="planner_items" dataTip="Planners" 
                    className="bg-highlight round-btn float-right m-r-15 m-t-4" 
                    onSelect={togglePlannerModal} icon="fas fa-compass"/>
                </div>
              </div>
              {!screen.xs && <FilterCareerBar /> }
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs">
            <div className="row m-t-30 m-t-0-xs">
              <div className="fulwid">
                <div className="layoutflow p-10 bg-white" style={{position:"sticky"}}>
                  <ReactFlowProvider>
                    <ReactFlow
                      elements={elements}
                      onConnect={onConnect}
                      connectionLineType="smoothstep"
                      nodeTypes={nodeTypes}
                      onInit={onLoad}/>
                    <Controls />
                  </ReactFlowProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isCareerDetailsOpen && <CareerDetailsTab journey_category_id={params.journey_category_id} journey_profile_id={params.journey_profile_id}
       isOpen={isCareerDetailsOpen} toggleModal={toggleCareerDetailsModal} futureCareer={selectedFutureCareer}
       journeyProfile={journeyProfile}  widthCss="w-80p" />
      }

      {isActivityTypeOpen && <ActivityTypeMenu journey_category_id={params.journey_category_id} journey_profile_id={params.journey_profile_id} 
        isOpen={isActivityTypeOpen} toggleModal={toggleActivityTypeModal} futureCareer={selectedFutureCareer}
        journeyProfile={journeyProfile} />
      }
      {isActivityOpen && <GenericModal title="Activity" component={ActivityList} dataSource={dataSource} isPopupView={true}
        widthCss="w-80p" toggleModal={toggleActivityModal} {...props} id={selectedActivity.id}/>
      }
      {isFileLinkOpen && <JourneyFilesLinksModal toggleModal={toggleFileLinkModal} journeyCategory={journeyCategory}/>}
      {isPlannerOpen && 
        <FilterSlider title="Check out these suggestions" component1={Suggestions} component2={screen.xs && FilterCareer}
          history={props.history} suggestions={suggestions} dataSource={dataSource}
          journey={journeyCategory} journeyProfile={journeyCategory.education_journey_profiles}
         openSideNav={isPlannerOpen} setOpenSideNav={togglePlannerModal} cueTipType="edu_path"/>
      }
      <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

const Menu = ({project, app, setLoading, history, userTypeList, menuCss, iconCss}) => {
  const journeyProfile = project.education_journey_profiles
  const {data, data_source} = journeyProfile

  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal()
  const { isOpen: isUserTypeOpen, toggleModal: toggleUserTypeModal} = useModal()

  const { showNotification } = useNotification()

  const deleteProject = (e) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.delete(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
        history.push("/journey")
      }
    })
    toggleDeleteModal(e)
  }

  const cloneJourney = (e, journey_category_id) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.cloneJourney(journey_category_id).then((res) => {
      if (res.status == 200) {
        showNotification("Success", "Journey Cloned Successfully")
        setLoading(false)
        history.push("/journey")
      }
    })
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault()
    setLoading(true)
    project.is_chatlist_enabled = !project.is_chatlist_enabled;
    JourneyCategoryService.update(project).then((res) => {
      if (res.status == 204) {
        setLoading(false)
      }
    })
  }

  const updateStatus = (e, journey_category, field) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      id: journey_category.id,
    }
    req[field] = !journey_category[field];
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        journey_category[field] = !journey_category[field];
        setLoading(false)
        if (field == "is_demo_template" && req.is_demo_template) {
          toggleUserTypeModal(e)
        }
      }
    })
  }

  const updateDemoJourneyUserType = (list) => {
    let req = {
      id: project.id,
      demo_journey_user_type: list,
    }
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        setLoading(false)
      }
    })
  }

  return(
    <>
      <span id="portal_menu" className={'m-r-15 portlet-dropdown'+(` ${menuCss}`)}>
        <a data-tip="Adjust Journey Settings" className="p-dropbtn">
          <i className={'fas fa-cog font-20'+(` ${iconCss}`)}></i>
        </a>

        <div className="p-dropdown-content">
          {project.is_admin_user &&
            <Fragment>
              <Link to={{
                pathname: `/college/interview/questions/${project.id}/${journeyProfile.id}/3382/28416`, 
                search: `graduate_program_ds_id=${data.future_occup_groups}&journey_category_ds_id=${data.fj_type_edu_jou}`,
                state: project 
              }}>
                <i className="fa fa-user"/> Interview
              </Link>
              <Link to={`/journey_profile/${project.education_journey_profiles.id}?form_id=1012`}>
                <i className="far fa-edit"/> Edit
              </Link>
              <a href="#" onClick={(e) => toggleDeleteModal(e, project)}>
                <i className="far fa-trash-alt"/> Delete
              </a>
              <Link to={{pathname: `/invite/user/${project.id}/${journeyProfile.id}`, state: {journey:project}, }}>
                <i className="fa fa-user"/> Invite User
              </Link>
              <a href="#" onClick={(e) => cloneJourney(e, project.id)}>
                <i className="far fa-clone"/> Create a copy
              </a>
              <a href="#" onClick={(e) => updateStatus(e, project, "is_journey_completed")}>
                <i className={`far ${project.is_journey_completed ? "fa-check-circle" : "fa-circle"}`}/> Completed
              </a>
            </Fragment>
          }
          <a href="#" onClick={(e) => toggleChatlistStatus(e)}>
            <i className={project.is_chatlist_enabled ? "far fa-check-circle": "far fa-circle"}/> Share chat
          </a>
          <a href="#" onClick={(e) => app.openHelp(e)}>
            <i className="fas fa-question-circle"/> Help
          </a>
          <a href="#" onClick={(e) => updateStatus(e, project, "is_disabled")}>
            <i className={project.is_disabled ? "fas fa-toggle-off": "fas fa-toggle-on"}/> { project.is_disabled ? "Inactive":"Active"} 
          </a>
        </div>
      </span>
      

      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
    </>
  )  
}

export default LayoutFlow;