export default {
  ProfileRegistration: 188,
  UserProfileWizard: 23,
  SettingsRegistration: 189,
  JourneyRegistration: 190,
  JourneySetupWizard: 24,
  SportsRegistration: 253,
  NewsRegistration: 240,
  NewsUserRegistration: 241,
  HelpRegistration: 243,
  DigitalSportsRegistration: 948,
  DigitalEducationRegistration: 945,
  CollegeProfileForm: 191,
  CollegeReview: 146,
  StudentProfileForm: 301,
  EducationResourceForm: 193,
  JourneyQuickUpdateForm: 950,
  FeedbackForm: 236,
  ReviewForm: 248,
  EducationActivity: 232,
  CoursePreparationForm: 954,
  ParentConsentForm: 974,
  MarketingForm:951,
  QuestionBankForm:983,
  EducationJourneyListId: "37cbde71-4776-4e0b-a17b-7a5f6d444445",
  ForumNewDiscussion:989,
  ForumReplyDiscussion:990,
  ZoomMeeting:995,
  TrackRelationship:997,
  UserGroupForm:998,
  TrainingAssessment:1005,
  TrainingStepAssessment:1006,
  EssayPromptReplyForm:1007,
  JourneyPriceModal:1011,
  CoursePriceModal:1013,
  JourneyNotification:958,
  DailyDabble:193 ,
  DailyDabbleUserSuggestion:1021,
  UnsubscribeNewsLetter:1022,
  CaseAssessment:1031,
  Subscription:1034,
  SubscriptionMarketing:1035,
  ScholarshipForm:1038,
  InterventionProtocol: 1019,
  MemberForm:1037,
  MemberFormWizard:62,
};