import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import useStyle from "../../../../hooks/useStyle";
import Cs from "../../../../services/CommonService";
import PlannerService from "../Planners/PlannerService"
import {EmptyFormFieldList} from "../../Common/FormFieldSuggestions" 

let plannerParams = null;
let requestParams = {}
const Suggestions = ({cueTipType, cueSuggestions, journey, journeyProfile, history, suggestions, dataSource, currentUser, activity, style}) => {
  
  const {cuePrompt_items} = dataSource
  const [cueTips, setCueTips] = useState([])
  const [planners, setPlanners] = useState([]);
  const [suggestionList, setSuggestionList] = useState(suggestions || [])
  const [emptyFields, setEmptyFields] = useState(cueSuggestions?.empty_fields || []);

  useEffect(() => {
    if(!journey.is_admin_user)removeUnwantedSuggestion()
    requestParams = {
      'edu_journey_type':'career_journey',
      'journey_category_id':journey?.id
    }
    if(cueTipType == 'edu_path')getCueTips()
  }, [])

  const getCueTips = () =>{
    Cs.getCueTips(requestParams).then((res)=>{
      if(res.status == 200){
        setCueTips(res.data.cue_tips)
        setEmptyFields(res.data.empty_fields || emptyFields)
      }
    })
  }

  const removeUnwantedSuggestion = () =>{
    setSuggestionList(suggestions.filter((s)=> s.item_type != 'invite_users'))
  }

  let route = (s) =>{
    let u = null
    switch (s.sid_code) {
      case 'journey':
        u = `/journey_profile/${journeyProfile.id}?form_id=${journeyProfile.form_id}`
        break;
      case 'matched_planner':
        u = `/career/planners`
        break;
      case 'invite_users':
        u = `/invite/user/${journey.id}/${journeyProfile.id}`
        history.push(u, {'journey': journey, 'share':null})
        return;
        break;
      case 'interview_questions':
        const data = journeyProfile.data
        const ds = journeyProfile.data_source
        const future_occup_groups = data.future_occup_groups
        const future_occup_title = ds.future_occup_groups.label

        history.push(
          {
            pathname: `/college/interview/questions/${journey.id}/${journeyProfile.id}/3382/28416`,
            search: `graduate_program_ds_id=${future_occup_groups}&journey_category_ds_id=${data.fj_type_edu_jou}&title=${future_occup_title}`,
            state: journey,
          }
        )
        break;
    }
    u && history.push(u, journey)
  }

  let Suggestion = ({}) => suggestionList.map((s, i) => {
    let d = s.data;
    let obj = cuePrompt_items && cuePrompt_items.options.find(o => o.value === d.value);

    return(
      <div key={i} className="next-action-text br-btm-grey clearfix" onClick={e=>route(d)}>
        <div className="col-xs-12 p-5 d-flex">
          <div className="font-24">
            <i className={obj?.icon} style={{color:obj?.icon_color}}/>
          </div>
          <div className="m-l-15 w-100p">
            <h5 className="darkblue lh-20 font-14">
              {obj.label}
            </h5>
            <div className="next-action-hover lh-18">{obj?.prompt_text}</div>
          </div>
          <div className="font-18 pull-right">
            <i className="fas fa-chevron-right lgrey m-l-10"/>
          </div>
        </div>
      </div>  
    ) 
  })

  /*let Planners = ({}) => planners.map((c, i) => {
    let d = c.data;
    return(
      <div key={i} className="next-action-text br-btm-grey clearfix">
        <div className="col-xs-12 p-5 d-flex">
          <div className="font-24">
            <i className="far fa-dot-circle grey"/>
          </div>
          <div className="m-l-5">
            <h5><a href={d.url} target="_blank">{d.record_title}</a></h5>
            <span className="next-action-hover lh-18" dangerouslySetInnerHTML={{ __html: d.notes}}></span>
          </div>
        </div>
      </div>  
    ) 
  })*/

  return(
    <div className="row">
      <div className="col-xs-12 next-action-container auto-scroll" style={style}>
        <EmptyFormFieldList fields={emptyFields}/>
        <Suggestion />
        <div className="col-xs-12">
          <small className="font-9 lgrey lh-16 p-5">{cuePrompt_items.id}</small>
        </div>
      </div>    
    </div>
  ) 
} 

export default Suggestions;