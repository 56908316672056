import React, {Fragment,useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../../hooks/useStyle';
import useSort from '../../../../../hooks/useSorting';
import SortDirection from '../../../../../components/Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../../../Common/arrayMove';
import Cs from "../../../../../services/CommonService";
import StepAssessments from "./StepAssessments"

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const AssessmentTable = ({assessment, requestParams, openQuestionModal, typeOfQuestion, journeyCategory, selectedAssessment, togglePublishModal, share}) => {
  useStyle('table');  
  
  const [currentAssessment, setCurrentAssessment] = useState();

  const toggleAssessment = (id) => {
    if(id === currentAssessment){
      setCurrentAssessment()
    }else{
      setCurrentAssessment(id)
    }
  }

  const TableData = ({assessment}) => assessment && assessment.map((o, k) =>
    <tr>
      <td data-title="Id">{o.record_id}</td>
      <td data-title="Name of Exam">{o.data.record_title}</td>
      <td data-title="Created Date" >{Cs.formatUpdateDate(o.updated_at)}</td>
      <td data-title="Submission Date" >{Cs.formatUpdateDate(o.data.submission_deadline_formatted_date)}</td>
      <td data-title="Created By" >{o.created_user.name}</td>
      <td data-title="Questions">
        <div>
          {o.step_assessments && o.step_assessments.length || 0} Questions
          {o.id == currentAssessment ? <i className="m-l-5 m-t-5 fa fa-chevron-up" onClick={e=>toggleAssessment(o.id)} aria-hidden="true"></i>:<i className="m-l-5 m-t-5 fa fa-chevron-down" onClick={e=>toggleAssessment(o.id)} aria-hidden="true"></i>}
        </div>
        {o.id === currentAssessment ? <div>
          <StepAssessments flag={false} stepAssessments={o.step_assessments} journeyCategory={journeyCategory} 
          openQuestionModal={openQuestionModal} typeOfQuestion={typeOfQuestion} share={share}/>
        </div>:
        <div>
        </div>
        }      
      </td>
      <td data-title="Preview" >
        {o.is_published && 
          <Fragment>
            <Link id="question_form_icon" 
              to={`/career_training/question/${o.journey_category_id}/${o.journey_profile_id}/${o.id}`}
              data-tip="Preview Question Form">
              <i className="far fa-file"/>
            </Link>
          </Fragment>
        }
      </td>
      <td data-title="Publish" >
        <span id="publish_icon" 
          onClick={(e) => {selectedAssessment = o;togglePublishModal(e)}}
          action="Publish Assessment to Students">
        <i className={o.is_published?'far fa-check-circle':'far fa-circle'} />
        </span>
      </td>

    </tr>
  )

  return (
    <div className="col-xs-12 m-l-10 m-t-10">
      <SortableContainer distance={1} lockAxis="y" useDragHandle>
        <div className="table-responsive p-0">
          <table className="shadow-small">
            <thead>
              <tr className="white bg-black">
                <th>Id
                </th>
                <th>Name of Exam 
                </th>
                <th>Created Date 
                </th>
                <th>Submission Date
                </th>
                <th>Created By
                </th>
                <th>Questions
                </th>
                <th>Preview
                </th>
                <th>Publish
                </th>
              </tr>
            </thead>
            <tbody>
              <TableData assessment={assessment} />
            </tbody>
          </table>
        </div>
      </SortableContainer>
    </div>
  )

}

export default AssessmentTable;