import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import JourneyCategoryService from "../../JourneyCategoryService";
import ActivityService from "../Activities/CollegeActivityService";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import useNotification from "../../../../hooks/useNotification";
import MultiSelectModal from "../../../Modals/MultiSelectModal";

const PortalHeader = (props) => {
  const { journeyCategory, share } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {state: { screen, user: currentUser }, dispatch} = useContext(AuthContext);
  
  const DemoMenu = () => (
    <span id="portal_menu" className="portlet-dropdown">
      <a data-tip="Adjust Journey Settings" className="p-dropbtn">
        <i className="fas fa-cog font-20">&#xf00a;</i>
      </a>
      <div
        className="p-dropdown-content">
        <Link
          to={{
            pathname: `/invite/user/${journeyCategory.id}/${journeyCategory.education_journey_profiles.id}`,
            state: {journey:journeyCategory},
          }}
        >
          <i className="fa fa-user"></i> Invite User
        </Link>
      </div>
    </span>
  );

  return (
    <div className="portlet-heading clearfix">
      {loading && <div className="spinner" />}
      <div className="col-xs-12 col-sm-10">
        <h3 className="portlet-title">
          <span>
            {journeyCategory.education_journey_profiles.data.fj_name_of_family_member}
            <i className="m-l-5 fas fa-angle-right" />
          </span>
          <span>
            {journeyCategory.education_journey_profiles.data.major}
          </span>
        </h3>
      </div>

      <div className="col-xs-12 col-sm-2">
        <span className="text-cap">
        {(journeyCategory.edu_journey_type).replace(/_/g, ' ')}
        </span>
        <div className="portlet-widgets">
          {journeyCategory.is_demo_journey ? <DemoMenu /> : <Menu {...props} setLoading={setLoading}/>}
          <Link data-tip="Back to Journey List"
            to={{pathname: `/journey`,}}>
            <i className="fas fa-home font-20"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

const Menu = (props) => {
  const {journeyCategory, setLoading, menuCss, iconCss} = props
  const history = useHistory()

  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal()
  const {isOpen: isUserTypeOpen, toggleModal: toggleUserTypeModal} = useModal()
  const { showNotification } = useNotification()

  const deleteProject = (e) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.delete(journeyCategory).then((res) => {
      if (res.status === 204) {
        setLoading(false)
        history.push("/journey")
      }
    })
    toggleDeleteModal(e)
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault()
    setLoading(true)
    journeyCategory.is_chatlist_enabled = !journeyCategory.is_chatlist_enabled
    JourneyCategoryService.update(journeyCategory).then((res) => {
      if (res.status === 204) {
        setLoading(false)
      }
    })
  }

  const getActiveUserReport = (e, journey_category_id) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      journey_category_id: journey_category_id,
      type: "active_users"
    }
    ActivityService.reports(req).then((res) => {
      if (res.status === 200) {
        setLoading(false)
        let activeUsers = res.data.report
      }
    })
  }

  const cloneJourney = (e, journey_category_id) => {
    e.preventDefault()
    setLoading(true)
    JourneyCategoryService.cloneJourney(journey_category_id).then((res) => {
      if (res.status === 200) {
        showNotification("Success", "Journey Cloned Successfully")
        setLoading(false)
        history.push("/journey")
      }
    })
  }

  const updateStatus = (e, journey_category, field) => {
    e.preventDefault()
    setLoading(true)
    let req = {
      id: journey_category.id,
    }
    req[field] = !journey_category[field];
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        journey_category[field] = !journey_category[field]
        setLoading(false)
        if (field === "is_demo_template" && req.is_demo_template) {
          toggleUserTypeModal(e)
        }
      }
    })
  }

  const updateDemoJourneyUserType = (list) => {
    let req = {
      id: journeyCategory.id,
      demo_journey_user_type: list,
    }
    JourneyCategoryService.update(req).then((res) => {
      if (res.status === 204) {
        setLoading(false)
      }
    })
  }

  return(
    <>
      <span id="portal_menu" className={`m-r-15 portlet-dropdown ${menuCss}`}>
        <a data-tip="Adjust Journey Settings" className="p-dropbtn">
          <i className={`fas fa-cog font-20 ${iconCss}`}/>
        </a>
        <div
          className="p-dropdown-content">
          <Link
            to={`/journey_profile/${journeyCategory.education_journey_profiles.id}?form_id=${journeyCategory.education_journey_profiles.form_id}`}>
            <i className="far fa-edit"/> Edit
          </Link>
          <a href="#" onClick={(e) => toggleDeleteModal(e, journeyCategory)}>
            <i className="far fa-trash-alt"/> Delete
          </a>
          <Link
            to={{
              pathname: `/invite/user/${journeyCategory.id}/${journeyCategory.education_journey_profiles.id}`,
              state: {journey:journeyCategory},
            }}
          >
            <i className="fa fa-user"/> Invite User
          </Link>
          {journeyCategory.is_admin_user && (
            <a href="#" onClick={(e) => cloneJourney(e, journeyCategory.id)}>
              <i className="far fa-clone"/> Create a copy
            </a>
          )}
          {journeyCategory.is_admin_user && 
            <a href="#" onClick={(e) => updateStatus(e, journeyCategory, "is_journey_completed")}>
              <i className={`far ${journeyCategory.is_journey_completed? "fa-check-circle": "fa-circle"}`}/> Completed
            </a>
          }
          <a href="#" onClick={(e) => toggleChatlistStatus(e)}>
            <i className={journeyCategory.is_chatlist_enabled
                ? "far fa-check-circle"
                : "far fa-circle"
              }/> Share chat
          </a>
          {journeyCategory.is_admin_user && 
            <a href="#" onClick={(e) =>updateStatus(e, journeyCategory, "is_demo_template")}>
              <i className={`far ${journeyCategory.is_demo_template? "fa-check-circle": "fa-circle"}`}/>
              &nbsp;Demo Journey  
            </a>
          }
          <a href="#" onClick={(e) => props.app.openHelp(e)}>
            <i className="fas fa-question-circle"/> Help
          </a>
        </div>
      </span>

      <ConfirmMenu
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        success={deleteProject}/>
      
      {isUserTypeOpen && 
        <MultiSelectModal
          isOpen={isUserTypeOpen}
          toggleModal={toggleUserTypeModal}
          title="User Type"
          options={props.userTypeList.options}
          onUpdate={updateDemoJourneyUserType}
          selectedList={journeyCategory.demo_journey_user_type}/>
      }
    </>
  )
}

export {PortalHeader, Menu};