import React, { useState, Fragment} from 'react'
import ReactDOM from 'react-dom'
import moment from '../../../node_modules/moment/min/moment.min.js'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import JourneyCategoryService from "../Journey/JourneyCategoryService"
import useStyle from '../../hooks/useStyle'

const CalendarModal = ({item, toggleModal, type}) => {
  useStyle('add_role_dialog')

  let errors = {}
  let dateFormat = "YYYY-MM-DD HH:mm"
  let previousDate = null
  
  if(item.schedule_data){
    //Convert String to Date
    previousDate = new Date(item.schedule_data.start_date)
  }else{
    item.schedule_data = item.schedule_data || {}
  }
  
  const [date, setDate] = useState(previousDate)
  const [title, setTitle] = useState(item.schedule_data.title)

  const onDateChanged = (value) =>{
    setDate(value)
  }

  const update = (e) =>{
    let req = {
      'journey_category_id':item.journey_category_id,
      'schedule_item_type':type,
      'schedule_item_id':item.id,
      'start_date':moment(date).format(dateFormat),
      'data':{
        'title':title,
        'schedule_item_title':item.data.name, 
        'sub_type':'schedule_data'
      },
      'schedule_data':{
        'title':title,
        'start_date':moment(date).format(dateFormat)
      }  
    }
    JourneyCategoryService.updateScheduleDate(req).then((res)=>{
      if(res.status == 204)toggleModal(e);
    })
  }

  const checkError = (field_key ,data) =>{
    errors[field_key] = data?false:true
    if(!errors[field_key]) delete errors[field_key]
    return errors[field_key]
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p12-10-15">Calendar</h4>
          <button className="btn-close"
            title="Close Modal" onClick={e=>toggleModal(e)}>×
          </button>
        </div>
    
        <form className="ml-container">
          <div className="ml-section">
            <label><b>Title</b></label>
            <input onChange={e=> setTitle(e.target.value)} className="ml-input ml-border ml-margin-bottom"
            type="text" placeholder="Schedule Title" name="schedule_title" required defaultValue={title}/>
            {checkError('title', title) && <span className="lred">Title is Required</span>}

            <label><b>Date</b></label>
            <DatePicker selected={date} onChange={date => onDateChanged(date)} peekNextMonth
            showMonthDropdown showYearDropdown dropdownMode="select" showTimeSelect inline />
            {checkError('date', date) && <span className="lred">Date is Required</span>}
            
            {Object.keys(errors).length === 0 &&
              <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{update(e)}}>
                <b>UPDATE</b>
              </div>
            }
          </div>
        </form>
    
        </div>
      </div>
    </Fragment>, document.body)
  )
}

export default CalendarModal;