import React, {Fragment} from "react";

const SocialShareBtn = () => (
  <Fragment>
    <a className="shareToTwitter icon icon-xs icon-round bg-twitter regularbold"
      href="https://twitter.com/intent/tweet?text=Edu Cuetree&url=https://www.edu.cuetree.com" target="blank">
      <i className="fab fa-twitter"></i>
    </a>
    <a className="shareToTwitter icon icon-xs icon-round bg-instagram regularbold">
      <i className="fab fa-instagram"></i>
    </a>
    <a className="shareToFacebook icon icon-xs icon-round bg-facebook regularbold"
      href="https://www.facebook.com/sharer/sharer.php?u=https://www.edu.cuetree.com" target="blank">
      <i className="fab fa-facebook-f"></i>
    </a>
    <a className="shareToWhatsApp icon icon-xs icon-round bg-linkedin regularbold"
      href="https://www.linkedin.com/sharing/share-offsite/?url=https://edu.cuetree.com" target="blank">
      <i className="fab fa-linkedin"></i>
    </a>
    <a className="shareToWhatsApp icon icon-xs icon-round bg-whatsapp regularbold"
      href="https://api.whatsapp.com/send?text=https://www.edu.cuetree.com"
      data-action="share/whatsapp/share" target="blank">
        <i className="fab fa-whatsapp"></i>
    </a>
    <a className="shareToTwitter icon icon-xs icon-round bg-instagram regularbold" 
      href="mailto:?subject=Cuetree Education&body=https://www.edu.cuetree.com">
        <i className="fas fa-envelope"></i>
    </a>
  </Fragment>
)

export default SocialShareBtn;