import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import DynamicForm from '../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../constants/FormTemplateList'
import UserGroupService from "./UserGroupService"
import FormContainer from '../../components/FormBuilder/FormContainer'

let formMode = null
let user = {} 
let data = {} 
let formFn = {}  

function UserForm(props) {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const get = () => {
    UserGroupService.get(props.id).then((res) => {
      if(res.status==200){
        user = res.data.user_group
        data = user.data || {}

        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    })
  }
  
  useEffect(() => { 
    user = {} 
    data = {} 
    formFn = {form_type: "user_group", editPermission:true}
    formMode = 'create-form-submissions'

    if(props.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [props.id])

  const create = (user) => {
    user.form_id = FormTemplateId.UserGroupForm
    user.group_id = currentUser.domain_info.id
    user.group_type = 'general'
    
    UserGroupService.create(user).then((res) => {
      if(res.status==201){
        props.toggleModal(res.data.user_group, 'create')
      }
    })
  }

  const update = (user) => {
    UserGroupService.update(user).then((res) => {
      if(res.status==204){
        props.toggleModal(user, 'update')
      }
    })
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.UserGroupForm} 
          form={user} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} 
          post={post} onCancel={props.toggleModal} 
          setPost={setPost} />
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default UserForm;