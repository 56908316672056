import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from '../../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import StepActivityResponseService from "./StepActivityResponseService";
import FormContainer from '../../../FormBuilder/FormContainer';

let formMode = null; 
let stepActivityResponse = {}; 
let data = {}; 
let formFn = {};  

function StepActivityResponseForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    StepActivityResponseService.get(params.id).then((res) => {
      if(res.status==200){
        stepActivityResponse = res.data.education_activity_response;
        data = stepActivityResponse.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       stepActivityResponse = null;
    })
  }
  
  useEffect(() => { 
    stepActivityResponse = {}; 
    data = {}; 
    formFn = {'form_type':'education_activity_response', 'journey_category_id':params.journey_category_id}; 
    formMode = 'create-form-submissions';
    data = {}; 
  
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (step_activity_response) => {
    step_activity_response.created_by = authState.user.id;
    step_activity_response.education_journey_profile_id = params.journey_profile_id;
    step_activity_response.journey_category_id = params.journey_category_id;
    step_activity_response.education_journal_entry_id = params.activity_id;
    step_activity_response.education_activity_id = params.step_activity_id;
    step_activity_response.form_type = params.form_type;
    step_activity_response.form_id = params.form_id;  
	
    StepActivityResponseService.create(step_activity_response).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (step_activity_response) => {
    step_activity_response.updated_by = authState.user.id;
    StepActivityResponseService.update(step_activity_response).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/highschool/activity/${params.journey_category_id}/${params.journey_profile_id}`);
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={params.form_id} form={stepActivityResponse} data={data} 
        formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    );  
  }else{
    return null
  }
  
}

export default StepActivityResponseForm;