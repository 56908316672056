import React, {useState, useRef} from'react';

const DragObject = ({item, handleDragStart, bgColor, onEdit, onDelete}) => {

  let dragElementRef = useRef();

  const [state, setState] = useState({
    left: '0px',
    top: '0px'
  })

  /*const handleDragStart = (e) =>{
    console.log('====================================');
    console.log('drag started');
    console.log('====================================');
  }*/

  const handleTouchStart = (e) =>{
    console.log('touch start');
    handleDragStart(e, item);
  }

  const handleTouchMove = (e) =>{
    console.log('touch move');
    // grab the loaction of the touch
    var touchLocation = e.targetTouches[0];
    setState({
      left: touchLocation.pageX + 'px',
      top: touchLocation.pageY + 'px'
    })
  }

  const handleTouchEnd = (e) =>{
    var dragElement = dragElementRef.current;
    var x = parseInt(dragElement.style.left, 10);
    var y = parseInt(dragElement.style.top, 10);
    console.log('Drop position x, y: ', x, y)
  }
  
  return (
    <span className="rbc-event m-l-5" 
      style={{top: state.top, left: state.left, backgroundColor:item[bgColor], minWidth:'95px', textAlign:"center"}} 
      ref={dragElementRef}
      draggable="true" 
      onDragStart={(e) => handleDragStart(e, item)}
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => handleTouchEnd(e)}
      onClick={e=>onEdit(e, item)}>
      {item.label}
      <span className="pull-right fas fa-times m-t-5" onClick={e=>onDelete(e, item)}/>
    </span>
  )

}

export default DragObject;


/*
<span className="rbc-event m-l-5" style={{backgroundColor:o.header_color,minWidth:'95px',textAlign:"center"}} data-id={o.value} data-type="education_journal_entry"
            activity-form-id={o.child_form} activity-type={o.label} id={o.value} title={o.label} draggable="true" 
            onDragStart={e=>handleDragStart(e)} key={k}>{o.label}</span>

   <div
      style={{ top: state.top, left: state.left }}
      className="column"
      ref={dragElementRef}
      draggable
      onDragStart={() => handleDragStart()}
      onTouchMove={(e) => handleTouchMove(e)}
      onTouchEnd={(e) => handleTouchEnd(e)}>
      <header>Drag Me</header>
    </div>
*/