import React, {Fragment, useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import HamBar from "./HamBar";
import PreLoadSpinner from "../../components/PreLoadSpinner";
import CommonService from "../../services/CommonService";

const ForgetPassword = (props) => {
  useStyle('/css/login.css', true) 
  useStyle('/css/common.css', true)
  
  const { state: authState, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(Math.random())

  const initialState = {
    email: "",
    isSubmitting: false,
    errorMessage: null,
    isEmailSent:false
  };

  const [data, setData] = useState(initialState);

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if(authState.isAuthenticated){
      props.history.push('/')
    }
  }, [authState.isAuthenticated]);
  
  const handleForgetSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    let req = { user: { email: data.email } };
    AuthenticationService.forgotPassword(req).then((res) => {
        if (res.status == 200) {
          setLoading(false);
          setData({
            ...data,
            isSubmitting: false,
            isEmailSent:true
          });
        } else {
          setLoading(false);
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: res.message || res.statusText,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setData({ ...data, errorMessage: error.response.data.error });
      });
  };

  if(loading){
    return(<PreLoadSpinner/>)
  }

  return (
    <Fragment>
      <HamBar className="hidden"/>
      <div id="ct">
        <section className="login-page flex justspacebetween">
          <form className="login-left wid_50 pos_relative flex aligncenter">
            <div className="login-form">
              <h2 className="title m-b-10">Reset your Account</h2>
              {data.isEmailSent && <p className="text-lite-gray m-b-30">Password reset link sent.</p>}
              
              {!data.isEmailSent && <div className="form-fields flex coldir">
                <label className="form-label m-b-10">Email</label>
                <input
                  className="form-input fulwid"
                  type="email" placeholder="Email" 
                  name="email" onChange={handleInputChange}
                  required
                />
              </div>} 
              <button
                onClick={handleForgetSubmit}
                className="btn btn-primary fulwid f16 fw500 tcenter text-white m-t-20"
              >
                SEND RESET INSTRUCTIONS
              </button>
              <div className="m-t-50">
                <p>
                  <Link to="signin" className="text-link">
                    <strong>Sign in </strong>
                  </Link>
                </p>
              </div>
            </div>
          </form>
          <div className="login-right tcenter justifycenter wid_50 pos_relative flex aligncenter">
            <div className="login-content">
              <a href="#" className="m-b-20">
                <img src="images/cueTree-logo.png" alt="" />
              </a>
              <h3 className="fw500 m-b-15">Transform education journeys</h3>
              <p className="text-lite-gray m-b-20 wid_80 w-80 wid_100_xs mx-auto">
                {CommonService.displayRandomPhrase(Math.floor(4*count))}
              </p>
              <div className="text-lite-gray badge badge-md badge-content m-b-20 mx-auto fw500">
                Improving learning experiences by augmenting and enriching connections
              </div>
              <div className="m-t-10 mx-auto">
                <ul className="flex gap-5 justifycenter">
                  <li>
                    <a href="#">
                      <img src="images/fb-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/instagram-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/twitter-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/linked-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/whatsapp-icon.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex justspacebetween m-t-50">
                <p className="text-lite-gray f12">{new Date().getFullYear()} IPIPAL INC. All Rights Reserved.</p>
                <p className="f12">
                  <Link to="/about_us/terms_of_service" className="text-link">
                    <strong>Terms of Service</strong>
                  </Link>
                  {" "}|{" "}
                  <Link to="/about_us/privacy_policy" className="text-link">
                    <strong>Privacy policy </strong>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  )
}

export default ForgetPassword;