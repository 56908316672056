import React, {Fragment} from "react";
import { Link } from "react-router-dom";
//import Cs from "../../../services/CommonService"

export const JourneyTitle = (props) => {
  let project = props.project || {}
  let title = props.title
  const edu_journey_type = project.edu_journey_type 

  const JourneyType = () =>(
    <Fragment>
      <p className="m-t-10 text-cap">
        {edu_journey_type && (project.edu_journey_type).replace(/_/g, ' ')}
        <span className="text-blue"> &gt; {title}</span>
      </p>
    </Fragment>
  )

  const Title = () =>{
    if(edu_journey_type == "training_journey"){
      return (
        <Fragment>
          <div className="app-title fw500 m-r-20">
            {project && project.education_journey_profiles.data.journey_name}
          </div>
          <JourneyType/>  
        </Fragment>
      )
    }else if(edu_journey_type == "high_school_journey"){
      return (
        <Fragment>
          <div className="app-title fw500 m-r-20 text-cap">
            {project && project.education_journey_profiles.data.fj_name_of_family_member}
          </div>
          <JourneyType/>  
        </Fragment>
      )
    }else if(edu_journey_type == "college_journey"){
      return (
        <Fragment>
          <div className="app-title fw500 m-r-20">
            {project && project.education_journey_profiles.data.fj_name_of_family_member}
          </div>
          <JourneyType/>  
        </Fragment>
      )
    }else if(edu_journey_type == "edu_team_journey"){
      return (
        <Fragment>
          <div className="app-title fw500 m-r-20">
            {project && project.education_journey_profiles.data.fj_name_of_family_member}
          </div>
          <JourneyType/>  
        </Fragment>
      )
    }else if(edu_journey_type == "academic_course"){
      return (
        <Fragment>
          <div className="app-title fw500 m-r-20">
            {project && project.education_journey_profiles.data.fj_name_of_family_member }
          </div>
          <JourneyType/>  
        </Fragment>
      )
    }else if(edu_journey_type == "pre_college_journey"){
      return (
        <Fragment>
          <div className="app-title fw500 m-r-20">
            {project && `${project.education_journey_profiles.data.major}` }<br/>
            {project && `${project.education_journey_profiles.data.state}` }
          </div>
          <JourneyType/>  
        </Fragment>
      )
    }else if(edu_journey_type == "career_journey"){
      return (
        <Fragment>
          <div className="app-title fw500 m-r-20">
            {project && `${project.education_journey_profiles.data.record_title}` }<br/>
            {project && `${project.education_journey_profiles.data.current_job}` }
          </div>
          <JourneyType/>  
        </Fragment>
      )
    }else{
      return <div className="app-title fw500 m-r-20">{title}</div>
    }
  }

  return(
    <Fragment>
      <Title/>
    </Fragment>
  )
}

export default JourneyTitle;