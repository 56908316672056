import React, {Fragment, useState, useEffect} from "react"
import Cs from '../../../../../services/CommonService'
import ResourceService from "../ResourceService"
import {PillList} from "../../../UserRoles/NotificationPill"
import {FormFieldTable, RenderData} from "../../../UserRoles/NotificationPill"

const EnrichCard = (props) => {
  const [enrichCard, setEnrichCard] = useState({})
  const [formFields, setFormFields] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    get({
      page:1,
      per_page:1,
      id: props.id, 
      is_active:true,
      form_field_params:{
        ids:[193],
        is_table_view:true
      }
    })
  }, [])

  const get = (req) => {
    if(props.id){
      ResourceService.getAll(req).then((res)=>{
        setEnrichCard(res.data.education_resources[0])
        setFormFields(res.data.form_fields[193])
        setLoading(false)
      })
    }
  }

  const Card = ({}) =>{
    return(
      <div className="bg-white border-rounded-10">
        <div className="card-title card-title-gray flex aligncenter p10 p-b-15">
          <div className="flex aligncenter">
            <a id="head" className="content">
              <div className="w-100">
                <h4 className="fw700 card-heading">{enrichCard.data.name || enrichCard.data.record_title}</h4>
                <p className="f12">since {Cs.formatUpdateDate(enrichCard.created_at)}</p>
              </div>
            </a>
          </div>
        </div>
        <div className="card-content p10-20">
          <div className="b-tab-table active m-t-5">
            <FormFieldTable formFields={formFields} data={enrichCard.data} dataSource={enrichCard.data_source}/> 
          </div>
        </div>
      </div>  
    )
  }

  return (
    loading?
      <div className="spinner"/>
      :
      <div id="ct">
        <div className="clearfix">
          <div className="bg-lwhite">
            <Card/>
          </div>
        </div>
      </div>
  )
    
}

export {EnrichCard};