import React, {useState, useEffect,Fragment} from "react";
import {Link} from "react-router-dom";
import ProjectMenu from "../../../Modals/ProjectMenu";
import ReactTooltip from 'react-tooltip';
import useModal from '../../../../hooks/useModal';
import ConfirmMenu from '../../../Modals/ConfirmMenu'
import PortalHeader from "../HighSchoolPortalPage/PortalHeader";
import JourneyCategoryService from "../../JourneyCategoryService";
import useStyle from '../../../../hooks/useStyle';
import CheckAccess from '../../Roles/CheckAccess';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import UpcomingActivities from "./UpcomingActivities";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle";
import {NavIcon,CardMainMenu} from '../../../Common/MenuItem'
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import {HighSchoolLink} from "../../../Common/RouteLinks"

let dataSources = null;

export const Suggestions = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  let params = props.match.params;
  let project = props.location.state;

  const [suggestions, setSuggestions] = useState(null);
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  let disableClickEvent = {}
  if(currentUser.current_admin.admin_role_name == 'demo_user'){
    disableClickEvent.pointerEvents = 'none'
  }

  const deleteProject = (e) => {
    e.preventDefault();
    JourneyCategoryService.delete(project).then((res) => {
      if (res.status == 204) {
        props.history.push('journey');
      }
    })
    toggleDeleteModal(e);
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault();
    project.is_chatlist_enabled = !project.is_chatlist_enabled;
    JourneyCategoryService.update(project).then((res) => {
      if (res.status == 204) {}
    })
  }

  useEffect(() => { 
    let req = {
      'journey_category_id':params.journey_category_id,
      'show_journey_templates': false, 
      'data_source_params':{
        "data_source_id":[3332],//Individual journey data_source
        "response_type":"object"
      }
    }

    JourneyCategoryService.getJourneyById(req).then((res)=>{
     if(res.status == 200){
       res = res.data;
       res.journey_category = res.journey_category[0];
       dataSources = res.data_source_list;
       setSuggestions(res.upcoming_activity)
      }
    })
  }, []);

  const showMenu = (e, a) => {
    toggleMenuModal(e);
  }

  const Menu = ({ activity}) => (
    <CardMainMenu activity= {activity} showMenu={showMenu} disableClickEvent={disableClickEvent} />
  )

 const menuItems = ({item}) =>{
   return(
    <Fragment>
      <Link to={`/journey_profile/${item.education_journey_profiles.id}?form_id=${item.education_journey_profiles.form_id}`}>
        <i className="far fa-edit"/> Edit
      </Link>
      <a href="#" onClick={e => {toggleDeleteModal(e, item)} }>
        <i className="far fa-trash-alt"/> Delete
      </a>
      <Link to={`/invite/user/${item.id}/${item.education_journey_profiles.id}`}>
        <i className="fa fa-user"></i> Invite User
      </Link>
      <a href="#">
        <i className="fas fa-info-circle"></i> Help
      </a>
      <a href="#" onClick={e=>toggleChatlistStatus(e)}>
        <i className={item.is_chatlist_enabled?'far fa-check-circle':'far fa-circle'}></i> Share chat
      </a>
    </Fragment>
   )
 }

  if(!suggestions)return(
    <PreLoadSpinner/>
  )

  return (
    <div>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Suggestions"} project={project} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
              link={HighSchoolLink.hs_portal(params)}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-home"/>
        </div>
        <div className="clear"></div>
      </div>
      
      <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={project} type="suggestions" MenuItems={menuItems} menuCss="top-40"/>
      
      <div className="page-content row">  
        <UpcomingActivities upcomingActivities={suggestions}/>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
      <SideMenuBar helpId="8"/>
    </div>
  )
};

export default Suggestions;

/*
<div onClick={e=>toggleShowView('upcoming_activity')} className="acc-md">Upcoming Activities
          <span className="fas fa-angle-down pull-right m-t-5"></span>
        </div>
*/