import React, { useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import DynamicForm from "../../../../components/FormBuilder/DynamicForm";
import FormTemplateId from "../../../../constants/FormTemplateList";
import StepActivitiesService from "./StepActivitiesService";
import FormContainer from '../../../FormBuilder/FormContainer';

let formMode = null;
let stepActivity = {};
let data = {};
let formFn = {};

function StepActivityForm(props) {
  const { state: authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    StepActivitiesService.get(params)
      .then((res) => {
        if (res.status == 200) {
          stepActivity = res.data.step_activity;
          data = stepActivity.data || {};
          /*TODO Render Delay Issue*/
          params.form_template_id = stepActivity.activity_form_id;
          setFormRendered(true);
        }
      })
      .catch(function (res) {
        stepActivity = null;
      });
  };

  useEffect(() => {
    stepActivity = {};
    data = {};
    formFn = {
      form_type: "edu_college_journey.step_activity",
      journey_category_id: params.journey_category_id,
    };
    formMode = "create-form-submissions";
    data = {};

    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, [params.id]);

  const create = (stepActivity) => {
    stepActivity.created_by = authState.user.id;
    stepActivity.journey_profile_id = params.journey_profile_id;
    stepActivity.journey_category_id = params.journey_category_id;
    stepActivity.activity_id = params.activity_id;
    stepActivity.activity_type = params.activity_type;
    stepActivity.activity_form_id = params.form_template_id;
    StepActivitiesService.create(stepActivity)
      .then((res) => {
        if (res.status == 201) {
          onSubmitPageRedirect();
        }
      })
      .catch(function (res) {});
  };

  const update = (stepActivity) => {
    stepActivity.updated_by = authState.user.id;
    StepActivitiesService.update(stepActivity)
      .then((res) => {
        if (res.status == 204) {
          onSubmitPageRedirect();
        }
      })
      .catch(function (res) {});
  };

  const onSubmitPageRedirect = () => {
    props.history.push(
      `/college/activity/${params.journey_category_id}/${params.journey_profile_id}`
    );
  };

  if (isFormRendered) {
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={params.form_template_id} form={stepActivity}
            data={data} formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost} />
      </FormContainer>
    );
  } else {
    return null;
  }
}

export default StepActivityForm;
