import React, { useEffect, useState, useContext} from 'react'
import querystringify from "querystringify"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import ScholarshipService from "./ScholarshipService"
import FormContainer from '../../FormBuilder/FormContainer'
import {FormApi} from "../../Common/FormApi"

let formMode = null 
let scholarship = {}
let data = {} 
let formFn = {}  

function ScholarshipForm(props) {
  const { state:{user:currentUser}, dispatch } = useContext(AuthContext)

  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  
  const params = props.match.params
  const queryParam = querystringify.parse(props.location.search)

  const get = () => {
    ScholarshipService.get(params.id).then((res) => {
      if(res.status==200){
        scholarship = res.data.scholarship
        data = scholarship.data || {}
        setFormRendered(true)
      }
    }).catch(function(res){
       scholarship = null
    })
  }
  
  useEffect(() => { 
    scholarship = {} 
    data = {} 
    formFn = {'form_type':'scholarship'} 
    formMode = 'create-form-submissions'
    data = {}

    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (scholarship) => {
    scholarship.created_by = currentUser.id
    scholarship.scholarship_form_id = FormTemplateId.ScholarshipForm
    ScholarshipService.create(scholarship).then((res) => {
      if(res.status == 201){
        onSubmitPageRedirect()
      }
    })
  }

  const update = (resource) => {
    ScholarshipService.update(scholarship).then((res) => {
      if(res.status == 204){
       onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push('/admin/scholarships')
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.ScholarshipForm} 
          form={scholarship} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect}  
          setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null
  }
  
}

export default ScholarshipForm;