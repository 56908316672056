import React, { Fragment, useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'
import { useRouteMatch } from 'react-router-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import NotesService from '../../services/NotesService'
import useStyle from '../../hooks/useStyle'
import useEscape from '../../hooks/useEscape'

const NotesModal = ({ id, type, pageType, isOpen, toggleModal }) => {
  useStyle('add_role_dialog')
  useStyle('notes')
  useEscape(toggleModal)
  
  const { state:currentUser, dispatch } = useContext(AuthContext)
  const match = useRouteMatch()
  
  const [notesList, setNotesList] = useState([])
  const [count, setCount] = useState(0)
  const [note, setNote] = useState({})
  const [text, setText] = useState('')

  const forceUpdate = () =>{
    setCount(value => ++value)
  }

  let requestParams = {
    sort_column: "updated_at",
    sort_direction: "desc",
    page_type: match.path,
    noteable_id: id,
    noteable_type: type
  }

  if(type){
    delete requestParams.page_type  
  }

  let getNotesList = () => {
    NotesService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setNotesList(res.data.notes)
      }
    })
  }

  useEffect(() => {
    getNotesList()
  }, [id])

  const addNote = (e, note) => {
    e.preventDefault() 
    note.note = text
    note.noteable_id = id
    note.noteable_type = type
    note.page_type = match.path
    note.updated_by = currentUser.user.id
    note.created_by = currentUser.user.id
    
    if(note.id){
      note.updated_by = currentUser.user.id
      NotesService.update(note).then((res)=>{
        if(res.status == 204){
          setNote({})
          setNotesList([...notesList])
          setText('')
        }
      })
    }else{
      NotesService.create(note).then((res)=>{
        if(res.status == 201){
          note.id = res.data.note.id  
          setNotesList([...notesList, note])
          note = {}
          setText('')
          setNote({})
        }
      })
    }
  }

  const deleteNote = (e, note) => {
    e.preventDefault()  
    NotesService.delete(note.id).then((res)=>{
      setNotesList(notesList.filter(i => i.id !== note.id))
    })  
  }

  const setEdit = (n) =>{
    setNote(n)
    setText(n.note)
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">

          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Page Note</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>

          <form className="ml-container">
            <div className="ml-section">
              <h6>You can create and save private notes that apply to only this screen</h6>
              <textarea onChange={e => setText(e.target.value)} rows="5" className="ml-input ml-border ml-margin-bottom" 
                placeholder="Notes" value={text} required>
              </textarea>

              <button className="ml-button ml-block bg-lgreen white ml-section ml-padding" onClick={e => {addNote(e, note)}}>
                <b>Submit</b>
              </button>
            </div>
          </form>
      
          <div className="ml-container">
            <ul className="note-ul note-card-4 m-b-20">
              {notesList.map((n, key) =>(
                <li className="note-bar" key={key}>
                  <div className="note-bar-item pull-left">
                    <span className="font-16">{n.note}</span><br/>
                  </div>
                  <div className="nbt-white font-16 pull-right m-r-5" onClick={e => {setEdit(n)}}>
                    <i className="far fa-edit" aria-hidden="true"></i>
                  </div>
                  <div className="nbt-white font-16 pull-right m-r-25" onClick={e => {deleteNote(e, n)}}>
                    <i className="far fa-trash-alt" aria-hidden="true"></i>
                  </div>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </div>
    </Fragment>, document.body)
  )
}

export default NotesModal;