import React, { Fragment } from 'react'

const Rating = (props) => {
   /*sumOfRating - summ of all the rating fields*/
   /*noOfRating - no of rating fields in multi rating modal*/

   if(props.sumOfRating && props.noOfRating){
      let avgRating = props.sumOfRating/props.noOfRating

      let rating = parseInt(avgRating)
      let noRating = ( 5 - rating )

      let RatingList = [...Array(rating)]
      let NoRatingList = [...Array(noRating)]

      const RatingView = () => RatingList.map((o, k) =>
         <i className="fas fa-star color-yellow1-dark" key={k}></i>
      )

      const NoRatingView = () => NoRatingList.map((o, k) =>
         <i className="far fa-star color-yellow1-dark" key={k}></i>
      )

      return (
         <div>
            <RatingView/>
            <NoRatingView/>
         </div>
      )
   }else{
      return null
   }
   
}

export default Rating;