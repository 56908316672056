import React, {useState, Fragment, useEffect, useContext, useRef} from "react"
import ReactTooltip from 'react-tooltip'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Pagination from '../Pagination'
import ConfirmMenu from "../Modals/ConfirmMenu"
import FormTemplateId from '../../constants/FormTemplateList'
import CommentsModal from "../Modals/CommentsModal"
import ProjectMenu from "../Modals/ProjectMenu"
import {Link} from "react-router-dom"
import useModal from "../../hooks/useModal"
import NotesModal from "../Modals/NotesModal"
import MenuModal from "../Modals/Menu"
import ChecklistsModal from "../Modals/ChecklistsModal"
import useStyle from '../../hooks/useStyle'
import {MenuItem, NavIcon, FilterMenuItem} from '../Common/MenuItem'
import SideMenuBar from "../../components/Common/SideMenuBar"
import CommonService from "../../services/CommonService"
import {ActiveStatusList, setDefaultLabel} from "../../constants/DataSourceList"
import NotificationPopup from '../Common/NotificationPopup'
import querystringify from "querystringify"
import Form from './Form'
import GenericModal from '../Modals/GenericModal'
import PreLoadSpinner from "../../components/PreLoadSpinner"
import {ProtocolCard} from "./ProtocolCard"
import InterventionProtocolService from "./InterventionProtocolService"
import FilterSlider from "../Common/FilterSlider"
import {CheckBox} from "../Common/FormInput"

let requestParams = {}
let selectedItem = {}
let dataSource = {}
let formProps = {}

const List = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  useStyle('filter_dialog.css', true)

  const indexEle = useRef(null)

  let queryParam = querystringify.parse(props.location.search)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [list, setList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [formFieldsList, setFormFieldsList] = useState({})
  const [pagemeta, setPagemeta] = useState([])
  const [isActive, setIsActive] = useState()
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({})

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isFormOpen, toggleModal:toggleFormModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isProtocolMenuOpen, toggleModal:toggleProtocolMenuModal } = useModal()
  const {isOpen: isFilterOpen, toggleModal:toggleFilterModal } = useModal()

  useEffect(() => {
    requestParams = {
      'filter': filter,
      'is_active': isActive,
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'totalItems': 0,
      'search': queryParam.search,
      'form_field_params': {
        'ids':[1019, 1049]
      },
      'data_source_params': {
        'data_source_id':[3574, 2860, 2214],
        'order_by':'order_list'
      }
    }

    getAll()

    return ()=>{
      formProps = {}
      requestParams = {}
      selectedItem = {}
      dataSource = {}
    } 
  }, [queryParam.search, currentUser?.id, filter])


  let paginate = (page) =>{
    requestParams.page = page
  }

  let getAll = () => {
    setLoading(true)
    InterventionProtocolService.getAll(requestParams).then((res)=>{
      setPagemeta(res.data.meta)
      setFormFieldsList(res.data.form_fields || {})
      setList(res.data.intervention_protocols)
      dataSource = res.data.data_source_list
      setLoading(false)
    })
  }

  const deleteItem = (e) => {
    e.preventDefault()
    InterventionProtocolService.delete(selectedItem.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedItem.id))
      }
    })
    toggleDeleteModal(e)
  }

  const onDelete = (e, item) => {
    selectedItem = item
    toggleDeleteModal(e)
  }

  const addMenu = (e, item) => {
    selectedItem = item
    toggleMenuModal(e)
  }

  const addNotes = (e, item) => {
    selectedItem = item
    toggleCommentModal(e)
  }

  const updateStatus = (e, item) =>{
    e.preventDefault()
    item.is_active = !item.is_active 
    InterventionProtocolService.update(item).then((res) => {
      if(res.status==204){
        setList([...list])
      }
    })
  }

  const openFormModal = (id, form_id) =>{
    formProps = {
      match:{
        params:{
          id: id,
          form_id: form_id
        }
      },
      isPopupMode:true,
      onSuccess:getAll
    }

    toggleFormModal()
  }

  const onProtocolTypeSelect = (e, item) =>{
    toggleProtocolMenuModal(e)
    openFormModal(null, item.child_form)
  }

  const toggleActive = (value) => {
    if(value && value != isActive){
      setIsActive(value)
    }else{
      setIsActive()
    }
  }

  const activeExist = (value) => requestParams.is_active == value;

  const toggleFilter = (value, name) => {
    if(value && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  const filterExists = (value, name) => filter[name] == value;

  const FilterView = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          {dataSource.ipa_categories.label}
        </h5>

        <CheckBox list={dataSource.ipa_categories.options || []} 
          labelKey="label" valueKey="child_form" 
          toggle={toggleFilter} exists={filterExists} 
          name="form_id"/> 
      </div>
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          {dataSource.fj_edu_entry_type.label}
        </h5>

        <CheckBox list={dataSource.fj_edu_entry_type.options || []} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="activity_type_id"/> 
      </div>
    </div>
  )

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <MenuItem id="edit_icon" 
          onSelect={e => openFormModal(item.id, item.form_id)}
          icon="far fa-edit"
          action="Edit"/>

        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => {onDelete(e,item);toggleMenuModal(e);}}/>

        <MenuItem id="chat_icon" 
          icon="fa fa-notes-medical"
          action="Comments"
          onSelect={(e) => addNotes(e,item)}/>

        <a href="/" onClick={e=>updateStatus(e, item)}>
          <i className={item.is_active ? "far fa-check-circle": "far fa-circle"}></i>
          <span className="font-13">Pub Active</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({item}) => (
    <div className="pull-right m-r-20">
      <i onClick={e=>addMenu(e, item)} className="fas fa-bars font-18" />     
    </div>
  )

  const protocolCardsView =  list && list.map((item, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <ProtocolCard protocol={item} menu={Menu} addMenu={addMenu}
        toggleModal={toggleDeleteModal} addNotes={addNotes} 
        datasource={dataSource} index={i} 
        formFieldsList={formFieldsList} />
      
      {selectedItem.id == item.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
        item={selectedItem} onDelete={onDelete} type="intervention_protocol" 
        MenuItems={menuItems} />
      }
    </div>
  ))

  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }
  
  if(isFormOpen){
    return(
      <GenericModal component={Form} {...formProps} 
        isOpen={isFormOpen} toggleModal={toggleFormModal} />
    )
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                Intervention Protocols
              </div>
              <div className="font-15">
                {pagemeta.total} Items
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="filter" dataTip="Filter Items" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={()=>toggleFilterModal()} icon="fas fa-sliders-h"/>

              <NavIcon id="create_items" dataTip="Create New Item" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={()=>toggleProtocolMenuModal()} icon="fas fa-plus"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              {protocolCardsView}

              <Pagination totalItems={requestParams.totalItems} 
                currentpage={currentpage} 
                setCurrentpage={paginate} 
                itemsPerPage={requestParams.per_page}/>
            </div>
          </div>
        </div>
      </div>
        
      <ConfirmMenu isOpen={isOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteItem}/>
      
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
          toggleModal={toggleCommentModal} 
          item={selectedItem} 
          type="intervention_protocols"/>
      }

      {isProtocolMenuOpen && 
        <GenericModal isOpen={isProtocolMenuOpen} 
          toggleModal={toggleProtocolMenuModal}
          component={FilterMenuItem} 
          title={dataSource.ipa_categories?.label}
          list={dataSource.ipa_categories?.options}
          dataSourceId={dataSource.ipa_categories?.id} 
          onSelect={onProtocolTypeSelect}/>
      }
      
      {isFilterOpen && 
        <FilterSlider component1={FilterView} 
        openSideNav={isFilterOpen}
        setOpenSideNav={toggleFilterModal}/>
      }

      <SideMenuBar helpId="2" />
    </Fragment>
  )
}

export default List;