import React, { Fragment, useContext} from 'react';
import {Link} from "react-router-dom";
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider';
import useStyle from '../../hooks/useStyle';
import { FormDescription, FormTitle } from './FieldLabel';

const FormContainer = (props) => {
  useStyle('/css/view-details-form.css', true)
  useStyle('/css/responsive.css', true)
  useStyle('/css/sidebar-theme.css', true)

  let {post, formId, showFormUpdateLink} = props	
  const { state:{user:currentUser} } = useContext(AuthContext)

  return (
    <section id="ct">
      <div className={props.isPopupMode ? 'form p-0 m-t-0' : 'form form-content m-t-30 m-t-20-xs'}>
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p20">
            <FormTitle title={post.title} locale={post.locale_title} 
              currentLocale={currentUser.current_locale}/> 
          </h4>
        </div>
        <div className="tab-details">
          <div className="flex justspacebetween aligncenter coldir-xs alignstart-xs form-details">
            <p>
              <FormDescription details={post.details} locale={post.locale_details} 
                currentLocale={currentUser.current_locale}/> 
            </p>
            <p>
              <span className="text-red m-r-5">*</span>Required fields
            </p>
          </div>
        </div>
        {props.children}
      </div>
    </section>
  )
}

export default FormContainer;



{/* <div className="page-content row">
      <div className={`portlet ${props.isPopupMode && 'p-0 m-t-0'}`} >
        <div className="portlet-heading bg-highlight color-white">
          <h4 className="portlet-title text-uppercase">
            {post.title}
          </h4>
          <div className="clearfix"></div>
        </div>
        <div id="portlet1" className="panel-collapse collapse in">
          <div className="portlet-body p-t-0">
            <div className="row p-7">
              {showFormUpdateLink && 
                <Link className="font-15 pull-right" to={`/data_source_list/suggestion/${formId}`}>
                  <u>Suggest updates to the forms</u>
                </Link>
              }
              <div className="form-details" dangerouslySetInnerHTML={{ __html: post.details }}></div>
              <label className="pull-right clear"><span className="lred font-16">* </span>Required field</label>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div> */}