import React, {useContext} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const EditDataSourceIcon = ({field, open, iconClass}) => {
  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);

  if(currentUser?.current_admin?.role_admin){
  	return(
	  <span className={(iconClass || 'fas fa-list')+' m-l-15 cursor'} onClick={e=>open(field)}/>	
  	)		
  }else{
  	return null
  }
  
}

export default EditDataSourceIcon;
