import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import CommonService from "../../../../../services/CommonService";
import AddToFavourite from "../../../../Common/AddToFavourite"
import CareerStepActivity from "../../StepActivities/StepActivities";

const TrainingModule = (props) => {
  let ds = props.dataSource.fj_edu_entry_type.options
  let activity = props.activity;
  activity.updated_at = CommonService.formatUpdateDate(
    activity.updated_at,
    "MMM DD YYYY"
  );
  let eduJourneyType = props.dataSource.fj_edu_entry_type.options;
  let borderColor = null;
  let data = activity.data;
  let data_source = activity.data_source;

  if (!data) return null;  
  try {
    borderColor = eduJourneyType[activity.data.fj_edu_entry_type].header_color;
  } catch (e) {}

  const cardStyle = {
    backgroundColor: borderColor || "#607D8B",
  };

  const category = (key) => {
    return (<div>
      <i className={ds[key].icon + ' m-r-10'} style={{color:ds[key].icon_color}}></i>
       {ds[key].label}
      </div>);
  };

  const list = (key) => {
    return <div>{data_source[key] && data_source[key].label}</div>;
  };

  const Favourite = () =>(
  	<AddToFavourite
    	favouriteId={activity.favourite_id}
    	favorableType="education_journal_entry"
    	favorableId={activity.id}
    	journeyCategoryId={activity.journey_category_id}
    	classNameActive="pin-40 green" classNameDisable="pin-40 white"
    	journeyProfileId={activity.education_journey_profile_id}/>
  )

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span
          className="m-l-25"
          id={`comment_${props.index}`}
          onClick={(e) => {
            props.addNotes(e, activity);
          }}
        >
          <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
          <span className="badge up bg-lgrey">{activity.comments_count}</span>
        </span>
        <span
          className="m-l-25"
          id={`check_list_${props.index}`}
          onClick={(e) => {
            props.addChecklist(e, activity);
          }}
        >
          <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
          <span className="badge up bg-lgrey">{activity.check_list_count}</span>
        </span>
        <span className="m-l-25" id={`mail_${props.index}`}>
          <Link
            to={`/journey/incoming/emails/education_journal_entry/${activity.id}`}
          >
            <i className="far fa-envelope font-18 text-muted" data-tip="Mail"></i>
            <span className="badge up bg-lgrey">{activity.mail_count}</span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  );

  const HonorCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    Grade
                    {activity.data_source.grade &&
                      activity.data_source.grade.label}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Name</strong>
                  <div>{data.name}</div>
                </td>

                <td>
                  <strong>Recognition</strong>
                  <div>{data.title_recognition}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Name</strong>
                  <div>{data.url}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const ResearchCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {activity.data_source.research_pub_subject.label}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    {activity.data_source.grade &&
                      activity.data_source.grade.label}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.name}</div>
                </td>
                <td>
                  <strong>Research</strong>
                  <div>{activity.data_source.research_pub_subject.label}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Research Url</strong>
                  <div>{data.research_pub_url}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Notes</strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: CommonService.subString(data.research_pub_notes),
                    }}
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>
        <Footer />
      </div>
    );
  };

  const ScholarCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {activity.data.name}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{activity.data.name}</div>
                </td>
                <td>
                  <strong>Amount</strong>
                  <div>{activity.data.amount}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>
        <Footer />
      </div>
    );
  };

  const TestingCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {activity.data_source.grade &&
                    activity.data_source.grade.label}
                </td>
              </tr>

              <tr>
                <td>
                  <strong>
                    {activity.data_source.type_of_test &&
                      activity.data_source.type_of_test.label}
                  </strong>
                  <div>{data.score}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Date</strong>
                  <div>{data.start_date}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>
        <Footer />
      </div>
    );
  };

  const CourseCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  Grade
                  {activity.data_source.grade &&
                    activity.data_source.grade.label}
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Course Type</strong>
                  <div>
                    {activity.data_source.course &&
                      activity.data_source.course.label}
                  </div>
                </td>
                <td>
                  <strong>Name</strong>
                  <div>{activity.data.course_name}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  <strong>Level</strong>
                  <div>
                    {activity.data_source.course_level &&
                      activity.data_source.course_level.label}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const SummerCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Grade</strong>
                  <div>{data.grade}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const AthleticsCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Grade</strong>
                  <div>{data.grade}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Name</strong>
                  <div>
                    {activity.data_source.name &&
                      activity.data_source.name.label}
                  </div>
                </td>
                <td>
                  <strong>Role</strong>
                  <div>
                    {activity.data_source.role &&
                      activity.data_source.role.label}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Commitment</strong>
                  <div>
                    {activity.data_source.time_commitment &&
                      activity.data_source.time_commitment.label}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const OtherCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.name}</div>
                </td>
                <td>
                  <strong>Date</strong>
                  <div>{data.start_date}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Notes</strong>
                  <div dangerouslySetInnerHTML={{ __html: data.notes }}></div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const CompetitionCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Grade {data.grade}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.name}</div>
                </td>
                <td>
                  <strong>Subject</strong>
                  <div>
                    {activity.data_source.edu_competition_subject &&
                      activity.data_source.edu_competition_subject.label}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Notes</strong>
                  <div>{data.acad_recog_notes}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const ArtsCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Time Commitment</strong>
                  <div>{list("time_commitment")}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Role</strong>
                  <div>{list("role")}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const EnrichCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.name}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Category</strong>
                  <div>{list("category")}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const ClubCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header  p-5" style={cardStyle}>
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Role</strong>
                  <div>{list("role")}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Club Type</strong>
                  <div>{list("club_type")}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  const JobInternCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5" style={cardStyle}>
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
                	<Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Department</strong>
                  <div>{list("department")}</div>
                </td>
                <td>
                  <strong>Name</strong>
                  <div>{data.org_name}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Url</strong>
                  <div>{data.org_url}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <CareerStepActivity stepActivities={activity.education_activities} />
        </div>

        <Footer />
      </div>
    );
  };

  if (activity.activity_form_id == 295) {
    return <HonorCard />;
  } else if (activity.activity_form_id == 282) {
    return <ResearchCard />;
  } else if (activity.activity_form_id == 296) {
    return <ScholarCard />;
  } else if (activity.activity_form_id == 294) {
    return <TestingCard />;
  } else if (activity.activity_form_id == 293) {
    return <CourseCard />;
  } else if (activity.activity_form_id == 356) {
    return <SummerCard />;
  } else if (activity.activity_form_id == 300) {
    return <AthleticsCard />;
  } else if (activity.activity_form_id == 193) {
    return <OtherCard />;
  } else if (activity.activity_form_id == 290) {
    return <CompetitionCard />;
  } else if (activity.activity_form_id == 297) {
    return <ArtsCard />;
  } else if (activity.activity_form_id == 302) {
    return <EnrichCard />;
  } else if (activity.activity_form_id == 298) {
    return <ClubCard />;
  } else if (activity.activity_form_id == 292) {
    return <JobInternCard />;
  } else {
    return null;
  }
};

export default TrainingModule;
