import React from "react"
import CommonService from '../../services/CommonService'
import env from '../../env'

const FormApi = ({formFn, data}) => {

  formFn.country_path = 'name'

  formFn.get_country = (search, on_success, on_failure) => {
    let req = {
      method: 'get',
      url: `${env.backend}countries`,
      params: {search:search}
    }

    CommonService.useAxios(req).then(({data, status})=>{
      on_success(data.countries)
    })
  }

  formFn.set_country = (item) => {
    data.country = item.name
    data.country_code = item.iso2
  }

  formFn.state_path = 'state_name'

  formFn.get_state = (search, on_success, on_failure) => {
    CommonService.get_state({search:search}).then((res)=>{
      on_success(res.data.states)
    })
  }

  formFn.set_state = (item) => {
    data.state = item.state_name
    data.state_code = item.state_code
  }

  formFn.district_path = 'district_name'
  formFn.get_district = (search, on_success, on_failure) => {
    CommonService.get_district({state_name:data.state ,search:search}).then((res)=>{
      on_success(res.data.cities)  
    })  
  }

  formFn.set_district = (item) => {
    data.district = item.district_name
    data.district_code = item.district_code
  }

  formFn.taluka_path = 'sub_district_name'
  formFn.get_taluka = (search, on_success, on_failure) => {
    CommonService.get_taluka({district_code:data.district_code ,search:search}).then((res)=>{
      on_success(res.data.sub_districts)  
    })  
  }

  formFn.set_taluka = (item) => {
    data.taluka = item.sub_district_name
    data.taluka_code = item.sub_district_code
  }

  formFn.village_path = 'village_name'
  formFn.get_village = (search, on_success, on_failure) => {
    CommonService.get_village({state_code:data.state_code, district_code:data.district_code, search:search}).then((res)=>{
      on_success(res.data.villages)  
    })  
  }

  formFn.set_village = (item) => {
    data.village = item.village_name
    data.village_code = item.village_code
  }

  /*College Multi Select*/
  formFn.colleges_pagenate = {page:1, per_page:10}
  formFn.colleges_path = "name"

  formFn.get_colleges = function(search, on_success, on_failure){
    formFn.colleges_pagenate.college_name = search;
    let req = {
      method: 'post',
      url: `${env.family_tree_backend}colleges/search`,
      data: formFn.colleges_pagenate
    }

    CommonService.useAxios(req).then(({data, status})=>{
      formFn.colleges_list = data.colleges;
      if(data.meta.total && formFn.colleges_pagenate.per_page){
        formFn.colleges_pagenate.pageSize = Math.ceil(data.meta.total/formFn.colleges_pagenate.per_page);
      } 
      on_success(formFn.colleges_list)
    })
  }

  formFn.toggle_colleges = (item) =>{
    const idx = data.colleges_array.findIndex(o => o.id == item.id);
    if(idx > -1) {
      data.colleges_array.splice(idx, 1);
    }else {
      data.colleges_array.push({id:item.id, name:item.name});
    }
    data.colleges = data.colleges_array.map((o)=> o.id).join(",");
    return data.colleges_array
  }

  formFn.exist_colleges = (item) =>{
    return data.colleges_array.findIndex(o => o.id == item.id) > -1;
  }

  return null


  /*Zip*/
  formFn.zip_path = 'zip_code'
  formFn.zip_pagenate = {
    page:1, 
    per_page:10, 
    unique:true, 
    fields:'zip', 
    sort_column:'zip'
  }

  formFn.get_zip = function(search, on_success, on_failure){
    formFn.zip_pagenate.zipcode = search;
    
    let req = {
      method: 'post',
      url: `${env.backend}searchzipcode`,
      data: formFn.zip_pagenate
    }

    CommonService.useAxios(req).then((res)=>{
      formFn.zip_list = res.data.options;
     /* if(res.data.meta.total && formFn.zip_pagenate.per_page){
        formFn.zip_pagenate.pageSize = Math.ceil(res.data.meta.total/formFn.zip_pagenate.per_page);
      } */
      on_success(formFn.zip_list)
    })
  }

  formFn.set_zip = function(obj){
    data.zip = obj.zip_code;
    data.state = obj.state;
    formFn.refreshForm()
  }

}

export {FormApi}