const position = { x: 0, y: 0 };
const positionList = [{x:30, y:50}]
const edgeType = 'smoothstep';

let initialElements = []
let initialEdges = []

const resetElement = () => {
  initialElements = [];
  initialEdges = [];
}
const setElements = (elements) => initialElements = elements;

const gradeElement = (gradeDataSources = [], currentGrade, addActivity, toggleSuggestionModal) => {
  gradeDataSources.forEach((d, i) => {
    let p = null
    if(i!=0){
      p = Object.assign({}, positionList[i-1])
      p.x += 225
      positionList.push(p)
    }else{
      p = positionList[0]
    }

    initialElements.push({
      id: `${d.value}`,
      type: 'AddDataSource',
      data: { label: d.label, currentGrade, addActivity, toggleSuggestionModal},
      position: p,
    })

    /*const source_id = (i==0?`${d.label}`:`${grade_data_sources[i-1]['label']}`)

    initialElements.push({ 
      id: `edge_${d.label}`, 
      source: source_id, 
      target: d.label, 
      type: edgeType, 
      animated: true 
    })*/

  })
}

const setGradeActivities = (activities = {}, activityColor, activityIcon, modalActivity, activityPaginate) => {
  for (let prop in activities) { 
    if(activities[prop] && activities[prop].education_journal_entries.length > 0){
      const gradeNode = findNodeById(initialElements, activities[prop].education_journal_entries[0].grade)
      let p = Object.assign({}, gradeNode.position)
      p.x -= 50
      p.y += 100

      initialElements.push({
        id: prop,
        type: 'AddActivity',
        data:{
          grade: activities[prop].education_journal_entries[0].grade,
          activities: activities[prop].education_journal_entries,
          meta: activities[prop].meta,
          activityColor,
          activityIcon,
          modalActivity,
          activityPaginate,
        },
        position:p,
      })

      initialEdges.push({ 
        id: `edge_${prop}`,
        grade:  activities[prop].education_journal_entries[0].grade, 
        source: activities[prop].education_journal_entries[0].grade.toString(), 
        target: prop, 
        type: edgeType, 
        animated: true 
      })
    }  
  }
}

const setSuggestedActivities = (activities = [], activityColor, grade, cloneActivity, activityIcon) =>{
  initialElements.push({
    id: `sugg_${grade}`,
    type: 'AddSuggestedActivity',
    data: { 
      activities,
      activityColor,
      activityIcon,
      cloneActivity
    },
    position,
  })

  initialEdges.push({ 
    id: 'edge_sugg', 
    source: `${grade}`, 
    target: `sugg_${grade}`, 
    type: edgeType, 
    animated: true, 
  })
}

const setCollegeNodes = (colleges, elements, setElement, selectedCollege, collegeUserViews) =>{
  const sourceId = '20' //college value
  const collegeNode = findNodeById(elements, sourceId)
  if(collegeNode){
    let p = { x: (collegeNode.position.x), y: collegeNode.position.y + 110 }
    let positionList = [p]

    elements.push({
      id: `college_node`,
      type: 'AddCollege',
      data: { 
        colleges,
        selectedCollege,
        collegeUserViews,
      },
      position: p,
    })

    initialEdges.push({ 
      id: `edge_college`, 
      source: sourceId, 
      target: `college_node`, 
      type: edgeType, 
      animated: true, 
    })
  }  
}

const findNodeById =(elements, id) =>{
  return elements.find((i, k)=>{
    return i.id == id
  })
}

export {findNodeById, initialElements, initialEdges, setElements, gradeElement, setGradeActivities, resetElement, setSuggestedActivities, setCollegeNodes}