import React, { useState, useEffect } from 'react'
import SubscriptionService from "./SubscriptionService"

function getFormattedAmount(amount) {
  // Format price details and detect zero decimal currencies
  let amountToFormat = amount
  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  });
  let parts = numberFormat.formatToParts(amountToFormat)
  let zeroDecimalCurrency = true
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  }
  amountToFormat = zeroDecimalCurrency ? amount : amount / 100
  let formattedAmount = numberFormat.format(amountToFormat)
  console.log(formattedAmount)

  return formattedAmount
}

function getDateStringFromUnixTimestamp(date) {
  let nextPaymentAttemptDate = new Date(date * 1000)
  let day = nextPaymentAttemptDate.getDate()
  let month = nextPaymentAttemptDate.getMonth() + 1
  let year = nextPaymentAttemptDate.getFullYear()

  return month + '/' + day + '/' + year
}

function PriceChangeForm(props) {
  let { customerId, subscriptionId, currentProductSelected, newProductSelected, setSelectedProduct,} = props;
  
  let [invoicePreview, setInvoicePreview] = useState({})

  useEffect(() => {
    let req = {
      customerId: customerId,
      subscriptionId: subscriptionId,
      newPriceId: newProductSelected.stripe_price_id
    }
    
    SubscriptionService.retrieveUpcomingInvoice(req).then((res)=>{
      setInvoicePreview(res.invoice)
    })
  }, [customerId, subscriptionId, newProductSelected])

  function confirmPriceChange() {
    let req = {
      subscriptionId: subscriptionId,
      newPriceId: newProductSelected.stripe_price_id
    }
    
    SubscriptionService.updateSubscription(req).then((res)=>{
      setSelectedProduct(newProductSelected)
      //props.history.push('/prices?customerId=' + customer.id);
    })
  }

  return (
    <div className="col-xs-12 p-7">
      <div className="round-medium shadow-small bg-white">
            
        <div className="col-xs-12 bg-green-3 white lh1p6 bg-highlight">
          <h4 className="m-t-15">
            Price Change
          </h4>
        </div>

        <div className="col-xs-12 m-t-15">
          <p className="font-16">
            <b>New price:</b> {newProductSelected.name}.
          </p>

          <p className="font-16">
            <b>You will be charged </b>
            {(invoicePreview && getFormattedAmount(invoicePreview.amount_due)) || ''}{' '}
            on{' '} 
            <span>
              {(invoicePreview.next_payment_attempt && getDateStringFromUnixTimestamp(invoicePreview.next_payment_attempt)) || ''}
            </span>
          </p>          
          
          <div className="col-xs-12 col-sm-6 m-b-5">
            <button className="price-btn bg-green" onClick={() => confirmPriceChange()}>
              Confirm change
            </button>
          </div>
          
          <div className="col-xs-12 col-sm-6 m-b-5">  
            <button className="price-btn bg-lred">
              Cancel
            </button>
          </div>
        </div>

      </div>
    </div>
  );
}

export default PriceChangeForm;