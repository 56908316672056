import env from '../../../env'
import axios from 'axios'
const url = `${env.family_tree_backend}relationship_trackings`

class TrackRelationshipService {
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params:req}).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  update(req){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

}

export default new TrackRelationshipService();