import React, {useContext} from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import CheckAccess from "../../Journey/Roles/CheckAccess"
import Cs from "../../../services/CommonService"
import UserFormService from "../UserFormService"
import FormFields from "./FormFields"

const Form = (props) => {
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  let form = props.form
  form.updated_at = Cs.formatUpdateDate(form.updated_at)

  const cardStyle = {backgroundColor: form.course?.color || '#607D8B'}

  const CopyToClipboard = async () =>{
    await navigator.clipboard.writeText(`${window.location.origin}/public/submissions/${form.journey_category_id}/${form.journey_profile_id}/${form.id}?is_form_wizard=1`)
    alert('Link copied')
  }

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16">
        {form.title}
        <i className="fas fa-expand pull-right white expand" onClick={(e) => props.routeAssessment(form)}></i>
      </div>
      <div className="font-16">
        {form.updated_at} <span className="font-14">by {form.created_user.name}</span>
      </div>
    </div>
  )

  const routeSubmissions = (form) => {
    props.history.push(`/list/submissions/${form.journey_category_id}/${form.journey_profile_id}/${form.id}`)
  }

  const Footer = () => (
    <div className="card-footer">
      <div className="pull-left">
        <span id={`comment_${props.index}`} className="m-l-25" onClick={(e) => props.addNotes(e, form)}>
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge up bg-lgrey">{form.comments_count || 0}</span>
        </span>
       
        <span className="m-l-25" onClick={e=>routeSubmissions(form)}>
          <i className="far fa-sticky-note font-18 text-muted"></i>
          <span className="badge up bg-lgrey">{form.answers_count || 0}</span>
        </span>

        <span className="m-l-25" onClick={e => props.toggleFavourite(form)}>
          <i className="far fa-heart font-18 text-muted"></i>
          <span className="badge up bg-lgrey">{form.answers_count || 0}</span>
        </span>
      </div>
      <div className="pull-right">
        <props.menu form={form} />
      </div>
    </div>
  )

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <Header />
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td><div className="ht-50 scroll-auto" dangerouslySetInnerHTML={{ __html: form.description}}></div></td>
              </tr>
              {form.is_public &&
                <tr>
                  <td>
                    <label>Public URL</label>
                    <div className="pill bg-highlight white" onClick={(e)=>CopyToClipboard(e)}>
                      Copy Link
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <FormFields formFields={form.form_fields} journeyCategory={props.journeyCategory} 
          openFormFieldModal={props.openFormFieldModal} typeOfInput={props.typeOfInput}/>
        </div>
        <Footer/>
      </div>
    )
  }

  return(<Card/>)
}

export default Form;