import React, {useState} from 'react';
import DynamicForm from '../../components/FormBuilder/DynamicForm';

/*Store initial field setting before function to avoid reinitating while re-render*/

let formMode = null; /*Mode New Form or Edit Existing Form*/
let formId = null; /*Form Template id to get form from backend*/
let planner = {}; /*Store  user_id, org_id to send to backend*/
let modal_val = {}; /*Store json form field value */
let formFn = {}; /*To share use case related common code to form field component*/

let create = function(req){
  //post;
}

function Form(props) {
  /*Contain form title, description*/
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  
  formFn.handleChange = (value) =>{
    console.log(value)
    formFn.value = value;
  }

  let get = () => {
    
  }

  if(!formId && params.form_template_id) {
    if(params.planner_id==null || params.planner_id == ''){
      formMode = 'create-form-submissions';
    }else if(params.planner_id){
      formMode = 'edit-form-submissions';
      get();
    }
    formId = params.form_template_id;
  }

  let create = function(req){
    openEduPortal()
  }

  let update = function(req){
    openEduPortal()
  }

  let openEduPortal = function(){
   props.history.push("/organizations");
  }

  return (
    <div className="portlet">
      <div className="portlet-heading bg-highlight color-white">
        <h3 className="portlet-title text-uppercase">
          {post.title}
        </h3>
         <div className="portlet-widgets"></div>
         <div className="clearfix"></div>
      </div>
      <div id="portlet1" className="panel-collapse collapse in">
        <div className="portlet-body p-t-0">
          <DynamicForm formMode={formMode} formId={formId} form={planner} data={modal_val} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={openEduPortal} 
          setPost={setPost}/>
        </div>
      </div>
    </div>
  );
}

export default Form;
