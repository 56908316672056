import React, { useState, Fragment, useEffect } from "react";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CoursePreparation from "../../CoursePreparations/List/CoursePreparation";
import CoursePreparationsService from "../../CoursePreparations/CoursePreparationsService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import CardMenu from "../../../../Modals/CardMenu";
import { Link } from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import useStyle from "../../../../../hooks/useStyle";
import CheckAccess from "../../../Roles/CheckAccess";
import CoursePreparationMenu from "../../CoursePreparations/List/CoursePreparationMenu";
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import CalendarModal from "../../../../Modals/CalendarModal";
import JourneyFilesLinksModal from "../../../JourneyFilesLinks/JourneyFilesLinksModal";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../../components/Common/NotificationPopup";
import {NavIcon,CardMenuInfo,MenuItem} from '../../../../Common/MenuItem'

let requestParams = {};
let selectedActivity = {};
let data_source = {};
let journey_category = null;
let share = null;
let step_activity_form = {
  team_book: { form_id: 260, type: "step_activity" },
  team: { form_id: 232, type: "team_step_activity" },
  sponsor: { form_id: 305, type: "sponsor_step_activity" },
};

const CoursePreparationList = (props) => {
  useStyle("card");
  useStyle("card_table");
  useStyle("add_role_dialog");

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  let cardGridCss = "col-xs-12 col-sm-6 col-md-4";
  let cardHeightCss = "ht-227";
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8";
    cardHeightCss = "mih-380";
  }

  const { state: authState, dispatch } = React.useContext(AuthContext);
  const [journeyActivityList, setJourneyActivityList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagemeta, setPagemeta] = useState([]);
  
  const { isOpen: isFilterOpen, toggleModal: toggleFilterModal } = useModal();
  const { isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal();
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal();
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal();
  const { isOpen: isChecklistOpen, toggleModal: toggleChecklistModal,} = useModal();
  const { isOpen: isCalendarOpen, toggleModal: toggleCalendarModal,} = useModal();
  const { isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal,} = useModal();

  useEffect(() => {
    journey_category = null;
    requestParams = {
      data_source_params: { data_source_id: 3196, response_type: "object" },
      education_journey_profile_id: params.education_journey_profile_id,
      filter_by_role: false,
      journey_category_id: params.journey_category_id,
      page: currentpage,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "desc",
      totalItems: 0,
      search: props.searchString,
      id: queryParam.id,
    };

    getJourneyActivityList();
  }, [props.searchString, currentpage]);

  let getJourneyActivityList = () => {
    setLoading(true);
    CoursePreparationsService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        journey_category = res.data.journey_category;
        share = res.data.invited_journey_member
        CheckAccess.userAccess(
          authState.user,
          journey_category,
          share
        );
        setJourneyActivityList(res.data.course_preparations);
        setLoading(false);
      }
    });
  };

  const openFileLinks = (e) => {
    e.preventDefault();
    toggleFileLinkModal(e);
  };

  const addNotes = (e, a) => {
    selectedActivity = a;
    toggleCommentModal(e);
  };

  const addChecklist = (e, a) => {
    selectedActivity = a;
    toggleChecklistModal(e);
  };

  const addReminderdate = (e, a) => {
    selectedActivity = a;
    toggleCalendarModal(e);
  };

  const deleteActivity = (e) => {
    e.preventDefault();
    CoursePreparationsService.delete(selectedActivity.id).then((res) => {
      if (res.status == 204) {
        setJourneyActivityList(
          journeyActivityList.filter((i) => i.id !== selectedActivity.id)
        );
      }
    });
    toggleDeleteModal(e);
  };

  const showMenu = (e, a) => {
    selectedActivity = a;
    toggleMenuModal(e);
  };

  const routeActivity = (activity) => {
    props.history.push(
      `/team/activity/${params.journey_category_id}/${params.education_journey_profile_id}?id=${activity.id}`
    );
    setJourneyActivityList(
      journeyActivityList.filter((i) => i.id == activity.id)
    );
  };

  const copyToPlanner = (e, activity) => {
    e.preventDefault();
    CoursePreparationsService.copyToPlanner(activity.id).then((res) => {
      if (res.status == 200) {
      }
    });
  };

  const menuItems = ({ item }) => {
    const title = item.data && item.data.name.replace(/\s/g, "_");

    return (
      <Fragment>
        <CardMenuInfo formId={item.course_form_id} recordId={item.record_id} 
          createdAt={item.created_at} />

        {CheckAccess.hasEditAccess(authState.user, journey_category, item) && (
          <MenuItem id="edit_icon" 
            link={`/course/preparation/form/${item.journey_category_id}/${item.journey_profile_id}/${item.id}`}
            icon="far fa-edit"
            action="Edit"
            />
        )}

        {CheckAccess.hasDeleteAccess(
          authState.user,
          journey_category,
          item
        ) && (
          <MenuItem id="delete_icon" 
            icon="far fa-trash-alt"
            action="Delete"
            onSelect={(e) => {toggleDeleteModal(e)}}
          />
        )}

        {CheckAccess.hasStepActivityAccess(
          authState.user,
          journey_category,
          item
        ) && (
          <MenuItem id="activity_icon" 
            link={`/team/step_activity/form/${item.journey_category_id}/${item.education_journey_profile_id}/${item.id}`}
            icon="far fa-sticky-note"
            action="Add Activity"
          />
        )}

        {CheckAccess.hasRoleAccess(authState.user, journey_category, item) && (
          <Link to={{pathname: `/journey/user_role/${item.journey_category_id}/${item.education_journey_profile_id}/academic_courses.course_preparation/${item.id}`,
              state: { journey: props.location.state, activity: item },
            }}>
            <i className="far fa-eye-slash text-muted font-15" aria-hidden="true"></i>
            <span className="font-13">
              Permissions
              <i className={`fas ${item.user_roles == null? "fa-times m-l-120 m-t-n2 lred": "fa-check m-l-120 m-t-n2 green"} `}></i>
            </span>
            <i className="fa fa-angle-right"></i>
          </Link>
        )}

        {CheckAccess.hasEditAccess(authState.user, journey_category, item) && (
          <a href="#" onClick={(e) => copyToPlanner(e, item)}>
            <i className="far fa-clone font-15" aria-hidden="true"></i>
            <span className="font-13">Copy to Planner</span>
            <i className="fa fa-angle-right"></i>
          </a>
        )}

        <a href={`mailto:${title}-CP-${item.record_id}@cuetree.com`} target="_blank">
          <i className="far fa-envelope font-15" aria-hidden="true"></i>
          <span className="font-13">{`${title}-CP-${item.record_id}@cuetree.com`}</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ activity }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, activity)} className="fas fa-bars m-r-15"/>
  )

  const journeyActivityCard =
    journeyActivityList &&
    journeyActivityList.map((a, i) => (
      <div className={cardGridCss} key={i}>
        <CoursePreparation
          activity={a}
          addNotes={addNotes}
          addChecklist={addChecklist}
          dataSource={data_source}
          menu={Menu}
          journeyCategory={journey_category}
          cardHeightCss={cardHeightCss}
          routeActivity={routeActivity}
          addReminderdate={addReminderdate}
          index={i}
        />
        {selectedActivity.id == a.id && (
          <ProjectMenu
            isOpen={isMenuOpen}
            toggleModal={toggleMenuModal}
            item={selectedActivity}
            type="activity"
            MenuItems={menuItems}
            menuHeight="365px"
          />
        )}
      </div>
    ));

  /*if(journeyActivityList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  if (queryParam.id && !loading && journeyActivityList.length == 0) {
    return (
      <NotificationPopup
        iconClass="fa fa-times-circle lred font-24"
        header="Not Found"
        message="Sorry, this message was deleted by the author"
      />
    );
  }

  return (
    <React.Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4 pull-left">
          {journey_category && <JourneyTitle title={"Course Preparations"}
            project={journey_category} setHelpId={props.setHelpId}/> }
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="add_new_activity" dataTip="Create New Activity" 
            link={`/course/preparation/form/${params.journey_category_id}/${params.journey_profile_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"/>

          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/course/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>

          <NavIcon id="file_links" dataTip="Journey Files and Links" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            onSelect={openFileLinks} icon="fas fa-link"/>
        </div>    
      </div>

      <div className="page-content row">
        {journeyActivityCard}
        {queryParam.id && journeyActivityList.length > 0 && (
          <div className="col-sm-12 col-sm-6 col-md-4">
            <CommentsModal
              isOpen="true"
              toggleModal={toggleCommentModal}
              item={journeyActivityList[0]}
              type="academic_courses.course_preparation"
              showInline="true"
            />
          </div>
        )}
        <Pagination
          totalItems={requestParams.totalItems}
          currentpage={currentpage}
          setCurrentpage={setCurrentpage}
          itemsPerPage={requestParams.per_page}
        />
      </div>

      {isCommentOpen && (
        <CommentsModal
          isOpen={isCommentOpen}
          toggleModal={toggleCommentModal}
          item={selectedActivity}
          type="academic_courses.course_preparation"
        />
      )}
      {isChecklistOpen && (
        <ChecklistsModal
          isOpen={isChecklistOpen}
          toggleModal={toggleChecklistModal}
          item={selectedActivity}
          type="academic_courses.course_preparation"
        />
      )}
      {isFilterOpen && (
        <CoursePreparationMenu
          params={params}
          isOpen={isFilterOpen}
          toggleModal={toggleFilterModal}
        />
      )}
      <ConfirmMenu
        isOpen={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        success={deleteActivity}
      />
      {isCalendarOpen && (
        <CalendarModal
          toggleModal={toggleCalendarModal}
          item={selectedActivity}
          type="edu_team_journey.activity"
        />
      )}
      {isFileLinkOpen && (
        <JourneyFilesLinksModal
          toggleModal={toggleFileLinkModal}
          journeyCategory={journey_category}
        />
      )}
      <SideMenuBar helpId="15" />
    </React.Fragment>
  );
};

export default CoursePreparationList;
