import Cs from '../../services/CommonService'

let route = {}

route.routeNotification = (n, props) => {
  route.props = props
  switch (n.notifiable_type){
    case 'comment':
      route[n.item_type](n, 'comment')
      break 
    case 'favourite':
      route[n.item_type](n)
      break
    case 'invited_journey_member':
      n.journey_category.id = n.journey_category_id
      Cs.routeJourney(n.journey_category, props)
      //$state.go('app.education_journey_profile', {'journey_category_id':n.item_id});
      break
    case 'shared_item':
      route[n.item_type](n)
      break
    default:
      route[n.notifiable_type](n)
      break
  }
}

route.college = function(n){
  route.props.history.push(`/highschool/favourite/colleges/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.item_id}`)
  //$state.go('app.college_profile', {'college_id':n.item_id, 'comment_id':n.notifiable_id, 'journey_category_id':n.journey_category_id});
}

route.favourite = function(n){
  //$state.go('app.college_profile', {'college_favourite_id':n.item_id, 'comment_id':n.notifiable_id, 'journey_category_id':n.journey_category_id});
  //props.history.push(`/highschool/favourite/colleges/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.item_id}`)
}

route.education_journal_entry = function(n, type){
  if(type == 'comment'){
      /*$state.go('app.team_activity_profile', {
        'activity_id':n.item_id,
        'comment_id':n.notifiable_id, 
        'journey_category_id':n.journey_category_id  
      })*/
      route.props.history.push(`/highschool/activity/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.item_id}&comment_id=${n.notifiable_id}`)
    }else{
      /*$state.go('app.team_activity_profile', {
        'activity_id':n.notifiable_id, 
        'journey_category_id':n.journey_category_id 
      })  */
      route.props.history.push(`/highschool/activity/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.notifiable_id}`)
    }
}

route.education_resource = function(n){
    //$state.go('app.education_resource_page', {'education_resource_id':n.item_id, 'notifiable_id':n.notifiable_id, 'journey_category_id':n.journey_category_id, 'notifiable_type':n.notifiable_type});
}
  
route['edu_team_journey.step_activity'] = function(n, type){
  if(type == 'comment'){
    /*$state.go('app.team_step_activity_profile', {
      'step_activity_id':n.item_id, 
      'comment_id':n.notifiable_id, 
      'journey_category_id':n.journey_category_id 
    })*/
    route.props.history.push(`/team/activity/${n.journey_category_id}/${n.education_journey_profile_id}?step_activity_id=${n.item_id}&comment_id=${n.notifiable_id}`)
  }else{
    /*$state.go('app.team_step_activity_profile', {
      'step_activity_id':n.notifiable_id, 
      'journey_category_id':n.journey_category_id 
    })*/
    route.props.history.push(`/team/activity/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.item_id}&step_activity_id=${n.notifiable_id}`)
  }
}

route['edu_team_journey.activity'] = function(n, type){
  if(type == 'comment'){
    /*$state.go('app.team_activity_profile', {
      'activity_id':n.item_id,
      'comment_id':n.notifiable_id, 
      'journey_category_id':n.journey_category_id  
    })*/
    route.props.history.push(`/team/activity/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.item_id}&comment_id=${n.notifiable_id}`)
  }else{
    /*$state.go('app.team_activity_profile', {
      'activity_id':n.notifiable_id, 
      'journey_category_id':n.journey_category_id 
    })  */
    route.props.history.push(`/team/activity/${n.journey_category_id}/${n.education_journey_profile_id}?id=${n.notifiable_id}`)
  }
}

route['carrier_training.module'] = function(n, type){
  if(type == 'comment'){
    route.props.history.push(`/career_training/modules/${n.journey_category_id}/${n.education_journey_profile_id}/${n.email_data.course_id}?module_id=${n.email_data.module_id}&commentable_type=${n.item_type}&commentable_id=${n.item_id}`)
  }
}

route['carrier_training.topic'] = function(n, type){
  if(type == 'comment'){
    route.props.history.push(`/career_training/modules/${n.journey_category_id}/${n.education_journey_profile_id}/${n.email_data.course_id}?module_id=${n.email_data.module_id}&topic_id=${n.email_data.topic_id}&commentable_type=${n.item_type}&commentable_id=${n.item_id}`)
  }
}

route['carrier_training.content'] = function(n, type){
  if(type == 'comment'){
    route.props.history.push(`/career_training/modules/${n.journey_category_id}/${n.education_journey_profile_id}/${n.email_data.course_id}?module_id=${n.email_data.module_id}&topic_id=${n.email_data.topic_id}&commentable_type=${n.item_type}&commentable_id=${n.item_id}`)
  }
}

export default route;