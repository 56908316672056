import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import SubscriptionService from "./SubscriptionService"
import SubscriptionAccesses from './SubscriptionAccesses'
import GenericModal from '../Modals/GenericModal'
import {PillDsList} from '../Journey/UserRoles/NotificationPill'
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { EditGrayIcon, LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons";

let selectedSubscription = {}
let requestParams ={}
let dataSources = []

const SubscriptionsTable = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isAccessOpen, toggleModal:toggleAccessModal} = useModal()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      organization_id: currentUser.current_organization_id,
      data_source_params: {
        data_source_id:[3678],
      }
    } 
    getAll()
  }, [])

  const getAll = () =>{
    SubscriptionService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSources = res.data.data_source_list
        setList(res.data.subscriptions)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onDelete = (e, sub) => {
    selectedSubscription = sub
    toggleDeleteModal(e)
  }

  const deleteSubscription = (e) => {
    e.preventDefault()
    SubscriptionService.delete(selectedSubscription.id).then((res) => {
      if (res.status == 200) {
        setList(list.filter(i => i.id !== selectedSubscription.id))
      }
    })
    toggleDeleteModal(e)
  }

  const updateSub = (sub, key) =>{
    let req = {
      id: sub.id,
      [key]: !sub[key]
    }
    SubscriptionService.update(req).then((res) => {
      if (res.status == 200) {
        sub[key] = !sub[key]
        setList([...list])
      }
    })
  }

  const openAccessModal = (o) =>{
    selectedSubscription = o
    toggleAccessModal()
  }

  const tableData = list.map((o, k) => {
    let ds = o.data_source || {}
    let data = o.data || {}

    return(
      <TR key={k}>
        <TD className="text-center">
          {o.id}
        </TD>
        <TD className="text-center">
          {data.name}
        </TD>
        <TD className="text-center d-in-grid">
          <PillDsList list={o.data_source?.journey_types} dataKey='label'/> 
        </TD>
        <TD className="text-center">
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD className="text-center">
          {o.marketing_data?
            <span className={o.is_approved?'fas fa-check-circle font-18':'far fa-circle font-18'} onClick={e=> updateSub(o, 'is_approved')}/>
            :<span className="font-14">Add marketing data</span>
          }
        </TD>
        <TD className="text-center font-18">
          <span className="far fa-list-alt" onClick={e=>openAccessModal(o)}/>
        </TD>
        <TD className="font-16 d-flex">
          <Link to={`/subscription/create/${o.id}`}>
            <span className="fas fa-edit color-blue2-dark"/>
          </Link>
          
          <Link to={{pathname:`/subscription/users/${o.id}`, state:o}}>
            <span className="fas fa-users color-blue2-dark m-l-15"/>
          </Link>

          <Link to={`/subscriptions/marketing/${o.id}`}>
            <span className="fas fa-ad color-blue2-dark m-l-15"/>
          </Link>

          <span onClick={e=>onDelete(e, o)}>
            <span className="fas fa-trash lred m-l-15"/>
          </span>
        </TD>
      </TR>
    )
  })

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                Subscriptions
              </div>
              <div className="font-15">
                List of Subscriptions - {list.length}
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="new_org" dataTip="Create New Subscription" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-plus"
                link="/subscription/create"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              <Table className="shadow-small">
                <THead>
                  <TR>
                    <TH onClick={e=>sort('record_id')} >
                      ID 
                      <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                    </TH>
                    <TH onClick={e=>sort('data.name')} >
                      Name 
                      <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
                    </TH>
                    <TH onClick={e=>sort('data.journey_types')}>
                      Journey Types 
                      <SortDirection sort_column={requestParams.sort_column} column="data.journey_types" reverse={requestParams.reverse}/>
                    </TH>
                    <TH onClick={e=>sort('created_at')} >
                      Date
                      <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
                    </TH>
                    <TH onClick={e=>sort('is_active')} >
                      Active
                      <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                    </TH>
                    <TH onClick={e=>sort('is_active')} >
                      Components
                      <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                    </TH>
                    <TH>
                      Action
                    </TH>
                  </TR>
                </THead>
                <TBody>
                  {tableData}
                </TBody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} 
      success={deleteSubscription}/>
      
      {isAccessOpen && 
        <GenericModal component={SubscriptionAccesses} 
          toggleModal={toggleAccessModal}
          subscription={selectedSubscription} 
          title={selectedSubscription.data.name}
          isPopupMode="true" widthCss="w-98p"
          components={dataSources?.journey_types?.options || []}/>
      }
    </Fragment>
  )

}

export default SubscriptionsTable;