import React, { Fragment, useState, useEffect, useContext } from "react"
import ImageEditor from "../ImageEditor/ImageEditor"
import useModal from "../../hooks/useModal"
import AuthenticationService from "../../services/AuthService"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import GenericModal from "../Modals/GenericModal"
import env from "../../env"

let profileImage = "images/avatar-2.jpg"

const ProfileImageUpload = ({}) => {
  const {state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext)
  
  if (currentUser.profile_image_url) {
    profileImage = env.file_url + currentUser.profile_image_url
  }

  const { isOpen:isImageEditorOpen, toggleModal:toggleImageEditorModal } = useModal()

  const profileImageUpload = (event, ele, blob) => {
    try {
      let file = null 
      if(event){
        let files = event.target.files
        let FileSize = files[0].size / 1024 / 1024; // in MB
        if (FileSize > 5) {
          event.target.value = null
          return
        } else {
          file = files[0]
        }
      }else{
        file = blob
      }

      if (file) {
        AuthenticationService.updateProfileImage(file).then((res) =>{
          if (res.status == 422) {
          
          } else if (res.status == 200) {
            toggleImageEditorModal()
            profileImage = res.data.user.profile_image_url
            ele.value = null
            dispatch({
              type: "UPDATE",
              payload: res.data,
            })
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  return(	
  	<>
  	  <div className="form-group">
        <label className="font-10">
          Add/Edit Profile Image
          <small>(size should be 100 x 100)</small>
        </label>
        <img onClick={e=>toggleImageEditorModal(e)}
          title="Add/Edit Profile Image"
          style={{ width: "64px" }}
          src={profileImage}/>
      </div>

  	  {isImageEditorOpen && (
        <GenericModal
          component={ImageEditor}
          image={profileImage}
          saveImage={profileImageUpload}
          title="Image Editor"
          isOpen={isImageEditorOpen}
          toggleModal={toggleImageEditorModal}
          minWidth = "200"
          minHeight = "200"
          maxWidth = "200"
          maxHeight = "200"/>
      )}	
  	</>	
  )
}

export default ProfileImageUpload;