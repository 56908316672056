import React, {Fragment, useState, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'

const AccountConfirmation = (props) => {
  useStyle('signup')

  let params = props?.match?.params
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const currentSubscription = currentUser.current_subscription

  return (
    <div id='account-card' className="animated fadeIn">
      <div id='lower-side'>
        <p className="p-12">
          You have successfully subscribed to the cueTree Educational service. 
          Your educationa journey is almost ready. 
          Next, you can experience a welcome tour for journey. 
          You will be able to skip the tour and review it later.
        </p>
        <p className="p-12 white" style={{backgroundColor:'#1AAD87'}}>
          Subscription plan: {currentSubscription.name} <br/>
          ID: {currentSubscription.subscription_user_id} <br/>
          Start date: {currentSubscription.created_at} <br/>
        </p>
        <p className="p-12">
          If you did not subscribe or intend to subscribe, please notify us promptly by submitting this form.
        </p>
      </div>
    </div>            
  )

}

export default AccountConfirmation;