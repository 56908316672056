import React from "react"
import Cs from '../../../services/CommonService'

const News = (props) => {
  let news = props.newsList
  let data = news.data || {}
  let newsTags = props.dataSource.news_tags
  let tagColor = props.dataSource.relevant_relevant_news_topics_topics
  const cardStyle = {backgroundColor: "#607D8B"}

  const SpanList = ({data, styleName}) => data ? data.map((d, i) => (
    <span className={styleName +' m-l-5'} key={i}>{d}</span>
  )):''

  const infoLabel = data.news_tags_array && data.news_tags_array.map((la,i) => {
    let bgColor = tagColor.options[parseInt(la)] && tagColor.options[parseInt(la)].bg_color || 'black'
    return( 
      <div className="pill m-r-15 white" style={{backgroundColor:bgColor}} key={i}>
        {newsTags.options[parseInt(la)]?.label}
      </div>
    )
  })

  const Footer = () =>(
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span className="m-l-15" onClick={(e) => props.addNotes(e, news)}>
          <i className="far fa-comment font-18 text-muted"/>
        </span>
      </div>
    </div>
  )

  const UserCard = () => {
    return (
        <div className="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
          <div className="flex">
            <div className="flex aligncenter justifycenter m-r-15">
              <img src={news.image}  width="150"/>
            </div>
            <div>
              <h4 className="fw700 f14 card-heading">{data.short_description}</h4>
              <a href={data.public_url} target="_blank" className="text-gray f12 m-b-0">
                {Cs.extractHostName(data.public_url)}
              </a>
              <p className="text-gray f12 m-b-0">
              {Cs.formatUpdateDate(data.date_published_article_formatted_date)}
              </p>
              <p className="m-b-5">
                <SpanList data={data.relevant_topics_array} styleName="pill text-left bg-highlight white"/>
              </p>
            </div>
          </div>
          <div className="arrow-icon flex aligncenter justifycenter border-rounded-10">
            <span onClick={(e) => props.addNotes(e, news)}
              className="table-icon table-icon-gray flex aligncenter justifycenter">
              <i className="far fa-comment font-18 text-muted"/>
            </span>
          </div>
        </div>
    )
  }

  const AdminCard = () => {
    return (
      <div className="flex justspacebetween alignsflextart m-b-20 border-dashed-sm p-b-20 notification-card p10">
        <div className="flex alignsflextart">
          <span className="flex aligncenter user_dropdown-xs m-r-15">
            <img src={news.image}  width="150"/>
          </span>
          <div className="flex coldir">
            <h5 className="f20 fw500 m-b-5">
              <a href={data.news_url} target="_blank">{data.title_news_item}</a><br/>
              <span className="text-gray f14">{Cs.formatUpdateDate(data.date_published_article_formatted_date)}</span>
            </h5>
            <p className="m-b-5">
              <SpanList data={data.relevant_topics_array} styleName="pill text-left bg-highlight white"/>
            </p>
            <p>
            <a href={data.news_url} target="_blank" className="underline highlight">{Cs.extractHostName(data.news_url)}</a>
            </p>
          </div>
        </div>
        <span onClick={(e) => props.addNotes(e, news)}
          className="table-icon table-icon-gray flex aligncenter justifycenter">
          <i className="far fa-comment font-18 text-muted"/>
        </span>
      </div>
    )
  }
  
  if(news.form_id == 241){
    return <UserCard/>
  }else{
    return <AdminCard/>  
  }
    
}

export default News;