import React, {Fragment, useState, useContext, useEffect, useMemo} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import SubscriptionUsersService from "./SubscriptionUsersService"
import GenericModal from '../Modals/GenericModal'
import {PillDsList} from '../Journey/UserRoles/NotificationPill'

let selectedSubUser = {}
let requestParams ={}

const SubscriptionUserTable = (props) => {
  useStyle('table')
  
  const params = props.match.params
  const subscription = props.location?.state

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [subUserList, setSubUserList] = useState([])

  const SubscriptionUserService = useMemo(() =>
    new SubscriptionUsersService('backend', 'subscriptions/'+params.subscription_id+'/users')
  , [params.subscription_id]);

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      subscription_id: params.subscription_id
    } 
    getAll()
  }, [])

  const getAll = () =>{
    SubscriptionUserService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setSubUserList(res.data.subscription_users)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const tableData = subUserList.map((o, k) => {
    return(
      <tr key={k}>
        <td className="text-center">
          {o.id}
        </td>
        <td className="ellipsis-lg text-center">
          {o.created_user.name}
        </td>
        <td className="text-center text-center">
          {Cs.formatUpdateDate(o.created_at)}
        </td>
        <td className="text-center">
          <span className={o.active?'fas fa-check-circle font-18':'far fa-circle font-18'}/>
        </td>
        <td className="font-16 d-flex">
         
        </td>
      </tr>
    )
  })

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          <h4>{subscription?.data?.name}</h4>
        </div>
        <div className="col-xs-4 text-right font-14">
          List of Subscription Users - {subUserList.length}
        </div>
      </div>
        
      <div className="page-content row">
        <div className="table-responsive">
          <table className="shadow-small">
            <thead>
              <tr className="white bg-black">
                <th onClick={e=>sort('id')} className="p-2 bold-400 text-center miw-95">
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="id" 
                  reverse={requestParams.reverse}/>
                </th>
                <th onClick={e=>sort('user.name')} className="p-2 bold-400 text-center miw-95">
                  User 
                  <SortDirection sort_column={requestParams.sort_column} column="data.name" 
                  reverse={requestParams.reverse}/>
                </th>
                <th onClick={e=>sort('created_at')} className="p-2 bold-400 text-center miw-95">
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="created_at" 
                  reverse={requestParams.reverse}/>
                </th>
                <th onClick={e=>sort('active')} className="p-2 bold-400 text-center miw-95">
                  Active
                  <SortDirection sort_column={requestParams.active} column="active" 
                  reverse={requestParams.reverse}/>
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </table>
        </div>
      </div>
      
    </Fragment>
  )

}

export default SubscriptionUserTable;