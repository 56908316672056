import React ,{Fragment ,useEffect,useState}from "react";
import Teamnodes from '../../constants/Teamnodes';
import useStyle from '../../hooks/useStyle';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import DigitalContentService from "../../components/DigitalContent/DigitalContentService";
import skrollr from './skrollr.js'
import env from "../../env"

let requestParams = {}

const TemplateMarketing = () => {
  useStyle('marketing');
  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [digitalContentList, setDigitalContentList] = useState([]);

  window.addEventListener("scroll", ()=>{
    console.log(document.documentElement.scrollTop)
  })

  useEffect(() => {
    requestParams = {
      'content_type': 'marketing',
      'page': 1,
      'paginate': 1,
      'per_page': 10,
      'sort_column': 'updated_at',
      'sort_direction': 'asc',
      'totalItems': 0,
      'search': null,
    }

    getMarketingContent();
  }, [currentUser.id]);

  let getMarketingContent = () => {
    DigitalContentService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       requestParams.totalItems = res.data.meta.total
       setDigitalContentList(res.data.digital_contents)
       skrollr.init();
      }
    })
  }

  let skroll = skrollr || null;
   useEffect(() => {

  }, []);

  let scroll = [
    {"data-0":"right:0%;top:20%", "data-500":"top:0%", "data-1000":"right:100%;top:0%"},
    {"data-500":"right:-100%;top:20%", "data-750":"right:-50%;top:0%", "data-1000":"right:0%;", "data-1500":"top:0%", "data-2000":"right:100%;"},
    {"data-1500":"right:-100%;top:20%", "data-1750":"right:-50%;top:0%", "data-2000":"right:0%;", "data-2500":"top:0%", "data-3000":"right:100%;"},
    {"data-2500":"right:-100%;top:20%", "data-2750":"right:-50%;top:0%", "data-3000":"right:0%;", "data-3500":"top:0%", "data-4000":"right:100%;"}
  ]

  const HeaderView = ({ma}) =>(
    <div className="col-xs-12 col-sm-6">
		<img className="img-responsive m-t-15 m-b-15" src={env.file_url+ma.data.illustration}/>
      </div>
  )

  const ContentView = ({ma}) =>(
    <div className="col-xs-12 col-sm-6 m-t-45 p-12">
		<div className="fts-18 l-h-1p5">
		<h1 className="text-center">{ma.data.heading}</h1>
			{ma.data.notes &&
			<p className="m-b-10">
			{ma.data.notes}.{ma.data.box_1_note}.{ma.data.box_2_note}.{ma.data.box_3_note}
			</p>
			}
			<p className="m-b-10">
			{ma.data.button_text && <button className="bouton" >
			{ma.data.button_text}
			</button>}
			</p>
        </div>
    </div>
  )
  
  const OddView = ({ma}) =>(
    <Fragment>
      <HeaderView ma={ma}/>
	    <ContentView ma={ma}/>
    </Fragment>
  )

  const EvenView = ({ma}) =>(
    <Fragment>
      <HeaderView ma={ma}/>
      <ContentView ma={ma}/>
    </Fragment>
  )

  const marketingTile = digitalContentList.map((ma, i) => {
  	return(
      <div className="row p-32 bg-info" style={{'position': 'fixed', 'float': 'none', 'width': '100%','height':'100%', 'padding': '5% 2% 1% 5%', 'backgroundColor': ma.data.color || 'rgb(63, 183, 238)'}} key={i} {...scroll[i]}>
        {i%2==0 ? <OddView ma={ma}/> : <EvenView ma={ma}/>}
      </div>
    )
  })

  return (
    <Fragment>   
      <div className="marketing-header row">
        <div className="col-md-2 col-xs-4 m-t-5">
          <a href="/signup"><img className="marketing-logo" src={'./../../../images/cueTree_logo.png'} /></a>
        </div>
        <div className="col-md-10 col-xs-8 marketing-title m-t-5">
          <h4>cueTree Learning Journeys</h4>
          <span className="bg-black white round-tab">BETA</span>
        </div>
      </div>
      {marketingTile}
    </Fragment>
  )
}

export default TemplateMarketing;