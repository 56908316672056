import React, { useState, Fragment, useContext, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from '../../../contexts/Authentication/AuthStateProvider';
import ReactTooltip from 'react-tooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers'

let timeout = null;
let invalid_emails = []
const InvitedUserModal = ({ inviteUser, isOpen, toggleModal, addInviteUser, roles, componentList, journey}) => {

  if(inviteUser){
    inviteUser.role_ids = inviteUser.role_ids || [];
    inviteUser.access_list = inviteUser.access_list || [];
  }

  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);
  
  const [newInviteUser, setNewInviteUser] = useState(inviteUser);
  const [showUser, setShowUser] = useState(false);
  const [loading, setLoading] = useState(false);
  let [error, setError] = useState({})

  let typeOfUser = null;
  try{
    typeOfUser = currentUser.domain_info.data_source.type_of_use.label
    inviteUser.partnership_id = currentUser.domain_info.partnership_org_id
  }catch(e){

  }

  useEffect(() => {
    FormHelper.validate('ct_form', newInviteUser, error, setError)
  }, [newInviteUser])

  useEffect(() => {
    return ()=>{
      invalid_emails = []
    }
  }, [])

  /* 
   * To check an item exist in checkbox options.
   */
  const setRole = (item, key) => {
    if(journey && ['high_school_journey', 'career_journey'].includes(journey.edu_journey_type)){
      //newInviteUser.access_type = item.access_type;
      newInviteUser[key] = [item];
    }else{
      let list = newInviteUser[key];
      let idx = list.indexOf(item);
      if(idx > -1) {
        list = list.filter(i => i !== item)
        newInviteUser[key] = list;
      }else {
        list.push(item)
      }
    }
    setNewInviteUser({...newInviteUser})
  }

  const exists = (item, key) => {
    let list = newInviteUser[key];
    return list.indexOf(item) > -1
  }

  const toggle = (access_type) => {
    setNewInviteUser({...newInviteUser, 'access_type': access_type});
  }

  const setEmail = (member) => {
    newInviteUser.email = member.data.email_id;
    newInviteUser.family_member_id = member.id;
    setShowUser(false);
  }

  const setCloneJourney = () =>{
    setNewInviteUser({...newInviteUser, 'clone_journey': !newInviteUser.clone_journey});
  }

  const setUserEmail = (value) =>{
    clearTimeout(timeout);

    // Make a new timeout set to go off in 1000ms (1 second)
    timeout = setTimeout(() => {
      newInviteUser.email = value
      invalid_emails = FormHelper.validateEmails(value)
      setNewInviteUser({...newInviteUser})
    }, 1000);
  }

  const validateForm = () =>{
    if(invalid_emails?.length == 0 && error.is_valid){
      return true
    }

    return false
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      <div className="flex justspacebetween modal-title">
        <h4 className="f18 text-white fw500 p12-10-15">{newInviteUser.id?'Update':'Invite'} User</h4>
        <button className="btn-close"
          title="Close Modal" onClick={e=>toggleModal(e)}>×
        </button>
      </div>
      
      {loading ? <div className="spinner"></div> : <form id="ct_form" className="ml-container">
        <div className="ml-section">

        <label className="req-field"><b>Email</b></label>
        <input onChange={e=>setUserEmail(e.target.value)} className="ml-input ml-border"
          type="text" placeholder="Email 1, Email 2..." name="email" required defaultValue={newInviteUser.email}/>
          <div className="errormsg ml-margin-bottom">
            {invalid_emails.toString()} {invalid_emails.length>0 && 'Email Invalid'}
          </div>

        {typeOfUser == 'Organizational Use' && <Fragment>
          <label className="req-field"><b>Type of Journey Instance</b></label>
        
          <span className="checkbox-container" onClick={e => setCloneJourney()}>
            <span>Shared journey with other users</span>
            <input type="checkbox" name="clone_journey" checked={!newInviteUser.clone_journey} readOnly/>
            <span className="checkbox-checkmark"></span>
          </span>
        
          <span className="checkbox-container" onClick={e => setCloneJourney()}>
            <span>Separate journey for this user</span>
            <input type="checkbox" name="clone_journey" checked={newInviteUser.clone_journey} readOnly/>
            <span className="checkbox-checkmark"></span>
          </span>
          </Fragment>
        }

        {roles.length > 0 && <Fragment>
          <label><b>Role</b></label>
          <div className="m-b-5">To add or remove a role, click “Roles” on the Journey page</div>
          <input type="hidden" name="role_ids" required/>
          {roles.map((r, k) => (
            <span key={k} className="checkbox-container" data-tip={r.role_description} onClick={e => setRole(r.record_id, 'role_ids')}>
              <span>{r.role}</span>
              <input type="checkbox" checked={exists(r.record_id, 'role_ids')} readOnly/>
              <span className="checkbox-checkmark"></span>
              {/*<p>{r.role_description}</p>*/}
            </span>
          ))}
          </Fragment>
        }

        <label><b>Description</b></label>
        <textarea maxLength="100" onChange={e=>{newInviteUser.message = e.target.value}}
          className="ml-input ml-border" placeholder="Invitation Message" 
          defaultValue={newInviteUser.message} name="message">
        </textarea>
        <span>Maximum 100 Characters</span>

        {validateForm() && 
          <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addInviteUser(e, newInviteUser);setLoading(true)}}>
            <b>{newInviteUser.id?'Update':'Invite'}</b>
          </div>
        }
        
      </div>
    </form> }
    <ReactTooltip place="bottom" />
  </div>
</div>
</Fragment>, document.body))
};

export default InvitedUserModal;