import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Contact from '../../components/UserContacts/Contact'
import UserContactService from "../../components/UserContacts/UserContactService"
import ConfirmMenu from '../Modals/ConfirmMenu'
import ContactTypeMenu from "./ContactTypeMenu"
import {Link} from "react-router-dom"
import useModal from '../../hooks/useModal'
import useStyle from '../../hooks/useStyle'
import {NavIcon,CardMenuInfo,MenuItem} from '../../components/Common/MenuItem'
import ProjectMenu from "../../components/Modals/ProjectMenu"
import Cs from "../../services/CommonService"
import CommentsModal from "../Modals/CommentsModal"
import ChecklistsModal from "../Modals/ChecklistsModal"
import Pagination from "../Pagination"
import SideMenuBar from "../../components/Common/SideMenuBar"

let requestParams = {}
let selectedMember = {}
let dataSource = {}
let membersType = {}

const ContactList = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)

  const { state:currentUser, dispatch } = useContext(AuthContext)

  const [members, setMembers] = useState([])
  const [currentpage, setCurrentpage] = useState(1)

  const { isOpen, toggleModal: toggleDeleteModal } = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()

  useEffect(() => {  
    requestParams = {
      'page': currentpage,
      'per_page': 30,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'data_source_params': {"data_source_id":[3400]}
    //  'accelerator_id':currentUser.user.accelerator.id
    }

    getMembersList()
  }, [currentUser.user, props.searchString, currentpage])

  let getMembersList = () => { 
    UserContactService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.data_source_list
        requestParams.totalItems = res.data.meta.total
        membersType = Cs.listToObj(dataSource.edu_stakeholder_type.options , 'value')
        setMembers(res.data.user_contacts)
      }
    })
  }

  const addDelete = (e, member) => {
    selectedMember = member
    toggleMenuModal(e)
    toggleDeleteModal()
  }

  const deleteMember = (e) => {
    e.preventDefault()
    UserContactService.delete(selectedMember.id).then((res) => {
      if (res.status == 204) {
        setMembers(members.filter(r => r.id !== selectedMember.id))
      }
    })
    toggleDeleteModal(e)
  }

  const addMenu = (e, member) => {
    selectedMember = member
    toggleMenuModal(e)
  }

  const addChecklist = (e, member) => {
    selectedMember = member
    toggleChecklistModal(e)
  }

  const addNotes = (e, member) => {
    selectedMember = member
    toggleCommentModal(e)
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => addDelete(e, item)}/>
        <MenuItem id="edit_icon" 
          link={`/add/contact/${item.form_type_id}/${item.form_id}/${item.id}`}
          icon="far fa-edit"
          action="Edit"/>
        <MenuItem id="track_icon" 
          link={`/list/relationship/tracking/${item.id}`}
          icon="fa fa-retweet"
          action="Track Relationship"/>
      </Fragment>
    )
  }

  const Menu = ({ item}) => (
    <i data-tip="Open Tile Menu" id="journey_menu" 
      onClick={(e) => addMenu(e, item)}
      className="fas fa-bars font-18"/>
  )
    
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                User Lists
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="add_new_member" dataTip="Add New Member" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={toggleFilterModal} icon="fas fa-plus"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              { members && members.map((member, i) => (
                <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
                  <Contact member={member} membersType={membersType} menu={Menu} 
                    toggleModal={toggleDeleteModal} addNotes={addNotes}  addMenu={addMenu}
                    addChecklist={addChecklist}/>
                  
                  {selectedMember.id == member.id && (
                    <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
                      item={selectedMember} addDelete={addDelete}
                      type="member" MenuItems={menuItems}
                      menuHeight="275px"/>
                  )}
                </div>
              ))}

              <Pagination totalItems={requestParams.totalItems}
                currentpage={currentpage} setCurrentpage={setCurrentpage}
                itemsPerPage={requestParams.per_page}/>
            </div>
          </div>
        </div>
      </div>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} 
        success={deleteMember}/>
      
      {isFilterOpen && <ContactTypeMenu dataSource={dataSource} 
        params={requestParams} isOpen={isFilterOpen} 
        toggleModal={toggleFilterModal}/>
      }
      
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} 
        toggleModal={toggleChecklistModal} 
        item={selectedMember} type="member"/>
      }
      
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={selectedMember} type="member"/>
      }
      
      <SideMenuBar helpId="2" />
    </Fragment>
  )

}

export default ContactList;