import React, {useState, useEffect, useContext, Fragment} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import UserRoleService from "../../Journey/UserRoles/UserRoleService"
import {Link} from "react-router-dom"
import useModal from '../../../hooks/useModal'
import useStyle from '../../../hooks/useStyle'
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import GenericModal from "../../Modals/GenericModal"
import Cs from "../../../services/CommonService"
import querystringify from "querystringify"
import JourneyUserGroupModal from "../JourneyUserGroup/JourneyUserGroupModal"
import Tabs, { TabPane } from 'rc-tabs'

let requestParams = {}
let selectedUserRole = null

const UsersWithGroupModal = (props) => {
  useStyle('table');  
  useStyle('add_role_dialog');
  
  const { state:currentUser, dispatch } = useContext(AuthContext);
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roleFilter, setRoleFilter] = useState();

  const { isOpen:isGroupOpen, toggleModal:toggleGroupModal } = useModal()

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'journey_category_id':props.journey_category_id,
      'roleable_id': props.roleable_id,
      'roleable_type': props.roleable_type,
      'add_invited_journey_member':true,
      'add_journey_group':true
    }

    getRoleList();
  }, [props.searchString]);

  let getRoleList = () => { 
    setLoading(true)
    UserRoleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setUserRoles(res.data.user_roles) 
        filterByRole(res.data.roles, res.data.user_roles)
        setLoading(false)
      }
    })
  }

  const filterByRole = (roles, users) =>{
    if(props.options.role_sid){
      const role = roles.find((r)=> r.sid == props.options.role_sid)
      const filteredUsers = users.filter((u)=> u?.role_ids?.includes(role.record_id))
      setUserRoles(filteredUsers)
    }
  }

  const addNewRole = (newUserRole) => {
    setLoading(true)
    if(newUserRole.id){
       UserRoleService.update(newUserRole).then((res) => {
        if(res.status==204){
          setLoading(false)
        }
      })   
    }else{
      let req = {
        filter_type:'user',
        email: newUserRole.email,
        user_id: newUserRole.user_registration_id,
        created_by: currentUser.user.id,
        journey_category_id: props.journey_category_id,
        journey_profile_id: props.journey_profile_id,
        roleable_id: props.roleable_id,
        roleable_type: props.roleable_type
      }
        
      UserRoleService.create(req).then((res) => {
        if(res.status==201){
          Object.assign(newUserRole, res.data)
          setLoading(false)
        }
      })  
    }
  }

  const addOrRemoveUser = (newUserRole) =>{
    if(newUserRole.id){
      setLoading(true)
      UserRoleService.delete(newUserRole.id, {'journey_category_id': props.journey_category_id}).then((res) => {
        if(res.status == 204){
          newUserRole.id = null
          setUserRoles([...userRoles])
          setLoading(false)
        }
      })    
    }else{
      addNewRole(newUserRole)
    }
  } 

  const addGroup = (e) =>{
    toggleGroupModal(e)
  }

  const userGroupList = (list) => list.map((n, k) =>
    <span className='pill bg-highlight white m-r-5 p-5' key={k}>
      {n.name}&emsp;
    </span>
  )

  const userList = userRoles.map((n, k) =>
    <tr key={k}>
      <td className="text-center">{k}</td>
      <td className="text-center">{n.email}</td>
      <td className="text-center">
        <input type="checkbox" className="ht-wt-30" onClick={e=>addOrRemoveUser(n)}
        defaultChecked={n.id}/>
      </td>
      { props.options.role_sid === 'student' ? <td className="text-center">
        {userGroupList(n.journey_groups || [])}
      </td>:''}
    </tr>
  )

  return (
    <Fragment>
      {loading && <div className="spinner"/>}
      <Tabs defaultActiveKey="1">
        
        <TabPane tab="Users" key="1">
          <div className="">
            <table className="shadow-small">
              <thead>
                <tr className="white bg-black">
                  <th className="text-center">Id 
                  </th>
                  <th className="text-center">User Email ID 
                  </th>
                  <th className="text-center">Invite to Course
                  </th>
                  { props.options.role_sid === 'student' ? <th className="text-center">Group
                </th>:''}
                </tr>
              </thead>
              <tbody>
                {userList}
              </tbody>
            </table>
          </div>
        </TabPane>

        {props.options.showGroup &&
          <TabPane tab="Group" key="2">
            <JourneyUserGroupModal journeyCategoryId={props.journey_category_id} 
              journeyProfileId={props.journey_profile_id}
              userList={userRoles} setUserList={setUserRoles}
              groupableId={props.roleable_id} groupableType={props.roleable_type}
              currentUser={currentUser.user} hasEditUserGroupPermission={props.hasEditUserGroupPermission}/>
          </TabPane>
        }  
      </Tabs>
    </Fragment>
  )

};

export default UsersWithGroupModal;