import env from '../../../../env';
import axios from 'axios';
const url = `${env.family_tree_backend}colleges`;

class CollegeService {
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  search(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}/search`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  report(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}/report`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  buildCollegeSearchParam(data, params){
    params.fields = 'id,school.name,school.city,school.state,2015.student.size,school.ownership,school.locale,school.degrees_awarded.predominant,2015.cost.avg_net_price.overall,2015.completion.rate_suppressed.overall,2013.earnings.10_yrs_after_entry.median,2013.earnings.6_yrs_after_entry.percent_greater_than_25000,school.under_investigation,2015.admissions.sat_scores.average.overall,2015.admissions.act_scores.midpoint.cumulative,2015.cost.tuition.in_state,2015.cost.tuition.out_of_state'
    params.assoc_or_bachelors = true;
    params.bachelors = true;
    params.include_favourites_count = true;
    params.highest_range = "2..4";
    params.predominant_range = "1..3";

    if(data.degree == 'a'){
      delete params['bachelors'];
      params.assoc=true;
    } else {
      delete params['assoc'];
      params.bachelors=true;
    }
   
    if(data.subject_array){
      //removePreviousMajor($scope.param);
      var p = data.subject_array
      params.subject_name=p;
    }

    if(data.college_size){
      params.size_range = data.college_size_array;
    }

    /*if($scope.college_type){
        $scope.param['school.ownership'] = $scope.college_type;
      }

      if($scope.setting){
        $scope.param['school.locale'] = $scope.setting;
      }
    */
    
    if(data.min_act_score){
      params.min_act_score = parseInt(data.min_act_score);
    }

    if(data.max_act_score){
      params.max_act_score = parseInt(data.max_act_score);
    }

    if(data.min_in_state){
      params.min_in_state = parseInt(data.min_in_state);
    }

    if(data.max_in_state){
      params.max_in_state = parseInt(data.max_in_state);
    }

    if(data.min_out_of_state){
      params.min_out_of_state = parseInt(data.min_out_of_state);
    }

    if(data.max_out_of_state){
      params.max_out_of_state = parseInt(data.max_out_of_state);
    }

    if(data.min_sat_score){
      params.min_sat_score = parseInt(data.min_sat_score);
    }

    if(data.max_sat_score){
      params.max_sat_score = parseInt(data.max_sat_score);
    }

    
    //Replace ' with " for discarding sql error
    if(data.college_do_you_sports!='No'){
      if(data.college_prefe_sports){
        data.college_prefe_sports = data.college_prefe_sports.replace(/'/gi, '"')
        params['sports'] = "'"+data.college_prefe_sports.replace(/,/gi, "','")+"'";
      }

      if(data.college_goal_ncaa_div){
        data.college_goal_ncaa_div = data.college_goal_ncaa_div.replace(/'/gi, '"')
        params['division'] = "'"+data.college_goal_ncaa_div.replace(/,/gi, "','")+"'";
      }
    }else{
      params['sports'] = null;
      params['division'] = null;
    }
   
    params.state = null;
    params.region_id = null;
    params.search = null;
    params.college_name_list = null;
    params.cities = null;
   
    if(data.college_location_preference==2 && data.list_of_states_array){
      params.state = data.list_of_states_array;
      params.cities = data.specific_cities_array;
    }else if(data.college_location_preference==3 && data.region_array){
      params.region_id = data.region_array;
    }else if(data.college_location_preference==1 && data.college_names_array){
      params.college_name_list = data.college_names_array.map(function(item) {
        return item.name;
      })
    }

   if(data.search)params.search = data.search;
   if(data.college_preferred_type)params.ownership = data.college_preferred_type_array;
   if(data.filter_nearby_college)params.filter_nearby_college = true;
    /*
    if(data.college_do_you_sports!='No'){
      if(data.college_prefe_sports_array){
        $scope.param.sports = "'"+data.college_prefe_sports_array;
      }

      if(data.college_goal_ncaa_div_array){
        $scope.param.division = data.college_goal_ncaa_div_array;
      }
    }else{
      $scope.param.sports = null;
      $scope.param.division = null;
    }

    $scope.param.state = null;
    if(data.list_of_states_array){
      $scope.param.state = data.list_of_states_array;
    }*/

  }

}

export default new CollegeService();