import React, {useState, Fragment, useEffect, useContext, useRef} from "react"
import ReactTooltip from 'react-tooltip'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import DigitalContentTable from '../../../components/DigitalContent/List/DigitalContentTable'
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import CommentsModal from "../../Modals/CommentsModal"
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import NotesModal from "../../Modals/NotesModal"
import MenuModal from "../../Modals/Menu"
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle'
import FilterModal from "../List/DigitalContentFilterModal"
import {MenuItem, NavIcon} from '../../Common/MenuItem'
import SideMenuBar from "../../../components/Common/SideMenuBar"
import {SwiperContainer, SwiperSlide} from "../../Common/Swiper"
import CommonService from "../../../services/CommonService"
import {CheckBox} from "../../Common/FormInput"
import FilterSlider from "./../../Common/FilterSlider"
import DigitalContentTableList from "./DigitalContentTableList"
import {ActiveStatusList, setDefaultLabel} from "../../../constants/DataSourceList"
import NotificationPopup from '../../Common/NotificationPopup'
import querystringify from "querystringify";
import DigitalContentForm from '../Form/DigitalContentForm'
import GenericModal from '../../Modals/GenericModal'
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import axios from "axios";

let requestParams = {}
let selectedDigitalContent = {}
let dataSource = {}
let cardViewCount = 1
let formProps = {}

const DigitalContentList = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)

  const indexEle = useRef(null)

  let queryParam = querystringify.parse(props.location.search)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [digitalContentList, setDigitalContentList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [formFieldsList, setFormFieldsList] = useState({})
  const [pagemeta, setPagemeta] = useState([])
  const [subjectId, setSubjectId] = useState()
  const [webPageId, setWebPageId] = useState()
  const [isActive, setIsActive] = useState()
  const [filter, setFilter] = useState()
  const [showDigitalContentTable, setShowDigitalContentTable] = useState(false)
  const [loading, setLoading] = useState(false)

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen:isFormFilterOpen, toggleModal:toggleFormFilterModal } = useModal()
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isFormOpen, toggleModal:toggleFormModal} = useModal()

  useEffect(() => {
    requestParams = {
      'content_type': subjectId,
      'web_page': webPageId,
      'is_active': isActive,
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'totalItems': 0,
      'search': queryParam.search,
      'data_source_params': {
        'data_source_id':[2214, 2861, 3153, 3191, 3170, 3171, 3372, 3368],
        'order_by': 'order_list',
      },
      'form_field_params': {
        'ids':[1030, 945, 951, 976, 195, 980, 982, 981, 998, 999, 986, 985, 1019, 281, 1023]
      }
    }

    getDigitalList()

    return ()=>{
      formProps = {}
      requestParams = {}
      selectedDigitalContent = {}
      dataSource = {}
    } 
  }, [queryParam.search, currentUser?.id, subjectId, webPageId, isActive])

 /* useEffect(()=>{
    try {
      const qp = {
        content_type: 'faq',
        page: 1,
        paginate: 1,
        per_page: 100,
        sort_column: 'updated_at',
        sort_direction: 'asc',
        totalItems: 0,
        is_active: true,
        data_source_filter: [{"key":"web_page.label","operator":"IN","value":"Daily Cue"}]
      }
      axios.get('https://life.admin.cuetree.com/digital_contents', {params: qp});
    } catch (error) {
      console.error(error);
    }
  }, [])*/

  let paginate = (page) =>{
    requestParams.page = page
    getDigitalList(true)
    setCurrentpage(page)
  }

  let getDigitalList = (is_paginate) => {
    setLoading(true)
    requestParams.content_type = subjectId 
    DigitalContentService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.data_source_list
        setDefaultLabel(null, dataSource.web_pages.options)

        //setPagemeta(res.data.meta)
        requestParams.totalItems = res.data.meta.total
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil( requestParams.totalItems / cardViewCount)
        setFormFieldsList(res.data.form_fields || {})
        if(requestParams.search || isActive || subjectId || webPageId || requestParams.page == 1 || is_paginate){
          setDigitalContentList(res.data.digital_contents)
        }else{
          setDigitalContentList([...digitalContentList ,...res.data.digital_contents])
        }
        setLoading(false)
      }
    })
  }

  const deleteJourney = (e) => {
    e.preventDefault();
    DigitalContentService.delete(selectedDigitalContent.id).then((res) => {
      if (res.status == 204) {
        setDigitalContentList(digitalContentList.filter(i => i.id !== selectedDigitalContent.id))
      }
    })
    toggleDeleteModal(e)
  }

  const addDelete = (e, item) => {
    selectedDigitalContent = item
    toggleDeleteModal(e)
  }

  const addMenu = (e, item) => {
    selectedDigitalContent = item
    toggleMenuModal(e)
  }

  const addNotes = (e, item) => {
    selectedDigitalContent = item
    toggleCommentModal(e)
  }

  const updateStatus = (e, item) =>{
    e.preventDefault()
    item.is_active = !item.is_active 
    DigitalContentService.update(item).then((res) => {
      if(res.status==204){
        setDigitalContentList([...digitalContentList])
      }
    })
  }

  const openFormModal = (type, id) =>{
    formProps = {
      match:{params:{
        id: id,
        formId: type?.child_form,
        type: type?.sid
      }},
      isPopupMode:true,
      onSuccess:getDigitalList
    }

    toggleFormModal()
  }

  const toggle = (value) => {
    if(value && value != filter){
      setFilter(value)
      setSubjectId(value)
    }else{
      setFilter()
      setSubjectId()
    }  
  }

  const exist = (value) =>{
    return value === filter 
  }

  const toggleWebpage = (value) => {
    if(value && value != webPageId){
      setWebPageId(value)
    }else{
      setWebPageId()
    }
  }

  const webPageExists = (value) => requestParams.web_page == value;

  const toggleActive = (value) => {
    if(value && value != isActive){
      setIsActive(value)
    }else{
      setIsActive()
    }
  }

  const activeExist = (value) => requestParams.is_active == value;

  const toggleDigitalContentTable = () => setShowDigitalContentTable(!showDigitalContentTable)

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <MenuItem id="edit_icon" 
          onSelect={e => openFormModal(null, item.id)}
          icon="far fa-edit"
          action="Edit"/>

        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => {addDelete(e,item);toggleMenuModal(e);}}/>

        <MenuItem id="preview_icon" 
          link={`/content_details/${item.id}?`}
          icon="far fa-eye"
          action="Preview"/>

        <MenuItem id="chat_icon" 
          icon="fa fa-notes-medical"
          action="Comments"
          onSelect={(e) => addNotes(e,item)}/>

        <a href="/" onClick={e=>updateStatus(e, item)}>
          <i className={item.is_active ? "far fa-check-circle": "far fa-circle"}></i>
          <span className="font-13">Pub Active</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ ja, fn }) => (
    <div className="pull-right m-r-20">
      <i onClick={e=>{addMenu(e, ja)}} className="fas fa-bars font-18" />     
    </div>
  )

  const Details = ({ ja, fn }) => (
    <div className="pull-left m-l-20">
      <Link data-tip="Details" to={`/digital_contents/details/${ja.id}`}>
        <i className="fa fa-info-circle font-18"/>
      </Link>
    </div>
  )

  const TrainingSubjectFilter = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Types of Content
        </h5>
        
        <span className="checkbox-container" onClick={e => toggle()}>
          <span>All</span>
          <input type="checkbox" checked={filter === undefined }/>
          <span className="checkbox-checkmark"></span>
        </span>

        <CheckBox list={dataSource.menu_digital_content.options || []} labelKey="label" valueKey="sid" 
          toggle={toggle} exists={exist}/> 

        <h5 className="font-16 text-center bg-highlight white">
          Webpage
        </h5>

        <CheckBox list={dataSource.web_pages.options || []} labelKey="label" valueKey="value" 
          toggle={toggleWebpage} exists={webPageExists}/> 

        <h5 className="font-16 text-center bg-highlight white">
          Active Status
        </h5>

        <CheckBox list={ActiveStatusList} labelKey="label" valueKey="value" 
          toggle={toggleActive} exists={activeExist}/>  
      </div>
    </div>
  )

  const TrainingSubjectFilters = () => {
    if(Object.keys(dataSource).length != 0){
      let content_type = dataSource.menu_digital_content.options.length > 0 && dataSource.menu_digital_content.options.find(o => o.sid === subjectId)
      let web_page = dataSource.web_pages.options.length > 0 && dataSource.web_pages.options.find(o => o.value === webPageId)
      let is_active = ActiveStatusList.length > 0 && ActiveStatusList.find(o => o.value === isActive)    
      return(
        <div className="pull-right m-r-20">
          {content_type ? <span className="pill bg-highlight white">{content_type.label}</span>: null }
          {web_page ? <span className="pill bg-highlight white">{web_page.label}</span>: null }
          {is_active ? <span className="pill bg-highlight white">{is_active.label}</span>: null }
        </div>
      )
    }else{
      return null
    }
   }
     
  const newsListCard =  digitalContentList && digitalContentList.map((ja, i) => (
    // <SwiperSlide key={i}>
      <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
        <DigitalContentTable digitalContent={ja}  menu={Menu} addMenu={addMenu} details={Details}
        toggleModal={toggleDeleteModal} addNotes={addNotes} Datasource={dataSource} 
        index={i} formFieldsList={formFieldsList}/>
        {selectedDigitalContent.id == ja.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={selectedDigitalContent} addDelete={addDelete} type="journey" 
          MenuItems={menuItems} />
        }
      </div>
    // </SwiperSlide>
  ))

  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                Digital Content
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="toggle_digital_content_table" dataTip="Toggle Digital Content Table" 
                onSelect={toggleDigitalContentTable}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fa fa-table"/>

              <NavIcon id="filter_items" dataTip="Filter Items" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={toggleFilterModal} icon="fas fa-sliders-h"/>

              {currentUser.current_admin.role_content &&
                <NavIcon id="create_items" dataTip="Create New Item" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={toggleFormFilterModal} icon="fas fa-plus"/>
              }
              <TrainingSubjectFilters />
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
                {!showDigitalContentTable && !isFormOpen &&
                  <Fragment>
                    {/* <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
                      requestParams={requestParams} indexEle={indexEle} cardViewCount={cardViewCount}> */}
                      {newsListCard}
                    {/* </SwiperContainer>
                  
                    <div className="col-md-12 col-sm-12 text-center">
                      <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
                    </div> */}
                    
                  </Fragment>
                }

                {showDigitalContentTable && <Fragment>
                  <DigitalContentTableList digitalContentList={digitalContentList} requestParams={requestParams}
                    selectedDigitalContent={selectedDigitalContent} updateStatus={updateStatus}
                    getDigitalList={getDigitalList} addDelete={addDelete} />
                  </Fragment>
                }

                <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
                    setCurrentpage={paginate} itemsPerPage={requestParams.per_page}/>
            </div>
          </div>
        </div>
      </div>
      
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
      
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedDigitalContent} type="digital_content"/>
      }
      
      {isFormFilterOpen && 
        <FilterModal isOpen={isFormFilterOpen} toggleModal={toggleFormFilterModal} 
        menuList={dataSource} onSelect={openFormModal}/>
      }

      {isFilterOpen && 
        <FilterSlider component1={TrainingSubjectFilter} openSideNav={isFilterOpen}
        setOpenSideNav={toggleFilterModal}/>
      }

      {isFormOpen && 
        <GenericModal component={DigitalContentForm} {...formProps} 
          isOpen={isFormOpen} toggleModal={toggleFormModal} widthCss="w-80p"/>
      }
      <SideMenuBar helpId="2" />
    </Fragment>
  )

}

export default DigitalContentList;