import React, {useState, Fragment, useEffect,useContext,useRef} from "react";
import {Link} from "react-router-dom";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Course from '../List/Course';
import CourseService from "../CourseService";
import useStyle from '../../../../../hooks/useStyle';
import PreLoadSpinner from "../../../../PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../Common/SideMenuBar";
import {NavIcon} from '../../../../Common/MenuItem'
import ReactTooltip from "react-tooltip";
import TrainingModuleService from "../../TrainingModules/TrainingModuleService"

let requestParams = {}
let journey_category = null;

const CourseList = (props) => {
  useStyle('card');
  useStyle('card_table');
  useStyle('add_role_dialog');
  useStyle("course");

  let params = props.match.params;
  let project = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
 
  let cardGridCss = "col-xs-12 col-sm-6 col-md-4";

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1); 

  useEffect(() => {
    ReactTooltip.rebuild()
    journey_category = null;
    requestParams = {
      'filter':'student_curriculum',
      'journey_category_id':params.journey_category_id,
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'last_modified_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'id':queryParam.id
    }

    getCourseList();
    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage]);

  let getCourseList = () => { 
    setLoading(true)
    CourseService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       setCourseList(res.data.course_details)
       setLoading(false)       
     }
    })
  }

  let getModules = (course_id, callback) => {
    let req = {
      'journey_profile_id':params.journey_profile_id,
      'journey_category_id':params.journey_category_id,
      'page':1,
      'per_page':100,
      'sort_column':'mf.sort_order',
      'sort_direction':'asc',
      'exists_in_planner':true,
      'topic_exists_in_planner':true,
      'filter':'student_curriculum',
      'course_id':course_id,
    }

    TrainingModuleService.getAll(req).then((res) => {
      if(res.status == 200){
        callback(res.data.modules)
      }
    })
  }

  const routeCurricullum = (course) =>{
    props.history.push(`/career_training/modules/${params.journey_category_id}/${params.journey_profile_id}/${course.id}`,project)
  }

  const routeDetails = (c) =>{
    props.history.push({pathname:`/career_training/course_page/${c.id}`,getModules})
  }                                                      

  const courseCard = courseList && courseList.map((c, i) => (
    <div className={cardGridCss}>
      <Course course={c} currentUser={currentUser} routeActivity={routeCurricullum} routeDetails={routeDetails} getModules={getModules} index={i}/>
    </div>
  ))
  
  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <Fragment>
      <div className="content top-10">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Courses"} project={project} />
        </div>
        
        <div className="col-md-8 float-right">  
          <NavIcon id="home_icon" dataTip="Back to Journey Home" 
            link={`/career_training/project/portal/${params.journey_category_id}`}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-home"/>
        </div>
        <div className="clear"></div>
      </div>

      <div className="page-content row">
        {courseCard}
      </div>
      
      <SideMenuBar helpId="8"/>
    </Fragment>
  )

};

export default CourseList;