import React, {useState, Fragment, useEffect, useContext} from "react"
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from '../../services/CommonService'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import NewsletterEmailService from "./NewsletterEmailService"
import JourneyTitle from "../Journey/JourneyTitle/JourneyTitle"
import {NavIcon, CardMenuInfo, MenuItem, CardMainMenu} from '../Common/MenuItem'
import Pagination from '../Pagination'
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { EditGrayIcon, LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons";

let requestParams = {}
let selectedEmail = null

const EmailList = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [emails, setEmails] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 
  const [loading, setLoading] = useState(true) 
  const [pagemeta, setPagemeta] = useState([])

  const { isOpen, toggleModal:toggleDeleteModal } = useModal()

  let params = props.match.params

  useEffect(() => {
    requestParams = {
      'page':currentpage,
      'per_page':20,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
    }

    getEmails()

    return () => {
     requestParams = {} 
    }
  }, [props.searchString, currentpage])

  let getEmails = () => { 
    setLoading(true)
    NewsletterEmailService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        setEmails(res.data.newsletter_emails)
        setLoading(false)
      }
    })
  }
  
  const {sort} = useSort(requestParams, getEmails)

  const update = (item, field) =>{
    NewsletterEmailService.update(item).then((res) => {
      if(res.status == 204){
        setEmails([...emails])
      }
    })
  }

  const onDelete = (e, email) => {
    selectedEmail = email
    toggleDeleteModal(e)
  }

  const deleteEmail = (e) =>{
    e.preventDefault()
    NewsletterEmailService.delete(selectedEmail.id).then((res) => {
      if (res.status == 204) {
        setEmails(emails.filter((i) => i.id !== selectedEmail.id))
      }
    })  
    toggleDeleteModal(e)
  }

  const tableData = emails.map((q, k) =>{
    return(
      <TR key={k}>
        <TD data-title="Id">{q.record_id}</TD>
        <TD>
          {q.email}
        </TD>  
        <TD>
          {Cs.formatUpdateDate(q.updated_at, 'MMM DD, YYYY hh:mm a')}
        </TD>
        <TD>
          {q.status}
        </TD>
        <TD>
          <div className="flex aligncenter">
            <EditGrayIcon id="edit" data-tip="Edit" link={`/newsletter/unsubscribe/${q.id}`}>
            </EditGrayIcon>
            <LinkDeleteIcon id="delete" data-tip="Delete" onSelect={(e)=>onDelete(e, q)}>
            </LinkDeleteIcon>
          </div>
        </TD>
      </TR>
    )
  })

  if(loading){
    return(<div className="spinner"/>)
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <JourneyTitle title="Newsletter Emails"/>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="home_icon" dataTip="Back to Newsletter Emails" 
                onSelect={e=>props.history.goBack()}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-home"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              <Table className="shadow-small">
                <THead>
                  <TR>
                    <TH onClick={e=>sort('record_id')}>Id 
                      <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
                    </TH>
                    <TH onClick={e=>sort('email')} className="miw-100 maw-120">Email
                      <SortDirection sort_column={requestParams.sort_column} column="email" reverse={requestParams.reverse}/>
                    </TH>
                    <TH onClick={e=>sort('updated_at')} className="miw-100">Date 
                      <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
                    </TH>
                    <TH onClick={e=>sort('status')} className="">Status 
                      <SortDirection sort_column={requestParams.sort_column} column="status" reverse={requestParams.reverse}/>
                    </TH>
                    <TH className="">Action</TH>
                  </TR>
                </THead>
                <TBody>
                  {tableData}
                </TBody>
              </Table>

              <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
                setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
            </div>
          </div>
        </div>
      </div>
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteEmail}/>
    </Fragment>
  )

}

export default EmailList;