import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Question from '../../Questions/List/Question'
import QuestionService from "../../Questions/QuestionService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import CommentsModal from "../../Modals/CommentsModal"
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link, useHistory} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import NotesModal from "../../Modals/NotesModal"
import MenuModal from "../../Modals/Menu"
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle'
import env from '../../../env'
import QuestionListTable from './QuestionListTable'
//import FilterModal from "../../../components/Organizations/List/OrganizationsFilterModal";

let requestParams = {}
let selectedQuestion = {}
let dataSource = {}
let roleCreateNews = false

const QuestionList = (props) => {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [questionsList, setQuestionsList] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [showTableView, setShowTableView] = useState(false)

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal, success:commentSuccess } = useModal()
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()

  let history = useHistory()
  
  useEffect(() => {
    requestParams = {
      'data_source_params': {
        'data_source_id': [],
      },
      'filter_favourites': false,
      'is_active': true,
      'is_user_suggested': false,
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': '',
      'sort_direction': 'desc',
      'search': props.searchString,
      'partnership_id': currentUser.domain_info.partnership_org_id
    }

    if(env.admin_emails.includes(currentUser.email) || currentUser.current_admin.role_content){
      roleCreateNews = true
      delete requestParams.is_active
    }

    if(history.location.pathname == '/admin/questions')setShowTableView(true);

    getQuestionsList()
  }, [props.searchString, currentUser.id, currentpage])

  let getQuestionsList = () => { 
    QuestionService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       dataSource = res.data.data_source_list 
       requestParams.totalItems = res.data.meta.total
       setQuestionsList(res.data.interview_questions)
      }
    })
  }

  const addDelete = (e, qu) => {
    selectedQuestion = qu
    toggleDeleteModal(e)
  }

  const addMenu = (e, qu) => {
    selectedQuestion = qu
    toggleMenuModal(e)
  }

  const addNotes = (e, qu) => {
    selectedQuestion = qu
    toggleCommentModal(e)
  }

  const updatePrivacy = (e, item) =>{
    e.preventDefault()
    if(['shared', 'private'].includes(item.privacy_type)){
      item.privacy_type = 'public'
      item.data.share_selection = 1
    }else if(item.privacy_type == 'public'){
      item.privacy_type = 'private'
      item.data.share_selection = 2
    }
    QuestionService.update(item).then((res) => {
      if(res.status==204){
        setQuestionsList([...questionsList])
      }
    })
  }

  const deleteQuestions = (e) => {
    e.preventDefault()
    QuestionService.delete(selectedQuestion.id).then((res) => {
      if (res.status == 204) {
        setQuestionsList(questionsList.filter(i => i.id !== selectedQuestion.id))
      }
    })
    toggleDeleteModal(e)
  }

  const cloneQuestion = (e, question) =>{
    e.preventDefault()
    const cloneReq = new Object(question)
    cloneReq.id = null
    QuestionService.create(cloneReq).then((res) => {
      if(res.status==201){
        setQuestionsList([res.data.interview_question, ...questionsList]) 
      }
    })
  }

  const routeForm = (e, id) =>{
    e.preventDefault()
    history.push('/questions/create/'+FormTemplateId.QuestionBankForm+'/'+(id?id:''))
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        {roleCreateNews &&
          <a href="#" onClick={e=>routeForm(e, item.id)}>
            <i className="fa fa-edit"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }
        <Link to="/" onClick={e=>addNotes(e, item)}>
          <i className="far fa-comment"></i>
          <span className="font-13">Comments</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  const Menu = ({qu, fn}) => (
    <i onClick={e=>addMenu(e, qu)} className="fas fa-bars" />     
  )

  const Details = ({ qu, fn }) => (
    <div className="dropup card-menu-left"></div>
  )
     
  const questionsListCard =  questionsList && questionsList.map((qu, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <Question questionsList={qu} menu={Menu} dataSource={dataSource}
        details={Details} addNotes={addNotes} updatePrivacy={updatePrivacy}
        toggleModal={toggleDeleteModal} />
        
        {selectedQuestion.id == qu.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={selectedQuestion} addDelete={addDelete} type="journey" 
          MenuItems={menuItems} menuHeight="250px"
          roleCreateNews={roleCreateNews}/>
        }
    </div>
  ))
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">Interview Question List</div>
            </div>

            <div className="flex aligncenter">
              {roleCreateNews &&
                <a href="#" className="bg-highlight round-btn float-right m-r-5 m-t-4"
                  onClick={e=>routeForm(e)}>  
                  <i className="fas fa-plus"></i>
                </a>
              }
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row p-20">
              {showTableView &&
                  <QuestionListTable setEdit={routeForm} setDelete={addDelete} menu={Menu} 
                  requestParams={requestParams} questionsList={questionsList} 
                  getQuestionsList={getQuestionsList}
                  updatePrivacy={updatePrivacy} cloneQuestion={cloneQuestion}/>
              }

              <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
              setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
            </div>
          </div>
        </div>
      </div>
      <ConfirmMenu
        isOpen={isOpen}
        toggleModal={toggleDeleteModal}
        success={deleteQuestions}/>
      
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedQuestion} type="cue_news"/>
      }
    </Fragment>
  )

}

export default QuestionList;