import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import DynamicForm from '../../FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import NewsletterEmailService from "../NewsletterEmailService"
import FormContainer from '../../FormBuilder/FormContainer'

let formMode = null 
let Newsletter = {} 
let data = {} 
let formFn = {}  

function UnSubscribeForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let currentUser = authState.user
  
  /*TODO: Move API to Router to load data before view*/
  
  useEffect(() => { 
    formMode = 'edit-form-submissions'
    data = {}
    Newsletter = {} 
    Newsletter.id = params.id
    formFn = {'form_type':'newsletter_email', 'editPermission':true}
    
    setFormRendered(true)
  }, [])

  const create = (Newsletter) => {
   
  }

  const update = (NewsLetter) =>{
    NewsletterEmailService.update(NewsLetter).then((res) => {
      if(res.status == 204){
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = (id) => {
    props.history.push("/newsletter/emails")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
         <DynamicForm formMode={formMode} formId={FormTemplateId.UnsubscribeNewsLetter} 
          form={Newsletter} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost} />
      </FormContainer>
    )  
  }else{
    return null
  }
  
}

export default UnSubscribeForm;