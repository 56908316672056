import env from '../../../../env';
import axios from 'axios';
const url = `${env.family_tree_backend}student_profiles`;
const edu_activity_stats_url = `${env.family_tree_backend}education_activity_stats`;

class StudentProfileService {
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getPublicProfiles(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}public/student_profiles`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getEduActivityStats(req) {
    return new Promise((resolve, reject) => {
      axios.get(edu_activity_stats_url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  updateEduActivityStats(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${edu_activity_stats_url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getEduActivityStatsById(req){
    return new Promise((resolve, reject) => {
      axios.get(`${edu_activity_stats_url}/${req.id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
}

export default new StudentProfileService();