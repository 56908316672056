import React, {useState} from "react";
import SignInAndSignUpBtn from "./List/SignInAndSignUpBtn"
import GenericModal from "../Modals/GenericModal"
import useModal from "../../hooks/useModal";
import env from "../../env" 

let Button = ({item, eleType}) => {
  let {data, data_source} = item

  const { isOpen:isAuthModalOpen, toggleModal:toggleAuthModal } = useModal()

  const routeUrl = () =>{
    if(data.in_app_url){
      localStorage.setItem("ct_life_last_url", data_source?.in_app_url?.url);
      toggleAuthModal()
    }else if(data.public_blog_page){
      window.open(`${window.location.origin}/blog${data_source.public_blog_page.url}`, '_blank');
    }else if(data.public_url){
      window.open(data.public_url, '_blank');
    }
  }  
  
  return(
    <>
      {eleType == 'button'?
        <button onClick={e=>routeUrl()} className="btn mt-2"
          style={{
            color: data.button_text_color,
            backgroundColor: data.button_color,
          }}>
          {data.button_text}
        </button>
      :
        <div onClick={e=>routeUrl()} className="btn btn-primary bg-orange white m-b-5 bobd-500">{data.button_text || 'Learn More'}</div>
      }
      {isAuthModalOpen && <GenericModal component={SignInAndSignUpBtn} title="CueTree" toggleModal={toggleAuthModal}/>}
    </>
  )  
}

export {Button};