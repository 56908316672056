import React, { useState, Fragment, useEffect } from 'react'

let requestParams = null

const AddUser = ({ userObj, isOpen, toggleModal, addUser, dataSources, currentUser }) => {
  userObj.role_manager_permissions = userObj.role_manager_permissions || {}
  
  const [user, setUser] = useState(userObj)
  let permissions = dataSources.Manager_List.options
  let userPermissions = ''
  const ds = currentUser.domain_info.data_source
  const typeOfUse = ds.type_of_use.label

  if(typeOfUse == 'Personal Use'){
    userPermissions = ds.fj_your_role.permissions
  }else if(typeOfUse == 'Organizational Use'){
    userPermissions = ds.type_organizational_use.permissions
  }

  const Permission = ({g}) =>(
    <Fragment>
      <span className="checkbox-container" onClick={e => setAccess(g.sid)}>
        <span>{g.label}</span>
        <input type="checkbox" checked={user.role_manager_permissions[g.sid]} readOnly/>
        <span className="checkbox-checkmark"></span>
      </span>
    </Fragment>
  )

  const PermissionList = () => permissions.map((g, k) =>{
    try{
      if(g.read_only == 'Yes'){
        return null
      }else if(true || userPermissions.includes(g.sid)){
        return(<Permission g={g} key={k}/>)
      }
    }catch(e){
      console.log(e)
    }  

    return null 
  })

  const setAccess = (access_type) => {
    let obj = user.role_manager_permissions
    if (obj[access_type]) {
      delete obj[access_type]
    }else {
      obj[access_type] = true; 
    }
    setUser({...user})
    checkError()
  }

  const setTerms = () => {
    user.is_terms_and_policy_agreed = !user.is_terms_and_policy_agreed
    setUser({...user})
    checkError()
  }

  const checkError = () =>{
    if(user.email && user.is_terms_and_policy_agreed && Object.keys(user.role_manager_permissions).every((k) => !user.role_manager_permissions[k]) !== true){
      return true
    }
    return false
  }

  return(
    <Fragment>
      <form className="ml-container" onSubmit={e=>addUser(e, user)}>
        <div className="ml-section">
          <label className="pull-right"><span className="m-r-5 lred">*</span><b>Required field</b></label>
          <label><b>NOTICE</b></label>
          <p className="font-14 m-b-10 lh-24">After you review and agree to this notice, you will be able to add a user and give specific permissions for the user to &nbsp;create and manage cueTree education journeys under your subscription account. </p>
          
          <p className="font-14 m-b-10 lh-24">You can modify the permissions for the user at any time and you can also delete the user from your user permissions list.</p>

          <p className="font-14 m-b-10 lh-24">If your cueTree subscription expires or remains unpaid, cueTree may notify all users that you have given permission to that their permissions may expire after a reasonable period of time. During that time they will be given the opportunity to pay the cueTree subscription fee required to maintain the cueTree education journeys and cueTree content to which they received access through your subscription.<br/></p>
    
          <label className="m-t-5"><b>Email</b><span className="m-l-5 lred">*</span></label>
          <input onChange={e=>{user.email = e.target.value}} 
            className="ml-input ml-border ml-margin-bottom form-group"
            type="text" placeholder="Email..." name="email"  
            defaultValue={user.email} required/>
          
          <label><b>Permissions</b><span className="m-l-5 lred">*</span></label>
          <PermissionList/>

          <label><b>Read and Agreed</b><span className="m-l-5 lred">*</span></label>
            <span className="checkbox-container" onClick={e=>setTerms()}>
              <span>I acknowledge that I am at least 13 years of age and agree with the cueTree Terms of Service and Privacy Policy.</span>
              <input name="terms" type="checkbox" checked={user.is_terms_and_policy_agreed} readOnly required/>
              <span className="checkbox-checkmark"></span>
            </span>

          { checkError() && <button className="ml-button ml-block ml-green ml-section ml-padding" type="submit">
              <b>{user.id?'Update':'Add'}</b>
            </button>
          }

        </div>
      </form>
    </Fragment>
  )

}

export default AddUser;