import React, { useState, Fragment} from 'react'
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import CommonService from '../../services/CommonService'

const InviteModal = ({toggleModal}) => {
  useStyle('user_lists')
  useStyle('add_role_dialog')
  
  const [inviteUser, setInviteUser] = useState({})
  const [loading, setLoading] = useState(false)
  const [alertMsg, setAlertMsg] = useState()

  const invitationPlaceholder = `Hi, I am using cueTree, a new collaboration and guided instruction application for students and educators. Check it out and connect with me.`

  const onInputChange = (e) =>{
    setInviteUser({...inviteUser, [e.target.name]:e.target.value})
  }

  const addInviteUser = (e) =>{
    setLoading(true)
    CommonService.inviteUser(inviteUser).then((res) => {
      if(res.status === 201){
        setLoading(false)
        setAlertMsg('Your invitation will be sent soon.')
        //toggleModal(e)
      }else{
        setLoading(false)
        setAlertMsg('Error, while sending invitation.')
      }
    })
  }

  const checkValid = () =>{
    if(inviteUser.email && inviteUser.message){
      return true
    }
    return false
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Invite Others</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
        
        {loading ? 
          <div className="spinner"/> :
          <form className="ml-container">
            <div className="ml-section">

              {alertMsg ?
                <div className="success-txt">
                  <p>{alertMsg}</p>
                </div>
                :
                <>
                  <label><b>Email</b></label>
                  <input onChange={e=>onInputChange(e)} className="form-control m-t-5" 
                    type="text" placeholder="email 1, email 2, email 3..." 
                    name="email" required />
                  <div className="ml-margin-bottom lgrey">
                    If adding multiple email IDs, add a comma after each email address
                  </div>

                  <label><b>Description</b></label>
                  <textarea onChange={e=>onInputChange(e)} name="message" 
                    maxLength="200" rows="3" className="form-control m-t-5" 
                    defaultValue={invitationPlaceholder} required>
                  </textarea>
                  <div className="ml-margin-bottom lgrey">Maximum 200 Characters</div>

                  <label><b>Note</b></label>
                  <div className="lgrey m-t-5">
                    When you submit this invitation, an email will be sent to the email addresses provided here.
                  </div>

                  {checkValid() &&
                    <div onClick={e=>addInviteUser(e)} className="ml-button ml-block ml-section bg-highlight white" >
                      <b>Invite</b>
                    </div>
                  }
                </>
              }  
            </div>
          </form>
        }
    
      </div>
    </div>
  </Fragment>, document.body))
};

export default InviteModal;