import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DynamicForm from '../../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../../constants/FormTemplateList'
import PlannerService from "./PlannerService"
import querystringify from "querystringify"
import FormContainer from '../../../FormBuilder/FormContainer'
import FormWizard from '../../../../components/FormBuilder/FormWizard'
import {CareerPosition} from '../../../Common/CareerPosition'

let formMode = null 
let activity = {} 
let data = {} 
let formFn = {}  

function PlannerForm(props) {
  const { state:{ user:currentUser } } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const get = () => {
    PlannerService.get(params.id).then((res) => {
      if(res.status==200){
        activity = res.data.planner_list || {}
        data = activity.data || {}
        setFormRendered(true)
      }
    }).catch((res)=>{
       activity = null
    })
  }
  
  useEffect(() => { 
    activity = {} 
    data = {} 
    formFn = {
      'form_type':'career_journey.planner', 
    } 
    
    formMode = 'create-form-submissions'
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (activity) => {
    activity.planner_form_id = params.form_id
    activity.planner_type = params.type
    PlannerService.create(activity).then((res) => {
      if(res.status == 201){
        onSubmitPageRedirect()
      }else if(res.status == 422){
        alert(res.data.error)
      }
    }).catch((res)=>{
       
    })
  }

  const update = (activity) => {
    PlannerService.update(activity).then((res) => {
      if(res.status == 204){
        onSubmitPageRedirect()
      }
    }).catch((res)=>{
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/career/admin/planners`)
  }

  if(queryParam.form_wizard_id || queryParam.form_wizard_schedule_id){
    return(<FormWizard form_wizard_id={queryParam.form_wizard_id} form={activity} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onSubmitPageRedirect}
      form_wizard_schedule_id={queryParam.form_wizard_schedule_id}/>)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={params.form_id} 
          form={activity} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
        <CareerPosition data={data} formFn={formFn} />
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default PlannerForm;