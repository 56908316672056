import React, { useEffect, useState, useCallback, Fragment, useContext } from 'react'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import {OptionsObjList} from '../../../Common/FormInput'
import JourneyTitle from "../../JourneyTitle/JourneyTitle"
import {NavIcon} from '../../../Common/MenuItem'
import CheckAccess from "../../Roles/CheckAccess"
import ReactFlow, { 
  ReactFlowProvider, addEdge, isNode, MiniMap, Controls, useNodesState, useEdgesState,
} from 'react-flow-renderer'
import dagre from 'dagre'
import useStyle from "../../../../hooks/useStyle"
import {
  initialElements, initialEdges, setElements, setCollegeNodes, gradeElement, 
  setGradeActivities, setSuggestedActivities, resetElement
} from './initialElements'
import CollegeAdmissionCueMeService from "../GradePath/CollegeAdmissionCueMeService"
import {AddActivity, AddDataSource, AddSuggestedActivity, AddCollege} from './Nodes'
import useModal from "../../../../hooks/useModal"
import ActivityTypeMenu from "../Activities/List/ActivityTypeMenu"
import ActivityService from "../Activities/ActivityService"
import './layouting.css'
import querystringify from "querystringify"
import Sidebar from './Sidebar'
import {PillStringList} from "../../UserRoles/NotificationPill"
import ActivityList from "../Activities/List/ActivityList"
import GenericModal from "../../../Modals/GenericModal"
import CollegeList from "../Colleges/SearchList/CollegeList"
import {Menu} from "../HighSchoolPortalPage/PortalHeader"
import Suggestions from "../HighSchoolPortalPage/Suggestions"
import CollegeTableList from "../Colleges/SearchList/CollegeTableList"
import ReactTooltip from "react-tooltip"
import CollegeFavouritesList from "../Colleges/Favourites/FavouritesList"
import JourneyFilesLinksModal from "../../JourneyFilesLinks/JourneyFilesLinksModal"
import FilterSlider from "./../../../Common/FilterSlider"
import SideMenuBar from "../../../Common/SideMenuBar"
import {HighSchoolLink} from "../../../Common/RouteLinks"
import FilterModal from '../../../Modals/FilterModal'
import SuggestionsModal from '../HighSchoolPortalPage/SuggestionsModal'
import Cs from '../../../../services/CommonService'
// In order to keep this example simple the node width and height are hardcoded.
// In a real world app you would use the correct width and height values of
// const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height

const nodeWidth = 172;
const nodeHeight = 50;

let reactFlowInstance = null;
const nodeTypes = {
  AddActivity,
  AddDataSource,
  AddSuggestedActivity,
  AddCollege
}

const getLayoutedElements = (elements, direction = 'TB') => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const isHorizontal = direction === 'TB'
  dagreGraph.setGraph({ rankdir: direction })

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight })
    } else {
      dagreGraph.setEdge(el.source, el.target)
    }
  })

  dagre.layout(dagreGraph)

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id)
      el.targetPosition = isHorizontal ? 'left' : 'top'
      el.sourcePosition = isHorizontal ? 'right' : 'bottom'

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  })
}

let requestParams = {}
let dataSource = {}
let favouriteColleges = []
let collegeUserViews = {}
let journeyCategory = {}
let eduProfile = null
let selectedActivity = null
let selectedCollege = null
let eduProfileDataSource = null
const LayoutFlow = (props) => {
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  useStyle('/css/tile-components.css', true)
  useStyle('/css/grade-stage.css', true)

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search)

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)

  const [journeyData, setJourneyData] = useState({})
  const [elements, setElements] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const [nodes, setNodes, onNodesChange] = useNodesState(initialElements);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const { isOpen: isActivityOpen, toggleModal: toggleActivityModal} = useModal()
  const { isOpen: isCollegeListOpen, toggleModal: toggleCollegeList} = useModal()
  const { isOpen: isCollegeOpen, toggleModal: toggleCollege} = useModal()
  const { isOpen: isSuggestionOpen, toggleModal: toggleSuggestionModal } = useModal()
  const { isOpen: isActivityTypeOpen, toggleModal: toggleActivityTypeModal } = useModal()
  const { isOpen: isFileLinkOpen, toggleModal: toggleFileLinkModal } = useModal()
  const { isOpen: isPlannerModal, toggleModal: togglePlannerModal } = useModal(true)

  let collegeSearchProps = {
    match:{
      params:{
        journey_category_id: params.journey_category_id,
        journey_profile_id: params.journey_profile_id,
      }
    },
    location:journeyCategory
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'page': 1,
      'per_page': 10,
      'journey_category_id': params.journey_category_id,
      'journey_profile_id': params.journey_profile_id,
      //'grade': queryParam.current_grade,
      'carrier_interests': journeyCategory.is_demo_template?null : queryParam.carrier_interests,
      'fields':['journal_entry_count'],
      'data_source_params': {
        'data_source_id':[3332, 2861, 2230, 2214, 3410],
        //Individual journey data_source
      }
    }
    collegeAdmissionCueMe()

    return () => {
      resetElement()
      requestParams = {}
      dataSource = {}
      favouriteColleges = []
      collegeUserViews = {}
      journeyCategory = {}
      eduProfile = null
      selectedActivity = null
      eduProfileDataSource = null
    }
  }, [])

  let collegeAdmissionCueMe = () => { 
    setIsLoading(false)
    CollegeAdmissionCueMeService.get(requestParams).then((res)=>{
      if(res.status == 200){
        res = res.data
        journeyCategory = res.journey_category
        eduProfile = journeyCategory.education_journey_profiles
        eduProfileDataSource = journeyCategory.education_journey_profiles.data_source
        eduProfile.subject_interest = eduProfile.data.goals?.subject_interest?.multi_goals?.split(',')
        eduProfile.career_interest = eduProfile.data.goals?.career_interest?.single_goal?.split(',')

        if(res.data_source_list){
          dataSource = res.data_source_list
          dataSource.path_nodes_high_school_journey = res.high_school_path_nodes
          dataSource.activityColor = dataSource.fj_edu_entry_type.options.reduce((obj, item) => (obj[item.child_form] = item.header_color, obj) ,{})
          dataSource.activityIcon = dataSource.fj_edu_entry_type.options.reduce((obj, item) => (obj[item.child_form] = item.icon, obj) ,{})
          gradeElement(filterGradeDs(), queryParam.current_grade, onGradeClick, toggleSuggestionModal)
        }
        setGradeActivities(res.college_admission_cue_mes, dataSource.activityColor, dataSource.activityIcon, openActivityModal, activityPaginate)
        if(queryParam.current_grade == requestParams.grade)setSuggestedActivities(res.activities || [], dataSource.activityColor, queryParam.current_grade, cloneActivity,dataSource.activityIcon)
        favouriteColleges = res.favourites || []
        collegeUserViews = res.college_user_view || {}
        //setElements(getLayoutedElements(initialElements))
        setNodes(initialElements)
        setEdges(initialEdges)
        if(!requestParams.grade){
          setCollegeNodes(favouriteColleges, initialElements, null, openCollegeModal, collegeUserViews)
          //setElements(initialElements)
          setNodes(initialElements)
          setEdges(initialEdges)
        }else{
          //setElements(initialElements)
          setNodes(initialElements)
          setEdges(initialEdges)
        }
        setJourneyData(res.college_admission_cue_mes)
        setSuggestions(res.suggestions)
        CheckAccess.userAccess(currentUser, journeyCategory, res.invited_journey_member);
        setIsLoading(true)
      }
    })
  }

  let activityPaginate = (page, grade, onSuccess) => { 
    let req = Object.assign({}, requestParams)
    req.page = page
    req.grade = grade
    delete req.data_source_params

    CollegeAdmissionCueMeService.get(req).then((res)=>{
      if(res.status == 200){
        onSuccess(res.data.college_admission_cue_mes['journal_entry_grade_'+grade])
      }
    })
  }

  const onLoad = (react_flow_instance) => {
    reactFlowInstance = react_flow_instance;
    console.log(reactFlowInstance)
    /*elements.map((el) => {
      if (el.id == queryParam.current_grade) {
        // it's important that you create a new object here
        // in order to notify react flow about the change
        console.log(el)
        const x = el.position.x + nodeWidth / 2;
        const y = el.position.y + nodeHeight / 2;
        const zoom = 1;
        reactFlowInstance.setTransform({x, y, zoom})
      }
      
      return el;
    })*/
  }

  let getActivityByFilter = (target) => { 
    delete requestParams.data_source_params
    requestParams[target.name] = target.value == 'all'?null:target.value
    if(requestParams.grade == 'Colleges'){
      resetElement()
      gradeElement(filterGradeDs(requestParams.grade), queryParam.current_grade, onGradeClick)
      setCollegeNodes(favouriteColleges, null, null, openCollegeModal, collegeUserViews)
      //setElements(getLayoutedElements(initialElements))
      //collegeAdmissionCueMe()
      setNodes(initialElements)
      setEdges(initialEdges)
    }else if(requestParams.grade){
      resetElement()
      gradeElement(filterGradeDs(requestParams.grade), queryParam.current_grade, onGradeClick)
      collegeAdmissionCueMe()
    }else{
      resetElement()
      gradeElement(dataSource.path_nodes_high_school_journey.options, queryParam.current_grade, onGradeClick)
      collegeAdmissionCueMe()
    }
  }

  /*let activityPaginate = (element_id, grade) =>{
    console.log(element_id)
    requestParams.grade = grade
    const filteredElements = initialElements.filter(function(e){
      if(e.id != element_id)return e;
    })
    console.log(filteredElements)
    setElements(filteredElements)
    function callback(res){
      setGradeActivities(res.college_admission_cue_mes, dataSource.activityColor, dataSource.activityIcon, openActivityModal)
    }
    const res = collegeAdmissionCueMe(callback)*/
    
    //setElements(filteredElements)
    //getLayoutedElements(initialElements)
    /*gradeElement(filterGradeDs(requestParams.grade), queryParam.current_grade, onGradeClick)
    collegeAdmissionCueMe()*/   
 /* }*/

  const filterGradeDs = (grade) =>{
    if(grade){
      return dataSource.path_nodes_high_school_journey.options.filter((n)=> grade == n.value)
    }

    return dataSource.path_nodes_high_school_journey.options
  }

  /*const setColleges = (favourites) =>{
    favourites.forEach((item, index)=>{
      favouriteColleges.push({
        title: item.label,
        cardTitle: item.label,
        cardSubtitle:`ACT - ${item.act_scores} \n SAT - ${item.sat_scores} \n STUDENT SIZE - ${item.student_size}`,  
      })  
    })
  }*/

  const onGradeClick = (ds) =>{
    if(ds.label == 'Colleges'){
      toggleCollegeList()
      //props.history.push(`/highschool/college/profile/${params.journey_category_id}/${params.journey_profile_id}`)
    }else{
      toggleActivityTypeModal()  
    }
  }

  const openActivityModal = (activity) =>{
    selectedActivity = activity
    toggleActivityModal()
  }

  const openCollegeModal = (college) =>{
    selectedCollege = college
    toggleCollege()
  }

  const cloneActivity = (activity) =>{
    let req = {
      id: activity.id,
      journey_category_id: params.journey_category_id,
      education_journey_profile_id: params.journey_profile_id
    }
    ActivityService.clone(req).then((res)=>{
      collegeAdmissionCueMe()
    })
  }

  const openFileLinks = (e) =>{
    e.preventDefault()
    toggleFileLinkModal(e)
  }

  const onConnect = (params) =>
    setElements((els) =>
      addEdge({ ...params, type: 'smoothstep', animated: true }, els)
    )
  
  /*
  //migra
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els))*/

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction)
      setElements(layoutedElements)
    },
    [elements]
  )

  const addFavCollegeNode = (c) =>{
    setCollegeNodes([{
      college_id: c.id,
      journey_category_id: params.journey_category_id,
      journey_profile_id: params.journey_profile_id,
      label: c.name,
      openCollegeModal
    }])
    //setElements(getLayoutedElements(initialElements))
    setNodes(initialElements)
    setEdges(initialEdges)
  }

  const PlannerMenu = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Planner
        </h5>
      </div>
    </div>
  )

  const FilterCareer =()=>(
    <Fragment>
      <div className="row bg-black white font-16 p-10">
        Filter By
      </div>
      <div className="row p-12 br-btm-grey">
        <div className="col-xs-12 col-sm-4 m-5-0">
          <select onChange={e => getActivityByFilter(e.target)} name="grade" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" defaultValue={requestParams.grade}>
            <option value="all">By stage</option>
            <OptionsObjList list={dataSource.path_nodes_high_school_journey.options} label_key="label" id_key="value"/>
          </select>
        </div>
        <div className="col-xs-12 col-sm-4 m-5-0">
          <select onChange={e => getActivityByFilter(e.target)} name="activity_form_id" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs">
            <option value="all">By activity type</option>
            <OptionsObjList list={dataSource.fj_edu_entry_type.options} 
            label_key="label" id_key="child_form"/>
          </select>
        </div>
        <div className="col-xs-12 col-sm-4 m-5-0">
          <select onChange={e => getActivityByFilter(e.target)} name="subject_search" className="form-control form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs" defaultValue={requestParams.subject_search}>
            <option value="all">By status</option>
            <OptionsObjList list={dataSource.edu_competition_subject.options} 
            label_key="label" id_key="label"/>
          </select>
        </div>
      </div>
    </Fragment>
  )

  const FilterCareerBar =()=>(
    <Fragment>
      <p className="m-t-10 m-b-10">Filter By</p>
        <div id="filters" className="flex aligncenter coldir-xs">
          <select onChange={e => getActivityByFilter(e.target)} name="grade" 
            className="form-select-dropdown m-b-10-xs form-select-w-100-xs bg-white" 
            defaultValue={requestParams.grade}>
            <option value="all">By stage</option>
            <OptionsObjList list={dataSource.path_nodes_high_school_journey.options} label_key="label" id_key="value"/>
          </select>
          <select onChange={e => getActivityByFilter(e.target)} name="activity_form_id" 
            className="form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs bg-white" 
            defaultValue={requestParams.activity_form_id}>
            <option value="all">By activity type</option>
            <OptionsObjList list={dataSource.fj_edu_entry_type.options} 
            label_key="label" id_key="child_form"/>
          </select>
          <select onChange={e => getActivityByFilter(e.target)} name="subject_search" 
            className="form-select-dropdown m-l-20 m-l-20-xs m-b-10-xs form-select-w-100-xs bg-white" 
            defaultValue={requestParams.subject_search}>
            <option value="all">By status</option>
            <OptionsObjList list={dataSource.edu_competition_subject.options} 
            label_key="label" id_key="label"/>
          </select>
        </div>
    </Fragment>
  )

  if(!isLoading)return null;

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20">
            <div className="flex coldir fulwid p20">
              <div className="flex coldir-xs justspacebetween">
                <div className="m-b-10-xs">
                  <JourneyTitle title={`Activities`} project={journeyCategory} />
                  <div className="m-t-5">
                    <PillStringList list={eduProfile.subject_interest} 
                    style="badge2 mid m-r-5"/>
                    <PillStringList list={eduProfile.career_interest} 
                    style="badge2 mid m-r-5"/>
                  </div>
                </div>
                <div className="flex aligncenter">
                  <NavIcon id="journey_list" dataTip="Back to Journey Home" 
                    link="/journey"
                    className="bg-highlight round-btn br-10 float-right m-r-15 m-t-4" 
                    icon="fas fa-home"/>
                  
                  <NavIcon id="go_back" dataTip="Back to Portal Page" 
                    link={HighSchoolLink.hs_portal(params)}
                    className="bg-highlight round-btn br-10 float-right m-r-15 m-t-4" 
                    icon="fas fa-arrow-left"/>
                    
                  <Menu project={journeyCategory} setLoading={setIsLoading} 
                    history={props.history} app={props.app}
                    menuCss="bg-highlight round-btn br-10 float-right m-r-15 m-t-4"
                    iconCss="white"/>

                  <NavIcon id="collections" dataTip="Journey Files and Links" 
                    className="bg-highlight round-btn br-10 float-right m-r-15 m-t-4" 
                    onSelect={openFileLinks} icon="fas fa-link"/>
                  
                  <NavIcon id="schedules" dataTip="Schedules" 
                    className="bg-highlight round-btn br-10 float-right m-r-15 m-t-4" 
                    link={HighSchoolLink.hs_calendar(params, journeyCategory)}
                    icon="fas fa-calendar-alt"/>
                      
                  <NavIcon id="suggestions" dataTip="Planners Modal" 
                    className="bg-highlight round-btn br-10 float-right m-r-15 m-t-4" 
                    onSelect={togglePlannerModal} icon="fas fa-plus"/>
                </div>
              </div>
              {!screen.xs && <FilterCareerBar/>}
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs">
            <div className="row m-t-30 m-t-0-xs">
              <div className="fulwid">
                <div className="layoutflow p-10 bg-white border-rounded-10" style={{position:"sticky"}}>
                  <ReactFlowProvider>
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      nodeTypes={nodeTypes}
                      onInit={onLoad}
                    />
                    <Controls/>
                  </ReactFlowProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" onClick={()=>Cs.scrollTo()}
        className="btn-primary text-white f18 f16-xs fw700 pos-fix b-50 r-16 bg-grey">
          <i className="fas fa-arrow-up m-r-5"/>Top
        </button>
      </div>

      {isFileLinkOpen && 
        <JourneyFilesLinksModal toggleModal={toggleFileLinkModal} 
        journeyCategory={journeyCategory}/>
      }

      {isActivityOpen && 
        <GenericModal component={ActivityList} isPopupView={true}
        widthCss="w-80p" toggleModal={toggleActivityModal} {...props} 
        id={selectedActivity.id}/>
      }
    
      {isActivityTypeOpen && 
        <ActivityTypeMenu journey_category={journeyCategory} 
        journey_category_id={params.journey_category_id} 
        education_journey_profile_id={params.journey_profile_id} 
        isOpen={isActivityTypeOpen} toggleModal={toggleActivityTypeModal} 
        goBackAfterSubmit="true"/>
      }

      {isCollegeOpen && 
        <GenericModal title="College" component={CollegeFavouritesList} 
        journey_category_id={params.journey_category_id} 
        widthCss="w-80p" education_journey_profile_id={params.journey_profile_id} 
        isOpen={isCollegeOpen} isPopupView={true} 
        toggleModal={toggleCollege} journey={journeyCategory} 
        id={selectedCollege.college_id} />
      }

      {isCollegeListOpen &&  
        <GenericModal title="Add College" 
        component={CollegeTableList} addFavCollegeNode={addFavCollegeNode} 
        widthCss="w-80p" {...collegeSearchProps} toggleModal={toggleCollegeList} 
        isPopupView={true} />
      }

      {isSuggestionOpen  && (
        <GenericModal title="Check out these suggestions" component={Suggestions} 
          toggleModal={toggleSuggestionModal} 
          journeyProfiles={journeyCategory.education_journey_profiles} 
          journey={journeyCategory}
          history={props.history}
          suggestions={suggestions} dataSource={dataSource} currentUser={currentUser} 
          cueTipType="edu_path"/>
      )}

      {isPlannerModal  && (
        <FilterModal widthCss="w-50p" 
          title="Click the cuetree suggestions to enrich your educational journey" 
          component1={SuggestionsModal} 
          component2={screen.xs && FilterCareer} openSideNav={isPlannerModal} 
          setOpenSideNav={togglePlannerModal} history={props.history}
          journey={journeyCategory} journeyProfiles={journeyCategory.education_journey_profiles}
          suggestions={suggestions} dataSource={dataSource} 
          currentUser={currentUser} cueTipType="edu_path"/>
      )}
      
      <ReactTooltip place="bottom" />
      <SideMenuBar helpId="2"/>
    </Fragment>
  )
}

export default LayoutFlow;