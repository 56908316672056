import React, {useState, Fragment, useEffect,useContext,useRef} from "react";
import {Link} from "react-router-dom";
import querystringify from "querystringify";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import Planner from './Planner';
import PlannerService from "./PlannerService";
import FavouritesService from "../../../../services/FavouritesService";
import Pagination from '../../../Pagination'
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../Modals/ProjectMenu";
import FormTemplateId from '../../../../constants/FormTemplateList';
import CommentsModal from "../../../Modals/CommentsModal";
import useModal from "../../../../hooks/useModal";
import NotesModal from "../../../Modals/NotesModal";
import MenuModal from "../../../Modals/Menu";
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import useStyle from '../../../../hooks/useStyle';
import CheckAccess from '../../Roles/CheckAccess';
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import NotificationPopup from "../../../../components/Common/NotificationPopup";
import HelpMessageList from '../../../../constants/HelpMessageList'
import {MenuItem, CardMenuInfo, NavIcon, CardMainMenu} from '../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../Common/Swiper"
import CommonService from "../../../../services/CommonService";
import ReactTooltip from "react-tooltip";
import ActivityTypeMenu from "../Activities/List/ActivityTypeMenu"
import FilterSlider from "./../../../Common/FilterSlider"
import {ActiveStatusList, setDefaultLabel} from "../../../../constants/DataSourceList"
import {CheckBox} from "../../../Common/FormInput"

let requestParams = {}
let selectedPlanner = {}
let dataSource = {}
let cardViewCount = 1

const PlannerList = (props) => {
  useStyle('/css/tile-components.css', true)
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/responsive.css', true)
  
  let params = props.match.params;
  let journey = props.location.state;
  let queryParam = querystringify.parse(props.location.search);

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [plannerList, setPlannerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1); 
  const [pagemeta, setPagemeta] = useState();
  const [formFieldsList, setFormFieldsList] = useState({});
  const [plannerType, setPlannerType] = useState()

  const indexEle = useRef(null);

  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const { isOpen:isPlannerTypeOpen, toggleModal:togglePlannerTypeModal } = useModal()
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();

  let cardGridCss = "col-xs-12";
  let cardHeightCss = "ht-400";
  if (queryParam.id || props.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-7";
    cardHeightCss = "mih-380";
    queryParam.id = queryParam.id || props.id;
  }

  useEffect(() => {
    requestParams = {
      'data': {},
      'data_source_params': {
        'data_source_id':[3344, 3438],
      },
      'form_field_params': {
        'ids':[1026, 1027, 1028, 1029]
      },
      'page':currentpage,
      'paginate':1,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'planner_form_id':plannerType,
      'id':queryParam.id
    }

    /*if(journey){
      const jp = journey.education_journey_profiles
      const {data, data_source} = jp
      
      const future_careers = []
      CommonService.mapToList(data.future_careers_array, 'value', future_careers)
      CommonService.mapToList(data.future_careers_2_array, 'value', future_careers)

      requestParams.filter = {
        future_occup_groups: [data_source?.future_occup_groups?.value, data_source?.future_occup_groups_2?.value],
        future_careers: future_careers
      }
    }*/

    getPlannerList()
    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage, plannerType]);

  let getPlannerList = () => { 
    setLoading(true)
    PlannerService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.data_source_list
        setDefaultLabel(dataSource.career_j_activity_cat.options)
        requestParams.totalItems = res.data.meta.total
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
        setFormFieldsList(res.data.form_fields || {})
        if(requestParams.search){
          setPlannerList(res.data.planner_lists)
        }else if(queryParam.id){
          cardViewCount = 1
          requestParams.totalPages += 1 /*Add +1 for comment section on swiper*/
          setPlannerList(res.data.planner_lists)
        }else{
          setPlannerList([...plannerList, ...res.data.planner_lists])  
        }
        setLoading(false)
        res.data.planner_lists.length == 0 && toggleHelpMessage()
      }
    })
  }

  const pinFavourite = (e) =>{
    e.preventDefault()
    //setIsFavourite(!isFavourite)
  }

  const addChecklist = (e, a) => {
    selectedPlanner = a;
    toggleChecklistModal(e);
  }

  const addNotes = (e, a) => {
    selectedPlanner = a;
    toggleCommentModal(e);
  }

  const deletePlanner = (e) => {
    e.preventDefault();
    PlannerService.delete(selectedPlanner.id).then((res)=>{
      if(res.status==204){
        setPlannerList(plannerList.filter(i => i.id !== selectedPlanner.id))
      }
    })
    toggleDeleteModal(e);
  }

  const showMenu = (e, a) => {
    selectedPlanner = a;
    toggleMenuModal(e);
  }

  const routePlanner = (planner) =>{
    props.history.push(`/career/planners?id=${planner.id}`)
    setPlannerList(plannerList.filter(i => i.id == planner.id))
  }

  const menuItems = ({item}) =>{
    const title = item.planner_type && item.planner_type.replace(/\s/g, '_')

    return(
      <Fragment>
        <CardMenuInfo formId={item.planner_form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.created_user.name}/>

        <MenuItem id="edit_icon" 
          link={`/career/planner/form/${item.planner_type}/${item.planner_form_id}/${item.id}`}
          icon="far fa-edit" action="Edit"/>
        
        <MenuItem id="delete_icon"  icon="far fa-trash-alt" action="Delete"
          onSelect={(e) => toggleDeleteModal(e)}/>
      </Fragment>
    )
  }

  const Menu = ({planner}) => (
    <CardMainMenu activity= {planner} showMenu={showMenu} />
  )

  const toggle = (value) => {
    setPlannerType(value)
  }

  const exist = (value) =>{
    return value == plannerType 
  }

  const PlannerFilter = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Types of Content
        </h5>
        
        <CheckBox list={dataSource.career_j_activity_cat.options || []} labelKey="label" valueKey="Planner_form" toggle={toggle} exists={exist}/> 
      </div>
    </div>
  )

  const plannerCard = plannerList && plannerList.map((a, i) => (
    // <SwiperSlide key={i}>
      <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
        <Planner addNotes={addNotes} addChecklist={addChecklist} planner={a}  
        dataSource={dataSource} menu={Menu} addMenu={showMenu} cardHeightCss={cardHeightCss} 
        routePlanner={routePlanner} index={i} popUpView={props.isPopupView}
        formFieldsList={formFieldsList}/>
        {selectedPlanner.id == a.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={selectedPlanner} type="career_journey.planner" MenuItems={menuItems} 
          menuHeight="300px"/>
        }
      </div>
    // </SwiperSlide>
  ))
  
  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }

  if(queryParam.id && !loading && plannerList.length == 0){
    return(<NotificationPopup iconClass="fa fa-times-circle lred font-24" header="Not Found" message="Sorry, this message was deleted by the author"/>)
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                <JourneyTitle title={"Planner Activities"} />
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="add_new_activity" dataTip="Create New Planner" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={e=>togglePlannerTypeModal(e)} icon="fas fa-plus"/>

              <NavIcon id="filter_items" dataTip="Filter Items" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                onSelect={toggleFilterModal} icon="fas fa-sliders-h"/>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              {!queryParam.id && 
              /* {<SwiperContainer currentpage={currentpage} 
                setCurrentpage={setCurrentpage} requestParams={requestParams} 
                indexEle={indexEle} cardViewCount={cardViewCount}>} */
                  plannerCard
                /*{ </SwiperContainer> 
              }
              
              {!queryParam.id && <div className="col-md-12 col-sm-12 text-center">
                  <span ref={indexEle}>1</span>/{requestParams.totalPages} Pages
                </div> */}
            </div>
          </div>
        </div>
      </div>
      
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedPlanner} type="career_journey.planner"/>}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedPlanner} type="career_journey.planner"/>}
      {isPlannerTypeOpen && <ActivityTypeMenu menuType="planner" params={dataSource} isOpen={isPlannerTypeOpen} toggleModal={togglePlannerTypeModal} />}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deletePlanner}/>
      <SideMenuBar helpId="8"/>
      {isHelpMessageOpen && 
        <NotificationPopup header="Note" message={HelpMessageList.new_activity} 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} toggleModal={toggleHelpMessage}/>
      }
      {isFilterOpen && 
        <FilterSlider component1={PlannerFilter} openSideNav={isFilterOpen}
        setOpenSideNav={toggleFilterModal}/>
      }
    </Fragment>
  )

}

export default PlannerList;