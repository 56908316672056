import React, {useEffect} from 'react'
import env from "../../env"
import CommonService from "../../services/CommonService"
import importScript from '../../hooks/importScript'

const LottieLight = (props) => {

	useEffect(() => {
		importScript('https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.5/lottie_light.min.js', init)  	
	}, [])

	function init (){
	  getLottieData()  
	}

	const getLottieData = () =>{
		let req = {
      		method: "get",
      		url: `${env.file_url}${props.file_url}`,
    	}

    	if(props.isPublic)
    		req.url = props.file_url;
    	    req.withCredentials = false;

		CommonService.useAxios(req).then((res)=>{
		  try{	
			window.lottie.loadAnimation({
      			container: document.getElementById(props.id),
      			renderer: 'svg',
      			loop: props.loop || true,
      			autoplay: props.autoplay || true,
      			speed : props.speed || 1,
      			//path: env.file_url+props.file_url,
      			animationData:res.data
    		})	
		  }catch(e){
		  	
		  }
		})
	}
	
	let style={'width':`${(props.width || 250)}px`, 'height':`${(props.height || 250)}px`}

	return(
	   <div id={props.id} style={style} className="lottie"></div>
	)
}

export default LottieLight;