import React, { useState, Fragment} from 'react';
import ReactDOM from 'react-dom';

const AddEvent = ({ newEvent, isOpen, toggleModal, addNewEvent}) => {

  const [event, setEvent] = useState(newEvent);

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom">
      
      <div className="flex justspacebetween modal-title">
        <h4 className="f18 text-white fw500 p12-10-15">{event.id?'Update':'Add'} Event</h4>
        <button className="btn-close"
          title="Close Modal" onClick={e=>toggleModal(false)}>×
        </button>
      </div>
    
      <form className="ml-container">
        <div className="ml-section">

        <label><b>Event Name</b></label>
        <input onChange={e=>{event.data.title = e.target.value}} className="ml-input ml-border ml-margin-bottom"
          type="text" placeholder="Event name" name="role_name" required defaultValue={event.data.title}/>
        
        <label><b>Description</b></label>
        <textarea onChange={e=>{event.data.description = e.target.value}}
          className="ml-input ml-border ml-margin-bottom" placeholder="Description" 
          defaultValue={event.data.description} required>
        </textarea>

        <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addNewEvent(e, event)}}>
          <b>{event.id?'Update':'Create'}</b>
        </div>
        
        </div>
      </form>
    
    </div>
  </div>
  </Fragment>, document.body))
};

export default AddEvent;