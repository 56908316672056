import React, {Fragment} from "react";
import {Link, useParams} from "react-router-dom";
import useModal from '../../../../../hooks/useModal';
import CommonService from '../../../../../services/CommonService';
import ProjectMenu from "../../../../Modals/ProjectMenu";
import {MenuItem} from '../../../../Common/MenuItem'

export const StudentProfile = (props) => {
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  const params = useParams()

  let {studentProfile, onDelete, dataSource:ds} = props;
  let data = studentProfile.data || {};
  studentProfile.updated_at = CommonService.formatUpdateDate(studentProfile.updated_at);
  
  const cardStyle = {
    borderTop: '5px solid #607D8B'
  }

  const MenuItems = () =>{
    return(
      <Fragment>
        <MenuItem id="edit_icon" 
          link={`/highschool/student/profile/form/${studentProfile.journey_category_id}/${studentProfile.education_journey_profile_id}/${studentProfile.id}`}
          icon="far fa-edit"
          action="Edit" />
        <MenuItem id="delete_icon" 
          icon="far fa-trash-alt"
          action="Delete"
          onSelect={(e) => {onDelete(studentProfile, e);toggleMenuModal(e)}}/>
      </Fragment>
    )
  }

  const accomplishments = data.notable_accomplishments_array && data.notable_accomplishments_array.map((a, i) => (
    <a href="#" key={i}>{ds.hs_notable_accomplishments.options[a].label}</a>
  ))
  
  return (
    <Fragment>
      <div className="content-boxed shadow-small bg-rose" style={cardStyle}>
        
        <div className="content">
          <h2 className="vcard-title color-highlight bold">
            <a href="#">{data.name}</a>
          </h2>

          <div className="vcard">
            <div className="pull-right m-t-n24">{studentProfile.updated_at}</div>
          </div>
        </div>

        <div className="content scroll-y" style={{height: '320px'}}>
          
          <div className="vcard-field">
            <strong className="light-green">Subjects</strong>
            <a href="#">{data.major}</a>
          </div>

          <div className="vcard-field">
            <strong className="light-green">College</strong>
            <a href="#">{data.college}</a>
          </div>

          <div className="vcard-field">
            <strong className="light-green">Accomplishments</strong>
            {accomplishments}
          </div>

          <div className="vcard-field clearfix">
            <div className="col-xs-4">
              <strong className="light-green">SAT English</strong>
              <div>{data.sat_english && ds.standardized_test_score_800.options[data.sat_english].label}</div>
            </div>
            <div className="col-xs-4">
              <strong className="light-green">SAT Math II</strong>
              <div>{data.sat_math_ii && ds.standardized_test_score_800.options[data.sat_math_ii].label}</div>
            </div>
            <div className="col-xs-4">
              <strong className="light-green">SAT Science</strong>
              <div>{data.sat_science && data.sat_science && ds.standardized_test_score_800.options[data.sat_science].label}</div>
            </div>
          </div>

          <div className="vcard-field clearfix">
            <div className="col-xs-4">
              <strong className="light-green">SAT</strong>
              <div>{data.sat_score && ds.standardized_test_score_1600.options[data.sat_score].label}</div>
            </div>
            <div className="col-xs-4">  
              <strong className="light-green">SAT Social Science</strong>
              <div>{data.sat_social_sciences && ds.standardized_test_score_800.options[data.sat_social_sciences].label}
              </div>
            </div>
            <div className="col-xs-4">
              <strong className="light-green">ACT</strong>
              <div>{data.act_score && ds.act_score.options[data.act_score].label}</div>
            </div>
          </div>

          <div className="vcard-field">/
            <strong className="light-green">Activities during HS Summer</strong>
            <div dangerouslySetInnerHTML={{ __html: data.summer_activities}}></div>
          </div>

          <div className="vcard-field">
            <strong className="light-green">Activities during School Year</strong>
            <div dangerouslySetInnerHTML={{ __html: data.school_activities}}></div>
          </div>
        </div>

        <div>
          <span className="m-l-15">{studentProfile.record_id}</span>
          <i onClick={e=>{toggleMenuModal(e)}} className="fas fa-bars pull-right m-r-15" />
        </div>
      </div>

      <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} MenuItems={MenuItems}/>
    </Fragment>
  )

}

export default StudentProfile;