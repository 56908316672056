import React from 'react';
import {CueTooltip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel} from '../FieldLabel'

const InputExplanatoryText = ({field, isFormWizard, openFieldLabelModal, currentLocale}) => {
  
  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  /*const SetError = () => 
    <div className="errormsg">{errors[inputAttributes.name] && errors[inputAttributes.name].message}</div>*/
    
  return (
  	<div className="form-group col-xs-12" {...parentAttributes}>
      <div className="flex coldir m-b-20">
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <div className="br-grey p-5 bg-white" dangerouslySetInnerHTML={{ __html: field.description_text }} />
      </div>
 	  </div> 
  )
}

export default InputExplanatoryText;