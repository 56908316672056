import React, { Fragment, useState, useEffect, useContext } from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import ZoomMeetingService from "./ZoomMeetingService"
import Tabs, { TabPane } from 'rc-tabs'
import {NavIcon, MenuItem, CardMenuInfo} from '../Common/MenuItem'
import JourneyTitle from "../Journey/JourneyTitle/JourneyTitle"
import useStyle from '../../hooks/useStyle'
import Cs from "../../services/CommonService"

const MeetingDetails = (props) => {
   useStyle("user_profile")

   const {state: { screen, user: currentUser }, dispatch, } = useContext(AuthContext)
   const [meetingData, setMeetingData] = useState()

   let params = props.match.params
   let journey_category = props.location.state

   useEffect(() => {
	 getMeetingDetails()     
   }, [])

   let getMeetingDetails = () => {
	  ZoomMeetingService.get_meeting_details(params.meeting_id).then((res) => {
	    if (res.status == 200) {
	        setMeetingData(res.data)
	    }
	  })
  	}

  	const Card = () =>{
  	  let meeting = meetingData.meeting	
	  return(
	  	<div className="col-xs-12">
	    	<div className="profile-card profile-card-style">
          		<div className="content mb-0">
	            	<h3 className="font-600">Basic Info</h3>

		            <div className="form-group">
		              <label>Topic</label>
		              <input
		                defaultValue={meeting.topic}
		                className="form-control"
		                readOnly/>
		            </div>

		            <div className="form-group">
		              <label>Duration(Mins)</label>
		              <input
		                defaultValue={meeting.duration}
		                className="form-control"
		                readOnly/>
		            </div>

		            <div className="form-group">
		              <label>No of Participants</label>
		              <input
		                defaultValue={meeting.participants_count}
		                className="form-control"
		                readOnly/>
		            </div>  

		             <div className="form-group">
		              <label>Start Time</label>
		              <input
		                defaultValue={Cs.formatUpdateDate(meeting.start_time, 'MMM DD, YYYY hh:mm:ss a')}
		                className="form-control"
		                readOnly/>
		            </div>  

		             <div className="form-group">
		              <label>End Time</label>
		              <input
		                defaultValue={Cs.formatUpdateDate(meeting.end_time, 'MMM DD, YYYY hh:mm:ss a')}
		                className="form-control"
		                readOnly/>
		            </div>  
	    		</div>
  		 	</div>
  		</div>
  	  )
  	}
  	
  	return (
	  <Fragment>
	  	<div className="content m-t-15 clearfix">
		  <div className="col-md-4 pull-left">
		  	<JourneyTitle title="Zoom Meeting" project={journey_category} />
		  </div>
		  <div className="col-md-8 pull-right">
			<NavIcon id="home_icon" dataTip="Back to Journey Home" 
				onSelect={props.history.goBack}
				className="bg-highlight round-btn float-right m-r-5 m-t-4" 
				icon="fas fa-home"/>
		  </div>
	        <div className="clear"/>
      	</div>

	    <Tabs defaultActiveKey='1'>
	      <TabPane tab="Details" key="1">
	  		<div className="page-content row">
	  			{meetingData && <Card/>}
	  		</div>
	      </TabPane>

	      <TabPane tab="Participants" key="2">
	  		<div className="page-content row">
	  			
	  		</div>
	      </TabPane>

	      <TabPane tab="Recordings" key="3">
	  		<div className="page-content row">
	  			
	  		</div>
	      </TabPane>
	    </Tabs>
	  </Fragment>
	)
}

export default MeetingDetails;