import React, {useState} from "react";
import AuthenticationService from "../../services/AuthService";

export const AuthContext = React.createContext();

let screen = {
  xs:false,
  sm:false,
  md:false,
  lg:false
}

function getBreakpoint() {
  var w = window.innerWidth;
  screen.width = w;
  screen.height = window.innerHeight;
  (w < 768) ? screen.xs=true : ((w < 992) ? screen.sm=true : ((w < 1200) ? screen.md=true : screen.lg=true));
}
getBreakpoint();
//window.addEventListener("resize", getBreakpoint);

let ua = navigator.userAgent;
let userAgent = null; 
if((ua.indexOf("Opera") || ua.indexOf('OPR')) != -1 ){
  userAgent = 'Opera';
}else if(ua.indexOf("Chrome") != -1 ){
  userAgent = 'Chrome';
}else if(ua.match(/(iPad|iPhone|iPod)/g)){
  userAgent = 'IOS'
}else if(ua.indexOf("Safari") != -1){
  userAgent = 'Safari';
}else if(ua.indexOf("Firefox") != -1 ) {
  userAgent = 'Firefox';
}else if((ua.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
{
  userAgent = 'IE'; 
} 

export const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  showPageLoader:true,
  userAgent: userAgent,
  screen:screen
};