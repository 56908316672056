import React, { Fragment, useState, useEffect, useContext} from "react"
import ReactDOM from "react-dom"
import { Link, useHistory } from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import useStyle from "../../../../../hooks/useStyle"
import CommonService from "../../../../../services/CommonService"
import useEscape from "../../../../../hooks/useEscape"
import ActivityService from "../ActivityService"

let requestParams = {}

const ActivityTypeMenu = ({journey_category, journey_category_id, education_journey_profile_id, params, isOpen, toggleModal, goBackAfterSubmit}) => {
  
  useStyle("add_role_dialog")
  useStyle("filter_dialog")
  useEscape(toggleModal)

  let history = useHistory()  

  const {state: { screen, user: currentUser },dispatch,} = useContext(AuthContext)
  const [activityCategory, setActivityCategory] = useState()
  const [reportOtherUser, setReportOtherUser] = useState({})
  const [reportCurrentUser, setReportCurrentUser] = useState({})

  useEffect(() => {
    requestParams = {
      journey_category_id:journey_category_id,
      data_source_params: {
        data_source_id:[2214],
      }
    }
    getActivityCategory()
  }, [])

  let getActivityCategory = () => {
    ActivityService.getActivityMenu(requestParams).then((res) => {
      let d = CommonService.groupBy(res.activity_category.options, "actions")
      setReportOtherUser(res.report_other_users)
      setReportCurrentUser(res.report_current_user)
      setActivityCategory(d)
    })
  }

  const openForm = (e, type)=>{
    e.preventDefault()
    if(type.pk_id == 28372){
      /*label: "College Watch", value: 30*/
      history.push({
        pathname:'/journey_profile',
        search:`form_id=${type.child_form}&fj_type_edu_jou=30`
      })
    }else{
      const qp = goBackAfterSubmit?'&goBack=1':''
      history.push({
        pathname:`/highschool/activity/form/${journey_category_id}/${education_journey_profile_id}/${type.label}/${type.child_form}`, 
        search:`form_wizard_id=${type.wizard_id}&fj_edu_entry_type=${type.value}${qp}`,
        state:{project:journey_category}
      })
    }
  }

  const List = (key) => activityCategory[key].map((k) => (
    <Fragment key={k.value}>
      <Link className="ver-icons" onClick={e=>openForm(e, k)}>
        <span>
          <i className={k.icon || "fas fa-plus"} style={{ color: k.icon_color }}/>
        </span>
        <div className="popup-menu-title">{k.label}</div>
        <i className="fas fa-chevron-right lgrey pull-right" />
        <span className="badge2 bg-lred pull-right m-r-5">{reportCurrentUser[k.child_form] || 0}</span>
        <span className="badge2 bg-lblue pull-right m-r-5">{reportOtherUser[k.child_form] || 0}</span>
      </Link>
    </Fragment>
  ))

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom" style={{width: '400px'}}>
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select an item</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>

          {activityCategory && (
            <div id="ct" className="p-5 scroll-x" style={{height:screen.height-120}}>
              {currentUser.current_admin.role_admin && 
                <div className="popup-menu-grid br-btm-grey">
                  <div className="head ht-30">
                    <span className="badge2 bg-lred pull-left m-r-5">0</span> 
                    <span className="pull-left">This journey</span>
                    <span className="pull-right">Avg across journeys</span>
                    <span className="badge2 bg-lblue pull-right m-r-5">0</span>
                  </div>
                </div>
              }

              {activityCategory.academic &&
                <div className="popup-menu-grid">
                  <div className="head">Academic</div>
                  {List("academic")}
                </div>
              }
              
              {activityCategory.extracurricular &&  
                <div className="popup-menu-grid">
                  <div className="head">Extracurricular</div>
                  {List("extracurricular")}
                </div>
              }
              
              {activityCategory.special_experience &&  
                <div className="popup-menu-grid">
                  <div className="head">Special experience</div>
                  {List("special_experience")}
                </div>
              }
              
              {activityCategory['4-Year HS Schedule'] &&  
                <div className="popup-menu-grid">
                  <div className="head">4-Year HS Schedule</div>
                  {List("4-Year HS Schedule")}
                </div>
              }
                
              <small className="font-9 lgrey lh-16 p-5">2214</small>
            </div>
          )}
        </div>
      </div>
    </Fragment>, document.body
  )
}

export default ActivityTypeMenu;