import React, { useState } from 'react';
import {CueTooltip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description} from '../FieldLabel'

const InputTags = ({field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  
  let listKey = field.client_id+'_array';
  formData[listKey] = formData[listKey] || [];
  
  let [modelarray, setModelarray] = useState(formData[listKey]);
  let [showError, setShowError] = useState(false);

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'className':'form-input form-input-gray form-input-full'
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  let validation = {
    required: field.required
  }
  
  /* 
   * To add items;
  */
  const add = (event) => {
    if (event.key === 'Enter') {
      if(event.target.value==null)return;
      setModelarray([...modelarray, event.target.value]);
      formData[listKey].push(event.target.value);
      
      /*Storing Result as string*/
      formData[field.client_id] = modelarray.toString();  
      event.target.value = null;
      setError();
    }
  }

  const remove = (e, item) => {
    e.preventDefault();
    var idx = modelarray.indexOf(item);
    if (idx > -1) {
      setModelarray(modelarray.filter(i => i !== item))
      formData[listKey].splice(idx, 1);
      
      /*Storing Result as string*/
      formData[field.client_id] = modelarray.toString();  
      setError();
    } 
  }

  const setError = () => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = modelarray.length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const tags = modelarray.map((t, k) =>  
    <span className="tag" key={k}>
      <span>{t}&nbsp;&nbsp;</span><a href="#" title="Removing tag" onClick={e => remove(e, t)}>x</a>
    </span>
  )

  return (
    <div {...parentAttributes}>
      <div className="flex coldir m-b-20">
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        <div id="tags_input" className="tagsinput" data-tip data-for={`tooltip_${field.client_id}`}>
          <input {...inputAttributes} placeholder="add a tag" onKeyDown={add} />
          {tags}
          <div className="tags_clear"></div>
        </div>  
        <Description description={field.description}/>
        <Error/>
        <CueTooltip id={field.client_id} description={field.tooltip}/>
      </div>
    </div> 
  )
}

export default InputTags;
