import React, { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Link, useHistory } from "react-router-dom";
import useModal from "../../../../hooks/useModal";
import ConfirmMenu from "../../../Modals/ConfirmMenu";
import JourneyCategoryService from "../../JourneyCategoryService";
import TeamActivityService from "../Activities/TeamActivityService";
import useNotification from "../../../../hooks/useNotification";
import Cs from "../../../../services/CommonService";

const PortalHeader = (props) => {
  const { project, share } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { showNotification } = useNotification();

  const deleteProject = (e) => {
    e.preventDefault();
    setLoading(true);
    JourneyCategoryService.delete(project).then((res) => {
      if (res.status == 204) {
        setLoading(false);
        history.push("/journey");
      }
    })
    toggleDeleteModal(e);
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault();
    setLoading(true);
    project.is_chatlist_enabled = !project.is_chatlist_enabled;
    JourneyCategoryService.update(project).then((res) => {
      if (res.status == 204) {
        setLoading(false);
      }
    })
  }

  const getActiveUserReport = (e, journey_category_id) => {
    e.preventDefault();
    setLoading(true);
    let req = {
      journey_category_id: journey_category_id,
      type: "active_users",
    }
    TeamActivityService.reports(req).then((res) => {
      if (res.status == 200) {
        setLoading(false);
        let activeUsers = res.data.report;
      }
    })
  }

  const cloneJourney = (e, journey_category_id) => {
    e.preventDefault();
    setLoading(true);
    JourneyCategoryService.cloneJourney(journey_category_id).then((res) => {
      if (res.status == 200) {
        showNotification("Success", "Journey Cloned Successfully");
        setLoading(false);
        history.push("/journey");
      }
    })
  }

  const updateStatus = (e, journey_category) => {
    e.preventDefault();
    setLoading(true);
    let req = {
      is_journey_completed: !journey_category.is_journey_completed,
      id: journey_category.id,
    }
    JourneyCategoryService.update(req).then((res) => {
      if (res.status == 204) {
        journey_category.is_journey_completed = !journey_category.is_journey_completed;
        setLoading(false);
      }
    })
  }

  const Menu = () => (
    <Fragment>
      {project.is_admin_user && 
        <span id="portal_menu" className="portlet-dropdown">
          <a data-tip="Adjust Journey Settings" className="p-dropbtn">
            <i className="fas fa-cog m-r-15 font-20"/>
          </a>
          <div className="p-dropdown-content font-16">
            {project.is_admin_user && 
              <Link to={`/journey_profile/${project.education_journey_profiles.id}?form_id=${project.education_journey_profiles.form_id}`}>
                <i className="far fa-edit" /> Edit
              </Link>
            }

            {project.is_admin_user && 
              <a href="#" onClick={(e) => toggleDeleteModal(e, project)}>
                <i className="far fa-trash-alt" /> Delete
              </a>
            }

            {project.is_admin_user && 
              <Link to={{
                pathname: `/invite/user/${project.id}/${project.education_journey_profiles.id}`,
                state: {'journey':project, 'share':share},
              }}>
                <i className="fa fa-user"/> Invite User
              </Link>
            }

            {project.is_admin_user && 
              <a href="#" onClick={(e) => cloneJourney(e, project.id)}>
                <i className="far fa-clone"/> Create a copy
              </a>
            }

            {project.is_admin_user && 
              <a href="#" onClick={(e) => updateStatus(e, project)}>
                <i className={`far ${
                    project.is_journey_completed ? "fa-check-circle" : "fa-circle"
                  }`}
                /> Completed
              </a>
            }

            {project.is_admin_user && 
              <a href="#" onClick={(e) => toggleChatlistStatus(e)}>
                <i className={
                    project.is_chatlist_enabled
                      ? "far fa-circle"
                      : "far fa-check-circle"
                  }
                /> Share chat
              </a>
            }
          </div>
        </span>
      }
    </Fragment>
  )

  return(
    <>
      <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
        <div className="flex coldir fulwid p20">
          <div className="flex justspacebetween">
            <div>
              <div className="app-title m-r-20">
                {project.education_journey_profiles.data.fj_name_of_family_member}
              </div>
              <p className="m-t-10">
                Team Journey &gt; &nbsp;
                <span className="text-blue">{project.education_journey_profiles?.data_source?.subject?.label}</span>
              </p>
            </div>
            <div className="flex aligncenter">
              <Menu/>
              <Link data-tip="Back to Journey List" to={{pathname: '/journey'}}>
                <i className="fas fa-home font-20"/>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ConfirmMenu
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        success={deleteProject}/>
    </>
  )

  return (
    <div className="portlet-heading clearfix">
      {loading && <div className="spinner" />}
      <div className="col-xs-12 col-sm-10">
        <h3 className="portlet-title ng-binding">
          <span>
            {project.education_journey_profiles.data.fj_name_of_family_member}
            <i className="fas fa-angle-right" />
          </span>
          <span>{project.education_journey_profiles.data.grad_date}</span>
          <span
            dangerouslySetInnerHTML={{
              __html:
                project.education_journey_profiles.data
                  .brief_13_brief_profile_of_team_profile,
            }}
          ></span>
        </h3>
      </div>

      <div className="col-xs-12 col-sm-2">
        <span className="text-cap">
          {(project.edu_journey_type).replace(/_/g, ' ')}
        </span>
        <div className="portlet-widgets">
          <Menu />
          <Link
            data-tip="Back to Journey List"
            to={{
              pathname: `/journey`,
            }}
          >
            <i className="fas fa-home font-20"></i>
          </Link>
        </div>
      </div>
      <ConfirmMenu
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        success={deleteProject}
      />
    </div>
  );
};

export default PortalHeader;
