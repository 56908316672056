import React, {useState, Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Dabble from './Dabble'
import querystringify from "querystringify"
import DailyDabbleService from "./DailyDabbleService"
import Pagination from '../Pagination'
import ConfirmMenu from "../Modals/ConfirmMenu"
import FormTemplateId from '../../constants/FormTemplateList'
import {MenuItem,NavIcon} from '../Common/MenuItem'
import ProjectMenu from "../Modals/ProjectMenu"
import {Link, useHistory} from "react-router-dom"
import useModal from "../../hooks/useModal"
import useStyle from '../../hooks/useStyle'
import Cs from '../../services/CommonService'
import DailyDabbleTableList from './DailyDabbleTableList'

let requestParams = {}
let selectedDabble = {}
let dataSource = {}

const DailyDabbleList = (props) => {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [dabbleList, setDabbleList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [formFieldsList, setFormFieldsList] = useState({})

  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const [showDabbleTable, setShowDabbleTable] = useState(false)

  let history = useHistory()
  let queryParam = querystringify.parse(props.location.search)
  
  let cardGridCss = "col-sm-12 col-sm-6 col-md-4"
  let cardHeightCss = "mih-380"
  
  useEffect(() => {
    requestParams = {
      'data_source_params': {
        'data_source_id': [2162, 3142],
      },
      'form_field_params': {
        'ids':[193]
      },
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': 'latest_dabble_date',
      'sort_direction': 'desc',
      'search': props.searchString,
    }

    getDailyDabbleList();
  }, [props.searchString, currentUser.id, currentpage])

  let getDailyDabbleList = () => { 
    DailyDabbleService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totaltems = res.data.meta.total
        dataSource = res.data.data_source_list
        setDabbleList(res.data.daily_dabbles)
        setFormFieldsList(res.data.form_fields || {})
      }
    })
  }

  const addDelete = (e, ja) => {
    selectedDabble = ja
    toggleDeleteModal(e)
  }

  const addMenu = (e, ja) => {
    selectedDabble = ja
    toggleMenuModal(e)
  }

  const updateStatus = (e, item) =>{
    e.preventDefault()
    item.is_active = !item.is_active 
    DailyDabbleService.update(item).then((res) => {
      if(res.status==204){
        setDabbleList([...dabbleList])
      }
    })
  }

  const setLatestDabble = (e, item) =>{
    e.preventDefault()
    let req = {
      id:item.id,
      latest_dabble_date:true
    }
    DailyDabbleService.update(req).then((res) => {
      if(res.status==204){
        setDabbleList([...dabbleList])
      }
    })
  }

  const deleteDailyDabble = (e) =>{
    e.preventDefault();
    DailyDabbleService.delete(selectedDabble.id).then((res) => {
      if (res.status == 204) {
        setDabbleList(dabbleList.filter((i) => i.id !== selectedDabble.id)) 
      }
    })  
    toggleDeleteModal(e);
  }

  const routeForm = (e, id) =>{
    e.preventDefault();
    history.push('/new/daily_dabble'+(id?`/${id}`:''))
  }
  
  const toggleDailyDabbleTable = () => setShowDabbleTable(!showDabbleTable)

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <a href="#" onClick={e=>addDelete(e, item)}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <a href="#" onClick={e=>routeForm(e, item.id)}>
          <i className="fa fa-edit"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <a href="/" onClick={e=>updateStatus(e, item)}>
          <i className={item.is_active ? "far fa-check-circle": "far fa-circle"}></i>
          <span className="font-13">Pub Active</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <a href="/" onClick={e=>setLatestDabble(e, item)}>
          <i className={item.latest_dabble_date ? "far fa-check-circle": "far fa-circle"}></i>
          <span className="font-13">Dabble Date {Cs.formatUpdateDate(item.latest_dabble_date)}</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ ja, fn }) => (
    <i onClick={e=>addMenu(e, ja)} className="fas fa-bars font-16 m-r-15" />     
  )

  const dailyDabbleListCard =  dabbleList && dabbleList.map((ja, i) => (
    <div className={cardGridCss} key={i}>
      <Dabble dabble={ja} menu={Menu} dataSource={dataSource}
        currentUser={currentUser} toggleModal={toggleDeleteModal} 
        cardHeightCss={cardHeightCss} formFieldsList={formFieldsList}/>
        
        {selectedDabble.id == ja.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedDabble} 
          addDelete={addDelete} type="journey" MenuItems={menuItems} menuHeight="250px"/>
        }
    </div>
  ))
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">
                Daily Cues
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="toggle_daily_dabble_table" dataTip="Toggle Daily Cue Table" 
                onSelect={toggleDailyDabbleTable}
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-table"/>

              <a href="#" className="bg-highlight round-btn float-right m-r-5 m-t-4" data-tip="Add a new Daily Cue" onClick={e=>routeForm(e)}>  
                <i className="fas fa-plus"/>
              </a>
            </div>
          </div>
          <div className="app-content-section m-t-30">
            <div className="row">
              {!showDabbleTable &&
                <Fragment>
                  {dailyDabbleListCard}
                </Fragment>
              }
              
              {showDabbleTable && 
                <DailyDabbleTableList dabbleList={dabbleList} requestParams={requestParams}
                  selectedDabble={selectedDabble} updateStatus={updateStatus} 
                  routeForm={routeForm} addDelete={addDelete} getDailyDabbleList={getDailyDabbleList}/>
              }

              <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
                setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
            </div>
          </div>
        </div>
      </div>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteDailyDabble}/>
      
    </Fragment>
  )

}

export default DailyDabbleList;