import React, { useState, Fragment, useEffect } from 'react'
import DataGroupService from './DataGroupService'
import DataGroupItemService from './DataGroupItemService'

let requestParams = null

const AddDataGroup = ({ item, isOpen, list, setList, groupType, toggleModal }) => {
  const [group, setGroup] = useState({'group_type': groupType})
  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    requestParams = {
      'page':1,
      'per_page':100,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'group_type':groupType
    }

    getDataGroup()
  }, [])

  const getDataGroup = () =>{
    DataGroupService.getAll(requestParams).then((res) => {
      if(res.status == 200)setGroupList(res.data.data_groups)
    })
  }

  const setEdit = (e, g) =>{
    e.stopPropagation()
    setGroup(g)
  }

  const reset = (e) =>{
    e && e.preventDefault()
    setGroup({'group_type': groupType, title:null})
  }

  const addDataGroup = (e) =>{
    e.preventDefault()
    if(group.id){
      DataGroupService.update(group).then((res) => {
        const groups = groupList.map(d => {
          return d.id == group.id
            ? { ...d}
            : d
        })  
        setGroupList(groups)
        reset()
      })
    }else{
      DataGroupService.create(group).then((res) => {
        setGroupList([res.data.data_group, ...groupList])
      })
    }
  }

  const addItemToDataGroup = (group) =>{
    if(!item.group_item_id){
      let req = {
        group_id: group.id,
        groupable_id: item.id, 
        groupable_type: groupType
      }

      DataGroupItemService.create(req).then((res) => {
        item.group_id = group.id
        item.group_item_id = res.data.data_group_item.id
        setList([...list])
      })
    }else{
      DataGroupItemService.delete(item.group_item_id).then((res) => {
        item.group_id = null
        item.group_item_id = null
        setList([...list])  
      })  
    }
  }

  const onDelete = (e, group) =>{
    e.stopPropagation();
    DataGroupService.delete(group.id).then((res) => {
      setGroupList(groupList.filter((i) => i.id !== group.id))
    })
  }

  const GroupList = () => groupList && groupList.map((g, k) =>{
    return(
      <Fragment key={k}>
        <span className="checkbox-container font-14" onClick={e => addItemToDataGroup(g)}>
          <span>{g.title}</span>
          <input type="checkbox" checked={item.group_id==g.id} readOnly/>
          <span className="checkbox-checkmark"></span>
          <i onClick={e=>setEdit(e, g)} className="far fa-edit m-l-15"/>
          <i onClick={e=>onDelete(e, g)} className="far fa-trash-alt m-l-15"/>
        </span>
      </Fragment>
    )
  })

  return(
    <Fragment>
      <form className="p-5 scroll-x">
        <div className="ml-section br-btm-grey">
          <label><b>Title</b></label>
          <input onChange={e=>{group.title = e.target.value}} 
            className="ml-input ml-border ml-margin-bottom"
            type="text" placeholder="Group Title..." name="title"  
            defaultValue={group.title} required/>

          <button className="ml-button ml-block ml-green ml-section ml-padding" 
            onClick={e=>addDataGroup(e)}>
            <b>{group.id?'Update':'Add'}</b>
          </button>
          
          {group.id && <button className="ml-button ml-block bg-lred ml-section ml-padding white" 
            onClick={e=>reset(e)}>
            <b>Clear</b>
          </button>}

          <GroupList/>

        </div>
      </form>
    </Fragment>
  )

}

export default AddDataGroup;