import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import CommonService from "../../../services/CommonService"

const User = (props) => {
  let { user } = props;  

  const roleUsersView = user.role_users && user.role_users.map((u, i) =>  (
    <Fragment key={i}>  
      <div className="float-left wk-center m-l-15">
        <span className="pull-right dot-live bg-green" />
        <img src="images/avatars/2s.png" alt="img" className="user-img-sm bg-blue2-dark" />
        <a>{CommonService.getNameFromEmail(u.email)}</a>
      </div>  
    </Fragment>
  ))

  const UserView = () =>  (
    <div className="float-left wk-center m-l-15">
      <span className="pull-right dot-live bg-green" />
      <img src="images/avatars/2s.png" alt="img" className="user-img-sm bg-blue2-dark" />
      <a>{CommonService.getNameFromEmail(user.email)}</a>
    </div>  
  )

  const CardView = () =>{
    if(user.role_users){
      return (<Fragment>
        {roleUsersView}
      </Fragment>)
    }else{
      return (<Fragment>
        <UserView />
      </Fragment>)
    }
  }

  return (
    <Fragment>
      <CardView/>
    </Fragment>
  )

};

export default User;