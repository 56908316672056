import React, {Fragment, useEffect, useState, useContext} from "react";
import useStyle from "../../hooks/useStyle";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import DigitalContentService from "../../components/DigitalContent/DigitalContentService";
import env from "../../env";
import LottieLight from "../Common/LottieLight"
import Cs from "../../services/CommonService";
import PreLoadSpinner from "../../components/PreLoadSpinner";
import ImagePlaceholder from "../Common/ImagePlaceholder"
import {SwiperContainer, SwiperSlide} from "../Common/Swiper"
import querystringify from "querystringify"
import CheckAccess from '../Journey/Roles/CheckAccess'

let requestParams = {}
let cardViewCount = 1

const WelcomeTour = (props) => {
  useStyle("slideshow")

  let queryParam = querystringify.parse(props.location.search)
  const state = props.location.state

  const { state: {screen, user: currentUser}, dispatch } = useContext(AuthContext)
  const [welcomeTourList, setWelcomeTourList] = useState([])
  const [settings, setSetting] = useState(currentUser.setting)
  const [slideIndex, setSlideIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [currentpage, setCurrentpage] = useState(1) 

  useEffect(() => {
    requestParams = {
      content_type: "edu_welcome_tour",
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "asc",
      totalItems: 0,
      search: null
    }

    const {data} = currentUser.domain_info
    const accountType = data?.type_of_use

    if(accountType){
      requestParams.data_filter = [{
        key:'user_type',
        operator:'=',
        value:accountType
      }]

      /*
        accountType == 1 for Personal Use
        accountType == 2 for Organizational Use
      */

      if(accountType == 1)
        requestParams.data_filter.push(
          {
            key:'primary_interest_personal',
            operator:'=',
            value:data?.primary_interest_personal
          }
        )
          
      if(accountType == 2)
        requestParams.data_filter.push(
          {
            key:'type_organizational_use',
            operator:'=',
            value:data?.type_organizational_use
          }
        )
    }

    getWelcomeTour()
  }, [currentUser.id])

  let getWelcomeTour = () => {
    DigitalContentService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)
        setWelcomeTourList(res.data.digital_contents)
        setLoading(false)
      }
    })
  }
  
  const updateSetting = () => {
    let req = {id: settings.id, welcome_tour: 'Completed'} 
    Cs.updateSetting(req).then((res) => {
      if (res.status == 200) {
        currentUser.setting = res.data.setting;
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser },
        })
        routeUser(currentUser)
      }
    })
  }

  const routeUser = (user) =>{
    if(state?.journey){
      Cs.routeJourney(state.journey, props)
    }else{
      CheckAccess.routeUser(user, props.history)
    }
  }
  
  const ImageSvgRender = ({ content }) => {
    if(content.data.lottie){
      return(
        <LottieLight id={content.id} {...content.data.lottie}/>
      )
    }else{
      return(
        <ImagePlaceholder imageClass={`w-100p img-responsive m-t-15 m-b-15 ${screen.xs ? '' :'img-resize'}`} url={env.file_url + content.data.illustration}/>
      )
    }
  }

  const showSlides = (n) => {
    if(n > (welcomeTourList.length-1)){
      setSlideIndex(0)
    }else if(n < 0){
      setSlideIndex(welcomeTourList.length-1)
    }else{
      setSlideIndex(n)
    }     
  }

  const slide = welcomeTourList.map((w, k) => 
    <SwiperSlide key={k}>
      <div className="mySlides ss-fade bg-white">
        { w.data.heading && <div className="ss-text  font-24 p-10" style={{color:w.data.text_color,backgroundColor:w.data.color}}>{w.data.heading}<br/>
        </div>}
        <div className="ss-numbertext">{k+1} / {welcomeTourList.length}</div>
        <div className="rich-text ss-text font-16" dangerouslySetInnerHTML={{ __html: w.data.notes }}></div>
        <ImageSvgRender content={w}/>
      </div>
    </SwiperSlide>
  )
      
  if(loading){
    return(<PreLoadSpinner/>)
  }

  return (
    <Fragment>
      <div className="page-content bg-white">
        <h4 className="text-center font-18  p-7">New user welcome tour</h4>
        <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
          requestParams={requestParams} cardViewCount={cardViewCount} autoHeight="true">
            {slide}
        </SwiperContainer> 
        <div className="">
          <div className="p-20 bg-white col-xs-12 col-sm-push-8 text-center clearfix">
            <button className="btn ss-finish" onClick={(e) =>updateSetting()}>
              {slideIndex+1 == welcomeTourList.length ? 'Finish':'Skip'}
            </button>
            <div>Click this button to set up your account.</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default WelcomeTour;