import React , {useState, useContext, Fragment} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import useStyle from '../../hooks/useStyle';
import PreLoadSpinner from "../../components/PreLoadSpinner";
import { Link } from "react-router-dom";
import CommonService from "../../services/CommonService";

const ResetPassword = (props) => {
  useStyle('/css/login.css', true) 
  useStyle('/css/common.css', true)

  const { state: authState, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(Math.random())

  const initialState = {
    password: "",
    cpassword:"",
    isSubmitting: false,
    errorMessage: null,
    errorSign: false
  };

  const [data, setData] = useState(initialState);

  const handleInputChange = (event) => {
    data[event.target.name] = event.target.value;
    setData({...data});
    checkRegex(event.target.value)
  };

  const checkRegex = (val) =>{
    //var pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,19}$/);
    const pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d\W]{6,19}$/)
    const newData = data
    if(pattern.test(val) === true ){
      setData({
        ...newData,
        errorMessage: null,
        errorSign: true
      });
    }else{
      setData({
        ...newData,
        errorMessage: 'password should have one numeric, one special character and should be greater than 6 chars',
        errorSign:false
      });
    }
  }

  const  checkCpassword = () =>{
    if(data.password!="" && data.cpassword!="" && data.password === data.cpassword){
        return true
    }else{
        return false
    }
  }

  const handleForgetSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    
    let req = { user: { password: data.password,password_confirmation:data.cpassword } };
    const userId = props.match.params.userId
    const token = props.match.params.token
    AuthenticationService.ResetPassword(req, userId, token).then((res) => {
      if (res.status == 200) {
        setLoading(false);
        props.history.push('/signin')
      }else {
        setLoading(false);
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: res.message || res.statusText,
        });
      }
    }).catch((error) => {
      setLoading(false);
      setData({ ...data, errorMessage: error.response.data.error });
    })
  };

  if(loading){
    return(<PreLoadSpinner/>)
  }

  return (
    <Fragment>
      <div id="ct">
        <section className="login-page flex justspacebetween">
          <form className="login-left wid_50 pos_relative flex aligncenter">
            <div className="login-form">
              <h2 className="title m-b-10">Reset your Password</h2>
              <p className="text-lite-gray m-b-30">
                Enter your new password below
              </p>
              <div className="form-fields flex coldir m-b-20">
                <label className="form-label m-b-10">Password</label>
                <input
                  type="password" placeholder="Choose a Password"
                    name="password" onChange={handleInputChange}
                  className="form-input fulwid"
                  required
                />
              </div>
              <div className="form-fields flex coldir">
                <label className="form-label m-b-10">Confirm Password</label>
                <input type="password" placeholder="Confirm your Password"
                    name="cpassword" onChange={handleInputChange} className="form-input fulwid"
                  required />
              </div>
              {data.errorMessage && (
                  <span className="form-error lred m-t-5">
                    {data.errorMessage}
                  </span>
                )}
                
              <button
                disabled={checkCpassword() ? false : true}
                  onClick={handleForgetSubmit}
                className="btn btn-primary fulwid f16 fw500 tcenter text-white m-t-20"
              >
                Reset Password
              </button>
              <div className="m-t-50">
                <p>
                  Want to Sign In? {" "}
                  <Link to="/signin" className="text-link">
                    <strong>Click Here </strong>
                  </Link>
                </p>
              </div>
            </div>
          </form>
          <div className="login-right tcenter justifycenter wid_50 pos_relative flex aligncenter">
            <div className="login-content">
              <a href="#" className="m-b-20">
                <img src="images/cueTree-logo.png" alt="" />
              </a>
              <h3 className="fw500 m-b-15">Transform education journeys</h3>
              <p className="text-lite-gray m-b-20 wid_80 w-80 wid_100_xs mx-auto">
                {CommonService.displayRandomPhrase(Math.floor(4*count))}
              </p>
              <div className="text-lite-gray badge badge-md badge-content m-b-20 mx-auto fw500">
                Improving learning experiences by augmenting and enriching connections
              </div>
              <div className="m-t-10 mx-auto">
                <ul className="flex gap-5 justifycenter">
                  <li>
                    <a href="#">
                      <img src="images/fb-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/instagram-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/twitter-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/linked-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/whatsapp-icon.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex justspacebetween m-t-50">
                <p className="text-lite-gray f12">{new Date().getFullYear()} IPIPAL INC. All Rights Reserved.</p>
                <p className="f12">
                  <Link to="/about_us/terms_of_service" className="text-link">
                    <strong>Terms of Service</strong>
                  </Link>
                  {" "}|{" "}
                  <Link to="/about_us/privacy_policy" className="text-link">
                    <strong>Privacy policy </strong>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default ResetPassword;