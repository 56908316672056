import React, {
  useState,
  useRef,
  Fragment,
  useContext,
  useEffect,
} from "react";
import { Link } from "react-router-dom";
import useStyle from "../../../../../hooks/useStyle";
import useModal from "../../../../../hooks/useModal";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import ReactTooltip from "react-tooltip";
import CourseService from "../CourseService";
import querystringify from "querystringify";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import {Image} from "../../../../Common/Image"
import {NavIcon} from '../../../../Common/MenuItem'
import TrainingModuleService from "../../TrainingModules/TrainingModuleService"

let requestParams = {}

const CoursePage = (props) => {
  useStyle("course");
  
  let project = {
    title: "Course Details",
  };

  let params = props.match.params;
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const [courseDetail, setCourseDetail] = useState();
  const [profile, setProfile] = useState();
  const [openModal, toggleModal] = useState('');
  const [enrollmentForm, setEnrollmentForm] = useState();

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'id':params.id
    }

    getCourseDetails();
    return () => {
      requestParams = {} 
    }
  }, [props.searchString]);

  let getCourseDetails = () => { 
    setLoading(true)
    CourseService.get(requestParams).then((res)=>{
     if(res.status == 200){
       setCourseDetail(res.data.course_detail)
       setProfile(res.data.profile)
       setEnrollmentForm(res.data.user_form)
       setLoading(false)       
     }
    })
  }

  let getModules = (course_id) => {
    let req = {
      'journey_profile_id':courseDetail.journey_profile_id,
      'journey_category_id':courseDetail.journey_category_id,
      'page':1,
      'per_page':100,
      'sort_column':'mf.sort_order',
      'sort_direction':'asc',
      'exists_in_planner':true,
      'topic_exists_in_planner':true,
      'filter':'student_curriculum',
      'course_id':course_id,
    }

    TrainingModuleService.getAll(req).then((res) => {
      if(res.status == 200){
        setModule(res.data.modules)
      }
    })
  }

  let setModule = (modules) =>{
    courseDetail.modules = modules
    setCourseDetail({...courseDetail})
  }

  let routeEnrollmentForm = () =>{
    enrollmentForm && props.history.push(`/career_training/enrollment/${enrollmentForm.journey_category_id}/${enrollmentForm.journey_profile_id}/${params.id}/${enrollmentForm.id}`)
  }

  const ModulesList = ({modules}) => modules.map((m, i) => { 
    return(
      <div className={"p-5 m-10-5 bg-white"} >
        <div className="row bold-600 bg-highlight white w-20p" style={{padding:'6px 12px'}}>
          {m.data.name}
        </div>
        <div className="parent">
          <Topics topics={m.topics || []} />
        </div>
      </div>  
    )
  })

  const Topics = ({topics}) => topics.map((topic, i) =>{
    return(
      <Fragment>
        <div className="module-title font-16 bg-white p-10-5">
          <div className="row">
            { i+1 +". " + topic.data.name}
          </div>
        </div>
      </Fragment>
    )
  })

  const Detail = ({courseDetail}) =>{
    if(courseDetail !== undefined){
      if(!courseDetail.modules)getModules(courseDetail.id);
      const data = courseDetail.data
      let ds = courseDetail.data_source
      let fee = courseDetail.course_fee || {data:{}} 

      const category = (key) => {
        if(ds){
          return ( ds[key]?.label 
          );
        }else{
          return (<div>
            </div>
          );
        }
      }

      const Fee = ({label, price, type}) => (
        <>
          {fee.data.student_subscription_model_array?.includes(type)?
            <div>
              <i className="fas fa-dollar-sign"/>
              {price} {label}
            </div>:null
          }
        </>
      )

      return(
        <div className="w3-content w3-margin-top" style={{ maxWidth: "1400px" }}>
            <div className="w3-row-padding">
              <div className="w3-third p-7">
                <div className="w3-white w3-text-grey w3-card-4">
                  <div className="w3-display-container p-10">
                    <h4 className="text-center">
                      {data.course_name}
                    </h4>
                    <img
                      src="../../../images/logo/cuetree_Icon.png"
                      style={{ height: "150px", margin: "auto" }}
                      alt="Course"
                    />
                  </div>
                  <div className="w3-container">

                  <div onClick={e=>routeEnrollmentForm()} className="w-100p m-b-5 ml-button ml-block ml-section ml-padding bg-highlight white">
                    Subscribe
                  </div>

                  {fee.id &&
                    <Fragment>
                      <p className="w3-large w3-text-teal">
                        Fee
                      </p>
                      <p className="m-15">
                        <Fee type={1} label="Per User" price={fee.data.course_fee_student_individual}/>
                        <Fee type={2} label="Per Buddy Group" price={fee.data.course_fee_student_buddy}/>
                      </p>
                      <p className="m-15">
                        <span className="w3-tag w3-round w-100p font-16" style={{backgroundColor:'rgb(255,91,23)',padding:'12px 24px'}}>Subscribe</span>
                      </p>
                    </Fragment>
                    }
                  </div>
                  <div className="w3-container">
                  <p className="w3-large w3-text-teal">
                        Course Details
                        { screen.xs && <span className="pull-right">{openModal == 'first' ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => toggleModal('')}/>:<i className="fa fa-chevron-down" aria-hidden="true" onClick={() => toggleModal('first')}/>}</span>}
                    </p>
                    {openModal === 'first' || screen.lg ? 
                    <div className="d-table-cell">
                    <p className="m-10 pull-left text-center">
                      Duration <br/>
                      <span className="w3-tag w3-teal w3-round">
                        {data.duration}
                      </span>
                    </p>
                    <p className="m-10 pull-left text-center">
                      Language <br/>
                      <span className="w3-tag w3-teal w3-round">
                        {category('language')}
                      </span>
                    </p>
                    <p className="m-10 pull-left text-center">
                      Skill Level <br/>
                      <span className="w3-tag w3-teal w3-round">
                        {category('skill_level')}
                      </span>
                    </p>
                    <p className="m-10 pull-left text-center">
                      Certificate <br/>
                      <span className="w3-tag w3-teal w3-round">{category('certificate')}</span>
                    </p>
                    </div>
                    :<div></div>}
                    <hr/>
                    <p className="w3-large w3-text-teal">
                        Instructor Details
                     { screen.xs && <span className="pull-right">{openModal == 'seventh' ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => toggleModal('')}/>:<i className="fa fa-chevron-down" aria-hidden="true" onClick={() => toggleModal('seventh')}/>}</span>}
                    </p>
                    {openModal === 'seventh' || screen.lg ? 
                    <div>
                    <p className="m-15">
                      <i className="fa fa-user fa-fw w3-margin-right w3-large w3-text-teal"></i>
                      {data.name || "-"}
                    </p>
                    <p className="m-15">
                      <i className="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-teal"></i>
                      {data.contact_email || "-"}
                    </p>
                    <p className="m-15">
                      <i className="fa fa-phone fa-fw w3-margin-right w3-large w3-text-teal"></i>
                      {data.contact_number || "-"}
                    </p>
                    <p className="m-15">
                      <i className="fab fa-linkedin fa-fw w3-margin-right w3-large w3-text-teal"></i>
                      {data.linkedin || "-"}
                    </p>
                    <p className="m-15">
                      <i className="fab fa-skype fa-fw w3-margin-right w3-large w3-text-teal"></i>
                      {data.skype || "-"}
                    </p>
                    <p className="m-15">
                      <i className="fab fa-whatsapp fa-fw w3-margin-right w3-large w3-text-teal"></i>
                      {data.whatsapp || "-"}
                    </p>
                    </div>:<div></div>}                    
                    <hr />
                    <p className="w3-large w3-text-teal">
                        Skills
                        { screen.xs && <span className="pull-right">{openModal == 'second' ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => toggleModal('')}/>:<i className="fa fa-chevron-down" aria-hidden="true" onClick={() => toggleModal('second')}/>}</span>}
                    </p>
                    {openModal === 'second' || screen.lg ?
                    <div>
                      <ul className="m-15">
                      {data.skills_array && data.skills_array.map((skill, i) => 
                          <li className="m-15" key={i}>{skill}</li>
                      )}
                      </ul>
                    <br />
                    </div> :<div></div>}
                    <hr />
                    <p className="w3-large w3-text-theme w3-text-teal">
                          Pre-Requisite
                        { screen.xs && <span className="pull-right">{openModal == 'sixth' ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => toggleModal('')}/>:<i className="fa fa-chevron-down" aria-hidden="true" onClick={() => toggleModal('sixth')}/>}</span>}
                    </p>
                    {openModal === 'sixth' || screen.lg ?
                    <div>
                      <ul className="m-15">
                        {data.details_array && data.details_array.map((detail, i) =>                             
                            <li className="m-15" key={i}>{detail}</li>
                        )}
                      </ul>
                    </div> :<div></div>
                    }
                    <hr />
                  </div>
                </div>
              </div>
  
              <div className="w3-twothird p-7">
                <div className="w3-container w3-card w3-white w3-margin-bottom w3-text-grey">
                  <h4 className="w3-text-teal w3-padding-16 w3-large">
                    Course Description
                    { screen.xs && <span className="pull-right">{openModal == 'third' ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => toggleModal('')}/>:<i className="fa fa-chevron-down" aria-hidden="true" onClick={() => toggleModal('third')}/>}</span>}
                  </h4>
                  {openModal === 'third' || screen.lg ?
                  <div>
                  <div className="w3-container">
                    <div className="richtext" dangerouslySetInnerHTML={{ __html: data.course_description}}></div>
                    <hr />
                  </div>
  
                  <div className="w3-container">
                    <h4 className="w3-text-teal w3-large">What you will learn</h4>
                    <div className="col-md-12">
                      <ul>
                        {data.learn_points_array && data.learn_points_array.map((point, i) => 
                          <li className="m-15" key={i}>
                            {point}
                          </li>
                        )}
                      </ul>
                    </div>

                  </div>
                  <hr/>
                  <div className="w3-container">
                    <h4 className="w3-text-teal w3-large">Requirements</h4>
                    <div className="col-md-12">
                      <ul className="list-style-v2 list-unstyled">
                        {data.requirements_array && data.requirements_array.map((req, i) => 
                          <li className="m-15" key={i}>
                            {req}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  </div>
                  :<div></div>}
                </div>
                <div className="w3-container w3-card w3-white w3-margin-bottom w3-text-grey">
                  <h4 className="w3-text-teal w3-padding-16 w3-large">
                    Curriculum
                    { screen.xs && <span className="pull-right">{openModal == 'fourth' ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => toggleModal('')}/>:<i className="fa fa-chevron-down" aria-hidden="true" onClick={() => toggleModal('fourth')}/>}</span>}
                  </h4>
                  {openModal === 'fourth'  || screen.lg ?
                  <div>
                    <ModulesList modules={courseDetail.modules || []} />
                  </div> :<div></div>}
                </div>
  
                <div className="w3-container w3-card w3-white w3-text-grey">
                  <h4 className="w3-text-teal w3-padding-16 w3-large">
                    Instructor
                    { screen.xs && <span className="pull-right">{openModal == 'fifth' ? <i className="fa fa-chevron-up" aria-hidden="true" onClick={() => toggleModal('')}/>:<i className="fa fa-chevron-down" aria-hidden="true" onClick={() => toggleModal('fifth')}/>}</span>}
                  </h4>
                  {openModal === 'fifth' || screen.lg ?
                  <div>
                  <div className="w3-container m-15">
                    <div className="col-md-12">
                      <div className="col-md-6 p-7">
                        <Image styleName="w-85" image={courseDetail.created_user.profile_image?(courseDetail.created_user.id +'/'+ courseDetail.created_user.profile_image):0} 
                          type="user_profile_image"/>
                      </div>
                      <div className="col-md-6 p-7 d-table-cell">
                          <p className="m-10 pull-left text-center">
                          Instructor rating <br/>
                          <span className="m-5 w3-tag w3-teal w3-round">4.87 </span>
                          </p>
                          <p className="m-10 pull-left text-center">
                          Reviews <br/>
                          <span className="m-5 w3-tag w3-teal w3-round">
                            1,533
                          </span>
                          </p>
                          <p className="m-10 pull-left text-center">
                          Students <br/>
                          <span className="m-5 w3-tag w3-teal w3-round">
                            23,912
                          </span>
                          </p>
                          <p className="m-10 pull-left text-center">
                          Courses <br/>
                          <span className="m-5 w3-tag w3-teal w3-round">29</span>
                          </p>
                      </div>
                    </div>
                    <br />
                  </div>
                  <div className="w3-container">
                    <h4 className="w3-text-teal pull-left">{data.name}</h4>
                  </div>
                  <div className="w3-container">
                   <div className="w3-text-grey" dangerouslySetInnerHTML={{ __html: data.bio}}></div>
                    <br />
                  </div>
                  </div>:<div></div>}
                </div>
              </div>
            </div>
          </div>
      )
    }else{
      return(<div>

      </div>)
    }
   
    
  }

  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }
  
  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-12">
          <div className="col-md-4 pull-left">
            <JourneyTitle title={project.title} project={project} />
          </div>
          <div className="col-md-8 pull-right">
            <NavIcon id="back_icon" dataTip="Back to Assessment" 
              onSelect={e=>props.history.goBack()}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-arrow-left"/>
          </div>
          <div className="clear"/>
        </div>
      </div>
      <div className="page-content">
        <Detail courseDetail={courseDetail}/>
      </div>
    </Fragment>
  )
}

export default CoursePage;
