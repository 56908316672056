import React ,{Fragment,useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../services/CommonService'
import env from "../../env"
import {FormFieldTable, RenderData} from "../Journey/UserRoles/NotificationPill"

const Dabble = (props) => {
  let {dabble, formFieldsList, dataSource} = props
  let {data, data_source} = dabble
  let formFields = formFieldsList[dabble.dabble_form_id] || []
  const cardStyle = {backgroundColor: "#607D8B"}

  const Footer = () =>(
    <div className="card-footer">
      <div className="pull-left">
        
      </div>
      <div className="pull-right">
        <props.menu ja={dabble} />
      </div> 
    </div>
  )

  const GenericContent = () => {
    return (
      <div className="bg-white border-rounded-10 m-t-10">
        <div className="card-title card-title-gray flex aligncenter p10 p-b-15" style={cardStyle}>
          <div className="flex aligncenter w-100p">
            <Link to="" id="head" className="content">
              <div className="w-100">
                <h4 className="fw700 card-heading">{data.title || data.record_title}</h4>
                <p className="f12">by {dabble?.created_user?.name || 'Public'} since {Cs.formatUpdateDate(dabble.updated_at)}</p>
              </div>
            </Link>
          </div>
          <div className="menu-drop-icon flex justifycenter aligncenter">
          </div>
        </div>
        <div className="card-content p10-20">
          <span><strong>Display Date</strong> {Cs.formatUpdateDate(dabble.dabble_display_date)}</span>
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
            <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          <ul className="flex aligncenter">
            <li className="m-r-25">
              <Link className="m-l-15" to={{pathname:`/list/daily_dabble_responses/${dabble.id}`, state:dabble }}>
                <i className="far fa-sticky-note font-18 text-muted" data-tip="Responses"></i>
              </Link>
            </li>
          </ul>
          <props.menu ja={dabble} />
        </div>
      </div>
    )
  }

  if(!data)return null;

  return (<GenericContent/>)
    
}

export default Dabble;