import React from "react";
import CommonService from '../../services/CommonService'
import env from "../../env"

const CareerPosition = ({formFn, data}) => {

  formFn.current_job_path = 'label'
  formFn.future_careers_path = 'label'
  formFn.future_career_path = 'label'
  formFn.future_careers_2_path = 'label'

  const getCareers = (occup_group, on_success, on_failure) =>{
    let req = {
      method: 'get',
      url: `${env.backend}data_source_lists`,
      params:{
        field:'data',
        data_source_id: CareerPositionList[occup_group].career_ds_id
      } 
    }
    
    CommonService.useAxios(req).then(({data, status})=>{
      on_success(data?.data_source_lists)
    })
  }

  formFn.get_current_job = (search, on_success, on_failure) => {
    getCareers(data.occupation_group, on_success, on_failure)
  }

  formFn.set_current_job = (item) => {
    data.current_job = item.label.trim()
  }

  formFn.get_future_careers = (search, on_success, on_failure) => {
    getCareers(data.future_occup_groups, on_success, on_failure)
  }

  const toggle_careers = (item, list_field, str_field) =>{
    const idx = data[list_field].findIndex(o => o.label == item.label);
    if(idx > -1) {
      data[list_field].splice(idx, 1);
    }else {
      data[list_field].push({label:item.label.trim(), fk_id:item.pk_id, value:item.value});
    }
    data[str_field] = data[list_field].map((o)=> o.label).join(",");
    return data[list_field]
  }

  const exist_careers = (item, list_field) =>{
    return data[list_field].findIndex(o => o.label == item.label) > -1;
  }

  formFn.toggle_future_careers = (item) =>{
    return toggle_careers(item, 'future_careers_array', 'future_careers')
  }

  formFn.exist_future_careers = (item) =>{
    return exist_careers(item, 'future_careers_array')
  }

  formFn.get_future_careers_2 = (search, on_success, on_failure) => {
    getCareers(data.future_occup_groups_2, on_success, on_failure)
  }

  formFn.toggle_future_careers_2 = (item) =>{
    return toggle_careers(item, 'future_careers_2_array', 'future_careers_2')
  }

  formFn.exist_future_careers_2 = (item) =>{
    return exist_careers(item, 'future_careers_2_array')
  }

  formFn.get_future_career = (search, on_success, on_failure) => {
    getCareers(data.future_occup_groups, on_success, on_failure)
  }

  formFn.set_future_career = (item) => {
    data.future_career = item.label.trim()
    data.future_career_fk_id = item.pk_id
    data.future_career_value = item.value
  }

  return null
}

let CareerPositionList = {1:{
            "sid": "architecture-and-engineering",
            "label": "Architecture and Engineering",
            "pk_id": 28999,
            "career_ds_id":3486,
            "value": 1
          }, 2:{
            "sid": "arts-and-design",
            "label": "Arts and Design",
            "pk_id": 29000,
            "career_ds_id":3487,
            "value": 2
          }, 3:{
            "sid": "building-and-grounds-cleaning",
            "label": "Building and Grounds Cleaning",
            "pk_id": 29001,
            "career_ds_id":3488,
            "value": 3
          }, 4:{
            "sid": "business-and-financial",
            "label": "Business and Financial",
            "pk_id": 29002,
            "career_ds_id":3489,
            "value": 4
          }, 5:{
            "sid": "community-and-social-service",
            "label": "Community and Social Service",
            "pk_id": 29003,
            "career_ds_id":3490,
            "value": 5
          }, 6:{
            "sid": "computer-and-information-technology",
            "label": "Computer and Information Technology",
            "pk_id": 29004,
            "career_ds_id":3491,
            "value": 6
          }, 7:{
            "sid": "construction-and-extraction",
            "label": "Construction and Extraction",
            "pk_id": 29005,
            "career_ds_id":3492,
            "value": 7
          }, 8:{
            "sid": "education-training-and-library",
            "label": "Education, Training, and Library",
            "pk_id": 29006,
            "career_ds_id":3493,
            "value": 8
          }, 9:{
            "sid": "entertainment-and-sports",
            "label": "Entertainment and Sports",
            "pk_id": 29007,
            "career_ds_id":3494,
            "value": 9
          }, 10:{
            "sid": "farming-fishing-and-forestry",
            "label": "Farming, Fishing, and Forestry",
            "pk_id": 29008,
            "career_ds_id":3495,
            "value": 10
          }, 11:{
            "sid": "management",
            "label": "Management",
            "pk_id": 29014,
            "career_ds_id":3501,
            "value": 11
          }, 12:{
            "sid": "math",
            "label": "Math",
            "pk_id": 29015,
            "career_ds_id":3502,
            "value": 12
          }, 13:{
            "sid": "media-and-communication",
            "label": "Media and Communication",
            "pk_id": 29016,
            "career_ds_id":3503,
            "value": 13
          }, 14:{
            "sid": "military",
            "label": "Military",
            "pk_id": 29017,
            "career_ds_id":null,
            "value": 14
          }, 15:{
            "sid": "office-and-administrative-support",
            "label": "Office and Administrative Support",
            "pk_id": 29018,
            "career_ds_id":3504,
            "value": 15
          }, 16:{
            "sid": "personal-care-and-service",
            "label": "Personal Care and Service",
            "pk_id": 29019,
            "career_ds_id":3506,
            "value": 16
          }, 17:{
            "sid": "production",
            "label": "Production",
            "pk_id": 29020,
            "career_ds_id":3507,
            "value": 17
          }, 18:{
            "sid": "protective-service",
            "label": "Protective Service",
            "pk_id": 29021,
            "career_ds_id":null,
            "value": 18
          }, 19:{
            "sid": "sales",
            "label": "Sales",
            "pk_id": 29022,
            "career_ds_id":3508,
            "value": 19
          }, 20:{
            "sid": "transportation-and-material-moving",
            "label": "Transportation and Material Moving",
            "value": 20,
            "career_ds_id":3509,
          }, 21:{
            "sid": "food-preparation-and-serving",
            "label": "Food Preparation and Serving",
            "pk_id": 29009,
            "career_ds_id":3496,
            "value": 21
          }, 22:{
            "sid": "healthcare",
            "label": "Healthcare",
            "pk_id": 29010,
            "career_ds_id":3497,
            "value": 22
          }, 23:{
            "sid": "installation-maintenance-and-repair",
            "label": "Installation, Maintenance, and Repair",
            "pk_id": 29011,
            "career_ds_id":3498,
            "value": 23
          }, 24:{
            "sid": "legal",
            "label": "Legal",
            "pk_id": 29012,
            "career_ds_id":3499,
            "value": 24
          }, 25:{
            "sid": "life-physical-and-social-science",
            "label": "Life, Physical, and Social Science",
            "pk_id": 29013,
            "career_ds_id":3500,
            "value": 25
          }
}

export {CareerPosition}