import React, {useState} from "react";
import {Link} from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const GradePath = (props) => {

  const PathGrid =  Object.values(props.path.options).map((path) =>  
    <div key={path.value} onClick={e=>{props.routePath(e, path)}}>
      <div><VerticalTimeline layout="1-column-left">
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#b5adad', color: 'black  ' }}
    contentArrowStyle={{ borderRight: '7px solid  #b5adad' }}
    date="2011 - present"
    iconStyle={{ background: '#b5adad', color: '#fff' }}
    icon={<i className="fa fa-university" style={{    color: 'white',
    marginLeft: '8px',marginTop:'8px',
    fontSize: '22px'}} aria-hidden="true"></i>}
  >
    <h3 className="vertical-timeline-element-title">Grade {path.label}</h3>
    <p>
      Creative Direction, User Experience, Visual Design, Project Management, Team Leading
    </p>
  </VerticalTimelineElement>
  </VerticalTimeline>
     </div>
    </div>
  )

  return (
    <div >
     {PathGrid}
    </div>
  )
};

export default GradePath;

/*
 <div className="timeline">
        <a href="#" className="timeline-content" >
          <div className="timeline-year">
            <small style={{backgroundColor: path.bg_color}}>
              Grade {path.label}
            </small>
          </div>
        </a>
      </div>

*/