import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import Cs from "../../services/CommonService"
import { HamburgerMenuIcon } from "../Common/ImageIcons"

const User = (props) => {
  const {user} = props
  let data = user.data
  let ds = user.data_source
  let bodyStyle = { height: "238px", overflowY: "auto" }
  let permissions = Cs.listToObj(props.dataSources.Manager_List.options, 'sid')

  const cardStyle = {
    borderTop: "5px solid #27a1fb",
    height: "360px",
  }

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 m-t-10">
        <div className="card-title card-title-gray flex aligncenter p10 p-b-15">
          <div className="flex aligncenter">
            <img src={Cs.getIconByType(null, 'profile_image')} className="rounded-circle bg-highlight" width="50"/>
          </div>
          <div className="flex aligncenter">
            <a id="head" className="content">
              <div className="w-100">
                <h4 className="fw700 card-heading truncate">{user.email}</h4>
                <p className="f12">since {Cs.formatUpdateDate(user.created_at)}</p>
              </div>
            </a>
          </div>
          <div className="menu-drop-icon flex justifycenter aligncenter">
            {/* <a href="#">
              <img src="./images/menu-drop.png" alt="" />
            </a> */}
          </div>
        </div>
        <div className="card-content p10-20">
          <div className="b-tab-table active scroll-auto ht-250 m-t-5">
            <table className="table card-table">
              <tbody>
                <tr>
                  <td>
                    <b>Total Journey <span className="badge bg-lred">{user.journey_count}</span></b> 
                  </td>
                </tr>
                {user.role_manager_permissions &&
                  <tr>
                    <td>
                      { Object.keys(user.role_manager_permissions).map((p,i)=>(
                        <span key={i} className="text-cap pill bg-lgreen">{permissions[p].label}</span>
                      ))}
                    </td>
                  </tr>
                }
              </tbody>
            </table>      
          </div>
        </div>
        <div className="card-footer flex justspacebetween p10-20 aligncenter">
          <ul className="flex aligncenter">
          </ul>
          <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, user)}/>
        </div>
      </div>
    </Fragment>
  )
}

export default User;