import React, {Fragment, useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CheckAccess from "../../../Roles/CheckAccess";
import Cs from "../../../../../services/CommonService";
import StepActivity from "../../StepActivities/StepActivities";
import AddToFavourite from "../../../../Common/AddToFavourite"
import Tabs, { TabPane } from 'rc-tabs'
import {FormFieldTable, RenderData} from "../../../UserRoles/NotificationPill"

const Activity = (props) => {
  const { state: authState, dispatch } = useContext(AuthContext)

  let activity = props.activity;
  activity.updated_at = Cs.formatUpdateDate(activity.updated_at)
  let data = activity.data;
  let data_source = activity.data_source;
  let ds = props.dataSource.college_cat.options
  let dsource ={}
  const cardStyle = {backgroundColor: (data.background_color || data.header_background || "#607D8B")}

  if (!data) return null;

  let userRoleLink = {
    pathname: `/journey/users/${activity.journey_category_id}/${activity.education_journey_profile_id}/edu_team_journey.activity/${activity.id}`,
    state: { journey: props.journeyCategory, activity: activity },
  }

  let incomingMailLink = {
    pathname: `/journey/incoming/emails/edu_team_journey.activity/${props.activity.id}`,
    state: { journey: props.journeyCategory, activity: props.activity },
  }

  const list = (key) => {
    return <div>{ds[key] && ds[key].label}</div>;
  }

  const category = (key) => {
    return (<div>
      <i className={dsource[key].icon + ' m-r-10'} style={{color:dsource[key].icon_color}}></i>
       {dsource[key].label}
      </div>
    )
  }

  const MailTag = () => (
    <a href={`mailto: ${activity.id}-team_activity@reply.cuetree.com?subject=Education Activity-${data.name}-${activity.record_id}`}
      target="_blank">
      <i className="far fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Favourite = () =>(
    <AddToFavourite
      favouriteId={activity.favourite_id}
      favorableType="edu_college_journey.activity"
      favorableId={activity.id}
      journeyCategoryId={activity.journey_category_id}
      classNameActive="pin-40 green" classNameDisable="pin-40 white"
      journeyProfileId={activity.education_journey_profile_id}/>
  )

  /*const Footer = () => (
    <div className="card-footer">
      <div className="pull-left lgrey">
        <span
          className="m-l-25"
          id={`comment_${props.index}`}
          onClick={(e) => {
            props.addNotes(e, activity)
          }}
        >
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge up bg-lgrey">{activity.comments_count}</span>
        </span>
        <span
          className="m-l-25"
          id={`check_list_${props.index}`}
          onClick={(e) => {
            props.addChecklist(e, activity)
          }}
        >
          <i className="far fa-check-circle font-18 text-muted"></i>
          <span className="badge up bg-lgrey">{activity.check_list_count}</span>
        </span>
        <span className="m-l-25" id={`mail_${props.index}`}>
          <Link
            to={`/journey/incoming/emails/education_journal_entry/${activity.id}`}
          >
            <i className="far fa-envelope font-18 text-muted"></i>
            <span className="badge up bg-lgrey">{activity.mail_count}</span>
          </Link>
        </span>
        <span className="m-l-25 lred pos-relative bottom-3">
          <i className="fas fa-lightbulb font-16"></i>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  )*/

  const Footer = () => (
    <div className="card-footer flex justspacebetween aligncenter">
      <ul className="flex aligncenter">
        <li className="m-l-10 m-r-25" id={`comment_${props.index}`}
          onClick={(e) => {props.addNotes(e, activity)}}>
          <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
          <span className="badge2 up bg-lgrey">{activity.comments_count}</span>
        </li>
        <li className="m-r-25" id={`check_list_${props.index}`}
          onClick={(e) => {props.addChecklist(e, activity)}}>
          <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
          <span className="badge2 up bg-lgrey">{activity.check_list_count}</span>
        </li>
        <li className="m-r-25" id={`mail_${props.index}`}>
          <Link to={`/journey/incoming/emails/education_journal_entry/${activity.id}`}>
            <i className="far fa-envelope font-18 text-muted" data-tip="Mail"></i>
            <span className="badge2 up bg-lgrey">{activity.mail_count}</span>
          </Link>
        </li>

        {activity.empty_fields_count != 0 &&
          <li className="m-r-25">
            <Link to={`/highschool/activity/edit/${activity.journey_category_id}/${activity.education_journey_profile_id}/${activity.id}?form_wizard_schedule_id=0&form_template_id=${activity.activity_form_id}&item_id=${activity.id}&item_type=education_journal_entries`}>
              <i className="far fa-lightbulb font-18" data-tip="Suggestions"></i>
              <span className="badge2 up bg-lgrey">{activity.empty_fields_count}</span>
            </Link>
          </li>
        }
      </ul>
      <props.menu activity={activity} />
    </div>
  )

  const Header = ({}) =>(
    <Fragment>
      <div className="card-title-gray flex aligncenter p10 p-b-15" style={cardStyle}>
        <div className="w-100">
          <h4 className="fw700 card-heading truncate">{data.name || activity.activity_type}</h4>
          <p className="f12">since {activity.updated_at}</p>
        </div>
        <div className="menu-drop-icon">
          {props.showIcons && 
            <Fragment>
              <Link className="white expand p-top-0 pull-right" to={userRoleLink}>
                <i className="fa fa-users" data-tip="Users"></i>
              </Link>
              <Favourite />
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )

  const Card = () => {
    return(
      <div className="bg-white border-rounded-10 m-b-10">
        <Header/>
        <div className={`card-content p10 scroll-auto ${props.cardHeightCss}`}>
          <Tabs defaultActiveKey='1'>
            <TabPane tab="Details" key="1">
              <div className="b-tab-table active m-t-5">
                <div className="border-dashed-sm p-b-10 m-b-5">
                  {activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}
                </div>
                <FormFieldTable data={data} labelClass="f15"
                  editFormField={false}
                  formFields={props.formFields[activity.activity_form_id]} 
                  dataSource={data_source}/>
              </div>
            </TabPane>
            <TabPane tab="Steps" key="2">
              <StepActivity stepActivities={activity.step_activities} />
            </TabPane>
          </Tabs> 
        </div>
        <Footer/>
      </div>    
    )
  }

  const HonorCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header p-5"
          style={cardStyle}
        >
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto ht-350 p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    Grade
                    {activity.data_source.grade &&
                      activity.data_source.grade.label}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Name</strong>
                  <div>{data.name}</div>
                </td>

                <td>
                  <strong>Recognition</strong>
                  <div>{data.title_recognition}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Name</strong>
                  <div>{data.url}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const ResearchCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header  p-5"
          style={cardStyle}
        >
          <div className="font-16">
            {activity.data_source.research_pub_subject.label}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    {activity.data_source.grade &&
                      activity.data_source.grade.label}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.research_title}</div>
                </td>
                <td>
                  <strong>Subject</strong>
                  <div>{activity.data_source.research_pub_subject.label}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Notes</strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: Cs.subString(data.research_pub_notes),
                    }}
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>
        <Footer />
      </div>
    )
  }

  const TestingCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header p-5"
          style={cardStyle}
        >
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  {activity.data_source.grade &&
                    activity.data_source.grade.label}
                </td>
              </tr>

              <tr>
                <td>
                  <strong>
                    {activity.data_source.type_of_test &&
                      activity.data_source.type_of_test.label}
                  </strong>
                  <div>{data.score}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Date</strong>
                  <div>{data.start_date}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>
        <Footer />
      </div>
    )
  }

  const CourseCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header p-5"
          style={cardStyle}
        >
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  Grade
                  {activity.data_source.grade &&
                    activity.data_source.grade.label}
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Course Type</strong>
                  <div>
                    {activity.data_source.course &&
                      activity.data_source.course.label}
                  </div>
                </td>
                <td>
                  <strong>Name</strong>
                  <div>{activity.data.course_name}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  <strong>Level</strong>
                  <div>
                    {activity.data_source.course_level &&
                      activity.data_source.course_level.label}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const SummerCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header  p-5"
          style={cardStyle}
        >
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Grade</strong>
                  <div>{data.grade}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const AthleticsCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header p-5"
          style={cardStyle}
        >
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Grade</strong>
                  <div>{data.grade}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const AidCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header  p-5"
          style={cardStyle}
        >
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.name}</div>
                </td>
                <td>
                  <strong>Date</strong>
                  <div>{data.start_date}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Notes</strong>
                  <div dangerouslySetInnerHTML={{ __html: data.notes }}></div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const CompetitionCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header  p-5"
          style={cardStyle}
        >
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Grade {data.grade}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.name}</div>
                </td>
                <td>
                  <strong>Subject</strong>
                  <div>
                    {activity.data_source.edu_competition_subject &&
                      activity.data_source.edu_competition_subject.label}
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Notes</strong>
                  <div>{data.acad_recog_notes}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const ArtsCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header  p-5"
          style={cardStyle}
        >
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Time Commitment</strong>
                  <div>{list("time_commitment")}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Role</strong>
                  <div>{list("role")}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const CurriculumCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header  p-5"
          style={cardStyle}
        >
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Title</strong>
                  <div>{data.record_title}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Course Name</strong>
                  <div>{data.course_name}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Course</strong>
                  <div>{list("category")}</div>
                </td>
                <td>
                  <strong>Grade</strong>
                  <div>{list("grade")}</div>
                </td>
              </tr>

              <tr>
                <td>
                <strong>Notes</strong>
                  <div>{data.notes && data.notes }</div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const ClubCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header  p-5"
          style={cardStyle}
        >
          <div className="font-16">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Role</strong>
                  <div>{list("role")}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Club Type</strong>
                  <div>{list("club_type")}</div>
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const JobInternCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header p-5"
          style={cardStyle}
        >
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Department</strong>
                  <div>{list("department")}</div>
                </td>
                <td>
                  <strong>Name</strong>
                  <div>{data.org_name}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Url</strong>
                  <div>{data.org_url}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  const ExperienceCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header p-5"
          style={cardStyle}
        >
          <div className="font-16 ellipsis">
            {data.record_title || activity.activity_type}
            <i
              className="fas fa-expand pull-right white expand"
              onClick={(e) => props.routeActivity(activity)}
            ></i>
            <Favourite />
          </div>
          <div>{activity.updated_at}</div>
        </div>

        <div className={`${props.cardHeightCss} scroll-auto p-5`}>
          <table className="table card-table">
            <tbody>
            <tr>
                <td>
                <div>{activity.data.fj_edu_entry_type && category(activity.data.fj_edu_entry_type)}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Department</strong>
                  <div>{list("department")}</div>
                </td>
                <td>
                  <strong>Name</strong>
                  <div>{data.org_name}</div>
                </td>
              </tr>

              <tr>
                <td>
                  <strong>Url</strong>
                  <div>{data.org_url}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <StepActivity stepActivities={activity.step_activities} />
        </div>

        <Footer />
      </div>
    )
  }

  return <Card/>

  /*if (activity.activity_form_id == 967) {
    return <HonorCard />;
  } else if (activity.activity_form_id == 968) {
    return <ResearchCard />;
  } else if (activity.activity_form_id == 960) {
    return <TestingCard />;
  } else if (activity.activity_form_id == 970) {
    return <CourseCard />;
  } else if (activity.activity_form_id == 971) {
    return <SummerCard />;
  } else if (activity.activity_form_id == 961) {
    return <AthleticsCard />;
  } else if (activity.activity_form_id == 969) {
    return <AidCard />;
  } else if (activity.activity_form_id == 962) {
    return <CompetitionCard />;
  } else if (activity.activity_form_id == 964) {
    return <ArtsCard />;
  } else if (activity.activity_form_id == 959) {
    return <CurriculumCard />;
  } else if (activity.activity_form_id == 963) {
    return <ClubCard />;
  } else if (activity.activity_form_id == 966) {
    return <JobInternCard />;
  } else if (activity.activity_form_id == 965) {
    return <ExperienceCard />;
  } else {
    return null;
  }*/
}

export default Activity;