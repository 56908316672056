import React, { useState, useEffect } from "react";
import "./styles.css";
import ReactFlow, {
  updateEdge,
  addEdge,
  Background,
  MiniMap,
  Controls
} from "react-flow-renderer";

import { nodeTypes } from "./Nodes";

const ReactFlowRenderer = () => {
  const [elements, setElements] = useState([]);

  const [name, setName] = useState("");
  const [activeNode, setActiveNode] = useState();
  const [newName, setNewName] = useState("");
  const [instance, setInstance] = useState();

  useEffect(() => {
    if (activeNode) setNewName(activeNode.data.label);
  }, [activeNode]);


  const connectHandler = (params) => {
    setElements((prev) => addEdge(params, prev));
  };

  const addRectangleHandler = () => {
    const newNode = {
      id: `${Date.now()}`,
      data: { label: `${name}` },
      type: "rectangle",
      position: {
        x: 0,
        y: 0
      }
    };
    newNode.data = { ...newNode.data, id: `${newNode.id}` };

    setElements((prev) => {
      return [...prev, newNode];
    });
    setName("");
  };

  const addCircleHandler = () => {
    const newNode = {
      id: `${Date.now()}`,
      data: { label: `${name}` },
      type: "circle",
      position: {
        x: 0,
        y: 0
      }
    };
    newNode.data = { ...newNode.data, id: `${newNode.id}` };

    setElements((prev) => {
      return [...prev, newNode];
    });
    setName("");
  };

  const addTriangleHandler = () => {
    const newNode = {
      id: `${Date.now()}`,
      data: { label: `${name}` },
      type: "triangle",
      position: {
        x: 0,
        y: 0
      }
    };
    newNode.data = { ...newNode.data, id: `${newNode.id}` };

    setElements((prev) => {
      return [...prev, newNode];
    });
    setName("");
  };

  const addTextHandler = () => {
    const newNode = {
      id: `${Date.now()}`,
      data: { label: `${name}` },
      type: "text",
      position: {
        x: 0,
        y: 0
      }
    };
    newNode.data = { ...newNode.data, id: `${newNode.id}` };

    setElements((prev) => {
      return [...prev, newNode];
    });
    setName("");
  };

  const edgeUpdateHandler = (oldEdge, newConnection) =>
    setElements((els) => updateEdge(oldEdge, newConnection, els));

  const clickHandler = (e) => {
    var htmlString = e.target.outerHTML.toString();
    var index = htmlString.indexOf(` id="`);
    index += 5;
    const currentId = htmlString.substr(index, 13);

    elements.forEach((_current) => {
      if (_current.id === currentId) {
        setActiveNode(_current);
      }
    });
    // setNewName(activeNode.data.label)
  };

  const updateNodeHandler = () => {
    if (!activeNode) return;
    setElements(
      elements.map((_current) => {
        if (_current.id === activeNode.id) {
          return {
            ..._current,
            data: { label: newName, id: _current.data.id }
          };
        }

        return _current;
      })
    );
  };

  const onLoad = (reactFlowInstance) => {
    setInstance(reactFlowInstance);
    reactFlowInstance.fitView();
  };

  const saveChangesHandler = () => {
    console.log("current state", instance.getElements());
  };

  return (
    <div id="page">
       <div className="col-xs-12">
        <div className="col-xs-4">
          <div style={{display:"grid"}}>
            <input
              className="p-5 m-5"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Enter new node name"
            />

            <button type="button" className="p-5 m-5" onClick={addRectangleHandler}>
              Create Activity
            </button>

            <button type="button" onClick={addCircleHandler} className="p-5 m-5">
              Create Planner
            </button>

            <button type="button" className="p-5 m-5" onClick={addTriangleHandler}>
              Create Meetings
            </button>

            <button type="button" className="p-5 m-5" onClick={addTextHandler}>
              Add plain text
            </button>

            <input className="p-5 m-5"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              type="text"
              placeholder="replace text"
            />

            <button type="button" className="p-5 m-5" onClick={updateNodeHandler}>
              Update
            </button>
      

          <button type="button" className="p-5 m-5" onClick={saveChangesHandler}>
            Save changes
          </button>
          </div>
        </div>
        <div className="col-xs-8" style={{height: "75vh",border: "1px solid black"}} >
          <ReactFlow
            elements={elements}
            onConnect={connectHandler}
            deleteKeyCode={8 || 46}
            onEdgeUpdate={edgeUpdateHandler}
            nodeTypes={nodeTypes}
            snapToGrid={true}
            snapGrid={[16, 16]}
            connectionLineStyle={{ stroke: "black", strokeWidth: 2 }}
            onDoubleClick={clickHandler}
            onInit={onLoad}
          > 
            <Controls />
          </ReactFlow>
        </div>
      
      </div>
    </div>
  );
};

export default ReactFlowRenderer;