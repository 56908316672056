import React, {useState, Fragment} from "react";
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import JourneyPlanner from '../../../../../components/Journey/TeamJourney/Planners/List/JourneyPlanner';
import JourneyPlannerService from "../../../../../components/Journey/TeamJourney/Planners/JourneyPlannerService";
import TeamActivityService from "../../Activities/TeamActivityService";
import Pagination from '../../../../Pagination'
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import FormTemplateId from '../../../../../constants/FormTemplateList';
import CommentsModal from "../../../../Modals/CommentsModal";
import CardMenu from "../../../../Modals/CardMenu"
import {Link} from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import NotesModal from "../../../../Modals/NotesModal";
import MenuModal from "../../../../Modals/Menu";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import useStyle from '../../../../../hooks/useStyle';
import FilterModal from "../../Planners/List/JourneyFilterModal";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import CommonService from '../../../../../services/CommonService';
import PreLoadSpinner from "../../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../JourneyTitle/JourneyTitle";
import SideMenuBar from "../../../../../components/Common/SideMenuBar";
import {NavIcon,CardMenuInfo,CardMainMenu} from '../../../../Common/MenuItem'

let requestParams = {}
let selectedJourneyPlanner = {};
let data_source = {};
let selectedPlanner = {};

const JourneyPlannerList = (props) => {
  useStyle('card');
  useStyle('card_table');
  let params = props.match.params;
  let project = props.location.state;

  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [journeyPlannerList, setJourneyPlannerList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal();

  React.useEffect(() => {
    requestParams = {
      'data':{},
      'data_source_params':{
        "data_source_id":[3241,3242],
        "response_type":"object"
      },
      'page':currentpage,
      'paginate':1,
      'per_page':10,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'search':props.searchString
    }

    getPlannerList();
  }, [props.searchString, currentpage]);

  let getPlannerList = (filter) => { 
    if(filter){
      requestParams.data.competition_activity_array = filter.team_planner_topics;
      requestParams.data.topic_area_array = filter.premade_team_tile_events;  
    }
    JourneyPlannerService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       data_source = res.data.data_source_list;
       requestParams.totalItems = res.data.meta.total;
       setJourneyPlannerList(res.data.team_planner_lists);
      }
    })
  }

  const showMenu = (e, planner) => {
    selectedPlanner = planner;
    toggleMenuModal(e);
  }

  const clonePlanner = (e) => {
    e.preventDefault();
    if(selectedPlanner.data.is_teambook==1){
      createTeamBook(selectedPlanner);
    }else{
      createActivity(selectedPlanner);
    }
    toggleMenuModal(e);
  }

  const createTeamBook = (planner) => {
    let req = {data:{}, draft:true}
    req.education_journey_profile_id = params.journey_proile_id;
    req.journey_category_id = params.journey_category_id;
    req.activity_type = 'team_book';
    req.activity_form_id = 259;
    let data = Object.assign({}, planner.data);
    delete data.competition_activity;
    delete data.is_teambook;
    delete data.topic_area;
    req.data = data;
    req.planner_list_id = planner.id;
    TeamActivityService.create(req).then((res)=>{
      props.history.push(`/team/activity/${params.journey_category_id}/${params.journey_proile_id}`);
    })
  }

  const createActivity = (planner) => {
    let req = {data:{}, draft:true}
    req.education_journey_profile_id = params.journey_proile_id;
    req.journey_category_id = params.journey_category_id;
    req.activity_type = 'team';
    req.activity_form_id = 257;
    let data = Object.assign({}, planner.data);
    delete data.competition_activity;
    delete data.is_teambook;
    delete data.topic_area;
    req.data = data;
    req.planner_list_id = planner.id;
    TeamActivityService.create(req).then((res)=>{
      props.history.push(`/team/activity/${params.journey_category_id}/${params.journey_proile_id}`);
    })
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <CardMenuInfo formId={item.planner_form_id} recordId={item.record_id} 
          createdAt={item.created_at} />

        <a href="#" onClick={e=>clonePlanner(e)}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Copy to Activity tab</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ planner }) => (
    <CardMainMenu activity= {planner} showMenu={showMenu} />
  )  

  /*if(journeyPlannerList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const journeyPlannerCard =  journeyPlannerList && journeyPlannerList.map((p, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
      <JourneyPlanner journeyPlanner={p} dataSources={data_source} menu={Menu}/>
      {selectedPlanner.id == p.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={selectedPlanner} MenuItems={menuItems}/>
      }
    </div>
  ))
  
  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-md-4 pull-left">
          <JourneyTitle title={"Journey Planners"} project={project} />
        </div>
        <div className="col-md-8 pull-right">
          <NavIcon id="filter_items" dataTip="Filter Items" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              onSelect={toggleFilterModal} icon="fas fa-sliders-h"/>

          <NavIcon id="back_home" dataTip="Back to Journey Home" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              link={`/team/project/portal/${params.journey_category_id}`}
              icon="fas fa-home"/>
        </div>
        <ReactTooltip place="left" />
      </div>

      <div className="page-content row">
        {journeyPlannerCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      {isFilterOpen && <FilterModal filterData={requestParams.data} filterDataSources={data_source} isOpen={isFilterOpen} toggleModal={toggleFilterModal} onSearch={getPlannerList}/>}
      <SideMenuBar helpId="2"/>
    </Fragment>
  )

};

export default JourneyPlannerList;