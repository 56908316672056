import React, {useState,useEffect, Fragment} from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import FormTemplateId from '../../constants/FormTemplateList';
import useStyle from '../../hooks/useStyle'
import useEscape from '../../hooks/useEscape';

const ProjectMenu = ({ isOpen, toggleModal, item,addChecklist,addNotes,addDelete,type, MenuItems, menuHeight, menuCss}) => { 
    useStyle('action_sheet');
  
    if(!isOpen) return null;
    
    const menuStyle = {height: (menuHeight || '330px'), opacity: 1, display: 'block', overflowY: 'scroll'}

    return(
      <div className={`menu-inline menu-box-bottom menu-box-detached round-medium menu-active ${menuCss}`} style={menuStyle}>
        <div className="ml-center">
          <span className="ml-button ml-hover-red color-red2-dark font-20" title="Close Modal" 
            style={{position: 'absolute',right: '0px',top: '3px'}} onClick={e=>toggleModal(e)}>
              <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
        <div className="link-list link-list-1 content bottom-0 m-t-25">
          {MenuItems && <MenuItems item={item}/>}
        </div>
      </div>
    )
}

export default ProjectMenu;