import React, { useState, Fragment } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

// import Swiper core and required modules
import SwiperCore, {Navigation,Keyboard, Autoplay, Pagination} from 'swiper/core';
// install Swiper modules
SwiperCore.use([Navigation,Keyboard, Autoplay,Pagination]);

const SwiperContainer = ({children, currentpage, setCurrentpage, requestParams, indexEle, cardViewCount, autoHeight}) => {

  const reachBeginning = () =>{
    console.log('begining');
    console.log(currentpage);
    /*(currentpage > 1) && setCurrentpage(currentpage - 1)*/
  }

  const reachEnd = () =>{
    console.log('end');
    console.log(currentpage);
    const totalPage = Math.ceil(requestParams.totalItems / requestParams.per_page);
    (currentpage < totalPage) && setCurrentpage(currentpage + 1) 
  }

  const activeIndexChange = (swiper) =>{
    console.log('index');
    console.log(swiper);
    //setIndex(swiper.activeIndex + 1)
    if(indexEle)indexEle.current.innerText = swiper.snapIndex + 1
    //(currentpage > 1) && setCurrentpage(currentpage - 1)
  }

  return(
    <Swiper slidesPerView={cardViewCount}  grabCursor={true}
      slidesPerGroup={cardViewCount} keyboard={{"enabled": true}}
      navigation={true} className="mySwiper"
      roundLengths={true}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      onReachEnd={() => reachEnd()}
      onReachBeginning={() => reachBeginning()}
      onBeforeTransitionStart={(swiper) => activeIndexChange(swiper)}>
      {children}
    </Swiper> 
  )

}

const SwiperInfiniteLoopContainer = ({children, cardViewCount, autoHeight}) => {
  return (
    <Fragment>
      <Swiper slidesPerView={cardViewCount} spaceBetween={30} 
      slidesPerGroup={cardViewCount} loop={true} loopFillGroupWithBlank={true} 
      pagination={{"clickable": true}}
      autoplay={{
        "delay": 2500,
        "disableOnInteraction": true
      }}
      autoHeight={autoHeight || false}
      className="mySwiper">
        {children}
      </Swiper>
    </Fragment>
  )

}

export {SwiperContainer, SwiperSlide, SwiperInfiniteLoopContainer};