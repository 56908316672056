import React, {useState, useEffect,Fragment} from "react";
import {Link} from "react-router-dom";
import ProjectMenu from "../../../Modals/ProjectMenu";
import useModal from '../../../../hooks/useModal';
import ConfirmMenu from '../../../Modals/ConfirmMenu'
import PortalHeader from "../HighSchoolPortalPage/PortalHeader";
import GradePath from "./GradePath";
import JourneyCategoryService from "../../JourneyCategoryService";
import useStyle from '../../../../hooks/useStyle';
import CheckAccess from '../../Roles/CheckAccess';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import JourneyTitle from "../../../Journey/JourneyTitle/JourneyTitle";
import CollegeAdmissionCueMeService from "./CollegeAdmissionCueMeService";
import {NavIcon,CardMainMenu} from '../../../Common/MenuItem'
import {HighSchoolLink} from "../../../Common/RouteLinks"

const GradePathPortal = (props) => {
  useStyle('path');

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  let params = props.match.params;
  let project = props.location.state;

  const [path, setPath] = useState(null);
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  const deleteProject = (e) => {
    e.preventDefault();
    JourneyCategoryService.delete(project).then((res) => {
      if (res.status == 204) {
        props.history.push('journey');
      }
    })
    toggleDeleteModal(e);
  }

  const toggleChatlistStatus = (e) => {
    e.preventDefault();
    project.is_chatlist_enabled = !project.is_chatlist_enabled;
    JourneyCategoryService.update(project).then((res) => {
      if (res.status == 204) {}
    })
  }

  useEffect(() => { 
    let req = {
      'journey_category_id':params.journey_category_id,
      'education_journey_profile_id':params.journey_profile_id,
      'data_source_params':{
        "data_source_id":[3332, 2214],//Individual journey data_source
        "response_type":"object"
      }
    }

    CollegeAdmissionCueMeService.get(req).then((res)=>{
     if(res.status == 200){
       res = res.data;
       res.journey_category = res.journey_category;
       setPath(res.data_source_list);
      }
    })
  }, []);

  const routePath = (e, path) => {
    e.preventDefault();
  }

  const showMenu = (e, a) => {
    toggleMenuModal(e);
  }

  const Menu = ({ activity}) => (
    <CardMainMenu activity= {activity} showMenu={showMenu} />
  )

 const menuItems = ({item}) =>{
   return(
    <Fragment>
      <Link to={`/journey_profile/${item.education_journey_profiles.id}?form_id=${item.education_journey_profiles.form_id}`}>
        <i className="far fa-edit"/> Edit
      </Link>
      <a href="#" onClick={e => {toggleDeleteModal(e, item)} }>
        <i className="far fa-trash-alt"/> Delete
      </a>
      <Link to={`/invite/user/${item.id}/${item.education_journey_profiles.id}`}>
        <i className="fa fa-user"></i> Invite User
      </Link>
      <a href="#">
        <i className="fas fa-info-circle"></i> Help
      </a>
      <a href="#" onClick={e=>toggleChatlistStatus(e)}>
        <i className={item.is_chatlist_enabled?'far fa-check-circle':'far fa-circle'}></i> Share chat
      </a>
    </Fragment>
   )
 }

  if(!path)return(
    <PreLoadSpinner/>
  )

  return (
    <React.Fragment>
    <div className="content top-10 clearfix">
        <div className="col-xs-8">
        <div>
    	<div className="pull-left font-16 bold-600">
  	   		{project && project.education_journey_profiles.data.fj_name_of_family_member }
   		</div><br/>
    	<div className="pull-left font-15">{"Grade Path"}</div> 
  	</div>
        
        </div>
        <div className="col-xs-4"> 
        <Menu/>
        <NavIcon id="home_icon" dataTip="Back to Journey Home" 
          link={HighSchoolLink.hs_portal(params)}
          className="bg-highlight round-btn float-right m-r-5 m-t-4" 
          icon="fas fa-home"/>
        </div>      
      <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} item={project} type="grade_path" MenuItems={menuItems} menuCss="top-40"/>
      </div>
      <div className="col-xs-12">  
        <GradePath path={path.path_nodes_high_school_journey} routePath={routePath}/>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteProject}/>
    </React.Fragment>
  )
};

export default GradePathPortal;