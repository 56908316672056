import React, {useState, useEffect, Fragment, useRef} from 'react';
import querystringify from "querystringify"
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Link} from "react-router-dom";
import JourneyCategoryService from "../../JourneyCategoryService"
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import DragObject from "./DragObject"
import JourneyTitle from "../../JourneyTitle/JourneyTitle"
import AddEvent from "./AddEvent"
import useModal from '../../../../hooks/useModal'
import useStyle from '../../../../hooks/useStyle'
import SideMenuBar from "../../../../components/Common/SideMenuBar";
import {NavIcon} from '../../../Common/MenuItem'
import Cs from "../../../../services/CommonService";
import {HighSchoolLink} from "../../../Common/RouteLinks"

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
const DragAndDropCalendar = withDragAndDrop(Calendar)
let data_sources = {};
let newEvent = null;

let HighSchoolSchedules = (props) => {
  useStyle('add_role_dialog');

  let events = [];
  let params = props.match.params;
  let journey = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
  const [data, setData] = useState({events: [], displayDragItemInCell: true,});
  const [isNewEventPopupOpen, setNewEventPopupOpen] = useState(false);

  useEffect(() => {
    let req = {
      'journey_type':params.journey_type,
      'journey_category_id':params.journey_category_id,
      'education_journal_entry_params':{
        'page': 1,
        'paginate': 1,
        'per_page': 10,
        'sort_column': 'updated_at', 
        'sort_direction': 'desc',
        'journey_category_id': params.journey_category_id,
        'education_journey_profile_id': params.journey_profile_id
      }
    }

    if(queryParam.filter!='all'){
      req.data_source_params = {
        'response_type':'object', 
        'data_source_id': 2214
      }
    }

    JourneyCategoryService.getSchedules(req).then((res)=>{
      if(res.status==200){
        data_sources = res.data.data_source_list;
        res.data.education_schedules.forEach((e, i)=>{
         /* e.start = Cs.stringToDate(e.start);
          e.end =  Cs.stringToDate(e.end || e.start);*/
          if(e.start){
            events.push({
              'id':e.id,
              'title':e.title,
              'item_type':e.item_type,
              'item_id':e.item_id,
              'start': Cs.stringToDate(e.start),
              'end': Cs.stringToDate(e.end || e.start),
              'allDay':true
            })
          }
        })
        data.events = events;
        setData({...data});
        /* config.events = res.data.education_schedules;
        $scope.activities= res.data.activities;
        $scope.journalEntryTypes= res.data.data_source_list[0].options;*/
      }
    })
    
    setAgendaLabel();

  }, []);

  const setAgendaLabel = () =>{
    setTimeout(function() {
      let calendarEl = document.querySelector("div.rbc-toolbar > span:nth-child(3) > button:nth-child(4)");
      if(calendarEl){
        calendarEl.innerText = 'Upcoming'
      }else{
        setAgendaLabel();
        /*console.log('--------------')*/
      }
    }, 1000);
  }

  //if(data.events.length==0)return null;

  const handleDragStart = (e, drag_item) => {
    /*let item = {
      'id':e.currentTarget.getAttribute('data-id'),
      'data_type':e.currentTarget.getAttribute('data-type'),
      'title':e.currentTarget.getAttribute('activity-type'),
      'activity_type':e.currentTarget.getAttribute('activity-type'),
      'activity_form_id':e.currentTarget.getAttribute('activity-form-id'),
    }*/
    let item = {
      'id':drag_item.value,
      'data_type':'education_journal_entry',
      'title':drag_item.label,
      'activity_type':drag_item.label,
      'activity_form_id':drag_item.child_form
    }
    data.draggedEvent = item;
    setData({...data})
  }

  const dragFromOutsideItem = () => {
    return data.draggedEvent
  }

  const onDropFromOutside = ({ start, end, allDay }) => {
    const { draggedEvent } = data;

    let event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay: allDay,
    }

    let req = {
      start_date: moment(start).format('YYYY-MM-DD'),
      start_date_string: moment(start).format('YYYY-MM-DD'),
      data:{'title':event.title},
      journey_category_id: params.journey_category_id,
      journey_category_type : params.journey_type
    }
    
    req.schedule_item_id = 'new'
    req.schedule_item_type = draggedEvent.data_type
    req.education_journey_profile_id = params.journey_profile_id
    req.journal_entry_data = {
      'fj_edu_entry_type': parseInt(draggedEvent.id),
      'start_date': moment(start).format('MM/DD/YYYY'),
      'start_date_formatted_date': req.start_date,
    }
    req.activity_form_id = draggedEvent.activity_form_id
    req.activity_type = draggedEvent.activity_type
    req.data.sub_type = parseInt(draggedEvent.id);

    JourneyCategoryService.createSchedule(req).then((res)=>{
      if(res.status==201){
        let d = res.data;
        event.id = d.id;
        event.title = d.data.title;
        event.item_id = d.schedule_item_id;
        event.item_type = d.schedule_item_type;
        event.data = d.data;
        event.activity_form_id = res.config.data.activity_form_id;
        event.is_new = true;
        setData({ ...data })
        moveEvent({ event, start, end })
      }
    })

    data.draggedEvent = null;
    
  }

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { events } = data;

    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    data.events = nextEvents;
    setData({...data})
    if(!event.is_new)updatedEvent(event, start, end);

    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  const resizeEvent = ({ event, start, end }) => {
    const { events } = data

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    setData({
      events: nextEvents,
    })

    updatedEvent(event, start, moment(end).subtract(1, 'days'))
    //alert(`${event.title} was resized to ${start}-${end}`)
  }

  const updatedEvent = (event, start, end) =>{
    let req = {
      'id':event.id,
      'start_date':moment(start).format('YYYY-MM-DD'),
      'start_date_string':moment(start).format('YYYY-MM-DD'),  
      'start_date_formatted_date':moment(start).format('YYYY-MM-DD'), 
      'schedule_item_id':event.item_id,
      //'schedule_item_type':event.item_type
    }
    if(event.end){
      req.end_date = moment(end).format('YYYY-MM-DD');
    }

    if(params.journey_type == 'college'){
      
    }else if(params.journey_type == 'education'){
      req.fj_edu_update_reminder = moment(end).format('MM/DD/YYYY');
      req.fj_edu_update_reminder_formatted_date = req.end_date;
    }else if(params.journey_type == 'training'){

    }

    JourneyCategoryService.updateSchedule(req).then((res)=>{
      if(res.status==204){
        
      }
    })
  }

  /*const newEvent = (event) => {
    // let idList = this.state.events.map(a => a.id)
    // let newId = Math.max(...idList) + 1
    // let hour = {
    //   id: newId,
    //   title: 'New Event',
    //   allDay: event.slots.length == 1,
    //   start: event.start,
    //   end: event.end,
    // }
    // this.setState({
    //   events: this.state.events.concat([hour]),
    // })
  }*/

  const eventStyleGetter = (event, start, end, isSelected) => {
    //var backgroundColor = data_sources.fj_edu_entry_type.options[event.title].icon_color;
    var style = {
        //backgroundColor: backgroundColor,
        color:'#fff',
    }
    return {
      style: style
    }
  }

  const onSelectEvent = (event) =>{
    if(event.item_type == 'education_journal_entry'){
      props.history.push(`/highschool/activity/${params.journey_category_id}/${params.journey_profile_id}?id=${event.item_id}`)
    }else if(event.item_type == 'education_activity' ){
      props.history.push(`/highschool/activity/${params.journey_category_id}/${params.journey_profile_id}?id=${event.item_id}`)
    }
  }

  const onSelectSlot = (slotInfo)=>{
    newEvent = {
      'schedule_item_id': params.journey_profile_id, 
      'schedule_item_type': 'education_journey_profile',
      'journey_category_id': params.journey_category_id,
      'journey_category_type' : params.journey_type,
      'start_date': moment(slotInfo.start).format("YYYY-MM-DD"),
      'data':{}
    }
    setNewEventPopupOpen(true)
  }

  const addNewEvent = (e, event)=>{
    JourneyCategoryService.createSchedule(event).then((res)=>{
      if(res.status==201){
        let e = res.data;
        if(e.start_date){
          /*e.start = Cs.stringToDate(e.start_date)
          e.end = e.end_date?Cs.stringToDate(e.end_date):e.start; */
          let d = {
            'id':e.id,
            'title':e.data.title,
            'start': Cs.stringToDate(e.start_date),
            'end': Cs.stringToDate(e.end_date || e.start_date),
            'allDay':true,
            'item_id':e.item_id,
            'item_type':e.item_type
          }
          //setData([d].concat(data))
          setData([d, ...data.events])
        }     
      }
    })
    setNewEventPopupOpen(false)
  }
  
  const CustomEvent = (event) => { 
    return ( 
      <span>
        {event.title} 
        <i className="fab fa-google pull-right" onClick={e=>Cs.addToCalendar(e, event, 'google')}></i>
        <i className="fab fa-yahoo m-r-15 pull-right" onClick={e=>Cs.addToCalendar(e, event, 'yahoo')}></i>
        <i className="fab fa-microsoft m-r-15 pull-right" onClick={e=>Cs.addToCalendar(e, event, 'office365')}></i>
      </span> 
    ) 
  }

  const routePortalPage = () =>{
    let u = ''
    if(params.journey_type == 'college'){
      u = `/college/portal/${params.journey_category_id}`
    }else if(params.journey_type == 'education'){
      u = HighSchoolLink.hs_portal(params)
    }else if(params.journey_type == 'training'){
      u = `/career_training/project/portal/${params.journey_category_id}`
    }
    props.history.push(u)
  }

  return(
    <Fragment>
      {queryParam.filter!='all' &&
        <div className="content top-10">
          <div className="col-md-4 pull-left">
            <JourneyTitle title={"Calendar"} project={journey} />
          </div>
          <div className="col-md-8 pull-right">
            <NavIcon id="home_icon" dataTip="Back to Journey Home" 
              onSelect={e => routePortalPage()}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-home"/>
          </div>
          <div className="clear"></div>
        </div>}

      {params.journey_type == 'education' && data_sources &&
        <div className="p-5 d-flex scroll-x m-t-15">
          {data_sources.fj_edu_entry_type && data_sources.fj_edu_entry_type.options && 
            Object.values(data_sources.fj_edu_entry_type.options).map((o, k) =>
              <DragObject item={o} handleDragStart={handleDragStart} key={k} bgColor="header_color"/>
            )
          }
        </div>
      }

      <div className="p-5 bg-white">

        <DragAndDropCalendar
          localizer={localizer}
          events={data.events}
          startAccessor="start"
          endAccessor="end" 
          onEventDrop={moveEvent}
          resizable
          onEventResize={resizeEvent}
          onDragStart={console.log}
          dragFromOutsideItem={data.displayDragItemInCell ? dragFromOutsideItem : null}
          onSelectEvent={(event)=>onSelectEvent(event)}
          onDropFromOutside={onDropFromOutside}
          handleDragStart={handleDragStart}
          onSelectSlot={(slotInfo)=>onSelectSlot(slotInfo)}
          selectable="true"
          eventPropGetter={eventStyleGetter}
          components={{event: CustomEvent}}/>
      </div>

      {isNewEventPopupOpen &&
        <AddEvent newEvent={newEvent} isOpen={isNewEventPopupOpen} toggleModal={setNewEventPopupOpen} addNewEvent={addNewEvent}/>
      }
      <SideMenuBar helpId="6"/> 
    </Fragment>
  )
}

export default HighSchoolSchedules;