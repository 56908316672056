import React, { memo, Fragment, useState, useEffect } from 'react';
import { Handle } from 'react-flow-renderer';
import {Link} from "react-router-dom";

const AddDataSource =  memo(({ data, isConnectable }) => {
  return (
    <>
      <div>
        {data.interview(data)}
        <Handle type="target" position={data.targetPosition} style={{ borderRadius: 0 }} />
        <div className="datasource-node lgrey">
          {data.label}
          <div className="font-14 bold black">{data.subTitle}</div>
        </div>
      </div>

      <Handle type="source"  position={data.sourcePosition} id="a" style={{borderRadius: 0 }}/>
    </>
  )
})

const AddFutureCareer =  memo(({ data, isConnectable }) => {
  return (
    <>
      <div className="wk-center" style={{border: '1px solid'}}>
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <span>
          <span className="pull-left p-5 fas fa-search font-14 bg-highlight white" onClick={e=>data.routeCareerDetails(data)}/>
          <span className="black p-5">{data.label || '-'}</span>
          <span className="pull-right p-5 fas fa-plus font-14 bg-highlight white" onClick={e=>data.routeActivityForm(data)}/>
        </span>
      </div>
      <Handle type="source" position="bottom" id="a" style={{borderRadius: 0 }}/>
    </>
  )
})

const SuggestedActivityList = ({ activities, activityColor, activityIcon, cloneActivity }) => activities.map((data, i)=>{
  data.icon = activityIcon[data.planner_form_id]
  data.bg_color = activityColor[data.planner_form_id]
  return (
    <>
      <span key={i} className="fa-stack fa-2x m-t-5">
        <i className="fas fa-circle fa-stack-2x"></i>
        <i className={`fa-stack-1x fa-inverse ${data.icon}`}></i>
      </span>
      <div>{data.data.title || data.data.record_title}</div>
      <div className="col-xs-12">
        <div className="text-center col-xs-6">
          <span onClick={e=>cloneActivity(data)} className="fas fa-check green"/>
        </div>
        <div className="text-center col-xs-6">
          <span className="fas fa-times-circle lred"/>
        </div>
      </div>
    </>
  )
})

const AddSuggestedActivity = ({ data, isConnectable }) => {
  const {activities, activityColor, activityIcon, cloneActivity} = data

  return (
    <>
      <div className="wk-center" style={{width:"200px", height:"20px"}}>
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <SuggestedActivityList {...{activities, activityIcon, activityColor, cloneActivity}} />
      </div>
    </>
  )
}

const PaginationSmall = (props) => {
  if(!props.totalItems || !props.itemsPerPage)return null;

  const nextPageClick=() =>{
    props.setCurrentpage(props.currentpage + 1)
  }
 
  const prevPageClick=() =>{
    props.setCurrentpage(props.currentpage - 1)
  }
 
  const totalPage = Math.ceil(props.totalItems / props.itemsPerPage);

  if(totalPage.length==0){
    return null;
  }
  
  return (
    <div className="pagenate-parent">
      {props.currentpage > 1 && <span className="fas fa-chevron-circle-left pagenate-left" onClick={e=>prevPageClick()}/>}
      <span className="badge bg-lred pagenate-text">{props.currentpage} / {totalPage}</span>
      {props.currentpage < totalPage && <span className="fas fa-chevron-circle-right pagenate-right" onClick={e=>nextPageClick()}/>}
    </div>
  )
}

const ActivityList = ({ activities, activityColor, activityIcon, openActivity }) => activities.map((data, i)=>{
  data.icon = activityIcon[data.activity_form_id]
  data.bg_color = activityColor[data.activity_form_id]
  const nodeStyle = {borderWidth:"1px 1px 1px 20px", borderColor:data.bg_color,borderStyle:"solid"}

  return (
    <Fragment key={i}>
      <div className="d-flex m-t-10" style={nodeStyle} onClick={e=>openActivity(data)}>
        <span className={`${data.icon} m-t-5 m-b-5 m-l-10 font-22 lgrey`}></span>
        <div className="" style={{margin:"auto"}}>{data.data.name || data.data.course_name || data.data.record_title}</div>
      </div>
    </Fragment>
  )
})

const AddActivity = ({id, data, isConnectable }) => {
  const {activities, meta, activityColor, activityIcon, openActivity, activityPaginate, careerProp} = data
  
  const [activitiesList, setActivitiesList] = useState([])
  const [pageMeta, setPageMeta] = useState(meta)
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    setActivities()
  }, [])

  const setActivities = () => setActivitiesList(activities);

  const setCurrentpage = (page) => {
    setLoading(true)
    activityPaginate(page, careerProp, onSuccess)
  }
    
  const onSuccess = (res) => {
    setActivitiesList([...res.activities])
    setPageMeta(res.meta)
    setLoading(false)
  }
  
  if(loading) 
    return <div className="m-l-15 spinner"/>

  return (
    <div className="br-grey p-10">
      <div className="wk-center" style={{width:"200px"}}>
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <ActivityList activities={activitiesList} {...{activityIcon, activityColor, openActivity}} />
      </div>
      <PaginationSmall totalItems={pageMeta.total} currentpage={parseInt(pageMeta.page)} 
        itemsPerPage="15" setCurrentpage={setCurrentpage}/>
      <Handle type="source" position="bottom" id="a" style={{borderRadius: 0}}/>
    </div>
  )
}

/*
const AddActivity =  memo(({ data, isConnectable }) => {
  return (
    <>
      <div className="wk-center" style={{width:"200px"}}>
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        <span onClick={e=>data.openActivity(data)}>
          <span className="fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x"></i>
            <i className={`fa-stack-1x fa-inverse ${data.icon}`}></i>
          </span>
          <div className="black">{data.label || '-'}</div>
        </span>
      </div>
      <Handle type="source" position="bottom" id="a" style={{borderRadius: 0 }}/>
    </>
  )
})
*/

export { AddActivity, AddDataSource, AddSuggestedActivity, AddFutureCareer};