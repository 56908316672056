import env from "../env";
import axios from "axios";
import moment from "../../node_modules/moment/min/moment.min.js";
import CheckAccess from "../components/Journey/Roles/CheckAccess";

export default class CommonService {
  static useAxios(req) {
    return new Promise((resolve, reject) => {
      axios(req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static getNotifications(req) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.family_tree_backend + "fj_notifications", { params: req })
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static createOrDeleteDataFilter(params){
    return new Promise((resolve, reject) => { 
      var u = env.backend+'data_filters'
      axios.post(u, params).then((res)=>{
          resolve( res );
      })
    })
  }

  /*static getDataSources(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'data_sources', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static searchDataSource(params){
    return new Promise((resolve, reject) => {	
    	var u = env.ecn_backend_api+'data_source_lists/search'
     	axios.post(u,params).then(function success(res){
     		if(res.status == 200){
       		resolve( res.data );
     		}else{
     			reject()
     		}
     	})
  	})
  }

  */

  static getDataSource(params) {
    return new Promise((resolve, reject) => {
      axios.post(env.backend + "form_templates/options", params).then((res) => {
          // handle success
        if(res.status == 200 && res.data.data_position){
          resolve(res.data);  
        }else if(res.status == 200){
          resolve(res.data.options);  
        }
      })
    })
  }

  static updateNotification(id, req) {
    return new Promise((resolve, reject) => {
      axios
        .put(env.family_tree_backend + "fj_notifications/" + id, req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static deleteNotification(req) {
    let url = "?delete_all=" + req.delete_all;
    return new Promise((resolve, reject) => {
      axios
        .delete(env.family_tree_backend + "fj_notifications/" + req.id + url)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static uploadFileToUrl(file, privacy_type, data, upload_type, url) {
    return new Promise((resolve, reject) => {
      var fd = new FormData();
      fd.append("file", file);
      fd.append("privacy_type", privacy_type);
      fd.append("content_type", file.type);  
      if (data) {
        for (const property in data) {
          fd.append(`${property}`, data[property]);
        }
      }

      var reader = new FileReader();
      var image = new Image();
      reader.readAsDataURL(file);

      reader.onload = function (_file) {
        image.src = _file.target.result;
        var postImageUploadurl = env.backend + (url || "file_uploads");

        if (this.width > 1000) {
          postImageUploadurl = postImageUploadurl + "?max_width=1000";
        }

        axios.post(postImageUploadurl, fd, {
            headers: { "Content-Type": undefined },
        }).then(function success(res) {
          try {
            if (upload_type != "comment")
              res.data.file_upload.url = res.data.file_upload.url.split("?")[0];
              resolve(res);
          } catch (e) {
              resolve(res);
          }
        })
      }
    })
  }

  static deleteFileFromUrl(url) {
    return new Promise((resolve, reject) => {
      var list = url.split("/");
      var id = list[6];
      axios
        .delete(`${env.backend}file_uploads/${id}`)
        .then((res) =>{
          resolve(res);
        }, (err)=>{
          resolve(err.response);
        })
        
    });
  }

  static getFileUrl(file) {
    return env.file_url + file;
  }

  static getIconByType(file, type) {
    if (file) {
      if (file.match(/\.(jpeg|jpg|gif|png|ico|svg|gif)$/) != null) {
        if (file.includes("selftree-public")) {
          return file;
        }else if(type == 'user_profile_image'){ 
          return env.file_url + env.profile_image_url + file;
        }else {
          return env.file_url + file;
        }
      } else if (file.match(/\.(pdf|PDF)$/)) {
        return "/images/pdf_icon.png";
      } else if (file.match(/\.(xls|XLXS|XLS|xlsx|ods)$/) != null) {
        return "/images/excel_icon.png";
      } else if (file.match(/\.(doc|docx|odt)$/)) {
        return "/images/doc_icon.jpg";
      } else if (file.match(/\.(ppt|pptx|odp)$/)) {
        return "/images/ppt.jpg";
      } else if (file.lastIndexOf(".csv") > 0) {
        return "/images/csv.jpg";
      } else if (file.lastIndexOf(".key") > 0) {
        return "/images/key.png";
      } else {
        return "/images/attachement-64.png";
      }
    }

    if (type == "team_journey") return "/images/avatars/2s.png";
    if (type == "hs_journey") return "/images/avatars/2s.png";
    if (type == "kids_journey") return "/images/avatars/2s.png";
    if (type == "mid_journey") return "/images/avatars/2s.png";
    if (type.includes('profile_image')) return "/images/avatars/2s.png";
  }

  static trimTitle(title) {
    if (title && title.length > 50) {
      return title.substring(0, 50) + "...";
    }
    return title;
  }

  static getFileName(url) {
    let n = url.lastIndexOf("/");
    let f = url.substring(n + 1);
    return f;
  }

  static getFormTemplateById(id) {
    return new Promise((resolve, reject) => {
      var u = env.backend + "form_templates/" + id;

      axios
        .get(u, { cache: true })
        .then(function (response) {
          resolve(response.data.form_template);
        })
    });
  }

  static getFormWizardFields(req) {
    return new Promise((resolve, reject) => {
      axios
        .post(env.backend + "form_wizard_fields/form_templates", req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static getFormWizardSchedules(form_wizard_schedule_id, req) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.backend + `form_wizard_schedulers/${form_wizard_schedule_id}`, { params: req })
        .then((res) => {
          resolve(res);
        })
    });
  }

  static removeUnusedCss() {
    document.head
      .querySelectorAll(`link[css-remove="true"]`)
      .forEach(function (a) {
        a.remove();
      });
    //document.querySelectorAll('[rel="stylesheet"]');
  }

  static loadCss(file_name) {
    const link = document.createElement("link");
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");
    link.setAttribute("href", `/styles/${file_name}.css`);
    link.setAttribute("css-remove", "true");
    document.head.appendChild(link);
  }

  static formatUpdateDate(date, format) {
    let f = format || "MMM DD, YYYY";
    if (date) return moment(date).format(f);
  }

  /*Safari Fix 
    split the date string*/
  static stringToDate(date){
    let s = date.split(/[^0-9]/);
    //for (i=0;i<a.length;i++) { alert(a[i]); }
    //new Date (s[0],s[1]-1,s[2],s[3],s[4],s[5])
    //endDate is considering the 0:00:00 time will display one day before so add 23 59 59
    return new Date (s[0],s[1]-1,s[2], '23', '59', '59');
  }

  static replace_content(content)
   {
    var exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    var element_content=content.replace(exp_match, "<a target='_blank' href='$1'>$1</a>");
    var new_exp_match =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
    var new_content=element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2">$2</a>');
    return new_content;
   }

  static extractHostName(url) {
    if (url && url.length > 0) {
      let u = document.createElement("a");
      u.href = url;
      return u.hostname;
    }
    return "";
  }

  static deactivate(current_admin) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${env.backend}organization_admins/${current_admin.id}/deactivate`)
        .then((res) => {
          resolve(res);
        })
    });
  }

  static updateSetting(setting) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.backend}settings/${setting.id}`, {'setting':setting})
        .then((res) => {
          resolve(res);
        })
    });
  }

  static groupBy(xs, key) {
    if(xs){
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});  
    }
  }

  static b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }

  static b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  }

  static subString(str) {
    return str && str.substring(0, 200);
  }

  static routeJourney(journey, props, return_path=false, current_user) {
    if(!CheckAccess.isDevUser(current_user.email, env) && !journey.is_demo_journey && !journey.subscription_id){
      if(current_user.id !== journey.created_by){
        alert('No subscription added')
        return
      }

      props.history.push({
        pathname:`/plans/${journey.id}`+(journey.journey_member_id?`/${journey.journey_member_id}`:''),
        search:'?journey_type='+(journey?.education_journey_profiles.data?.fj_type_edu_jou)
      })
      return
    }

    const currentGrade = journey.education_journey_profiles?.data?.fj_edu_current_grade
    const carrierInterests = journey.education_journey_profiles?.data?.interest 
   
    if (journey.edu_journey_type == 'edu_team_journey') {
      /*Team journey template*/
      props.history.push(
        `/team/project/portal/${journey.id}`
      )
    } else if (journey.edu_journey_type == 'edu_kids_journey') {
      /*Kids journey*/
    } else if (journey.edu_journey_type == 'mid_school_journey') {
      /*Mid School journey*/
    } else if (journey.edu_journey_type == 'high_school_journey') {
		  /*High School journey*/
      const p = {
        pathname:`/highschool/path/${journey.id}/${journey.education_journey_profiles.id}`, 
        search:`?current_grade=${currentGrade}&carrier_interests=${carrierInterests || ''}`,
        state:journey
      }
      if(return_path) return p;
      
      props.history.push(p)
            
    } else if (journey.edu_journey_type == 'academic_course') {
		  /*Course journey*/
  		props.history.push(
  			`/course/portal/${journey.id}`,
        journey
  		)
	  }else if (journey.edu_journey_type == 'college_journey') {
		  /*College journey*/
  		props.history.push(
  			`/college/path/${journey.id}/${journey.education_journey_profiles.id}`,
        journey
  		)
	  } else if (journey.edu_journey_type == 'training_journey') {
      /*High School journey*/
      props.history.push(
        `/career_training/project/portal/${journey.id}`,
        journey
      )
    }else if(journey.edu_journey_type == 'pre_college_journey'){
      props.history.push(
        `/college/admissions/${journey.id}/${journey.education_journey_profiles.id}`,
        {project:journey}
      )
    }else if(journey.edu_journey_type == 'career_journey'){
      props.history.push(
        `/career/path/${journey.id}/${journey.education_journey_profiles.id}`,
        journey
      )
    }
  }

  static journeyTitle(journey, journey_profile){
    const data = journey_profile?.data || {}
    if (['edu_team_journey', 'edu_kids_journey', 
      'mid_school_journey', 'high_school_journey',
      'college_journey'].includes(journey.edu_journey_type)) {
      return data.fj_name_of_family_member
    } else if (journey.edu_journey_type == 'academic_course') {
      return data.record_title
    }else if (journey.edu_journey_type == 'training_journey') {
      return data.journey_name
    }else if(journey.edu_journey_type == 'pre_college_journey'){
      return `Pre College - ${data.state}`
    }else if(journey.edu_journey_type == 'career_journey'){
      //return `Career - ${data.state}`
      return data.record_title
    }
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static linkify(inputText) {
    try {
      let replacedText, replacePattern1, replacePattern2, replacePattern3;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(
        replacePattern1,
        '<a href="$1" target="_blank">$1</a>'
      );

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank">$2</a>'
      );

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(
        replacePattern3,
        '<a href="mailto:$1">$1</a>'
      );

      return replacedText;
    } catch (e) {
      return inputText;
    }
  }

  static getFileUploads(req) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.backend + "file_uploads", { params: req })
        .then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  static getJourneyHyperLinks(req) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.family_tree_backend + "journey_links", { params: req })
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static getIncomingMails(req) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.backend + "incoming_mails", { params: req })
        .then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  static deleteIncomingMails(req) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${env.backend}incoming_mails/${req}`)
        .then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  static getRatingById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.family_tree_backend + "ratings/" + id)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static createRating(req) {
    return new Promise((resolve, reject) => {
      axios
        .post(env.family_tree_backend + "ratings", req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static updateRating(req, id) {
    return new Promise((resolve, reject) => {
      axios
        .put(env.family_tree_backend + `ratings/${id}`, req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static getNameFromEmail(email) {
    return email.split("@")[0];
  }

  static getInvitedUsers(req) {
    return new Promise((resolve, reject) => {
      axios
        .get(env.backend + "invite_users", { params: req })
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static inviteUser(req) {
    return new Promise((resolve, reject) => {
      axios
        .post(env.backend + "invite_users", req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static createDemoJourney(req) {
    return new Promise((resolve, reject) => {
      axios.post(env.backend + "invite_users/demo_journey", req).then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static sortItems(req) {
    return new Promise((resolve, reject) => {
      axios.post(env.backend + "sorted_items", req).then((res) => {
          // handle success
        resolve(res);
      })
    })
  }

  static updateRoleName(req, id) {
    return new Promise((resolve, reject) => {
      axios.put(env.backend + `update_role_name/${id}`, req).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }

  static findById(dataSources, permission_list_id){
    let componentList = null
    for (const d in dataSources) {
      const t = dataSources[d]
      if(t.id == permission_list_id){
        componentList = Object.values(t.options)  
      }
    }
    return componentList
  }

  static listToObj(list, key){
    return list && list.reduce((obj, item) => (obj[item[key]] = item, obj) ,{});
  }

  static getCardCount(screen){
    if(screen.xs){
      return 1
    }else if(screen.sm){
      return 2
    }else if(screen.md){
      return 3
    }else if(screen.lg){
      return 3
    }
  }

  static getRandomColor(){
    let color =  "#" + (Math.random() * 0xFFFFFF << 0).toString(16);
    return color;
  }

  static getEduSuggestions(req){
    return new Promise((resolve, reject) => {
      axios.get(env.family_tree_backend + "education_suggestions", { params: req }).then((res) => {
          // handle success
          resolve(res);
        })
    })
  }
  
  static getUsageReport(){
    return new Promise((resolve, reject) => {
      axios.get(env.backend + "payments/usage_report").then((res) => {
          // handle success
          resolve(res);
        })
    })  
  }
  
  static addToCalendar(ele, e, type){
    ele.stopPropagation()
    /*let e = {
      start_datetime: formatUpdateDate(), 
      end_datetime: formatUpdateDate(),
      title: "Ruby Conference",
      timezone: 'America/New_York',
      location: "20 W 34th St, New York, NY 10001", 
      url: "https://www.ruby-lang.org/en/",
      description: "Join us to learn all about Ruby.",
      add_url_to_description: true
    } */

    let url = null

    if(type == 'google'){
      let start_datetime = this.formatUpdateDate(e.slotStart, 'YYYYMMDD')
      let end_datetime = this.formatUpdateDate(e.slotEnd, 'YYYYMMDD')
      url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${e.title}&dates=${start_datetime}/${end_datetime}`
    }else if(type == 'yahoo'){
      let start_datetime = this.formatUpdateDate(e.slotStart, 'YYYYMMDD')
      url = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${e.title}&st=${start_datetime}`
    }else if(type == 'office365'){
      let start_datetime = this.formatUpdateDate(e.slotStart, 'YYYY-MM-DD')
      let end_datetime = this.formatUpdateDate(e.slotEnd, 'YYYY-MM-DD')
      url = `https://outlook.office.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&subject=${e.title}&startdt=${start_datetime}&enddt=${end_datetime}`
    }else if(type == 'outlook'){
      let start_datetime = this.formatUpdateDate(e.slotStart, 'YYYY-MM-DD')
      let end_datetime = this.formatUpdateDate( e.slotEnd, 'YYYY-MM-DD')
      // For outlook.com, different to Outlook the desktop application below
      url = `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&subject=${e.title}&startdt=${start_datetime}&enddt=${end_datetime}`
    }

    window.open(url);

  }

  static getFormAssessmentFields(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend + "carrier_training/form/step_assessments", { params: req }).then((res) => {
          // handle success
          resolve(res.data.step_assessments);
        })
    })  
  }

  static getUserForm(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend + 'form/user_forms', { params: req }).then((res) => {
          // handle success
          resolve(res.data.user_form);
        })
    })  
  }

  static updateDataSourceList(id, req){
    return new Promise((resolve, reject) => {
      axios.put(env.backend+'data_source_lists/'+id, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  static deleteDataSourceList(id){
    return new Promise((resolve, reject) => {
      axios.delete(env.backend+'data_source_lists/'+id).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }
  
  static getDataSourceList(req){
    return new Promise((resolve, reject) => {
      axios.get(env.backend+'data_source_lists', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
  static createDataSourceList(params){
    return new Promise((resolve, reject) => { 
      var u = env.backend+'data_source_lists'
      axios.post(u, params).then((res)=>{
          resolve( res );
      })
    })
  }

  static googleTranslate(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+"google_translate", req).then((res) => {
          // handle success
        resolve(res.data);
      })
    })  
  }

  static updateFormFieldLocale(req){
    return new Promise((resolve, reject) => {
      axios.put(env.backend+'form_field/update_locale', req).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  static postNewsletter(req) {
    return new Promise((resolve, reject) => {
      axios
        .post(env.backend + "newsletter_emails", req)
        .then((res) => {
          // handle success
          resolve(res);
        })
    });
  }

  static setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  static getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  static mapToList(mapList, key, resultList=[]){
    mapList.forEach((m)=> resultList.push[m[key]])

    return resultList
  }

  static getCueTips(req){
    return new Promise((resolve, reject) => {
      axios.post(env.family_tree_backend+'education_suggestions/cue_tips', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  static displayRandomPhrase(i){
    let r_text = [];
    r_text[0] = "Achieve differently";
    r_text[1] = "Shape your future";
    r_text[2] = "Transform learning experiences";
    r_text[3] = "Navigate learning journeys";
    return r_text[i]
  }
  
  static getQueryString(obj={}){
    let s = '?'
    Object.entries(obj).forEach(([key, value], idx) => {
      if(value != null)
        s += `${idx>0?'&':''}${key}=${value}`
    })
    return s
  }

  static isUrl = (string) => {
    try { return Boolean(new URL(string)); }
    catch(e){ return false; }
  }

  static scrollTo = (top=0, left=0) =>{
    window.scroll({
      top: top, 
      left: left, 
      behavior: 'smooth' 
    })
  }
}