import env from '../../env'
import axios from 'axios'
const credential_url = `${env.backend}zoom/credentials`
const url = `${env.backend}zoom/`

class ZoomMeetingService {
  
  get_credential(req){
    return new Promise((resolve, reject) => {
      axios.get(`${credential_url}/${req.id}`, { params: req}).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  create_credential(req){
    return new Promise((resolve, reject) => {
      axios.post(credential_url,req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  update_credential(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${credential_url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  delete_credential(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${credential_url}/${id}`).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  get_meeting(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}meetings/${id}`).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  get_meetings(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}meetings`, { params: req}).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  create_meeting(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}meetings`, req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  update_meeting(req){
    return new Promise((resolve, reject) => {
      axios.put(`${url}meetings/${req.id}`, req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  delete_meeting(id){
    return new Promise((resolve, reject) => {
      axios.delete(`${url}meetings/${id}`).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  get_meeting_details(id){
    return new Promise((resolve, reject) => {
      axios.get(`${url}meetings/${encodeURIComponent(encodeURIComponent(id))}/report`).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

}

export default new ZoomMeetingService();