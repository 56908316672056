import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import AuthenticationService from "../../services/AuthService";
import { Link } from "react-router-dom";
import NotificationPopup from '../Common/NotificationPopup'

const OrgAccountInactiveNotice = (props) => {
  let message = ` Thank you for your interest in the cueTree Education application.
  Your account is almost ready.
  A cueTree application representative will contact you to confirm your business
  information before your account can be activated.
  We look forward to serving your business.`

  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);

  const onSuccess = () =>{
    AuthenticationService.signout().then(()=>{
      dispatch({type: "LOGOUT"});
    })
  }

  return (
    <Fragment>
      <NotificationPopup header="Note" message={message} onSuccess={onSuccess}/>
    </Fragment>
  )
}

export default OrgAccountInactiveNotice;