import React, {useState, Fragment, useContext, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../../hooks/useStyle';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CollegeService from "../../Colleges/CollegeServices";
import College from "./College"
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal";
import CommonService from "../../../../../services/CommonService";
import querystringify from "querystringify";
import {NavIcon} from '../../../../Common/MenuItem'
import ReactTooltip from "react-tooltip";
import RatingView from "../../../../Common/RatingView"

let selectedCollege = null;

const CollegeCompareList = (props) => {
  useStyle('table');
  useStyle('card_table');

  const { state:{user:currentUser}, dispatch } = useContext(AuthContext);
  const [colleges, setColleges] = useState();
  const [formTemplate, setFormTemplate] = useState([]);
  const [loading, setLoading] = useState(true);

  //let params = props.match.params;
  let selectedCollegeIds = props.collegeCompareList;

  useEffect(() => {
    getCollege();
  }, []);

  let getCollege = () => { 
    setLoading(true)
    let req = {
      page:1, 
      per_page:10, 
      college_ids: selectedCollegeIds, 
      include_college_cue:true, 
      form_field_params:{
        form_template_id:1001, 
        component:'rating'
      }
    }
    CollegeService.search(req).then((res)=>{
      if(res.status == 200){
        setColleges(res.data.colleges)
        setFormTemplate(res.data.form_template)
        setLoading(false)
      }
    })
  }

  const CollegeData = () => formTemplate.map((f, k) =>{
    return(
      <tr key={k}>
        <CollegeTableData field={f}/>
      </tr>
    )
  })

  const CollegeTableHead = () => colleges.map((c, k) =>
    <th key={k}>{c.name}</th>
  )

  const CollegeTableData = ({field}) => colleges.map((c, k) =>{
    c.college_cues = c.college_cues || []
    const collegeCue = c.college_cues.find((r)=> r.form_id == 1001)

    return(
      <td className="lh-24" key={k}>
        {collegeCue?.data ? 
          <RatingView sumOfRating={collegeCue.data[field.client_id]} noOfRating="1"/> :'-'
        }
      </td>
    )
  })

  const CollegeAttributeList = ({}) => formTemplate.map((f, k) =>
    <tr key={k}>
      <td className="lh-24">{f.label}</td>
    </tr>
  )

  /*window.addEventListener("resize", function(event) {
    console.log(document.body.clientWidth + ' wide by ' + document.body.clientHeight+' high');
    console.log(screen)
  })*/

  return (
    <Fragment>
      {loading ? <div className="spinner"/> :
        <div className="page-content row d-inflex">
            <div className="row miw-150">
              <table>
                <thead>
                  <tr className="white bg-black">
                    <th>Rating</th>
                  </tr>
                </thead>  
                <tbody>
                  <CollegeAttributeList/>
                </tbody>
              </table>
            </div>
            <div className="row scroll-x" style={{width: (document.body.clientWidth-150)}}>
              <table style={{width: (colleges.length*250)+'px'}}>
                <thead>
                  <tr className="white bg-black">
                    <CollegeTableHead/>
                  </tr>
                </thead>
                <tbody>
                  <CollegeData colleges={colleges || []}/>
                </tbody>
              </table>
            </div>
        </div>
      }
    </Fragment>
  )
}

export default CollegeCompareList;