import env from '../../../../env';
import axios from 'axios';

class CollegeAdmissionCueMeService {

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.family_tree_backend}college_admission_cue_mes`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
}

export default new CollegeAdmissionCueMeService();