import React, {useEffect, useState, Fragment, useContext} from "react"
import {Link} from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Invite from './Invite'
import ConfirmMenu from '../Modals/ConfirmMenu'
import useModal from '../../hooks/useModal'
import useStyle from '../../hooks/useStyle'
import PreLoadSpinner from "../../components/PreLoadSpinner"
import CommonService from "../../services/CommonService"
import Pagination from '../Pagination'
import ReactTooltip from 'react-tooltip'

let requestParams = {}

const InviteOthersList = (props) => {
  
  useStyle('user_lists')
  
  let params = props.match.params

  const { state:currentUser, dispatch } = useContext(AuthContext)
  const [invitedUserList, setInvitedUserList] = useState([])
  const [invitedJourneyUserList, setInvitedJourneyUserList] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'page':currentpage,
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
    }

    getInvitedUserList()
    ReactTooltip.rebuild()
  }, [props.searchString, currentpage])

  let getInvitedUserList = () => { 
    CommonService.getInvitedUsers(requestParams).then((res)=>{
     if(res.status == 200){
       requestParams.totalItems = res.data.meta.total 
       setInvitedUserList(res.data.invite_users)
       setInvitedJourneyUserList(res.data.invited_journey_users)
      }
    })
  }

  return (
    <Fragment>
      <div className="content m-t-15">
        <h5 className="float-left font-500">Contacts</h5>
        <div className="clear"/>
      </div>

      <div className="page-content row">
        {invitedUserList.map((invited_user, i) => (
          <div className="col-xs-12 col-sm-6 col-md-4 m-b-10" key={i}>
            <Invite invitedUser={invited_user}/>
          </div>
        ))}

        {invitedJourneyUserList.map((invited_user, i) => (
          <div className="col-xs-12 col-sm-6 col-md-4 m-b-10" key={i}>
            <Invite invitedUser={invited_user}/>
          </div>
        ))}

        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
          setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>

    </Fragment>
  )

}

export default InviteOthersList;